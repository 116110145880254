import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { store, updateProductRequirementValuesForOrderLine } from '@springtree/eva-sdk-redux';
import { noop } from 'lodash';
import { IRequirements } from 'src/app/components/product-details-requirements/product-details-requirements.component';
import { EvaOrderlineRequirementModalComponent } from 'src/app/modals/orderline-requirement-modal/orderline-requirement.modal';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { Logger } from '../../shared/decorators/logger';
import { IFeedback } from '../../shared/typings';
import { EvaErrorGeneratorProvider } from '../eva-error-generator/eva-error-generator';
import { AnyAction } from 'redux';

/** The supported order line requirements by this component */
export enum SupportedOrderLineRequirements {
  SERIAL_NUMBER = 0,
  PRODUCT_REQUIREMENTS = 1
}

/** Represents an action we will dispatch and its correspending feedback */
export interface IOrderLineRequirementOperation {
  /** Action the parent needs to dispatch via store.dispatch */
  action: [AnyAction, Promise<any>, Promise<any>];
  /** Feedback parent will show whenever this action fails or succeeds */
  feedback: IFeedback;
  /** This property is pure for debugging in the parent */
  type?: SupportedOrderLineRequirements;
}

export interface IOrderLineRequirementState {
  // Refactor this to a MAP rather than an array
  //
  operations: Map<SupportedOrderLineRequirements, IOrderLineRequirementOperation>;
  /** Whether ALL requirement controls are valid, this might be useful in the future */
  valid: boolean;
}

@Logger('[eva-product-requirements-provider]')
@Injectable()
export class EvaProductRequirementsProvider {
  logger: Partial<Console>;

  constructor(
    private modalCtrl: ModalController,
    private toastCtrl: EvaToastController,
    private $translate: TranslateService,
    private $evaErrorGenerator: EvaErrorGeneratorProvider
  ) {}

  public async showProductRequirementsModal( productId: number, orderLineId: number ) {
    const modal = await this.modalCtrl.create({
      component: EvaOrderlineRequirementModalComponent,
      componentProps: {
        productId,
        orderLineId
      }
    });

    modal.present();
  }

  public async updateRequirements(orderLineId: number, model: IRequirements) {
    const orderlineRequirementUpdate = this.createProductRequirementUpdateOperation(orderLineId, model);

    const [action, fetchPromise] = orderlineRequirementUpdate.action;

    const feedback = orderlineRequirementUpdate.feedback;

    store.dispatch(action);
    const toast = this.toastCtrl.create();
    try {
      // Await the orderLineRequirements update.
      //
      await fetchPromise;
      toast.setMessage(feedback.success);
    } catch ( e ) {
      const errorData = await this.$evaErrorGenerator.constructFailureFeedback( e, feedback.failure);
      toast.setData(errorData);

    } finally {
      toast.present();
    }

    return fetchPromise.catch(noop);
  }

  /**
   * Represents an operation that contains the actions, promises and feedback of the an order line requirement update for
   * product requirements
   */
  public createProductRequirementUpdateOperation(orderLineId: number, model: IRequirements): IOrderLineRequirementOperation {
    const feedback: IFeedback = {
      success: this.$translate.instant('product.add.requirements.success'),
      failure: this.$translate.instant('product.add.requirements.fail')
    };

    const Values = {};

    model.forEach( requirement => {
      Values[requirement.ID] = requirement.Value;
    })

    const orderlineRequirementUpdate: IOrderLineRequirementOperation = {
      action: updateProductRequirementValuesForOrderLine.createFetchAction({
        OrderLineID: orderLineId,
        Values
      }),
      feedback,
      type: SupportedOrderLineRequirements.PRODUCT_REQUIREMENTS
    };

    return orderlineRequirementUpdate;
  }
}
