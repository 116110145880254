import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { getOrder, getShoppingCart } from '@springtree/eva-sdk-redux';
import { isNil } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import isNotNil from '../../shared/operators/isNotNil';

export type TCustomerAddress = EVA.Core.AddressDto;

export type TAddressType = 'shipping' | 'billing';

export interface IEditAddressOpts {
  address: EVA.Core.AddressDto;
  addressType: TAddressType;
  navCtrl: NavController;
  orderId: number;
  customerId: number;
}

@Injectable()
export class OrderAddressesProvider {
  private orderRequirements$ = getShoppingCart.getResponse$().pipe(
    isNotNil(),
    map(cartResponse => cartResponse.AdditionalOrderData?.RequiredData?.Order)
  );

  public currentAddress$ = new BehaviorSubject<EVA.Core.AddressDto>(null);

  public currentAddressRequirements$ = new BehaviorSubject<EVA.Core.Requirement[]>([]);

  async loadAddressData(orderId: number, addressId: number, addressType: TAddressType) {
    if (this.currentAddress$.value?.ID === addressId) {
      return;
    }

    let address: EVA.Core.AddressDto;

    const shoppingCartState = await getShoppingCart.getState$().pipe(first()).toPromise();
    if (!isNil(shoppingCartState) && shoppingCartState.response?.ShoppingCart?.ID === orderId) {
      const shoppingCart = shoppingCartState.response.ShoppingCart;
      address = addressType === 'billing' ? shoppingCart.BillingAddress : shoppingCart.ShippingAddress;
    } else {
      const [action] = getOrder.createFetchAction({
        OrderID: orderId
      });

      const fetchPromise = getOrder.fetchData(action);

      const response = await fetchPromise;

      const shoppingCart = response.Result;

      address = addressType === 'billing' ? shoppingCart.BillingAddress : shoppingCart.ShippingAddress;
    }

    const orderRequirements = await this.orderRequirements$.pipe(first()).toPromise();

    /** For every address type, we want to look for a different name in the order requirements object */
    const orderRequirementNameStartsWithMapping: { [key in TAddressType]: string } = {
      shipping: 'ShippingAddress.',
      billing: 'BillingAddress.'
    };

    const requirements = orderRequirements.filter(orderRequirement => {
      const hasAddressRequirement = orderRequirement.Name.startsWith(orderRequirementNameStartsWithMapping[addressType]);

      return hasAddressRequirement;
    });

    this.currentAddress$.next(address);

    this.currentAddressRequirements$.next(requirements);
  }

  async editAddress(opts: IEditAddressOpts) {

    const { addressType, address, navCtrl } = opts;

    const orderRequirements = await this.orderRequirements$.pipe(first()).toPromise();

    /** For every address type, we want to look for a different name in the order requirements object */
    const orderRequirementNameStartsWithMapping: { [key in TAddressType]: string } = {
      shipping: 'ShippingAddress.',
      billing: 'BillingAddress.'
    };

    /**
     * We will loop through all order requirements and find any that start with either `ShippingAddress.` or `billingAddress.`
     * depending on the order type that was passed
     */
    const addressRequirements = orderRequirements.filter(orderRequirement => {
      const hasAddressRequirement = orderRequirement.Name.startsWith(orderRequirementNameStartsWithMapping[addressType]);

      return hasAddressRequirement;
    });

    this.currentAddress$.next(address);

    this.currentAddressRequirements$.next(addressRequirements);

    navCtrl.navigateForward([`/orders/address/edit/${opts.orderId}/${opts.customerId}/${address.ID}/${addressType}`]);
  }
}
