import { Injectable } from '@angular/core';
import { EvaApplicationConfigProvider, AppBundlesBehavior } from '../eva-application-config/eva-application-config';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { getBundleProductDetail } from '@springtree/eva-sdk-redux';
import isNotNil from 'src/app/shared/operators/isNotNil';
import { isNil, uniq } from 'lodash-es';

export enum TranslationKeys {
  TITLE = 'title',
  BUTTON = 'button',
  INFO = 'info',
  ADD_FAIL = 'add_fail'
}

/** Represents the dynamic translated strings of this component */
export interface ITranslations {
  [TranslationKeys.TITLE]: string;
  [TranslationKeys.BUTTON]: string;
  [TranslationKeys.INFO]: string;
  [TranslationKeys.ADD_FAIL]: string;
}

export interface IChosenBundleLineOption {
  LineID: number;
  ProductsIDs: number[];
}

@Injectable()
export class ProductBundlesProvider {

constructor(
  private $evaApplicationConfig: EvaApplicationConfigProvider,
  private $translate: TranslateService,
  ) { }

  // This will save the current editable bundle order line id
  // @see https://n6k.atlassian.net/browse/OPTR-17014
  public editBundleOrderLineID$ = new BehaviorSubject<number>(null);

  // We use this to keep track of selected option before save them to bundle form
  public lineOptionChosen$ = new BehaviorSubject<IChosenBundleLineOption>(null);

  public lineVarianceChosenProductID$ = new BehaviorSubject<number>(null);

  // We use this to keep track of removed options to update bundle form
  public lineOptionRemoved$ = new BehaviorSubject<number[]>(null);

  // We want to filter out deleted lines @see https://eva2015.atlassian.net/browse/OPTR-5460
  public lines$ = getBundleProductDetail.getResponse$().pipe(
    isNotNil(),
    map(productBundleDetails => productBundleDetails.Lines ),
    map( bundleLines => bundleLines.filter( bundleLine => !bundleLine.IsDeleted ) )
  );

  // This will hold the collection of selected lines which can be used to deduce any excluded items for the bundle display
  public allProductsSelected$ = new BehaviorSubject<EVA.Core.BundleProductLineOption[]>(null);

  // The selection of excluded product ids is based on the selected bundle options.
  public excludedProductIDs$ = this.allProductsSelected$.pipe(
    map((allSelectedOptions: EVA.Core.BundleProductLineOption[]) => {
      let excludedProductIDs: number[] = [];

      // Add all the products IDs excluded by the selected options
      allSelectedOptions?.forEach(optionLine => {
        excludedProductIDs = excludedProductIDs.concat(...optionLine.ExcludesProductIDs);
      })

      return uniq(excludedProductIDs);
    })
  );

  public getBundleTranslations$(onEditMode: number = null) {
    return this.$evaApplicationConfig.AppBundlesBehavior$.pipe(
      map(bundleType => {
        const translations: ITranslations = {
          button: null,
          title: null,
          info: null,
          add_fail: null
        };

        if (bundleType === AppBundlesBehavior.Fashion) {
          translations[TranslationKeys.BUTTON] = this.$translate.instant('bundle.products.bundle.button.add.fashion') as string;
          translations[TranslationKeys.TITLE] = this.$translate.instant('bundle.products.details.title.fashion') as string;
          translations[TranslationKeys.INFO] = this.$translate.instant('bundle.information.fashion') as string;
          translations[TranslationKeys.ADD_FAIL] = this.$translate.instant('bundle.products.details.product.add.fail.fashion') as string;
        } else {
          // bundleType is either Default or Disabled
          translations[TranslationKeys.BUTTON] = this.$translate.instant('bundle.products.bundle.button.add.default') as string;
          translations[TranslationKeys.TITLE] = this.$translate.instant('bundle.products.details.title.default') as string;
          translations[TranslationKeys.INFO] = this.$translate.instant('bundle.information.default') as string;
          translations[TranslationKeys.ADD_FAIL] = this.$translate.instant('bundle.products.details.product.add.fail.default') as string;
        }

        // On edit mode we change the label
        // @see https://n6k.atlassian.net/browse/OPTR-17014
        if(!isNil(onEditMode)){
          translations[TranslationKeys.BUTTON] = this.$translate.instant('bundle.products.bundle.button.save.default') as string;
        }

        return translations;
      })
    );
  }

}
