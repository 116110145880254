import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { IClientApp, IClientAppNotificationPayload, IClientAppScanBarcodePayload } from '@springtree/eva-sdk-redux';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { EvaBarcodeScannerProvider } from 'src/app/modules/scanner/eva-barcode-scanner';
import isNotNil from 'src/app/shared/operators/isNotNil';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { ClientAppCommunicationProvider } from '../client-app-communication/client-app-communication';

/**
 * The status of the remote scanner app (if any)
 *
 * @export
 * @interface IRemoteScannerStatus
 */
 export interface IRemoteScannerStatus {
  configured: boolean;
  connected: boolean;
  status: 'active' | 'idle' | 'connected' | 'disconnected';
  peer?: IClientApp;
}

/**
 * This provider will remember scan mode properties like token and device id
 */
@Logger('[scan-mode-provider]')
@Injectable()
export class ScanModeProvider implements ILoggable {

  public logger: Partial<Console>;

  // We dont show switch camera button when Apple Scanner is active
  // @see https://n6k.atlassian.net/browse/OPTR-21869
  public isAppleScannerActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public readonly SCAN_MODE_CHANNEL_STORAGE_KEY = 'evaCompanion:scanModeChannel';

  public remoteScannerStopped$ = this.$clientAppCommunication.left$.pipe(
    filter((event) => event.ChannelID === this.currentChannel
      && event.App.UUID === this.theRemoteScannerStatus$.value.peer?.UUID
      && event.Disconnected !== true)
  );

  private idleIntervalTimer: any;

  private idleIntervalMs = 5000;

  /**
   * The current scan mode channel we are using for remote barcode scanning.
   * Remembered so we only have 1 active channel at a time.
   */
  private currentChannel: string | undefined;

  public theRemoteScannerStatus$: BehaviorSubject<IRemoteScannerStatus> = new BehaviorSubject({
    configured: false,
    connected: false,
    status: 'disconnected',
  });

  public get remoteScannerStatus$() {
    return this.theRemoteScannerStatus$.asObservable();
  }

  public isConnected$: Observable<boolean> = this.remoteScannerStatus$.pipe(
    map(connectionInformation => {
      if (connectionInformation.connected) {
        return true;
      } else if (connectionInformation?.peer?.UUID) {
        return true;
      }

      return false;
    })
  );

  constructor(
    private $clientAppCommunication: ClientAppCommunicationProvider,
    private navCtrl: NavController,
  ) {
    this.$clientAppCommunication.connected$.subscribe((connected) => {
      if (connected) {
        this.resubscribe();
      }
    });

    this.$clientAppCommunication.peers$.subscribe((peers) => {
      const currentScanModeChannel = this.currentChannel;
      if (currentScanModeChannel) {
        // Find the peer application which should be in the current scan-mode channel
        // It may have left the channel recently so fallback to the last known peer UUID
        //
        const currentRemoteScannerStatus = this.theRemoteScannerStatus$.value;
        const applicationId = localStorage.getItem('evaCompanion:applicationInstanceId');
        const peerApp = peers.find((peer) =>
            (peer.channels.indexOf(currentScanModeChannel) !== -1 ||
              peer.clientApp.UUID === currentRemoteScannerStatus.peer?.UUID) &&
            peer.clientApp.UUID !== applicationId
        );
        this.logger.log('Remote scanner app', peerApp);
        const peerStatus = peerApp?.status || 'disconnected';
        const peerConnected = peerStatus === 'active' || peerStatus === 'connected';
        const stillInChannel = peerApp?.channels.indexOf(currentScanModeChannel) !== -1;
        const now = new Date().valueOf();
        const peerLastActive = peerApp?.lastSeen.valueOf() || 0;
        const recentlyActive = now - peerLastActive < 1500;
        const peerIdle = now - peerLastActive > 20000; // POS heartbeat is 15s
        const peerUnreachable = now - peerLastActive > 60000;

        let scannerStatus = peerStatus as IRemoteScannerStatus['status'];
        if (!stillInChannel || peerUnreachable) {
          scannerStatus = 'disconnected';
        } else if (recentlyActive) {
          scannerStatus = 'active';
        } else if (peerIdle) {
          scannerStatus = 'idle';
        } else {
          scannerStatus = 'connected';
        }

        this.theRemoteScannerStatus$.next({
          connected: peerConnected,
          status: scannerStatus,
          configured: true,
          peer: peerApp?.clientApp,
        });
      } else {
        this.theRemoteScannerStatus$.next({ connected: false, configured: false, status: 'disconnected' });
      }
    });
  }

  /**
   * Sends a scanned barcode to the current scan mode channel
   */
  public async sendBarcode(barcode: string) {
    if (!this.currentChannel) {
      return;
    }

    // Restart the idle timer
    //
    this.startIdleTimer();

    const payload: IClientAppScanBarcodePayload = {
      Action: 'scanBarcode',
      Data: {
        Barcode: barcode,
      }
    };

    await this.$clientAppCommunication.sendMessage({
      ChannelID: this.currentChannel,
      Message: payload,
    });
  }

  /**
   * Subscribes to a scan mode channel. Will leave any previous current channel.
   */
  public async subscribe( channel: string ): Promise<void> {
    if (this.currentChannel) {
      try {
        await this.unsubscribe();
      } catch (error) {
        this.logger.warn('Failed to unsubscribe from previous scan-mode channel', error);
      }
    }
    try {
      localStorage.setItem(this.SCAN_MODE_CHANNEL_STORAGE_KEY, channel);
      await this.$clientAppCommunication.joinChannel(channel);
      this.currentChannel = channel;
      this.startIdleTimer();
    } catch (error) {
      this.logger.warn('Failed to subscribe to scan-mode channel', error);
    }
  }

  /**
   * Leaves the current scan mode channel
   *
   */
  public async unsubscribe() {
    localStorage.removeItem(this.SCAN_MODE_CHANNEL_STORAGE_KEY);
    if (this.currentChannel) {
      await this.$clientAppCommunication.leaveChannel(this.currentChannel);
    }
    this.currentChannel = undefined;
    clearInterval(this.idleIntervalTimer);
}

  /**
   * Resubscribes to last known channel
   *
   */
  public async resubscribe() {
    const channel = localStorage.getItem(this.SCAN_MODE_CHANNEL_STORAGE_KEY);
    if (!this.currentChannel && channel) {
      await this.subscribe(channel);
      this.navCtrl.navigateRoot(['scan-mode']);
    }
  }

  public getScanModeChannel() {
    return localStorage.getItem(this.SCAN_MODE_CHANNEL_STORAGE_KEY);
  }

  public scanModeChannelActive() {
    return !!this.getScanModeChannel();
  }

  private startIdleTimer() {
    clearInterval(this.idleIntervalTimer);
    this.idleIntervalTimer = setInterval(
      () => {
        const payload: IClientAppNotificationPayload = {
          Action: 'notification',
          Data: {
            Message: 'remote scanner active',
          }
        };

        this.$clientAppCommunication.sendMessage({
          ChannelID: this.currentChannel,
          Message: payload,
        });
      },
      this.idleIntervalMs,
    );
  }
}
