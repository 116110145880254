import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RunnerTaskCreateGuardService } from '../pages/tabs/routing/runner-task-create.service';
import { CustomPreloadingStrategyService } from './custom-preloading-strategy.service';
import { AuthGuardService } from './guards/auth-guard.service';
import { DiscountGuardService } from './guards/discount-guard.service';
import { LoginGuardService } from './guards/login-guard.service';
import { OptInGuardService } from './guards/opt-in-guard.service';
import { ProductDetailsGuardService } from './guards/product-detail-guard.service';
import { ScanGuardService } from './guards/scan-guard.service';
import { CompositeTaskResolverService } from './resolvers/composite-task-resolver.service';
import { TaskResolverService } from './resolvers/task-resolver.service';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('../pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [LoginGuardService]
  },
  {
    path: 'scan-eva-endpoint',
    loadChildren: () => import('../pages/scan-eva-endpoint/scan-eva-endpoint.module').then( m => m.ScanEvaEndpointPageModule )
  },
  {
    path: 'scan-mode',
    loadChildren: () => import('../pages/scan-mode/scan-mode.module').then( m => m.ScanModePageModule )
  },
  {
    path: 'offline',
    loadChildren: () => import('../pages/offline/offline.module').then( m => m.OfflinePageModule )
  },
  {
    path: 'environment-override',
    loadChildren: () => import('../pages/environment-override/environment-override.module').then(m => m.EnvironmentOverridePageModule)
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'tabs'
      },
      {
        path: 'tabs',
        loadChildren: () => import('../pages/tabs/tabs.module').then(m => m.TabsPageModule)
      },
      {
        path: 'scann-multiple',
        loadChildren: () => import('../pages/scann-multiple/scann-multiple.module').then(m => m.ScannMultiplePageModule),
      },
      {
        path: 'cycle-count-pre-count/:taskId',
        loadChildren: () => import('../pages/cycle-count-precount/cycle-count-precount.module').then(m => m.CycleCountPreCountPageModule),
        resolve: { task: TaskResolverService }
      },
      {
        path: 'cycle-count-confirmation/:taskId',
        loadChildren: () => import('../pages/cycle-count-confirmation/cycle-count-confirmation.module').then(m => m.CycleCountConfirmationPageModule),
        resolve: { task: TaskResolverService },
      },
      {
        path: 'cycle-count-related-counts/:taskId',
        loadChildren: () => import('../pages/cycle-count-related-counts/cycle-count-related-counts.module').then(m => m.CycleCountRelatedCountsPageModule),
        resolve: { task: TaskResolverService },
      },
      {
        path: 'zoned-cycle-count-confirmation-details/:taskId/:stockLabelId',
        loadChildren: () => import('../pages/cycle-count-confirmation-details/cycle-count-confirmation-details.module').then(m => m.CycleCountConfirmationDetailsPageModule),
        resolve: { task: TaskResolverService }
      },
      {
        path: 'stock-reservation/:taskId',
        loadChildren: () => import('../pages/stock-reservation/stock-reservation.module').then(m => m.StockReservationPageModule),
        resolve: { task: TaskResolverService }
      },
      {
        path: 'stock-movement-follow-up/:taskId',
        loadChildren: () => import('../pages/stock-movement-follow-up/stock-movement-follow-up.module').then(m => m.StockMovementFollowUpPageModule),
        resolve: { task: TaskResolverService }
      },
      {
        path: 'runner-task-create',
        loadChildren: () => import('../pages/runner-task-create/runner-task-create.module').then(m => m.RunnerTaskCreatePageModule),
        canActivate: [RunnerTaskCreateGuardService]
      },
      {
        path: 'runner-task-create/:productId',
        loadChildren: () => import('../pages/runner-task-create/runner-task-create.module').then(m => m.RunnerTaskCreatePageModule),
        canActivate: [RunnerTaskCreateGuardService]
      },
      {
        path: 'runner-task-list',
        loadChildren: () => import('../pages/runner-task-list/runner-task-list.module').then(m => m.RunnerTaskListPageModule)
      },
      {
        path: 'runner-task-blocked-products',
        loadChildren: () => import('../pages/runner-task-blocked-products/runner-task-blocked-products.module').then(m => m.RunnerTaskBlockedProductsPageModule)
      },
      {
        path: 'runner-task-list/:zone',
        loadChildren: () => import('../pages/runner-task-list/runner-task-list.module').then(m => m.RunnerTaskListPageModule)
      },
      {
        path: 'runner-task-details',
        loadChildren: () => import('../pages/runner-task-details/runner-task-details.module').then(m => m.RunnerTaskDetailsPageModule)
      },
      {
        path: 'reservation-cleanup/:taskId',
        loadChildren: () => import('../pages/reservation-cleanup/reservation-cleanup.module').then(m => m.ReservationCleanupPageModule),
        resolve: { task: TaskResolverService }
      },
      {
        path: 'full-stock-count-create',
        loadChildren: () => import('../pages/full-stock-count-create/full-stock-count-create.module').then(m => m.FullStockCountCreatePageModule)
      },
      {
        path: 'full-stock-count-label/:taskId',
        loadChildren: () => import('../pages/full-stock-count-label/full-stock-count-label.module').then(m => m.FullStockCountLabelPageModule),
        resolve: { task: TaskResolverService }
      },
      {
        path: 'full-stock-count-confirmation/:taskId',
        loadChildren: () => import('../pages/full-stock-count-confirmation/full-stock-count-confirmation.module').then(m => m.FullStockCountConfirmationPageModule),
        resolve: { task: TaskResolverService }
      },
      {
        path: 'full-stock-count-deviation/:taskId',
        loadChildren: () => import('../pages/full-stock-count-deviation/full-stock-count-deviation.module').then(m => m.FullStockCountDeviationPageModule),
        resolve: { task: TaskResolverService }
      },
      {
        path: 'full-stock-count-recount/:taskId',
        loadChildren: () => import('../pages/full-stock-count-recount/full-stock-count-recount.module').then(m => m.FullStockCountRecountPageModule),
        resolve: { task: TaskResolverService }
      },
      {
        path: 'ship-from-store-pick/:taskId',
        loadChildren: () => import('../pages/ship-from-store-pick/ship-from-store-pick.module').then(m => m.ShipFromStorePickPageModule),
        resolve: { task: TaskResolverService, compositeTask: CompositeTaskResolverService }
      },
      {
        path: 'ship-from-store-pack/:taskId',
        loadChildren: () => import('../pages/ship-from-store-pack/ship-from-store-pack.module').then(m => m.ShipFromStorePackPageModule),
        resolve: { task: TaskResolverService, compositeTask: CompositeTaskResolverService }
      },
      {
        path: 'ship-from-store-ship/:taskId',
        loadChildren: () => import('../pages/ship-from-store-ship/ship-from-store-ship.module').then(m => m.ShipFromStoreShipPageModule),
        resolve: { task: TaskResolverService, compositeTask: CompositeTaskResolverService }
      },
      {
        path: 'ship-from-store-deliver/:taskId',
        loadChildren: () => import('../pages/ship-from-store-deliver/ship-from-store-deliver.module').then(m => m.ShipFromStoreDeliverPageModule),
        resolve: { task: TaskResolverService, compositeTask: CompositeTaskResolverService }
      },
      {
        path: 'ship-from-store-print/:taskId',
        loadChildren: () => import('../pages/ship-from-store-print/ship-from-store-print.module').then(m => m.ShipFromStorePrintPageModule),
        resolve: { task: TaskResolverService, compositeTask: CompositeTaskResolverService }
      },
      {
        path: 'second-chance-product/:taskId/:productId',
        loadChildren: () => import('../pages/second-chance-product-task/second-chance-product-task.module').then( m => m.SecondChanceProductTaskPageModule),
        resolve: {task: TaskResolverService}
      },
      {
        path: 'return-to-supplier-task/:taskId',
        loadChildren: () => import('../pages/return-to-supplier-task/return-to-supplier-task.module').then( m => m.ReturnToSupplierTaskPageModule),
        resolve: {task: TaskResolverService}
      },
      {
        path: 'rts-stock-movement-task/:taskId',
        loadChildren: () => import('../pages/rts-stock-movement-task/rts-stock-movement-task.module').then( m => m.RTSStockMovementTaskPageModule),
        resolve: {task: TaskResolverService}
      },
      {
        path: 'print-task/:taskId',
        loadChildren: () => import('../pages/print-task/print-task.module').then(m => m.PrintTaskPageModule),
        resolve: { task: TaskResolverService }
      },
      {
        path: 'print-price-label-task/:taskId',
        loadChildren: () => import('../pages/print-price-label-task/print-price-label-task.module').then(m => m.PrintPriceLabelTaskPageModule),
        resolve: { task: TaskResolverService }
      },
      {
        path: 'incoming-shipment-edit/:shipmentId',
        loadChildren: () => import('../pages/incoming-shipment-edit/incoming-shipment-edit.module').then(m => m.IncomingShipmentEditPageModule),
      },
      {
        path: 'incoming-shipment-details/:shipmentId',
        loadChildren: () => import('../pages/incoming-shipment-details/incoming-shipment-details.module').then(m => m.IncomingShipmentDetailsPageModule),
      },
      {
        path: 'diagnostics',
        loadChildren: () => import('../pages/diagnostics/diagnostics.module').then(m => m.DiagnosticsPageModule)
      },
      {
        path: 'gift-card-requirements/:orderLineId',
        loadChildren: () => import('../pages/gift-card-requirements/gift-card-requirements.module').then( m => m.GiftCardRequirementsPageModule)
      },
      {
        path: 'disabled-discounts/:orderId',
        loadChildren: () => import('../pages/disabled-discounts-list/disabled-discounts-list.module').then( m => m.DisabledDiscountsListPageModule),
      },
      {
        path: 'discount/:orderLineId',
        loadChildren: () => import('../pages/discount/discount.module').then( m => m.DiscountPageModule),
        canActivate: [DiscountGuardService]
      },
      {
        path: 'discount',
        loadChildren: () => import('../pages/discount/discount.module').then( m => m.DiscountPageModule),
        canActivate: [DiscountGuardService]
      },
      {
        path: 'product-discount-options/:orderLineId',
        loadChildren: () => import('../pages/product-discount-options/product-discount-options.module').then(m => m.ProductDiscountOptionsPageModule)
      },
      {
        path: 'line-action-type/:orderLineId',
        loadChildren: () => import('../pages/line-action-type/line-action-type.module').then( m => m.LineTypePageModule)
      },
      {
        path: 'free-product-discount-options/:orderLineId',
        loadChildren: () => import('../pages/free-product-discount-options/free-product-discount-options.module').then(m => m.FreeProductDiscountOptionsPageModule)
      },
      {
        path: 'gwp-scanner-mode/:orderLineId',
        loadChildren: () => import('../pages/gwp-scanner-mode/gwp-scanner-mode.module').then(m => m.GWPScannerModePageModule)
      },
      {
        path: 'gwp-product-options/:orderLineId',
        loadChildren: () => import('../pages/gwp-product-options/gwp-product-options.module').then(m => m.GWPProductOptionsPageModule)
      },
      {
        path: 'free-gwp-scanner-mode/:orderLineId',
        loadChildren: () => import('../pages/free-gwp-scanner-mode/free-gwp-scanner-mode.module').then(m => m.FreeGWPScannerModePageModule)
      },
      {
        path: 'free-gwp-product-options/:orderLineId',
        loadChildren: () => import('../pages/free-gwp-product-options/free-gwp-product-options.module').then(m => m.FreeGWPProductOptionsPageModule)
      },
      {
        path: 'orders',
        children: [
          {
            path: 'search',
            loadChildren: () => import('../pages/order-search/order-search.module').then(m => m.OrderSearchPageModule),
          },
          {
            path: 'search/:serialNumber',
            loadChildren: () => import('../pages/order-search/order-search.module').then(m => m.OrderSearchPageModule),
          },
          {
            path: 'details/:orderId',
            loadChildren: () => import('../pages/order-detail/order-detail.module').then(m => m.OrderDetailPageModule),
          },
          {
            path: 'produce-documents/:orderId',
            loadChildren: () => import('../pages/order-produce-documents/order-produce-documents.module').then(m => m.OrderProduceDocumentsPageModule),
          },
          {
            path: 'filters',
            loadChildren: () => import('../pages/order-filters/order-filters.module').then(m => m.OrderFiltersPageModule),
          },
          {
            path: 'blobs/:orderId',
            loadChildren: () => import('../pages/order-blobs/order-blobs.module').then(m => m.OrderBlobsPageModule),
          },
          {
            path: 'return/:orderId',
            loadChildren: () => import('../pages/order-return/order-return.module').then(m => m.OrderReturnPageModule),
          },
          {
            path: 'create-shipment/:orderId',
            loadChildren: () => import('../pages/order-create-shipment/order-create-shipment.module').then(m => m.OrderCreateShipmentPageModule),
          },
          {
            path: 'address/select/:orderId/:userId',
            loadChildren: () => import('../pages/order-address-select/order-address-select.module').then(m => m.OrderAddressSelectPageModule),
          },
          {
            path: 'address/edit/:orderId/:userId/:addressId/:addressType',
            loadChildren: () => import('../pages/order-address-edit/order-address-edit.module').then(m => m.OrderAddressEditPageModule),
          },
          {
            path: 'address/edit/customer-updated-orders/:orderId/:userId/:addressId/:addressType',
            loadChildren: () => import('../pages/customer-updated-orders/customer-updated-orders.module').then(m => m.CustomerUpdatedOrdersPageModule),
          },
          {
            path: 'giftwrapping/:orderId',
            loadChildren: () => import('../pages/order-giftwrapping/order-giftwrapping.module').then(m => m.OrderGiftwrappingPageModule),
          },
          {
            path: 'shipment/:orderId/:shipmentId',
            loadChildren: () => import('../pages/order-shipment-detail/order-shipment-detail.module').then(m => m.OrderShipmentDetailPageModule),
          }
        ]
      },
      {
        path: 'checkout',
        children: [
          {
            path: ':orderId',
            loadChildren: () => import('../pages/checkout/checkout.module').then(m => m.CheckoutPageModule),
          },
          {
            path: 'giftcard-pay/:paymentMethodTypeModelCode',
            loadChildren: () => import('../pages/checkout-gift-card-pay/checkout-gift-card-pay.module').then(m => m.CheckoutGiftCardPayPageModule),
          },
          {
            path: 'partial-shipments/:orderId',
            loadChildren: () => import('../pages/partial-shipments/partial-shipments.module').then( m => m.PartialShipmentsPageModule)
          },
          {
            path: 'payment-history/:orderId',
            loadChildren: () => import('../pages/payment-history/payment-history.module').then( m => m.PaymentHistoryPageModule)
          },
          {
            path: 'store-selection/:orderId',
            loadChildren: () => import('../pages/store-selection/store-selection.module').then( m => m.StoreSelectionPageModule)
          },
          {
            path: 'order-signing/:orderId',
            loadChildren: () => import('../pages/order-signing/order-signing.module').then( m => m.OrderSigningPageModule)
          },
          {
            path: 'shipping-methods/:orderId',
            loadChildren: () => import('../pages/shipping-methods/shipping-methods.module').then( m => m.ShippingMethodsPageModule)
          },
          {
            path: 'shipping-delivery/:orderId',
            loadChildren: () => import('../pages/shipping-delivery/shipping-delivery.module').then( m => m.ShippingDeliveryPageModule)
          },
          {
            path: 'options/select/:orderId/:categoryId',
            loadChildren: () => import('../pages/checkout/order-options/order-options.module').then(m => m.OrderOptionsModule)
          },
        ],
      },
      {
        path: 'checkout-required-details',
        loadChildren: () => import('../pages/checkout-required-details/checkout-required-details.module').then( m => m.CheckoutRequiredDetailsPageModule),
      },
      {
        path: 'users',
        children: [
          {
            path: 'search',
            loadChildren: () => import('../pages/user-search/user-search.module').then(m => m.UserSearchPageModule),
          },
          {
            path: 'details/:userId',
            loadChildren: () => import('../pages/user-detail/user-detail.module').then(m => m.UserDetailPageModule),
          },
          {
            path: 'create',
            loadChildren: () => import('../pages/customer-create/customer-create.module').then(m => m.CustomerCreatePageModule),
          },
          {
            path: 'opt-in',
            loadChildren: () => import('../pages/customer-opt-in/customer-opt-in.module').then(m => m.CustomerOptInPageModule),
            canActivate: [OptInGuardService],
          },
          {
            path: 'opt-in/:userId',
            loadChildren: () => import('../pages/customer-opt-in/customer-opt-in.module').then(m => m.CustomerOptInPageModule),
            canActivate: [OptInGuardService],
          },
          {
            path: 'edit/:userId',
            loadChildren: () => import('../pages/customer-edit/customer-edit.module').then(m => m.CustomerEditPageModule),
          },
        ]
      },
      {
        path: 'address-book',
        children: [
          {
            path: 'list/:userId',
            loadChildren: () => import('../pages/address-book-list/address-book-list.module').then(m => m.AddressBookListPageModule),
          },
          {
            path: 'create/:userId',
            loadChildren: () => import('../pages/address-book-create/address-book-create.module').then(m => m.AddressBookCreatePageModule),
          },
          {
            path: 'edit/:userId/:addressBookId',
            loadChildren: () => import('../pages/address-book-edit/address-book-edit.module').then(m => m.AddressBookEditPageModule),
          },
        ]
      },
      {
        path: 'scan',
        loadChildren: () => import('../pages/scan/scan.module').then(m => m.ScanPageModule),
        canActivate: [ScanGuardService]
      },
      {
        path: 'products',
        children: [
          {
            path: 'search',
            loadChildren: () => import('../pages/product-search/product-search.module').then(m => m.ProductSearchPageModule),
          },
          {
            path: 'search-results',
            loadChildren: () => import('../pages/product-search-results/product-search-results.module').then(m => m.ProductSearchResultsPageModule),
          },
          {
            path: 'details/:productId',
            loadChildren: () => import('../pages/product-detail/product-detail.module').then(m => m.ProductDetailPageModule),
            canActivate: [ProductDetailsGuardService]
          },
          {
            path: 'details-stock/:productId',
            loadChildren: () => import('../pages/product-detail-stock/product-detail-stock.module').then(m => m.ProductDetailStockPageModule),
          },
          {
            path: 'details-stock/:productId/status',
            loadChildren: () => import('../pages/product-status/product-status.module').then(m => m.ProductStatusPageModule),
          },
          {
            path: 'details-stock/:productId/overview',
            loadChildren: () => import('../pages/product-overview/product-overview.module').then(m => m.ProductOverviewPageModule),
          },
          {
            path: 'details-stock/:productId/external-stock',
            loadChildren: () => import('../pages/product-external-stock/product-external-stock.module').then(m => m.ProductExternalStockPageModule),
          },
          {
            path: 'details-stock/:productId/details',
            loadChildren: () => import('../pages/product-details/product-details.module').then(m => m.ProductDetailsPageModule),
          },
          {
            path: 'details-stock/:productId/print-label',
            loadChildren: () => import('../pages/product-print-label/product-print-label.module').then(m => m.ProductPrintLabelPageModule),
          },
          {
            path: 'bundle-details/:bundleId',
            loadChildren: () => import('../pages/product-bundle-details/product-bundle-details.module').then(m => m.ProductBundleDetailsPageModule),
          },
          {
            path: 'bundle-details/:bundleId/line/:lineId',
            loadChildren: () => import('../pages/product-bundle-line-options/product-bundle-line-options.module').then(m => m.ProductBundleLineOptionsPageModule),
          },
          {
            path: 'bundle-details/:bundleId/line/:lineId/variances/:productId',
            loadChildren: () => import('../pages/product-bundle-variances/product-bundle-variances.module').then(m => m.ProductBundleVariancesPageModule),
          },
          {
            path: 'more-stock-info/:productId',
            loadChildren: () => import('../pages/more-stock-info/more-stock-info.module').then(m => m.MoreStockInfoPageModule),
          },
          {
            path: 'stock-adjustment/:productId/:stockLabelId',
            loadChildren: () => import('../pages/stock-adjustment/stock-adjustment.module').then(m => m.StockAdjustmentPageModule),
          },
          {
            path: 'stock-adjustment/:productId/move/:stockLabelId',
            loadChildren: () => import('../pages/move-stock/move-stock.module').then(m => m.MoveStockPageModule),
          },
          {
            path: 'stock-adjustment/:productId/adjust/:stockLabelId',
            loadChildren: () => import('../pages/change-quantity/change-quantity.module').then(m => m.ChangeQuantityPageModule),
          },
        ],
      },
      {
        path: 'audit-documents',
        children: [
          {
            path: '',
            loadChildren: () => import('../pages/audit-documents/audit-documents.module').then(m => m.AuditDocumentsPageModule),
          },
          {
            path: 'details/:id',
            loadChildren: () => import('../pages/audit-document-details/audit-document-details.module').then(m => m.AuditDocumentDetailsPageModule),
          }
        ]
      },
      {
        path: 'gift-receipt',
        children: [
          {
            path: 'list/:orderId',
            loadChildren: () => import('../pages/gift-receipt-list/gift-receipt-list.module').then(m => m.GiftReceiptListPageModule),
          },
          {
            path: 'create/:orderId',
            loadChildren: () => import('../pages/gift-receipt-create/gift-receipt-create.module').then(m => m.GiftReceiptCreatePageModule),
          },
        ]
      },
      {
        path: 'borrowed-items',
        children: [
          {
            path: 'list/:userId',
            loadChildren: () => import('../pages/borrowed-items-list/borrowed-items-list.module').then(m => m.BorrowedItemsListPageModule),
          },
          {
            path: 'create/:userId',
            loadChildren: () => import('../pages/borrowed-item-create/borrowed-item-create.module').then(m => m.BorrowedItemCreatePageModule),
          },
        ]
      },
      {
        path: 'preview-document/:name/:taskId',
        loadChildren: () => import('../pages/preview-document/preview-document.module').then(m => m.PreviewDocumentPageModule)
      },
      {
        path: 'print-price-label-quick-mode',
        loadChildren: () => import('../pages/print-price-label-quick-mode/print-price-label-quick-mode.module').then((m) => m.PrintPriceLabelQuickModePageModule),
      },
      {
        path: 'order-custom-fields/:orderId/:backendID',
        loadChildren: () => import('../pages/order-custom-fields/order-custom-fields.module').then((m) => m.OrderCustomFieldsPageModule),
      },
      {
        path: 'order-custom-fields/:orderId/:backendID/:orderOptionID',
        loadChildren: () => import('../pages/order-custom-fields/order-custom-fields.module').then((m) => m.OrderCustomFieldsPageModule),
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: CustomPreloadingStrategyService, enableTracing: false })
  ],
  exports: [RouterModule],
  providers: [LoginGuardService, AuthGuardService]
})
export class AppRoutingModule { }
