import { Injectable } from '@angular/core';
import { IParseBarcodeHandler } from '../eva-parse-barcode-handler/eva-parse-barcode-handler';
import { IParsedBarcode } from '../../modules/scanner/eva-barcode-scanner';
import { IParsedDeviceHub } from '../../shared/typings';
import { getCurrentUser, getShoppingCart, IClientAppTransferOrderPayload, logout, settings, store } from '@springtree/eva-sdk-redux';
import { Logger, ILoggable } from '../../shared/decorators/logger';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ClientAppCommunicationProvider } from '../client-app-communication/client-app-communication';
import { filter, take, timeout } from 'rxjs/operators';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';

/**
 * The device hub has been replaced by the client app communication hub.
 * The previous implementation here was used to send the order from the CAPP to another app (POS)
 * We will now perform this action using the `transferOrder` message of the client app communication hub.
 */
@Logger('[eva-parse-barcode-device-hub-handler-provider]')
@Injectable()
export class EvaParseBarcodeDeviceHubHandlerProvider implements IParseBarcodeHandler, ILoggable {

  logger: Partial<Console>;

  constructor(
    private $clientAppCommunication: ClientAppCommunicationProvider,
    private $translate: TranslateService,
    private alertCtrl: AlertController,
    private navCtrl: NavController,
    private toastCtrl: EvaToastController,
  ) { }

  async handle(parsedData: IParsedBarcode) {
    const deviceId = (parsedData.Data as IParsedDeviceHub).DeviceID;
    const channel = `DeviceHub:${deviceId}`;
    const orderId = getShoppingCart.getState().response?.ShoppingCart.ID;
    const userId = getCurrentUser.getState().response?.User.ID;

    // Should not happen but checking anyway
    //
    if (!userId) {
      this.logger.error('Cannot transfer without current user');
      return;
    }

    // Show the user confirmation dialog first
    //
    const userConfirmedTransfer = await this.userConfirmedTransfer();

    if (!userConfirmedTransfer) {
      return;
    }

    try {
      await this.$clientAppCommunication.joinChannel(channel);
    } catch (error) {
      this.logger.error(`failed to join channel: ${channel}`, error);
      return;
    }

    // Prepare the message payload we will send to the other app
    // An order id of 0 means there is nu current order
    //
    const payload: IClientAppTransferOrderPayload = {
      Action: 'transferOrder',
      Data: {
        OrderID: orderId || 0,
        Token: settings.userToken,
        UserID: userId,
        Status: 'AVAILABLE',
      }
    };

    // Notify the other app and wait for either the acceptance or decline message
    //
    try {
      await this.$clientAppCommunication.sendMessage({
        ChannelID: channel,
        Message: payload,
      });

      // Wait for either the DECLINED or ACCEPTED message
      // Set a timeout of 5 minutes to prevent endless subscriptions
      //
      try {
        const responseMessage = await this.$clientAppCommunication.messages$.pipe(
          filter((message) => message.ChannelID === channel && message.Message.Action === 'transferOrder'),
          take(1),
          timeout(5 * 60000),
        ).toPromise();

        const responsePayload = responseMessage.Message as IClientAppTransferOrderPayload;
        switch (responsePayload.Data.Status) {
          case 'ACCEPTED':
            // Session was transferred, time to logout
            // Send the released message and leave the channel
            // Do this before logging out because that will close the
            // current client app hub connection
            //
            try {
              payload.Data.Status = 'RELEASED';
              await this.$clientAppCommunication.sendMessage({
                ChannelID: channel,
                Message: payload,
              });
              await this.$clientAppCommunication.leaveChannel(channel);
            } catch (error) {
              this.logger.error(`failed to send release message channel: ${channel}`, error);
            }

            const successToast = this.toastCtrl.create({
              message: this.$translate.instant('transfer.order.push.success'),
            });
            successToast.present();

            // Unset but do not logout the token otherwise it will expire
            // making it unusable for the receiving app
            //
            settings.userToken = undefined;
            const [userAction, , chainPromise] = getCurrentUser.createFetchAction();
            store.dispatch(userAction);
            await chainPromise;

            await this.navCtrl.navigateRoot(['login']);
            break;

          default:
          case 'DECLINED':
            try {
              await this.$clientAppCommunication.leaveChannel(channel);
            } catch (error) {
              this.logger.error(`failed to leave channel after decline: ${channel}`, error);
              return;
            }

            const errorToast = this.toastCtrl.create({
              message: this.$translate.instant('transfer.order.push.fail'),
            });
            errorToast.present();
            break;
        }
      } catch (error) {
        this.logger.error('Session push failed', error);
        const errorToast = this.toastCtrl.create({
          message: this.$translate.instant('transfer.order.push.fail'),
        });

        try {
          await this.$clientAppCommunication.leaveChannel(channel);
        } catch (leaveError) {
          this.logger.error(`failed to leave channel after error: ${channel}`, leaveError);
          return;
        }
        errorToast.present();
      }

    } catch (error) {
      this.logger.error(`failed to send Session to device with deviceId: ${deviceId}, orderId: ${orderId}`, error);
    }
  }

  /** We need to ensure the user confirms the order transfer to another device before transferring it */
  private async userConfirmedTransfer(): Promise<boolean> {
    let confirmed = false;

    const alert = await this.alertCtrl.create({
      header: this.$translate.instant('transfer.order.different.device.warning.title'),
      message: this.$translate.instant('transfer.order.different.device.warning.message'),
      buttons: [{
        text: this.$translate.instant('action.cancel')
      }, {
        handler: () => {
          confirmed = true;
        },
        text: this.$translate.instant('action.transfer'),
      }]
    });

    alert.present();

    const alertDismissPromise = alert.onDidDismiss<void>();

    await alertDismissPromise;

    return confirmed;
  }
}
