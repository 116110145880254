import { Observable, Subscriber } from 'rxjs';
import { NgZone } from '@angular/core';

export function runInZone<T>(zone: NgZone) {
  return (source: Observable<T>) => {
    return new Observable((observer: Subscriber<T>) => {
      const onNext = (value: T) => zone.run(() => observer.next(value));
      const onError = (e: any) => zone.run(() => observer.error(e));
      const onComplete = () => zone.run(() => observer.complete());
      return source.subscribe(onNext, onError, onComplete);
    });
  };
}
