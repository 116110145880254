import { Directive, HostListener, Inject, Self } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';
import { IonInputFocusFixService } from './ion-input-focus-fix.service';

/**
 * Sometimes an input is focused and the keyboard opens up but the cursor is not blinking and typing on the keyboard doesn't work.
 * This is primarily an issue on iOS 12 and the only solution seems to be to override the 'value' of the native input. This tricks iOS into
 * focusing correctly on the pseudo focused input field and fixes the issue.
 *
 * This directive will use the ionFocus event on the ion-input as a trigger to activate this iOS 12 specific fix
 */
@Logger('[ion-input-focus-fix-directive]')
@Directive({
  selector: 'ion-input'
})
export class IonInputFocusFixDirective implements ILoggable {

  logger: Partial<Console>;

  @HostListener('ionFocus')
  onIonFocus() {
    const isActive = this.ionInputFocusFixService.getActiveState();

    if (!isActive) {
      return;
    }

    setTimeout(async () => {
      const nativeInput = await this.ionInput.getInputElement();

      nativeInput.value = nativeInput.value;

      this.logger.log('fix activated');
    }, 300);
  }

  constructor(@Self() @Inject(IonInput) private ionInput: IonInput, private ionInputFocusFixService: IonInputFocusFixService) {

  }

}
