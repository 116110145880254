import { IConfirmAlertParams, presentConfirmAlert } from './utils/present-alert';

/**
 * Add this decorator to any method you want the user to verify, so any method that will perform a destructive action.
 */
export function ConfirmAlert(params: IConfirmAlertParams) {
  return function (_target: any, _prop: string, descriptor: TypedPropertyDescriptor<(...args: any[]) => Promise<any>>) {
    const method = descriptor.value;

    descriptor.value = function () {
      const args: IArguments = arguments;

      // Use the utility function to present the confirmation alert
      //
      const presentConfirmAlertPromise = presentConfirmAlert(params);

      return presentConfirmAlertPromise.then( (userConfirmed: boolean) => {
        if ( userConfirmed ) {
          const returnValue = method.apply(this, args);

          return returnValue;
        } else {
          return false;
        }
      });
    };
  };
}
