<ion-list *ngIf="cartLines$ | async as cartLines" [hidden]="cartLines | isEmpty" class="ion-padding">

  <eva-disabled-discounts-card></eva-disabled-discounts-card>

  <eva-gift-lines></eva-gift-lines>

  <eva-product-discount-option-lines></eva-product-discount-option-lines>

  <!-- ALL ORDER LINES (without section separation) -->
  <ng-container *ngIf="!showOrderLinesTypes">
    <eva-basket-list-item *ngFor="let line of cartLines"
      [listItemSwipeActions]="listItemSwipeActions" [line]="line"
      (canDeleteLineAction)="canDeleteLine($event)"
      (goToProductBundlesAction)="goToProductBundles($event)"
      (changeStockLabelAction)="changeStockLabel($event)">
    </eva-basket-list-item>
  </ng-container>

  <!-- SERVICE LINES -->
  <ng-container *ngIf="showOrderLinesTypes && (serviceTypeLines$ | async)?.length">
    <h6>{{ 'order.type.service' | translate }}</h6>
    <eva-basket-list-item *ngFor="let line of serviceTypeLines$ | async"
      [listItemSwipeActions]="listItemSwipeActions" [line]="line"
      (canDeleteLineAction)="canDeleteLine($event)"
      (goToProductBundlesAction)="goToProductBundles($event)"
      (changeStockLabelAction)="changeStockLabel($event)">
    </eva-basket-list-item>
  </ng-container>

  <!-- DISCOUNT LINES -->
  <ng-container *ngIf="showOrderLinesTypes && (discountTypeLines$ | async)?.length">
    <eva-basket-list-item *ngFor="let line of discountTypeLines$ | async"
      [listItemSwipeActions]="listItemSwipeActions" [line]="line"
      (canDeleteLineAction)="canDeleteLine($event)">
    </eva-basket-list-item>
  </ng-container>

  <!-- RESERVE TYPE -->
  <ng-container *ngIf="showOrderLinesTypes && (cartReserveTypeLines$ | async)?.length">
    <h6>{{ 'order.type.reserve' | translate }}</h6>
    <eva-basket-list-item *ngFor="let line of cartReserveTypeLines$ | async"
      [listItemSwipeActions]="listItemSwipeActions" [line]="line"
      (canDeleteLineAction)="canDeleteLine($event)"
      (goToProductBundlesAction)="goToProductBundles($event)"
      (changeStockLabelAction)="changeStockLabel($event)">
    </eva-basket-list-item>
  </ng-container>

  <!-- ORDER TYPE -->
  <ng-container *ngIf="showOrderLinesTypes && (cartOrderTypeLines$ | async)?.length">
    <h6>{{ 'order.type.order' | translate }}</h6>
    <eva-basket-list-item *ngFor="let line of cartOrderTypeLines$ | async"
      [listItemSwipeActions]="listItemSwipeActions" [line]="line"
      (canDeleteLineAction)="canDeleteLine($event)"
      (goToProductBundlesAction)="goToProductBundles($event)"
      (changeStockLabelAction)="changeStockLabel($event)">
    </eva-basket-list-item>
  </ng-container>

  <!-- CARRY-OUT TYPE -->
  <ng-container *ngIf="showOrderLinesTypes && (cartCarryOutTypeLines$ | async)?.length">
    <h6>{{ 'order.type.shipline' | translate }}</h6>
    <eva-basket-list-item *ngFor="let line of cartCarryOutTypeLines$ | async"
      [listItemSwipeActions]="listItemSwipeActions" [line]="line"
      (canDeleteLineAction)="canDeleteLine($event)"
      (goToProductBundlesAction)="goToProductBundles($event)"
      (changeStockLabelAction)="changeStockLabel($event)">
    </eva-basket-list-item>
  </ng-container>

  <!-- DELIVERY TYPE -->
  <ng-container *ngIf="showOrderLinesTypes && (cartDeliveryTypeLines$ | async)?.length">
    <h6>{{ 'order.type.delivery' | translate }}</h6>
    <eva-basket-list-item *ngFor="let line of cartDeliveryTypeLines$ | async"
      [listItemSwipeActions]="listItemSwipeActions" [line]="line"
      (canDeleteLineAction)="canDeleteLine($event)"
      (goToProductBundlesAction)="goToProductBundles($event)"
      (changeStockLabelAction)="changeStockLabel($event)">
    </eva-basket-list-item>
  </ng-container>

</ion-list>

<eva-empty-state @fadeInOut *ngIf=" showEmptyBasketIcon$ | async ">

  <h2>
     {{ 'empty.basket.orders' | translate : {orderType: (labelStream$ | async)} }}
  </h2>

  <eva-icon [icon]="iconStream$ | async"></eva-icon>

</eva-empty-state>
