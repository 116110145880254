import { Component, ViewChild } from '@angular/core';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { getCurrentUser } from '@springtree/eva-sdk-redux';
import { isNil } from 'lodash-es';
import { Subject, BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { CustomFieldFormElementComponent } from 'src/app/components/custom-field-form-element/custom-field-form-element.component';
import { CustomFieldFormConfig, UserCustomFieldsService } from 'src/app/services/user-custom-fields/user-custom-fields.service';

/**
 * This modal manage custom fields of type ARRAY
 *
 * --------------- WARNING ----------------------
 * Be aware that this component only works for USER custom fields
 * as it reads the data only from here: userCustomFieldsService
 *
 * @see https://n6k.atlassian.net/browse/OPTR-20281
 */
@Component({
  selector: 'eva-custom-fields-arrays-modal',
  templateUrl: 'custom-fields-arrays.modal.html',
  styleUrls: ['./custom-fields-arrays.modal.scss'],
})
export class CustomFieldsArraysModalComponent {

  @ViewChild('customInput') customInputField: CustomFieldFormElementComponent;

  public configCustomField: CustomFieldFormConfig = this.navParams.get('config');

  public savedCustomFieldValues: [] = this.navParams.get('values');

  public stop$ = new Subject<void>();

  public logger: Partial<Console>;

  public customFieldConfig: CustomFieldFormConfig;

  public fieldValues$ = new BehaviorSubject<string[]>([]);

  public form: FormGroup = this.fb.group({
    customField: new FormControl()
  });

  public get isValid(){
    const hasValues = this.fieldValues$?.value?.length;
    return this.form.valid && hasValues;
  }

  constructor(
    private $userCustomFieldsService: UserCustomFieldsService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private fb: FormBuilder
  ) { }

  async ngOnInit() {
    await this.getCustomFieldData();
    await this.getSavedValues();
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  // Get custom field data to create the form
  private async getCustomFieldData(){
    const userCurrentCustomFields: { [key: number]: EVA.Core.CustomFieldValueWithOptions } = await getCurrentUser.getResponse$().pipe(
      first(userResponse => !isNil(userResponse)),
      map(userResponse => userResponse.User.CustomFieldValuesWithOptions),
    ).toPromise();

    const customFieldsUpsert = await this.$userCustomFieldsService.getCustomerUpsertView(userCurrentCustomFields);
    const customFieldData = customFieldsUpsert.customFields.find(field => field.CustomFieldID === this.configCustomField.CustomFieldID);

    // We search for the desired custom field and add it to the control form
    Object.entries(customFieldsUpsert.customFieldsFormGroup.controls)?.forEach(([key, control]) => {
      if(key === String(customFieldData?.CustomFieldID)){
        this.form.addControl('customField', control);
      }
    });

    this.customFieldConfig = { ...customFieldData };
  }

  // Get saved values
  private async getSavedValues(){
    // Remove custom field type to work with easily. We will modify
    // values and types according to the custom field configuration
    const savedValues = this.savedCustomFieldValues?.map(value => {
      // when dealing with values like -> {StringValue: 'e'}
      if(!isNil(value) && typeof value === 'object'){
        return Object.values(value)[0] as any;
      }
      // when dealing with just strings as value
      // @see https://n6k.atlassian.net/browse/OPTR-23573
      return value;
    });

    if(savedValues?.length) {
     this.fieldValues$.next(savedValues);
    }
  }

  // Add a new input to the array of inputs
  public addInputItem() {
    const currentFieldValues = this.fieldValues$.value;
    currentFieldValues.push(this.form.get('customField').value);
    this.form.get('customField').setValue('');
    this.fieldValues$.next(currentFieldValues);
  }

  // Remove input from array
  public removeInputItem(index: number) {
    const currentFieldValues = this.fieldValues$.value;
    currentFieldValues.splice(index, 1);
    this.fieldValues$.next(currentFieldValues);
  }

  async saveDataAction(){
    const arrayValues: EVA.Core.CustomFieldValue[] = [];
    const dataType = this.getDataType();

    // Add custom field value type
    this.fieldValues$?.value?.forEach((value) => {
      arrayValues.push({ [dataType]: value })
    });

    const payload = {
      CustomFieldID: this.customFieldConfig.CustomFieldID,
      ArrayValues: arrayValues
    }

    this.dismiss(payload);
  }

  getDataType(): string{
    if (this.customFieldConfig.DataTypeID  === EVA.Core.CustomFieldDataTypes.Bool) {
      return 'BoolValue';
    }
    else if (this.customFieldConfig.DataTypeID  === EVA.Core.CustomFieldDataTypes.DateTime) {
      return 'DateTimeValue';
    }
    else if (this.customFieldConfig.DataTypeID  === EVA.Core.CustomFieldDataTypes.Date) {
      // this format is accepted on BE for Date type: YYYY-MM-DD
      return 'DateTimeValue';
    }
    else if (this.customFieldConfig.DataTypeID  === EVA.Core.CustomFieldDataTypes.Integer || this.customFieldConfig.DataTypeID === EVA.Core.CustomFieldDataTypes.Decimal) {
      return 'NumberValue';
    }
    else if (this.customFieldConfig.DataTypeID  === EVA.Core.CustomFieldDataTypes.String ||
      this.customFieldConfig.DataTypeID  === EVA.Core.CustomFieldDataTypes.Enum || this.customFieldConfig.DataTypeID  === EVA.Core.CustomFieldDataTypes.Text) {
      return 'StringValue';
    }
  }

  dismiss(payload?) {
    this.modalCtrl.dismiss(payload);
  }
}
