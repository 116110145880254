import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'lodash';

@Pipe({
  name: 'isEmpty',
  pure: false
})
export class IsEmptyPipe implements PipeTransform {
  /**
   * Checks whether an array is empty or not using lodash isEmpty
   */
  transform( value: any ): boolean {
    const condition = isEmpty(value);
    return condition;
  }
}
