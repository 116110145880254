import { Injectable } from '@angular/core';
import { isNil } from 'lodash';
import { OrderLinesRequirements } from '../../shared/typings';
import { Logger, ILoggable } from '../../shared/decorators/logger';
import { TGiftLineRequirements } from 'src/app/components/gift-lines/gift-lines.component';

export interface ISelectedProductRequirementsOpts {
  orderLineId: number;
  getShoppingCartResponse?: EVA.Core.ShoppingCartResponse;
}

/**
 * This provider is responsible for managing pick discount option requirements, which will be needed in the checkout and
 * in pick discount option components
 */
@Logger('[pick-discount-option-requirements-provider]')
@Injectable()
export class PickDiscountOptionRequirementsProvider implements ILoggable {
  logger: Partial<Console>;

  private readonly REQUIREMENT_NAME = 'PickDiscountProduct';

  hasInvalidPickDiscountProductRequirement(orderLineRequirements: EVA.Core.Requirement[]): boolean {
    const pickDiscountProductRequirement = this.getPickDiscountProductRequirement(orderLineRequirements);

    const hasInvalidPickDiscountProductRequirement = pickDiscountProductRequirement && !pickDiscountProductRequirement.IsValid;

    return hasInvalidPickDiscountProductRequirement;
  }

  /**
   * This will get all the currently selected pick discount product order lines
   */
  getPickDiscountProductLines( order: EVA.Core.OrderDto, orderLineRequirementsMap: OrderLinesRequirements): EVA.Core.OrderLineDto[] {
    // To get the selected products we need to do three things
    // 1. Find all the orderline ids containing a PickDiscountProduct requirement
    // 2. Find the orderline with that ID and see if it has a parentID
    // 3. Find the orderline with that parentID and return it
    //

    /** These order lines ids will be ones with PickDiscountProduct requirements */
    const matchingOrderLineIds = Object.entries(orderLineRequirementsMap).filter(
      ([_orderLineId, orderLineRequirements]: [string, EVA.Core.Requirement[]]) => {
      const hasPickDiscountProductRequirement = orderLineRequirements.some(orderLineRequirement => {
        return orderLineRequirement.Name === this.REQUIREMENT_NAME;
      });

      return hasPickDiscountProductRequirement;
    })
    .map(([orderlineId]) => + orderlineId);

    /** These are the discount lines, which will help us find the selected products */
    const matchingOrderLines = order.Lines.filter(orderLine => matchingOrderLineIds.includes(orderLine.ID) );

    /** We will use this ParentIDs array to finally find the products */
    const matchingOrderLineParentIDs = matchingOrderLines
      .map(matchingOrderLine => matchingOrderLine.ParentID)
      .filter( parentID => !isNil(parentID));

    const matchingPickDiscountProductLines = order.Lines.filter(orderLine => matchingOrderLineParentIDs.includes(orderLine.ID));

    return matchingPickDiscountProductLines;
  }

  getPickDiscountProductRequirement(orderLineRequirements: EVA.Core.Requirement[]) {
    const invalidPickDiscountProductRequirement = this.getRequirement(orderLineRequirements, this.REQUIREMENT_NAME);

    return invalidPickDiscountProductRequirement;
  }

  /**
   * Helper method to return the `EVA.Core.Requirement` by providing the `Requirement name`
   */
  getRequirement(orderLineRequirements: EVA.Core.Requirement[] = [], requirementName: string): EVA.Core.Requirement {
    const matchedRequirement = orderLineRequirements.find(requirement => requirement.Name === requirementName);

    return matchedRequirement;
  }

  /**
   * Returns some of the requirements we want to handle for selected PDO products, which are the following
   * - InsufficientStock
   * - RestrictedShipping
   */
  getSelectedProductRequirements(opts: ISelectedProductRequirementsOpts): TGiftLineRequirements {
    const { getShoppingCartResponse, orderLineId } = opts;

    // We want to find the matching product order line, which can be found from the discount using its parentID
    // so we will first find the discount line, and then use its parentID to find the matching order line containing the selected product
    //
    const orderLineDiscountLine = getShoppingCartResponse.ShoppingCart.Lines.find(line => line.ID === orderLineId);

    if (isNil(orderLineDiscountLine)) {
      this.logger.warn('getSelectedProductRequirements no matching orderLineDiscountLine could be found');
      return null;
    }

    const currentSelectedProductIdOrderLine = getShoppingCartResponse.ShoppingCart.Lines.find(line => {
      return line.ID === orderLineDiscountLine.ParentID;
    });

    // If there is no currently selected product, we will return null;
    //
    if (isNil(currentSelectedProductIdOrderLine)) {
      return null;
    }

    // Now we have the order line of the selected product we want to check if it exists in the requirements
    //
    const matchingOrderLineRequirement = getShoppingCartResponse.AdditionalOrderData?.RequiredData?.OrderLines[currentSelectedProductIdOrderLine.ID];

    // If there is no matching order line requirement we will return null
    //
    if (isNil(matchingOrderLineRequirement)) {
      return null;
    }

    const insufficientStockRequirement = this.getRequirement(matchingOrderLineRequirement, 'InsufficientStock');

    const restrictedShippingRequirement = this.getRequirement(matchingOrderLineRequirement, 'RestrictedShipping');

    const giftLineRequirement: TGiftLineRequirements = {
      hasInsufficientStock: insufficientStockRequirement && !insufficientStockRequirement.IsValid,
      hasShippingRestriction: restrictedShippingRequirement && !restrictedShippingRequirement.IsValid
    };

    return giftLineRequirement;
  }

}
