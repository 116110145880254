<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'return.to.supplier.task.set.product.error.title' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button navPop>
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-refresher (ionRefresh)="refresh()" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <p class="ion-no-margin ion-margin-bottom">
    {{ errorMessageI18nKey | translate }}
  </p>

  <ion-card *ngFor="let viewProduct of viewProducts$ | async" class="ion-padding ion-no-margin">
    <eva-product-card-common [showImage]="false" [product]="viewProduct">
      <b>{{viewProduct.quantity}}</b>
    </eva-product-card-common>
  </ion-card>
</ion-content>

<ion-footer>
  <ion-button expand="full" color="primary" (click)="dismiss()">
    {{ 'action.close' | translate }}
  </ion-button>
</ion-footer>
