import { Injectable, OnDestroy } from '@angular/core';
import { store, verifyCustomer, getShoppingCart, getShoppingCartInfo, forceVerifyCustomer, elevationHelper, IElevationEvent, verifyOrder } from '@springtree/eva-sdk-redux';
import { map, first } from 'rxjs/operators';
import isNotNil from '../../shared/operators/isNotNil';
import { Logger, ILoggable } from '../../shared/decorators/logger';
import { TranslateService } from '@ngx-translate/core';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';

/** This provider will be responsible for calling the `verifyCustomer` and showing the correct feedback */
@Logger('[verify-customer-provider]')
@Injectable()
export class VerifyCustomerProvider implements ILoggable {

  logger: Partial<Console>;

  constructor(private toastCtrl: EvaToastController, private $translate: TranslateService) {}

  /**
   * This method is used the verify a customer who lost the Order QR code and the employee
   * needs an approval permission from a manager to be able to relese the order.
   */
  async forceVerify(request: Partial<EVA.Core.ForceVerifyCustomer>, elevationToken?: string, verifyOrder?:boolean) {

    const [forceVerifyAction, forceVerifyPromise] = forceVerifyCustomer.createFetchAction({
      ...request
    }, null, undefined, null, elevationToken);

    store.dispatch(forceVerifyAction);

    try {
      await forceVerifyPromise;

      const currentShoppingCartId: number = await getShoppingCart.getResponse$().pipe(
        isNotNil(),
        map(getShoppingCartResponse => getShoppingCartResponse.ShoppingCart.ID),
        first()
      ).toPromise();

      if (currentShoppingCartId === request.OrderID) {
        // We're calling getShoppingCartInfo directly in order to set off the chain of events that will ensure the cartstate is up to date.
        // This is one of only two places where we may manually dispatch getShoppingCartInfo, the other case being partial payments.
        //
        const [cartInfoAction] = getShoppingCartInfo.createFetchAction();

        store.dispatch(cartInfoAction);

        this.toastCtrl.create({
          title: this.$translate.instant(verifyOrder ? 'verify.order' : 'verify.customer'),
          message: this.$translate.instant(verifyOrder ? 'verify.order.success' : 'verify.customer.success')
        }).present();
      }

    } catch (error) {
      this.logger.error('error verifying customer', error);
    }

    return forceVerifyPromise;
  }

  /**
   * This method is used when the customer presents the Order QR code
   * to be able to pickup the order from the store. Just to verify customer QR.
   */
  async verifyCustomer(request: Partial<EVA.Core.VerifyCustomer>) {
    const [verifyAction, verifyPromise] = verifyCustomer.createFetchAction({
      ...request
    });

    store.dispatch(verifyAction);

    try {
      await verifyPromise;

      const currentShoppingCartId: number = await getShoppingCart.getResponse$().pipe(
        isNotNil(),
        map(getShoppingCartResponse => getShoppingCartResponse.ShoppingCart.ID),
        first()
      ).toPromise();

      if (currentShoppingCartId === request.OrderID) {
        // We're calling getShoppingCartInfo directly in order to set off the chain of events that will ensure the cartstate is up to date.
        // This is one of only two places where we may manually dispatch getShoppingCartInfo, the other case being partial payments.
        //
        const [cartInfoAction] = getShoppingCartInfo.createFetchAction();

        store.dispatch(cartInfoAction);

        this.toastCtrl.create({
          title: this.$translate.instant('verify.customer'),
          message: this.$translate.instant('verify.customer.success')
        }).present();
      }

    } catch (error) {
      this.logger.error('error verifying customer', error);

      this.toastCtrl.create({
        title: this.$translate.instant('verify.customer'),
        message: this.$translate.instant('not.a.valid.badge')
      }).present();
    }

    return verifyPromise;
  }

  /**
   * This method is used to verify an order, in case the employee has the rights
   */
  async verifyOrder(request: Partial<EVA.Core.VerifyOrder>, elevationToken?: string) {
    const [forceVerifyAction, forceVerifyPromise] = verifyOrder.createFetchAction({
      ...request
    }, null, undefined, null, elevationToken);

    store.dispatch(forceVerifyAction);

    try {
      await forceVerifyPromise;

      const currentShoppingCartId: number = await getShoppingCart.getResponse$().pipe(
        isNotNil(),
        map(getShoppingCartResponse => getShoppingCartResponse.ShoppingCart.ID),
        first()
      ).toPromise();

      if (currentShoppingCartId === request.OrderID) {
        // We're calling getShoppingCartInfo directly in order to set off the chain of events that will ensure the cartstate is up to date.
        // This is one of only two places where we may manually dispatch getShoppingCartInfo, the other case being partial payments.
        const [cartInfoAction] = getShoppingCartInfo.createFetchAction();

        store.dispatch(cartInfoAction);

        this.toastCtrl.create({
          title: this.$translate.instant(verifyOrder ? 'verify.order' : 'verify.customer'),
          message: this.$translate.instant(verifyOrder ? 'verify.order.success' : 'verify.customer.success')
        }).present();
      }

    } catch (error) {
      this.logger.error('error verifying customer', error);
    }

    return forceVerifyPromise;
  }

}
