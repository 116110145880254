import { animate, group, keyframes, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { easing } from 'src/app/shared/animations';


const GREEN = '#5dca86';
const RED = '#ec5868';

export const dotAnimationTrigger = trigger('dotAnimationTrigger', [
  state('1', style({
    opacity: 1,
    transform: 'translate(75px, 75px) scale(21)'
  })),
  transition('0 => 1', [
    style({
      opacity: 1
    }),
    animate(`300ms ${easing.enter}`, style({
      transform: 'translate(75px, 75px) scale(21)'
    }))
  ])
]);

export const dotsAnimationTrigger = trigger('dotsAnimationTrigger', [
  transition('* => failure', [
    group([
      query('.inner-dot', [
        animate(`300ms ${easing.enter}`, style({
          fill: RED
        }))
      ]),
      query('svg', [
        animate(`700ms ${easing.enter}`, keyframes([
            style({
              transform: 'translate(-15px)'
            }),
            style({
              transform: 'translate(15px)'
            }),
            style({
              transform: 'translate(-15px)'
            }),
            style({
              transform: 'translate(15px)'
            }),
            style({
              transform: 'translate(-15px)'
            }),
            style({
              transform: 'translate(0px)'
            }),
        ]))
      ])
    ]),
    query('.inner-dot', [
      animate(`150ms ${easing.leave}`, style({
        fill: RED,
        transform: 'translate(75px, 75px) scale(1)',
      })),
      style({
        opacity: 0
      })
    ])
  ]),
  transition('* => success', [
    group([
      query('.inner-dot', [
        style({
          fill: GREEN
        }),
        animate(`350ms 150ms ${easing.enter}`, style({
          fill: GREEN
        }))
      ]),
      query('svg', stagger(`100ms`, [
          animate(`500ms`, keyframes([
            style({
              transform: 'translateY(-20px)'
            }),
            style({
              transform: 'translateY(5px)'
            }),
            style({
              transform: 'translateY(0)'
            })
          ]))
      ]))
    ]),
    query('.inner-dot', [
      animate(`150ms ${easing.leave}`, style({
        fill: GREEN,
        transform: 'translate(75px, 75px) scale(1)',
      })),
      style({
        opacity: 0
      })
    ])
  ])

]);
