import { IViewProduct } from "src/app/components/product-card-common/product-card-common";
import { IPaginatedView } from "src/app/directives/page-dto/page-dto";
import { IViewUnitOfMeasure } from "../../components/unit-of-measures-feedback/unit-of-measures-feedback.component";

export interface IReturnToSupplierSelection {
  // The value will represent the quantity selected
  [productId: string]: number;
}

export enum ReturnToSupplierSelectionType {
  Pick,
  Exclude
}

export enum ReturnToSupplierUIComponent {
  mainPage,
  searchModal,
  summaryModal,
  summaryTotal
}

export interface IReturnToSupplierTaskViewItem extends IViewProduct {
  orderlineId: number;
  quantity: number;
  stockLabelId: number;
   /** @see https://n6k.atlassian.net/browse/OPTR-12354 */
   viewUnitOfMeasures?: IViewUnitOfMeasure[];
}

export type TRmaTaskPaginationType = IPaginatedView<EVA.Core.ListReturnToSupplierTaskOrderLinesResponse, IReturnToSupplierTaskViewItem>;

export interface ReturnToSupplierTaskSetShipmentButtonConfig {
  show: boolean;
  text: string;
}
