import { SupportedTaskGroups, TTaskSubgroupType, TNonEmptyArray, TSupportedTaskGroupType } from './types';
import { cloneDeep } from 'lodash';

export interface ITaskSubgroupMapping {
  category?: TTaskSubgroupType;
  subGroupsMappingType: 'task' | 'subtask';
  tasks: TNonEmptyArray<TTaskSubgroupType>;
}

export interface ITaskGroupSingularMapping {
  key: TSupportedTaskGroupType;
  mapping: TNonEmptyArray<ITaskSubgroupMapping>;
}

export type TTaskGroupMapping = {
  [key in keyof typeof SupportedTaskGroups]: TNonEmptyArray<ITaskSubgroupMapping>;
};

const mappings: TTaskGroupMapping = {
  'FullStockCountTasksGroup': [{
    category: 'FullStockCount',
    subGroupsMappingType: 'task',
    tasks: ['FullStockCount', 'FullStockCountLabel']
  }],
  'ReceiveGoodsTasksGroup': [{
    subGroupsMappingType: 'task',
    tasks: ['ReceiveShipment']
  }],
  'ReservationTasksGroup': [{
    subGroupsMappingType: 'task',
    tasks: ['StockReservation']
  }],
  'ShipFromStoreTasksGroup': [{
    subGroupsMappingType: 'subtask',
    tasks: ['Pick']
  }, {
    subGroupsMappingType: 'subtask',
    tasks: ['Ship']
  }, {
    subGroupsMappingType: 'subtask',
    tasks: ['Pack']
  }, {
    subGroupsMappingType: 'subtask',
    tasks: ['Deliver']
  }, {
    subGroupsMappingType: 'subtask',
    tasks: ['Print']
  }],
  'StockMovementTasksGroup': [{
    subGroupsMappingType: 'task',
    tasks: ['StockMovementFollowUp']
  }, {
    subGroupsMappingType: 'task',
    tasks: ['ReservationCleanup']
  }, {
    subGroupsMappingType: 'task',
    tasks: ['ReturnToSupplier']
  }],
  'CycleCountTasksGroup': [{
    subGroupsMappingType: 'task',
    tasks: ['ZonedCycleCount']
  }, {
    subGroupsMappingType: 'task',
    tasks: ['ZonedCycleCountPreCount']
  }],
  'RunnerTasksGroup': [{
    subGroupsMappingType: 'task',
    tasks: ['StockReplenishment']
  }],
  'CompliancyTasksGroup': [{
    subGroupsMappingType: 'subtask',
    tasks: ['YearlyControlDocument']
  }, {
    subGroupsMappingType: 'subtask',
    tasks: ['MonthlyControlDocument']
  }, {
    subGroupsMappingType: 'subtask',
    tasks: ['StationClosingDocument']
  }, {
    subGroupsMappingType: 'subtask',
    tasks: ['StationInitializationDocument']
  }],
  'Print': [{
    subGroupsMappingType: 'task',
    tasks: ['Print']
  }],
  'PrintPriceLabel': [{
    subGroupsMappingType: 'task',
    tasks: ['PrintPriceLabel']
  }],
  'OperationalGroup': [{
    subGroupsMappingType: 'task',
    tasks: ['SecondChanceProductRegistration']
  }],
};

/**
 * Gets the default task group mapping
 */
export function getDefaultTaskGroupMapping(): TTaskGroupMapping {
  return cloneDeep(mappings);
}

/**
 * Allows override of default task group mapping
 */
export function overrideDefaultTaskGroupMapping(
  defaultTaskGroupMapping: TTaskGroupMapping = getDefaultTaskGroupMapping(), items: ITaskGroupSingularMapping[]) {
  const writableDefaultTaskGroupMapping = { ...defaultTaskGroupMapping };
  for (const item of items) {
    writableDefaultTaskGroupMapping[item.key] = item.mapping;
  }

  return writableDefaultTaskGroupMapping;
}



