<ion-header>
  <ion-toolbar>
    <ion-title> {{ 'decline.task.title' | translate }} </ion-title>
    <ion-buttons slot="end">
      <ion-button navPop>
        <ion-icon name="close" ></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-no-padding">

  <p class="ion-no-margin ion-padding-horizontal ion-padding-top">
    {{'decline.task.message' | translate}}
  </p>

  <ion-item class="ion-margin-top">
    <ion-label position="floating">
      {{'decline.task.reason' | translate}}
    </ion-label>
    <ion-select [formControl]="declineReasonCtrl">
      <ion-select-option *ngFor="let reason of declineReasons$ | async" [value]="reason.ReasonID">
        {{ reason.Name }}
      </ion-select-option>
    </ion-select>
  </ion-item>

</ion-content>

<ion-footer class="vertical-buttons">
  <ion-button expand="full" color="light" (click)="dontDecline()">
    {{ 'action.dont.decline' | translate }}
  </ion-button>
  <ion-button expand="full" (click)="declineTask()">
    {{ 'action.decline' | translate }}
  </ion-button>
</ion-footer>
