import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { noop } from 'lodash-es';
import { first, map, take } from 'rxjs/operators';
import { OverlayService } from './overlay.service';
import { EvaToast } from './eva-toast';
import { IEvaToastOptions, EvaToastOptions } from './eva-toast-interface';

@Injectable({
  providedIn: 'root',
})
export class EvaToastController {
  public logger: Partial<Console>;

  /** Whether to use a queue system or present all toasts at the same time */
  private queueToasts = true;

  /** The toast that is currently present */
  private presentToast: EvaToast;

  constructor(
    private factoryResolver: ComponentFactoryResolver,
    private overlayProvider: OverlayService,
    private injector: Injector,
    private appRef: ApplicationRef,
  ) {}

  create(opts: IEvaToastOptions = {}): EvaToast {
    /** This will resolve immediately depending on whether a toast is present or not */
    let turnInQueue: Promise<any> = Promise.resolve();

    // Means theres a present toast, we will assign its dismiss stream as a promise to the turnInQueue
    // variable so the current toasts waits for it in the .present()
    //
    if (this.presentToast && this.queueToasts) {
      turnInQueue = this.presentToast.onDidDismiss().pipe(first(), map(noop)).toPromise();
    }

    // We want to merge the default options with the users options
    //
    const options = { ...new EvaToastOptions(), ...opts };

    const toast = new EvaToast(
      this.overlayProvider,
      options,
      this.factoryResolver,
      this.injector,
      this.appRef,
      turnInQueue,
    );

    this.presentToast = toast;

    // Removing the current present toast
    //
    toast
      .onDidDismiss()
      .pipe(take(1))
      .subscribe(() => (this.presentToast = null));

    return toast;
  }

  /** Simple method to show a toast without passing in a configuration object  */
  showMessage(message: string) {
    const toast = this.create({ message });
    return toast.present();
  }

  /** Whether to enable queuing or not */
  enableQueuing(enable: boolean) {
    this.queueToasts = enable;
  }
}
