<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'shipping.restriction' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding-horizontal">
  <h6 *ngIf="restrictedOrderLines.length > 1"> {{ 'shipping.restriction.paragraph.plural' | translate }} </h6>
  <h6 *ngIf="restrictedOrderLines.length === 1"> {{ 'shipping.restriction.paragraph' | translate }} </h6>
  <ion-card *ngFor="let restrictedOrderline of restrictedOrderLines" class="restricted-line">
    <ion-card-content>
      <ion-row class="ion-justify-content-between">
        <ion-col size="8">
          <h2>{{ restrictedOrderline.Description }}</h2>
        </ion-col>
        <ion-col *ngIf="!(restrictedOrderline.QuantityOpen | isNil)" size="4" class="ion-text-end">
          <h2>{{ 'quantity.shorthand' | translate }}: {{ restrictedOrderline.QuantityOpen }}</h2>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <small class="id">{{ restrictedOrderline.Product.CustomID }}</small>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <p color="danger"> {{ 'product.shipping.restricted' | translate }}</p>
        </ion-col>
      </ion-row>
    </ion-card-content>
  </ion-card>
</ion-content>
<ion-footer>
  <ion-button [disabled]="cancelOrderLineIsFetching$ | async" expand="full" color="primary" class="modal-button" (click)="deleteRestrictedProducts()" type="submit">
    <span *ngIf="restrictedOrderLines.length > 1">{{ 'cart.lines.remove' | translate }}</span>
    <span *ngIf="restrictedOrderLines.length === 1">{{ 'cart.line.remove' | translate }}</span>
  </ion-button>
</ion-footer>
