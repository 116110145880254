import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getShoppingCart, getStockDetailsForProduct } from '@springtree/eva-sdk-redux';
import { map, take } from 'rxjs/operators';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { Logger, ILoggable } from '../../shared/decorators/logger';
import isNotNil from '../../shared/operators/isNotNil';

/**
 * Its a business requirement because of RMA orders to check the quantityOnHand and compare it to the amount a user has in their cart
 * We will do so as a side effect of addProductToOrder
 * @see https://eva2015.atlassian.net/browse/OPTR-671
 */
@Logger('[eva-product-stock-check-provider]')
@Injectable()
export class EvaProductStockCheckProvider implements ILoggable {

  logger: Partial<Console>;

  private readonly TOAST_DURATION = 10000;

  constructor(
    private toastCtrl: EvaToastController,
    private $translate: TranslateService
  ) { }

  /**
   * checks the availability of a given product and checks how much we have of it in the cart, based on those conditions
   * multiple things will be done outlined in the jira ticket
   * @see https://eva2015.atlassian.net/browse/OPTR-671
   * @param productId the id of the product that was just added
   */
  async checkAvailability( productId: number ) {
    const cart = await getShoppingCart.getResponse$().pipe(
      isNotNil(),
      map( response => response.ShoppingCart ),
      isNotNil(),
      take(1)
    ).toPromise();

    const matchingOrderLine = cart.Lines
      .filter(line => Boolean(line.Product))
      .find(line => line.Product.ID === productId);

    if (!matchingOrderLine) {
      this.logger.error(`Tried to find ${productId} in cart without success, returning early`);
      return;
    }

    const product = matchingOrderLine.Product;

    // We want to check the `TotalQuantityToShip` over the entire order of this product
    // 1- Filter out lines without products
    // 2- Filter out products without a matching id
    // 3- Mapping the lines TotalQuantityToShip
    // 4- Reducing that into one value by summing them all up
    //
    const totalQuantityToShip = cart.Lines
      .filter( line => Boolean(line.Product) )
      .filter( line => line.Product.ID === productId )
      .map( line => line.TotalQuantityToShip )
      .reduce( (prev, curr) => prev + curr, 0);

    const [action] = getStockDetailsForProduct.createFetchAction({
      ProductID: productId
    });

    getStockDetailsForProduct.fetchData(action).then(getStockDetailsForProductResponse => {
      const quantityOnHand: number = getStockDetailsForProductResponse.QuantityOnHand;

      if (quantityOnHand === 0) {
        this.showProductDoesntHaveStockWarning(product.PrimitiveName);
      } else if (totalQuantityToShip > quantityOnHand) {
        this.showProductDoesntHaveEnoughStockWarning(quantityOnHand, product.PrimitiveName);
      }
    });
  }

  private showProductDoesntHaveEnoughStockWarning(actualStock: number, productName: string) {
    // There are only amounOfProductsAvailable available. Adviced to check the stock of product: productName

    this.toastCtrl.create({
      message: this.$translate.instant('product.insufficient.stock', {
        actualStock,
        productName
      }),
      duration: this.TOAST_DURATION
    }).present();
  }

  private showProductDoesntHaveStockWarning(productName: string) {
    this.toastCtrl.create({
      message: this.$translate.instant('product.no.stock', {
        productName
      }),
      duration: this.TOAST_DURATION
    }).present();
  }

}
