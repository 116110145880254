import { Injectable } from '@angular/core';
import { Logger, ILoggable } from '../../shared/decorators/logger';
import { TranslateService } from '@ngx-translate/core';
import { get, isString } from 'lodash';
import { TimeoutError } from '@springtree/eva-sdk-redux';
import { IEvaToastError } from 'src/app/modules/eva-toast/eva-toast-interface';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';


export interface IErrorFeedback extends IEvaToastError {
  message: string;
}

/**
 * This service will take any fetch request promises and construct a meaning error out of them
 * Error handling is complex due to the different scenarios than can occur.
 * This service will try to handle all possible scenarios in which a requst could fail
 *
 * @example
 * ```typescript
 * const toast = this.toastCtrl.create();
 *
 * fetchPromise.catch( async error => {
 *   const evaError = await this.$evaErrorGenerator.constructFailureFeedback(error, feedback.failure);
 *
 *   toast.setData(evaError);
 * })
 * .then(() => toast.present());
 * ```
 */
@Logger('[eva-error-generator-provider]')
@Injectable()
export class EvaErrorGeneratorProvider implements ILoggable {

  public logger: Partial<Console>;

  constructor(
    private $translate: TranslateService,
    private evaToastCtrl: EvaToastController
  ) { }

  /**
   * Constructs an error message
   * @param error The error returned by the .catch
   * @param prefferedMessage the message we would like to show
   */
  public async constructFailureFeedback( error: any, prefferedMessage?: string ): Promise<IErrorFeedback> {
    const feedback: IErrorFeedback = {
      message: '',
      error: null
    };

    if ( error instanceof Response ) {
      try {

        let evaError: EVA.Core.EmptyResponseMessage;

        // Check if we need to read the body of the error, or if it's already been read
        //
        if ( error.bodyUsed ) {
          evaError = get( error, 'response' );
        } else {
          evaError = await error.json();
        }

        const exception: EVA.Core.ServiceError = evaError.Error;
        feedback.error = exception;
        feedback.message = exception?.Message;

      } catch ( errorParsingJSON ) {
        this.logger.error('error parsing json', errorParsingJSON);
      }
    }

    // We want to set the preffered message before offline detection. Because overriding evas backend error is acceptable
    // but overriding the offline error is not
    //
    if (prefferedMessage) {
      feedback.message = prefferedMessage;
    }

    if ( error instanceof TypeError  && error.message === 'Failed to fetch' ) {

        // This means the user is probably offline
        //
        feedback.message = this.$translate.instant('offline.warning');
    } else if ( error instanceof TimeoutError ) {
      feedback.message = this.$translate.instant('connection.timeout');
    }

    return feedback;
  }

  /** Shows the error using the eva toast and the error generator */
  public async showTranslatedError(error: any, i18nMessage: string|[string, any]) {

    const [translateKey, translateOptions] = isString(i18nMessage) ? [i18nMessage, null] : i18nMessage;

    const errorFeedback = await this.constructFailureFeedback(
      error,
      this.$translate.instant(translateKey, translateOptions)
    );

    this.evaToastCtrl
      .create(errorFeedback)
      .present();
  }
}
