import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { getShoppingCart, OrderMode } from '@springtree/eva-sdk-redux';
import { isNil } from 'lodash';
import { isEmpty } from 'lodash-es';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, first, map, take } from 'rxjs/operators';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import isNotNil from '../../shared/operators/isNotNil';
import { EvaErrorGeneratorProvider } from '../eva-error-generator/eva-error-generator';
import { OrderLifecycleService } from '../order-lifecycle/order-lifecycle.service';

interface IInterLogisticsCreateOpts {
  /** will create a new order even if there is one */
  forceCreate: boolean;
}

@Logger('[inter-logistics-switch-provider]')
@Injectable()
export class InterLogisticsSwitchProvider implements ILoggable {

  public logger: Partial<Console>;

  public characteristics$: Observable<number> = getShoppingCart.getState$().pipe(
    filter(state => !state.isFetching),
    map(state => state.response),
    isNotNil(),
    map(response => response.ShoppingCart.Characteristics)
  );

  public orderMode$: Observable<OrderMode> = getShoppingCart.getState$().pipe(
    filter( state => isNil(state.checkoutStatus.mode) === false ),
    map( state => state.checkoutStatus.mode ),
    distinctUntilChanged()
  );

  public get isRma(): Promise<boolean> {
    const isRma = this.characteristics$.pipe(
      take(1),
      map( characteristics => Boolean(characteristics & 128) )
    ).toPromise();

    return isRma;
  }

  public get isInterbranch(): Promise<boolean> {
    const isInterbranch = this.characteristics$.pipe(
      take(1),
      map(characteristics => Boolean(characteristics & 4))
    ).toPromise();
    return isInterbranch;
  }

  constructor(
      private toastCtrl: EvaToastController,
      private $translate: TranslateService,
      private $evaErrorGenerator: EvaErrorGeneratorProvider,
      private loadingCtrl: LoadingController,
      private alertCtrl: AlertController,
      private $orderLifecycleService: OrderLifecycleService,
    ) {
  }

 /**
  * Creates an rma order out of a given order id and shows proper feedback
  * */
  async createRmaOrder(opts?: IInterLogisticsCreateOpts ) {
    const orderSwitchConfirmed = await this.orderSwitchConfirmed();

    if (!orderSwitchConfirmed) {
      return;
    }

    // It doesn't make a whole lot of sense to switch from RMA to RMA
    //
    if ( (await this.isRma) && !opts?.forceCreate ) {
      this.logger.warn('createRmaOrder called with an already rma order.. returning early');
      return;
    }

    const [unsetPromise] = this.$orderLifecycleService.unsetOrderId();
    try {
      await unsetPromise;

      this.$orderLifecycleService.setRequestedCharacteristics(OrderMode.returnToSupplier);

      const toast = this.toastCtrl.create();

      this.logger.log('Modifying order to return to supplier order success');
      const message: string = this.$translate.instant('order.type.modify.success', {
        value: this.$translate.instant('order.type.return.to.supplier')
      });
      toast.setMessage(message);
      toast.present();
    } catch (error) {
      const evaFeedback = await this.$evaErrorGenerator.constructFailureFeedback(error, this.$translate.instant('order.type.modify.fail'));

      this.toastCtrl.create(evaFeedback).present();
    }
  }

  /**
   * Creates an interbranch order out of a given order id and shows proper feedback
   */
  async createInterbranchOrder(opts?: IInterLogisticsCreateOpts) {
    const orderSwitchConfirmed = await this.orderSwitchConfirmed();

    if (!orderSwitchConfirmed) {
      return;
    }

    // It doesn't make a whole lot of sense to switch from Interbranch to Interbranch
    //
    if ((await this.isInterbranch) && !opts?.forceCreate) {

      this.logger.warn('createInterbranchOrder called with an already interbranch order.. returning early');

      return;
    }

    const [unsetPromise] = this.$orderLifecycleService.unsetOrderId();

    try {
      await unsetPromise;

      this.$orderLifecycleService.setRequestedCharacteristics(OrderMode.interbranch);

      const toast = this.toastCtrl.create();

      this.logger.log('Modifying order to return to interbranch success');

      const message: string = this.$translate.instant('order.type.modify.success', {
        value: this.$translate.instant('order.type.interbranch') as string
      });

      toast.setMessage(message);
      toast.present();
    } catch (error) {
      const evaFeedback = await this.$evaErrorGenerator.constructFailureFeedback(error, this.$translate.instant('order.type.modify.fail'));

      this.toastCtrl.create(evaFeedback).present();
    }
  }

  async createSalesOrder() {
    const orderSwitchConfirmed = await this.orderSwitchConfirmed();

    if (!orderSwitchConfirmed) {
      return;
    }

    const loader = await this.loadingCtrl.create({
      message: this.$translate.instant('order.type.modifying')
    });

    const [unsetPromise] = this.$orderLifecycleService.unsetOrderId();

    loader.present();

    try {
      await unsetPromise;
      this.$orderLifecycleService.setRequestedCharacteristics(OrderMode.sale);

      this.toastCtrl.create({
        message: this.$translate.instant('order.type.modify.success', {
          value: this.$translate.instant('sales')
        })
      }).present();
    } catch (error) {
      const evaFeedback = await this.$evaErrorGenerator.constructFailureFeedback(error, this.$translate.instant('order.type.modify.fail'));

      this.toastCtrl.create(evaFeedback).present();
    } finally {
      loader.dismiss();
    }
  }

  /** whenever the order is completed too soon,  */
  async onOrderPrematureCompletion(orderMode: OrderMode) {
    const orderTypesMapping: { [key: string]: { label: string, handler: Function } } = {
      [OrderMode.interbranch]: {
        label: this.$translate.instant('order.type.interbranch'),
        handler: () => this.createInterbranchOrder({ forceCreate: true })
      },
      [OrderMode.returnToSupplier]: {
        label: this.$translate.instant('order.type.return.to.supplier'),
        handler: () => this.createRmaOrder({ forceCreate: true })
      }
    };
    const alert = await this.alertCtrl.create({
      header: this.$translate.instant('interlogistics.empty.alert.title'),
      message: this.$translate.instant('interlogistics.empty.alert.message', { orderType: orderTypesMapping[orderMode].label }),
      buttons: [{
        text: this.$translate.instant('action.no'),
        role: 'cancel'
      },
      {
        text: this.$translate.instant('action.yes'),
        handler: () => {
          orderTypesMapping[orderMode].handler();
        }
      }]
    });

    alert.present();
  }

  /**
   * we will show an alert to users whenever they have items in their cart and they are look to switch the order type
   * this method will return a boolean whether the switch has been confirmed or not.
   * */
  async orderSwitchConfirmed(): Promise<boolean> {
    const cartLines = await getShoppingCart.getState$().pipe(
      filter( state => !state.isFetching),
      map( state => state.response?.ShoppingCart?.Lines),
      first()
    ).toPromise();

    if ( isEmpty(cartLines) ) {
      return true;
    }

    const alert = await this.alertCtrl.create({
      header: this.$translate.instant('are.you.sure'),
      message: this.$translate.instant('start.new.order.description'),
      buttons: [
        {
          text: this.$translate.instant('action.cancel'),
          role: 'cancel',
        },
        {
          text: this.$translate.instant('yes'),
          role: 'confirm',
        }
      ]
    });

    alert.present();

    const dismissEvent = await alert.onDidDismiss();

    const dismissRole = dismissEvent.role;

    const switchConfirmed = dismissRole === 'confirm';

    return switchConfirmed;
  }
}
