import { Inject, Injectable, InjectionToken } from '@angular/core';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ContentfulClientApi, CreateClientParams, createClient, EntryCollection } from 'contentful';
import { ModalController } from '@ionic/angular';
import { isNil } from 'lodash';
import { gt as semverGreaterThan } from 'semver';
import { WhatsNewComponentModal } from './whats-new-modal/whats-new-modal';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';

/** The contentful type we will use to fetch the whats new data  */
export const CONTENTFUL_WHATS_NEW_CONTENT_TYPE = new InjectionToken<string>('contentful.whats.new.content.type');

export interface IWhatsNewParams {
  version: string;
  userId: number;
  languageId: string;
  organizationUnitId: number;
}

export interface INewScreenSlides {
  slides: INewScreenSlide[];
  version: string;
}

export interface INewScreenSlide {
  heroImage: string;
  header: string;
  description: string;
}

interface IWhatsNewStorageData {
  version: string;
  opened: boolean;
}

interface IEntryIdentifierOpts {
  languageId: string;
  userId: number;
  organizationUnitId: number;
}

interface IMemoizationData {
  id: string;
  data: Promise<INewScreenSlides>;
}

/**
 * @see https://eva2015.atlassian.net/browse/OPTR-2433
 */
@Logger('[contentful-provider]')
@Injectable()
export class ContentfulProvider implements ILoggable {

  logger: Partial<Console>;

  private client: ContentfulClientApi;

  /**
   * What the local storage key prefix will be
   */
  private readonly LOCAL_STORAGE_KEY_PREFIX = 'whatsNewData';

  /**
   * When the consumer checks whether they should open the whats new modal or not, the data will be fetched
   * we don't want re-fetch said data when the modal is opened so we will store the promise here for later usage
   */
  private getWhatsNewCarrouselPromise: IMemoizationData;

  constructor(
    private modalCtrl: ModalController,
    @Inject(CONTENTFUL_WHATS_NEW_CONTENT_TYPE) private contentFulType: string
  ) { }

  initialise(config: CreateClientParams) {
    this.client = createClient(config);
  }

  public isInitialised() {
    return !isNil(this.client);
  }

  /**
   * Decides whether there is news to show or not
   * @param currentVersion version of the package.json should respect the semver format
   */
  async shouldOpenWhatsNewModal(whatsNewOptions: IWhatsNewParams): Promise<boolean> {

    const {
      version: currentVersion,
      languageId,
      organizationUnitId,
      userId,
    } = whatsNewOptions;

    try {

      const generatedEntryIdentifier = this.generateEntryIdentifier({
        languageId,
        organizationUnitId,
        userId
      });

      const localData: IWhatsNewStorageData = JSON.parse(localStorage.getItem(generatedEntryIdentifier));

      // If there is no local data, the app has never saved a `whatsNewData` key before, thus we can safely assume
      // the user has never seen the modal before
      //
      if ( isNil(localData) ) {
        return this.whatsNewContentPresentForVersion(whatsNewOptions);
      }

      const localVersion = localData.version;

      /** We have a new version of the app if the version in the package.json is greater than the one we have locally */
      const isNewVersion = semverGreaterThan(currentVersion, localVersion);

      /** We should open if there is a new version of the app, otherwise check if the modal hasn't been opened for the current version yet */
      const shouldOpen = isNewVersion ? true : !localData.opened;

      // If we should open according to the version changes and whether the current version hasn't opened yet
      // we want to check if contenful has content for this version
      //
      if ( shouldOpen ) {
        return this.whatsNewContentPresentForVersion(whatsNewOptions);
      }
    } catch (e) {
      this.logger.error('Error parsing whatsNewData', e);
    }
  }

  async openWhatsNewModal(whatsNewOptions: IWhatsNewParams) {
    const { version, languageId, organizationUnitId, userId } = whatsNewOptions;

    const identefier = this.generateEntryIdentifier(whatsNewOptions);

    let whatsNewCarrouselData: INewScreenSlides;

    // If we already have this data locally, we will use
    //
    if ( this.getWhatsNewCarrouselPromise && this.getWhatsNewCarrouselPromise.id === identefier ) {
      whatsNewCarrouselData = await this.getWhatsNewCarrouselPromise.data;
    } else {
      // If we don't have this data locally we will fetch it
      //
      whatsNewCarrouselData = await this.getWhatsNewCarrousel(version, organizationUnitId, languageId);
    }

    const modal = await this.modalCtrl.create({
      component: WhatsNewComponentModal,
      componentProps: {
        whatsNewData: whatsNewCarrouselData
      },
      backdropDismiss: false,
      cssClass: 'whats-new-component-modal',
    });

    modal.present().then(() => {
      // This will ensure the should open doesn't return true again
      //
      const data = {
        opened: true,
        version
      } as IWhatsNewStorageData;

      const generatedEntryIdentifier = this.generateEntryIdentifier({
        languageId,
        organizationUnitId,
        userId
      });

      localStorage.setItem(generatedEntryIdentifier, JSON.stringify(data));
    }).catch( error => this.logger.error('Error opening the whats new modal', error));

    const promise = modal.onDidDismiss<void>();

    return promise;
  }

  private async getWhatsNewCarrousel(version: string, organizationUnitId: number, languageId: string): Promise<INewScreenSlides|null> {

    if (!this.isInitialised()) {
      this.logger.warn('The contentful API has not been initalized yet');
      return;
    }

    try {
      const requestPayload = {
        content_type: this.contentFulType,
        include: 5,
        'fields.version': version,
        'fields.organizationUnitId': organizationUnitId,
        'locale': languageId
      };

      const entries = await this.client.getEntries(requestPayload);

      this.logger.log(entries);

      // If there are 0 entries, we will return null
      //
      if ( entries.total === 0 ) {
        // We will retry once without an OU ID
        //
        if ( !isNil(organizationUnitId) ) {
          this.logger.log(`No entry found for organizationUnitID ${organizationUnitId} retrying without an organizationUnitId`);

          return this.getWhatsNewCarrousel(version, null, languageId);
        } else {
          return null;
        }

      }
      const parsedEntry = this.parseContentfulEntry(entries);

      return parsedEntry;
    } catch (e) {
      this.logger.warn('error getting the whats new carousel', e);
    }
  }


  /**
   * Checks if this version has whats new content
   */
  private async whatsNewContentPresentForVersion(whatsNewOptions: IWhatsNewParams) {
    const { version, organizationUnitId, languageId } = whatsNewOptions;

    const slidesPromise = this.getWhatsNewCarrousel(version, organizationUnitId, languageId);

    const identefier = this.generateEntryIdentifier(whatsNewOptions);
    // Storing this for later usage when opening the modal
    //
    this.getWhatsNewCarrouselPromise = {
      data: slidesPromise,
      id: identefier
    };

    const slidesResponse = await slidesPromise;
    /** Slides will be available if the response was not null */
    const slidesAvailable = !isNil(slidesResponse);
    return slidesAvailable;
  }

  /**
   * What the local storage key will be, the keys will look like this to ensure it works for multiple users per ou per device
   * `whatsNewData-${userId}-${organizationUnitID}-${langaugeId}`for example if a given user id is `6` the localStorageKey will be `whatsNewData-6-1-nl`
   */
  private generateEntryIdentifier(entryIdentifierOpts: IEntryIdentifierOpts) {
    const identifier = `${this.LOCAL_STORAGE_KEY_PREFIX}-${entryIdentifierOpts.userId}-${entryIdentifierOpts.organizationUnitId}-${entryIdentifierOpts.languageId}`;

    return identifier;
  }

  private parseContentfulEntry(entries: EntryCollection<any>): INewScreenSlides {
    // Taking the first entry
    //
    const [item] = entries.items;
    /** We will be showing this object to the user */
    const newScreen: INewScreenSlides = {
      version: (item.fields as any).version,
      slides: (item.fields as any).slides
        .filter((contentfulSlide: any) => !isNil(contentfulSlide.fields))
        .map((contentfulSlide: any) => {
          const slide: INewScreenSlide = {
            description: documentToHtmlString(contentfulSlide.fields.description),
            header: contentfulSlide.fields.header,
            // for iOS we need to add the https protocol
            //
            heroImage: 'https:' + contentfulSlide.fields.heroImage.fields.file.url,
          };
          return slide;
        })
    };
    return newScreen;
  }
}
