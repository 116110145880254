<ion-header class="ion-no-border ion-no-padding">
  <ion-toolbar>
    <ion-title> {{ 'verify.operation' | translate }} </ion-title>
    <ion-buttons slot="end">
      <ion-button navPop>
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="inner-content">

  <ng-container [ngSwitch]="evaVerificationStatus">

    <div *ngSwitchCase="evaVerificationStatusEnum.LOADING" class="loading-container" @fadeInOut>
      <ion-spinner></ion-spinner>
    </div>

    <p *ngSwitchCase="evaVerificationStatusEnum.LOADED" class="ion-text-center">{{ 'verify.customer.ask.manager' | translate }}</p>

    <img *ngSwitchCase="evaVerificationStatusEnum.LOADED" alt="eva pay qr code"
      [src]="verificationQRCode"
      [class.hide]="imageLoading"
      @fadeInOut>

    <!-- In case the permission is denied we show the error -->
    <eva-empty-state *ngSwitchCase="evaVerificationStatusEnum.ERROR">
      <p> {{ 'verify.customer.fail.permission' | translate }} </p>
      <ion-icon name="alert"></ion-icon>
    </eva-empty-state>

  </ng-container>
</div>
