import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { LoadingOptions } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { isNil, noop } from 'lodash';
import { Logger, ILoggable } from '../../shared/decorators/logger';
import { UnknownFunction } from 'src/app/shared/typings';

interface IEvaLoading extends HTMLIonLoadingElement {
  startLoadingTimer: UnknownFunction;
  stopLoadingTimer: UnknownFunction;
}

export interface ILoadingOptionsDebugInfo {
  className: string;
  methodName: string;
}

interface EvaLoadingOptions extends LoadingOptions {
  /** how long it takes before we show the spinner, will default to 4000 */
  timeToShowSpinner?: number;

  /** This will be used to track how long methods take, this information will be sent to a place where we can monitor it */
  debugInformation: ILoadingOptionsDebugInfo;
}
/**
 * Acts as an abstraction on top of the normal loading controller, it will show loading spinners only if a request
 * takes longer than a certain amount (1 second)
 * ```typescript
 * const spinner = this.$evaLoadingController.create();
 *
 * // Will only show the spinner if stop loading timer is not called within 2 seconds
 * //
 * spinner.startLoadingTimer();
 *
 * const [ action, fetchPromise ] = reducer.createFetchAction();
 *
 * fetchPromise
 *   .then(noop)
 *   .catch(noop)
 *   .then(() => spinner.stopLoadingTimer() );
 * ```
 */

@Logger('[eva-loading-controller-provider]')
@Injectable()
export class EvaLoadingControllerProvider extends LoadingController implements ILoggable {
  logger: Partial<Console>;

  private readonly TIME_TO_SHOW_SPINNER = 4000;

  /** Whether there is a timer active or not */
  private loaderPresent = false;

  constructor(private $translate: TranslateService) {
    super();
  }

  async create(opts?: EvaLoadingOptions): Promise<IEvaLoading> {
    const loadingOptions = opts as LoadingOptions;
    const loading = await super.create({
      message: this.$translate.instant('internet.connectivity.slow.message') as string,
      ...loadingOptions
    });

    const timeToShowSpinner = isNil(opts.timeToShowSpinner) ? this.TIME_TO_SHOW_SPINNER : opts.timeToShowSpinner;

    /** Reference to the presentation timer which will clear if the spinner is stopped within timeToShowSpinner */
    let presentationTimer: any;

    /** reference to the max presentation timer, this will be cleared if stopped is called within maxAllowedSpinnerDuration */
    let maxPresentationTimer: any;

    /** This will be used for tracking */
    const loaderId = `${Math.random()}`;

    Object.defineProperty(loading, 'startLoadingTimer', {
      value: () => {

        /**
         * The spinner cannot be shown for longer than 20 seconds, this would mean its not being stopped
         * properly or there is a very long running service in the app that we need to look into.
         */
        const maxAllowedSpinnerDuration = 20000;

        maxPresentationTimer = setTimeout(() => {
          this.logger.error(`spinner not dismissed in under ${maxAllowedSpinnerDuration} debugInfo.className=${opts.debugInformation.className} debugInfo.methodName=${opts.debugInformation.methodName}`);
        }, maxAllowedSpinnerDuration);

        presentationTimer = setTimeout(() => {
          if ( this.loaderPresent === false ) {
            this.loaderPresent = true;
            loading.present();
          }
        }, timeToShowSpinner);
      }
    });

    Object.defineProperty(loading, 'stopLoadingTimer', {
      value: () => {
        clearTimeout(presentationTimer);
        clearTimeout(maxPresentationTimer);
        loading.dismiss().then(noop).catch(noop).then(() => {
          this.loaderPresent = false;
        });
      }
    });

    return loading as IEvaLoading;
  }
}
