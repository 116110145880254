import { isEmpty, get } from 'lodash';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * This will ensure that a given stream doesnt let values that look like this `{offline: true}` go by, this is a problem in sentinel mode
 * and this operator should only be used on pages that will be available in sentinel mode
 *
 * - checkout
 * - basket
 * - product search
 * - product details
 * - order search
 * - order details
 * */
export default function isNotOffline<T>() {
  return (source: Observable<T>) => {
    return source.pipe(
      filter(value => !isEmpty(value) && !get(value, 'offline'))
    );
  };
}
