import { Component, OnInit, ViewChild } from '@angular/core';
import { getProducts, settings, store } from '@springtree/eva-sdk-redux';
import { NavParams, IonRefresher, ModalController } from '@ionic/angular';
import { noop } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IViewProduct } from 'src/app/components/product-card-common/product-card-common';
import { fadeInOut } from 'src/app/shared/animations';
import { EvaFeedback } from 'src/app/shared/decorators/eva-feedback';
import { Logger, ILoggable } from 'src/app/shared/decorators/logger';
import { IReturnToSupplierSelection, ReturnToSupplierSelectionType } from '../../return-to-supplier-task.typings';

interface IViewProductSetError extends IViewProduct {
  quantity: number;
}

/**
 * We will show this component whenever we fail to add products to the selection
 *
 * @see https://n6k.atlassian.net/browse/OPTR-11403
 */
@Logger('[return-to-supplier-task-set-products-error-modal-component]')
@Component({
  selector: 'eva-return-to-supplier-task-set-products-error',
  templateUrl: 'return-to-supplier-task-set-products-error.modal.html',
  styleUrls: ['./return-to-supplier-task-set-products-error.modal.scss'],
  animations: [fadeInOut]
})
export class ReturnToSupplierTaskSetProductsErrorModalComponent implements OnInit, ILoggable {
  logger: Partial<Console>;

  selection: IReturnToSupplierSelection = this.navParams.get('selection') ?? {};

  unselecting: boolean = this.navParams.get('unselecting') ?? false;

  totalItems = Object.keys(this.selection).length;

  selectionType: ReturnToSupplierSelectionType = this.navParams.get('selectionType');

  @ViewChild(IonRefresher) refresher: IonRefresher;

  viewProducts$: Observable<IViewProductSetError[]> = getProducts.getResponse$().pipe(
    map( getProductsResponse => {
      return Object.entries(getProductsResponse?.Products ?? {}).map(([productId, productData]) => {
        const numericProductId: number = + productId;
        const viewProduct: IViewProductSetError = {
          customId: productData.custom_id,
          productId: numericProductId,
          description: productData['display_value'],
          quantity: this.selection[numericProductId],
        };

        return viewProduct;
      });
    })
  );

  get errorMessageI18nKey(): string {
    // We have 4 scenarios
    // 1- unselecting & pick = removing from shipment
    // 2- unselecting & exclude = adding back to shipment
    // 3- selecting & pick = adding to shipment
    // 4- selecting & Exclude = removing from shipment
    //
    const selecting = !this.unselecting;

    // This if statemment will cover scenario #1 and #4
    if (
      this.selectionType === ReturnToSupplierSelectionType.Pick && this.unselecting ||
      this.selectionType === ReturnToSupplierSelectionType.Exclude && selecting
    ) {
      return 'return.to.supplier.task.unselect.product.fail.explaination';
    } else if (
      this.selectionType === ReturnToSupplierSelectionType.Pick && selecting ||
      this.selectionType === ReturnToSupplierSelectionType.Exclude && this.unselecting
    ) {
      // This will cover scenario #3 and #2
      return this.totalItems < 1 ?
          'return.to.supplier.task.select.product.fail.explaination' :
          'return.to.supplier.task.select.product.plural.fail.explaination';
    }
  }

  constructor(private navParams: NavParams, private modalCtrl: ModalController) {}

  async ngOnInit() {
    try {
      await this.fetchProducts();
    } catch (error) {
      const errorMessage = Object.entries(this.selection).map(([productId, quantity]) => `productId=${productId} quantity=${quantity}`);

      this.logger.error(`error fetching products... with ids ${errorMessage}`, error);
    }
  }

  @EvaFeedback({
    i18nFailKey: 'return.to.supplier.task.products.fetch.fail'
  })
  fetchProducts() {
    const selectionEntries = Object.entries(this.selection);

    const productIds = selectionEntries.map(([productId]) => + productId);

    const [action, promise] = getProducts.createFetchAction({
      ProductIDs: productIds,
      IncludedFields: settings.defaultProductProperties
    });

    store.dispatch(action);

    return promise;
  }

  async refresh() {
    try {
      await this.fetchProducts().catch(noop);
    } finally {
      this.refresher.complete();
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
