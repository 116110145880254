export interface IEvaToastButton {
  label: string;
  callback: () => any;
}

/** Represents the toast options the user can provide us with */
export interface IEvaToastOptions extends Partial<IEvaToastError> {
  title?: string;
  message?: string;
  duration?: number;
  showCloseHandle?: boolean;
  /** Buttons to show in the toast */
  buttons?: IEvaToastButton[];
}

/**
 * This will be generated by the eva error generator or manually, this object will be shown to the user
 * once they tap on a toast that contained an error
 */
export interface IEvaToastError {
  error: any | EVA.Core.ServiceError;
}

/** Represents what the toast options will look like internally */
export class EvaToastOptions implements IEvaToastOptions {
  title: string;
  message: string;
  duration = 3000;
  showCloseHandle = true;
  error: IEvaToastError;
  buttons: IEvaToastButton[] = [];
}
