import { get, isFunction, isNil, kebabCase } from 'lodash-es';

/** Any class decorated by the logger class should implement this interface */
export interface ILoggable {
  logger: Partial<Console>;
}

/** Kebabcases the class name */
export const kebabClassName = (ref: Object) => {
  const componetName = kebabCase(ref.constructor.name);

  return componetName;
};

export const createLogger = (prefix: string): Partial<Console> => {
  const supportedMethods = Object.keys(console) as Array<keyof Console>;
  const customConsole: Partial<Console> = {};

  supportedMethods.forEach(function (method) {
    if (isFunction(console[method])) {
      customConsole[method] = function (...callArgs: any[]) {
        if (method === 'error') {
          handleConsoleError(prefix, callArgs);
        } else {
          (console[method] as Function).apply(console, [prefix, ...callArgs]);
        }
      };
    }
  });

  return customConsole;
};

/**
 * For handling console.error calls we want to treat eva response objects differently to ensure they are readable in sentry and atatus. We will do so by checking the arguments of
 * of console.error and if they are an instance of the Response object AND its an eva error we will change the Response object into a readable string instead.
 *
 * This method contains eva-sdk-redux specific code due to its dependency on non standard property added by the sdk to the `Response` object.
 */
const handleConsoleError = (prefix: string, consoleErrorArguments: any[]) => {

  /** We will need this in order to replace the object argument with our custom error */
  const argumentInstanceOfResponseIndex = consoleErrorArguments.findIndex(consoleErrorArgument => consoleErrorArgument instanceof Response);

  const argumentInstanceOfResponse: Response | null = consoleErrorArguments[argumentInstanceOfResponseIndex];

  /** In some cases, we will just fallback to default logging */
  const defaultErrorHandler = () => console['error'].call(console, ...[prefix, ...consoleErrorArguments]);

  const isEvaError = (error: unknown): error is EVA.Core.EmptyResponseMessage => {
    // If the response contains an `Error` property and it has an `Error.Code` property we will consider it an eva error.
    //
    return !isNil(get(error, 'Error')) && !isNil(get(error, 'Error.Code'));
  };

  const handleError = (error: unknown) => isEvaError(error) ? handleEvaError(error) : defaultErrorHandler();

  const handleEvaError = (evaError: EVA.Core.EmptyResponseMessage) => {
    const evaServiceError = evaError.Error;

    const formattedError = `(EVA_ERROR: Message='${evaServiceError.Message}' Code='${evaServiceError.Code}' RequestID='${evaServiceError.RequestID}' Type='${evaServiceError.Type}')`;

    const newCallArgs = [...consoleErrorArguments];

    newCallArgs[argumentInstanceOfResponseIndex] = formattedError;

    console['error'].call(console, ...[prefix, ...newCallArgs]);
  };

  // If there is no argument that is an instance of response, we will log the error like we usually would and return early
  //
  if (!argumentInstanceOfResponse) {
    defaultErrorHandler();
    return ;
  }

  if (argumentInstanceOfResponse.bodyUsed) {
    // The eva-sdk-redux adds the json or text response as `response` property on the object, but it usually is not a
    // property that exists on javascript Response objects.
    //
    handleError(get(argumentInstanceOfResponse, 'response'));

    return;
  }

  // If the resposne body wasnt used, we will call `.json` to extract the json response and we will handle the resulting json
  //
  argumentInstanceOfResponse.json()
    .then((error: unknown) => handleError(error) )
    .catch(() => {
      // If parsing the JSON fails, we will just fallback to showing the error like we usually do.
      //
      defaultErrorHandler();
    });
};

/** Simply use this Decorator like so
 *
 ```typescript
 @Logger()
 class FooBar implements ILoggable {
  logger: Partial<console>;

  constructor() {
    this.logger.log('x') // [foo-bar] x
  }
 }
 ```
 *
 *
 */
export function Logger(prefix?: string) {
  return (target: any) => {
    const kebabCasedName = kebabClassName(target.prototype);
    target.prototype.logger = createLogger(prefix || `[${kebabCasedName}]`);
  };
}

/**
 * We use this function to format Angular template errors which are not logged
 * by the logger injected into the component, but by IonicErrorHandler.
 *
 * @param error Usually an Angular template error
 */
export function formatError(error: any) {
  const isAngularError = (ngError: unknown) => {
    // These two properties are specific for Angular template errors
    //
    return !isNil(get(ngError, 'ngDebugContext.view')) && !isNil(get(ngError, 'ngErrorLogger'));
  };

  if (!isAngularError(error)) {
    return error;
  }

  const ngDebugContextView = get(error, 'ngDebugContext.view');

  const formattedErrorMessage = `(NG_ERROR: Component=${kebabClassName(get(ngDebugContextView, 'component', 'unknown'))}`;

  return {...error, formattedErrorMessage};
}
