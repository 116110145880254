import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'src/app/components/components.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { BarcodeScannerButtonsComponent } from './barcode-scanner-buttons/barcode-scanner-buttons';
import { CameraPlaceHolderComponent } from './camera-placeholder/camera-placeholder';
import { ProductSearchModalComponent } from './product-search-modal/product-search.modal';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    CommonModule,
    DirectivesModule,
    ReactiveFormsModule,
    PipesModule,
    ComponentsModule
  ],
  declarations: [CameraPlaceHolderComponent, BarcodeScannerButtonsComponent, ProductSearchModalComponent],
  exports: [CameraPlaceHolderComponent, BarcodeScannerButtonsComponent, ProductSearchModalComponent]
})
export class ScannerModule { }
