<!-- String 0, Integer = 2, Decimal = 3 -->

<ng-container *ngIf="customField.DataTypeID == 0 || customField.DataTypeID == 2 || customField.DataTypeID == 3">
  <!-- INPUT -->
  <ng-container *ngIf="(!customField.IsArray || !openModalForArrays); else arrayForm">
    <ion-item lines="full">
      <ion-label [position]="position">{{ formControlLabel }}</ion-label>
      <ion-input [formControl]="formControl" [type]="customField.DataTypeID == 0 ? 'text' : 'number'" [inputmode]="inputMode ? inputMode : null"></ion-input>
    </ion-item>
  </ng-container>

  <!-- ARRAY VALUES -->
  <ng-template #arrayForm>
    <ion-item lines="full" (click)="editArrayValues()">
      <ion-label>{{ formControlLabel }}</ion-label>
      <div class="array-values">{{ getArrayValuesToDisplay(formControl) }}</div>
      <ion-icon name="create-outline"></ion-icon>
      <!-- This input is hidden because we only use it for the form validation -->
      <ion-input [hidden]="true" [formControl]="formControl"></ion-input>
    </ion-item>
  </ng-template>
</ng-container>

<!-- Bool = 1 -->

<ng-container *ngIf="customField.DataTypeID == 1">
  <ion-item lines="full">
    <ion-label>{{formControlLabel}}</ion-label>
    <ion-checkbox [formControl]="formControl"></ion-checkbox>
  </ion-item>
</ng-container>

<!-- Enum = 4 -->

<ng-container *ngIf="customField.DataTypeID == 4">
  <ion-item lines="full">
    <ion-label>{{formControlLabel}}</ion-label>
    <ion-select [formControl]="formControl" [attr.multiple]="customField.Options.IsArray ? true : null">
      <ion-select-option *ngFor="let enumValueEntry of customField.Options.EnumValues | keyvalue" [value]="enumValueEntry.key">{{ enumValueEntry.value }}
      </ion-select-option>
    </ion-select>
  </ion-item>
</ng-container>

<!-- Text 5 -->

<ng-container *ngIf="customField.DataTypeID == 5">
  <ion-item lines="full">
    <ion-label [position]="position">{{ formControlLabel }}</ion-label>
    <ion-textarea [formControl]="formControl"></ion-textarea>
  </ion-item>
</ng-container>

<!-- DateTime = 6, Date = 7 -->

<ng-container *ngIf="customField.DataTypeID == 6 || customField.DataTypeID == 7">
  <ion-item lines="full">
    <ion-label>{{ formControlLabel }}</ion-label>
    <ion-text slot="end">
      <ng-container *ngIf="formControl.value as date; else creationDateToPlaceholder">
        <ion-button (click)="showDateModal()" *ngIf="customField.DataTypeID == 6" fill="outline">
          {{ date | intlDate: intlDateTimeOpts }}
        </ion-button>
        <ion-button (click)="showDateModal()" *ngIf="customField.DataTypeID == 7" fill="outline">
          {{ date | intlDate }}
        </ion-button>
      </ng-container>
      <ng-template #creationDateToPlaceholder>
        <ion-button fill="outline" (click)="showDateModal()" [disabled]="formControl.disabled">
          {{ 'action.select' | translate }}
        </ion-button>
      </ng-template>
    </ion-text>
    <ion-modal [trigger]="ionModalTriggerId" class="date-picker-modal" #dateModal>
      <ng-template>
        <ion-content>
          <!--
            See possible presentations of the date component here
            https://ionicframework.com/docs/api/datetime#presentation
           -->
          <ion-datetime
            [presentation]="customField.DataTypeID == 6 ? 'date-time' : 'date'"
            [formControl]="formControl"
            [showDefaultButtons]="true"
            [min]="customField.Options.MinimumDate"
            [max]="customField.Options.MaximumDate"
          ></ion-datetime>
        </ion-content>
      </ng-template>
    </ion-modal>
  </ion-item>
</ng-container>

<!-- ERRORS -->

<div [val-messages]="formControl" class="ion-padding-horizontal">
  <span val-message="required"> {{ 'generic.is.required' | translate }} </span>
  <span val-message="minlength">
    {{ 'min.length.validator' | translate: {
      minLength: formControl.errors?.minlength?.requiredLength,
      actual: formControl.errors?.minlength?.actualLength
    } }} </span>
  <span val-message="maxlength">
    {{ 'max.length.validator' | translate: {
      maxLength: formControl.errors?.maxlength?.requiredLength,
      actual: formControl.errors?.maxlength?.actualLength
    } }} </span>
  <span val-message="min">
    {{ 'min.validator' | translate: {
      min: formControl.errors?.min?.min,
      actual: formControl.errors?.min?.actual
    } }} </span>
  <span val-message="max">
    {{ 'max.validator' | translate: {
      max: formControl.errors?.max?.max,
      actual: formControl.errors?.max?.actual
    } }} </span>
</div>
