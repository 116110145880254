import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { addBundleProductToOrder, addProductToOrder, addServiceProductToOrder, addUserBorrowedProductToOrder, cancelOrder, changeOrderLinesToCarryOut, changeOrderLinesToDelivery, changeOrderLinesToPickup, createInterbranchOrder, createReturnToSupplierOrder, getShoppingCart, getShoppingCartInfo, ICartAddBundleProductToOrderAction, ICartAddProductToOrderAction, ICartAddServiceProductToOrderAction, ICartCancelOrderAction, ICartChangeOrderLinesToCarryOutAction, ICartChangeOrderLinesToDeliveryAction, ICartChangeOrderLinesToPickupAction, ICartModifyQuantityOrderedAction, ICartSetRequestedDateAction, ICartUpdateSerialNumberAction, modifyQuantityOrdered, OrderMode, setRequestedDate, settings, store, updateSerialNumber } from '@springtree/eva-sdk-redux';
import { ICartAddUserBorrowedProductToOrderAction } from '@springtree/eva-sdk-redux/dist/types/redux/reducers/get-shopping-cart-info';
import { isNil } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { GenericIcon } from 'src/app/components/icon/icon.component';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';
import { EvaErrorGeneratorProvider } from '../eva-error-generator/eva-error-generator';
import isNotNil from 'src/app/shared/operators/isNotNil';

interface ICharacteristicsMapping {
  [key: string]: {
    titleI18nKey: string;
    icon: GenericIcon;
  };
};
@Logger('[order-lifecycle-service]')
@Injectable({
  providedIn: 'root',
})
export class OrderLifecycleService implements ILoggable {
  /**
   * Logger instance implementation
   */
  public logger: Partial<Console>;

  private requestedOrderCharacteristics$ = new BehaviorSubject<OrderMode>(OrderMode.sale);

  private characteristicsMapping: ICharacteristicsMapping = {
    [OrderMode.sale]: {
      titleI18nKey: 'sales',
      icon: {
        type: 'ionicon',
        value: 'cart-outline'
      }
    },
    [OrderMode.returnToSupplier]: {
      titleI18nKey: 'order.type.return.to.supplier',
      icon: {
        type: 'svg',
        value: './svg/order-rma.svg'
      }
    },
    [OrderMode.interbranch]: {
      titleI18nKey: 'order.type.interbranch',
      icon: {
        type: 'svg',
          value: './svg/order-interbranch.svg'
      }
    }
  }

  private orderMode$: Observable<OrderMode> = getShoppingCart.getState$().pipe(
    map(state =>  state?.checkoutStatus.mode)
  );

  public orderCharacteristics$: Observable<OrderMode> = combineLatest([this.orderMode$, this.requestedOrderCharacteristics$]).pipe(
    map(([orderMode, requestedOrderCharacteristics]) => {
      // If orderMode is empty or its something else than Sale/RTS/interbranch, we will switch over to the local set requestedOrderCharacteristics
      //
      return (isNil(orderMode) || ![OrderMode.sale, OrderMode.returnToSupplier, OrderMode.interbranch].includes(orderMode)) ? requestedOrderCharacteristics : orderMode;
    })
  );

  public pageTitleStream$: Observable<string> = this.orderCharacteristics$.pipe(
    map(currentCharacteristicOrder => this.characteristicsMapping[currentCharacteristicOrder].titleI18nKey),
    mergeMap(value => this.$translate.get(value))
  );

  public pageIconStream$ = this.orderCharacteristics$.pipe(
    map(currentCharacteristicOrder => this.characteristicsMapping[currentCharacteristicOrder].icon)
  );

  public isInterbranchOrder$ = this.orderCharacteristics$.pipe(
    map((checkoutState) => checkoutState === OrderMode.interbranch)
  );

  public isRtsOrder$ = this.orderCharacteristics$.pipe(
    map((checkoutState) => checkoutState === OrderMode.returnToSupplier)
  );

  public cartResponse$ = getShoppingCart.getState$().pipe(
    map(state => state?.response),
    isNotNil()
  );

  /**
   * One of the characteristics of a 'return order' is if the open amount is negative
   * But this is not the only characteristics, so this member needs to be refined in the future
   * ⚠️️️️️
   * @see https://eva2015.atlassian.net/browse/OPTR-1612
   * @see https://eva2015.atlassian.net/browse/OPTR-7353
   */
  public isRefundOrderType$: Observable<boolean> = this.cartResponse$.pipe(
    map(cart => {
      const isRefund = this.isRefundOrder(cart);

      /**
       * We make an extra check in case we have a combined order (refund + new purchase together)
       * in that case we must show Payments methods, not refund methods.
       * @see https://n6k.atlassian.net/browse/OPTR-17362
       */
      if(isRefund && cart?.Amounts?.Open?.PendingInTax > 0){
        return false;
      }

      return isRefund;
    })
  );

  constructor(
    private $translate: TranslateService,
    private toastCtrl: EvaToastController,
    private $evaErrorGenerator: EvaErrorGeneratorProvider,
    private alertCtrl: AlertController,
    public route: ActivatedRoute
  ) {}

  public setRequestedCharacteristics(characteristics: OrderMode) {
    this.requestedOrderCharacteristics$.next(characteristics);
  }

  /**
   * Helper method to get synchronous access to the current order ID
   */
   public getCurrentOrderId() {
    return getShoppingCartInfo.getState().request?.OrderID || settings.currentOrderId || 0;
  }

  /**
   * Switches the currently active order id in the SDK.
   * Dispatching a new getShoppingCartInfo with the requested order id will also
   * be reflected in the SDK `settings.currentOrderId`
   */
   public setCurrentOrderById(orderID: number) {
    const [action, promise, chainPromise] = getShoppingCartInfo.createFetchAction({
      OrderID: orderID,
    });
    store.dispatch(action);
    return [promise, chainPromise];
  }

  /**
   * Clear the current cart/order so a new one can be started when the next
   * cart operation is requested.
   * This used to be DetachOrderFromSession before sessions were deprecated
   */
   public unsetOrderId() {
    const [action, promise, chainPromise] = getShoppingCartInfo.createFetchAction({
      OrderID: 0,
    });
    store.dispatch(action);
    return [promise, chainPromise];
  }

  /**
   * Helper method to request the current cart data to be updated.
   * Often used on component initialization to force an update
   */
   public updateCurrentOrder() {
    const [action, promise] = getShoppingCartInfo.createFetchAction();
    store.dispatch(action);
    return promise;
  }

  /**
   * Wrapper method to add a product to the current order
   */
   public async addProductToOrder(payload: Omit<ICartAddProductToOrderAction, 'reducer'>) {
    const promise = getShoppingCartInfo.addToCart<EVA.Core.AddProductToOrderResponse>({
      ...payload,
      reducer: addProductToOrder,
    });

    return promise;
  }

  /**
   * Wrapper method to add a service product to the current order
   */
   public addServiceProductToOrder(payload: Omit<ICartAddServiceProductToOrderAction, 'reducer'>) {
    return getShoppingCartInfo.addToCart<EVA.Core.AddServiceProductToOrderResponse>({
      ...payload,
      reducer: addServiceProductToOrder,
    });
  }

  /**
   * Wrapper method to add a bundle product to the current order
   */
  public addBundleProductToOrder(payload: Omit<ICartAddBundleProductToOrderAction, 'reducer'>) {
    return getShoppingCartInfo.addToCart<EVA.Core.AddBundleProductToOrderResponse>({
      ...payload,
      reducer: addBundleProductToOrder,
    });
  }

  /**
   * Wrapper method to add a borrowed product to the current order
   */
   public async addBorrowedProductToOrder(payload: Omit<ICartAddUserBorrowedProductToOrderAction, 'reducer'>) {
    const promise = getShoppingCartInfo.addToCart<EVA.Core.AddUserBorrowedProductToOrder>({
      ...payload,
      reducer: addUserBorrowedProductToOrder,
    });

    return promise;
  }

  /**
   * Wrapper method to cancel the current order
   */
   public cancelOrder(payload: Omit<ICartCancelOrderAction, 'reducer'>) {
    return getShoppingCartInfo.modifyCart<EVA.Core.CancelOrderResponse>({
      ...payload,
      reducer: cancelOrder,
    });
  }

  /**
   * Wrapper method to add RMA or Interbranch order
   */
  public async createOrderByCharacteristic() {
    const OrderID = this.getCurrentOrderId();
    const mode = await this.orderCharacteristics$.pipe(take(1)).toPromise();
    //-----------------------RMA----------------------------
    if (mode === OrderMode.returnToSupplier) {
      try {
        const [createReturnToSupplierOrderAction, createReturnToSupplierOrderPromise] = createReturnToSupplierOrder.createFetchAction({
          OrderID,
        });

        store.dispatch(createReturnToSupplierOrderAction);

        await createReturnToSupplierOrderPromise;
      } catch (error) {
        this.logger.error(error);

        this.showCharacteristicFeedbackOnFail(error);

        this.setRequestedCharacteristics(OrderMode.sale);
      }
    //-----------------------Interbranch----------------------------
    } else if (mode === OrderMode.interbranch) {
      try {
        const [createInterbranchOrderAction, createInterbranchOrderPromise] = createInterbranchOrder.createFetchAction({
          OrderID,
        });

        store.dispatch(createInterbranchOrderAction);

        await createInterbranchOrderPromise;
      } catch (error) {
        this.logger.error(error);

        this.showCharacteristicFeedbackOnFail(error);

        this.setRequestedCharacteristics(OrderMode.sale);
      }
    }
  }

  private async showCharacteristicFeedbackOnFail(error: any) {
    const errorFeedback = await this.$evaErrorGenerator.constructFailureFeedback(
      error,
      this.$translate.instant('order.type.modify.fail')
    );
    this.toastCtrl.create(errorFeedback).present();
  }

  /**
   * Wrapper method to change one or more order lines to be carry out
   */
   public changeOrderLinesToCarryOut(payload: Omit<ICartChangeOrderLinesToCarryOutAction, 'reducer'>) {
    return getShoppingCartInfo.modifyCart<EVA.Core.ShoppingCartResponse>({
      ...payload,
      reducer: changeOrderLinesToCarryOut,
    });
  }

  /**
   * Wrapper method to change one or more order lines to be delivery
   */
   public changeOrderLinesToDelivery(payload: Omit<ICartChangeOrderLinesToDeliveryAction, 'reducer'>) {
    return getShoppingCartInfo.modifyCart<EVA.Core.ShoppingCartResponse>({
      ...payload,
      reducer: changeOrderLinesToDelivery,
    });
  }

  /**
   * Wrapper method to change one or more order lines to be pick up
   */
   public changeOrderLinesToPickup(payload: Omit<ICartChangeOrderLinesToPickupAction, 'reducer'>) {
    return getShoppingCartInfo.modifyCart<EVA.Core.ShoppingCartResponse>({
      ...payload,
      reducer: changeOrderLinesToPickup,
    });
  }

  /**
   * Wrapper method to modify the ordered quantity for an order line
   */
   public modifyQuantityOrdered(payload: Omit<ICartModifyQuantityOrderedAction, 'reducer'>) {
    return getShoppingCartInfo.modifyCart<EVA.Core.ModifyQuantityOrderedResponse>({
      ...payload,
      reducer: modifyQuantityOrdered,
    });
  }

  /**
   * Wrapper method to set the requested order date
   */
   public setRequestedDate(payload: Omit<ICartSetRequestedDateAction, 'reducer'>) {
    return getShoppingCartInfo.modifyCart<EVA.Core.EmptyResponseMessage>({
      ...payload,
      reducer: setRequestedDate,
    });
  }

  /**
   * Wrapper method to split an order line
   */
  public updateSerialNumber(payload: Omit<ICartUpdateSerialNumberAction, 'reducer'>) {
    return getShoppingCartInfo.modifyCart<EVA.Core.EmptyResponseMessage>({
      ...payload,
      reducer: updateSerialNumber,
    });
  }

  /**
   * Check if IgnoreDiscounts is enabled, because it could
   * throw an error when applying a discount / coupon.
   * This could happen when an order is copied with IgnoreDiscounts option enabled.
   *
   * @see https://sentry.io/share/issue/11fc591f40ca42e49991333b895867be
   */
   public async checkIgnoreDiscountsEnabled() {
    const ignoreDiscountsEnabled = await getShoppingCart.getResponse$().pipe(
      isNotNil(),
      take(1),
      map(response => response?.ShoppingCart.IgnoreDiscounts)
    ).toPromise();

    if(ignoreDiscountsEnabled){
      this.alertCannotApplyDiscounts();
    }
    return ignoreDiscountsEnabled;
  }

  /**
   * Warning the user when cannot apply discounts
   * because IgnoreDiscounts is enabled.
   *
   * @see https://sentry.io/share/issue/11fc591f40ca42e49991333b895867be
   */
  private async alertCannotApplyDiscounts(){
    const alert = await this.alertCtrl.create({
      header: this.$translate.instant('discounts.disabled'),
      message: this.$translate.instant('discounts.cannot.apply'),
      buttons: [this.$translate.instant('action.ok')]
    });
    alert.present();
  }

  private isRefundOrder(cart: EVA.Core.ShoppingCartResponse) {
    // We changed the way for checking order refunds
    // @see https://n6k.atlassian.net/browse/OPTR-20333
    const hasReturns = cart?.ShoppingCart?.Properties;
    const amountForRefund = cart?.AdditionalOrderData?.OrderAmountAvailableForRefund;

    return Boolean(hasReturns & EVA.Core.OrderProperties.HasReturnLines) || (amountForRefund > 0);
  }

}
