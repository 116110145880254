import { Injectable } from '@angular/core';
import { ILoggable, Logger } from '../../shared/decorators/logger';

/**
 * This provider will be responsible for saving the last selected organisation unit id in.
 *
 * @see https://n6k.atlassian.net/browse/OPTR-9714
 */
@Logger('[selected-organisation-unit-provider]')
@Injectable()
export class SelectedOrganisationUnitProvider implements ILoggable {
  logger: Partial<Console>;

  /** This will only be saved and used if the App:Feature:LockOnOrganizationUnit:Enabled setting is set to true */
  private readonly ORGANISATION_UNIT_KEY = 'selectedOrganisationUnit';

  /** We will always be saving this locally, regardless of App:Feature:LockOnOrganizationUnit:Enabled setting. It will be mainly for the client app hub */
  private readonly LAST_USED_ORGANISATION_UNIT_KEY = 'lastUsedOrganisationUnitId';

  /** This should always be called whenever the user logs in, so we can use it for connecting to the clientAppHub */
  setLastUsedOrganisation(organisationId: number) {
    localStorage.setItem(this.LAST_USED_ORGANISATION_UNIT_KEY, organisationId.toString());
  }

  /** Returns the last used organisation unit */
  getLastUsedOrganisation(): number {
    try {
      const lastUsedOrganisationId = localStorage.getItem(this.LAST_USED_ORGANISATION_UNIT_KEY);

      return parseInt(lastUsedOrganisationId, 10);
    } catch (error) {
      this.logger.error('error getting the last selected organisation', error);
    }
  }

  /**
   * This should only be used for persisting the last selected OU in the context of locking the OU (App:Feature:LockOnOrganizationUnit:Enabled)
   * @see https://n6k.atlassian.net/browse/OPTR-9714
   */
  setOrganisation(selectedOrganisation: EVA.Core.GetOrganizationUnitSummaryResponse) {
    try {
      const selectedOrganisationString = JSON.stringify(selectedOrganisation);

      localStorage.setItem(this.ORGANISATION_UNIT_KEY, selectedOrganisationString);

      this.logger.log(`organisation with id=${selectedOrganisation.ID} saved to localStorage`);
    } catch (error) {
      this.logger.error('error saving the selected organisation in localStorage', error);
    }
  }

  getOrganisation(): EVA.Core.OrganizationUnitDto {
    try {
      const selectedOrganisationString = localStorage.getItem(this.ORGANISATION_UNIT_KEY);

      const selectedOrganisation: EVA.Core.OrganizationUnitDto = JSON.parse(selectedOrganisationString);

      return selectedOrganisation;
    } catch ( error ) {
      this.logger.error('error getting the selected organisation', error);

      // When parsing the item from localStorage item, it could be that we saved something bad to begin with.
      // so just we're sure we don't have this problem next time we will remove this item from localStorage
      //
      localStorage.removeItem(this.ORGANISATION_UNIT_KEY);
    }
  }

  resetOrganisation() {
    localStorage.removeItem(this.ORGANISATION_UNIT_KEY);
  }

}
