import { Injectable } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { cancelPayment, communicator, getAvailablePaymentMethods, getOrder, getPaymentTransaction, getPaymentTransactionsForOrder, getShoppingCart, IHubEventPaymentTransactionUpdated, IHubPaymentJoinRequest, IOrderPayment, OrderStatus, PaymentTransactionProperties, settings, store } from '@springtree/eva-sdk-redux';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { OrderLifecycleService } from 'src/app/services/order-lifecycle/order-lifecycle.service';
import { EvaFeedback } from 'src/app/shared/decorators/eva-feedback';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';
import { filter, first, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { isNil, uniq } from 'lodash-es';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import isNotNil from 'src/app/shared/operators/isNotNil';
import { ILineActionsTypes } from 'src/app/pages/basket/components/basket-list/basket-list.component';

export interface ICheckFraudTransaction {
  id: number;
  fraudProcessIsActiveInformed: boolean;
  fraudProcessIsRejectedInformed: boolean;
}

/**
 * We use this service to keep alive the connection with the PaymentHub
 * and also to inform the user when the payments are confirmed
 * @see https://n6k.atlassian.net/browse/OPTR-20555
 */
@Logger('[pending-payments-service]')
@Injectable({
  providedIn: 'root',
})
export class PendingPaymentsService implements ILoggable {

  public logger: Partial<Console>;

  // We keep a record of all payment cancellation status
  public paymentCancellationFails$ = new BehaviorSubject<number[]>([]);

  // Wee keep a record of the transactions we showed a warning modal
  public lastCheckedPaymentTransactions: Map<number,ICheckFraudTransaction> = new Map();

  // When Risk fraud check is rejected we need to hide PROCEED button until receive any response
  public fraudCheckIsRejected$ = new BehaviorSubject(null);

  public orderHasUnshippedCarryOutLines$ = combineLatest([
    getShoppingCart.getResponse$().pipe(isNotNil()),
    this.$orderLifecycleService.isRefundOrderType$
  ])
  .pipe(
    isNotNil(),
    map(([cart, isRefundOrder]) => {
      const lines = cart?.ShoppingCart?.Lines?.filter((line) => !line.IsCancelled);
      const orderHasUnshippedCarryOutLines = lines.some((line) =>
        line.LineActionType === this.lineActionTypes.CARRY_OUT && !line.IsShipped
      );

      // If the order is REFUND, we don't check if has unshipped carry-out lines
      const hasUnshippedCarryOutLines = isRefundOrder ? true : orderHasUnshippedCarryOutLines;

      return hasUnshippedCarryOutLines;
    })
  )

  /**
   * Check if ALL of the transactions has not allowed pending payment methods
   * therefore we HIDE the pending blue bar and other warnings about payments...
   */
  public hasNotAllowedPaymentMethods$ = getPaymentTransactionsForOrder.getResponse$().pipe(
    isNotNil(),
    map(paymentTransactionsResponse => paymentTransactionsResponse.PaymentTransactions),
    map((transactions:EVA.Core.GetPaymentTransactionsResponse.PaymentTransaction[]) => {
      // Get only pending payment transactions
      const pendingPayments = transactions?.filter(
        transaction => transaction.Status === EVA.Core.PaymentStatuses.Pending
      )

      // Check if all payments are not allowed
      const allPaymentsNotAllowed = pendingPayments?.every(transaction => {
        // We try to find if some not allowed payment method is present into current transaction
        const foundForbiddenMethod = this.notAllowedPayments.some(payment => {
          const paymentCode = transaction.Type?.Code?.toUpperCase();
          const paymentName = transaction.Type?.Name?.toUpperCase();
          return paymentCode.includes(payment) || paymentName.includes(payment);
        });
        return foundForbiddenMethod;
      });

      return allPaymentsNotAllowed;
    })
  );

  // We don't handle PENDING flow for some payments like PIN and Adyen(EVAPay, BankRefund, AutoRefund)
  private notAllowedPayments = ['PIN','EVAPAY','EVAPAY','ADYEN_DROPIN','PIN_ONLINE_REFUND','REFUND_ALL'];

  // With this subject we control when to receive events or not
  private stop$ = new Subject<void>();

  private subscriptionPaymentsConfirmation$: Subscription;

  private subscriptionPaymentUpdates$: Subscription;

  private lineActionTypes = ILineActionsTypes;

  public orderRequirements$ = getShoppingCart.getResponse$().pipe(
    map(response => response?.AdditionalOrderData?.RequiredData),
    isNotNil()
  );

  constructor(
    private alertCtrl: AlertController,
    private $translate: TranslateService,
    private $orderLifecycleService: OrderLifecycleService,
    private toastCtrl: EvaToastController,
    private $orderLifeCycleService: OrderLifecycleService,
    private platform: Platform,
  ){
    this.initialBootCheck();
    this.checkFraudulentPayments();
  }

  // Wait for Ionic/App to bootstrap first
  public async initialBootCheck(){
    await this.platform.ready();
    const isRefundOrder = await this.$orderLifeCycleService.isRefundOrderType$.pipe(first()).toPromise();
    this.checkIfShouldSubscribeToActions(isRefundOrder);
  }

  // This method removes subscriptions to prevent confirmation toast for not pending payments
  public unsubscribePendingPaymentsActions(){
    console.log('>>> connecting events STOP');
    this.subscriptionPaymentUpdates$ = undefined;
    this.subscriptionPaymentsConfirmation$ = undefined;
    this.leavePaymentHubChannel();
    this.paymentCancellationFails$.next([]);
    this.fraudCheckIsRejected$.next(null);
    this.stop$?.next();
  }

  // This method handle PENDING Payments/Refunds responses
  public async handleCreatePaymentPendingResponses(paymentResponse: IOrderPayment, isRefundType?:boolean){
    const currentTransaction = paymentResponse.paymentTransactions.find(transaction => transaction.ID === paymentResponse.paymentTransactionId);
    const paymentIsInPendingMode = currentTransaction?.Status === EVA.Core.PaymentStatuses.Pending;

    // We don't cover several cases (payments, ship lines...)
    const canContinue = await this.checkIfEnterPendingPaymentFlow(currentTransaction, isRefundType);

    if(paymentIsInPendingMode && canContinue){
      // Wait to pass 500ms before checking again payment status
      await new Promise(resolve => setTimeout(resolve, 500));

      const paymentStatus = await this.checkPaymentStatus(paymentResponse.paymentTransactionId);
      const paymentIsConfirmed = paymentStatus === EVA.Core.PaymentStatuses.Confirmed;

      if(paymentIsConfirmed){
        const paymentApproved = this.$translate.instant('checkout.pending.payments.confirmation');
        this.toastCtrl.showMessage(paymentApproved);
        this.$orderLifecycleService.updateCurrentOrder();
      }
      else // If payment it's still on pending mode
      {
        // Subscribe to cart update actions
        this.checkIfShouldSubscribeToActions(isRefundType);

        // Warn the user about the response delay (toast)
        this.askConfirmationPendingPayment(paymentResponse.paymentTransactionId, isRefundType);
      }
    }
  }

  private async checkIfEnterPendingPaymentFlow(currentTransaction:EVA.Core.PaymentTransactionDto, isRefundType?:boolean): Promise<boolean> {
    // Check if at least one line(carry-out) is not shipped to allow continuing pending flow
    const unshippedCarryOutLineFound = await this.checkIforderHasUnshippedCarryOutLines(isRefundType);

    // Check if the payment is forbidden to enter pending payment flow
    const forbiddenPaymentFound = this.notAllowedPayments.some(payment => {
      const paymentCode = currentTransaction.Type?.Code?.toUpperCase();
      const paymentName = currentTransaction.Type?.Name?.toUpperCase();
      return paymentCode.includes(payment) || paymentName.includes(payment);
    });

    return !forbiddenPaymentFound && unshippedCarryOutLineFound;
  }

  // We only check for unshipped lines on sales payment types, not refunds
  public async checkIforderHasUnshippedCarryOutLines(isRefundType?:boolean) {
    if(isRefundType){
      return true;
    }

    const hasUnshippedLines = await this.orderHasUnshippedCarryOutLines$.pipe(isNotNil(),first()).toPromise();

    return hasUnshippedLines;
  }

  /**
   * We check if payment is pending type and show a modal about cancelation fails
   * @see https://n6k.atlassian.net/browse/OPTR-20555?focusedCommentId=121231
   */
  public async showCancellationPaymentFailed(paymentId: number){
    const transactions = await getPaymentTransactionsForOrder.getResponse$().pipe(isNotNil(),first()).toPromise();
    const currentTransaction = transactions?.PaymentTransactions?.find(transaction => transaction.ID === paymentId);
    const paymentIsInPendingMode = currentTransaction?.Status === EVA.Core.PaymentStatuses.Pending;

    if(paymentIsInPendingMode){
      const paymentsIDs = await this.paymentCancellationFails$.pipe(first()).toPromise();
      const updatedPaymentIDs = uniq([...paymentsIDs,paymentId]);
      this.paymentCancellationFails$.next(updatedPaymentIDs);

      // Show modal info to the user
      const alert = await this.alertCtrl.create({
        header: this.$translate.instant('payment.transaction.status.warning.title'),
        message: this.$translate.instant('payment.transaction.cancel.pending'),
        buttons: [{
          text: this.$translate.instant('action.ok'),
          role: 'cancel'
        }]
      });
      alert.present();
    }
  }

  // Show warning to the user when the order has pending payments
  // and it's is attempting to make another payment
  public async askConfirmationPendingPayments(isRefund: boolean){
    const orderHasUnshippedCarryOutLines = await this.checkIforderHasUnshippedCarryOutLines(isRefund);
    const transactions = await getPaymentTransactionsForOrder.getResponse$().pipe(isNotNil(),first()).toPromise();
    const hasPendingPayments = transactions?.PaymentTransactions?.some(transaction => transaction.Status === EVA.Core.PaymentStatuses.Pending);
    const hasNotAllowedPaymentMethods = await this.hasNotAllowedPaymentMethods$.pipe(isNotNil(),first()).toPromise();

    if(hasPendingPayments && orderHasUnshippedCarryOutLines && !hasNotAllowedPaymentMethods){
      const alert = await this.alertCtrl.create({
        header: this.$translate.instant('payment.transaction.pending'),
        message: this.$translate.instant('payment.transaction.pending.message'),
        backdropDismiss: false,
        buttons: [{
          text: this.$translate.instant('action.cancel'),
          role: 'cancel'
        },
        {
          text: this.$translate.instant('action.continue'),
          cssClass: 'alert-danger',
          role: 'confirm'
        }]
      });
      alert.present();
      return alert.onDidDismiss<{role: string}>();
    }

    return null;
  }

  /**
   * This method is executed first time when the Service is invoked OR
   * every time the user tries to pay with a pending payment type
   */
  private async checkIfShouldSubscribeToActions(isRefund?:boolean){
    const notSubscribedToUpdates = isNil(this.subscriptionPaymentUpdates$);
    const notSubscribedToConfirmation = isNil(this.subscriptionPaymentsConfirmation$);
    const transactions = await getPaymentTransactionsForOrder.getResponse$().pipe(isNotNil(),first()).toPromise();
    const cartResponse = await getShoppingCart.getResponse$().pipe(isNotNil(),first()).toPromise();
    const hasCurrentOrder = cartResponse?.ShoppingCart?.ID;

    // Check if some of the transactions are in pending mode to prevent unnecessary actions
    const hasPendingPayments = transactions?.PaymentTransactions?.some(transaction => transaction.PendingOrConfirmed);
    const orderHasUnshippedCarryOutLines = await this.checkIforderHasUnshippedCarryOutLines(isRefund);

    if((notSubscribedToUpdates || notSubscribedToConfirmation) && hasCurrentOrder && hasPendingPayments && orderHasUnshippedCarryOutLines){
      console.log('>>> connecting SHOULD SUBSCRIBE');
      this.stop$ = new Subject<void>();
      this.joinPaymentHubChannel();
      this.listenForPendingPaymentUpdates();
      this.listenForPaymentConfirmation();
    }
  }

  // Subscribe / listen to the SignalR `PaymentHub` for updates
  private async joinPaymentHubChannel() {
    try {
      const orderId = this.$orderLifecycleService.getCurrentOrderId();
      const payload: IHubPaymentJoinRequest = {
        token: settings.userToken,
        orderId
      }
      await communicator.joinPaymentHub(payload);
    } catch (error) {
      this.logger.warn('Failed to subscribe to pending payments channel', error);
    }
  }

  private async leavePaymentHubChannel() {
    try {
      const orderId = this.$orderLifecycleService.getCurrentOrderId();
      const payload: IHubPaymentJoinRequest = {
        token: settings.userToken,
        orderId
      }
      await communicator.leavePaymentHub(payload);
    } catch (error) {
      this.logger.warn('Failed to leave pending payments hub', error);
    }
  }

  private listenForPendingPaymentUpdates(){
    console.log('>>> connecting events OrderUpdated');
    this.subscriptionPaymentUpdates$ = communicator.event$.pipe(
      filter((event) => event?.method === 'OrderUpdated'),
      takeUntil(this.stop$)
    ).subscribe(() => {
      // Refresh pending payments loader
      this.getPaymentTransactions();

      // We refresh the cart info in case some info has changed
      this.$orderLifecycleService.updateCurrentOrder();

      // Also refresh payment methods in case OpenAmount has changed
      this.getAvailablePaymentMethods();
    });
  }

  private async listenForPaymentConfirmation(){
    console.log('>>> connecting events Confirmation');
    this.subscriptionPaymentsConfirmation$ = getShoppingCart.getState$().pipe(
      takeUntil(this.stop$),
    ).subscribe((cart) => {
      const openAmount = cart.response?.Amounts?.Open?.InTax;
      if(cart.status === OrderStatus.paid || cart.status === OrderStatus.completed || openAmount === 0){
        const paymentApproved = this.$translate.instant('checkout.pending.payments.confirmation');
        this.toastCtrl.showMessage(paymentApproved);
        this.unsubscribePendingPaymentsActions();
      }
    });
  }

  private async checkPaymentStatus(transactionID: number): Promise<EVA.Core.PaymentStatuses>{
    const [getPaymentTransactionsAction, requestPromise] = getPaymentTransaction.createFetchAction({
      PaymentTransactionID: transactionID
    });

    store.dispatch(getPaymentTransactionsAction);
    const result = await requestPromise;

    return result?.PaymentTransaction?.Status;
  }

  // This method is used to refresh component state
  private getPaymentTransactions() {
    const orderId = this.$orderLifecycleService.getCurrentOrderId();
    const [getPaymentTransactionsAction, promise] = getPaymentTransactionsForOrder.createFetchAction({
      OrderID: orderId
    });

    store.dispatch(getPaymentTransactionsAction);

    return promise;
  }

  @EvaFeedback({
    i18nFailKey: 'payment.methods.fetch.fail'
  })
  private getAvailablePaymentMethods() {
    const orderId = this.$orderLifecycleService.getCurrentOrderId();
    const [paymentAction, promise] = getAvailablePaymentMethods.createFetchAction({
      OrderID: orderId,
      ReturnUnusablePaymentMethods: true
    });

    store.dispatch(paymentAction);

    return promise;
  }

  @EvaFeedback({
    i18nFailKey: 'payment.history.cancel.payment.failure',
    i18nSuccessKey: 'payment.history.cancel.payment.success'
  })
  private async cancelPayment(paymentId: number) {
    const [action, promise] = cancelPayment.createFetchAction({
      PaymentID: paymentId,
    });

    store.dispatch(action);

    try {
      await promise;
    }
    catch(err){
      this.showCancellationPaymentFailed(paymentId);
    }
    finally {
      this.getPaymentTransactions();
      this.$orderLifecycleService.updateCurrentOrder();
    }

    return promise;
  }

  private async askConfirmationPendingPayment(transactionID: number, isRefundType?:boolean) {
    const message = isRefundType ? 'payment.transaction.status.warning.message.refund' : 'payment.transaction.status.warning.message';
    const alert = await this.alertCtrl.create({
      header: this.$translate.instant('payment.transaction.status.warning.title'),
      message: this.$translate.instant(message),
      backdropDismiss: false,
      buttons: [
        {
          text: this.$translate.instant('action.cancel'),
          cssClass: 'alert-danger',
          handler: () => {
            this.cancelPayment(transactionID);
          }
        },
        {
          text: this.$translate.instant('action.close'),
          role: 'cancel'
        }
      ]
    });

    alert.present();
  }


  // ---------- Payment Check Frauds -------------- //

  /**
   * Some kind of payments (e.g QR Adyen card) requires a fraud check verification
   * @see https://n6k.atlassian.net/browse/OPTR-22850
   */
  public async showWarningModalForCheckingFraud(fraudDetected: boolean = false): Promise<void> {
    let warningMessage = fraudDetected ?
      'payment.transaction.status.warning.fraud.detected' :
      'payment.transaction.status.warning.fraud.message';

    // Show modal info to the user
    const alert = await this.alertCtrl.create({
      header: this.$translate.instant('payment.transaction.status.warning.title'),
      message: this.$translate.instant(warningMessage),
      buttons: [{
        text: this.$translate.instant('action.close'),
        role: 'cancel'
      }]
    });

    alert.present();
  }

  // Listen to the `PaymentHub` for updates
  // @see https://n6k.atlassian.net/browse/OPTR-22850
  public async joinPaymentsChannelHub() {
    try {
      const orderId = this.$orderLifecycleService.getCurrentOrderId();
      const payload: IHubPaymentJoinRequest = {
        token: settings.userToken,
        orderId,
        paymentTransactionUpdatedCallback:(data:IHubEventPaymentTransactionUpdated) => {
          this.handlePaymentHubChannelUpdate();
        }
      }
      await communicator.joinPaymentHub(payload);
    } catch (error) {
      this.logger.warn('Failed to subscribe to pending payments channel for frauds', error);
    }
  }

  /**
   * Some kind of payments (e.g QR Adyen card) requires a fraud check verification
   * @see https://n6k.atlassian.net/browse/OPTR-22850
   */
  private async checkFraudulentPayments(): Promise<void>{
    getShoppingCart.getResponse$().pipe(isNotNil()).subscribe((cart:EVA.Core.ShoppingCartResponse) => {
      if(cart?.ShoppingCart?.Payments?.length){
        const orderRequirementInvalid = this.checkIfRequirementIsValid(cart);
        const fraudCheckPayments = cart.ShoppingCart.Payments.filter(
          (payment) => payment.Type.Code === 'ADYEN_DROPIN' && !payment.IsRefund
        );
        const fraudTransactionsFound = fraudCheckPayments.every(
          (payment) => Boolean(payment['Properties'] & PaymentTransactionProperties.FraudCheckDenied)
        );

        // We don't have multiple ACTIVE payments with the same method QREvaPay at the same time.
        // However we could have old payments, potentialy with the same payment type.
        fraudCheckPayments?.sort((a,b) => b.ID - a.ID); // ordered desc and get first transaction
        const paymentTransactionID = fraudCheckPayments?.length && fraudCheckPayments[0].ID;

        // This prevents to open the modals multiple times
        const lastEventRegistered = this.lastCheckedPaymentTransactions.get(paymentTransactionID);
        const shouldInformAboutFraudProcess = isNil(lastEventRegistered) && orderRequirementInvalid;
        const shouldInformFraudProcessRejected = (lastEventRegistered?.fraudProcessIsRejectedInformed === false);

        // We inform the user that a fraud check process is happening and we start listening to the hub for updates
        if(fraudCheckPayments?.length && shouldInformAboutFraudProcess){
          this.showWarningModalForCheckingFraud(false);
          this.joinPaymentsChannelHub();
          this.lastCheckedPaymentTransactions.set(paymentTransactionID,{
            id: paymentTransactionID,
            fraudProcessIsActiveInformed:true,
            fraudProcessIsRejectedInformed:false
          });
        }

        // We inform the user that the fraud check has not passed (REJECTED),
        // therefore it should select another payment method to continue
        if (fraudCheckPayments?.length && fraudTransactionsFound && shouldInformFraudProcessRejected) {
          this.showWarningModalForCheckingFraud(true);
          this.fraudCheckIsRejected$.next(true);
          this.lastCheckedPaymentTransactions.get(paymentTransactionID).fraudProcessIsRejectedInformed = true;
        }
      }
    });
  }

  private async handlePaymentHubChannelUpdate(){
    await this.$orderLifecycleService.updateCurrentOrder();
    const cart = await getShoppingCart.getResponse$().pipe(isNotNil(),first()).toPromise();
    const allPaymentsConfirmed = cart.ShoppingCart?.Payments?.every(payment => payment.Status === EVA.Core.PaymentStatuses.Confirmed);
    if(allPaymentsConfirmed){
      // We need to cover this edgecase in case it happens
      // @see https://n6k.atlassian.net/browse/OPTR-22850?focusedCommentId=131841
      this.fraudCheckIsRejected$.next(false);
    }
  }

  public checkIfRequirementIsValid(cart:EVA.Core.ShoppingCartResponse){
    const fraudCheckRequirement = cart?.AdditionalOrderData?.RequiredData?.Order.find((issue) => issue.Name === 'PaymentFraudCheck');
    const isValid = fraudCheckRequirement?.IsValid;
    if (!isNil(isValid) && !isValid) {
      return true;
    }
    return false;
  }

  public checkForFraudOperation(cart:EVA.Core.ShoppingCartResponse, payments:EVA.Core.GetPaymentTransactionsResponse){
    const orderRequirementInvalid = this.checkIfRequirementIsValid(cart);
    const pendingPaymentsIsRefund = payments?.PaymentTransactions?.find(
      (payment) => payment.Type.Code === 'ADYEN_DROPIN' && payment.IsRefund
    )
    if(pendingPaymentsIsRefund){
      return false; // to cover this case @see https://n6k.atlassian.net/browse/OPTR-22850?focusedCommentId=132092
    }
    return orderRequirementInvalid;
  }

}
