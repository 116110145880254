<header>
  <div class="subtitle">{{ label }}</div>

  <p *ngIf="(error$ | async); let errorMessage"  class="error">
    {{ errorMessage }}
  </p>
</header>

<div class="dots-container" [@dotsAnimationTrigger]=" (state$ | async) " (@dotsAnimationTrigger.done)="resetAnimationState($event)">
  <!-- NUMBERS -->
  <ng-container *ngIf="showNumbers; else showDots">
    <div *ngFor="let value of numbers; let index = index; trackBy: trackByFn ">
      {{ (value !== false) ? value : '' }}
    </div>
  </ng-container>

  <!-- DOTS -->
  <ng-template #showDots>
    <svg *ngFor="let dot of dots; let index = index; trackBy: trackByFn " viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
      <svg:circle class="outer-dot" r="20" fill="transparent" stroke="#aeadae" stroke-width="3" />
      <svg:circle r="1" class="inner-dot" [@dotAnimationTrigger]="dots[index] === true"
        (@dotAnimationTrigger.start)="onLoadingAnimation = true"
        (@dotAnimationTrigger.done)="doMainAnimation($event, index)" />
    </svg>
  </ng-template>
</div>

<div class="keys">
  <ion-fab-button class="key-button" fill="clear" type="button" *ngFor="let key of keys; let index = index" [class.center]="key === 0" (click)="press(key)" [disabled]="loading">
    {{ key }}
  </ion-fab-button>
  <ion-fab-button class="key-button backspace-button" fill="clear" type="button" (click)="backpace()" [disabled]="loading" *ngIf="!(values | isEmpty)" @fadeInOut>
    <ion-icon name="backspace"></ion-icon>
  </ion-fab-button>
</div>
