/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { getAvailablePaymentMethods, getAvailableRefundPaymentMethodsForOrder, getPaymentTypeByID, store } from '@springtree/eva-sdk-redux';
import { filter, includes, indexOf, isEmpty, sortBy, isNil } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { first, map, tap, take, filter as rxFilter, shareReplay } from 'rxjs/operators';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import isNotNil from '../../shared/operators/isNotNil';
import { TranslateService } from '@ngx-translate/core';
import { TGiftCardType } from '../eva-application-config/eva-application-config';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';

/** Represents the possible payments string values that we support */
export type TPaymentMethod = 'PIN' | 'CASH' | 'INTERBRANCH' | 'ADYEN' | 'ADYEN_DROPIN' | TGiftCardType | 'RITUALS-KADEOS' | 'RITUALS-CHEQUES' | 'RITUALS-OTHER' | 'EVAPAY' |
  'EVAPAY_MAIL' | 'EVAPAY_QR' | 'ADYEN_CHECKOUT_API' | 'MANUAL' | 'SCOTCH_EGC' | 'SCOTCH_EFT' | 'SCOTCH' | 'CUSTOM' | 'GIFTCARD' | 'LOYALTY' | 'ADYEN_POSSDK' |
  'ADYEN_POSSDK_CARDREADER' | 'ADYEN_POSSDK_TAPTOPAY';

/**
 * Special additional payment properties
 * Certain payment methods require additional information
 */
export interface AdditionalPaymentData {
  cardNumber?: string;
  pinCode?: string;
  password?: string;
  userID?: number;
  adyenMerchantAccount?: string;
  adyenChannel?: 1 | 2 | 3;
  adyenToken?: string;
}

export interface IPaymentMethodIncludingType {
  MethodModel: EVA.Core.AvailablePaymentMethod;
  Type: EVA.Core.AvailablePaymentMethodType;
}

export interface IAvailablePaymentMethodType extends EVA.Core.AvailablePaymentMethodType {
  IsCustom?: boolean
}

/** This provider will contain the available payment methods */
@Logger('[eva-payment-method-provider]')
@Injectable()
export class EvaPaymentMethodProvider implements ILoggable {

  public logger: Partial<Console>;

  public initialSelectedPaymentType: EVA.Core.AvailablePaymentMethodType;

  public initialSelectedPaymentParentInfo: EVA.Core.AvailablePaymentMethod;

  /**
   * List of payment methods this app will support
   */
  private readonly paymentWhiteList: TPaymentMethod[] = [
    'ADYEN_POSSDK',
    'CASH',
    'INTERBRANCH',
    'PIN',
    'RITUALS-KADEOS',
    'RITUALS-CHEQUES',
    'RITUALS-OTHER',
    'EVAPAY',
    'MANUAL',
    'SCOTCH_EGC',
    'SCOTCH_EFT',
    'SCOTCH',
    'CUSTOM',
    'EVAPAY_MAIL',
    'EVAPAY_QR',
    'GIFTCARD',
    'VALUTEC',
    'INTERSOLVE',
    'ADYEN_CHECKOUT_API',
    'APIGIFTCARD',
    'FASHIONCHEQUE',
    'LOYALTY'
  ];

  private readonly refundPaymentWhiteList: TPaymentMethod[] = [
    'ADYEN',
    'GIFTCARD',
    'ADYEN_DROPIN',
    'ADYEN_CHECKOUT_API',
    ...this.paymentWhiteList];

  /**
   * This is the order in which the payment methods will be returned in the
   * availablePaymentMethods$ stream
   * Any method not in the list will be returned last
   *
   */
  private readonly defaultPaymentMethodOrder: TPaymentMethod[] = [
    'EVAPAY',
    'INTERBRANCH',
    'ADYEN',
    'CASH',
    'PIN',
    'INTERSOLVE',
    'VALUTEC',
    'LOYALTY'
  ];

  /**
   * Sometimes there are payment methods, which we don't want to render everything for.
   * ADYEN_CHECKOUT_API is an example, where we only want to render the ADYEN_CHECKOUT_API_GIFTCARD but ignore the other payment method types.
   */
  private paymentMethodTypeWhiteList: { [key in TPaymentMethod]?: string[] } = {
    ADYEN_CHECKOUT_API: ['ADYEN_CHECKOUT_API_GIFTCARD'],
    ADYEN_POSSDK: ['ADYEN_POSSDK_CARDREADER', 'ADYEN_POSSDK_TAPTOPAY']
  };

  /** The currently selected payment method */
  public selectedPaymentMethodModelIncludingType$: BehaviorSubject<IPaymentMethodIncludingType> = new BehaviorSubject(null);

  public selectedRefundPaymentMethodModel$: BehaviorSubject<EVA.Core.RefundPaymentMethod> = new BehaviorSubject(null);

  public availablePaymentMethodModels$ = getAvailablePaymentMethods.getResponse$()
    .pipe(
      isNotNil(),
      map(serviceResponse => {
        // Filter available with the blacklist
        //
        const availableMethods = filter(serviceResponse.Results, (paymentMethod) => {
          const isNotInPaymentBlackList = paymentMethod.IsUsable && includes(this.paymentWhiteList, paymentMethod.Name);
          return isNotInPaymentBlackList;
        });

        // Return methods in sorted order
        //
        return sortBy(availableMethods, (availablePaymentMethodModel) => {
          const sortIndex = indexOf(this.defaultPaymentMethodOrder, availablePaymentMethodModel.Name);
          return sortIndex === -1 ? Infinity : sortIndex;
        });
      }),
      tap((paymentMethods) => this.logger.debug('Available payment methods (sorted)', paymentMethods)),
      tap((paymentMethods) => {
        // Check the selected payment method is still available
        //
        this.selectedPaymentMethodModelIncludingType$.pipe(take(1)).subscribe((selectedMethodIncludingType) => {
          // TODO: check the child type payment method is still available
          if (selectedMethodIncludingType && indexOf(paymentMethods, selectedMethodIncludingType.MethodModel) === -1) {
            this.logger.log('Unselecting payment method due to it being unavailable');
            this.selectedPaymentMethodModelIncludingType$.next(null);
          }
        });
      }),
      shareReplay(1)
    );

  public availableRefundPaymentMethodModels$ = getAvailableRefundPaymentMethodsForOrder.getState$().pipe(
    rxFilter(getAvailableRefundPaymentMethodsForOrderState => !getAvailableRefundPaymentMethodsForOrderState.isFetching),
    map(getAvailableRefundPaymentMethodsForOrderState => {
      // If this reducer has an error, we will return an empty array
      //
      if (getAvailableRefundPaymentMethodsForOrderState.error) {
        return [];
      }

      const refundPaymentMethods = getAvailableRefundPaymentMethodsForOrderState.response?.Methods || [];

      const availableRefundMethods = filter(refundPaymentMethods, refundPaymentMethod => {
        return includes(this.refundPaymentWhiteList, refundPaymentMethod.Code);
      });

      return sortBy(availableRefundMethods, (availablePaymentMethodModel) => {
        const sortIndex = indexOf(this.defaultPaymentMethodOrder, availablePaymentMethodModel.Code);
        return sortIndex === -1 ? Infinity : sortIndex;
      });
    }),
    tap(availableRefundPaymentMethodModels => {
      this.logger.debug('available refund payment methods (sorted)', availableRefundPaymentMethodModels);
    }),
    tap(availableRefundPaymentMethodModels => {
      // Check the selected payment method is still available
      //
      this.selectedRefundPaymentMethodModel$.pipe(take(1)).subscribe((selectedRefundMethod) => {
        if (selectedRefundMethod?.Code && availableRefundPaymentMethodModels.findIndex(method => method.Code === selectedRefundMethod.Code) === -1) {
          this.logger.log('Unselecting refund payment method due to it being unavailable');
          this.selectedRefundPaymentMethodModel$.next(null);
        }
      });
    })
  );

  constructor(private toastCtrl: EvaToastController, private $translate: TranslateService) { }

  public async selectRefundPaymentMethod(newRefundPaymentMethodCode: EVA.Core.RefundPaymentMethod) {
    const availableRefundPaymentMethodModels = await this.availableRefundPaymentMethodModels$.pipe(first()).toPromise();

    const isValidRefundPaymentMethod = availableRefundPaymentMethodModels.find(availableRefundPaymentMethodModel => {
      return availableRefundPaymentMethodModel.Code === newRefundPaymentMethodCode.Code;
    });

    if (isValidRefundPaymentMethod) {
      this.selectedRefundPaymentMethodModel$.next(newRefundPaymentMethodCode);
    } else {
      const message = this.$translate.instant('refund.payment.method.unavailable');

      this.toastCtrl.create({ message }).present();
    }
  }

  /** Changes the currently selected payment method to a different one */
  public async selectPaymentMethod(newPaymentMethod: IPaymentMethodIncludingType) {

    // When payment method is custom we use the Name instead of Code
    // @see https://n6k.atlassian.net/browse/OPTR-15820
    //
    const paymentMethodByName = await this.getPaymentMethodIncludingTypeByCode(
      newPaymentMethod.MethodModel.IsCustom ? newPaymentMethod.Type.Name : newPaymentMethod.Type.Code
    );

    const isValidPaymentMethod = !isNil(paymentMethodByName);

    if (isValidPaymentMethod) {
      this.selectedPaymentMethodModelIncludingType$.next(newPaymentMethod);
    } else {
      const message = this.$translate.instant('payment.method.unavailable', {
        paymentMethod: newPaymentMethod
      });

      this.toastCtrl.create({ message }).present();
    }
  }

  public async getPaymentMethodIncludingTypeByCode(paymentMethodTypeCode: string): Promise<IPaymentMethodIncludingType> {
    const paymentMethodModels = await this.availablePaymentMethodModels$.pipe(
      first(availablePaymentMethodModels => !isEmpty(availablePaymentMethodModels))
    ).toPromise();

    let paymentMethodIncludingType: IPaymentMethodIncludingType;
    for (const paymentMethodModel of paymentMethodModels) {
      const existCurrentPaymentMethodType = paymentMethodModel.Types.find(type => {
        // When payment method is custom we use the Name instead of Code
        // @see https://n6k.atlassian.net/browse/OPTR-15820
        //
        return (paymentMethodModel.IsCustom ? type.Name : type.Code) === paymentMethodTypeCode;
      });
      if (existCurrentPaymentMethodType) {
        paymentMethodIncludingType = {
          MethodModel: paymentMethodModel,
          Type: existCurrentPaymentMethodType
        };

        break;
      }
    }

    return paymentMethodIncludingType;
  }

  public async getRefundPaymentMethodTypeModelByCode(refundMethodTypeCode: string) {
    // PIN_ONLINE_REFUND doesnt really exist, and its just a 'PIN' refund with different logic. So we will map it back to 'PIN'
    if (refundMethodTypeCode === 'PIN_ONLINE_REFUND') {
      refundMethodTypeCode = 'PIN';
    }
    const refundMethods = await this.getRefundPaymentMethodModelByCode(refundMethodTypeCode);
    let paymentMethodTypeModel: EVA.Core.AvailablePaymentMethodType;

    paymentMethodTypeModel = refundMethods?.Types?.find(type => {
      // We should compare both Name and Code, in case we are dealing with custom or manual refunds
      // @see https://n6k.atlassian.net/browse/OPTR-19600
      // @see https://n6k.atlassian.net/browse/OPTR-19871
      const names = [type.Code, type.Code.toUpperCase(), type.Name, type.Name.toUpperCase()];

      return names.includes(refundMethodTypeCode);
    });

    // Sometimes we don't find the method looking at the Types array,
    // because the Name and Code are not matching at all (Rare cases e.g. using Adyen + Paypal)
    // In that case we compare directly the child with Parent for a matching
    // @see https://n6k.atlassian.net/browse/OPTR-19570
    if (isNil(paymentMethodTypeModel) && refundMethodTypeCode === refundMethods.Code) {
      paymentMethodTypeModel = this.initialSelectedPaymentType || refundMethods.Types[0];
    }

    return paymentMethodTypeModel;
  }

  public async getRefundPaymentMethodModelByCode(selectedRefundPaymentMethod: string) {
    const refundPaymentMethodModels = await this.availableRefundPaymentMethodModels$.pipe(
      first(availableRefundPaymentMethodModels => !isEmpty(availableRefundPaymentMethodModels))
    ).toPromise();

    const refundPaymentMethodModel = refundPaymentMethodModels.find(refundPaymentMethod => {
      if (refundPaymentMethod.IsCustom) {
        // When payment method is custom we use the Name instead of Code
        // @see https://n6k.atlassian.net/browse/OPTR-15820
        //
        return refundPaymentMethod.Types[0].Name === selectedRefundPaymentMethod;
      } else {
        return refundPaymentMethod.Code === selectedRefundPaymentMethod;
      }
    });

    if (isNil(refundPaymentMethodModel)) {
      this.logger.error(`setRefundPaymentMethodModel no matching refundPaymentMethodModels=${refundPaymentMethodModels.join(',')} with code=${selectedRefundPaymentMethod}`);
    }

    return refundPaymentMethodModel;
  }

  getPaymentMethodTypeModelByCode(paymentMethods: EVA.Core.AvailablePaymentMethod[], paymentMethodTypeCode: string) {
    let paymentMethodTypeModel: EVA.Core.AvailablePaymentMethodType;

    for (const paymentMethod of paymentMethods) {
      paymentMethodTypeModel = paymentMethod.Types.find(type => {
        // When payment method is custom we display the Name instead of Code
        // And becuase of that, in this case we also read the Name, not just the Code
        // @see https://n6k.atlassian.net/browse/OPTR-15820
        // @see https://n6k.atlassian.net/browse/OPTR-19406
        if (paymentMethod.IsCustom) {
          return (type.Name === paymentMethodTypeCode) || (type.Code === paymentMethodTypeCode);
        } else {
          return type.Code === paymentMethodTypeCode;
        }
      });

      if (paymentMethodTypeModel) {
        break;
      }
    }

    return paymentMethodTypeModel;
  }

  public async setRefundPaymentMethodModelByCode(refundPaymentMethodCode: string) {
    const refundPaymentMethodModel = await this.getRefundPaymentMethodModelByCode(refundPaymentMethodCode);

    if (refundPaymentMethodModel) {
      this.selectedRefundPaymentMethodModel$.next(refundPaymentMethodModel);
    } else {
      const message = this.$translate.instant('refund.payment.method.unavailable');

      this.toastCtrl.create({ message }).present();
    }
  }

  /**
   * We dont want to show all payment method types, so we filter them out conditionally. If there is are a "whitelist" for the given payment method.
   * If there is no whitelist, we will simply return all payment method types
   * @returns the potentially filtered payment method types, could sometimes be equal to the "paymentMethodTypes" method parameter incase there is no matching white list for the paymentMethodName.
   */
  public getSupportedPaymentMethodTypes(allowedPaymentMethodModel: EVA.Core.AvailablePaymentMethod): IAvailablePaymentMethodType[] {
    // The payment method we want to filter the types for
    const paymentMethodName: string = allowedPaymentMethodModel.Name;

    // Payment methods whitelist mapping
    const paymentMethodTypeWhiteListMapping = this.paymentMethodTypeWhiteList[paymentMethodName];

    // The payment method types we will be filtering items out of based on the whitelist
    const paymentMethodTypes: IAvailablePaymentMethodType[] = allowedPaymentMethodModel.Types;

    // When payment method is custom we want to display the Name instead of Code
    // @see https://n6k.atlassian.net/browse/OPTR-15820
    if (allowedPaymentMethodModel.IsCustom) {
      paymentMethodTypes.forEach(method => method.IsCustom = true);
    }

    // We make this check because sometimes we don't have the Types array filled
    // @see https://n6k.atlassian.net/browse/OPTR-18553
    const additionalCurrencies = allowedPaymentMethodModel.Types[0]?.Data?.AdditionalCurrencies;

    if (allowedPaymentMethodModel.Name === 'CASH' && additionalCurrencies?.length > 0) {
      allowedPaymentMethodModel.Types[0].Data.AdditionalCurrencies.forEach((additionalCurrency) => {
        const newName = `CASH-${additionalCurrency.CurrencyID}`;
        const newMethod: IAvailablePaymentMethodType =
          { ...allowedPaymentMethodModel.Types[0], Name: newName, Code: newName, Data: additionalCurrency };
        if (!paymentMethodTypes.find((method) => method.Name === newName)) {
          paymentMethodTypes.push(newMethod);
        }
      });
    }

    if (paymentMethodTypeWhiteListMapping) {
      return paymentMethodTypes.filter(paymentMethodType => paymentMethodTypeWhiteListMapping.includes(paymentMethodType.Code));
    } else {
      return paymentMethodTypes;
    }
  }

  public getPaymentTypeById(paymentTypeId: number) {
    const [action, fetchPromise] = getPaymentTypeByID.createFetchAction({
      ID: paymentTypeId,
    });

    store.dispatch(action);

    fetchPromise.catch(error => {
      this.logger.log('error fetching the order', error);
    });

    return fetchPromise;
  }
}
