import {
  ContentChildren, Directive,

  HostBinding, Input,



  Optional, QueryList
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValMessageDirective } from './val-message.directive';
import { MessagesConfiguration } from './val-messages-config';


@Directive({
  selector: '[val-messages]'
})
export class ValMessagesDirective {

  @Input('val-messages') control: AbstractControl;

  @ContentChildren(ValMessageDirective) _errorChildren: QueryList<ValMessageDirective>;

  // This will be called every change detection cycle. There are possible improvements here, like if the errors object didn't change. Return early
  //
  @HostBinding('style.display')
  get displayValue() {

    // If we should not show errors, we want to hide them all
    //
    if (!this.shouldShowError()) {
      this._errorChildren.forEach( errorChild => errorChild.hide());

      return;
    }

    let errorShown = false;

    this._errorChildren.forEach(errorChild => {
      const errorMessageExists = this.control.errors[errorChild.errorName];

      if ( !errorShown && errorMessageExists)  {
        errorChild.show();
        errorShown = true;
      } else {
        errorChild.hide();
      }
    });

    return errorShown ? 'block' : 'none';
  }

  constructor( @Optional() private config: MessagesConfiguration ) { }


  shouldShowError(): boolean {
    let shouldShowError = Object.keys(this.control.errors || {}).length > 0;

    const touchedRule = this.config.showOn.indexOf('touched') !== -1;

    if ( touchedRule && !this.control.touched ) {
      shouldShowError = false;
    }

    const dirtyRule = this.config.showOn.indexOf('dirty') !== -1;

    if ( dirtyRule && !this.control.dirty ) {
      shouldShowError = false;
    }

    return shouldShowError;
  }
}
