import { Component } from '@angular/core';
import { createIdentificationPinForEmployee, getCurrentUser, store } from '@springtree/eva-sdk-redux';
import { filter ,  map ,  take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ModalController, NavParams } from '@ionic/angular';
import isNotNil from '../../shared/operators/isNotNil';
import { isNil } from 'lodash';
import { IDotsFilledCallbackResult, TDotsFilledInCallback } from 'src/app/components/pin-input/pin-input.component';

/**
 * Simple interface containing user details that we need for the create pin call
 */
interface IUserInfo {
  userId: number;
  currentUserOrganizationId: number;
}

/**
 * Result from the create pin modal.
 *
 * Based on this, we show a message to the user that the pin was successfully set-up
 *
 */
export interface ICreatePinResult {
  pinCreated: boolean;
}

/**
 * @see https://eva2015.atlassian.net/browse/OPTR-6542
 */
@Component({
  selector: 'eva-edit-pin-modal',
  templateUrl: 'edit-pin-modal.html',
})
export class EditPinModalComponent {

  /**
   * Header text of the modal
   */
  public readonly modalTitle: number = this.navParams.get('modalTitle');

  public constructor(
    private $translate: TranslateService,
    private modalCtrl: ModalController,
    private navParams: NavParams
  ) {
  }

  /**
   * User initiated action of closing the modal
   */
  public closeModal() {
    // User decided to close this modal so the pin was not created
    //

    const pinNotCreatedData: ICreatePinResult = {
      pinCreated: false
    };

    this.dismiss(pinNotCreatedData);
  }

  /**
   * Performs pin validation and sets the PIN
   */
  createPin: TDotsFilledInCallback = async (pinValue: number[]) => {
    const stringPinValue = pinValue.join('');
    let callbackResult: IDotsFilledCallbackResult = {
      animationState: 'success'
    };

    // Attempt to create the PIN
    //
    const currentUser = await this.getCurrentUser();
    let createPinSuccess = false;

    try {
      const result = await this.dispatchCreatePin(stringPinValue, currentUser);
      createPinSuccess = this.wasPinCreated(stringPinValue, result);
    } catch { }

    // If we encounter any error, we show the Pin too weak error message to the user
    //
    if (!createPinSuccess) {
      callbackResult = {
        animationState: 'failure',
        message: this.$translate.instant('pin.too.weak')
      };
    } else {
      // Close the modal automatically if we did not encounter any errors with the pin creation call
      //

      this.dismiss({ pinCreated: true });
    }

    return callbackResult;
  }

  /**
   * Creates the PIN by passing
   *
   * Pin value
   * Logged in user ID
   * Logged in user current organizational unit
   *
   * We handle any errors in the caller function
   */
  private async dispatchCreatePin(pin: string, userInfo: IUserInfo) {
    const [createPinAction, createPinPromise] = createIdentificationPinForEmployee.createFetchAction({
      OrganizationUnitID: userInfo.currentUserOrganizationId,
      Pin: pin
    });

    store.dispatch(createPinAction);
    const createPinResult = await createPinPromise;

    return createPinResult;
  }

  /**
   * Close the modal and pass pin creation result
   */
  private dismiss(result: ICreatePinResult): void {
    this.modalCtrl.dismiss(result);
  }

  /**
   * Validates that the create pin call is successful
   */
  private wasPinCreated(
    userChosenPinValue: string,
    createPinResponse: EVA.Core.Management.CreateIdentificationPinForEmployeeResponse
  ): boolean {

    // we consider the create pin call a success if we don't have any error
    // and the returned pin matches the one that we sent
    //
    const createPinSuccess =
      createPinResponse.Pin === userChosenPinValue
      && isNil(createPinResponse.Error);

    return createPinSuccess;
  }

  /**
   * Get the current user and map it to a simple interface which contains just what we need:
   *
   * User ID
   * Current Organization ID
   */
  private async getCurrentUser(): Promise<IUserInfo> {
    const currentUserPromise = getCurrentUser.getState$().pipe(
      filter( state => state.isAnonymous === false ),
      map( state => state.response ),
      isNotNil(),
      map( response => response.User ),
      take(1)
    ).toPromise();

    const loggedInUser = await currentUserPromise;

    const userInfo: IUserInfo = {
      userId: loggedInUser.ID,
      currentUserOrganizationId: loggedInUser.CurrentOrganizationID
    };

    return userInfo;
  }
}
