import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { getShoppingCart, getShoppingCartInfo } from '@springtree/eva-sdk-redux';
import { flatten, get, isEmpty, isNil } from 'lodash';
import { Observable, of } from 'rxjs';
import { filter, map, mergeMap, take, withLatestFrom } from 'rxjs/operators';
import { PickDiscountOptionRequirementsProvider } from 'src/app/services/pick-discount-option-requirements/pick-discount-option-requirements';
import { ProductDiscountOptionService } from 'src/app/services/product-discount-option/product-discount-option.service';
import { IntlNumberPipe } from '../../pipes/intl-number/intl-number';
import { slideUpDown } from '../../shared/animations';
import { Logger } from '../../shared/decorators/logger';
import isRequired from '../../shared/decorators/members/is-required';
import isNotNil from '../../shared/operators/isNotNil';
import { TGiftLineRequirements } from '../gift-lines/gift-lines.component';

export interface IViewPickableProduct {
  Price: number;
  CurrencyID: string;
  Description: string;
  ID: number;
  CustomID: number;
  blobGUID: string;

  tierDescription: string;
}


/**
 * This component will be usde in the PDO page and modal depending on where its needed
 */
@Logger('[product-discount-options-content-component]')
@Component({
  selector: 'eva-product-discount-options-content',
  templateUrl: 'product-discount-options-content.component.html',
  styleUrls: ['product-discount-options-content.component.scss'],
  animations: [slideUpDown],
  providers: [IntlNumberPipe]
})
export class ProductDiscountOptionsContentComponent implements OnInit {

  public logger: Partial<Console>;

  /** Order line id of the discount line */
  @isRequired
  @Input()
  orderLineId: number;

  @Output()
  onSelectDiscountOption = new EventEmitter<IViewPickableProduct>();

  public selectDiscountOption(product: IViewPickableProduct|null) {
    this.currentSelectedProductId$ = of(!isNil(product) ? product.ID : null);
    this.onSelectDiscountOption.emit(product);
  }

  private currentOptionsPerLine$ = getShoppingCart.getState$().pipe(
    filter( state => !state.isFetching ),
    map (state => state.response),
    isNotNil(),
    map(response => response.AdditionalOrderData?.DiscountPickProductOptions[this.orderLineId]),
    isNotNil()
  );

  /**
   * Gets the current tier and also the previous tiers. We will map these to the possible products for discount
   */
  private unlockedDiscountTiers$: Observable<EVA.Core.OrderPickProductDiscountOptions.PickProductTierOptions[]> = this.currentOptionsPerLine$.pipe(
    map(options => {
      const currentAndPreviousTiers = options.Tiers
        .filter(tier => tier.IsCurrentTier || tier.Difference < 0)
        .sort((tierA, tierB) => tierA.Difference - tierB.Difference);

      return currentAndPreviousTiers;
    })
  );

  private nextTier$: Observable<EVA.Core.OrderPickProductDiscountOptions.PickProductTierOptions> = this.currentOptionsPerLine$.pipe(
    map(options => options.Tiers),
    map((tiers) => {
      // We need to get only the next tiers
      // That is achieved by first filtering the current tier (IsCurrentTier=true)
      // and also filtering tiers that have Difference < 0
      // Difference property is returned by the backend to specify the amount we need to spent to reach that particular tier
      // For a previous tier, this difference is negative
      //
      const nextTiers = tiers
        .filter(tier => !tier.IsCurrentTier && tier.Difference > 0)
        .sort(tier => tier.Difference);

      if (!isEmpty(nextTiers)) {
        return nextTiers[0];
      }
    })
  );

  public possibleProductsForDiscount$: Observable<IViewPickableProduct[]> = this.unlockedDiscountTiers$.pipe(
    map(unlockedDiscountTiers => {
      // Get the list of GWP for each unlocked discount tier
      //
      const unlockedGwpProductsRaw: IViewPickableProduct[][] = unlockedDiscountTiers.map(
        unlockedDiscountTier => this.$productDiscountOptionService.mapDiscountTierProducts(unlockedDiscountTier.Products, unlockedDiscountTier.Description)
      );

      // Obtain a flat array of results from the above
      //
      const possibleProductsForDiscount = flatten(unlockedGwpProductsRaw);

      return possibleProductsForDiscount;
    })
  );

  public nextDiscountTierDescription$: Observable<string> = this.nextTier$.pipe(
    isNotNil(),
    map(tier => tier.From),
    mergeMap(from => {
      return this.totalAmountInCart$.pipe(
        map(totalAmount => {
          return from - totalAmount;
        })
      );
    }),
    // Make it a localized string with currencySymbol
    mergeMap(difference => {
      return this.currencyId$.pipe(
        map(currencyId => this.intlNumberPipe.transform(difference, {
          currency: currencyId,
          currencyDisplay: 'symbol',
          style: 'currency'
        }))
      );
    }),
    map(differenceString => {
      return this.$translate.instant('next-discount-tier-description', { amount: differenceString });
    })
  );

  public nextTierProductsForDiscount$: Observable<IViewPickableProduct[]> = this.nextTier$.pipe(
    isNotNil(),
    map(nextTier => {
      return this.$productDiscountOptionService.mapDiscountTierProducts(nextTier.Products, nextTier.Description);
    })
  );

  public currentSelectedProductId$: Observable<number> = getShoppingCart.getState$().pipe(
    filter(state => !isNil(state.pickDiscountProducts) &&  !isNil( state.pickDiscountProducts[this.orderLineId]?.productOrderLine ) ),
    map(state => state.pickDiscountProducts[this.orderLineId].productOrderLine.ProductID)
  );

  public currentSelectedProductRequirements$: Observable<TGiftLineRequirements|null> = getShoppingCart.getResponse$().pipe(
    isNotNil(),
    map((getShoppingCartResponse) => {
      return this.$pickDiscountOptionRequirements.getSelectedProductRequirements({
        orderLineId: this.orderLineId,
        getShoppingCartResponse
      });
    })
  );

  public currencyId$ = getShoppingCartInfo.getState$().pipe(
    map(state => state.response),
    filter(cartInfo => !isNil(cartInfo)),
    map(cartInfo => cartInfo.CurrencyID)
  );

  /**
   * User needs to confirm their seletion if they already have one and there is an invalid order requirement
   */
   public confirmationNeeded$: Observable<boolean> = getShoppingCart.getState$().pipe(
    filter(state => !isNil(state.response)),
    withLatestFrom(this.currentSelectedProductId$),
    map(([state, currentSelectedProductId]) => {
      const orderLineRequirements = (state.response?.AdditionalOrderData?.RequiredData?.OrderLines || {})[this.orderLineId];
      const invalidPickDiscountProductRequirement = (orderLineRequirements ?? []).some(orderLineRequirement => {
          return orderLineRequirement.Name === 'PickDiscountProduct' && !orderLineRequirement.IsValid;
        });
      const confirmationNeeded = invalidPickDiscountProductRequirement && !isNil(currentSelectedProductId);

      return confirmationNeeded;
    })
  );

  private totalAmountInCart$ = getShoppingCart.getState$().pipe(
    map(state => state.response),
    filter(cartInfo => !isNil(cartInfo)),
    map(cartInfo => cartInfo.Amounts?.Total?.InTax)
  );

  constructor(
    public navCtrl: NavController,
    private $translate: TranslateService,
    private intlNumberPipe: IntlNumberPipe,
    private $pickDiscountOptionRequirements: PickDiscountOptionRequirementsProvider,
    private $productDiscountOptionService: ProductDiscountOptionService
  ) {
  }

  async ngOnInit() {
    // for confirmation without selection
    const currentSelectedProductId = await this.currentSelectedProductId$.pipe(take(1)).toPromise();
    if (!isNil(currentSelectedProductId)) {
      const possibleProducts = await this.possibleProductsForDiscount$.pipe(take(1)).toPromise();
      const possibleProduct = possibleProducts.find(product => product.ID === currentSelectedProductId);
      this.selectDiscountOption(possibleProduct);
    }
  }
}
