import { Injectable } from '@angular/core';
import {
  getCurrentUser,
  getOrganizationUnitDetailed,
  getProductAvailability,
  getShopsByProximity,
  store,
} from '@springtree/eva-sdk-redux';
import { find, head, isEmpty, isNil } from 'lodash';
import { Observable } from 'rxjs';
import { filter, first, map, pluck, withLatestFrom } from 'rxjs/operators';
import { EvaFeedback } from '../../shared/decorators/eva-feedback';
import { Logger, ILoggable } from '../../shared/decorators/logger';
import isNotNil from '../../shared/operators/isNotNil';

@Logger('[shops-provider]')
@Injectable()
export class ShopsProvider implements ILoggable {
  logger: Partial<Console>;

  public currentOrganisationDetails$ = getOrganizationUnitDetailed
    .getResponse$()
    .pipe(isNotNil());

  public shopsCloseBy$ = getShopsByProximity.getState$().pipe(
    filter((state) => state.isFetching === false),
    map((state) => state.response),
    isNotNil(),
    withLatestFrom(this.currentOrganisationDetails$.pipe(pluck('ID'))),
    map(([response, currentOrganizationId]) => {
      /** Shops that do not have a organisation unit id matching ours
       */
      const shops = response.Shops.filter(
        (shop: any) => shop.ID !== currentOrganizationId
      );

      return shops;
    })
  );

  public shopsQuantityMap$: Observable<Map<number, number>> = getProductAvailability.getState$().pipe(
    filter((state) => !state.isFetching),
    map((state) => state.response),
    filter((response) => !isNil(response) && !isEmpty(response.PickupOrganizationUnits)),
    map((response) => response.PickupOrganizationUnits),
    map((pickupOrganizationUnits) => {
      const shopsQuantityMap = new Map<number, number>();

      pickupOrganizationUnits.forEach(pickupOrganizationUnit => {
        const { Products: products } = pickupOrganizationUnit;
        if (!isEmpty(products)) {
          products.forEach(product => shopsQuantityMap.set(pickupOrganizationUnit.ID, product.QuantityAvailable))
        }
      });

      return shopsQuantityMap;
    })
  );

  @EvaFeedback({
    i18nFailKey: 'shops.failed.to.fetch',
  })
  public async getShopsByProximity(productID: number) {
    try {

  const user = await getCurrentUser.getState$().pipe(isNotNil(), first(), map(state => state.response)).toPromise();

  const [organizationAction, organizationPromise] = await getOrganizationUnitDetailed.createFetchAction({
    ID: user.User.CurrentOrganizationID
  });

  store.dispatch(organizationAction);

  const organization = await organizationPromise;

  // We don't want to pass the service a null latitude or longitude, otherwise the backend will throw an exception
  //
  const Latitude = organization.Latitude || undefined;
  const Longitude = organization.Longitude || undefined;
  const CountryID = user.User.CountryID || undefined;

  const [getShopsByProximityAction, promise] =
    getShopsByProximity.createFetchAction({
      Latitude: Latitude,
      Longitude: Longitude,
      CountryID: CountryID,
      ProductID: productID,
      HasStock: true,
    });

  store.dispatch(getShopsByProximityAction);

      await promise;

      this.getAvailability(productID);

      return promise;

    } catch (error) {
      this.logger.error('Failed to get stores', error);
    }
  }

  private async getAvailability(productID: number) {
    const [getProductAvailabilityAction] =
    getProductAvailability.createFetchAction({
      Options: {
        Pickup: {
          IncludePickupOrganizationUnits: true
        },
        QuantityAvailable: true
      },
      Products: [
        {
          ID: productID,
          QuantityRequested: 0,
        },
      ],
    });
    store.dispatch(getProductAvailabilityAction);
  }
}
