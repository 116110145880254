import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { addProductToOrder, getShoppingCart, IEvaProductRequirement } from '@springtree/eva-sdk-redux';
import { cloneDeep, isNil, isString } from 'lodash';
import { of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { fadeInOut } from '../../shared/animations';
import { Logger } from '../../shared/decorators/logger';
import isRequired from '../../shared/decorators/members/is-required';
import isNotNil from '../../shared/operators/isNotNil';

export type IRequirements = EVA.Core.ProductRequirementLineModel[];

/**
 * This component will be responsible for providing a user interface which can be used to change a product's requirements
 * said requirements will be handled by a parent component via the designated services (UpdateProductRequirementValuesForOrderLine)
 * This component can be used on both products that are or aren't in the current cart. Incase a product is infact
 */
@Logger('[product-details-requirements-component]')
@Component({
  selector: 'eva-product-details-requirements',
  templateUrl: 'product-details-requirements.component.html',
  styleUrls: ['product-details-requirements.component.scss'],
  animations: [ fadeInOut ]
})
export class ProductDetailsRequirementsComponent implements OnInit, OnDestroy {

  logger: Partial<Console>;

  private _model: IRequirements;

  public get model(): IRequirements {
    return this._model;
  }

  /** The model is the requirement ID, Name and Value */
  @Input()
  public set model(newModel: IRequirements) {
    this._model = newModel;

    this.modelChange.emit(newModel);
  }

  @Input() onAddFail: Subject<void>;

  @Input() orderLineId: number;

  /**
   * This will contain the requirements of a given product, we need this to construct the form ALTHOUGH it will not
   * contain any values for our form
   */
  @isRequired
  @Input() productRequirements: IEvaProductRequirement[];

  @Output() modelChange = new EventEmitter<IRequirements>();

  @Output() requirementsValid = new EventEmitter<boolean>();

  @Output() requirementUpdated$ = new EventEmitter<void>();


  public addedProduct$ = addProductToOrder.getResponse$()
    .pipe(isNotNil());

  public stop$ = new Subject<void>();

  public form: FormGroup;

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.createForm();
    this.onFail();

    // If this component is used in the PDP, it might not have order requirements,
    // because its maybe not in the cart yet
    //
    if ( this.hasOrderLineId ) {
      this.getCurrentCartRequirements();
    } else {
      this.logger.log('using this component in pdp...');
    }
  }

  /**
   * Increases the count of an array input
   * @param requirement_id
   */
  inCreaseCount(requirement_id: number) {
    const formArray = <FormArray>this.form.controls[requirement_id];
    formArray.push(new FormControl(null));
  }

  /**
   * Removes this item from the array input
   * @param requirement_id
   * @param index
   */
  removeArrayItem( requirement_id: number, index: number ) {
    const formArray = <FormArray>this.form.controls[requirement_id];
    formArray.removeAt(index);
  }

  /**
   * Create a formGroup out of the product_requirements of this product
   */
  async createForm() {

    // If we have an order line id, we will actually check the AdditionalOrderData.ProductRequirements to see if this component
    // already had any values set, this will ensure its aware of any earlier filled in requirements
    //
    if ( this.hasOrderLineId ) {
      try {
        const productRequirementValues: IRequirements = await getShoppingCart.getResponse$().pipe(
          isNotNil(),
          map( response => {

            const requirementsResponse = response.AdditionalOrderData?.ProductRequirements?.find(requirementObject => requirementObject.OrderLineID === this.orderLineId);
            const matchingProductRequirements = requirementsResponse?.RequirementModels ?? [];

            return matchingProductRequirements;
          }),
          take(1)
        ).toPromise();

        if ( productRequirementValues ) {
          this.model = productRequirementValues;
        }
      } catch ( error ) {
        this.logger.log( error );
      }
    }

    const formGroupControls: { [ key: string ]: FormControl | FormArray } = {};

    this.productRequirements.forEach((requirement: IEvaProductRequirement) => {

      if ( requirement.is_array ) {
        /**
         * For now we do not support arrays
         */
      } else {

        /**
         * If the value is undefined, we want to default to null
         */
        let controlValue = null;

        let matchingModelEntry = this.model.find( modelEntry => modelEntry.ID === requirement.id );

        if ( !isNil( matchingModelEntry ) ) {
          controlValue = matchingModelEntry.Value;

          /**
         * One exception we default the control value to false for booleans
         */
        } else if ( requirement.data_type === 'Bool' ) {
          controlValue = false;
        } else if ( requirement.data_type === 'String' ) {
          controlValue = '';
        }

        const validators = [];

        if ( requirement.is_required ) {
          validators.push( Validators.required );
        }

        if ( requirement.data_type === 'Integer' ) {
          validators.push( Validators.pattern( '[0-9]+' ) );
        } else if ( requirement.data_type === 'Decimal' ) {
          validators.push( Validators.pattern( '[0-9]+(.[0-9]{1,2})?' ) );
        }

        formGroupControls[requirement.id] = new FormControl( controlValue, validators );

      }
    } );

    this.form = this.fb.group(formGroupControls);

    this.form.valueChanges
    .pipe(
      startWith(this.form.value)
    )
    .subscribe( newFormValues => {
      if ( !!this.model ) {
        Object.keys(newFormValues).forEach((requirementId: string) => {
          /** Cloning the model so we can mutate it and fire the setter */
          const modelClone = cloneDeep(this.model);

          const numericRequirementId = parseInt(requirementId, 10);

          // We will take the value from the form, and make sure to cast if it necessary
          //
          const requirementValueCasted = this.cast(numericRequirementId, this.productRequirements, newFormValues[requirementId]);;

          // Find the matching requirement index
          //
          const requirementIndex = modelClone.findIndex(requirement => requirement.ID === numericRequirementId);

          // Use the index to update the requirement value
          //
          if (requirementIndex > -1) {
            modelClone[requirementIndex].Value = requirementValueCasted;
          } else {
            // If we dont find the requirement, we will add it to the model
            modelClone.push({
              ID: numericRequirementId,
              Name: this.productRequirements.find(requirement => requirement.id === numericRequirementId)?.name,
              Value: requirementValueCasted
            });
          }

          // Overriding the model with the newly created one to ensure the setter is fired
          // and the new value is sent to our parent
          //
          this.model = modelClone;
        });
        this.requirementsValid.emit(this.form.valid);
      }
    });
  }

  /**
   * Sets up to a listener to a stream that will be passed by our host, this stream
   * will notify us whenever adding a product has failed (locally) due to the requirements being invalid
   */
  onFail() {
    (this.onAddFail || of(null) )
    .pipe(
      filter(() => !isNil(this.form)),
      takeUntil(this.stop$)
    )
    .subscribe(() => {

      Object.keys(this.form.controls).forEach((controlName) => {
        const row = this.form.get(controlName); // 'control' is a FormControl

        /**
         * We cannot use this directly `row.constructor.name === 'FormControl'`.
         * The minify process will change classes names when building for production
         * @see https://n6k.atlassian.net/browse/OPTR-16968
         */

        if (row instanceof FormControl) {
          const control = row as FormControl;
          control.markAsDirty();
          control.markAsTouched();
          control.updateValueAndValidity();
        } else if (row instanceof FormArray) {
          const array = row as FormArray;
          array.controls.forEach((control) => {
            control.markAsDirty();
            control.markAsTouched();
            control.updateValueAndValidity();
          });
        }

        this.logger.log({row});
      });
    });
  }

  /**
   * Get the set requirements for the current orderLine
   */
  async getCurrentCartRequirements() {
    this.model = await getShoppingCart.getResponse$()
      .pipe(
        isNotNil(),
        map(response => response.AdditionalOrderData?.ProductRequirements?.find(requirementObject => requirementObject.OrderLineID === this.orderLineId)),
        map(requirementObject => requirementObject?.RequirementModels ?? [] ),
        isNotNil(),
        takeUntil(this.stop$),
        distinctUntilChanged(),
        tap(() => {
          this.createForm();
        })
      ).toPromise();
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  /**
   * Casts the input to the correct value
   *
   * @param requirement_id
   * @param requirements
   * @param originalValue
   */
  private cast(requirement_id: number, requirements: IEvaProductRequirement[], originalValue: any): EVA.Core.ProductRequirementLineModel {
    // First find the mathing requirement
    const requirement: IEvaProductRequirement = requirements.find((req) => req.id === requirement_id);

    let originalValueArray = originalValue;

    if (!requirement?.is_array) {
      originalValueArray = [originalValueArray];
    }

    originalValueArray.forEach( ( value: any, index: number ) => {
      switch ( requirement?.data_type ) {
        // for STRING type it's used that default value '' set on form
        case 'Decimal':
          // Javascript's parseFloat doesn't take a locale parameter so we need to replace it
          // @see https://n6k.atlassian.net/browse/OPTR-16967
          const parsedValue = (new String(value)).replace(/,/, '.');
          value = isNaN(parseFloat(parsedValue)) ? null : parseFloat(parsedValue);
          break;
        case 'Integer':
          value = isNaN(parseInt(value, 10)) ? null : parseInt(value, 10);
          break;
        case 'Bool':
          value = ( value === true || isString( value ) && value.toLowerCase() === 'true' ) ? true : false;
          break;
      }
      originalValueArray[index] = value;
    });

    if (!requirement?.is_array) {
      originalValueArray = originalValueArray[0];
    }

    return originalValueArray;
  }

  /**
   * Checks whether this component has an order line id set or not
   * */
  private get hasOrderLineId(): boolean {
    const hasOrderLineId = !isNil(this.orderLineId);

    return hasOrderLineId;
  }
}
