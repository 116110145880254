import { Injectable } from "@angular/core";
import { PreloadingStrategy, Route } from "@angular/router";
import { Observable, of } from "rxjs";
import { ILoggable, Logger } from "../shared/decorators/logger";

@Logger('[custom-preloading-strategy-service]')
@Injectable({
  providedIn: "root",
})
export class CustomPreloadingStrategyService implements PreloadingStrategy, ILoggable {
  logger: Partial<Console>;

  preload(route: Route, importFunction: () => Observable<any>): Observable<any> {
    if (route.data && route.data.preload) {
      // this will preload this module
      return importFunction();
    }
    return of(null);
  }
}
