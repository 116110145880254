import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EvaApplicationConfigProvider } from 'src/app/services/eva-application-config/eva-application-config';
import { ILoggable, Logger } from '../../shared/decorators/logger';

export type TCustomerDetail = Pick<EVA.Core.UserDto, 'FullName' | 'PhoneNumber' | 'EmailAddress' | 'FiscalID' | 'ID' | 'CountryID'>;

export type TCustomerCompany = Pick<EVA.Core.CompanyDto, 'VatNumber' | 'RegistrationNumber' | 'Name'>;

/**
 * This component will be responsible for rendering customer cards
 */
@Logger('[user-details-card-component]')
@Component({
  selector: 'eva-user-details-card',
  templateUrl: 'user-details-card.component.html',
  styleUrls: ['user-details-card.component.scss'],
})
export class UserDetailsCardComponent implements ILoggable {

  public logger: Partial<Console>;

  public showFiscalID$ = this.$applicationConfig.userRequirements$.pipe(
    map(requirements => requirements.FiscalID?.Display)
  );

  @Input() user$: Observable<TCustomerDetail>;

  @Input() userCompany: TCustomerCompany;

  constructor(private $applicationConfig: EvaApplicationConfigProvider) {}

}
