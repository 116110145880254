import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';
import { INewScreenSlides } from '../contentful';

/**
 * This component is responsible for showing the user information about new features. You have to ensure this component isn't opened until $contenful is initialized
 */
@Logger('[whats-new-component-modal]')
@Component({
  selector: 'whats-new-modal',
  templateUrl: './whats-new-modal.html'
})
export class WhatsNewComponentModal implements ILoggable {

  logger: Partial<Console>;

  data: INewScreenSlides = this.navParams.get('whatsNewData');

  constructor(
    private navParams: NavParams,
    private modalController: ModalController
  ) { }

  dismiss() {
    this.modalController.dismiss();
  }
}
