import { Injectable } from '@angular/core';
import { getAvailableSubscriptions, store } from '@springtree/eva-sdk-redux';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ILoggable, Logger } from '../../shared/decorators/logger';

export interface ISubscriptionDetails extends Partial<EVA.Core.GetAvailableSubscriptionsResponse.SubscriptionDto> {
  requirements?: EVA.Core.UserRequirementForSubscriptionResponse[];
  inquiryData?: EVA.Core.GetInquiryResponse.InquiryData;
}

export interface ISubscriptionValues {
  id: number;
  checked?: boolean;
  inquiry?: { [key: string]: any };
  requirements?: { [key: string]: any };
  isValid?: boolean;
}

export interface IInquiryDataStructure {
  subId: number;
  inquiryId: number;
  items: EVA.Core.GetInquiryResponse.InquiryItemData[];
}

@Logger('[available-subscriptions-provider]')
@Injectable()

export class AvailableSubscriptionsProvider implements ILoggable {

  public logger: Partial<Console>;

  private _userRequirementsForSubs$ = new BehaviorSubject<{ [key: number]: EVA.Core.UserRequirementForSubscriptionResponse[] }>(null);

  public get userRequirementsForSubs$(): Observable<{ [key: number]: EVA.Core.UserRequirementForSubscriptionResponse[] }> {
    return this._userRequirementsForSubs$.asObservable();
  }

  // Curently selected subscription in order to trigger the corresponding panel (inquiry)
  private _selectedSubscription$ = new BehaviorSubject<ISubscriptionDetails>(null);
  public get selectedSubscription$(): Observable<ISubscriptionDetails> {
    return this._selectedSubscription$.asObservable();
  }

  // Used to store subscription/inquiries/subscriptionRequirements before saving
  private _subscriptionValues$ = new BehaviorSubject<ISubscriptionValues[]>([]);
  public get subscriptionValues$(): Observable<ISubscriptionValues[]> {
    return this._subscriptionValues$.asObservable();
  }

  // Data structure that helps getting customField data when saving an inquiry
  private _inquryDataStructure$ = new BehaviorSubject<IInquiryDataStructure[]>([]);
  public get inquiryDataStructure$(): Observable<IInquiryDataStructure[]> {
    return this._inquryDataStructure$.asObservable();
  }

  public setSelectedSubscription(sub: ISubscriptionDetails) {
    this._selectedSubscription$.next(sub);
  }

  public setUserRequirementsForSubs(requirements: { [key: number]: EVA.Core.UserRequirementForSubscriptionResponse[] }) {
    this._userRequirementsForSubs$.next(requirements);
  }

  /**
   * We keep the user data here, in order to prefill subscription
   * or requirements for subscriptions (phoneNumber/emailAddress)
   */
  private _userData$ = new BehaviorSubject<{ customer: EVA.Core.GetUserResponse; phoneNumbers: EVA.Core.UserPhoneNumberModel[]; }>(null);
  public get userData$(): Observable<{ customer: EVA.Core.GetUserResponse; phoneNumbers: EVA.Core.UserPhoneNumberModel[]; }> {
    return this._userData$.asObservable();
  }

  public setUserData(userData: { customer: EVA.Core.GetUserResponse; phoneNumbers: EVA.Core.UserPhoneNumberModel[]; }) {
    this._userData$.next(userData);
  }

  /**
   * we use this stream to make sure we have availableSubscriptions in the response even if the call fails at start up
   * @see https://n6k.atlassian.net/browse/OPTR-10403
   */
  public getAvailableSubscriptionsStream$() {
    this.refetchAvailableSubscrptions();
    return getAvailableSubscriptions.getResponse$();
  }

  // We want to propagate the same value to other subscriptions who has the same customFieldID
  // @see https://n6k.atlassian.net/browse/OPTR-20673
  public propagateSameValueCustomFields(key,value){
    const subCurrentValues = this._subscriptionValues$.value;

    subCurrentValues?.forEach(subscription => {
      if(key in subscription.inquiry){
        subscription.inquiry[key] = value;
      }
    });

    if(subCurrentValues){
      this._subscriptionValues$.next(subCurrentValues);
    }
  }

  // We want to check if the value already exists on other subscriptions same customFieldID
  // @see https://n6k.atlassian.net/browse/OPTR-20673
  public checkExistingValueOnAnotherSubscription(customFieldID:string, skipCurrentSubscriptionID:number){
    const subCurrentValues = this._subscriptionValues$.value;
    let found;

    subCurrentValues?.forEach(subscription => {
      // When checking we skip current subscription to avoid getting same value
      if(subscription.id != skipCurrentSubscriptionID && customFieldID in subscription.inquiry && subscription.inquiry[customFieldID]){
        found = subscription.inquiry[customFieldID];
      }
    });

    return found;
  }

  private async refetchAvailableSubscrptions() {
    const availableSubscriptions = await getAvailableSubscriptions.getResponse$().pipe(first()).toPromise();

    if (!availableSubscriptions?.Subscriptions) {
      this.logger.log('Refetching available subscriptions');
      await this.fetchAvailableSubscriptions();
    }
  }

  private async fetchAvailableSubscriptions() {
    const [action, fetchPromise] = getAvailableSubscriptions.createFetchAction();
    store.dispatch(action);
    try {
      await fetchPromise;
      this.logger.log('Available subscriptions fetched successfully');
    } catch (error) {
      this.logger.error('Fetching available subscriptions failed');
    }
  }

  public setSubscriptionValues(subValues: ISubscriptionValues, fromCheck: boolean) {
    if (subValues) {
      let oldSubValues = this._subscriptionValues$.value;
      let entryExists;

      if (oldSubValues?.length) {
        entryExists = oldSubValues.find((sub) => sub.id === subValues.id);
      } else {
        oldSubValues = [];
        entryExists = false;
      }

      let newSubValues: ISubscriptionValues[];

      if (entryExists) {
        newSubValues = oldSubValues.map((subValue) => {
          if (subValue.id === subValues.id) {
            if (fromCheck) {
              return {...subValue, checked: subValues.checked };
            } else {
              return {
                ...subValue,
                inquiry: subValues?.inquiry,
                requirements: subValues?.requirements,
                isValid: subValues.isValid,
              };
            }
          }
          return subValue;
        });
      } else {
        oldSubValues.push(subValues);
        newSubValues = oldSubValues;
      }

      this._subscriptionValues$.next(newSubValues);
    } else {
      this._subscriptionValues$.next(null);
    }
  }

  public setInquiryDataStructure(dataStructure: IInquiryDataStructure) {
    if (dataStructure) {
      const currentInquiryDataStructure = this._inquryDataStructure$.value ?? [];
      if (!currentInquiryDataStructure.find((inqData) => inqData.subId === dataStructure.subId)) {
        currentInquiryDataStructure.push(dataStructure);
      }
      this._inquryDataStructure$.next(currentInquiryDataStructure);
    } else {
      this._inquryDataStructure$.next(null);
    }
  }

}
