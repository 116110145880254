import { Injectable } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { getBundleProductsForProduct, getProductDetail, getShoppingCart, IEvaProductRequirement, OrderMode, OrderStatus, store } from '@springtree/eva-sdk-redux';
import { TGetShoppingCartInfoChainData } from '@springtree/eva-sdk-redux/dist/types/redux/reducers/get-shopping-cart-info';
import { get, isEmpty, isNil } from 'lodash';
import { Subject } from 'rxjs';
import { first, map, take, withLatestFrom } from 'rxjs/operators';
import { ILineActionTypeOrderElement } from 'src/app/modals/insufficient-stock-modal/insufficient-stock-modal';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import isNotNil from 'src/app/shared/operators/isNotNil';
import { timeToShowSpinnerDefaults } from '../../shared/constants';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { AppBundlesBehavior, EvaApplicationConfigProvider } from '../eva-application-config/eva-application-config';
import { EvaErrorGeneratorProvider, IErrorFeedback } from '../eva-error-generator/eva-error-generator';
import { EvaInsufficientStockProvider } from '../eva-insufficient-stock/eva-insufficient-stock';
import { EvaLoadingControllerProvider } from '../eva-loading-controller/eva-loading-controller';
import { EvaProductRequirementsProvider } from '../eva-product-requirements/eva-product-requirements';
import { EvaStartupProvider } from '../eva-start-up/eva-start-up';
import { OrderLifecycleService } from '../order-lifecycle/order-lifecycle.service';
import { StockLabelProvider } from '../stock-label/stock-label.provider';

interface IProductAddOpts {
  showProductRequirementModalIfNeeded?: boolean;
}

type ProductAddReturnType = Promise<[Promise<EVA.Core.AddProductToOrderResponse>, Promise<TGetShoppingCartInfoChainData>]>;

/**
 * There are multiple places that add products to the cart and show feedback accordingly, this service will do just that
 *
 */
@Logger('[eva-product-add-provider]')
@Injectable()
export class EvaProductAddProvider implements ILoggable {

  public static forceNewLineTypeIDs = [64];

  public logger: Partial<Console>;

  private appBundlesBehaviorIsFashion$ = this.$evaApplicationConfig.AppBundlesBehavior$.pipe(
    map( appBundlesBehavior => appBundlesBehavior === AppBundlesBehavior.Fashion )
  );

  public allowMixedOrders$ = this.$evaApplicationConfig.ordersAllowMixed$.pipe(
    map( allow => Boolean(allow) )
  );

  /**
   * This stream will be emit once a product is added and we should check the bundles of the product
   * @see https://eva2015.atlassian.net/browse/OPTR-1137
   */
  public checkBundlesForProduct$ = new Subject<number>();

  private defaultLineActionTypeOverride: number;

  private fiscalConfigurationRequired$ = this.$evaApplicationConfig.fiscalConfigurationRequired$;

  public isConfirmationAlertOpen: boolean = false;

  public hasPendingPayments = getShoppingCart.getResponse$().pipe(
    map(response => response?.ShoppingCart?.Lines?.length > 0 && response?.Amounts?.Paid?.Pending !== 0 && !response?.ShoppingCart?.IsPaid)
  )

  constructor(
    private $translate: TranslateService,
    private toastCtrl: EvaToastController,
    private $evaErrorGenerator: EvaErrorGeneratorProvider,
    private $evaProductRequirements: EvaProductRequirementsProvider,
    private $applicationConfig: EvaApplicationConfigProvider,
    private $evaApplicationConfig: EvaApplicationConfigProvider,
    private insufficientStockProvider: EvaInsufficientStockProvider,
    private evaLoadingCtrl: EvaLoadingControllerProvider,
    private $orderLifecycleService: OrderLifecycleService,
    private alertCtrl: AlertController,
    private navCtrl: NavController
  ) { }

  async add(actionData: Partial<EVA.Core.AddProductToOrder>, options: IProductAddOpts = {}, typeId?: number): ProductAddReturnType {
    const fiscalConfigurationRequired: boolean = await this.fiscalConfigurationRequired$.pipe(take(1)).toPromise();
    const hasPendingPayments = await this.hasPendingPayments.pipe(take(1)).toPromise();

    if (fiscalConfigurationRequired) {
      this.toastCtrl.showMessage(this.$translate.instant('missing.configuration.add.product.message'));
      return;
    }

    const isDeliveryType: boolean = await getShoppingCart.getState$().pipe(
      first(),
      map(cartState => cartState.checkoutStatus.mode === OrderMode.delivery)
    ).toPromise();

    const isOrderPaid: boolean = await getShoppingCart.getResponse$().pipe(
      first(), isNotNil(),
      map(response => response.ShoppingCart.IsPaid)
    ).toPromise();

    const hasCommittedLines: boolean = await getShoppingCart.getResponse$().pipe(
      first(),
      map(response => response.ShoppingCart.Lines),
      map((lines) =>  lines.some((line) => line.QuantityCommitted > 0))
    ).toPromise();

    const hasReservedLines: boolean = await getShoppingCart.getResponse$().pipe(
      first(),
      map(response => response.ShoppingCart.Lines),
      map((lines) =>  lines.some((line) => line.QuantityReserved > 0))
    ).toPromise();

    /**
     * @see https://n6k.atlassian.net/browse/OPTR-18499
     */
    actionData.ForceNewLine = this.shouldForceNewLine(typeId);

    if(((isDeliveryType || hasReservedLines) && isOrderPaid) || hasCommittedLines){
      const askConfirmation = await this.askConfirmationAddProduct();
      if(askConfirmation?.role !== 'confirm'){
        return;
      }
    } else if (hasPendingPayments) {
      const askConfirmation = await this.askConfirmationToAddProductOnPendingPayments();
      if (askConfirmation?.role !== 'confirm') {
        return;
      }
    }

    const shoppingCart = await getShoppingCart.getResponse$().pipe(
      first(), isNotNil(),
      map(response => response.ShoppingCart)
    ).toPromise();

    const totalLinesAllowed = await this.$applicationConfig.orderMaxLines$.pipe(first()).toPromise();
    const maximumAlmostReached = this.checkIfMaximumReached(shoppingCart,actionData,totalLinesAllowed);

    if(maximumAlmostReached) {
      const askConfirmation = await this.askConfirmationAddProductMaxAllowed();
      if(askConfirmation?.role !== 'confirm'){
        return;
      }
    }

    const isProductPartOfBundle = await this.isProductPartOfBundle(actionData);

    // To align with customer expectations, and the other apps, we let the user choose if they want to add the product as a bundle
    //
    if (isProductPartOfBundle) {
      this.navCtrl.navigateRoot('tabs/basket');

      const alert = await this.alertCtrl.create({
        header: this.$translate.instant('bundle.child.product.detected.alert.header'),
        message: this.$translate.instant('bundle.child.product.detected.alert.message'),
        buttons: [
          {
            text: this.$translate.instant('bundle.child.product.detected.alert.button.separate'),
            role: 'separate',
          },
          {
            text: this.$translate.instant('bundle.child.product.detected.alert.button.bundle'),
            role: 'bundle',
          },
        ],
      });

      alert.present();

      const dismissEvent = await alert.onDidDismiss<{role: string}>();

      if (dismissEvent.role === 'bundle') {
        this.checkBundlesForProduct(actionData.ProductID);

        // If the user chooses to add the product as a bundle, we will end up in this flow again
        return;
      }
    }

    const defaultLineActionType = await this.getDefaultLineActionType();

    const request: EVA.Core.AddProductToOrder = {
      LineActionType: defaultLineActionType,
      ...actionData,
      Children: [],
      OrderID: this.$orderLifecycleService.getCurrentOrderId()
    };

    try {
      const {cartOperationPromise, cartUpdateChainPromise} = await this.$orderLifecycleService.addProductToOrder({request});

      this.addProductToOrderSideEffect(cartOperationPromise, cartUpdateChainPromise, options, actionData);

      await this.$orderLifecycleService.createOrderByCharacteristic();

      return [cartOperationPromise, cartUpdateChainPromise];
    } catch (error) {
      // show an alert when the order has been completed
      //
      if (error?.response?.Error?.Type === 'OrderLineCreator:OrderIsCompleted') {
        this.alertCtrl.create({
          header: this.$translate.instant('order.already.completed.title'),
          message: this.$translate.instant('order.already.completed.message'),
          buttons: [{
            text: this.$translate.instant('action.ok'),
          }]
        }).then((alert) => alert.present());
        return;
      }
      this.addProductToOrderSideEffect(Promise.reject(error), Promise.resolve(null), options, actionData);
    }

  }

  // Check if maximum allowed lines has reached the limit
  // @see https://n6k.atlassian.net/browse/OPTR-20058
  // @see https://n6k.atlassian.net/browse/OPTR-20609
  private checkIfMaximumReached(shoppingCart:EVA.Core.OrderDto, actionData:Partial<EVA.Core.AddProductToOrder>, totalLinesAllowed:number){
    const totalLinesOrder = shoppingCart.TotalLineCount;
    const productAlreadyExists = shoppingCart.Lines.find(line => line.ProductID === actionData.ProductID);
    const isInterbranch = getShoppingCart.getState()?.checkoutStatus?.mode === OrderMode.interbranch;
    const isRma = getShoppingCart.getState()?.checkoutStatus?.mode === OrderMode.returnToSupplier;

    // We dont limit when order type is RTS or Interstore
    if(isInterbranch || isRma){
      return false;
    }

    // Check if we are adding a new line or just increasing qty
    if(productAlreadyExists){
      return false;
    }

    return totalLinesOrder === totalLinesAllowed;
  }

  // Warn the user about maximum allowed lines reached
  // to avoid blocking current cart actions
  // @see https://n6k.atlassian.net/browse/OPTR-20058
  // @see https://n6k.atlassian.net/browse/OPTR-20609
  private async askConfirmationAddProductMaxAllowed() {
    if (!this.isConfirmationAlertOpen) {
      this.isConfirmationAlertOpen = true;
      const alert = await this.alertCtrl.create({
        header: this.$translate.instant('order.maximum.reached'),
        message: this.$translate.instant('product.add.confirmation.maximum.reached'),
        backdropDismiss: false,
        buttons: [
          {
            text: this.$translate.instant('action.cancel'),
            role: 'cancel',
          },
          {
            text: this.$translate.instant('product.add'),
            cssClass: 'alert-danger',
            role: 'confirm',
          },
        ],
      });
      alert.present();
      const dismissEvent = await alert.onDidDismiss<{role: string}>();
      this.isConfirmationAlertOpen = false;
      return dismissEvent;
    }
  }

  private async askConfirmationAddProduct() {
    if (!this.isConfirmationAlertOpen) {
      this.isConfirmationAlertOpen = true;
      const alert = await this.alertCtrl.create({
        header: this.$translate.instant('order.paid'),
        message: this.$translate.instant('product.add.confirmation.delivery'),
        buttons: [
          {
            text: this.$translate.instant('action.cancel'),
            role: 'cancel',
          },
          {
            text: this.$translate.instant('product.add'),
            cssClass: 'alert-danger',
            role: 'confirm',
          },
        ],
      });
      alert.present();
      const dismissEvent = await alert.onDidDismiss<{role: string}>();
      this.isConfirmationAlertOpen = false;
      return dismissEvent;
    }
  }

  private async askConfirmationToAddProductOnPendingPayments() {
    const alert = await this.alertCtrl.create({
      header: this.$translate.instant('modify.order.pending.payments.order.warning.title'),
      message: this.$translate.instant('modify.order.pending.payments.order.warning.message'),
      buttons: [
        {
          text: this.$translate.instant('action.cancel'),
          role: 'cancel',
        },
        {
          text: this.$translate.instant('action.update'),
          cssClass: 'alert-danger',
          role: 'confirm',
        },
      ],
    });
    alert.present();
    const dismissEvent = await alert.onDidDismiss<{role: string}>();
    return dismissEvent;
  }

  /**
   * Based on the following things, we will return the line action type that is supposed to be used
   * 1- If the cart is empty, we will use the default line action type, which is based on either the app setting or a specific number
   * depending on customer
   * 2- If the cart is not empty, we want to use the current line action type
   * 3- If its RMA or interbranch, we always return 2
   */
  public async getDefaultLineActionType(): Promise<number> {
    const cartState = await getShoppingCart.getState$().pipe(first()).toPromise();

    const checkoutStatus = cartState.checkoutStatus;

    const allowMixedOrders = await this.allowMixedOrders$.pipe(first()).toPromise();

    if (checkoutStatus.mode === OrderMode.interbranch || checkoutStatus.mode === OrderMode.returnToSupplier) {
      // If the order type is interbranch or rma we want to the line action type to be three (Ship)
      //
      return 3;
    } else if (!isNil(this.defaultLineActionTypeOverride)) {
      return this.defaultLineActionTypeOverride;
    } else {
      const defaultLineActionType: Promise<number> = this.$applicationConfig.defaultLineActionType$.pipe(
        map( appConfigDefaultLineActionType => {
          const cartLines: EVA.Core.OrderLineDto[] = get(cartState, 'response.ShoppingCart.Lines', []);

          // Check if we have a mixed order lines or not
          const mixedOrderNotFound = cartLines.every(line => line.LineActionType === cartLines[0].LineActionType );

          const filteredCartLines = cartLines.filter(cartLine => cartLine.LineActionType !== 0);

          // If the cart is empty, we will use the default line action type
          if (isEmpty(filteredCartLines)) {
            return appConfigDefaultLineActionType;
          }
          else {
            // Cart is not empty, but we have Mixed Orders, so we return the default order line type
            if(allowMixedOrders && !mixedOrderNotFound){
              // this will prevent changing an order that is not mixed but has a different order line action type than the default
              // @see https://n6k.atlassian.net/browse/OPTR-18909
              return appConfigDefaultLineActionType;
            }
            // Cart is not empty, return the current line action type that is used.
            else {
              // This will prevent the possibility of mixed orders
              const [firstCartLine] = filteredCartLines;
              const currentLineActionType = firstCartLine.LineActionType;
              return currentLineActionType;
            }
          }
      }), first()).toPromise();

      return defaultLineActionType;
    }

  }

  private handleProductRequirement( cart: EVA.Core.ShoppingCartResponse, productId: number, orderLineId: number ) {
    const addedLine = cart.ShoppingCart.Lines.find( line => {
      const matchingLine = line.ID === orderLineId;

      return matchingLine;
    });

    const productRequirements: IEvaProductRequirement[] = get(addedLine?.Product.Properties, 'product_requirements', []);

    if ( !isEmpty(productRequirements) ) {
      this.$evaProductRequirements.showProductRequirementsModal(productId, orderLineId);
    }
  }

  /** We will notify the sales page a product has been added so it checks the bundles for a given product */
  private checkBundlesForProduct(addedProductId: number) {
    this.checkBundlesForProduct$.next(addedProductId);
  }

  private async addProductToOrderSideEffect(
    fetchPromise: Promise<EVA.Core.AddProductToOrderResponse>,
    chainPromise: Promise<TGetShoppingCartInfoChainData>,
    options: IProductAddOpts,
    actionData: Partial<EVA.Core.AddProductToOrder>
  ) {

    const loader = await this.evaLoadingCtrl.create({
      timeToShowSpinner: timeToShowSpinnerDefaults.long,
      debugInformation: {
        className: 'EvaProductAddProvider',
        methodName: 'addProductToOrderSideEffect'
      }
    });

    loader.startLoadingTimer();

    try {
      const addProductToOrderResponse = await fetchPromise;
      const orderlineId = addProductToOrderResponse.OrderLineID;

      // We will wait for the chain promise so the cart state that we fetch afterwards is the new one
      //
      await chainPromise;
      /** We want the newly fetched cart, which will contain our product requirements */
      const newCart: EVA.Core.ShoppingCartResponse = getShoppingCart.getState().response;
      // This means the consumer wants the product requirements modal to be presented if needed
      //
      if (!isNil(options) && options.showProductRequirementModalIfNeeded === true) {
        this.handleProductRequirement(newCart, actionData.ProductID, orderlineId);
      }
    } catch (error) {
      const feedbackFailure = this.$translate.instant('product.add.fail') as string;
      this.logger.error(`failed to add to product ${actionData.ProductID} to cart`, error);
      const evaError = await this.$evaErrorGenerator.constructFailureFeedback(error, feedbackFailure);
      // Despite the fact the Response object doesn't contain yet another `response` property.
      // The store internally modifies the `Response` Object to contain the parsed response
      //
      const cartResponse = await getShoppingCart.getResponse$().pipe(
        first(getShoppingCartResponse => !isNil(getShoppingCartResponse))
      ).toPromise();

      const cartLines = cartResponse.ShoppingCart.Lines;

      const insufficientStockError = get(error, 'response.Error.Type') === 'Stock:InsufficientStock';

      const productNotSellable = get(error, 'response.Error.Type') === 'Sellability:ProductNotSellable';

      if (error instanceof Response && insufficientStockError) {
        // We only want to handle this if its an empty cart
        //
        if (isEmpty(cartLines)) {
          this.handleInsufficientStockError(error, actionData, options);
        } else {
          // insufficient stock when there are items
          //
          this.showInsufficientToastError(error);
        }
      } else {
        this.toastCtrl.create(evaError).present();
      }
    } finally {
      loader.stopLoadingTimer();
    }

  }

  private async showInsufficientToastError(error: Response) {
    const errorMessage = this.$translate.instant('insufficient.stock') as string;
    const evaError = await this.$evaErrorGenerator.constructFailureFeedback(error, errorMessage);
    this.toastCtrl.create(evaError).present();
  }

  private async handleInsufficientStockError(error: any, actionData: Partial<EVA.Core.AddProductToOrder>, options: IProductAddOpts) {
    const lineActionTypeOrderElement: ILineActionTypeOrderElement = await this.insufficientStockProvider.handleInsufficientStockError(error, actionData);

    if (!isNil(lineActionTypeOrderElement)) {
      this.defaultLineActionTypeOverride = lineActionTypeOrderElement.lineActionTypeValue;

      try {
        await this.add(actionData, options);

        // Show success toast
        this.toastCtrl.create({
          message: this.$translate.instant('product.add.success')
        }).present();

        this.navCtrl.navigateRoot('tabs/basket');
      } catch { }
      finally {
        this.defaultLineActionTypeOverride = undefined;
      }

    }
  }

  private shouldForceNewLine(typeId: number): boolean {
    return EvaProductAddProvider.forceNewLineTypeIDs.includes(typeId);
  }

  private async isProductPartOfBundle(actionData: Partial<EVA.Core.AddProductToOrder>): Promise<boolean> {
        const { ProductID } = actionData;

    const [action, fetchPromise] = getBundleProductsForProduct.createFetchAction({
      ProductID,
      IncludedFields: ['display_price', 'display_value', 'primary_image']
    });

    store.dispatch(action);

    let showBundleModal = false;

    try {
      const response = await fetchPromise;
      showBundleModal = isEmpty(response.Result) === false;
    } catch (error) {
      this.logger.error(`failed to fetch bundles for product ${ProductID}`, error);
    }

    return showBundleModal;
  }
}
