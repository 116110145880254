import { Component, Input } from '@angular/core';
import { IsBeforeTodayPipe } from '../is-before-today.pipe';


export type IPurchaseOrderShipment = EVA.Core.PurchaseOrderShipmentDto

/**
 * Incoming shipment component
 */
@Component({
  selector: 'eva-incoming-shipment-card',
  templateUrl: 'incoming-shipment-card.html',
  providers: [IsBeforeTodayPipe]
})
export class IncomingShipmentCardComponent {
  /**
   * Shipment item input that we are going to display in this component
   */
  @Input() shipment: IPurchaseOrderShipment;
}
