import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger, ILoggable } from '../../shared/decorators/logger';

export interface IBuildInfo {
  branch: string;
  buildNr: string;
  buildTime: string;
  commit: string;
  description: string;
  name: string;
  target: string;
  version: string;
  appKey: string;
  evaEndpointUrl: string;
  applicationId: string;
  applicationName: string;
}
/**
 * This provider will fetch the build.json
 */
@Logger('[build-metadata-provider]')
@Injectable()
export class BuildMetadataProvider implements ILoggable {

  logger: Partial<Console>;

  private buildJson: Promise<IBuildInfo>;

  constructor(public http: HttpClient) {
    this.loadBuildJson();
  }

  getBuildJson(): Promise<IBuildInfo> {
    return this.buildJson;
  }


  /** We are in development mode if the fetching of the build.json failed  */
  public async isInDevelopmentMode(): Promise<boolean> {
    let isInDevelopmentMode = false;
    try {
      await this.buildJson;
    } catch  {
      isInDevelopmentMode = true;
    }

    return isInDevelopmentMode;
  }

  private loadBuildJson() {
    this.buildJson = this.http.get<IBuildInfo>('build.json').toPromise();
  }
}
