import { Component, ElementRef, Output, HostListener, EventEmitter } from '@angular/core';

/**
 * Some pages in the app relay on showing dark, overlays for things like search overlays
 * This component does exactly that
 */
@Component({
  selector: 'eva-overlay',
  template: '',
  styleUrls: ['overlay.component.scss']
})
export class OverlayComponent {
  @Output() onClick = new EventEmitter<MouseEvent>();

  constructor( public el: ElementRef<HTMLElement> ) { }

  @HostListener('click', ['$event']) onHostClick(event: MouseEvent) {
    this.onClick.emit(event);
  }
}
