export const BUNDLE_PRODUCT_TYPE = 2048;
export const SERVICE_PRODUCT_TYPE = 16;
export const DISCOUNT_PRODUCT_CUSTOM_ORDERLINE_TYPE = 'DISCOUNTPRODUCT';
export const GIFTWRAPPING_PRODUCT_CUSTOM_ORDERLINE_DESCRIPTION = 'GiftWrapping';
export const SAFT_AUDIT_PROVIDER_NAME = 'SAFT';

export const getAvailableSubscriptionsSmsBackendId = 'loyalty-sms';

export const timeToShowSpinnerDefaults = {
  default: 4000,
  short: 2000,
  long: 10000
};

export const shipFromStoreSubTypeTranslation: {[key: string]: string} = {
  'Pick': 'ship.from.store.pick.title',
  'Pack': 'ship.from.store.pack.title',
  'Ship': 'ship.from.store.ship.title'
};

export const appUrlScheme = 'io.newblack.eva.app';

export const SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' }
