<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button navPop>
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title> {{ titleText$ | async }} </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-slides (ionSlideDidChange)="onSlideChange()" [zoom]="false">
    <ion-slide *ngFor="let bundle of bundles$ | async" class="ion-padding">
      <ion-row class="description ion-justify-content-between ion-align-items-center">
        <div> {{ bundle.BundleProductContent?.display_value }} </div>
        <div>
          <b>
            <eva-price
              [price]="bundle.BundleProductContent?.display_price || 0"
              [currencyString]="bundle.BundleProductContent?.currency_id"
            >
            </eva-price>
          </b>
        </div>
      </ion-row>
      <eva-img [guid]="bundle.BundleProductContent?.primary_image?.blob">
      </eva-img>
      <ion-button expand="full" color="primary" class="ion-no-margin" (click)="openBundle(bundle.BundleProductID)">
        {{buttonText$ | async}}
      </ion-button>
    </ion-slide>
  </ion-slides>

  <ng-container *ngIf="bundles$ | async as bundle">
    <eva-dots [total]="bundle.length" [selectedIndex]="activeSlideIndex$ | async"></eva-dots>
  </ng-container>
</ion-content>
