<ion-row>
  <ion-col size="3" *ngIf="showImage">
    <eva-img [productId]="product.productId" [guid]="product.blobGUID" product-gallery-navigator stop-propagation="click"></eva-img>
  </ion-col>
  <ion-col size="5" *ngIf="showImage">
    <header class="description">{{ product.description }}</header>
    <eva-product-variations *ngIf="product.productVariations" [productVariations]="product.productVariations">
    </eva-product-variations>
  </ion-col>
  <ion-col *ngIf="!showImage" size="8" class="ion-no-padding">
    <header class="description">{{ product.description }}</header>
    <eva-product-variations *ngIf="product.productVariations" [productVariations]="product.productVariations">
    </eva-product-variations>
  </ion-col>
  <ion-col size="4" class="ion-text-end">
    <!-- If we only have primaryLocalizedPricing, we will show that otherwise we will show both primary&secondary with the secondary strike through'd -->
    <ng-container *ngIf="showPrice && product.primaryLocalizedPricing && !product.secondaryLocalizedPricing; else mixedPricingWrapper">
      <eva-localized-price [pricing]="product.primaryLocalizedPricing"[currencyString]="product.currencyId" [preferredPriceDisplayMode]="product.preferredPriceDisplayMode"></eva-localized-price>
    </ng-container>

    <ng-template #mixedPricingWrapper>
      <!-- If there is no mixed pricing, we will show the regular normal price -->
      <ng-container *ngIf="showPrice && product.primaryLocalizedPricing && product.secondaryLocalizedPricing; else showRegularPrice">

        <!-- If primary and secondary prices are the same, just show one of them -->
        <ng-container *ngIf="product.primaryLocalizedPricing.price === product.secondaryLocalizedPricing.price; else mixedPricingContainer">
          <eva-localized-price [pricing]="product.primaryLocalizedPricing"
          [currencyString]="product.currencyId" [preferredPriceDisplayMode]="product.preferredPriceDisplayMode"></eva-localized-price>
        </ng-container>
        <ng-template #mixedPricingContainer>
          <!-- If primary and secondary are not the same, show both and strike through the secondary -->
          <eva-localized-price [pricing]="product.primaryLocalizedPricing"
          [currencyString]="product.currencyId" [preferredPriceDisplayMode]="product.preferredPriceDisplayMode"></eva-localized-price>

          <eva-localized-price [pricing]="product.secondaryLocalizedPricing"
          [currencyString]="product.currencyId" [preferredPriceDisplayMode]="product.preferredPriceDisplayMode" class="line-through"></eva-localized-price>
        </ng-template>
      </ng-container>

    </ng-template>

    <!-- Fallback is just the regular price -->
    <ng-template #showRegularPrice>
      <eva-price *ngIf="showPrice && product.price" [currencyString]="product.currencyId" [price]="product.price"></eva-price>
      <ng-content select=".top-end"></ng-content>
    </ng-template>
  </ion-col>
</ion-row>

<ion-row class="ion-justify-content-between ion-align-items-center">
  <!-- we want to align the small element with the description of the product if the image is hidden-->
  <small secondary-text class="bottom-row">{{ product.customId }}</small>
  <ng-content></ng-content>
</ion-row>
