import { AfterViewInit, Component, ElementRef, HostBinding, HostListener, Input, NgZone, OnDestroy } from '@angular/core';
import { ResizeObserver } from '@juggle/resize-observer';
import { isNil, noop } from 'lodash';
import { animationFrameScheduler, BehaviorSubject, Subject } from 'rxjs';
import { finalize, map, observeOn, pairwise, takeUntil, tap } from 'rxjs/operators';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { OverlayComponent } from '../overlay/overlay.component';

interface ITouchMovementEvent {
  start: TouchEvent;
  move: TouchEvent;
}

interface ITouchMovement {
  screenYStart: number;
  screenYMove: number;
}

@Logger('[swipe-indicator-component]')
@Component({
  selector: 'eva-swipe-indicator',
  templateUrl: './swipe-indicator.component.html',
  styleUrls: ['./swipe-indicator.component.scss']
})
export class SwipeIndicatorComponent implements ILoggable, OnDestroy, AfterViewInit {

  public logger: Partial<Console>;

  @Input() tabsEl: HTMLElement;

  /** This value will represent the progress of the swipe event and will be between 0 and 1. Where 1 is pulled up and 0 is pulled down */
  private internalInterpolatedValue = new BehaviorSubject<number>(0);

  private touchEvents$ = new Subject<ITouchMovementEvent>();

  private stop$ = new Subject<void>();

  private touchStartEvent: TouchEvent;

  public readonly SNAP_ANIMATION_DURATION = 300;

  private mutationObserver: MutationObserver;

  private resizeObserver: ResizeObserver;

  /**
   * This value will be calculated when the swiper is first rendered (this value will be around 144)
  */
  private pulledDownTransformYValue: number;

  /** 0 would mean the off screen content is touching the screen, -16 will make sure theres 16 pixels of padding */
  private pulledUpTransformYValue: number = -16;

  /**
   * This will be a value between 0 (swiper pulled up) or another value representing the swiper pulled down. The other value will be computed when the component is rendered
   */
  private transformTranslateYValue = null;

  private offScreenElementHeight: number = null;

  @HostBinding('style.transform')
  get transformMatrixValue(): string {
    if (isNil(this.transformTranslateYValue)) {
      return null;
    }

    const matrixValue = this.getMaxtrixValue(this.transformTranslateYValue);

    return matrixValue;
  }

  @Input()
  set overlayEl(overlay) {
    if (!isNil(overlay) && !(overlay instanceof OverlayComponent) ) {
      throw new Error(
        'Are you sure you passed an eva-overlay element reference as input? \t' +
        '<eva-overlay #overlay></eva-overlay> \t' +
        '<swipe-indicator [overlayEl]="overlay"></swipe-indicator>'
      );
    }

    this._overlayEl = overlay;

    this.overlayNativeEl = overlay.el.nativeElement;
  }

  get overlayEl(): OverlayComponent {
    return this._overlayEl;
  }

  private _overlayEl: OverlayComponent;

  private overlayNativeEl: HTMLElement;

  offscreenElement: HTMLElement;

  constructor(
    private el: ElementRef<HTMLElement>,
    private zone: NgZone
  ) {}

  @HostListener('window:keyup.ArrowUp') onArrowUp() {
    this.pullUp();
  }

  @HostListener('window:keyup.ArrowDown') onArrowDown() {
    this.pullDown();
  }

  @HostListener('touchstart', ['$event']) ontouchStart(e: TouchEvent) {
    this.touchStartEvent = e;

    this.touchEvents$ = new Subject<ITouchMovementEvent>();

    const touchStartInterpolatedValue = this.internalInterpolatedValue.value;

    this.touchEvents$.pipe(
      observeOn(animationFrameScheduler),
      map(({ start, move }) => {
        const movement: ITouchMovement = {
          screenYStart: start.touches[0].screenY,
          screenYMove: move.touches[0].screenY
        };

        return movement;
      }),
      finalize(() => {
        // We will only call snapElement if the element was swiped a bit
        //
        if (touchStartInterpolatedValue !== this.internalInterpolatedValue.value) {
          this.logger.log('finished swiping, calling snapElement');
          // Ensure finalize code is not called twice
          //
          this.snapElement();
        }
      }),
      takeUntil(this.stop$),
      map( event => event.screenYStart - event.screenYMove ),
      pairwise(),
      map( ( [prev, next] ) => next - prev ),
    ).subscribe(diff => {
      const delta = diff * -1;

      const newTransformTranslateYValue = delta + this.transformTranslateYValue;

      // To calculate the interpoated value we will do the following (n2-x) / (n2-n1)
      // where
      // n2 is the pulled down value (~144)
      // x is the new translate y value
      // n1 is the pulled up value (-16)
      //
      const internalInterpolatedValue = (this.pulledDownTransformYValue - newTransformTranslateYValue ) / (this.pulledDownTransformYValue - this.pulledUpTransformYValue);

      // If the new translateY value is between the min and max value, we will set it
      if (internalInterpolatedValue > 0 && internalInterpolatedValue < 1) {
        this.internalInterpolatedValue.next(internalInterpolatedValue);
      }
    });

    this.internalInterpolatedValue.pipe(
      takeUntil(this.stop$),
    ).subscribe({
      next: y => {
        // To get the transform value we will use the following formula
        // x = -y(n2-n1) + n2
        // where
        // x = transformTranslateYValue
        // y = the internal interpolated value which will be changed by the animation method
        // n2 = pulledDownTransformYValue
        // n1 = pulledUpTransformYValue
        this.transformTranslateYValue = (y * -1) * (this.pulledDownTransformYValue-this.pulledUpTransformYValue) + this.pulledDownTransformYValue;
      }
    });


  }

  @HostListener('touchend', ['$event']) ontouchEnd(e: TouchEvent) {
    this.touchStartEvent = null;
    this.touchEvents$.complete();
  }

  @HostListener('window:touchmove', ['$event']) ontouchMove(e: TouchEvent) {
    if ( this.touchStartEvent ) {
      this.touchEvents$.next({
        move: e,
        start: this.touchStartEvent
      });
    }
  }

  ngAfterViewInit(): void {

    this.internalInterpolatedValue
      .pipe(
        tap( value => {
          // If the elements opacity is 0 we want to display none it
          //
          this.overlayNativeEl.style.display = value <= 0 ? 'none' : 'block';
        }),
        takeUntil(this.stop$)
      )
      .subscribe( value => {
        if ( !isNil(this.tabsEl) ) {
          const translateYValue = value * 100;
          this.tabsEl.style.transform = `translateY(${translateYValue}%)`;
        }

        this.overlayNativeEl.style.opacity = `${value}`;
      });

    if (!this.overlayNativeEl) {
      this.logger.error(
        `overlay element not present please add it like so  \n <div #overlay></div> <swipe-indicator [overlayNativeEl]="overlay"> ....`
      );
    } else {
      this.overlayEl.onClick.pipe(
        takeUntil(this.stop$)
      ).subscribe(() => this.pullDown());
    }


    this.mutationObserver = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        // Whenever the swiper is shown for the first time, we want to save the initial transform translate y value
        //
        if (mutation.type === 'attributes' && this.el.nativeElement.hidden === false && isNil(this.pulledDownTransformYValue)) {
          const styleTransform = getComputedStyle(this.el.nativeElement).transform;

          const translateY = new WebKitCSSMatrix(styleTransform).f;

          this.pulledDownTransformYValue = translateY;
        }
      }
    });

    // Observing changes on the host element
    //
    this.mutationObserver.observe(this.el.nativeElement, {
      attributes: true,
      attributeFilter: ['hidden']
    });

    this.offscreenElement = this.el.nativeElement.querySelector('[off-screen]');

    this.resizeObserver = new ResizeObserver(() => {
      this.zone.run(() => this.offScreenElementResize());
    });

    this.resizeObserver.observe(this.offscreenElement);
  }

  /**
   * whenever the off screen element is resized, we want to calculate our new 'pulled down' state transform translateY value;
  */
  private offScreenElementResize() {

    // if this element or its parent are not displayed, we dont want to execute resize logic
    // https://stackoverflow.com/a/21696585/4047409
    if (this.offscreenElement.offsetParent === null) {
      return;
    }

    // The first time this method will be called, we will not have a height set yet, so we will set the height and not
    // modify the value of the pulled down state
    //
    if (isNil(this.offScreenElementHeight)) {
      this.offScreenElementHeight = this.offscreenElement.clientHeight;
      if(this.offscreenElement.clientHeight === 200){
        console.log('***** ERROR RESIZE FAILED *******');
      }
      // We remove this return because of this issue:
      // @see https://n6k.atlassian.net/browse/OPTR-16603
      // return;
    }

    const difference = this.offScreenElementHeight - this.offscreenElement.clientHeight;

    // For unknown reason sometimes offScreenElementHeight equal to offscreenElement.clientHeight
    // in that case we must recalculate the offset again.
    const ignoreDiference = this.offScreenElementHeight >= this.offscreenElement.clientHeight;

    this.offScreenElementHeight = this.offscreenElement.clientHeight;

    if(ignoreDiference){
      this.pulledDownTransformYValue = this.offscreenElement.clientHeight - this.offscreenElement.offsetTop;
    } else {
      this.pulledDownTransformYValue -= difference;
    }

    // If after a resize the swiper is supposed to be pulled down, we will make sure to update the current translateY value
    //
    if ( this.internalInterpolatedValue.value === 0 ) {
      this.transformTranslateYValue = this.pulledDownTransformYValue;
    }
  }

  /**
   * Pulls this element up
   * @param callback we will call this when the animation is finished
   */
  public pullUp(callback: Function = noop) {
    this.overlayNativeEl.style.display = 'block';
    this.animate(1, () => {
      callback();
    });
  }


  /**
   * Pushes this element down
   * @param callback we will call this when the animation is finished
   */
  public pullDown(callback: Function = noop) {
    this.animate(0, () => {
      callback();
    });
  }


  /** Snaps the element depending on which side its closer to */
  public snapElement() {
    if ( this.internalInterpolatedValue.value > 0.5 ) {
      this.pullUp();
    } else {
      this.pullDown();
    }
  }

  ngOnDestroy() {
    this.internalInterpolatedValue.next(0);

    this.stop$.next();

    this.mutationObserver.disconnect();

    this.resizeObserver.disconnect();
  }

   /**
   * a css matrix has the following values, we will only be changing the last element in this matrix
   *  matrix(scaleX(),skewY(),skewX(),scaleY(),translateX(),translateY())
  */
  private getMaxtrixValue(translateY: number): string {
    return `matrix(1, 0, 0, 1 , 0, ${translateY})`;
  }

  /**
   * See original implementation here https://stackblitz.com/edit/rxjs-animation-interpolation
   * @param newValue
   * @param callback
   */
  private animate(newValue: number, callback: Function) {
    const animationDuration = this.SNAP_ANIMATION_DURATION;

    const currentValue = this.internalInterpolatedValue.value;

    let startTime, previousTimeStamp, animationFrameId;

    const step = (timestamp: number) => {
      if (startTime === undefined) {
        startTime = timestamp;
      }

      const elapsed = timestamp - startTime;

      const percentageToCompletion = elapsed / animationDuration;

      if (previousTimeStamp !== timestamp) {
        const value = Math.min(percentageToCompletion, 1);

        const newAnimationValue = (newValue - currentValue) * value + currentValue;

        this.internalInterpolatedValue.next(newAnimationValue);
      }

      // If the elapsed time is smaller than the animation duration we will continue
      // otherwise we will cancel the animation
      //
      if (elapsed < animationDuration) {
        previousTimeStamp = timestamp;
        window.requestAnimationFrame(step);
      } else {
        cancelAnimationFrame(animationFrameId);
        callback();
      }
    }

    animationFrameId = window.requestAnimationFrame(step);
  };

}
