<div class="select-container">
  <ion-button expand="full" fill="clear" (click)="openModal($event)" class="fake-button"></ion-button>
  <ion-item tabindex="-1">
    <ion-label position="floating">{{ itemSearchData.title }}</ion-label>
    <ion-select [formControl]="formControl">
      <ion-select-option *ngFor="let countryItem of itemSearchData.items" [value]="countryItem[itemSearchData.valueKey]">
        {{ countryItem[itemSearchData.labelKey] }}
      </ion-select-option>
    </ion-select>
  </ion-item>
</div>
