import { Component, Input } from '@angular/core';
import { getUser } from '@springtree/eva-sdk-redux';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserCustomFieldsService } from 'src/app/services/user-custom-fields/user-custom-fields.service';
import { Logger } from '../../shared/decorators/logger';
import { EvaApplicationConfigProvider } from 'src/app/services/eva-application-config/eva-application-config';

@Logger('[employee-discount-component]')
@Component({
  selector: 'eva-employee-discount',
  templateUrl: 'employee-discount.component.html'
})
export class EmployeeDiscountComponent {

  public logger: Partial<Console>;

  private _userId: number;

  @Input()
  public set userId(newUserId: number) {
    if (newUserId !== this._userId) {
      this._userId = newUserId;

      this.fetchUser(newUserId);
    }
  }

  public get userId() {
    return this._userId;
  }

  /** We will not use the store here because changing the store user value will cause a re-render, which will tirgger an inifnite loop */
  getUserResponse$ = new BehaviorSubject<EVA.Core.GetUserResponse|null>(null);

  private userExtendedCustomFields$: Observable<EVA.Core.CustomFieldResponse[]> = this.$appConfig.extendedCustomFields$.pipe(
    map(customFields => customFields.User),
  );

  private extendedCustomFields$: Observable<EVA.Core.CustomFieldResponse[]> = combineLatest(([
    this.getUserResponse$,
    this.userExtendedCustomFields$,
  ])).pipe(
    map(([user, userExtendedCustomFields]) => {
      const customFieldsIds = Object.keys(user?.CustomFieldValuesWithOptions || {}).map(Number);
      return customFieldsIds.map(customFieldId => {
        return userExtendedCustomFields.find(extendedCustomField => extendedCustomField.CustomFieldID === customFieldId);
      });
    }),
  );

  availableDiscount$: Observable<number> = this.extendedCustomFields$.pipe(
    map(customFields => this.$userCustomFields.getRitualsAvailableDiscount(customFields)),
  );

  isEmployee$: Observable<boolean> = this.extendedCustomFields$.pipe(
    map(customFields => this.$userCustomFields.isRitualsEmployee(customFields)),
  );

  constructor(
    private $userCustomFields: UserCustomFieldsService,
    private $appConfig: EvaApplicationConfigProvider,
  ) { }

  private async fetchUser(userId: number) {

    const currentUserResponse = getUser.getState().response;

    // If we already have this user fetched, we will use that data
    //
    if (currentUserResponse?.ID === userId) {
      this.getUserResponse$.next(currentUserResponse);

      return;
    }

    const [action] = getUser.createFetchAction({
      ID: userId
    });

    try {
      const userResponse = await getUser.fetchData(action);

      this.getUserResponse$.next(userResponse);
    } catch (error) {
      this.logger.error(`error calling getUser with id=${userId}`, error);
    }
  }

}
