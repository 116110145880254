import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { isEmpty } from 'lodash';
import { bootstrapStore } from 'src/app/shared/bootstrap-store';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';
import evaConfigFile from '../../../assets/config/eva-config.json';
import { EnvironmentOverrideProvider } from '../environment-override/environment-override';
import { ManagedConfigurations } from '@robingenz/capacitor-managed-configurations';

/** Describes a configuration file loaded from disk */
export interface IEnvironment {
  readonly endpoint: string;
  readonly customerName: string;
  readonly defaultToken?: string;
  readonly name: string;
  readonly metadata?: any;
  source?: 'apple-managed-config' | 'manual' | 'eva-customer-manager';
}

@Logger('[eva-startup-provider]')
@Injectable()
export class EvaStartupProvider implements ILoggable {

  /**
   * Status of the start up, this will be based on the result of the promise that was returned by the http request
   *
   * @deprecated use evaConfig member instead
   */
  public environmentFile: Promise<IEnvironment> = Promise.resolve(evaConfigFile);

  public evaConfig: IEnvironment = evaConfigFile;

  public bootstrapOutput: Promise<any>;

  logger: Partial<Console>;

  constructor(
    private $evaConfigurationData: EnvironmentOverrideProvider,
    private platform: Platform,
  ) {}

  /**
   * Loads up the environment file and bootstraps store
   */
  public async bootstrap(): Promise<void|IEnvironment> {

    // Wait for Ionic to bootstrap first
    //
    await this.platform.ready();

    let appManagedConfigEndpoint: string | undefined;
    try {
      appManagedConfigEndpoint = (await ManagedConfigurations.getString({ key: 'endpoint' }))?.value;
    } catch (error) {
      // We dont want to show Capacitor errors on the browser console
      // @see https://n6k.atlassian.net/browse/OPTR-19788
      if (this.platform.is('capacitor' )) {
        this.logger.error('Cannot find endpoint from Apple managed configuration', error);
      }
    }

    if (isEmpty(evaConfigFile)) {
      throw new Error('[startup:service] Empty environment Config');
    }

    const evaConfigFileCopy = {...evaConfigFile};

    if (evaConfigFileCopy.customerName === 'generic') {
      const overrideActive = this.$evaConfigurationData.overrideActive;

      if (overrideActive) {
        const envOverride = this.$evaConfigurationData.getOverride();

        this.logger.info('Found environment override', envOverride);

        // Apple manage configuration are subject to change. Detect that the endpoint is was
        // set using Apple managed configu, but if it's gone. We need to remove the override
        //
        if (envOverride.source === 'apple-managed-config' && isEmpty(appManagedConfigEndpoint)) {
          this.logger.info('Reset current override because Apple managed config is empty now');
          this.$evaConfigurationData.resetOverride();
        // No Apple managed configuration found, simply use what's stored in local storage
        //
        } else {
          this.logger.warn('Configuration override active, using that', envOverride);

          // If there is an override active, we will modify the configuration file
          //
          evaConfigFileCopy.endpoint = envOverride.endpoint;
        }

      } else {
        this.logger.log('Configuration not active. Requesting one after initailisation');
      }
    }

    // What to do if there is no endpoint at this point
    //
    if (isEmpty(evaConfigFileCopy.endpoint)) {
      // If there is no enpoint, but there is a app preference set, we'll use that one
      //
      if (!isEmpty(appManagedConfigEndpoint)) {
        this.logger.log(`bootstrap endpoint using the Apple managed configuration: ${appManagedConfigEndpoint}`);
        evaConfigFileCopy.endpoint = appManagedConfigEndpoint;

        // Store this as the environment override
        //
        await this.$evaConfigurationData.setOverride({
          endpoint: evaConfigFileCopy.endpoint,
          customerName: 'generic',
          source: 'apple-managed-config',
        });

      // We will only bootstrap the store if we have an endpoint, otherwise we will request one at a later stage
      //
      } else {
        this.logger.log(
          `bootstrap skipped due to lack of endpoint customer=${evaConfigFileCopy.customerName}, will be done later. This is okay if customer is generic`,
        );
        return;
      }
    }

    this.evaConfig = evaConfigFileCopy;
    // We will set this value for backwards compatibilty

    this.environmentFile = Promise.resolve(evaConfigFileCopy);

    try {
      this.bootstrapOutput = bootstrapStore(evaConfigFileCopy);

      await this.bootstrapOutput;

      this.logger.log(`bootstrapping with endpoint=${evaConfigFileCopy.endpoint} success`);
    } catch (bootstrapError) {
      this.logger.error('Failed to bootstrap', bootstrapError);
    }

    this.logger.log('Loading done.');
  }
}

export function startupProviderFactory(startupProvider: EvaStartupProvider) {
  return () => startupProvider.bootstrap();
}
