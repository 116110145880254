import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
  selector: '[val-message]'
})
export class ValMessageDirective {

  @Input('val-message') errorName: string;

  @HostBinding('style.display')
  displayValue = 'none';

  hide() {
    this.displayValue = 'none';
  }

  show() {
    this.displayValue = 'block';
  }

}
