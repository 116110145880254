import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EvaToastController } from "src/app/modules/eva-toast/eva-toast.controller";
import { IParsedBarcode } from "src/app/modules/scanner/eva-barcode-scanner";
import { ILoggable, Logger } from "src/app/shared/decorators/logger";
import { IParsedElevationBarcode } from "src/app/shared/typings";
import { IParseBarcodeHandler } from "../eva-parse-barcode-handler/eva-parse-barcode-handler";

/**
 * Will handle QR codes that the manager scans
 * @see https://n6k.atlassian.net/browse/OPTR-16402
 */
@Logger('[eva-parse-barcode-elevation-token-handler-provider]')
@Injectable({
  providedIn: 'root'
})
export class EvaParseBarcodeElevationTokenHandlerProvider implements IParseBarcodeHandler, ILoggable {
  logger: Partial<Console>;

  constructor(private toastCtrl: EvaToastController, private $translate: TranslateService) {}

  /** We will simply show a success toast to the user */
  handle(parsedData: IParsedBarcode) {
    const data = parsedData.Data as IParsedElevationBarcode;

    let isHandled = false;

    // For now, if the elevation barcode is not related to the click&collect flow, we will skip the feedback
    //
    if (data.Functionality !== 'ReleasePaidPickupOrders') {
      return isHandled;
    }

    isHandled = true;

    if ( data.Success ) {
      this.toastCtrl.showMessage(this.$translate.instant('elevation.barcode.scan.success'));
    } else {
      this.toastCtrl.showMessage(this.$translate.instant('elevation.barcode.scan.fail'));
    }

    return isHandled;
  }

}
