import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { listOrdersForCustomer, store } from '@springtree/eva-sdk-redux';
import { LoadingController, NavController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { EvaApplicationConfigProvider } from '../eva-application-config/eva-application-config';

export interface ICustomerUpdatedOrdersOpenParams {
  orderId: number;
  customerId: number;
  navCtrl: NavController;
  addressBookShippingAddressId?: number;
  addressBookBillingAddressId?: number;
}

interface IConstructListOrdersForCustomerParams {
  startIndex: number;
  pageSize: number;
  openOrdersOnly: boolean;
  customerId: number;
}
@Logger('[customer-updated-orders-provider]')
@Injectable()
export class CustomerUpdatedOrdersProvider implements ILoggable {
  logger: Partial<Console>;

  /** we will use this map to ensure we don't do the ListOrdersForCustomer request multiple times */
  listOrdersForCustomerResponseMap = new Map<string, EVA.Core.ListOrdersForCustomerResponse>();

  constructor(
    private loadingCtrl: LoadingController,
    private $translate: TranslateService,
    private $evaApplicationConfig: EvaApplicationConfigProvider
  ) { }

  /** We will only open the customer updated orders page if the setting is enabled and there is at least one order with the same address */
  async openPage(opts: ICustomerUpdatedOrdersOpenParams) {
    let shouldOpen = false;

    const suggestUpdateAddressOnOpenOrders = await this.$evaApplicationConfig.suggestUpdateAddressOnOpenOrders$.pipe(first()).toPromise();

    if (!suggestUpdateAddressOnOpenOrders) {
      return;
    }

    const loading = await this.loadingCtrl.create({
      message: this.$translate.instant('attach.address.to.order.loading')
    });

    loading.present();

    const listOrdersForCustomerParam = this.constructListOrdersForCustomerParameters({
      pageSize: 20,
      startIndex: 0,
      customerId: opts.customerId,
      openOrdersOnly: true
    });

    try {
      const listOrdersForCustomerResponse = await this.listOrdersForCustomer(listOrdersForCustomerParam);

      /** We will subtract one incase theres an orderId passed to this page,
       * meaning we are in context of an order thats not relevant for this page
       */
      const subtractTotal = opts.orderId ? 1 : 0;

      const totalOpenOrders = listOrdersForCustomerResponse.Result.Total - subtractTotal;

      loading.dismiss();

      const hasOrdersToUpdate = totalOpenOrders > 0;

      shouldOpen = hasOrdersToUpdate;

      if (shouldOpen) {
        const { orderId, customerId, addressBookBillingAddressId, addressBookShippingAddressId, navCtrl } = opts;
        const addressId = addressBookBillingAddressId ? addressBookBillingAddressId : addressBookShippingAddressId
        const addressType = addressBookShippingAddressId ? 'shipping' : 'billing';
        navCtrl.navigateForward([
          `/orders/address/edit/customer-updated-orders/${orderId}/${customerId}/${addressId}/${addressType}`]
        );
      }
    } catch (error) {
      this.logger.error('error calling listOrdersForCustomer, openPage aborted', error);
    }

    return shouldOpen;
  }

  constructListOrdersForCustomerParameters({
    startIndex,
    pageSize,
    openOrdersOnly,
    customerId
  }: IConstructListOrdersForCustomerParams): Partial<EVA.Core.ListOrdersForCustomer> {
    const listOrdersForCustomerParameters: Partial<EVA.Core.ListOrdersForCustomer> = {
      PageConfig: {
        Start: startIndex,
        Limit: pageSize,
        SortDirection: 1,
        SortProperty: 'CreationTime',
        Filter: {
          OpenOrdersOnly: openOrdersOnly,
          ModifiableOrdersOnly: openOrdersOnly,
          ShowOrdersWithoutLines: undefined,
          Type: undefined,
          Status: undefined
        }
      },
      ID: customerId,
    };

    return listOrdersForCustomerParameters;
  }

  async listOrdersForCustomer(
    listOrdersForCustomerParameters: Partial<EVA.Core.ListOrdersForCustomer>
  ): Promise<EVA.Core.ListOrdersForCustomerResponse> {

    /** Because the objects passed to this function will be different,
     * we will use a string variant of the object to ensure the map identifcation works
     */
    const requestKey = JSON.stringify(listOrdersForCustomerParameters);

    const responseInMap = this.listOrdersForCustomerResponseMap.get(requestKey);

    // If we already responded to this, we will return the response.
    //
    if (responseInMap) {
      return responseInMap;
    }

    const [listCustomerOrdersRequest, listCustomerOrdersPromise] = listOrdersForCustomer.createFetchAction(listOrdersForCustomerParameters);

    store.dispatch(listCustomerOrdersRequest);

    try {
      const listCustomerOrdersResponse = await listCustomerOrdersPromise;

      this.listOrdersForCustomerResponseMap.set(requestKey, listCustomerOrdersResponse);
    } catch (error ) {
      this.logger.error('error calling listOrdersForCustomer', error);
    }

    return listCustomerOrdersPromise;
  }

}
