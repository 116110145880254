import { AfterViewInit, Directive, Self } from '@angular/core';
import { IonDatetime } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';

/**
 * Whenever thes a ion-datetime, we want to edit its localisation
 */
@Logger('[ion-date-time-directive]')
@Directive({
  selector: 'ion-datetime',
})
export class IonDateTimeDirective implements AfterViewInit, ILoggable {
  logger: Partial<Console>;

  constructor(@Self() private dateTime: IonDatetime, private $translate: TranslateService) { }

  ngAfterViewInit(): void {
    // Sometimes currentLang is like en_US, which is not an accepted format by ion-datetime directive as valid
    // @see https://n6k.atlassian.net/browse/OPTR-21718
    const localeWithCorrectDelimiter = this.$translate?.currentLang?.replace('_','-');
    this.dateTime.locale = localeWithCorrectDelimiter;
  }

}
