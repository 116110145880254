import { Injectable, NgZone } from '@angular/core';
import { IonRefresher, ModalController } from '@ionic/angular';
import { communicator, deactivateUserTask, getCurrentUser, getUserTaskCounts,
  getUserTaskNotCompletedReasons,
  IHubEvent, IHubEventUserTask, IHubUserTaskJoinRequest, listAvailableUserTasks, settings, store } from '@springtree/eva-sdk-redux';
import { cloneDeep, difference, find, get, isEmpty, isEqual, isNil, noop } from 'lodash';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, pairwise, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { ITaskFilterValue } from 'src/app/modals/eva-task-filter-modal/eva-task-filter-modal';
import { TaskDeclineModalComponent, TaskDeclineModalDismissData } from 'src/app/modals/task-decline-modal/task-decline-modal.component';
import { ConfirmAlert } from '../../shared/decorators/confirm-alert';
import { EvaFeedback } from '../../shared/decorators/eva-feedback';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import isNotNil from '../../shared/operators/isNotNil';
import isNotOffline from '../../shared/operators/isNotOffline';
import { RoutesProvider } from '../routes/routes';
import { getDefaultTaskGroupMapping, ITaskSubgroupMapping, TTaskGroupMapping } from './task-group-mapping';
import {
  ITaskGroupView, ITaskSubGroupView, ITaskView, IViewTask,
  SupportedTaskGroups, SupportedTasks,
  TNonEmptyArray, TSupportedTaskGroupType, TTaskGroupsTranslationMapping,
  TTaskSubgroupType, UserTask, ViewTasks
} from './types';

/** Tasks that will lock the app if present */
export const fullStockCountTasks = [SupportedTasks.FullStockCount];

interface ISingularTaskGroupMapping {
  group: SupportedTaskGroups;
  subGroupsMapping: TNonEmptyArray<ITaskSubgroupMapping>;
}

@Logger('[user-task-provider]')
@Injectable()
export class UserTaskProvider implements ILoggable {

  /**
   * Mappings for translations
   */
  private taskGroupsTranslationMapping: TTaskGroupsTranslationMapping = {
    CompliancyTasksGroup: 'task.group_type.compliancy_tasks_group',
    CycleCountTasksGroup: 'task.group_type.cycle_count_tasks_group',
    FullStockCountTasksGroup: 'task.group_type.full_stock_count_tasks_group',
    ReceiveGoodsTasksGroup: 'task.group_type.receive_goods_tasks_group',
    ReservationTasksGroup: 'task.group_type.click_and_collect_tasks_group',
    RunnerTasksGroup: 'task.group_type.runner_tasks_group',
    ShipFromStoreTasksGroup: 'task.group_type.ship_from_store_tasks_group',
    StockMovementTasksGroup: 'task.group_type.stock_movement_tasks_group',
    Print: 'print.task.plural',
    PrintPriceLabel: 'product.print.price.label',
    OperationalGroup: 'task.group_type.operational_group',
  };

  /**
   * The default logger
   */
  logger: Partial<Console>;

  private readonly DEFAULT_USER_TASK_COUNT_RESPONSE: EVA.Core.GetUserTaskCountsResponse = {
    NumberOfTasks: 0,
    DetailedNumberOfTasks: {},
    Error: null,
    Metadata: null
  };

  public printLegalDocumentSubTaskNameMapping: {[key: string]: string} = {
    YearlyControlDocument: 'yearly.control.document',
    MonthlyControlDocument: 'monthly.control.document',
    StationInitializationDocument: 'station.initialization.document',
    StationClosingDocument: 'station.closing.document'
  };

  /**
   * The current user task count which take initial cyclecount into account
   * and can be updated from either signalr or polling
   */
  public userTaskCount$ = new BehaviorSubject<EVA.Core.GetUserTaskCountsResponse>(this.DEFAULT_USER_TASK_COUNT_RESPONSE);

  /**
   * Indicates the currently logged in user is an employee
   * Used to enable/disable the whole user task collection
   * We need to ensure this only emits on a distinct value change and
   * when the user has progressed through the login enough to have a user token
   */
  private isEmployee$: Observable<boolean> = getCurrentUser.getState$().pipe(
    map(state => !!state?.isEmployee),
    distinctUntilChanged(isEqual),
  );

  /**
   * This is our observable interval that will call getUserTaskCounts
   */
  private getUserTaskCountsInterval$ = interval(5000);

  /**
   * Signal to stop calling getUserTaskCounts
   */
  private stopPolling$ = new Subject<void>();

  /**
   * Task types that are supported. Derrived from `SupportedTasks`
   */
  private supportedTaskTypes: string[] = Object.values(SupportedTasks);

  taskFilterValue: ITaskFilterValue;

  /**
   * we use this in order to reload the task deadlines when refreshing or navigating back to the tasks-page
   *
   */
  tasksReloaded$ = new Subject<void>();

  public fullStockCountEnabled$: Observable<boolean> = this.userTaskCount$.pipe(
    map(userTaskCount => this.fullStockCountEnabled(userTaskCount))
  );

  public userTaskCountsBadge$: Observable<number> = this.userTaskCount$.pipe(
    map((userTaskCount) => {

      const fullStockCountModeEnabled = this.fullStockCountEnabled(userTaskCount);

      /** If full stock count mode is enabled, we only want to show the total full stock count tasks. Not the total tasks as we cannot access those */
      if (fullStockCountModeEnabled) {
        const totalFullStockCountTasks = fullStockCountTasks
          .filter(fullStockCountTaskType => !isNil(userTaskCount.DetailedNumberOfTasks[fullStockCountTaskType]))
          .map(fullStockCountTaskType => userTaskCount.DetailedNumberOfTasks[fullStockCountTaskType].Count)
          .reduce((a, b) => a + b, 0);

        return totalFullStockCountTasks;
      } else {
        return userTaskCount.NumberOfTasks;
      }
    })
  );

  /**
   * This will remember the join payload for the user task hub
   * which will be used when we want to close our current connection.
   * It's existence is also a good indicator that we are using
   * the user task hub
   */
  private userTaskHubJoinRequest: IHubUserTaskJoinRequest | undefined;

  constructor(
    private $routes: RoutesProvider,
    private ngZone: NgZone,
    private modalCtrl: ModalController
  ) {}

  public async refreshTasks(refresher: IonRefresher) {
    try {
      await this.loadTasks().catch(noop);
    } finally  {
      refresher.complete();
    }
  }

  public findMatchingTask(taskView: ITaskView, taskId: number): IViewTask {
    const flattenTasks = this.flattenTaskView(taskView);
    const matchingTask = flattenTasks.find(urgentTask => urgentTask.ID === taskId);
    return matchingTask;
  }

  public async getAllUrgentTasks(): Promise<ViewTasks> {
    const allTasks = await listAvailableUserTasks.getResponse$().pipe(
      isNotNil(),
      map(response => response.AvailableTasks),
      first()
    ).toPromise();

    const relevantTasks = this.filterIrellevantTasks(allTasks);

    const urgentTasks  = relevantTasks.filter(
      task => task.HasUrgency
    );

    return urgentTasks;
  }

  public getTaskView(currentUserId: number, tasks: UserTask[]): ITaskView {
    //
    // Get tasks that are:
    // Flatten (meaning that when we encounter a composite task we replace it with its first child)
    // Supported (we filter out unsupported ones)
    //
    const relevantTasks = this.filterIrellevantTasks(tasks);

    // Get urgent tasks
    //
    const urgentTasks = this.getUrgentUserTasks(relevantTasks);

    // Remove the urgent tasks from task array for processing
    //
    const tasksWithoutUnassignedUrgency = difference(relevantTasks, urgentTasks);

    // Get my tasks
    //
    const currentUserTasks = this.getCurrentUserTasks(currentUserId, tasksWithoutUnassignedUrgency);

    // Remove the current user tasks from the task array for processing
    //
    const tasksGrouped = this.getTaskGroups(relevantTasks);

    const result: ITaskView = {
      urgentTasks,
      myTasks: currentUserTasks,
      allTasks: tasksGrouped
    };

    return result;
  }

  public getTranslationKeyForGroupType(groupType: TSupportedTaskGroupType): string {
    return this.taskGroupsTranslationMapping[groupType];
  }

  initialise() {
    this.listenForEmployeeLogin();

    this.listenForCommunicatorUserTaskUpdates();

    this.listenForPollUserTaskUpdates();
  }

  getFilters(): ITaskFilterValue {
    return this.taskFilterValue;
  }

  setFilters(filters: ITaskFilterValue) {
    this.taskFilterValue = filters;
  }

  @EvaFeedback({
    i18nFailKey: 'tasks.load.fail'
  })
  async loadTasks() {
    const taskFilter: EVA.Core.ListAvailableUserTasks = {
      UserTaskTypes: [],
      Filters: {},
      UserTaskSubTypes: []
    };

    // Was a filter specified by the user?
    //
    if (!isEmpty(this.taskFilterValue)) {
      let filters = {};

      if (+this.taskFilterValue.zone) {
        filters = {...filters, CycleCountZoneID: [parseInt(this.taskFilterValue.zone as any, 10)]}
      }

      if (+this.taskFilterValue.origin) {
        filters = {...filters, OriginTypeID: [parseInt(this.taskFilterValue.origin as any, 10)]}
      }

      taskFilter.Filters.ZonedCycleCountPreCount = filters;
    }

    const [listUserTasksAction, listAvailableUserTasksPromise] = listAvailableUserTasks.createFetchAction(taskFilter);

    store.dispatch(listUserTasksAction);

    try {
      const availableTasksResponse = await listAvailableUserTasksPromise;

      // We want to lock down the app if there is a full stock count or full stock count label task
      //
      this.handleFullStockCountAppLocking(availableTasksResponse);

      // We trigger the deadlines refresh
      //
      this.tasksReloaded$.next();
    } catch (error) {
      this.logger.error('Error calling listAvailableUserTasks', error);
    }

    return listAvailableUserTasksPromise;
  }

  /**
   * Returns tasks that can are supported by the application
   */
  public getSupportedTasks(tasks: UserTask[]): UserTask[] {
    const tasksSupportedInApplication = tasks.filter(
      (task) => {
        const taskSupported = this.isTaskSpportedInTheApplication(task);

        return taskSupported;
      }
    );

    return tasksSupportedInApplication;
  }

  /**
   * Returns tasks that are not supported by the application
   */
  public getNotSupportedTasks(tasks: UserTask[]): UserTask[] {
    const tasksNotSupportedInApplication = tasks.filter(
      (task) => {
        const taskNotSupported = !this.isTaskSpportedInTheApplication(task);

        return taskNotSupported;
      }
    );

    return tasksNotSupportedInApplication;
  }

  /**
   * Returns a boolean indicating whether the task has been cancelled or not. True being cancelled and false being not cancelled
   *
   * @param taskId
   */
  async cancelTask(taskId: number): Promise<boolean> {
    let reasons: EVA.Core.GetUserTaskNotCompletedReasonsResponse.Reason[] = [];
    try {
      const [action, promise] = getUserTaskNotCompletedReasons.createFetchAction({
        UserTaskID: taskId
      });

      store.dispatch(action);

      const getUserTaskNotCompletedReasonsResponse = await promise;

      reasons = getUserTaskNotCompletedReasonsResponse.Reasons;
    } catch (error) {
      this.logger.error('Error calling getUserTaskNotCompletedReasons', error);
    }

    // If we have a "not complete" reasons, we will provide the user the option to select one. Otherwise
    // we will display a simple alert
    //
    if ( isEmpty(reasons) ) {
      return this.cancelTaskExcludingReason(taskId);
    } else {
      return this.cancelTaskIncludingReason(taskId);
    }
  }

  async cancelTaskIncludingReason(taskId: number): Promise<boolean> {
    let taskCancelled = false;

    try {
      const modal = await this.modalCtrl.create({
        component: TaskDeclineModalComponent
      });

      modal.present();

      const dismissEvent = await modal.onDidDismiss<TaskDeclineModalDismissData>();

      if ( dismissEvent.data?.declineConfirmed ) {
        await this.cancelTaskWithFeedback(taskId, dismissEvent.data.reasonId);
        // Assuming we reach this line of code, the task has been cancelled
        //
        taskCancelled = true;
      }
    } catch (error) {
      this.logger.error('error presenting TaskDeclineModalComponent modal', error);
    }

    return taskCancelled;
  }

  /**
   * The user will be shown an alert where they can confirm the cancellation of the task. Without having to specifiy a reason
   * as they are not available
   */
   @ConfirmAlert({
    i18nConfirmButtonKey: 'action.decline',
    i18nCancelButtonKey: 'action.dont.decline',
    i18nTitleKey: 'decline.task.title',
    i18nMessageKey: 'decline.task.message'
  })
  private async cancelTaskExcludingReason(taskId: number): Promise<boolean> {
    try {
      await this.cancelTaskWithFeedback(taskId);
      return true;
    } catch (error) {
      return false
    }
  }

  @EvaFeedback({
    i18nFailKey: 'cancel.task.fail',
    i18nSuccessKey: 'cancel.task.success'
  })
  private async cancelTaskWithFeedback(taskId: number, reasonId?: number) {
    const [deactivateUserTaskAction, deactivateUserTaskPromise] = deactivateUserTask.createFetchAction({
      UserTaskIDs: [taskId],
      ReasonID: reasonId
    });

    store.dispatch(deactivateUserTaskAction);

    try {
      await deactivateUserTaskPromise;
    } catch (error) {
      this.logger.error('error calling deactivateUserTask', error);
    }

    return deactivateUserTaskPromise;
  }

  callGetUserTaskCounts() {
    const [getUserTaskCountsAction] = getUserTaskCounts.createFetchAction({
      UserTaskTypes: this.supportedTaskTypes,
      IncludeAssignedTasks: true
    });

    store.dispatch(getUserTaskCountsAction);
  }

  /**
   * Check if the Companion App supports the task type.
   */
  private isTaskSpportedInTheApplication(task: UserTask): boolean {
    const isTaskSupported = task.Type.Name in SupportedTasks;
    return isTaskSupported;
  }

  /**
   * This listens for events coming from the communicator to trigger getUserTaskCounts
   * Events can arrive at any time (push)
   */
  private listenForCommunicatorUserTaskUpdates() {
    communicator.event$.pipe(
      filter(event => event.method === 'UserTaskCreated' || event.method === 'UserTaskUpdated'),
      debounceTime(500),
      tap((e) => this.logger.log('listenForCommunicatorUserTaskUpdates', e)),
    ).subscribe((event: IHubEvent) => {

      this.ngZone.run(() => {
        // Whenever there is a task update event, we will use it as a trigger for GetUserTaskCounts
        //
        this.callGetUserTaskCounts();

        if (event.method === 'UserTaskUpdated') {
          this.handleTaskAssigneeUpdate(
            event.data as IHubEventUserTask
          );
        }
      } );
    });
  }

  private async handleTaskAssigneeUpdate(taskHubEvent: IHubEventUserTask) {
    // Find the user task in the available user tasks array
    //
    const availableUserTasks: EVA.Core.AvailableUserTaskDto[] = await listAvailableUserTasks.getState$().pipe(
      filter( state => !isEmpty( state.response ) ),
      map (state => state.response.AvailableTasks),
      take(1)
    ).toPromise();

    const matchedUserTask = availableUserTasks.find(availableUserTask => availableUserTask.ID === taskHubEvent.ID);
    const matchedUserTaskId = get(matchedUserTask, 'User.ID') as number;
    if (matchedUserTaskId !== taskHubEvent.UserID) {
      // The task was assigned to somebody else, so we need to refresh the available tasks list
      //
      this.logger.log(`Task assignee changed for task ${matchedUserTaskId}. Refetching available tasks`);
      this.loadTasks();
    }
  }

  /**
   * This listens for responses from the getUserTaskCounts service to update the task count
   * The service will be called by the interval (pull)
   */
  private listenForPollUserTaskUpdates() {
    getUserTaskCounts.getResponse$().pipe(
      isNotNil(),
      isNotOffline(),
      // this has to be casted to GetUserTaskCounts, see why here https://github.com/ReactiveX/rxjs/issues/4772
      //
      startWith(null as EVA.Core.GetUserTaskCounts),
      pairwise()
    ).subscribe(
      ([oldUserTaskUpdatedData, newUserTaskUpdatedData]: [EVA.Core.GetUserTaskCountsResponse, EVA.Core.GetUserTaskCountsResponse]) => {
      this.logger.log('UserTask count update from getUserTaskCounts', newUserTaskUpdatedData);
      this.userTaskCount$.next(newUserTaskUpdatedData);

      // We used to compare the `NumberOfTasks` but that proved problematic in the case of ship from store tasks as on completion
      // of a pick or pack
      // a pack or ship task would be created, which would increment the number of tasks thus causing the `NumberOfTasks` to remain the same
      // after the completion of a task, this would not trigger a change when we expect one
      //
      const unEqualTaskEvents = !isEqual(oldUserTaskUpdatedData?.DetailedNumberOfTasks, newUserTaskUpdatedData.DetailedNumberOfTasks);

      if (!isNil(oldUserTaskUpdatedData) && unEqualTaskEvents ) {
        this.ngZone.run(() => this.loadTasks());
      }
    });
  }

  /**
   * This will start the getUserTaskCounts interval after doing an initial fetch
   * Will also stop any previous polling as a safety precaution
   */
  private startPolling() {

    // Make sure we do not have any pending intervals
    //
    this.stopPolling$.next();

    // Perform the initial fetch
    //
    this.callGetUserTaskCounts();

    // Start the polling interval
    this.getUserTaskCountsInterval$.pipe(
      takeUntil(this.stopPolling$)
    ).subscribe(() => this.callGetUserTaskCounts() );
  }

  /**
   * Once an employee logs in we can either use the communicator for task count updates
   * or resort to polling if SignalR is not available
   */
  private listenForEmployeeLogin() {

    this.isEmployee$.subscribe(async (isEmployee) => {
      this.logger.log('User employee status changes', isEmployee);

      // First close any current connection we may have
      // The isEmployee$ will only emit when changed
      //
      if (this.userTaskHubJoinRequest) {
        this.logger.log('Employee login state changed. Leaving current user task hub');
        try {
          communicator.leaveUserTaskHub(this.userTaskHubJoinRequest);
        } catch (error) {
          this.logger.warn('Error leaving user task hub', error);
        }
        this.userTaskHubJoinRequest = undefined;
      }

      if (isEmployee) {
        // Start listening for user task counts through communicator or polling
        //
        if (find(communicator.hubs, { Name: 'UserTaskHub' })) {

          try {
            this.logger.log('Joining user task hub for employee', settings.userToken);
            this.userTaskHubJoinRequest = {
              token: settings.userToken,
            };
            await communicator.joinUserTaskHub(this.userTaskHubJoinRequest);
          } catch (error) {
            // use ERROR for one release (https://n6k.atlassian.net/browse/OPTR-10069)
            this.logger.error('Error joining user task hub. Falling back to polling', error);

            // Fallback to polling
            //
            this.startPolling();
          }
        } else {
          // Use polling due to no SignalR hub being available
          //
          this.startPolling();
        }

      } else {
        // Stop polling
        // Do this always in case the joinUserTask hub failed and we did
        // a fallback on getUserTaskCounts
        //
        this.stopPolling$.next();

        // Reset user task count
        //
        this.userTaskCount$.next(this.DEFAULT_USER_TASK_COUNT_RESPONSE);
      }
    });
  }

  private handleFullStockCountAppLocking(availableTasksResponse: EVA.Core.ListAvailableUserTasksResponse) {
    const shouldLockDownApp = availableTasksResponse.AvailableTasks.some(task => {
      return fullStockCountTasks.includes(task.Type.Name as SupportedTasks);
    });

    // We will be disabling all tabs when we have a full stock count label
    //
    if (shouldLockDownApp) {
      this.$routes.disableRoutes();
    } else {
      // This will enable the routes in the side menu
      //
      this.$routes.enableRoutes();
    }
  }

  private getMappingAsArray(taskGroupMapping: TTaskGroupMapping = getDefaultTaskGroupMapping()): ISingularTaskGroupMapping[] {
    const mappingArray: ISingularTaskGroupMapping[] = Object.keys(taskGroupMapping).map(key => {
      const mappingValue = taskGroupMapping[key as SupportedTaskGroups];

      const result: ISingularTaskGroupMapping = {
        group: key as SupportedTaskGroups,
        subGroupsMapping: mappingValue
      };

      return result;
    });

    return mappingArray;
  }

  /**
   * Gets tasks grouped by the mapping
   */
  private getTaskGroups(tasks: UserTask[], taskGroupMapping: TTaskGroupMapping = getDefaultTaskGroupMapping()): ITaskGroupView[] {
    // Iterate through the supported tasks group
    //
    const taskGroupMappingArray = this.getMappingAsArray(taskGroupMapping);

    const tasksGrouped: ITaskGroupView[] = taskGroupMappingArray.map(
      (mapping) => {
        // Find all tasks that match the mappings
        //
        const taskSubgroups = this.getTasksSubgroups(mapping, tasks);

        const count = taskSubgroups.map(subGroup => subGroup.tasks)
          .reduce((prev, current) => prev.concat(current), []).length;

        if (count > 0) {
          const taskGroupViewItem: ITaskGroupView = {
            group: mapping.group,
            taskSubgroups,
            count
          };

          return taskGroupViewItem;
        }
      }
    ).filter(entry => !isNil(entry));

    return tasksGrouped;
  }

  /**
   * Gets the tasks subgroups for a mapping
   */
  private getTasksSubgroups(mapping: ISingularTaskGroupMapping, tasks: UserTask[]): ITaskSubGroupView[] {

    const subGroupsMapping = mapping.subGroupsMapping;

    // try to do a filter + map
    const taskSubgroups: ITaskSubGroupView[] = subGroupsMapping.map(
      (subGroup) => {
        const subGroupTasks = subGroup.tasks.map(subGroupTask => subGroupTask.toString());

        // Get the tasks for this sub group
        //
        const relevantTasks = tasks.filter(
          (task) => {

            let taskToCheck: UserTask = task;

            if (this.isCompositeTask(task)) {
              taskToCheck = task.Children[0];
            }

            switch (subGroup.subGroupsMappingType) {
              case 'task': {
                if (!isNil(taskToCheck.Type)) {
                  return subGroupTasks.includes(taskToCheck.Type.Name);
                }

                return false;
              }
              case 'subtask': {
                if (!isNil(taskToCheck.SubType)) {
                  return subGroupTasks.includes(taskToCheck.SubType.Name);
                }

                return false;
              }
              default: {
                this.logger.error(`Unhandled subgroup mapping type ${subGroup.subGroupsMappingType}`);
                return false;
              }
            }
          }
        );

        if (!isEmpty(relevantTasks)) {
          const subGroupCategory: TTaskSubgroupType = subGroup.category || subGroup.tasks[0];
          const taskSubGroupView: ITaskSubGroupView = {
            subGroup: subGroupCategory,
            tasks: relevantTasks
          };

          return taskSubGroupView;
        }
      }
    ).filter(entry => !isNil(entry));

    return taskSubgroups;
  }

  /**
   * Gets the tasks assigned to the current user
   */
  private getCurrentUserTasks(currentUserId: number, tasks: UserTask[]): IViewTask[] {
    const currentUserTasks = tasks.filter(
      (task) => {
        return this.isCompositeTask(task) ? task.Children[0].User?.ID === currentUserId : task.User?.ID === currentUserId;
      }
    );

    return currentUserTasks;
  }

  /**
   * Returns supported tasks with composite tasks flatten (take the first child of the task)
   */
  private filterIrellevantTasks(tasks: UserTask[]): IViewTask[] {
    // Clone array
    //
    const tasksClone = cloneDeep(tasks);

    // Get only supported tasks
    //
    const onlySupportedTasks = tasksClone.filter(
      (task) => {
        const taskToCheck = this.isCompositeTask(task) ? task.Children[0] : task;
        const taskSupported = this.isTaskSpportedInTheApplication(taskToCheck);
        return taskSupported;
      }
    );

    return onlySupportedTasks;
  }

  /**
   * Gets urgent tasks, sorted by sequence, unassigned, assigned
   */
  private getUrgentUserTasks(tasks: UserTask[]): UserTask[] {
    const tasksWithUrgency = tasks.filter(
      // Filter out tasks that have an assignee and take only those that have urgency
      //
      task => {
        const taskOrChildrenHaveUrgency =
          this.isCompositeTask(task)
            ? task.Children[0].HasUrgency
            : task.HasUrgency;
        return taskOrChildrenHaveUrgency;
      }
    );

    // Sort tasks by Unassigned and Sequence ascending - lowest first
    const unassignedTasks = tasksWithUrgency.filter( task => isNil(task.User)).sort(unassignedTask => unassignedTask.Sequence);

    // Sort tasks by Assigned and Sequence ascending - lowest first
    const assignedTasks = tasksWithUrgency.filter( task => !isNil(task.User)).sort(assignedTask => assignedTask.Sequence);

    return [...unassignedTasks, ...assignedTasks];
  }

  private flattenTaskView(taskView: ITaskView): ViewTasks {
    const flattenedAllTasks = taskView.allTasks.map(taskGroup => {
      const flattenTasksPerTaskSubgroups = taskGroup.taskSubgroups
        .map(taskSubGroup => taskSubGroup.tasks)
        .reduce((previous, current) => previous.concat(...current), []);

      return flattenTasksPerTaskSubgroups;
    }).reduce((previous, current) => previous.concat(...current), []);

    const flatTaskArray = [...taskView.urgentTasks, ...taskView.myTasks, ...flattenedAllTasks];

    return flatTaskArray;
  }

  /**
   * We consider a task to be composite if it has children tasks
   */
  private isCompositeTask(task: UserTask) {
    return !isEmpty(
      task.Children
    );
  }

  private fullStockCountEnabled(userTaskCount: EVA.Core.GetUserTaskCountsResponse): boolean {
    return fullStockCountTasks.some(fullStockCountTaskType => !isNil(userTaskCount.DetailedNumberOfTasks[fullStockCountTaskType]));
  }
}
