import { Component, Input } from '@angular/core';

export interface GenericIcon {
  value: string;
  type: 'ionicon' | 'svg';
}

/**
 * this component will render an ion-icon with either a `src`, `name` or `md` attribute
 * */
@Component({
  selector: 'eva-icon',
  templateUrl: './icon.component.html',
  styles: [`
  :host {
    --icon-size: var(--size, 22px);
    width: var(--icon-size);
    height: var(--icon-size);
    font-size: var(--icon-size);
    display: inline-block;
  }

  `]
})
export class IconComponent {

  @Input() icon: GenericIcon;

}
