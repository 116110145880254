import { HttpClient } from '@angular/common/http';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as i18nCountries from 'i18n-iso-countries';
import { MissingTranslationHandlerImplementation } from './missing-translations';
import { DEFAULT_LOCALE } from './modules/injection-tokens';

declare var require: any;

i18nCountries.registerLocale( require('i18n-iso-countries/langs/en.json') );
i18nCountries.registerLocale( require('i18n-iso-countries/langs/da.json') );
i18nCountries.registerLocale( require('i18n-iso-countries/langs/de.json') );
i18nCountries.registerLocale( require('i18n-iso-countries/langs/es.json') );
i18nCountries.registerLocale( require('i18n-iso-countries/langs/fr.json') );
i18nCountries.registerLocale( require('i18n-iso-countries/langs/nl.json') );
i18nCountries.registerLocale( require('i18n-iso-countries/langs/pt.json') );
i18nCountries.registerLocale( require('i18n-iso-countries/langs/es.json') );
i18nCountries.registerLocale( require('i18n-iso-countries/langs/sk.json') );
i18nCountries.registerLocale( require('i18n-iso-countries/langs/fi.json') );

export function translateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

/**
 * Default loader according to ngx-translate/core
 * @see https://github.com/ngx-translate/core#configuration
 */
const defaultTranslateLoader = {
  provide: TranslateLoader,
  useFactory: translateLoaderFactory,
  deps: [HttpClient]
};

export const ConfiguredTranslateModule = TranslateModule.forRoot({
  loader: defaultTranslateLoader,
  missingTranslationHandler: {
    provide: MissingTranslationHandler,
    useClass: MissingTranslationHandlerImplementation,
    deps: [DEFAULT_LOCALE]
  },
  useDefaultLang: false
});
