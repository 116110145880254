<ng-container *ngIf="!controlsDisabled; else readonlyRequirements">
  <ng-container *ngIf="hasProductRequirements$ | async">
    <eva-product-details-requirements
      [productRequirements]="productRequirements$ | async"
      [orderLineId]="orderLineId"
      [model]="productRequirementsModel"
      (modelChange)="productRequirementsModelChange($event)"
      (requirementsValid)="productRequirementsValid($event)"
      (click)="openRequirementsModal()"
      stop-propagation="click"
  >
    </eva-product-details-requirements>
  </ng-container>

  <form [formGroup]="requirementsForm" justify-content-between>
    <ng-container *ngIf="requirementsForm.get('serialNumber')">
      <ion-item (click)="openGiftCardPage()" class="serial-number-item" stop-propagation="click">
        <ion-label position="floating">
          {{ "serial.number" | translate}}
        </ion-label>
        <ion-input formControlName="serialNumber" type="text">
        </ion-input>
      </ion-item>
    </ng-container>
  </form>
</ng-container>

<ng-template #readonlyRequirements>
  <eva-order-line-requirement-view-mode
    [orderLineId]="orderLineId"
    [productRequirements]="productRequirements$ | async"
    [productRequirementsModel]="productRequirementsModel"
  ></eva-order-line-requirement-view-mode>

  <ng-container *ngIf="showRequirementSerialNumber$ | async">
    <ion-item lines="full" class="ion-no-padding">
      <ion-row class="requirement-item">
        <ion-col size="5" class="ion-no-padding">
          <ion-label>{{ "serial.number" | translate }}</ion-label>
        </ion-col>
        <ion-col size="7" class="ion-no-padding" ellipsis>
          <ion-note>
            {{ (orderLine$ | async)?.SerialNumber }}
          </ion-note>
        </ion-col>
      </ion-row>
    </ion-item>
  </ng-container>
</ng-template>
