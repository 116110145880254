import { Logger, ILoggable } from '../../shared/decorators/logger';
import { Injectable } from '@angular/core';
import { isNil, flatten } from 'lodash';

/**
 * Possible status types keys that are currently known and handled in the Companion App
 */
export type TProductStatusType =
  'PreRelease'
  | 'DeliveryOnly'
  | 'DisableDelivery'
  | 'DisablePickup'
  | 'DisableBackorder'
  | 'UseUp';

/**
 * A status info containing the code/numeric value and the corresponding in-app translation key
 */
export interface IProductStatusEntry {
  key: TProductStatusType;
  code: number;
  i18n: string;
}

/**
 *
 */
export interface IProductStatus {
  active: boolean;
  i18n: string;
}

/**
 * This provider handles showing/calculating product statuses
 *
 * @see https://eva2015.atlassian.net/browse/OPTR-6565
 *
 */
@Logger('[eva-product-status-provider]')
@Injectable()
export class EvaProductStatusProvider implements ILoggable {

  /**
   * Potential product statuses - contains:
   * The key for information purposes
   * Code - the product status value
   * i18n - the translation corresponding in the app
   */
  private potentialProductStatuses: IProductStatusEntry[] = [
    {
      key: 'PreRelease',
      code: 1,
      i18n: 'product.status.prerelease',
    },

    {
      key: 'DeliveryOnly',
      code: 4,
      i18n: 'product.status.deliveryonly',
    },

    {
      key: 'DisableDelivery',
      code: 8,
      i18n: 'product.status.disabledelivery',
    },

    {
      key: 'DisablePickup',
      code: 16,
      i18n: 'product.status.disablepickup',
    },

    {
      key: 'DisableBackorder',
      code: 32,
      i18n: 'product.status.disablebackorder',
    },

    {
      key: 'UseUp',
      code: 34,
      i18n: 'product.status.useup',
    }
  ];

  public logger: Partial<Console>;

  /**
   * Gets product status translations
   *
   * Used in the product status chip
   */
  public getProductStatusTranslationKeys(status: number): string[] {
    const statusEntries = this.getProductStatusEntries(status);

    const translationKeys: string[] = statusEntries.map(statusEntry => statusEntry.i18n);

    return translationKeys;
  }

  /**
   * Gets all the product statuses that are bitmasket with the received statuse array
   *
   * @see https://eva2015.atlassian.net/browse/OPTR-6565
   *
   * Main consumer of this is the `Product details` tab of the Product detail stock
   */
  public getProductStatusesInfo(statuses: number[]): IProductStatus[] {
    // Get the list of statuses that are bitmasked with values in the array
    //
    const activeStatuses = flatten(statuses.map(status => this.getProductStatusEntries(status)));
    const statusesResult = this.potentialProductStatuses.map(potentialProductStatus => {
      // See if the potential product status is active (contained in the product detail)
      // if it is contained in the list of active statuses
      //
      //
      const isActive = !isNil(activeStatuses.find(status => status.code === potentialProductStatus.code));
      const statusItem: IProductStatus = { active: isActive, i18n: potentialProductStatus.i18n };

      return statusItem;
    });

    return statusesResult;
  }

  /**
   * Get the list of product status entries from the possible product statuses array that
   *
   * match the status parameter (are bitmasked)
   */
  private getProductStatusEntries(status: number): IProductStatusEntry[] {
    // Interate through all potential statuses and check if they are bitmasked
    //
    const statuses: IProductStatusEntry[] = [];

    for (const potentialProductStatus of this.potentialProductStatuses) {
      if ( (status & potentialProductStatus.code) !== 0) {
        statuses.push(potentialProductStatus);
      }
    }

    return statuses;
  }
}
