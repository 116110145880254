import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '../components/components.module';
import { DirectivesModule } from '../directives/directives.module';
import { ModalsModule } from '../modals/modals.module';
import { IonInputFocusFixModule } from '../modules/ion-input-focus-fix/ion-input-focus-fix.module';
import { ScannerModule } from '../modules/scanner/scanner.module';
import { PipesModule } from '../pipes/pipes.module';
import { ModalControllerWrapper } from '../services/modal-controller-wrapper/modal-controller-wrapper.service';
import { ValMessagesModule } from './ngx-messages/val-messages.module';

@NgModule({
  exports: [
    TranslateModule,
    ReactiveFormsModule,
    DirectivesModule,
    ComponentsModule,
    PipesModule,
    ValMessagesModule,
    ModalsModule,
    ScannerModule,
    IonInputFocusFixModule
  ],
  providers: [
    ModalControllerWrapper,
    {
      provide: ModalController,
      useExisting: ModalControllerWrapper
    },
  ]
})
/** This module will contain commonly used items, and should be imported by future modules (pages in our case) */
export class SharedModule { }
