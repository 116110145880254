import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { getCurrentUser } from '@springtree/eva-sdk-redux';
import { isFunction } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericIcon } from 'src/app/components/icon/icon.component';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import isNotNil from '../../shared/operators/isNotNil';
import someInArray from '../../shared/operators/someInArray';
import { EvaApplicationConfigProvider } from '../eva-application-config/eva-application-config';
import { InterLogisticsSwitchProvider } from '../inter-logistics-switch/inter-logistics-switch';
import { OrderLifecycleService } from '../order-lifecycle/order-lifecycle.service';

export interface IRoute {
  /** i18n title */
  title: string;

  /** whether the menu items are hidden or not */
  hidden?: Promise<boolean> | Observable<boolean>;

  /** router path */
  path?: string;

  /** will be provided to the router when navigating */
  routeExtras?: NavigationExtras;

  /** a function to execute on click, will fallback to navigating to a 'path' */
  onClick?: (route: IRoute) => any;

  icon: GenericIcon;
}

/**
 * Due to a business requirement, all routes accessible through the hamburger menu
 * need to be rendered with tabs on the bottom of their page. This means we need the routes in both the tabs component
 * and the app.component (For the side menu)
 *
 * Please notice that not all the application routes are supposed to live in this file, pushed views will NOT be included
 * @see https://coggle.it/diagram/Wvvl6-fgI0uNKbsW/t/sales-app-routing
 */
@Logger('[routes-provider]')
@Injectable()
export class RoutesProvider implements ILoggable {

  public logger: Partial<Console>;

  private currentUserFunctionalities$ = getCurrentUser.getResponse$().pipe(
    isNotNil(),
    map( userResponse => userResponse.User.ScopedFunctionalities )
  );

  /** Whether to disable all routes except the information page */
  private disableRoutes$ = new BehaviorSubject(false);

  disableSales$ = getCurrentUser.getResponse$().pipe(
    isNotNil(),
    map( userResponse => userResponse.User.ScopedFunctionalities ),
    map(functionalities => !(this.functionalityName in functionalities)),
  );

  isInterbranchOrder$ = this.$orderLifecycleService.isInterbranchOrder$;

  isRtsOrder$ = this.$orderLifecycleService.isRtsOrder$;

  isNormalOrder$ = combineLatest([
    this.isInterbranchOrder$,
    this.isRtsOrder$
  ]).pipe(
    map(([isInterbranchOrder, isRtsOrder]) => !isInterbranchOrder && !isRtsOrder)
  );

  private isOffline$ = new BehaviorSubject(false);

  private readonly functionalityName = 'Sales';

  public routes: IRoute[] = [{
    title: 'information',
    path: 'information',
    icon: {
      type: 'ionicon',
      value: 'information-circle-outline'
    }
  },
  {
    title: 'sales',
    path: 'basket',
    hidden: combineLatest([
      this.disableRoutes$,
      this.disableSales$,
      this.isNormalOrder$,
    ]).pipe(someInArray(Boolean)),
    onClick: async (route) => {
      try {
        await this.$interLogisticsSwitch.createSalesOrder();

        await this.router.navigate([`tabs/${route.path}`], route.routeExtras);
      } catch (error) {
        this.logger.error('error navigating to interbranch order', error);
      }
    },
    icon: {
      type: 'ionicon',
      value: 'cart-outline'
    }
  },
  {
    title: 'order.type.interbranch',
    path: 'basket',
    hidden: combineLatest([
      this.disableRoutes$,
      this.currentUserFunctionalities$.pipe(
        map(functionalities => ((functionalities['InterbranchOrders'] & 1) === 0))
      ),
      this.disableSales$,
      this.isInterbranchOrder$,
      this.isOffline$,
      ]).pipe(someInArray(Boolean)),
      onClick: async (route) => {
        try {
          await this.$interLogisticsSwitch.createInterbranchOrder({ forceCreate: true });

          await this.router.navigate([`tabs/${route.path}`], route.routeExtras);
        } catch (error) {
          this.logger.error('error navigating to interbranch order', error);
        }
      },
      icon: {
        value: './svg/order-interbranch.svg',
        type: 'svg'
      }
  }, {
    title: 'order.type.return.to.supplier',
    path: 'basket',
    hidden: combineLatest([
      this.disableRoutes$,
      this.currentUserFunctionalities$.pipe(
        map(functionalities => ((functionalities['ReturnToSupplierOrders'] & 1) === 0))
      ),
      this.disableSales$,
      this.isRtsOrder$,
      this.isOffline$,
    ]).pipe(someInArray(Boolean)),
    onClick: async (route) => {
      try {
        await this.$interLogisticsSwitch.createRmaOrder({ forceCreate: true });

        await this.router.navigate([`tabs/${route.path}`], route.routeExtras);
      } catch (error) {
        this.logger.error('error navigating to rts order', error);
      }
    },
    icon: {
      value: './svg/order-rma.svg',
      type: 'svg'
    }
  },
  {
    title: 'check.giftcard.balance',
    path: 'giftcard-balance-check',
    hidden: combineLatest([
      this.disableRoutes$,
      this.disableSales$
    ]).pipe(someInArray(Boolean)),
    icon: {
      type: 'ionicon',
      value: 'gift-outline'
    }
  },
  {
    title: 'service.products',
    path: 'service-products',
    hidden: combineLatest([
      this.disableRoutes$,
      this.disableSales$
    ]).pipe(someInArray(Boolean)),
    icon: {
      type: 'ionicon',
      value: 'build-outline'
    }
  },
  {
    title: 'customers',
    path: '/users/search',
    routeExtras: {
      queryParams: {
        clean: 1,
      }
    },
    hidden: combineLatest([
      this.disableRoutes$,
      this.$evaAppConfig.customerEnabled$.pipe(
        map(enabled => !enabled)
      ),
      this.disableSales$
    ]).pipe(someInArray(Boolean)),
    icon: {
      type: 'svg',
      value: './svg/person_search.svg'
    },
  },
  {
    title: 'incoming.shipments',
    path: 'incoming-shipment-list',
    hidden: combineLatest([
      this.disableRoutes$,
      this.disableSales$
    ]).pipe(someInArray(Boolean)),
    icon: {
      type: 'svg',
      value: './svg/local_shipping.svg'
    }
  },
  {
    title: 'stock.adjustment',
    path: 'products/search',
    routeExtras: {
      relativeTo: this.router.routerState.root,
      queryParams: {
        normalSearch: false,
      }
    },
    hidden: combineLatest([
      this.disableRoutes$,
      this.disableSales$
    ]).pipe(someInArray(Boolean)),
    icon: {
      type: 'ionicon',
      value: 'swap-horizontal-outline'
    }
  },
  {
    title: 'product.print.price.label',
    path: 'print-price-label',
    hidden: combineLatest([
      this.disableRoutes$,
      this.disableSales$,
      this.$evaAppConfig.printPriceLabelTaskEnabled$.pipe(
        map(enabled => !enabled)
      ),
    ]).pipe(someInArray(Boolean)),
    icon: {
      type: 'ionicon',
      value: 'print-outline'
    },
  }
];

  constructor(
    private $evaAppConfig: EvaApplicationConfigProvider,
    private router: Router,
    private $interLogisticsSwitch: InterLogisticsSwitchProvider,
    private $orderLifecycleService: OrderLifecycleService,
  ) { }

  /** Navigates to a component by its name */
  public async navigateToPage( route: IRoute ) {
    // If there is an onClick function, we will call that instead of navigation
    if (isFunction(route.onClick)) {
      route.onClick(route);

      return;
    }

    try {
      await this.router.navigate([`tabs/${route.path}`], route.routeExtras);
    } catch (error) {
      this.logger.error(`error navigating to ${route.path}`, error);
    }
  }

  disableRoutes() {
    this.disableRoutes$.next(true);
  }

  enableRoutes() {
    this.disableRoutes$.next(false);
  }

  isOfflineMode() {
    this.isOffline$.next(true);
  }

  isOnlineMode() {
    this.isOffline$.next(false);
  }
}
