import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';

@Pipe({
  name: 'isNil',
})
export class IsNilPipe implements PipeTransform {
  /**
   * Checks whether an element is either null or undefined using lodash
   */
  transform( value: any ): boolean {
    const condition = isNil(value);
    return condition;
  }
}
