import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { IEvaToastError } from 'src/app/modules/eva-toast/eva-toast-interface';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { GetKeyFromInterface } from 'src/app/shared/typings';

@Component({
  selector: 'eva-error-viewer-modal',
  templateUrl: './error-viewer.modal.html',
  styleUrls: ['./error-viewer.modal.scss']
})
export class ErrorViewerModalComponent {
  public error: GetKeyFromInterface<IEvaToastError, 'error'> = this.navParams.get('error');

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private $clipboard: ClipboardService
  ) {}

  async copyError() {
    const json = JSON.stringify(this.error, null, 4);

    this.$clipboard.copyToClipboard(json);

    this.modalCtrl.dismiss();
  }

}
