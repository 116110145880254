import { Injectable } from '@angular/core';
import {
  communicator,
  getCurrentUser,
  IClientApp,
  IClientAppMessage,
  IHubClientAppJoinRequest,
  IHubEventJoinedChannelMessage,
  IHubEventLeftChannelMessage,
  settings
} from '@springtree/eva-sdk-redux';
import { isEqual } from 'lodash';
import nodePackage from 'package.json';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { generateRandomName } from 'src/app/shared/utils/name-generator';
import { v4 } from 'uuid';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { SelectedOrganisationUnitProvider } from '../selected-organisation-unit/selected-organisation-unit';

/**
 * The client app communication SignalR hub functions like a chat services between EVA Apps.
 * It can be used when applications need to talk to each other directly.
 * Examples of this are using another app as a source for a scanned barcode of to transfer
 * an order or logged in user. It also used to drive the CFD application.
 *
 * NOTE: All channels are local to an organization unit. This can not be used inter-store.
 *
 * The hub provides the means to join channels and send messages.
 * The SDK provides access to these methods and handler for apps joining and leaving channels.
 * You will not be able to see messages that occurred before you joined a channel.
 *
 * For an application to use the client app hub it needs to first register itself with a
 * unique identifier which we will persist in local storage.
 */
@Logger('[client-app-communication-provider]')
@Injectable()
export class ClientAppCommunicationProvider implements ILoggable {

  /**
   * The default logger
   */
  logger: Partial<Console>;

  /**
   * The stream of messages coming from the current client app hub
   *
   * @type {Subject<IClientAppMessage>}
   */
  public messages$: Subject<IClientAppMessage> = new Subject();

  /**
   * The stream of events of clients joining any of the active channels
   */
  public joined$: Subject<IHubEventJoinedChannelMessage> = new Subject();

  /**
   * The stream of events of clients leaving any of the active channels
   */
  public left$: Subject<IHubEventLeftChannelMessage> = new Subject();

  /**
   * The stream of detected peer clients
   */
  public peers$ = communicator.peers$;

  /**
   * The stream of events indicating the hub is ready for use
   */
  private isConnected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public connected$ = this.isConnected$.asObservable();

  /**
   * Indicates the currently logged in user is an employee
   * Used to connect to the client app hub for the current organization unit
   * We need to ensure this only emits on a distinct value change and
   * when the user has progressed through the login enough to have a user token
   */
  private isEmployee$: Observable<boolean> = getCurrentUser.getState$().pipe(
    map(state => !!state?.isEmployee),
    distinctUntilChanged(isEqual),
  );

  /**
   * Our client application details. Populated in the constructor
   */
  private clientApp: IClientApp;

  /**
   * This will remember the join payload for the client app hub
   * which will be used when we want to close our current connection.
   * It's existence is also a good indicator that we are using
   * the client app hub
   */
  private clientAppJoinRequest: IHubClientAppJoinRequest | undefined;

  /**
   * Construct the provider and prepare our client app details
   */
  constructor(
    private $selectedOrganisationUnit: SelectedOrganisationUnitProvider,
  ) {
    // Pull the last known application instance identifier
    // from localStorage or create a new one.
    // Format is a UUID prefixed with out application name
    //
    let appId = localStorage.getItem('evaCompanion:applicationInstanceId');
    let appName = localStorage.getItem('evaCompanion:applicationInstanceName');
    if (!appId) {
      appId = `${nodePackage.name}-${v4()}`;
      localStorage.setItem('evaCompanion:applicationInstanceId', appId);
    }
    if (!appName) {
      appName = `CAPP - ${generateRandomName()}`;
      localStorage.setItem('evaCompanion:applicationInstanceName', appName);
    }
    this.clientApp = {
      UUID: appId,
      DisplayName: appName,
    };
  }

  /**
   * Called from the app.component to start our required listeners and
   * actually provide our services
   */
  initialise() {
    this.listenForEmployeeLogin();
  }

  /**
   * Join a channel on the current client app hub
   */
  public async joinChannel(channel: string) {
    if (!this.clientAppJoinRequest) {
      throw new Error('Not connected to client app hub');
    }

    await communicator.joinChannel({
      App: this.clientApp,
      ChannelID: channel,
    });
    this.logger.log(`Joined client app hub channel: ${channel}`);
  }

  /**
   * Leave a channel on the current client app hub
   */
   public async leaveChannel(channel: string) {
    if (!this.clientAppJoinRequest) {
      throw new Error('Not connected to client app hub');
    }

    await communicator.leaveChannel({
      App: this.clientApp,
      ChannelID: channel,
    });
    this.logger.log(`Left client app hub channel: ${channel}`);
  }

  /**
   * Sends a message using the client app hub (if connected)
   */
  public async sendMessage(appMessage: IClientAppMessage): Promise<void> {
    if (!this.clientAppJoinRequest) {
      throw new Error('Not connected to client app hub');
    }

    await communicator.sendAppMessage(appMessage);
    this.logger.log('Sent app message', appMessage);
  }

  /**
   * Used to force an immediate reconnect attempt
   * Useful for app resume
   */
   public reconnect() {
    return communicator.reconnect();
  }

  /**
   * Once an employee logs in we can connect to the client app hub for the
   * current organization unit.
   * If we are logged out we will connect for the last known organization unit.
   */
  private listenForEmployeeLogin() {
    this.isEmployee$.subscribe(async (isEmployee) => {
      this.logger.log('User employee status changes', isEmployee);

      // First close any current connection we may have
      // The isEmployee$ will only emit when changed
      //
      if (this.clientAppJoinRequest) {
        this.logger.log('Employee login state changed. Leaving current client app hub');
        try {
          await communicator.leaveClientAppHub(this.clientAppJoinRequest);
        } catch (error) {
          this.logger.warn('Error leaving client app hub', error);
        }
        this.clientAppJoinRequest = undefined;
      }

      let currentOrganizationUnitId = 0;
      if (isEmployee) {
        const userState = getCurrentUser.getState().response;
        const organizationUnitId = userState.User.CurrentOrganizationID;

        if (organizationUnitId) {
          this.clientAppJoinRequest = {
            app: this.clientApp,
            token: settings.userToken,
            options: {
              requestedOrganizationUnitID: organizationUnitId,
            },
            messageReceivedCallback: (message) => {
              this.messages$.next(message);
            },
            joinChannelCallback: (event) => {
              this.joined$.next(event);
            },
            leaveChannelCallback: (event) => {
              this.left$.next(event);
            }
          };

          this.logger.log(`Joining client hub for organization unit ${organizationUnitId}`);
          await communicator.joinClientAppHub(this.clientAppJoinRequest);
          currentOrganizationUnitId = organizationUnitId;
        }
      } else {
        // Connect for the last known organization unit
        //
        const selectedOrganisationUnitId = this.$selectedOrganisationUnit.getLastUsedOrganisation();
        if (selectedOrganisationUnitId) {
          this.clientAppJoinRequest = {
            app: this.clientApp,
            token: settings.userToken,
            options: {
              requestedOrganizationUnitID: selectedOrganisationUnitId,
            },
            messageReceivedCallback: (message) => {
              this.messages$.next(message);
            },
            joinChannelCallback: (event) => {
              this.joined$.next(event);
            },
            leaveChannelCallback: (event) => {
              this.left$.next(event);
            }
          };

          this.logger.log(`Joining client hub for organization unit ${selectedOrganisationUnitId}`);
          await communicator.joinClientAppHub(this.clientAppJoinRequest);

          // Join the default organization unit channel
          //
          await communicator.joinChannel({
            App: this.clientApp,
            ChannelID: `OU:${selectedOrganisationUnitId}`,
          });

          currentOrganizationUnitId = selectedOrganisationUnitId;
        }
      }

      // Join the default organization unit channel
      //
      await communicator.joinChannel({
        App: this.clientApp,
        ChannelID: `OU:${currentOrganizationUnitId}`,
      });

      this.isConnected$.next(true);
    });
  }
}
