import { Injectable } from '@angular/core';
import { Logger, ILoggable } from '../../shared/decorators/logger';
import { TranslateService } from '@ngx-translate/core';
import { EvaPaymentMethodProvider } from '../eva-payment-method/eva-payment-method';
import { ModalController } from '@ionic/angular';
import { TimeoutError } from '@springtree/eva-sdk-redux';
import { EvaErrorGeneratorProvider } from '../eva-error-generator/eva-error-generator';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { PaymentTimeoutModalComponent } from 'src/app/modals/payment-timeout-modal/payment-timeout.modal';

/**
 * A payment can result in multiple errors, this provider will be responsible for handling said errors
 */
@Logger('[eva-payment-error-handler-provider]')
@Injectable()
export class EvaPaymentErrorHandlerProvider implements ILoggable {
  logger: Partial<Console>;

  constructor(
    private toastCtrl: EvaToastController,
    private $translate: TranslateService,
    private $paymentMethod: EvaPaymentMethodProvider,
    private modalCtrl: ModalController,
    private $evaErrorHandler: EvaErrorGeneratorProvider
  ) { }

  async handleError( errorPayload: any ) {
    this.logger.error('Error performing payment because of', errorPayload);

    // If the request times out or the fetch threw a TypeError which could potentially be due to connectivity we will show
    // a modal that will try to re-establish the connection
    //
    if ( errorPayload instanceof TimeoutError || errorPayload instanceof TypeError ) {
      const paymentTimeoutModalModal = await this.modalCtrl.create(
        {
          component: PaymentTimeoutModalComponent,
          backdropDismiss: false
        },
      );

      paymentTimeoutModalModal.present();

      return;
    }

    /** The error message we will be showing to the user */
    let errorMessage = this.$translate.instant('payment.fail');

    /** we'll show a specific message for status code 403 (Forbidden) */
    if (errorPayload.status === 403) {
      errorMessage = this.$translate.instant('payment.fail.unauthorized');
    }

    switch ( errorPayload.error ) {
      case 'INVALID_ORDER':
      case 'CARD_NR_REQUIRED':
        // Noop
      break;

      case 'STATION_REQUIRED':
        errorMessage = this.$translate.instant('payment.update.fail.station');
      break;

      case 'UNSUPPORTED_METHOD':
        const paymentMethodLabel = this.$paymentMethod.selectedPaymentMethodModelIncludingType$.value.Type.Code;

        errorMessage = this.$translate.instant('payment.method.unavailable', {
          paymentMethod: paymentMethodLabel
        });
      break;
    }

    if (errorPayload.response?.Error?.Type === 'NeedsCommitment') {
      errorMessage = this.$translate.instant('insufficient.stock');
    }

    const errorData = await this.$evaErrorHandler.constructFailureFeedback(errorPayload, errorMessage);

    this.toastCtrl.create(errorData).present();
  }

}
