import { Directive, Input, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import isRequired from '../../shared/decorators/members/is-required';

@Directive({
  selector: '[stop-propagation]'
})
export class StopPropagationDirective implements AfterViewInit, OnDestroy {
  @isRequired
  @Input('stop-propagation') propertyListener: string;

  private listener: Function;

  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    this.listener = (event: MouseEvent) => {
      event.stopPropagation();
    };

    (this.el.nativeElement as HTMLElement).addEventListener(this.propertyListener, this.listener.bind(this)) ;
  }

  ngOnDestroy() {
    (this.el.nativeElement as HTMLElement).removeEventListener(this.propertyListener, this.listener as EventListenerOrEventListenerObject);
  }
}
