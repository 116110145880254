import { Directive, ElementRef, AfterViewInit, OnDestroy, HostBinding } from '@angular/core';

/**
 * Whenever the ion-footer element is empty, meaning there are no html children. We want to hide it.
 */
@Directive({
  selector: 'ion-footer',
})
export class IonFooterEmptyDirective implements AfterViewInit, OnDestroy {

  private mutationObserver: MutationObserver;

  public get footerEl(): HTMLElement {
    return this.el.nativeElement;
  }

  @HostBinding('hidden') hiddenValue: true|null = null;

  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    this.checkForChildren();

    this.mutationObserver = new MutationObserver(() => {
      this.checkForChildren();
    });

    this.mutationObserver.observe(this.el.nativeElement, {
      childList: true
    });
  }

  /** If there are no children, we will hide this element */
  checkForChildren() {
    const shouldHide = this.footerEl.children.length === 0;
    this.hiddenValue = shouldHide ? true : null;
  }

  ngOnDestroy() {
    this.mutationObserver.disconnect();
  }
}
