import { Component, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { noop } from 'lodash-es';
import { IItemsSearchData, ItemSearchComponent } from 'src/app/modals/item-search/item-search';
import isRequired from '../../shared/decorators/members/is-required';


@Component({
  selector: 'eva-item-search-button',
  templateUrl: 'item-search-button.component.html',
  styleUrls: ['item-search-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ItemSearchButtonComponent,
      multi: true
    }
  ]
})

export class ItemSearchButtonComponent implements ControlValueAccessor {
  @isRequired
  @Input() itemSearchData: IItemsSearchData<any>;

  private onTouchedCallback: Function = noop;

  private onChangeCallBack: (val: any) => void = noop;

  formControl = new FormControl();

  constructor(private modalCtrl: ModalController) {}

  @HostListener('blur') onblur() {
    this.onTouchedCallback();
  }

  async openModal($event: MouseEvent) {
    $event.stopPropagation();
    const modal = await this.modalCtrl.create({
      component: ItemSearchComponent,
      componentProps: {
        itemSearchData: this.itemSearchData,
      },
    });

    await modal.present();

    const dataFromModal = await modal.onDidDismiss();

    if (dataFromModal.data) {
      this.formControl.setValue(dataFromModal.data[this.itemSearchData.valueKey]);
    }
  }

  // Gets called when the consumer uses this component
  // writes values. gives us a value and we act on it "input decorator""
  writeValue(valueKey: string): void {
    const selectedItem = this.itemSearchData.items
      .find( item => item[this.itemSearchData.valueKey] === valueKey );

    if (selectedItem) {
      this.formControl.setValue(selectedItem[this.itemSearchData.valueKey]);
    }
  }

  // "Output decorator", sends the value outwards to parent
  // gives a fn that we need to store
  registerOnChange(fn: any): void {
    this.formControl.valueChanges.subscribe(fn);
  }

  // Same just on touched
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  // if parent tells us the control is disabled
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  }
}
