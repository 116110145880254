import { Injectable } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { addTrackingCodeToReturnToSupplierShipment, getCurrentUser, listAvailableUserTasks, listReturnToSupplierTaskOrderLines, listReturnToSupplierTaskShipmentLines, setLineForReturnToSupplierShipment, store } from '@springtree/eva-sdk-redux';
import { isNil } from 'lodash';
import { isEmpty } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { EvaApplicationConfigProvider } from 'src/app/services/eva-application-config/eva-application-config';
import { ScanPageProvider } from 'src/app/services/scan-page/scan-page';
import { IViewTask } from 'src/app/services/user-task/types';
import { EvaFeedback, IExtendedFeedback } from 'src/app/shared/decorators/eva-feedback';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';
import isNotNil from 'src/app/shared/operators/isNotNil';
import resumeScan from 'src/app/shared/operators/resumeScan';
import { ReturnToSupplierTaskSetProductsErrorModalComponent } from './components/return-to-supplier-task-set-products-error-modal/return-to-supplier-task-set-products-error.modal';
import { IReturnToSupplierSelection, ReturnToSupplierSelectionType, ReturnToSupplierUIComponent } from './return-to-supplier-task.typings';

interface IUpdateSelectionInShipment {
  selection: IReturnToSupplierSelection;
  selectionType: ReturnToSupplierSelectionType;
  taskId: number;
  unselecting: boolean;
}

@Logger('[return-to-supplier-task-provider]')
@Injectable({
  providedIn: 'root'
})
export class ReturnToSupplierTaskProvider implements ILoggable {
  logger: Partial<Console>;

  /**
   * the total number of items the user selected.
   * In pick mode this will represent the items the user picked
   * In exclude mode this will represent the items the user excluded
   */
  selectionTotal$ = new BehaviorSubject(0);

  constructor(
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private $appConfig: EvaApplicationConfigProvider,
    private $translate: TranslateService,
    private $scanPage: ScanPageProvider
  ) {}

  async handleReturnToSupplierTask(selectedTask: Partial<IViewTask>, navCtrl: NavController) {
    // We will do the following checks before opening the task
    // 1. does the current user already have a task assigned to them? if they do, we will prevent them from starting another one
    // 2. does the task already have a tacking code set? if not we will ask the user one
    //
    const currentUserId = await getCurrentUser.getResponse$().pipe(
      isNotNil(),
      first(),
      map( response => response.User.ID)
    ).toPromise();

    const allTasks = await listAvailableUserTasks.getResponse$().pipe(
      isNotNil(),
      first(),
      map(response => response.AvailableTasks)
    ).toPromise();

    // We will find all related RTS tasks by order id and check if one of them is already assigned to this user.
    // In doing so we will make sure we filter out the task that was pressed, otherwise it will give us false negatives.
    //
    const alreadyStartedReturnToSupplierTaskByCurrentUser = allTasks
    .filter( task => task.ID !== selectedTask.ID)
    .find( task => {
      const assignedToCurrentUser = task.User?.ID === currentUserId;

      const matchingTask = task.Type.Name === selectedTask.Type.Name && task.Data['OrderID'] === selectedTask.Data['OrderID'] && assignedToCurrentUser;

      return matchingTask;
    });

    // If the user already started a task, we will notify him about it and ask them if they want to open that one instead.
    // One future improvement could be making sure the already assigned to other user alert isnt shown in this context OR
    // making sure backend doesnt return tasks that we cannot start anyway.
    //
    if ( !isNil(alreadyStartedReturnToSupplierTaskByCurrentUser)) {
      this.alertCtrl.create({
        header: this.$translate.instant('return.to.supplier.task.already.started.task.error.title'),
        message: this.$translate.instant('return.to.supplier.task.already.started.task.error.message'),
        buttons: [this.$translate.instant('action.cancel'), {
          text: this.$translate.instant('return.to.supplier.task.already.started.task.error.button'),
          handler: () => {
            this.handleReturnToSupplierTask(alreadyStartedReturnToSupplierTaskByCurrentUser, navCtrl);
          }
        }]
      }).then( alert => alert.present() );

      return;
    }

    const trackingCodeSet: boolean = !isEmpty(selectedTask.Data?.TrackingCode as string);

    const canHaveTrackingCode: boolean = await this.$appConfig.returnToSupplierShipmentCanHaveTrackingCode$.pipe(first()).toPromise();

    /**
     * navigate to RTS StockMovement page
     * Ticket: https://n6k.atlassian.net/browse/OPTR-21363
     */
    if (selectedTask.SubType?.Name === 'ReturnToSupplierStockMovement') {

      const navigateToReturnToSupplierStockMovementTask = () => {
      return navCtrl.navigateForward([`rts-stock-movement-task/${selectedTask.ID}`]);
    };

      if (trackingCodeSet || !canHaveTrackingCode) {
        navigateToReturnToSupplierStockMovementTask();

        return;
      }
    }

    const navigateToReturnToSupplierTask = () => {
      return navCtrl.navigateForward([`return-to-supplier-task/${selectedTask.ID}`]);
    };

    if (trackingCodeSet || !canHaveTrackingCode) {
      navigateToReturnToSupplierTask();

      return;
    }

    try {
      const scan = await this.$scanPage.openPage({
        navCtrl: navCtrl,
        title: this.$translate.instant('ship.order.start.notice'),
        typeOfScan: 'NONE',
      });

      scan.relatedScan$$.pipe(
        resumeScan()
      ).subscribe(async barcodeResult => {
        const parsedBarcode = barcodeResult.parsedData.Value;

        try {
          await this.addShippingLabel(selectedTask.ID, parsedBarcode);

          await this.$scanPage.dismissPage(navCtrl);

          await navigateToReturnToSupplierTask();
        } catch (error) {
          this.logger.error('error adding shipping label to the task and navigating to it', error);
        }
      });
    } catch (error) {
      this.logger.error('error opening scan page');
    }
  }

  @EvaFeedback({
    i18nFailKey: 'return.to.supplier.task.add.shipment.label.fail',
    i18nSuccessKey: 'return.to.supplier.task.add.shipment.label.success'
  })
  private async addShippingLabel(taskId: number, trackingCode: string) {
    const [action, promise] = addTrackingCodeToReturnToSupplierShipment.createFetchAction({
      UserTaskID: taskId,
      TrackingCode: trackingCode
    });

    store.dispatch(action);

    try {
      await promise;
    } catch ( error ) {
      this.logger.error('error adding addShippingLabelToReturnToSupplierShipment', error);
    }

    return promise;
  }

  /** we will call this to update the selection total which is shown in the top right corner */
  @EvaFeedback({
    i18nFailKey: 'return.to.supplier.task.selection.fetch.fail',
  })
  async fetchSelectionTotal(taskId: number, selectionType: ReturnToSupplierSelectionType) {
    const service = await this.getAppropriateService(ReturnToSupplierUIComponent.summaryTotal, selectionType);

    const [action] = service.createFetchAction({
        UserTaskID: taskId,
        PageConfig: {
          Limit: 0
        }
      });

      store.dispatch(action);

      const promise = service.fetchData(action);

      try {
        const response = await promise;

        this.selectionTotal$.next(response.Result?.Total ?? 0);
      } catch (error) {
        this.logger.error(`error loading total in ${service.name}`, error);
      }

    return promise;
  }

  @EvaFeedback()
  updateSelectionInShipment({
    selection,
    selectionType,
    taskId,
    unselecting,
  }: IUpdateSelectionInShipment) {
    /** This will be an array of products we failed to set on the shipment, we wil use this in communication with the user. */
    const failedToSetProducts: IReturnToSupplierSelection = {};

    const formValueEntries = Object.entries(selection);

    const excludeMode = selectionType === ReturnToSupplierSelectionType.Exclude;

    const ExcludeValue = unselecting
      ? excludeMode
        ? false
        : true
      : excludeMode
        ? true
        : false
      ;

    const feedbackPromises = formValueEntries.map(([productId, quantity]) => {
      /** this will always be a number if we make sure the form controls always use a valid productId */
      const numericProductId: number = +productId;

      const [action, promise] =
        setLineForReturnToSupplierShipment.createFetchAction({
          ProductID: numericProductId,
          Quantity: quantity,
          UserTaskID: taskId,
          Exclude: ExcludeValue,
        });

      store.dispatch(action);

      promise.catch((error) => {
        this.logger.error(
          `error calling setLineForReturnToSupplierShipment for productId=${productId} quantity=${quantity}`,
          error
        );

        failedToSetProducts[productId] = quantity;
      });

      return promise;
    });

    const multipleLines = formValueEntries.length > 1;

    const feedback = {
      failure: '',
      success: ''
    };

    if ( unselecting ) {
      const failedFeedback = {
        [ReturnToSupplierSelectionType.Exclude]: 'return.to.supplier.task.exclude.unselect.product.fail',
        [ReturnToSupplierSelectionType.Pick]: 'return.to.supplier.task.exclude.product.fail'
      };

      const failedFeedbackPlural = {
        [ReturnToSupplierSelectionType.Exclude]: 'return.to.supplier.task.exclude.unselect.product.plural.fail',
        [ReturnToSupplierSelectionType.Pick]: 'return.to.supplier.task.exclude.product.fail'
      };

      const successFeedback = {
        [ReturnToSupplierSelectionType.Exclude]: 'return.to.supplier.task.exclude.unselect.product.success',
        [ReturnToSupplierSelectionType.Pick]: 'return.to.supplier.task.exclude.product.success',
      };

      const successFeedbackPlural = {
        [ReturnToSupplierSelectionType.Exclude]: 'return.to.supplier.task.exclude.unselect.product.plural.success',
        [ReturnToSupplierSelectionType.Pick]: 'return.to.supplier.task.exclude.product.success',
      };

      feedback.failure = multipleLines
      ? failedFeedbackPlural[selectionType]
      : failedFeedback[selectionType];

      feedback.success = multipleLines
      ? successFeedbackPlural[selectionType]
      : successFeedback[selectionType];
    } else {
      const failedFeedback = {
        [ReturnToSupplierSelectionType.Exclude]:
          'return.to.supplier.task.exclude.product.fail',
        [ReturnToSupplierSelectionType.Pick]:
          'return.to.supplier.task.pick.product.fail',
      };

      const failedFeedbackPlural = {
        [ReturnToSupplierSelectionType.Exclude]:
          'return.to.supplier.task.exclude.product.plural.fail',
        [ReturnToSupplierSelectionType.Pick]:
          'return.to.supplier.task.pick.product.plural.fail',
      };

      const successFeedback = {
        [ReturnToSupplierSelectionType.Exclude]:
          'return.to.supplier.task.exclude.product.success',
        [ReturnToSupplierSelectionType.Pick]:
          'return.to.supplier.task.pick.product.success',
      };

      const successFeedbackPlural = {
        [ReturnToSupplierSelectionType.Exclude]:
          'return.to.supplier.task.exclude.product.success',
        [ReturnToSupplierSelectionType.Pick]:
          'return.to.supplier.task.pick.product.plural.success',
      };

      feedback.failure = multipleLines
      ? failedFeedbackPlural[selectionType]
      : failedFeedback[selectionType];

      feedback.success = multipleLines
      ? successFeedbackPlural[selectionType]
      : successFeedback[selectionType];
    }


    const feedbackPromise = this.allSettled(...feedbackPromises).then( results => {
      return results.map( result => {
        const castedResult = result as {status: string, value?: unknown};
        if ( castedResult.value) {
          return castedResult.value as EVA.Core.EmptyResponseMessage;
        } else {
          throw new Error((castedResult as {status: string, reason: any}).reason);
        }
      });
    });

    const evaFeedback: IExtendedFeedback = {
      feedbackPromise,
      i18nFailParams: feedback.failure,
      i18nSuccessParams: feedback.success,
      finallyCallback: () => {
        this.logger.log('finally callback..');
        if (Object.entries(failedToSetProducts || {}).length > 0) {
          this.showSelectionFailureFeedback({
            selection: failedToSetProducts,
            selectionType,
            unselecting,
            taskId,
          });
        }

        this.fetchSelectionTotal(taskId, selectionType);
      },
    };

    return evaFeedback;
  }

    /**
   * will return the service we should use depending on the selection type & UI component. We need to do this because in
   * exclude/pick mode need to swap the usage of services
   * */
  async getAppropriateService(uiComponent: ReturnToSupplierUIComponent, selectionType: ReturnToSupplierSelectionType) {
    const servicesMapping = {
      [ReturnToSupplierSelectionType.Exclude]: {
        [ReturnToSupplierUIComponent.mainPage]: listReturnToSupplierTaskShipmentLines,
        [ReturnToSupplierUIComponent.searchModal]: listReturnToSupplierTaskShipmentLines,
        [ReturnToSupplierUIComponent.summaryModal]: listReturnToSupplierTaskOrderLines,
        [ReturnToSupplierUIComponent.summaryTotal]: listReturnToSupplierTaskOrderLines,
      },
      [ReturnToSupplierSelectionType.Pick]: {
        [ReturnToSupplierUIComponent.mainPage]: listReturnToSupplierTaskOrderLines,
        [ReturnToSupplierUIComponent.searchModal]: listReturnToSupplierTaskOrderLines,
        [ReturnToSupplierUIComponent.summaryModal]: listReturnToSupplierTaskShipmentLines,
        [ReturnToSupplierUIComponent.summaryTotal]: listReturnToSupplierTaskShipmentLines,
      }
    };

    const matchingService = servicesMapping[selectionType][uiComponent];

    if (isNil(matchingService)) {
      this.logger.error('error in getAppropriateService, couldnt find matching service');
    }

    return matchingService;
  }

  /** Communicates with the user which lines werent set due to an error */
  private async showSelectionFailureFeedback({
    selection,
    selectionType,
    unselecting,
  }: Partial<IUpdateSelectionInShipment>) {
    const modal = await this.modalCtrl.create({
      component: ReturnToSupplierTaskSetProductsErrorModalComponent,
      componentProps: {
        selectionType,
        selection,
        unselecting,
      }
    });

    modal.present();

    const dismissPromise = modal.onDidDismiss();

    return dismissPromise;
  }

  private allSettled(...promises: Array<Promise<unknown>>) {
    const mappedPromises = promises.map((promise) => {
      return promise
        .then((value) => {
          return {
            status: 'fulfilled',
            value,
          };
        })
        .catch((reason) => {
          return {
            status: 'rejected',
            reason,
          };
        });
    });
    return Promise.all(mappedPromises);
  }
}
