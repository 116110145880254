import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'lodash-es';
import { FusejsService, AngularFusejsOptions } from './fusejs.service';


@Pipe({ name: 'fusejs' })
export class FusejsPipe implements PipeTransform {
  constructor(
    private fusejsService: FusejsService
  ) { }

  transform(elements: Array<Object>, searchTerms: string, options: AngularFusejsOptions = {}) {
    if (isEmpty(searchTerms)) {
      return elements;
    }

    return this.fusejsService.searchList(elements, searchTerms, options);
  }
}
