import { Injectable, Inject, OnDestroy, ComponentRef, EmbeddedViewRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Logger, ILoggable } from '../../shared/decorators/logger';

/**
 * Will return a ViewContainRef which will is essential for dynamically created components
 * Its inspired by angular material cdk's portal, overlay and overlayContainer
 *
 * Basically this service will be creating a space for overlays. This is the overlay container.
 * In that container. There will be smaller pieces called overlays which will contain our dynamically created components
 *
 * @see https://blog.angularindepth.com/angular-cdk-portals-b02f66dd020c
 * @see https://blog.angularindepth.com/exploring-angular-dom-abstractions-80b3ebcfc02
 */
@Logger('[overlay-provider]')
@Injectable()
export class OverlayProvider implements OnDestroy, ILoggable {
  logger: Partial<Console>;

  private containerElement: HTMLElement;

  private createdOverlays = new Map<string, HTMLElement>();

  constructor( @Inject(DOCUMENT) private _document: Document) { }

  ngOnDestroy() {
    if (this.containerElement && this.containerElement.parentNode) {
      this.containerElement.parentNode.removeChild(this.containerElement);
    }
  }

  /** Inserts a component into an overlay prefferably created by the createOverlay method */
  public insertComponent(componentRef: ComponentRef<any>, overlay: HTMLElement) {
    const component = this._getComponentRootNode(componentRef);

    overlay.appendChild(component);
  }

  /** returns an overlay, will create it if doesnt exist */
  public getOverlay( className: string ): HTMLElement {
    const overlayInMap = this.createdOverlays.get(className);
    if ( overlayInMap ) {
      return overlayInMap;
    } else {
      return this.createOverlay(className);
    }
  }

  private createOverlay(className: string): HTMLElement {
    const pane = this._document.createElement('div');
    pane.classList.add(className);
    this.getContainerElement().appendChild(pane);
    this.createdOverlays.set(className, pane);
    return pane;
  }

  /**
   * This method returns the overlay container element. It will lazily
   * create the element the first time  it is called to facilitate using
   * the container in non-browser environments.
   * @returns the container element
   */
  private getContainerElement(): HTMLElement {
    if (!this.containerElement) {
      this.createContainer();
    }
    return this.containerElement;
  }

   /**
   * Create the overlay container element, which is simply a div
   * with the 'eva-overlay-container' class on the document body.
   * This container will contain all the overlay elements
   */
  private createContainer(): void {
    const container = this._document.createElement('div');

    container.classList.add('eva-overlay-containers');
    this._document.body.querySelector('ion-app').appendChild(container);
    this.containerElement = container;
  }

  /** Gets the root HTMLElement for an instantiated component. */
  private _getComponentRootNode(componentRef: ComponentRef<any>): HTMLElement {
    return (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
  }

}
