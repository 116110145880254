import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * This pipe will take a gravatar hash as input and will return a sanitized url
 */
@Pipe({
  name: 'gravatar',
})
export class GravatarPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(gravatarHash: string) {
    const imageUrl = `https://www.gravatar.com/avatar/${gravatarHash}`;

    return this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
  }
}
