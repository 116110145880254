import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { IPurchaseOrderShipment } from '../incoming-shipment-card/incoming-shipment-card';

/**
 * Purchase order shipment modal result
 */
export interface IIncomingShipmentModalResult {
  shipment: EVA.Core.PurchaseOrderShipmentDto;
}

/**
 *
 * Modal component that will show multiple shipments that a user can choose from
 *
 * @see https://eva2015.atlassian.net/browse/OPTR-6784
 */
@Component({
  selector: 'eva-incoming-shipment-modal',
  templateUrl: './incoming-shipment-modal.html',
  styleUrls: ['./incoming-shipment-modal.scss']
})
export class IncomingShipmentModalComponent {
  /**
   * Purchase order shipments the user can choose from
   */
  public readonly shipments: EVA.Core.PurchaseOrderShipmentDto[] = this.navParams.get('purchaseOrderShipments');

  /**
   * Chosen shipment item
   */
  public chosenShipment: EVA.Core.PurchaseOrderShipmentDto = null;

  public constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams
  ) { }

  /**
   * Dismiss the modal
   */
  public dismiss() {
    this.modalCtrl.dismiss();
  }

  /**
   * Triggered when the user clicks on a shipment item
   */
  public chooseShipment( shipment: IPurchaseOrderShipment ) {
    if ( this.chosenShipment === shipment ) {
      // Toggle
      //
      this.chosenShipment = null;
    } else {
      this.chosenShipment = shipment;
    }
  }

  /**
   * Called when the user clicks the modal button
   */
  public confirmChosenShipment() {
    this.closeWithResult(this.chosenShipment);
  }

  /**
   * Pass the chosen shipment result to the user
   */
  private closeWithResult(shipment: EVA.Core.PurchaseOrderShipmentDto) {
    const modalResult: IIncomingShipmentModalResult = {
      shipment
    };

    this.modalCtrl.dismiss(modalResult);
  }
}
