import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { cancelOrder, store } from '@springtree/eva-sdk-redux';
import { EvaFeedback } from '../../shared/decorators/eva-feedback';
import { timeToShowSpinnerDefaults } from '../../shared/constants';
import { OrderLifecycleService } from 'src/app/services/order-lifecycle/order-lifecycle.service';

/**
 * @see https://eva2015.atlassian.net/browse/OPTR-5585
 */
@Logger('[return-orders-modal-component]')
@Component({
  selector: 'return-orders-modal',
  templateUrl: './return-orders.modal.html',
  styleUrls: ['./return-orders.modal.scss']
})
export class ReturnOrdersModalComponent implements ILoggable {
  public logger: Partial<Console>;

  public readonly restrictedOrderLines: EVA.Core.OrderLineDto[] = this.navParams.get('restrictedOrderLines');

  public readonly orderId: number = this.navParams.get('orderId');

  public cancelOrderLineIsFetching$ = cancelOrder.isFetching$();

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    private $orderLifecycleService: OrderLifecycleService,
  ) {}

  @EvaFeedback({
    i18nFailKey: 'cart.lines.delete.fail',
    i18nSuccessKey: 'cart.lines.delete.success',
    timeToShowSpinner: timeToShowSpinnerDefaults.long,
  })
  public async deleteRestrictedProducts() {
    const promises: Promise<any>[] = this.restrictedOrderLines.map(async restrictedOrderLine => {
      const request: EVA.Core.CancelOrder = { OrderLines: [{OrderLineID: restrictedOrderLine.ID}] };

      const {cartOperationPromise} = await this.$orderLifecycleService.cancelOrder({request});

      return cartOperationPromise;
    });

    const allPromises = Promise.all(promises);

    try {
      await allPromises;
      // If everything ended with success, close the modal
      //
    } finally {
      this.closeModal();
    }

    return allPromises;
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }
}
