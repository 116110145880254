import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ComponentsModule } from 'src/app/components/components.module';
import { WebVitalsModalComponent } from './web-vitals-modal/web-vitals-modal.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    IonicModule,
  ],
  declarations: [WebVitalsModalComponent]
})
export class WebVitalsModule { }
