<ion-header>
  <ion-toolbar>
    <ion-title>{{ modalTitle }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <eva-pin-input [label]="'create.pin.subtitle' | translate" [dotsFilledInCallback]="createPin"></eva-pin-input>
</ion-content>
