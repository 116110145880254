import { Component, Input, HostBinding, ViewEncapsulation } from '@angular/core';

/**
 * This component will be used to show the user feedback about the fact this current view is empty
 *
 * @example
 * ```html
 * <eva-empty-state>
 *    <h2> Your basket is empty </h2>
 *    <ion-icon name="cart"></ion-icon>
 *    <eva-icon [icon]="iconStream$ | async"></eva-icon>
 * </eva-empty-state>
 * ```
 */
@Component({
  selector: 'eva-empty-state',
  templateUrl: './empty-state.html',
  styleUrls: ['./empty-state.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmptyStateComponent {

  /** Absolutely centers this component */
  @Input('absolute-center')
  @HostBinding('class.center') center = true;
}
