import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { getShoppingCart, IEvaProductRequirement, updateSerialNumber } from '@springtree/eva-sdk-redux';
import { cloneDeep, find, get, isEmpty, isEqual, isNil } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';
import { EvaProductRequirementsProvider, IOrderLineRequirementOperation, IOrderLineRequirementState, SupportedOrderLineRequirements } from '../../services/eva-product-requirements/eva-product-requirements';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import isRequired from '../../shared/decorators/members/is-required';
import isNotNil from '../../shared/operators/isNotNil';
import { IRequirements } from '../product-details-requirements/product-details-requirements.component';

export interface IProductRequirementObject {
  type: 'Bool' | 'String' | 'Integer' | 'Decimal' | 'Enum';
  value: any;
  name?: any;
}
@Logger('[order-line-requirement-component]')
@Component({
  selector: 'eva-order-line-requirement',
  templateUrl: './order-line-requirement.component.html',
  styleUrls: ['./order-line-requirement.component.scss']
})
export class OrderLineRequirementComponent implements ILoggable, OnInit, OnDestroy {
  public logger: Partial<Console>;

  @isRequired
  @Input()
  orderLineId: number;

  @Input()
  controlsDisabled: boolean = null;

  /**
   * This component will be creating actions for the components we are rendering internally
   * every change, this ensures the parent can update the requirements whenever they like
   */
  @Output() orderLineRequirementsChange = new EventEmitter<IOrderLineRequirementState>();

  /**
   * This form will contain serial numbers, but will NOT include the product requirements as they currently dont support
   * control value accessor
   */
  public requirementsForm: FormGroup = this.fb.group({});


  public requirements$ = getShoppingCart.getResponse$().pipe(
    isNotNil(),
    map(response => {
      const matchingOrderLine = response.AdditionalOrderData?.RequiredData?.OrderLines[this.orderLineId] ?? [];

      return matchingOrderLine;
    })
  );

  public hasRequirements$: Observable<boolean> = this.requirements$.pipe(
    map( requirements => {
      const hasRequirements = !isEmpty(requirements);

      return hasRequirements;
    })
  );

  public orderLine$: Observable<EVA.Core.OrderLineDto> = getShoppingCart.getState$().pipe(
    filter(state => !state.isFetching),
    map(state => state.response),
    isNotNil(),
    map(response => response.ShoppingCart),
    isNotNil(),
    map(shoppingCart => {
      /** The matching order line id */
      const matchingOrderLineId = shoppingCart.Lines.find(line => {
        return line.ID === this.orderLineId;
      });

      return matchingOrderLineId;
    })
  );

  public productRequirements$: Observable<IEvaProductRequirement[]> = this.orderLine$.pipe(
    isNotNil(),
    map(line => line.Product),
    map(product => {
      const requirements = <IEvaProductRequirement[]>get(product, 'Properties.product_requirements', []);

      return requirements;
    })
  );

  public hasProductRequirements$: Observable<boolean> = this.productRequirements$.pipe(
    map( productRequirements => {
      const hasRequirements = !isEmpty(productRequirements);

      return hasRequirements;
    })
  );

  @Input()
  public productRequirementsModel: IRequirements = [];

  private _orderLineRequirementsState: IOrderLineRequirementState;

  private stop$ = new Subject<void>();

  public get orderLineRequirementsState(): IOrderLineRequirementState {
    return this._orderLineRequirementsState;
  }

  public showRequirementSerialNumber$: Observable<boolean> = this.requirements$.pipe(
    map(requirements => !isEmpty(requirements.find(requirement => requirement.Name === 'SerialNumber')))
  );


  public set orderLineRequirementsState(value: IOrderLineRequirementState) {
    if ( !isEqual(this._orderLineRequirementsState, value) ) {
      this._orderLineRequirementsState = value;

      this.orderLineRequirementsChange.emit(value);
    }
  }

  public get serialNumbersFormArray(): FormArray {
    if ( this.requirementsForm ) {
      const serialNumbersFormArray = this.requirementsForm.get('serialNumbers') as FormArray;

      return serialNumbersFormArray;
    }
  }

  constructor(
      private fb: FormBuilder,
      private $productRequirements: EvaProductRequirementsProvider,
      private $translate: TranslateService,
      private navCtrl: NavController

    ) {
    this.orderLineRequirementsState = {
      operations: new Map(),
      valid: null
    };
  }

  ngOnInit() {
    this.requirements$.pipe( isNotNil(), take(1) ).subscribe(requirements => {
      this.createSerialNumberFormControl(requirements);
    });
  }

  getProductId(orderlineId: number): number {
    const matchingline = getShoppingCart.getState().response.ShoppingCart.Lines
      .find( line => line.ID === orderlineId );

    const productId = get(matchingline.Product, 'ID');

    return productId;
  }

  openRequirementsModal() {
    if ( this.controlsDisabled ) {
      const productId = this.getProductId(this.orderLineId);

      this.$productRequirements.showProductRequirementsModal(productId, this.orderLineId);
    }
  }

  async openGiftCardPage() {
    this.navCtrl.navigateForward(`gift-card-requirements/${this.orderLineId}`)
      .catch(error => this.logger.error('navigating to page-gift-card-requirements', error ));
  }

  productRequirementsModelChange( newProductRequirements: IRequirements ) {
    const productRequirementUpdateOperation = this.$productRequirements.createProductRequirementUpdateOperation(this.orderLineId, newProductRequirements);

    const orderLineRequirementsStateClone = cloneDeep(this.orderLineRequirementsState);

    orderLineRequirementsStateClone.operations.set(SupportedOrderLineRequirements.PRODUCT_REQUIREMENTS, productRequirementUpdateOperation);

    this.orderLineRequirementsState = orderLineRequirementsStateClone;
  }

  productRequirementsValid(valid: boolean) {
    const orderLineRequirementsStateClone = cloneDeep(this.orderLineRequirementsState);

    orderLineRequirementsStateClone.valid = valid;

    this.orderLineRequirementsState = orderLineRequirementsStateClone;
  }

  async createSerialNumberFormControl(requirements: EVA.Core.Requirement[]) {

    // We currently only support serial numbers beside product requirements, so we want to fetch those
    // and create form controls for them
    //
    const serialNumberRequirement = find( requirements, requirement => requirement.Name === 'SerialNumber' );

    // If this order line has no serial number requirement, exit early.
    //
    if ( isNil(serialNumberRequirement) ) {
      return;
    }

    const matchingOrderLine = await getShoppingCart.getResponse$().pipe(
      isNotNil(),
      map(response => response.ShoppingCart.Lines.find(line => line.ID === this.orderLineId)),
      take(1)
    ).toPromise();

    // Add the form control
    //
    const serialNumberControl = this.fb.control(matchingOrderLine.SerialNumber, Validators.required );

    this.requirementsForm.addControl('serialNumber', serialNumberControl);

    this.setupSerialNumberControlListener();
  }

  ngOnDestroy()  {
    this.stop$.next();
  }

  private setupSerialNumberControlListener() {
    this.requirementsForm.get('serialNumber')
      .valueChanges
      .pipe(
        startWith(this.requirementsForm.get('serialNumber').value)
      )
      .subscribe((newSerialNumberValue: string) => {
        const operation: IOrderLineRequirementOperation = {
          feedback: {
            failure: this.$translate.instant('serial.number.update.fail'),
            success: this.$translate.instant('serial.number.update.success')
          },
          action: updateSerialNumber.createFetchAction({
            OrderLineID: this.orderLineId,
            SerialNumber: newSerialNumberValue
          })
        };

        const orderLineRequirementsStateClone = cloneDeep(this.orderLineRequirementsState);

        orderLineRequirementsStateClone.operations.set(SupportedOrderLineRequirements.SERIAL_NUMBER, operation);

        this.orderLineRequirementsState = orderLineRequirementsStateClone;
      });
  }
}
