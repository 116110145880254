import { IStorableItem } from "../services/recent-items/recent-items.service";

/** Represents an interface we will be showing to users whenever they preformed an action */
export interface IFeedback {
  success: string;
  failure: string;
}

/** This will be what `Data` contains in the case `ParseBarcode` responds with a `Type` of `PRODUCT_BARCODE` */
export interface IParseBarcodeProduct {
  BarcodeName: any;
  ProductID: number;
  Quantity: number;
  TypeID: number;
}
/** This will be what `Data` contains in the case `ParseBarcode` responds with a `Type` of `ORDER` */
export interface IParsedBarcodeOrder {
  OrderID: number;
}

export interface IParsedVerifyCustomer {
  OrderID: number;
  Token: string;
}

export interface IParsedBarcodeStation {
  StationID: number;
}

export interface IParsedRitualsLoyalty {
  BackendRelationID: number;
  BillingAddress: EVA.Core.AddressDto;
  Company: EVA.Core.CompanyDto;
  Data: {
    GoogleContactID: string;
    GoogleLoyaltyID: string
  };
  EmailAddress: string;
  FirstName: string;
  ID: number;
  LastName: string;
  PhoneNumber: number;
  ShippingAddress: EVA.Core.AddressDto;
  Type: EVA.Core.UserTypes;
}

/**
 * @see https://n6k.atlassian.net/browse/OPTR-11148
 */
export interface IParsedUserCustomID {
  UserID: number;
}

export interface IParsedSocialSecurityNumber {
  FirstName: string;
  LastName: string;
  UserID: number;
}

export interface IParsedSession {
  Token: string;
  OrderID: number;
  UserID: number;
}

export interface IParsedInitialCycleCountLabel {
  Number: number;
  ID: number;
}

export interface IParsedDeviceHub {
  DeviceID: string;
  Token: string;
}

export interface IParsedUserTask {
  UserTaskID: number;
  TypeName: string;
  SubTypeName: string;
}

/** Represents an option in our UI which will have a label for the user and an unique id to idenitfy it */
export interface ILabel<T = any> {
  label: string;
  id: T;
}

export interface IOfflineFeedback {
  icon: string;
  label: string;
  cssClass: 'success' | 'fail';
  success: boolean;
}


/** Some meta data we want from the package json */
export interface IPackageMetaData {
  name: string;
  version: string;
}

export interface IParsedRitualsShipFromStoreDeliveryConfirmation {
  OrderID: number;
  ShipmentID: number;
}

/** Get the type of a given key in an interface */
export type GetKeyFromInterface<Interface, Key extends keyof Interface> = Interface[Key];

/**
 * If project is ever upgraded to +TS3.5, we can remove this helper
 * @see https://stackoverflow.com/a/52520678/4047409
 */
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

/**
 * The fields the search will be requesting from eva
 *
 * This is used by both product search overlay and product search modal
 */
export type TProductSearchIncludedFields = 'display_price' | 'display_value' | 'product_id' | 'currency_id' | 'custom_id' | 'primary_image.blob' | 'product_types' | 'barcodes';


/**
 * The current search mode
 *
 * This is used by both product search overlay and product search modal
 */
export type TKeyboardType = 'text' | 'number';


/**
 * Represents a product search view result
 *
 * This is used by both product search overlay and product search modal
 */
export interface IViewProductSearchResult extends IStorableItem {
  blob: string;
  description: string;
  price: number;
  customID: string;
  currencyID: string;
  configurable: boolean;
  isBundleProduct: boolean;
  isNonOptionsBundle?: boolean;
  barcode?: string;
  usp_blobs?: string[];
  usp_texts?: string[];
  eco_tax?: number;
}

/**
 * Interface used for all search modals that interface with the camera placeholder component
 * The modal(s) need to return a string barcode that can further be processed by the camera placeholder component.
 */
export interface IBarcodeSearchResult {
  barcode: string;
}

export enum StockReplenishmentType {
  default = 'default',
  priority = 'urgent'
}

export interface OrderLinesRequirements {
  [key: number]: EVA.Core.Requirement[];
}


/**
 * Typed data for employee discount
 *
 * {
 *   "Type": "RitualsEmployeeBarcode",
 *   "Data": {
 *       "UserID": 22943,
 *       "IsValid": true
 *   }
 * }
 */
export interface IParsedEmployeeDiscountData {
  UserID: number;
  IsValid: boolean;
}

export interface MatchLink {
  // io.some.app://product/32881?bar=x#foo
  path: string; // /32881
  queryString: string; // bar=x
  fragment: string; // foo
  host: string; // product
  url: string; // io.some.app://product/32881?bar=x
  scheme: string; // io.some.app
}

export type UnknownFunction = () => unknown;

// See https://github.com/microsoft/TypeScript/issues/24509
//
export type Mutable<T> = {
  -readonly[P in keyof T]: T[P]
};

export interface IParsedBoardingPass {
  Success: boolean;
  Format: string;
  NumberOfLegs: number;
  Name: string;
  Type: string;
  OperatingCarrierPNRCode: string;
  DepartingAirportCode: string;
  DestinationAirportCode: string;
  AirlineCompanyCode: string;
  FlightNumber: number;
  JulianDate: number;
  Cabin: string;
  Seat: number;
  CheckinSequence: number;
  Status: number;
  DataLength: number;
  Data: any;
}

/**
 * @see https://n6k.atlassian.net/browse/OPTR-16402
 */
export interface IParsedElevationBarcode {
  UserID: number;
  OrganizationUnitID: number;
  Functionality: string;
  Scope: number;
  Success: boolean;
  Message: string;
}
