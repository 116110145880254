import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { getProductDetail, getShoppingCart, store } from '@springtree/eva-sdk-redux';
import { find, get, isEmpty, isNil } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { IProductComplexCard } from 'src/app/components/product-complex-card/product-complex-card';
import { IRequirements } from 'src/app/components/product-details-requirements/product-details-requirements.component';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { EvaErrorGeneratorProvider } from 'src/app/services/eva-error-generator/eva-error-generator';
import { IOrderLineRequirementState } from 'src/app/services/eva-product-requirements/eva-product-requirements';
import { Logger } from '../../shared/decorators/logger';
import isNotNil from '../../shared/operators/isNotNil';

/**
 * this component will be responsible for performing the edit of an order lines requirements
 * it will be rendering a few components that are specailized in rendering each type of requirement
 * that component will communicate with us what kind of requirements have been filled in and what services to call
 */
@Logger('[eva-orderline-requirement-modal-component]')
@Component({
  selector: 'eva-orderline-requirement-modal',
  templateUrl: './orderline-requirement.modal.html'
})
export class EvaOrderlineRequirementModalComponent {

  public logger: Partial<Console>;

  public loading$: Observable<boolean> = getProductDetail.getState$().pipe(
    filter(state => state.isFetching === false),
    map(state => state.isFetching)
  );

  public orderLine$: Observable<EVA.Core.OrderLineDto> = getShoppingCart.getState$().pipe(
    filter(state => !state.isFetching),
    map(state => state.response),
    isNotNil(),
    map(response => response.ShoppingCart),
    isNotNil(),
    map(shoppingCart => {
      /** The matching order line id */
      const matchingOrderLineId = shoppingCart.Lines.find(line => {
        this.logger.log('order line id is', this.orderLineId);
        return line.ID === this.orderLineId;
      });

      return matchingOrderLineId;
    })
  );

  public isRequirementsSerial$ = getShoppingCart.getResponse$().pipe(
    isNotNil(),
    map(response => {
      const matchingOrderLine = response.AdditionalOrderData?.RequiredData?.OrderLines[this.orderLineId];

      const serialNumberRequirement = find( matchingOrderLine, matchingOrder => matchingOrder.Name === 'SerialNumber' );
      // If the product has 2 requirement, price & serial then display a "Serial number" title for the modal instead of custom requierments.
      //
      const isRequirementsSerial = serialNumberRequirement && matchingOrderLine.length === 2;

      return isRequirementsSerial;
    })
  );

  /** The product details */
  public productDetail$: Observable<IProductComplexCard> = this.orderLine$.pipe(
    map(line => {
      const product = line.Product;

      const productCard: IProductComplexCard = {
        title: product.PrimitiveName,
        customID: product.CustomID,
        status: get(product.Properties, 'product_status'),
        blobGUID: get(product.Properties, 'primary_image.blob'),
        id: product.ID
      };
      return productCard;
    }),
    tap(product => this.logger.log('complex card product data is...', product)),
  );

  public productRequirements$ = this.orderLine$.pipe(
    map(line => line.Product),
    map(product => get(product, 'Properties.product_requirements') || []),
    tap(requirements => {
      if (isEmpty(requirements)) {
        this.logger.error('Requirements are empty in the modal, something went wrong...');
      }
    })
  );

  /** This is not the same as order line requirements */
  orderLineProductRequirementsModel$: Observable<IRequirements> = getShoppingCart.getResponse$().pipe(
    isNotNil(),
    map( response => {
      const orderLineProductRequirements = (response.AdditionalOrderData?.ProductRequirements ?? []).find( productRequirement => productRequirement.OrderLineID === this.orderLineId );

      return orderLineProductRequirements?.RequirementModels ?? [];
    }),
  );

  public readonly productId: number = this.navParams.get('productId');

  public readonly orderLineId: number = this.navParams.get('orderLineId');

  public invalidRequirements$ = new BehaviorSubject<boolean>(false);

  private orderlineRequirementsState: IOrderLineRequirementState;

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    private toastCtrl: EvaToastController,
    private $evaErrorGenerator: EvaErrorGeneratorProvider) {

    const [productDetailaction] = getProductDetail.createFetchAction({ ID: this.productId });
    store.dispatch(productDetailaction);
  }

  public async updateRequirements() {
    if (!isNil(this.orderlineRequirementsState)) {
      // Looping through all the operations, and updating them one by one
      //
      Array.from(this.orderlineRequirementsState.operations.values())
        .filter(operation => !isNil(operation.action)) // <= remove later
        .forEach(operation => {
          const [action, fetchPromise] = operation.action;

          store.dispatch(action);

          const toast = this.toastCtrl.create();

          fetchPromise
            .then(() => toast.setMessage(operation.feedback.success))
            .catch(async error => {
              const evaFeedback = await this.$evaErrorGenerator.constructFailureFeedback(error, operation.feedback.success);

              toast.setData(evaFeedback);
            })
            .then(() => toast.present());
        });
      this.modalCtrl.dismiss();
    }
  }

  orderLineRequirementsChange(orderlineRequirementsState: IOrderLineRequirementState) {
    this.orderlineRequirementsState = orderlineRequirementsState;
    this.invalidRequirements$.next(!orderlineRequirementsState.valid);
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
