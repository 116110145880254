<ion-tabs (ionTabsDidChange)="onTabChange($event)" #tabs>

  <div slot="bottom" [hidden]="!showTabs">
    <ion-tab-bar>
      <ion-tab-button tab="basket" [disabled]="disabledSalesTabButton$ | async">
        <ng-container>
          <eva-icon [icon]="iconStream$ | async"></eva-icon>
          <ion-label>{{ labelStream$ | async }}</ion-label>
        </ng-container>
        <ion-badge *ngIf="cartTotal$ | async">
          {{cartTotal$ | async}}
        </ion-badge>
      </ion-tab-button>

      <ion-tab-button tab="tasks" [disabled]="disabledTasksTabButton$ | async">
        <ion-icon name="list-outline"></ion-icon>
        <ion-label>{{'tasks' | translate}}</ion-label>
        <ion-badge color="danger" *ngIf="!(disabledTasksTabButton$ | async)" [hidden]="(userTaskCountsBadge$ | async) === 0">{{userTaskCountsBadge$ | async}}</ion-badge>
      </ion-tab-button>

      <ion-tab-button tab="orders" [disabled]="disabledOrdersTabButton$ | async">
        <ion-icon name="copy-outline"></ion-icon>
        <ion-label>{{'orders' | translate}}</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
    <eva-overlay #overlay></eva-overlay>
    <eva-swipe-indicator [overlayEl]="overlay" [tabsEl]="ionTabBar?.nativeElement" *ngIf="ionTabBar?.nativeElement" [hidden]="!(showSwiper$ | async)" #swipeIndicator>
      <ion-button expand="full" class="ion-no-margin" (click)="navigate(swipeIndicator)" [disabled]="!(areOrderLinesValid$ | async) || (hasReachedMaximumOrderLines$ | async)">

        {{checkoutButtonText$ | async }}

        <ng-container *ngIf="checkoutButtonPricing$ | async as checkoutButtonPricing">
          <ng-container *ngIf="checkoutButtonPricing?.pricing?.price || checkoutButtonPricing?.pricing?.priceInTax">
            (<eva-localized-price
              [currencyString]="checkoutButtonPricing.currencyId"
              [pricing]="checkoutButtonPricing.pricing"
              [preferredPriceDisplayMode]="checkoutButtonPricing.preferredPriceDisplayMode">
            </eva-localized-price>)
          </ng-container>
        </ng-container>
      </ion-button>
      <div off-screen>
        <eva-cart-secondary-actions #cartSecondaryAction [swipeIndicator]="swipeIndicator"></eva-cart-secondary-actions>
      </div>
    </eva-swipe-indicator>
  </div>

</ion-tabs>
