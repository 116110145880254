<ng-container *ngFor="let page of pages">
  <ion-menu-toggle autoHide="true" [hidden]="page.hidden | async">
    <ion-item button (click)="openPage(page)" lines="none">
      <ion-label>
        {{ page.title | translate }}
      </ion-label>

      <eva-icon slot="end" [icon]="page.icon"></eva-icon>
    </ion-item>
  </ion-menu-toggle>
</ng-container>
