<ng-container *ngIf="productRequirements && form">
  <form [formGroup]="form">
    <ng-container *ngFor="let requirement of productRequirements; let index = index">
      <ng-container *ngIf="!requirement.is_array" [ngSwitch]="requirement.data_type">
        <ng-container *ngSwitchCase="'Integer'">
          <ion-item>
            <ion-label position="floating">{{requirement.name}}</ion-label>
            <ion-input
              type="text"
              [attr.autofocus]="index === 0 ? true : null"
              pattern="[0-9]+"
              [formControlName]="requirement.id"
              clearInput
            ></ion-input>
          </ion-item>
          <div class="validation-errors" *ngIf="form.controls[requirement.id]?.invalid && form.controls[requirement.id]?.dirty">
              <!-- TODO missing translations -->
              <p *ngIf="form.controls[requirement.id]?.errors.required">This is a required field</p>
              <p *ngIf="form.controls[requirement.id]?.errors.pattern">This is not a valid number</p>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'Decimal'">
          <ion-item>
            <ion-label position="floating">{{requirement.name}}</ion-label>
            <ion-input
              type="text"
              [attr.autofocus]=" index === 0 ? true : null"
              pattern="[0-9]+(.[0-9]{1,2})?"
              [formControlName]="requirement.id"
              clearInput
            ></ion-input>
          </ion-item>
          <div class="validation-errors" *ngIf="form.controls[requirement.id]?.invalid && form.controls[requirement.id]?.dirty">
              <!-- TODO missing translations -->
              <p *ngIf="form.controls[requirement.id]?.errors.required">This is a required field</p>
              <p *ngIf="form.controls[requirement.id]?.errors.pattern">This is not a valid decimal</p>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'Bool'">
          <ion-item>
            <ion-label>{{requirement.name}}</ion-label>
            <ion-checkbox color="secondary" [formControlName]="requirement.id"></ion-checkbox>
          </ion-item>
          <div class="validation-errors" *ngIf="form.controls[requirement.id]?.invalid && form.controls[requirement.id]?.dirty">
              <!-- TODO missing translations -->
              <p *ngIf="form.controls[requirement.id]?.errors.required">This is a required field</p>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'Text'">
          <ion-item>
            <ion-label position="stacked">{{requirement.name}} </ion-label>
            <ion-textarea [formControlName]="requirement.id"></ion-textarea>
          </ion-item>
          <div class="validation-errors" *ngIf="form.controls[requirement.id]?.invalid && form.controls[requirement.id]?.dirty">
            <!-- TODO missing translations -->
            <p *ngIf="form.controls[requirement.id]?.errors.required">This is a required field</p>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'Enum'">
          <ion-item>
            <ion-label position="floating">{{'action.select' | translate}} {{requirement.name}}</ion-label>
            <ion-select [formControlName]="requirement.id">
              <ion-select-option *ngFor="let item of requirement.data | keyvalue" [value]="item.key">
                {{ item.key }}: {{ item.value }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ion-item>
            <ion-label position="floating">{{requirement.name}}</ion-label>
            <ion-input
              type="text"
              [attr.autofocus]=" index === 0 ? true : null"
              [formControlName]="requirement.id"
              clearInput
            ></ion-input>
          </ion-item>
          <div class="validation-errors" *ngIf="form.controls[requirement.id]?.invalid && form.controls[requirement.id]?.dirty">
            <!-- TODO missing translations -->
            <p *ngIf="form.controls[requirement.id]?.errors.required">This is a required field</p>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="requirement.is_array">
        <!-- TODO missing translations -->
        <p>
          Requirements don't support array fields at this time (requirement '{{requirement.name}}')
        </p>
      </ng-container>
    </ng-container>
  </form>
</ng-container>
