import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, IonSlides } from '@ionic/angular';
import { INewScreenSlide } from '../contentful';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';

/**
 * This component is responsible for showing the user information about new features. You have to ensure this component isn't opened until $contenful is initialized
 */
@Logger('[whats-new-component]')
@Component({
  selector: 'whats-new',
  templateUrl: './whats-new.html',
  styleUrls: ['./whats-new.scss']
})
export class WhatsNewComponent implements OnInit, ILoggable {

  logger: Partial<Console>;

  /** The slides we will show to the user */
  @Input() slides: INewScreenSlide[];

  activeSlideIndex = 0;

  /** Emits whenever the user is done with seeing the new features. */
  @Output() done$ = new EventEmitter<void>();

  @ViewChild(IonSlides) ionSlides: IonSlides;

  constructor(
    private alertCtrl: AlertController,
    private $translate: TranslateService
  ) { }

  ngOnInit() {
  }

  async onSlideChange() {
    const index = await this.ionSlides.getActiveIndex();

    this.activeSlideIndex = index;
  }

  /**
   * This will be called whenever the user presses on the close button. Which will be shown at the last slide
   */
  close() {
    this.done$.next();
  }

  /**
   * Asks the user if they are sure about skipping the introduction to new features, if they are we will stop
   *  */
  skip() {
    this.alertCtrl.create({
      header: this.$translate.instant('are.you.sure'),
      message: this.$translate.instant('whats.new.skip.alert.message'),
      buttons: [{
        text: this.$translate.instant('yes.im.sure'),
        handler: () => {
          // In this case the user is done with this component
          //
          this.done$.next();
        }
      }, {
        text: this.$translate.instant('action.go.back')
      }]
    }).then(alert => alert.present());
  }
}
