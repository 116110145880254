import { Injectable } from '@angular/core';
import { getBundleProductDetail } from '@springtree/eva-sdk-redux';
import isNotNil from '../../shared/operators/isNotNil';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { get } from 'lodash';
import { IProductComplexCard } from 'src/app/components/product-complex-card/product-complex-card';

/**
 *
 * The `GetBundleProductDetailResponse` returns an array of products
 * This service will transform said array into a map structure to ease up look ups of products
 */
@Injectable()
export class GetBundleProductProductMapProvider {

  public productsMap$: Observable<Map<number, EVA.Core.GetBundleProductProduct>> = getBundleProductDetail.getResponse$().pipe(
    isNotNil(),
    map( getBundleProductDetailResponse => {

      const productMap = new Map();

      getBundleProductDetailResponse.Products.forEach(bundleProduct => productMap.set(bundleProduct.ID, bundleProduct));

      return productMap;
    })
  );

  constructor() { }

  /** Helper function that will create complex cards */
  constructComplexCard(bundleProduct: EVA.Core.GetBundleProductProduct): IProductComplexCard {
    const productComplexCardData: IProductComplexCard = {
      title: bundleProduct.Content?.display_value,
      blobGUID: get(bundleProduct.Content, 'primary_image.blob') as string,
      customID: bundleProduct.CustomID,
      id: bundleProduct.ID,
      status: get(bundleProduct.Content, 'product_status')
    };

    return productComplexCardData;
  }
}
