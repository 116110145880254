<ion-row *ngIf="totalQuantityToShip > 0 || isReturnOrderType">
  <ion-col size="2" class="light-text">
    {{ totalQuantityToShip }}
  </ion-col>
  <ion-col size="6" class="normal-text">
    <div>{{ productLine.description }}</div>
    <ion-badge *ngIf="!(productLine.currentQuantityToShip | isNil)">
      {{ 'order.type.shipline' | translate }} {{ productLine.currentQuantityToShip }}
    </ion-badge>
  </ion-col>
  <ion-col size="4" class="price normal-text">
    <div class="pricing">
      <ng-container *ngIf="!productLine.displayProperties.IsFree; else freeProduct">
        <eva-localized-price
          [currencyString]="orderData?.currency"
          [preferredPriceDisplayMode]="preferredPriceDisplayMode$ | async"
          [pricing]="productLine.pricing">
        </eva-localized-price>
      </ng-container>
      <ng-template #freeProduct>
        <ion-badge>{{ 'free' | translate }}</ion-badge>
      </ng-template>
    </div>
  </ion-col>
</ion-row>
