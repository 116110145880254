import { AnimationEvent } from '@angular/animations';
import { Component, Input, NgZone, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { dotAnimationTrigger, dotsAnimationTrigger } from './pin-animation';
import { isEmpty } from 'lodash';
import { take } from 'rxjs/operators';
import { fadeInOut } from 'src/app/shared/animations';

export type TPinAnimationState = 'success' | 'failure';

export interface IDotsFilledCallbackResult {
  animationState: TPinAnimationState;
  message?: string;
}

export type TDotsFilledInCallback = (values: number[]) => Promise<IDotsFilledCallbackResult>;

export interface IPinInputComponent {
  resetPin(): void;
}

/**
 * @see https://stackblitz.com/edit/md-motion-1?file=app%2Fapp.component.html
 */
@Component({
  selector: 'eva-pin-input',
  templateUrl: './pin-input.component.html',
  styleUrls: ['./pin-input.component.scss'],
  animations: [dotsAnimationTrigger, dotAnimationTrigger, fadeInOut]
})
export class PinInputComponent implements IPinInputComponent {

  @Input() PIN_LENGTH = 5;

  @Input() showNumbers: boolean = false;

  public keys = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 0
  ];

  public get defaultValue() {
    return Array(this.PIN_LENGTH).fill(false);
  }

  /** True will mean a full dot, false will mean an empty one */
  public dots: boolean[] = this.defaultValue;

  /** Number will mean a full value, false will mean an empty value */
  public numbers: any[] = this.defaultValue;

  public state$ = new BehaviorSubject<TPinAnimationState>(null);

  /** Error message we might show to the user */
  public error$ = new BehaviorSubject<string>(null);

  public loading = false;

  public values: number[] = [];

  public onLoadingAnimation = false;

  @Input()
  label: string;

  /** We will call this function whenever all dots are filled in */
  @Input()
  dotsFilledInCallback: TDotsFilledInCallback;

  @Output() onAnimationDone = new EventEmitter<void>();

  constructor(private zone: NgZone) {}

  async press(value: number) {
    this.error$.next(null);
    const emptyElementIndex = this.dots.findIndex(el => el === false);
    const hasEmptyDot = emptyElementIndex === -1;

    this.values.push(value);

    if (hasEmptyDot) {
      this.dots = this.defaultValue;
      this.numbers = this.defaultValue;
    } else {
      this.dots[emptyElementIndex] = !this.dots[emptyElementIndex];
      this.numbers[emptyElementIndex] = value;
    }

    if(this.showNumbers){
      this.callbackParentFunction(emptyElementIndex);
    }
  }

  /**
   * When we are handling numbers, we don't apply any effects on them
   * we directly call on parent component method
   * @see https://n6k.atlassian.net/browse/OPTR-20010
   */
  async callbackParentFunction(index){
    this.onLoadingAnimation = true;
    const event:any = { toState:true }
    const done = await this.doMainAnimation(event,index);

    if(done) {
      this.resetAnimationState(null);
    }
  }

  trackByFn(index: number) {
    return index;
  }

  async doMainAnimation(event: AnimationEvent, index: number) {
    const lastElement = index === this.dots.length - 1;
    const lastDotExpanded = (event.toState as any) === true;

    if (lastElement && lastDotExpanded && this.onLoadingAnimation) {
      this.onLoadingAnimation = !this.onLoadingAnimation;
      // Signal loading operation - during this time the keys buttons will be disabled
      //
      await this.signalLoading(true);

      const pinValues = this.values.slice(0, this.PIN_LENGTH);
      const callbackResult = await this.dotsFilledInCallback(pinValues);

      this.state$.next(callbackResult.animationState);
      this.error$.next(callbackResult.message);

      // Resetting the values
      //
      this.values = [];

      // Signal loading operation finished - the keys buttons will be enabled
      //
      this.loading = false;

      return true;
    }
  }

  resetAnimationState(event: AnimationEvent) {
    this.dots = this.defaultValue;

    this.numbers = this.defaultValue;

    this.onAnimationDone.emit();

    this.state$.next(null);
  }

  backpace() {
    if ( !isEmpty(this.values) ) {
      const valuesLength = this.values.length;

      this.values.pop();

      this.dots[valuesLength - 1] = false;

      this.numbers[valuesLength - 1] = false;
    }
  }

  resetPin() {
    this.resetAnimationState(null);
  }

  /**
   * Signal whether we are loading or not.
   *
   * Wait for zone ready to avoid expression changed after it was checked errors
   */
  private async signalLoading(loading: boolean): Promise<void> {
    // Waiting 1 tick so angular doesnt throw expression has changed after it was checked errors
    // ( previous value = false, now = true)
    //

    const waitForZonePromise = this.zone.onStable.pipe(take(1)).toPromise();

    await waitForZonePromise;

    this.loading = loading;
  }
}
