import { Component, EventEmitter, forwardRef, HostListener, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { autocompleteAddress, getAddressForZipCode, getAddressRequirements, getAutocompleteAddressByReference, getCurrentUser, getOrganizationUnitSummary, listCountries, store } from '@springtree/eva-sdk-redux';
import { get, isEmpty, isNil, noop, cloneDeep, isEqual } from 'lodash';
import { merge, of, Subject, Subscription } from 'rxjs';
import { auditTime, debounceTime, filter, first, map, startWith, take, takeUntil, pairwise, tap, distinctUntilChanged } from 'rxjs/operators';
import { EvaApplicationConfigProvider } from 'src/app/services/eva-application-config/eva-application-config';
import { FORM_TOUCH, IFormTouch } from '../../directives/form-touch/form-touch';
import { fadeInOut, slideUpDown } from '../../shared/animations';
import { EvaFeedback } from '../../shared/decorators/eva-feedback';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import isNotNil from '../../shared/operators/isNotNil';

/** Represents an eva ADDRESS */
import EvaAddress = EVA.Core.AddressDto;

// We use this interface until SDK will add Street type
interface EvaAddressNew extends EvaAddress {
  Street?: string
}

/** Validation messages mixin for type completion */
export type TValidationMessages<T> = {
  [P in keyof T]: Partial<{
    required: string;
    maxlength: string;
    minlength: string;
    requiredLength: string;
  }>;
};

// AddressRequirements visibility state fields
export const fieldState = {
  'VISIBLE':1,
  'NOT_VISIBLE':2,
  'VISIBLE_AND_REQUIRED':3
}

/** The fields in the EVA.Core.AddressDto this component will be supporting */
type TSupportedFields = 'Street' | 'ZipCode' | 'HouseNumber' | 'CountryID' | 'City' | 'Address1' | 'Address2' | 'State' | 'District' | 'Subdistrict';

/** The Object the adress component can emit */
export type TAddressForm = Pick<EvaAddressNew, TSupportedFields>;

type AddressSuggestion = EVA.Core.AddressSuggestion;

/**
 * This component will be responsible for editing address, its a dumb component and does not do anything with the store
 * Tip: it will only emit value changes if all fields are filled in to ensure validation rules can be applied by the consumer
 */
@Logger('[edit-address-component]')
@Component({
  selector:    'eva-edit-address',
  templateUrl: 'edit-address.component.html',
  styleUrls: ['edit-address.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EditAddressComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: forwardRef(() => EditAddressComponent)
  }, {
    provide: FORM_TOUCH,
    multi: false,
    useExisting: forwardRef(() => EditAddressComponent)
  }],
  animations: [fadeInOut, slideUpDown]
})
export class EditAddressComponent implements ControlValueAccessor, OnInit, ILoggable, Validator, IFormTouch, OnDestroy, OnChanges {

  public logger: Partial<Console>;

  /** Whether this control is required by the parent or not, we will use to determine whether we should check for address completeness */
  @Input() isRequired: boolean;

  // We need to know when we are dealing with a Billing Address
  @Input() isBillingAddress: boolean = false;

  @Input() shouldCheckValidation: boolean = false;

  @Output() onUpdateCountry = new EventEmitter<Event>();

  /** Value we will call whenever our form is touched */
  private onTouchedCallback: Function = noop;

  private subscriptionCountryID: Subscription;

  private stop$ = new Subject<void>();

  private formValueChangesSubscription: Subscription;

  /** The form we will use in the template */
  public form: FormGroup;

  /** Searching flag  */
  public addressLookupSearching = false;

  // We use this flag to show the form after we loaded the whole configuration
  // @see https://n6k.atlassian.net/browse/OPTR-19243?focusedCommentId=110451
  public addressRequirementsLoaded = false;

  // AddressRequirements visibility state fields
  private fieldState = fieldState;

  /** Validation messages we will use in our template */
  public validationMessages: TValidationMessages<TAddressForm> = {
    CountryID: {
      required: null
    },
    ZipCode: {
      required: null
    },
    HouseNumber: {
      required: null
    },
    City: {
      required: null
    },
    District: {
      required: null
    },
    Subdistrict: {
      required: null
    },
    Street:{
      required: null
    },
    Address1: {
      required: null
    },
    Address2: {
      required: null
    },
    State: {
      required: null,
      requiredLength: null,
      maxlength: null,
      minlength: null
    }
  };

  public addressLookup$ = autocompleteAddress.getResponse$().pipe(
    isNotNil(),
    map( value => get( value, 'Result', [] ) as EVA.Core.AddressSuggestion[] )
  );

  public addressRequirements$ = getAddressRequirements.getState$().pipe(
    filter(state => !state.isFetching && !isNil(state.response)),
    map( value => value?.response?.AddressRequirements )
  );

  public addressLookupControl: FormControl;

  /** Apply required validator dinamically */
  private houseNumberIsRequired: boolean = false;

  /** We need to check visibility dinamically */
  private houseNumberIsVisible: boolean = true;
  private houseNumberMaxLength: number = 20;

  /** Apply required validator dinamically */
  public isCityOptional = false;

  public isDistrictOptional = false;
  public isSubdistrictOptional = false;
  public districtMaxLength: number = 100;
  public subdistrictMaxLength: number = 100;


  /** Apply required validator dinamically */
  public isZipcodeOptional = false;

  public streetIsOptional = false;

  public address1isOptional = false;
  public address1MaxLength: number = 100;

  public address2isOptional = false;
  public address2MaxLength: number = 100;

  /** Check visibility dinamically */
  public isStateVisible = false;
  public isStateRequired = false;

  /** check state validators dinamically */
  public isStateLength2 = false;
  public isStateLength3 = false;
  public stateMaxLength = 0;
  public stateMinLength = 0;

  /**
   * We use this variable to force UI re-render ion-item style caused by a bug
   * affecting iOS devices which prevents filling input values with data.
   * We set it as `undefined` to apply the default style.
   * @see https://n6k.atlassian.net/browse/OPTR-16593
   */
  public renderItemStyle: string = undefined;

  constructor(
      private formBuilder: FormBuilder,
      private $translate: TranslateService,
      private $appConfig: EvaApplicationConfigProvider
  ) {
    this.form = this.formBuilder.group({
      CountryID:   [ null ],
      ZipCode:     [ null ],
      HouseNumber: [ null ],
      City:        [ null ],
      District:    [ null ],
      Subdistrict: [ null ],
      Street:      [ null ],
      Address1:    [ null ],
      Address2:    [ null ],
      State:       [ null ]
    });
  }

  @HostListener('blur', ['$event']) onBlur(event: FocusEvent) {
    this.onTouchedCallback();
  }

  async ngOnChanges() {
    if (this.shouldCheckValidation) {
      this.setFormControlValidators();
    } else {
      this.removeFormControlValidators();
    }

    if(this.form.get('CountryID')) {
      const values = cloneDeep(this.form.value);
      if(values) {
        delete values.CountryID;
        if(!Object.values(values).every(isEmpty)) {
          this.setFormControlValidators();
        }
      } else {
        this.removeFormControlValidators();
      }
    }
  }

  async ngOnInit() {

    this.fetchCountries();

    this.fetchOrganizationUnits();

    this.setupAddressCompletion();

    await this.registerCountryIDChanges();

    this.setDefaultSelectedCountry();

    this.setTranslations();

    this.addAddressObservers();
  }

  ngOnDestroy(): void {
    this.subscriptionCountryID?.unsubscribe();
    this.addressRequirementsLoaded = false;
  }

  /**
   * We subscribe to Country ID changes in order to update required fields
   * on form based on the current selected country settings.
   * @see https://n6k.atlassian.net/browse/OPTR-16405
   */
  async registerCountryIDChanges(){
    this.subscriptionCountryID = this.form.get('CountryID')?.valueChanges.pipe(distinctUntilChanged()).subscribe(async formControl => {
      // Every time the user selects another country we get new requirements
      // @see https://n6k.atlassian.net/browse/OPTR-18644
      const countryId = this.form.get('CountryID')?.value || formControl;
      if(countryId){
        await this.getAddressRequirementsData(countryId);
        await this.handleAddressLinesBySettings(countryId);
        this.addressRequirementsLoaded = true;
      }

      const values = cloneDeep(this.form.value);
      if(values) {
        delete values.CountryID;
        if(!Object.values(values).every(isEmpty)) {
          this.setFormControlValidators();
        }
      }

      const payload = {
        countryID: this.form.get('CountryID')?.value,
        isFromBillingAddress: this.isBillingAddress
      };
      this.onUpdateCountry.emit(payload as any);
    });
  }

  private async getAddressRequirementsData(countryID: string){
    if(countryID){
      const [action,promise] = getAddressRequirements.createFetchAction({
        CountryID: countryID
      });
      store.dispatch(action);
      await promise;
    }
  }

  /** We are putting this one in a method as sometimes HouseNumber will not be present and we want to return an "empty" stream */
  getHouseNumberValueChanges() {
    return this.form.get('HouseNumber')?.valueChanges ?? of(null);
  }

  addAddressObservers() {
    merge(
      this.form.get('Address1').valueChanges.pipe(distinctUntilChanged()),
      this.form.get('Address2')?.valueChanges.pipe(distinctUntilChanged()),
      this.form.get('ZipCode').valueChanges.pipe(distinctUntilChanged()),
      this.form.get('City').valueChanges.pipe(distinctUntilChanged()),
      this.form.get('District').valueChanges.pipe(distinctUntilChanged()),
      this.form.get('Subdistrict').valueChanges.pipe(distinctUntilChanged()),
      this.form.get('State').valueChanges.pipe(distinctUntilChanged()),
      this.form.get('Street').valueChanges.pipe(distinctUntilChanged()),
      this.getHouseNumberValueChanges(),
    )
    .pipe(
      takeUntil(this.stop$),
      isNotNil()
    )
    .subscribe(([prev, next]) => {
      if (!prev  || !isEqual(prev, next)) {
        const address1Control = this.form.get('Address1');
        const address1 = (address1Control && !address1Control.disabled && address1Control.value);

        const address2Control = this.form.get('Address2');
        const address2 = (address2Control && !address2Control.disabled && address2Control.value);

        const zipCodeControl = this.form.get('ZipCode');
        const zipCode = (zipCodeControl && !zipCodeControl.disabled && zipCodeControl.value);

        const cityControl = this.form.get('City');
        const city = (cityControl && !cityControl.disabled && cityControl.value);

        const districtControl = this.form.get('District');
        const district = (districtControl && !districtControl.disabled && districtControl.value);

        const subdistrictControl = this.form.get('Subdistrict');
        const subdistrict = (subdistrictControl && !subdistrictControl.disabled && subdistrictControl.value);

        const stateControl = this.form.get('State');
        const state = (stateControl && !stateControl.disabled && stateControl.value);

        const streetControl = this.form.get('Street');
        const street = (streetControl && !streetControl.disabled && streetControl.value);

        const houseNumberControl = this.form.get('HouseNumber');
        const houseNumber = (houseNumberControl && !houseNumberControl.disabled && houseNumberControl.value);

        if (zipCode || houseNumber || city || street || address1 || state || address2 || district || subdistrict) {
          this.setFormControlValidators();
        } else {
          this.removeFormControlValidators();
        }
      }
    });
  }

  fetchCountries() {
    listCountries.getResponse$().pipe(first(response => isNil(response)))
      .subscribe(() => {
        // Only fetch the country list once
        //
        const [action] = listCountries.createFetchAction({
          FetchAll: true
        });
        store.dispatch(action);
      });
  }

  /** Sets the validation message translations */
  async setTranslations() {
    /** Object with the field names in our form */
    const translations: TAddressForm = {
      ZipCode: await this.$translate.get('zip.code').toPromise(),
      CountryID: await this.$translate.get('country').toPromise(),
      City: await this.$translate.get('city').toPromise(),
      District: await this.$translate.get('district').toPromise(),
      Subdistrict: await this.$translate.get('subdistrict').toPromise(),
      HouseNumber: await this.$translate.get('house.number').toPromise(),
      Street: await this.$translate.get('street').toPromise(),
      Address1: await this.$translate.get('address.one').toPromise(),
      Address2: await this.$translate.get('address.two').toPromise(),
      State: await this.$translate.get('state').toPromise()
    };

    this.validationMessages.ZipCode.required = await this.getRequiredTranslation(translations.ZipCode);
    this.validationMessages.CountryID.required = await this.getRequiredTranslation(translations.CountryID);
    this.validationMessages.City.required = await this.getRequiredTranslation(translations.City);
    this.validationMessages.District.required = await this.getRequiredTranslation(translations.District);
    this.validationMessages.Subdistrict.required = await this.getRequiredTranslation(translations.Subdistrict);
    this.validationMessages.HouseNumber.required = await this.getRequiredTranslation(translations.HouseNumber);
    this.validationMessages.Street.required = await this.getRequiredTranslation(translations.Street);
    this.validationMessages.Address1.required = await this.getRequiredTranslation(translations.Address1);
    this.validationMessages.Address2.required = await this.getRequiredTranslation(translations.Address2);
    this.validationMessages.State.required = await this.getRequiredTranslation(translations.State);
  }

  setupDutchAddressCompletion() {
    merge(
      this.form.get('ZipCode').valueChanges,
      this.getHouseNumberValueChanges(),
    )
    .pipe(
      debounceTime(400),
      map(() => this.form.value as TAddressForm ),
      filter( formValue => !isEmpty(formValue.HouseNumber) && !isEmpty(formValue.ZipCode))
    )
    .subscribe(async formValue => {
      const [action, promise] = getAddressForZipCode.createFetchAction({
        ZipCode: formValue.ZipCode,
        HouseNumber: formValue.HouseNumber
      });
      store.dispatch(action);
      try {
        const response = await promise;
        if ( response.Result ) {
          this.form.get('CountryID').setValue(response.Result.CountryID);
          this.form.get('City').setValue(response.Result.City);
          this.form.get('Address1').setValue(response.Result.Address1);
        }
      } catch (e) {
        this.logger.error(e, ' Error getting address for zip code');
      }
    });
  }

  /**
   * Select an address from the address search
   */
  @EvaFeedback({
    i18nFailKey: 'address.autocomplete.by.reference.fail',
  })
  async selectAddress( address: AddressSuggestion ) {
    this.addressLookupControl.setValue(address.Description, { emitEvent: false });

    this.addressLookupSearching = false;
    const [ action, promise ] = getAutocompleteAddressByReference.createFetchAction( {
      Suggestion: address
    } );

    store.dispatch( action );

    try {
      const addressResult = await promise;
      const addressDetails: EVA.Core.AddressSuggestionDetails = addressResult.Result;

      if (addressDetails) {
        /**
         * The house number will be pre-pended to Address1 depending on:
         * if the target country requires separate house numbers or not.
         * if house number is required: Street -> Address1, HouseNumber -> HouseNumber
         * if house number is not required: HouseNumber + Street -> Address1
         * @see https://n6k.atlassian.net/browse/OPTR-16405
         */
        const streetNameValue = addressDetails.Street?.LongName || '';
        const houseNumberValue = addressDetails.HouseNumber?.LongName || '';
        const addHouseNumber = !(this.houseNumberIsRequired || this.houseNumberIsVisible);
        const houseNumberLabel = addHouseNumber ? houseNumberValue : '';
        const Address1 = `${houseNumberLabel} ${streetNameValue}`.trim();

        // We apply the 'outline' style temporarily in order
        // to force it to display the input values to user
        this.renderItemStyle = 'outline';

        this.form.patchValue({
          HouseNumber: get(addressDetails, 'HouseNumber.LongName'),
          City: get(addressDetails, 'City.LongName'),
          ZipCode: get(addressDetails, 'PostalCode.LongName'),
          CountryID: get(addressDetails, 'Country.ShortName'),
          State: get(addressDetails, 'Region.ShortName'),
          Street: get(addressDetails, 'Street.LongName'),
          District: get(addressDetails, 'District.LongName'),
          Address1
        });

        // We revert it to default style removing the 'outline'
        setTimeout(() => { this.renderItemStyle = undefined; },50)
      }
    } catch ( e ) {
      this.logger.error('error fetching autocomplete address by reference', e);
    }

    return promise;
  }

  writeValue( address: Partial<EvaAddress> ): void {
    if ( !isNil(address) ) {
      this.form.patchValue(address, { emitEvent: false });

      if(this.isBillingAddress){
        // We want to show the form validation in case the user already entered some data
        Object.keys(this.form.controls).forEach(controlName => {
          const formControl = this.form.controls[controlName];
          formControl.markAsTouched({ onlySelf: true });
          formControl.updateValueAndValidity({ emitEvent: true });
        });
      }

      if (!this.isRequired && !this.formValueChangesSubscription) {
        this.formValueChangesSubscription = this.form.valueChanges.pipe(
          startWith(address), pairwise()).subscribe(async ([oldFormValue, formValue]) => {
          if(!oldFormValue || !isEqual(oldFormValue, formValue)) {
            const values = cloneDeep(formValue);
            if(values) {
              delete values.CountryID;
              if(!Object.values(values).every(isEmpty)) {
                this.setFormControlValidators();
              } else {
                this.removeFormControlValidators();
              }
            }
          }
        });
      }
    }
  }

  registerOnChange(fn: (val: EvaAddress) => void): void {
    this.form.valueChanges
    .pipe(
      map( formValue => {
        /**
         * if the form only has empty values, we will just emit a null. This builds up on top of a previous change (OPTR-4979)
         * which would only emit when some of the fields were not empty. This solution is even better because we do want to emit a change
         * incase all fields are empty, said change would be an empty object. Otherwise the page would do a call with old data ( a form value that IS not empty when this component only contains empty values )
         *
         * This change was needed to avoid a bug that was more apparent in OPTR-5569, when a customerCreate call would be performed with stale data that represented an incomplete address
         *
         * @see https://eva2015.atlassian.net/browse/OPTR-4979
         * @see https://eva2015.atlassian.net/browse/OPTR-5569
         * */
        const isEmptyForm = this.isEmptyForm(formValue);

        return isEmptyForm ? null : formValue;
      })
    )
    .subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  /**
   * This method will be called whenever the parents ngControl value has changed
   */
  validate(): null|ValidationErrors {

    /**
     * If all the fields are empty, we want to treat this form differently.
     */
    const isEmptyForm = this.isEmptyForm(this.form.value);

    /**
     * We will only validate this control if its required by the parent & it hasn't been filled in
     * @see https://eva2015.atlassian.net/browse/OPTR-4724
     */

    if ( this.form.valid || (isEmptyForm && !this.isRequired) ) {
      return null;
    } else {
      // If this control is invalid and its not required by the parent, we will add this error to the control to ensure
      // an address is completely filled
      //
      return { invalidAddress: true };
    }
  }

  /**
   * Returns the translation of 'param is required'
   * param = 'email' output = 'Email is required'
   * */
  private getRequiredTranslation(param: string): Promise<string> {
    return this.$translate.get('is.required', { field: param }).toPromise();
  }

  /**
   * Returns the translation of `param is required to have acceptedLength characters`
   * param = 'state', acceptedLength = 2, output = `State is required to have 2 characters`
   */
  private getAcceptedLengthTranslation(param: string, acceptedLength: number): Promise<string> {
    return this.$translate.get('is.required.length', {
      field: param,
      acceptedLength: acceptedLength
    }).toPromise();
  }

  private getMaxLengthTranslation(param: string, acceptedLength: number): Promise<string> {
    return this.$translate.get('is.max.length.allowed', {
      field: param,
      acceptedLength: acceptedLength
    }).toPromise();
  }

  private async setupAddressCompletion() {
    const currentCountryId: string = await getCurrentUser.getResponse$().pipe(isNotNil(), map(userResponse => userResponse.User.CurrentCountryID), take(1)).toPromise();
    // Address completion using zipcode + house number
    // is only used for OU's in the Netherlands
    //
    if (currentCountryId === 'NL') {
      this.setupDutchAddressCompletion();
    } else {
      this.setupInternationalAddressCompletion();
    }
  }

  /**
   * We set a default country ID in case the user didn't selected yet
   * @see https://n6k.atlassian.net/browse/OPTR-19247
   */
  private setDefaultSelectedCountry(){
    getOrganizationUnitSummary.getResponse$()
    .pipe(
      first(response => !isNil(response)),
      takeUntil(this.stop$))
    .subscribe(async (data: EVA.Core.GetOrganizationUnitSummaryResponse) => {
      const formCountryId = this.form.get('CountryID')?.value;
      if(data?.Address?.CountryID && !formCountryId){
        this.form.get('CountryID').setValue(data.Address.CountryID);
      }
    });
  }

  private fetchOrganizationUnits() {
    getOrganizationUnitSummary.getResponse$()
      .pipe(
        first(response => isNil(response)),
        takeUntil(this.stop$))
      .subscribe(async () => {
        const user = await getCurrentUser.getResponse$().pipe(
          isNotNil(),
          map(response => response.User),
          take(1)
        ).toPromise();

        const [action] = getOrganizationUnitSummary.createFetchAction({
          OrganizationUnitID: user.CurrentOrganizationID
        });
        store.dispatch(action);
    });
  }


  private setupInternationalAddressCompletion() {
    this.addressLookupControl = this.formBuilder.control(null);

    this.addressLookupControl.valueChanges.pipe(
      auditTime(500),
      isNotNil(),
    ).subscribe(query => {
      this.addressLookupSearching = true;
      const [action] = autocompleteAddress.createFetchAction({
        Query: query
      });
      store.dispatch(action);
    });
  }

  /*
   * Check if settings applies to the current selected Country.
   * If no country was resolved from the auto-complete we will use
   * the current organization unit country based settings.
   *
   * @see https://n6k.atlassian.net/browse/OPTR-16405
   */
  private async checkAppliedSettings(countryIds: string[], selectedCountryID?: string): Promise<boolean>{
    const currentOrganizationCountryId: string = await getCurrentUser.getResponse$().pipe(
      first( userResponse => !isNil(userResponse)),
      map( userResponse => userResponse.User.CurrentCountryID )
    ).toPromise();

    return (countryIds.map(countryId => countryId.toLowerCase()).includes(selectedCountryID?.toLowerCase() || currentOrganizationCountryId.toLowerCase()))
  }

  /**
   * Determines which fields to show based on Settings
   * @see https://n6k.atlassian.net/browse/OPTR-18644
   */
  private async handleAddressLinesBySettings(selectedCountryID?: string) {
    let addressRequirements: {[key: string]:EVA.Core.AddressRequirementDto} = await this.addressRequirements$.pipe(first()).toPromise();

    // Zipcode by default is hiden and disabled
    this.form.get('ZipCode').disable();
    const zipcode = addressRequirements.ZipCode?.Visible;
    // We check if it's optinal or required and register it
    if(zipcode === this.fieldState.VISIBLE || zipcode === this.fieldState.VISIBLE_AND_REQUIRED){
      this.isZipcodeOptional = (zipcode === this.fieldState.VISIBLE);
      this.form.get('ZipCode').enable();
    }

    // City by default is hiden and disabled
    this.form.get('City').disable();
    const city = addressRequirements.City?.Visible;
    if(city === this.fieldState.VISIBLE || city === this.fieldState.VISIBLE_AND_REQUIRED){
      this.isCityOptional = (city === this.fieldState.VISIBLE);
      this.form.get('City').enable();
    }

    // District by default is hiden and disabled
    this.form.get('District').disable();
    const district = addressRequirements.District?.Visible;
    if(district === this.fieldState.VISIBLE || district === this.fieldState.VISIBLE_AND_REQUIRED){
      this.isDistrictOptional = (district === this.fieldState.VISIBLE);;
      if(addressRequirements.District['MaxLength']){
        this.districtMaxLength = addressRequirements.District['MaxLength'];
      }
      this.form.get('District').enable();
    }

    // Subdistrict by default is hiden and disabled
    this.form.get('Subdistrict').disable();
    const subdistrict = addressRequirements.Subdistrict?.Visible;
    if(subdistrict === this.fieldState.VISIBLE || subdistrict === this.fieldState.VISIBLE_AND_REQUIRED){
      this.isSubdistrictOptional = (subdistrict === this.fieldState.VISIBLE);
      if(addressRequirements.Subdistrict['MaxLength']){
        this.subdistrictMaxLength = addressRequirements.Subdistrict['MaxLength'];
      }
      this.form.get('Subdistrict').enable();
    }

    // State by default is disabled and hiden
    this.form.get('State').disable();
    this.isStateVisible = false;
    const state = addressRequirements.State?.Visible;
    if(state === this.fieldState.VISIBLE || state === this.fieldState.VISIBLE_AND_REQUIRED){
      this.isStateRequired = (state === this.fieldState.VISIBLE_AND_REQUIRED);
      this.isStateVisible = true;

      const stateLength2Countries = await this.$appConfig.addressStateLength2Countries$.pipe(take(1)).toPromise();
      this.isStateLength2 = await this.checkAppliedSettings(stateLength2Countries, selectedCountryID);
      const stateLength3Countries = await this.$appConfig.addressStateLength3Countries$.pipe(take(1)).toPromise();
      this.isStateLength3 = await this.checkAppliedSettings(stateLength3Countries, selectedCountryID);

      if(addressRequirements.State['MaxLength'] > 0){
        this.stateMaxLength = addressRequirements.State['MaxLength'];
      }
      if(addressRequirements.State['MinLength'] > 0){
        this.stateMinLength = addressRequirements.State['MinLength'];
      }

      this.form.get('State').enable();
    }

    // HouseNumber by default is disabled and hiden
    this.houseNumberIsVisible = false;
    this.houseNumberIsRequired = false;
    this.form.get('HouseNumber').disable();
    const houseNumber = addressRequirements.HouseNumber?.Visible;
    if(houseNumber === this.fieldState.VISIBLE || houseNumber === this.fieldState.VISIBLE_AND_REQUIRED){
      this.houseNumberIsVisible = true;
      this.houseNumberIsRequired = (houseNumber === this.fieldState.VISIBLE_AND_REQUIRED);
      if(addressRequirements.HouseNumber['MaxLength']){
        this.houseNumberMaxLength = addressRequirements.HouseNumber['MaxLength'];
      }
      this.form.get('HouseNumber').enable();
    }

    // Street by default is disabled and hiden
    this.form.get('Street').disable();
    const street = addressRequirements.Street?.Visible;
    if(street === this.fieldState.VISIBLE || street === this.fieldState.VISIBLE_AND_REQUIRED){
      this.streetIsOptional = (street === this.fieldState.VISIBLE);
      this.form.get('Street').enable();
    }

    // Address1 by default is disabled and hiden
    this.form.get('Address1').disable();
    const address1 = addressRequirements.Address1?.Visible;
    if(address1 === this.fieldState.VISIBLE || address1 === this.fieldState.VISIBLE_AND_REQUIRED){
      this.address1isOptional = (address1 === this.fieldState.VISIBLE);
      if(addressRequirements.Address1['MaxLength']){
        this.address1MaxLength = addressRequirements.Address1['MaxLength'];
      }
      this.form.get('Address1').enable();
    }

    // Address2 by default is disabled and hiden
    this.form.get('Address2').disable();
    const address2 = addressRequirements.Address2?.Visible;
    if(address2 === this.fieldState.VISIBLE || address2 === this.fieldState.VISIBLE_AND_REQUIRED){
      this.address2isOptional = (address2 === this.fieldState.VISIBLE);
      if(addressRequirements.Address1['MaxLength']){
        this.address2MaxLength = addressRequirements.Address2['MaxLength'];
      }
      this.form.get('Address2').enable();
    }

    // Apply all validators according to the latest requirements
    await this.setFormControlValidators();

    // We need to prevent showing validators when was fired by the method handleAddressLinesBySettings
    // Otherwise when the user enters this page, will see all required lines in red
    // @see https://n6k.atlassian.net/browse/OPTR-19360
    Object.keys(this.form.controls).forEach(controlName => {
      const formControl = this.form.controls[controlName];
      formControl.markAsUntouched();
    });
  }

  /**
   * We check all inputs except CountryID, because it's always required
   * and it's autofilled on initialization, even if the user hasn't touched the form yet
   * @see https://n6k.atlassian.net/browse/OPTR-19360
   */
  private isEmptyForm(formValue: Partial<TAddressForm>) {
    const fields = { ...formValue };
    delete fields.CountryID;
    return Object.values(fields).every(isEmpty);
  }

  /**
   * Required length validator
   * @see https://eva2015.atlassian.net/browse/OPTR-6407
   */
  private stateRequiredLengthValidator(isRequired: boolean, requiredLength: number): ValidatorFn {
    const validator: ValidatorFn = (control) => {
      const rawValue = control.value as string;

      //If it's required we show the alert
      if(isRequired && (isNil(rawValue) || rawValue?.length === 0)){
        return { required: true };
      }

      // As the state is optional
      // We mark it valid if it's empty or has the required length
      const isValid = isNil(rawValue) || rawValue.length === 0 || rawValue.length === requiredLength;
      return isValid ? null : { requiredLength: true };
    };
    return validator;
  }

  /**
   * This is the main method that controls the form validations
   * Because it's called from multiple places, we must have a
   * global variable in order to check if HouseNumer is required
   */
  private async setFormControlValidators() {
    const requirements: {[key: string]: ValidatorFn} = {
      CountryID: Validators.compose([Validators.required]),
      ZipCode: Validators.compose(this.isZipcodeOptional ? [Validators.maxLength(20)] : [Validators.required, Validators.maxLength(20)]),
      City: Validators.compose(this.isCityOptional ? [Validators.maxLength(100)] : [Validators.required, Validators.maxLength(100)]),
      Street: Validators.compose(this.streetIsOptional ? [] : [Validators.required]),
      Address1: Validators.compose(this.address1isOptional ? [Validators.maxLength(this.address1MaxLength)] : [Validators.required, Validators.maxLength(this.address1MaxLength)]),
      Address2: Validators.compose(this.address2isOptional ? [Validators.maxLength(this.address2MaxLength)] : [Validators.required, Validators.maxLength(this.address2MaxLength)]),
      HouseNumber: Validators.compose(this.houseNumberIsRequired ? [Validators.required,Validators.maxLength(this.houseNumberMaxLength)] : [Validators.maxLength(this.houseNumberMaxLength)]),
      State: Validators.compose(this.isStateRequired ? [Validators.required] : []),
      District: Validators.compose(this.isDistrictOptional ? [Validators.maxLength(this.districtMaxLength)] : [Validators.required, Validators.maxLength(this.districtMaxLength)]),
      Subdistrict: Validators.compose(this.isSubdistrictOptional ? [Validators.maxLength(this.subdistrictMaxLength)] : [Validators.required, Validators.maxLength(this.subdistrictMaxLength)]),
    };

    if(this.isStateVisible) {
      const stateTranslation = await this.$translate.get('state').toPromise();
      if(this.isStateLength3) {
        requirements.State = this.stateRequiredLengthValidator(this.isStateRequired,3);
        this.validationMessages.State.requiredLength = await this.getAcceptedLengthTranslation(stateTranslation, 3);
      }
      if(this.isStateLength2) {
        requirements.State = this.stateRequiredLengthValidator(this.isStateRequired,2);
        this.validationMessages.State.requiredLength = await this.getAcceptedLengthTranslation(stateTranslation, 2);
      }
      //@see https://n6k.atlassian.net/browse/OPTR-21561
      if(!requirements.State && (this.stateMaxLength || this.stateMinLength)) {
        requirements.State = Validators.compose(this.isStateRequired ?
          [Validators.required,Validators.maxLength(this.stateMaxLength),Validators.minLength(this.stateMinLength)] :
          [Validators.maxLength(this.stateMaxLength),Validators.minLength(this.stateMinLength)]);
        this.validationMessages.State.maxlength = await this.getMaxLengthTranslation(stateTranslation, this.stateMaxLength);
        this.validationMessages.State.minlength = await this.getAcceptedLengthTranslation(stateTranslation, this.stateMinLength);
      }
    }

    Object.keys(this.form.controls).forEach(controlName => {
      const formControl = this.form.controls[controlName];

      formControl.setValidators(requirements[controlName]);

      formControl.markAsTouched({ onlySelf: true });

      formControl.updateValueAndValidity({ emitEvent: false });
    });

    this.form.updateValueAndValidity();
  }

  private removeFormControlValidators() {
    Object.keys(this.form.controls).forEach(controlName => {
      const formControl = this.form.controls[controlName];

      formControl.clearValidators();

      formControl.markAsPristine();

      formControl.markAsUntouched();

      formControl.updateValueAndValidity({ emitEvent: false });
    });
  }
}
