import { ElementRef, Injectable, NgZone } from '@angular/core';
import { Capacitor, Plugins } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { Platform } from '@ionic/angular';
import { parseBarcode } from '@springtree/eva-sdk-redux';
import { isBoolean, isEmpty, isNil, isNumber } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, first, map, tap, withLatestFrom } from 'rxjs/operators';
import 'scandit-capacitor-datacapture-barcode';
import 'scandit-capacitor-datacapture-core';
import { ScanModeProvider } from 'src/app/services/scan-mode/scan-mode-provider';
import { EvaApplicationConfigProvider, ScanditScannerCamera } from '../../services/eva-application-config/eva-application-config';
import { EvaStartupProvider } from '../../services/eva-start-up/eva-start-up';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { Scandit } from 'scandit-capacitor-datacapture-barcode';
import { AppleScannerProvider, IBarcodeData } from './apple-scanner.provider';
import { AudioFeedbackProvider } from 'src/app/services/audio-feedback/audio-feedback';
import { Device } from '@capacitor/device';

const { ScanditCaptureCorePlugin } = Plugins;
// declare var Scandit;

/** Represents the types parse barcode could return */
export type TScanType =
  'BOARDINGPASS' |
  'DeviceHub' |
  'DISCOUNTCOUPON' |
  'INITCC' |
  'NONE' |
  'ORDER' |
  'PRODUCT_BARCODE' |
  'RITUALS:LOYALTY' |
  'RitualsEmployeeBarcode' |
  'SCANMODE' |
  'SESSION' |
  'SHIP_FROM_STORE_DELIVERY_CONFIRMATION' |
  'SOCIAL_SECURITY_NUMBER' |
  'STATION' |
  'STOCKRESOURCE' |
  'USER_CUSTOMID' |
  'USERTASK' |
  'VERIFYCUSTOMER' |
  'ElevationBarcode';

/** Represents the parsed barcode */
export interface IParsedBarcode extends EVA.Core.ParseBarcodeResponse {
  Type: TScanType;
}

/**
 * Interface describing the result of the barcode scan, might contain errors or actual data
 * Depending on the request to parse the data a ParseBarcode response from EVA is
 * included in the structure
 *
 * @export
 * @interface IBarcodeScannerResult
 */
export interface IBarcodeScannerResult {
  status: 'OK' | 'ERROR';
  source: string;
  /**
   * Whenever a component is listening to scan events, they register their ID via CameraPlaceHolder.register
   * This property will contain the ID of the component the scan event was for, to ensure only one page handles the scan event
   * This is because having multiple pages listening to this provider for scan events is possible
   */
  forComponentId?: string;
  rawData?: IRawBarcodeScannerData;
  parsedData?: IParsedBarcode;
  error?: any;
}

/**
 * Intermediate datastructure for raw scanned barcodes. Can be used for manual
 * entry as well.
 *
 * @export
 * @interface IBarcodeScannerData
 */
export interface IRawBarcodeScannerData {
  barcode: string;
  type: string;
}

export interface IPageNavigationEvent {
  name: string;
  component: any;
}

export enum InitializeRejectReason {
  missingLicense = 'missingLicense',
}

export enum SCANNER_TYPES {
  barcode = 'normal scanner',
  matrix = 'matrix scanner'
}

@Logger('[eva-barcode-scanner-provider]')
@Injectable({
  providedIn: 'root'
})
export class EvaBarcodeScannerProvider implements ILoggable {
  getiOSversion() {
    throw new Error('Method not implemented.');
  }

  public isCollapsed = false;

  public mustRecreateContext = false;

  public isIdle: boolean;

  logger: Partial<Console>;

  // Store the latest requestorReference, so when we scan,
  // we can use the last set requestorReference value
  public requestorReference: string;

  /** Property holding a reference to the Scandit picker */
  private barcodeCapture: any;

  public barcodeView: any;

  public session: any;

  public context: any;

  public camera: any;

  public overlay: any;

  public barcodeTracking;

  public trackingOverlay;

  public trackedBarcodes = {};

  public barcodesToSearch = [];

  public $trackedBarcodes = new BehaviorSubject<any[]>(null);

  public activeScannerType: SCANNER_TYPES = SCANNER_TYPES.barcode;

  /** we want to call the init method once, after the first startScan is called */
  public isInitialised = false;

  /**
   * Reference to the camera placeholder Element. This placeholder element is the
   * representation of the camera in the view hierarchie of the Angular application
   */
  private placeholder: ElementRef<HTMLElement>;

  /**
   * Reference to the HTML Body element, used for toggling a class indicating the
   * camera is active
   */
  private body: HTMLElement = document.body;

  /**
   * Pause the barcode scanner when the keyboard is shown
   */
  private pauseOnKeyboardShow: boolean;

  /**
   * Timer monitoring the use of the barcode scanner
   */
  private idleTimer: any;

  private readonly TIME_TO_IDLE = 30000;

  // Provides the current iOS (major) version
  //
  private $currentiOSVersion = new BehaviorSubject<number | null>(null);

  /**
   * This will be our registery with pages that have a scanner
   */
  private pagesWithScanner = new Set<string>();

  // First time the user enters the scan-mode page, we set rear
  // but the second time, we will remember the last position was choosen
  private lastCameraPositionSelected = 'rear';

  // This is the most updated camera position and it's a convenient way
  // to check current camera position without accesing Scandit
  private currentCameraPositionSelected = 'rear';

  // If we should check the last camera position or set the default one.
  public shouldRememberLastCameraPosition = false;

  // Public data stream contain of the scann results
  //
  public data$: Observable<IBarcodeScannerResult>;

  /** whether we should call parseBarcode or not */
  private parseBarcodeEnabled = true;

  /** Represents the height of the camera in viewport height units */
  public CAMERA_VIEWPORT_HEIGHT = 23;

  private dataSubject = new Subject<IBarcodeScannerResult>();

  /**
   * Default we pause the scanner on an idle event, but with this flag we can make the scanner collapse instead
   */
  private collapseScannerOnIdle = false;

  initializeRejectReason$ = new BehaviorSubject<InitializeRejectReason | null>(null);

  private _isForcePaused: boolean;

  // Time to wait until pause the camera
  private readonly DEBOUNCE_TIME = 500;

  /** Whether to scanner is active or not */
  private _isActive: boolean;

  public get isActive(): boolean {
    return this._isActive;
  }

  public set isActive(isActiveNewValue: boolean) {
    if (isActiveNewValue !== this._isActive) {
      this._isActive = isActiveNewValue;
      isActiveNewValue ? this.body.classList.add('barcodescanner-active') : this.body.classList.remove('barcodescanner-active');
    }
  }

  public get isForcePaused(): boolean {
    return this._isForcePaused;
  }

  public set isForcePaused(newIsForcePausedValue: boolean) {
    if (newIsForcePausedValue !== this._isForcePaused) {
      this._isForcePaused = newIsForcePausedValue;

      this._isForcePaused ? this.body.classList.add('barcodescanner-paused') : this.body.classList.remove('barcodescanner-paused');
    }
  }

  public get isPaused(): boolean | null {
    const isPaused = this.barcodeCapture?.isEnabled === false ?? null;

    return isPaused;
  };

  private isPairConnected$ = this.$scanModeProvider.remoteScannerStatus$.pipe(
    map(connectionInformation => {
      if (connectionInformation?.connected || connectionInformation?.peer?.UUID) {
        return true;
      }
      return false;
    })
  );

  /**
   * Check if we should display Apple or Scandit scanner
   *
   * @see https://n6k.atlassian.net/browse/OPTR-21869
   */
  public isAppleScannerActive$: Observable<boolean> = combineLatest([
    this.$evaApplicationConfig.scanditLicenseKey$,
    this.$evaApplicationConfig.appScannerProvider$,
    this.$appleScannerProvider.appleScannerIsSupported$,
    this.$currentiOSVersion
  ])
    .pipe(
      map(([licenseKey, appScannerProvider, isAppleScannerSupported, currentiOSVersion]) => {
        // Temporary we should introduce the following logic: If App:Scanner:Provider is set to Apple
        // we don’t care if the ScanditLicenseKey is empty or not, we will just switch to Apple
        if (appScannerProvider?.trim()?.toLowerCase() === 'apple' && isAppleScannerSupported) {
          return true;
        }

        // If we're on iOS 16, and higher, we need to know if Apple scanner is enabled
        // Maybe have to wait for it
        //
        if (!isNumber(currentiOSVersion)) {
          return null
        } else if (currentiOSVersion >= 16 && !isBoolean(isAppleScannerSupported)) {
          return null;
        }

        // For iPhones running ios >= 16, Apple scanner will be default,
        // unless App:Scandit:LicenseKey is configured with a value OR Apple Scanner is not supported/available
        //
        if (!isEmpty(licenseKey) || !isAppleScannerSupported) {
          return false;
        }

        return true;
      }),
      filter(value => isBoolean(value)),
      tap(isAppleScannerActive => {
        this.$scanModeProvider.isAppleScannerActive$.next(isAppleScannerActive);
      })
    );

  /**
   * Creates an instance of EvaBarcodeScannerProvider.
   */
  constructor(
    public platform: Platform,
    private ngZone: NgZone,
    private $evaApplicationConfig: EvaApplicationConfigProvider,
    private $evaStartup: EvaStartupProvider,
    private $scanModeProvider: ScanModeProvider,
    private $appleScannerProvider: AppleScannerProvider,
    private $audioFeedback: AudioFeedbackProvider
  ) {
    (window as any).barcodeScanner = this;

    this.data$ = this.dataSubject;

    this.setupKeyboardListeners();

    this.listenToConnectionStatus();

    this.checkiOSversion();
  }

  /** We should call this whenever overlays like alerts and modals are opened to ensure scanner is paused */
  onOverlayEvent({ open }: { open: boolean }) {
    this.logger.log(`[onOverlayEvent] open=${open}`);

    // Instead of only stopping/starting the scanner when an overlay is opened, we are pausing
    // instead and trying to avoid the scanner of being stopped due to an ios bug
    // see https://n6k.atlassian.net/browse/OPTR-6493
    //

    if (open && this.isActive && !this.isPaused) {
      // If the overlay is opened, and the scanner is active and not paused. We
      // will force pause the scanner. We will also make sure we stop the idle timer to make sure the
      // scanner isnt completely stopped.
      //
      this.pauseScan(true);
      this.stopIdleTimer();
    } else if (!open && this.isForcePaused) {
      // If the overlay is closed, we want to resume the scanner and reset the idle timer.
      //
      this.resumeScan(true);
      this.resetIdleTimer();
    }
  }

  /** we need to keep track of page navigation to determine whether to enable/disable the scanner */
  onPageNavigation(page: Object) {

    const pageNavigation: IPageNavigationEvent = {
      component: page,
      name: page.constructor.name
    };

    this.logger.log('[onPageNavigation] page object', pageNavigation);

    const hasCameraPlaceholder = 'cameraPlaceholder' in pageNavigation.component;

    const viewId = pageNavigation.name;

    // If any page has a camera placeholder, it means it has a scanner. This will work with pages of type D, because the cammera place holder
    // isn't rendered until a type of interaction
    // @see https://eva2015.atlassian.net/browse/OPTR-1287
    //
    if (hasCameraPlaceholder) {
      if (!this.pagesWithScanner.has(viewId)) {
        this.logger.log(`[onPageNavigation] '${viewId}' has a 'cameraPlaceholder'. Saving it in scanner registery`);
      }
      this.pagesWithScanner.add(viewId);
    }

    // Whether or not to collaps the scanner on Idle
    // TODO v5 test this
    //
    this.collapseScannerOnIdle = pageNavigation.component?.scannerConfig?.collapseScannerOnIdle ?? false;

    // This means the page was not found in the pages with scanner registery
    //
    if (!this.pagesWithScanner.has(viewId)) {
      this.logger.log(`[onPageNavigation] '${viewId}' doesnt have a 'cameraPlaceholder', making sure to stop the scanner.`);
      this.stopScan();
    }
  }

  public registerRequestorReference(componentId: string) {
    this.requestorReference = componentId;
  }

  /** Register a placeholder element used to sync the camera on top of */
  public registerPlaceholder(element: ElementRef) {
    this.placeholder = element;

    if (this.isCollapsed) {
      this.collapse();
    } else {
      this.expand();
    }

    // Default to true on each new registration since we consider that to be a new page / context
    //
    this.pauseOnKeyboardShow = true;
  }

  /** Ignores keyboard events which would cause the scanner to pause when a keyboard is present */
  public ignoreKeyboardEvents(): void {
    this.pauseOnKeyboardShow = false;
  }

  public async startScan(componentListeningId?: string) {
    const scannerAppleActive = await this.isAppleScannerActive$.pipe(first()).toPromise();

    if (!this.platform.is('capacitor')) {
      this.logger.warn('Only implemented for capacitor deployments');
      return;
    }

    if (!this.isInitialised) {
      if (scannerAppleActive) {
        await this.initialiseAppleScannerContext();
      } else {
        const scanditAppKey = await this.getScanditLicenseKey();
        // when the scandit key is not set, we dont want to initialise the scandit sdk
        if (!scanditAppKey) {
          this.setInitializeRejectionReason(InitializeRejectReason.missingLicense);
          return;
        }
        await this.initialiseGlobalContext(scanditAppKey);
      }
    }

    this.isActive = true;

    this.resetIdleTimer();

    if (!isNil(componentListeningId)) {
      this.requestorReference = componentListeningId;
    }

    const isResumingMatrixScanner = !scannerAppleActive && this.activeScannerType === SCANNER_TYPES.matrix && componentListeningId;

    if (this.activeScannerType === SCANNER_TYPES.barcode || isResumingMatrixScanner) {
      this.resumeScanning();
      this.disableCamera(false);
    }
  }

  private setInitializeRejectionReason(value: InitializeRejectReason) {
    this.initializeRejectReason$.next(value);
  }

  /**
   * Handle a scanned barcode, perform a parsebarcode on it and stream back
   * the result over the data$ stream.
   *
   * @param parse Parse the barcode
   * @param [componentListeningId] Optionally provide a reference to the requestor so it's possible to filter on this info when reading the result stream
   */
  public handleBarcode(barcodeData: IRawBarcodeScannerData, componentListeningId?: string, source?: string) {

    this.logger.log('HandleBarcode', barcodeData, 'requestorReference', componentListeningId, 'source', source);

    // If parseBarcodeEnabled is set to false we don't perform parse barcode. Otherwise we do.
    //
    if (!this.parseBarcodeEnabled) {

      this.publish({
        status: 'OK',
        source: 'scandit',
        forComponentId: componentListeningId,
        rawData: barcodeData
      });

    } else {

      const [fetchAction] = parseBarcode.createFetchAction({
        Barcode: barcodeData.barcode,
      });

      parseBarcode.fetchData(fetchAction).then((response) => {
        this.logger.log('ParseBarcode Response', response);
        if (response.Error) {
          this.logger.log('Exception during parsing barcode', response.Error);
          this.publish({
            status: 'ERROR',
            source: 'scandit',
            forComponentId: componentListeningId,
            rawData: barcodeData,
            error: response.Error
          });
        } else {
          this.publish({
            status: 'OK',
            source: 'scandit',
            forComponentId: componentListeningId,
            rawData: barcodeData,
            parsedData: (response as IParsedBarcode)
          });
        }
      })
        .catch((error) => {
          this.logger.log('Parse barcode error', error);

          this.publish({
            status: 'ERROR',
            source: 'scandit',
            forComponentId: componentListeningId,
            rawData: barcodeData,
            error
          });
        });
    }

  }

  /**
   * Temporary pause the scanning but keep the camera preview open
   */
  public pauseScan(force?: boolean) {
    if (isNil(this.barcodeCapture)) {
      this.logger.warn('Scandit picker is not setup yet. Unable to pause something that hasnot been setup or started.');

      return;
    }
    this.logger.info('Pausing scan');

    this.pauseScanning();

    if (force) {
      this.isForcePaused = true;
    }
  }

  /**
   * Resume a paused scan
   */
  public resumeScan(force?: boolean) {
    if (this.isActive) {
      if (this.isForcePaused && !force) {
        this.logger.info('Scanner is force paused, not resuming because resumeScan is called without force: true');
      } else {
        this.logger.info('Resuming after pause');

        if (this.isForcePaused) {
          this.isForcePaused = false;
        }

        if (this.barcodeCapture || this.barcodeTracking) {
          this.resumeScanning();
        } else {
          this.logger.warn('Scandit picker is not setup yet. Unable to resume something that hasnot been setup or started.');
        }
      }
    } else {
      this.logger.warn('You attempted to resume the scanner while it was inactive, please activate the scanner first');
    }
  }

  /**
   * Collapse the barcode scanner
   */
  public collapse() {
    this.pauseScan();

    this.hideCameraPlaceHolder(true);
    this.isCollapsed = true;

    this.stopScan();
  }

  /**
   * Expand the barcode scanner back to it's original dimensions
   */
  public expand() {
    this.isCollapsed = false;

    this.startScan();

    this.hideCameraPlaceHolder(false);
  }

  /**
   * Reset the idle timer and start over
   *
   */
  public resetIdleTimer() {
    // Idle timer is only in use on mobile
    //
    if (!this.platform.is('capacitor')) {
      return;
    }

    // this.logger.debug( 'Reset idle timer' );
    this.stopIdleTimer();

    // Already idle or not active?
    //
    if (this.isIdle || !this.isActive) {
      return;
    }

    this.idleTimer = setTimeout(() => {
      this.logger.debug('Idle timer firing!');
      this.stopScan();
      this.isIdle = true;

      if (this.collapseScannerOnIdle) {
        this.collapse();
      }

    }, this.TIME_TO_IDLE); // 30 seconds
  }

  /**
   * Stop the idle timer
   *
   */
  public stopIdleTimer() {
    if (this.idleTimer) {
      clearTimeout(this.idleTimer);
    }

    this.isIdle = false;
  }

  /** This will pause scanning & the camera */
  public stopScan() {
    this.logger.log('Stop scanning');
    this.isActive = false;

    this.pauseScanning();
    this.disableCamera();
  }

  /**
   * Convenience method for publishing data to the datasubject
   */
  private publish(data: IBarcodeScannerResult) {
    this.ngZone.run(() => {
      this.dataSubject.next(data);
    });
  }

  /**
   *  ----------- APPLE SCANNER -----------
   */
  private async initialiseAppleScannerContext() {
    Capacitor.isLoggingEnabled = false;

    if (this.isInitialised) {
      this.logger.log('initialise can only be called once, returning early');
      return;
    }

    this.logger.log('initialise called');
    this.isInitialised = true;

    await this.$appleScannerProvider.initializeScanner();

    const maxHeight = this.getAppleScannerFrame();

    this.$appleScannerProvider.setCameraViewport(maxHeight);

    // Register a listener to get informed whenever a new barcode got recognized
    this.$appleScannerProvider.onBarcodeScannEvents$.pipe(
      withLatestFrom(this.$appleScannerProvider.listeningBarcodes$),
      filter(([_, listeningBarcodes]) => Boolean(listeningBarcodes))
    )
      .subscribe(([barcodeData, _]: [IBarcodeData, boolean]) => {
        if (barcodeData?.barcode && barcodeData?.symbology) {

          this.resetIdleTimer();
          this.$audioFeedback.play('success');
          this.$appleScannerProvider.listeningBarcodes$.next(false);

          // When the user points the camera to the barcode we need to highlight the barcode on the live video
          // However if we call inmediately pauseScanning() method, iOS will hide too fast the highlighting feature
          // @see https://n6k.atlassian.net/browse/OPTR-21869?focusedCommentId=125526
          setTimeout(() => {
            this.pauseScanning();
            this.$appleScannerProvider.listeningBarcodes$.next(true);
          }, this.DEBOUNCE_TIME);

          const payload = {
            barcode: barcodeData.barcode,
            type: barcodeData.symbology
          };

          this.handleBarcode(payload, this.requestorReference);
        }
      });

    this.activeScannerType = SCANNER_TYPES.barcode;

    this.barcodeCapture = { isEnabled: true };

    // The camera will be expended, by default
    this.isCollapsed = false;
    this.hideCameraPlaceHolder(false);
    this.logger.log('Start scanning');
  }

  private async checkiOSversion() {
    const deviceInfo = await Device.getInfo();

    // The iOS version is not valid semver, so will simply split the version on the dot and take the first "value"
    // 12.4.1 => would give 12  OR  14.8 => would give 14
    const [iosMajorVersion] = deviceInfo?.osVersion?.split('.');
    const currentVersion: number = parseInt(iosMajorVersion, 10);

    // We only want this for iOS
    if (!deviceInfo || deviceInfo?.operatingSystem !== 'ios') {
      return null;
    } else {
      this.$currentiOSVersion.next(currentVersion);
    }
  }

  /**
   * Initialize and configure the Scandit scanner. This can be called multiple time, but effectively only does something the
   * first time it is run. Main reason for this is that the Scandit plugin doesn't allow re-set'ting the context.
   */
  private async initialiseGlobalContext(scanditAppKey: string): Promise<any> {
    Capacitor.isLoggingEnabled = false;

    if (this.isInitialised) {
      this.logger.log('initialise can only be called once, returning early');
      return;
    }

    this.logger.log('initialise called');
    this.isInitialised = true;

    const Scandit = await this.getScanditInstance();
    (window as any).Scandit = Scandit;

    // Create data capture context using your license key.
    this.context = Scandit.DataCaptureContext.forLicenseKey(scanditAppKey);

    this.barcodeView = Scandit.DataCaptureView.forContext(this.context);

    // We want the scanner container to occupy half screen size, by default
    const scanditFrame = await this.getScanditFrame();
    this.barcodeView.setFrame(scanditFrame, true);

    // Camera is off by default and must be turned on to start streaming frames to the data capture context
    this.camera = Scandit.Camera.default;
    this.camera.preferredResolution = Scandit.VideoResolution.FullHD;
    this.context.setFrameSource(this.camera);

    // Here we have to set both scanner types (standard and matrix scanner)
    await this.createBarcodeCaptureGlobalObjects();
    await this.createBarcodeTrackingGlobalObjects();

    // We enable the scanner type standard, by default
    this.startBarcodeCapture();

    this.barcodeView.show();

    // The camera will be expended, by default
    this.isCollapsed = false;
    this.hideCameraPlaceHolder(false);
    this.logger.log('Start scanning');
  }

  /**
   * Global BarcodeCapture (normal scanner) configuration
   * We must call this method only once
   */
  private async createBarcodeCaptureGlobalObjects() {
    const Scandit = await this.getScanditInstance();

    // The barcode capturing process is configured through barcode capture settings
    const settings = await this.createSettings();

    // Create new barcode capture mode with the settings from above.
    this.barcodeCapture = Scandit.BarcodeCapture.forContext(null, settings);

    // Register a listener to get informed whenever a new barcode got recognized.
    this.barcodeCapture.addListener({
      didScan: (_barcodeCapture: any, session: any) => {
        this.logger.log(session);
        this.resetIdleTimer();
        const code: { data: string; symbology: string } = session.newlyRecognizedBarcodes[0];
        this.pauseScanning();

        this.handleBarcode({
          barcode: code.data,
          type: code.symbology
        },
          this.requestorReference,
        );

        return true;
      }
    });

    this.overlay = Scandit.BarcodeCaptureOverlay.withBarcodeCapture(this.barcodeCapture);
    this.overlay.viewfinder = new Scandit.RectangularViewfinder();
  }

  /**
   * Global BarcodeTracking (matrix scanner) configuration
   * We must call this method only once
   */
  private async createBarcodeTrackingGlobalObjects() {
    const Scandit = await this.getScanditInstance();

    // The barcode capturing process is configured through barcode capture settings
    const settings = await this.createSettings(true);

    // Create new barcode tracking mode with the settings from above.
    this.barcodeTracking = Scandit.BarcodeTracking.forContext(null, settings);

    // Register a listener to get informed whenever a new barcode is tracked.
    // This fires whenever objects are updated and it's the right place to react to the tracking results.
    this.barcodeTracking.addListener({
      didUpdateSession: (barcodeTracking, session) => {
        // Check tracked barcodes to display them
        Object.values(session.trackedBarcodes).forEach((trackedBarcode: any) => {
          this.trackedBarcodes[trackedBarcode.identifier] = trackedBarcode.barcode.data;
        });
        // Remove tracked barcodes that are no longer tracked
        session.removedTrackedBarcodes.forEach(identifier => {
          this.trackedBarcodes[identifier] = null;
        });

        // TODO check performance here
        const scanned = Object.values(this.trackedBarcodes);
        this.$trackedBarcodes.next(scanned.filter(barcode => !isNil(barcode)));
      }
    });

    this.trackingOverlay = Scandit.BarcodeTrackingBasicOverlay.withBarcodeTracking(this.barcodeTracking);

    this.trackingOverlay.listener = {
      brushForTrackedBarcode: (overlay, trackedBarcode) => {
        // Return a custom Brush based on the tracked barcode.
        if (this.barcodesToSearch.length === 0 || this.barcodesToSearch.includes(trackedBarcode.barcode.data)) {
          const brush = new Scandit.Brush(
            Scandit.Color.fromRGBA(15, 197, 12, 0.47),
            Scandit.Color.fromRGBA(15, 197, 12, 0.90), 1
          );
          return brush;
        }
        // Brush for not found Barcode
        else {
          const brush = new Scandit.Brush(
            Scandit.Color.fromRGBA(255, 31, 12, 0.34),
            Scandit.Color.fromRGBA(255, 31, 12, 0.90), 1
          );
          return brush;
        }
      }
    };

  }

  /**
   * Call this method every time we need to show the Normal scanner
   * to scann a single barcode each time the user wants it
   */
  public async startBarcodeCapture() {

    this.activeScannerType = SCANNER_TYPES.barcode;

    const scannerAppleActive = await this.isAppleScannerActive$.pipe(first()).toPromise();

    if (scannerAppleActive) {
      return;
    }

    const Scandit = await this.getScanditInstance();
    this.camera.switchToDesiredState(Scandit.FrameSourceState.Off);
    this.barcodeCapture.isEnabled = false;
    const scanditFrame = await this.getScanditFrame();

    setTimeout(() => {
      this.context.removeAllModes();
      this.barcodeView.removeOverlay(this.trackingOverlay);

      this.context.addMode(this.barcodeCapture);

      // We want to occupy the barcode scanner size (half screen)
      this.barcodeView.setFrame(scanditFrame, true);

      // Set again RectangularViewfinder over the screen
      this.overlay.viewfinder = new Scandit.RectangularViewfinder();

      this.barcodeView.addOverlay(this.overlay);

      this.barcodeCapture.isEnabled = true;

      this.camera.switchToDesiredState(Scandit.FrameSourceState.On);
    }, 500);
  }

  /**
   * Call this method every time we need to show the Matrix Scanner
   * to scann a multiple barcodes each time the user wants that
   *
   * @see https://n6k.atlassian.net/browse/OPTR-17456
   */
  public async startBarcodeTracking() {
    const scannerAppleActive = await this.isAppleScannerActive$.pipe(first()).toPromise();

    if (scannerAppleActive) {
      return;
    }

    if (!this.platform.is('capacitor')) {
      this.logger.warn('Only implemented for capacitor deployments');
      return;
    }

    this.activeScannerType = SCANNER_TYPES.matrix;

    const Scandit = await this.getScanditInstance();
    this.camera.switchToDesiredState(Scandit.FrameSourceState.Off);
    this.barcodeTracking.isEnabled = false;

    setTimeout(() => {
      this.context.removeAllModes();
      this.barcodeView.removeOverlay(this.overlay);

      this.barcodeView.setFrame( // We need this types of scanner to occupy the full screen
        new Scandit.Rect(new Scandit.Point(0, 0), new Scandit.Size(window.screen.width, window.screen.height)), true
      );

      this.context.addMode(this.barcodeTracking);

      this.barcodeView.addOverlay(this.trackingOverlay);

      this.barcodeTracking.isEnabled = true;

      this.camera.switchToDesiredState(Scandit.FrameSourceState.On);
    }, 500);
  }

  async enableScanMode(enabled = true, enableParseBarcode = false) {
    const scannerAppleActive = await this.isAppleScannerActive$.pipe(first()).toPromise();
    this.logger.log('enableScanMode', enabled);

    if (scannerAppleActive) {
      // If scan mode is activated we want the scanner to occupy the entire screen. Otherwise we will reset it to its original size
      if (enabled) {
        // Set scanner viewport to full screen mode
        this.$appleScannerProvider.setCameraViewport(100);
        this.parseBarcodeEnabled = enableParseBarcode;
      } else {
        // Set scanner viewport to helf screen mode
        this.$appleScannerProvider.setCameraViewport(50);
        this.parseBarcodeEnabled = true;
      }
      return;
    }

    if (isNil(this.barcodeView)) {
      return;
    }

    const Scandit = await this.getScanditInstance();

    // If scan mode is activated we want the scanner to occupy the entire screen. Otherwise we will reset it to its original size
    if (enabled) {
      this.parseBarcodeEnabled = enableParseBarcode;
      this.barcodeView.setFrame(new Scandit.Rect(new Scandit.Point(0, 0), new Scandit.Size(window.screen.width, window.screen.height)), true);
    } else {
      this.parseBarcodeEnabled = true;
      const scanditFrame = await this.getScanditFrame();
      this.barcodeView.setFrame(scanditFrame, true);
    }
  }

  /**
   * Change camera position when we are on Paired mode with POS
   *
   * @see https://n6k.atlassian.net/browse/OPTR-18456
   */
  public async toggleCameraPosition() {
    let cameraPosition: string;

    if (this.camera.position === 'userFacing') {
      this.lastCameraPositionSelected = 'rear';
      this.currentCameraPositionSelected = 'rear';
      cameraPosition = 'worldFacing';
    } else if (this.camera.position === 'worldFacing') {
      this.lastCameraPositionSelected = 'front';
      this.currentCameraPositionSelected = 'front';
      cameraPosition = 'userFacing';
    }

    this.logger.log(`[toggleCameraPosition] cameraPosition=${cameraPosition}`);
    const scannerAppleActive = await this.isAppleScannerActive$.pipe(first()).toPromise();

    if (scannerAppleActive) {
      this.$appleScannerProvider.setCameraPosition(cameraPosition);
      this.logger.log(`[toggleCameraPosition] camera position set to ${cameraPosition}`);
    } else {
      const Scandit = await this.getScanditInstance();
      this.camera.switchToDesiredState(Scandit.FrameSourceState.Off);
      this.logger.log(`[toggleCameraPosition] switchToDesiredState state=Off`);
      this.camera.position = cameraPosition;
      this.logger.log(`[toggleCameraPosition] camera position set to ${cameraPosition}`);
      this.camera.switchToDesiredState(Scandit.FrameSourceState.On);
      this.logger.log(`[toggleCameraPosition] switchToDesiredState state=On`);
    }
  }

  private hideCameraPlaceHolder(hide: boolean) {
    if (this.placeholder?.nativeElement) {
      this.placeholder.nativeElement.style.display = hide ? 'none' : 'flex';
    }
  }

  // Only used by Apple scanner
  private getAppleScannerFrame(): number {
    const platformHeight = this.platform.height();
    const getPixelsForViewPortPoints = (totalPoints: number) => (platformHeight * totalPoints) / 100;
    const approximateStatusBarHeight = 40;
    const headerHeight = 52;

    // @see https://n6k.atlassian.net/browse/OPTR-21869?focusedCommentId=125524
    const iPhoneSESpace = 45;

    /** This will ensure the scanner scandit box ends up on vertically centered in the view */
    const additionalHeight = getPixelsForViewPortPoints(10);

    const spaceForCamera = getPixelsForViewPortPoints(this.CAMERA_VIEWPORT_HEIGHT) + approximateStatusBarHeight + headerHeight + additionalHeight + iPhoneSESpace;

    // We convert it to percentage
    const totalHeight = Math.floor(spaceForCamera * 0.1);

    return totalHeight;
  }

  // Only used by Scandit scanner
  private async getScanditFrame() {
    const platformHeight = this.platform.height();

    const getPixelsForViewPortPoints = (totalPoints: number) => (platformHeight * totalPoints) / 100;

    const approximateStatusBarHeight = 40;

    const headerHeight = 52;

    /** This will ensure the scanner scandit box ends up on vertically centered in the view */
    const additionalHeight = getPixelsForViewPortPoints(10);

    const spaceForCamera = getPixelsForViewPortPoints(this.CAMERA_VIEWPORT_HEIGHT) + approximateStatusBarHeight + headerHeight + additionalHeight;

    const Scandit = await this.getScanditInstance();

    return new Scandit.Rect(new Scandit.Point(0, 0), new Scandit.Size(window.screen.width, spaceForCamera));
  }

  // Only used by Scandit scanner
  private async createSettings(matrixScanner: boolean = false) {
    const Scandit = await this.getScanditInstance();

    let settings = new Scandit.BarcodeCaptureSettings();

    if (matrixScanner) {
      settings = Scandit.BarcodeTrackingSettings.forScenario(Scandit.BarcodeTrackingScenario.A);
    }

    settings.enableSymbologies([
      Scandit.Symbology.EAN13UPCA,
      Scandit.Symbology.EAN8,
      Scandit.Symbology.UPCE,
      Scandit.Symbology.Code39,
      Scandit.Symbology.InterleavedTwoOfFive,
      Scandit.Symbology.QR,
      Scandit.Symbology.DataMatrix,
      Scandit.Symbology.Code128,
    ]);

    // We need to support inverted QR codes
    //
    const qrSettings = settings.settingsForSymbology(Scandit.Symbology.QR);

    qrSettings.isColorInvertedEnabled = true;

    // See https://support.newblack.io/support/tickets/1966
    //
    const ean13upcaSettings = settings.settingsForSymbology(Scandit.Symbology.EAN13UPCA);
    ean13upcaSettings.setExtensionEnabled('remove_leading_upca_zero', true);

    // Some 1d barcode symbologies allow you to encode variable-length data. By default, the
    // Scandit BarcodeScanner SDK only scans barcodes in a certain length range. If your
    // application requires scanning of one of these symbologies, and the length is falling
    // outside the default range, you may need to adjust the "active symbol counts" for this
    // symbology. This is shown in the following few lines of code.
    //
    const code39SymbologySettings = settings.settingsForSymbology(Scandit.Symbology.Code39);

    code39SymbologySettings.activeSymbolCounts = [
      7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21
    ];

    return settings;
  }

  /**
   * will act as a substitute for v5 scanner pauses and cancels
   *
   * @see https://docs.scandit.com/5.0/phonegap/class_scandit_1_1_barcode_picker.html#a02d5fa6b14e221f3012a794b905be166 voic cancel()
   * @see https://docs.scandit.com/5.0/phonegap/class_scandit_1_1_barcode_picker.html#a02d5fa6b14e221f3012a794b905be166 voic pauseScanning()
   */
  private async pauseScanning() {
    const scannerAppleActive = await this.isAppleScannerActive$.pipe(first()).toPromise();
    if (scannerAppleActive) {
      this.$appleScannerProvider.pauseScanning();
      this.barcodeCapture = { isEnabled: false };
      return;
    }

    if (isNil(this.barcodeCapture)) {
      return;
    }
    this.logger.log('pauseScanning called.');
    this.barcodeCapture.isEnabled = false;
  }

  public async resumeScanning() {
    const scannerAppleActive = await this.isAppleScannerActive$.pipe(first()).toPromise();
    if (scannerAppleActive) {
      // Resume or start Apple scanner again
      this.$appleScannerProvider.resumeScanning();
      this.barcodeCapture = { isEnabled: true };
      return;
    }

    if (isNil(this.barcodeCapture) && isNil(this.barcodeTracking)) {
      return;
    }

    this.logger.log('resumeScanning called.');

    if (this.activeScannerType === SCANNER_TYPES.matrix) {
      this.barcodeTracking.isEnabled = true;
    } else {
      this.barcodeCapture.isEnabled = true;
    }
  }

  private async disableCamera(disabled: boolean = true) {
    const scannerAppleActive = await this.isAppleScannerActive$.pipe(first()).toPromise();
    if (scannerAppleActive) {
      if (disabled) {
        // Pause or stop Apple scanner
        this.$appleScannerProvider.pauseScanning();
        this.logger.log('[disableCamera] switchToDesiredState .Off success');
      } else {
        // Resume or start Apple scanner
        this.$appleScannerProvider.resumeScanning();
        this.logger.log('[disableCamera] switchToDesiredState .On success');
      }
      return;
    }

    if (isNil(this.camera)) {
      return;
    }

    this.logger.log('disableCamera called disabled=', disabled);
    const Scandit = await this.getScanditInstance();

    if (disabled) {
      this.camera.switchToDesiredState(Scandit.FrameSourceState.Off)
        .then(() => {
          this.logger.log('[disableCamera] switchToDesiredState .Off success');
        }).catch(error => {
          this.logger.error('[disableCamera] switchToDesiredState .Off error', error);
        });
    } else {
      this.camera.switchToDesiredState(Scandit.FrameSourceState.On)
        .then(() => {
          this.logger.log('[disableCamera] switchToDesiredState .On success');
        }).catch(error => {
          this.logger.error('[disableCamera] switchToDesiredState .On error', error);
        });
    }
  }

  // Only used by Scandit
  async getScanditInstance() {
    const Scandit = await ScanditCaptureCorePlugin.initializePlugins();
    return Scandit;
  }

  /**
   * Return the Scandit license key based on available configuration
   *
   * @returns (string) Scandit license key
   */
  private async getScanditLicenseKey(): Promise<string> {

    const evaConfig = this.$evaStartup.evaConfig;
    const scanditBuildTimeLicenseKey = evaConfig?.metadata?.['Scandit:AppKey'] as string;
    const scanditLicenseKey = await this.$evaApplicationConfig.scanditLicenseKey$.pipe(first()).toPromise();

    // If there is a eva-config.json with Scandit key use that
    // This is to be backwards compatible for customer specific builds
    //
    if (evaConfig.customerName === 'generic' && !isEmpty(scanditBuildTimeLicenseKey)) {
      this.logger.info('Found configured Scandit app key in eva-config.json');
      
      return scanditBuildTimeLicenseKey;
    } else {
      this.logger.info('Found configured Scandit license key');

      return scanditLicenseKey;
    }
  }

  private setupKeyboardListeners() {
    let scannerPausedDueToKeyboard = false;

    if (Capacitor.isNativePlatform()) {
      Keyboard.addListener('keyboardDidShow', () => {
        this.ngZone.run(() => {
          if (this.pauseOnKeyboardShow) {
            if (this.isActive) {
              scannerPausedDueToKeyboard = true;
              this.collapse();
            } else {
              scannerPausedDueToKeyboard = false;
            }
          }
        });
      });

      Keyboard.addListener('keyboardDidHide', () => {
        this.ngZone.run(() => {
          if (scannerPausedDueToKeyboard) {
            this.expand();
            scannerPausedDueToKeyboard = null;
          }
        });
      });
    }
  }

  /**
   * Listen when we are connected to the device POS, as a slave scanner
   * In that case we need to remember the last camera position and restore it
   *
   * @see https://n6k.atlassian.net/browse/OPTR-18456
   */
  private async listenToConnectionStatus() {
    const scannerAppleActive = await this.isAppleScannerActive$.pipe(first()).toPromise();

    this.isPairConnected$.pipe(distinctUntilChanged()).subscribe((isConnected => {
      // We only set the last position if we are connected and should restore,
      // otherwise we could be in another page where we don't need this feature
      if (isConnected && this.shouldRememberLastCameraPosition) {
        this.getSelectedCameraPosition(scannerAppleActive).then(lastPosition => {
          if (this.lastCameraPositionSelected !== this.currentCameraPositionSelected) {
            this.setCameraPosition(scannerAppleActive, lastPosition);
          }
        });
      }
    })
    );
  }

  private async setCameraPosition(scannerAppleActive: boolean, lastPosition: Promise<Scandit.CameraPosition> | string) {
    if (scannerAppleActive) {
      this.$appleScannerProvider.setCameraPosition(this.currentCameraPositionSelected);
    } else {
      const Scandit = await this.getScanditInstance();
      this.camera.switchToDesiredState(Scandit.FrameSourceState.Off);
      this.camera.position = lastPosition;
      this.camera.switchToDesiredState(Scandit.FrameSourceState.On);
    }
  }

  /**
   * Get the current camera selected position as a state for the Scandit operations
   */
  private async getSelectedCameraPosition(scannerAppleActive: boolean): Promise<string> {
    if (scannerAppleActive) {
      return this.lastCameraPositionSelected;
    } else {
      const cameraPosition = this.lastCameraPositionSelected;
      const Scandit = await this.getScanditInstance();
      switch (cameraPosition) {
        case ScanditScannerCamera.front:
          return Scandit.CameraPosition.UserFacing;
        default:
          return Scandit.CameraPosition.WorldFacing;
      }
    }
  }

  /**
   * This method will set the camera to the default state (REAR)
   * To be available on the rest of the app pages where we don't use the front camera
   */
  public async resetCameraPosition(): Promise<any> {
    const scannerAppleActive = await this.isAppleScannerActive$.pipe(first()).toPromise();

    if (scannerAppleActive && this.lastCameraPositionSelected === 'front') {
      this.currentCameraPositionSelected = 'rear';
      this.$appleScannerProvider.setCameraPosition(this.currentCameraPositionSelected);
      return false;
    }

    if (!scannerAppleActive) {
      const Scandit = await this.getScanditInstance();
      if (this.lastCameraPositionSelected === 'front') {
        this.currentCameraPositionSelected = 'rear';
        this.camera.switchToDesiredState(Scandit.FrameSourceState.Off);
        this.camera.position = Scandit.CameraPosition.WorldFacing;
        return this.camera.switchToDesiredState(Scandit.FrameSourceState.On);
      }
    }
  }
}
