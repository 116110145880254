<ion-app>
  <ion-menu side="start" menuId="first" contentId="main" swipe-gesture="false">
    <ion-header>
      <ion-toolbar>
        <ion-item *ngIf=" user$ | async; let user " lines="none">
          <ion-avatar slot="start">
            <img id="avatar" [src]=" user.GravatarHash | gravatar ">
          </ion-avatar>
          <ion-label>
            <h6 class="ion-no-margin">{{ user.FullName }}</h6>
            <small secondary-text>{{ user.EmailAddress }}</small>
          </ion-label>
        </ion-item>
      </ion-toolbar>
    </ion-header>
    <ion-content>

      <eva-menu></eva-menu>

    </ion-content>
    <ion-footer class="ion-no-padding">
      <ion-menu-toggle autoHide="true" (click)="logout()">
        <ion-item button>
          <ion-icon slot="start" name="exit-outline"></ion-icon>
          <ion-label>
            {{ 'logout' | translate }}
          </ion-label>
        </ion-item>
      </ion-menu-toggle>
    </ion-footer>
  </ion-menu>

  <ion-router-outlet id="main" (activate)="onRouterOutletActivate($event)"></ion-router-outlet>

</ion-app>
