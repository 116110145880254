<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title> {{ 'payment.error' | translate }} </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" #content>
  <ion-text color="danger">
    <span>
      {{ 'payment.connection.timeout.message' | translate }}
    </span>
  </ion-text>

  <div class="feedback-container">
    <div class="absolute-container">
      <ion-spinner *ngIf="loading$ | async" @fadeInOut></ion-spinner>
    </div>
    <eva-empty-state *ngIf="!(loading$ | async) && (feedback$ | async) as feedback" [class]="feedback.cssClass" @fadeInOut>
      <ion-icon [icon]="feedback.icon"></ion-icon>
      <header> {{ feedback.label }} </header>
    </eva-empty-state>
  </div>
</ion-content>
<ion-footer class="ion-no-border ion-no-shadow">
  <ion-button expand="block" color="primary" (click)="retry()" [disabled]="loading$ | async">
    {{ 'action.retry' | translate }}
  </ion-button>

</ion-footer>
