import { Component } from '@angular/core';
import { IRoute, RoutesProvider } from 'src/app/services/routes/routes';
import { Logger } from '../../shared/decorators/logger';

@Logger('[menu-component]')
@Component( {
  selector: 'eva-menu',
  templateUrl: './menu.html',
  styleUrls: ['./menu.scss']
} )
export class MenuComponent {

  public logger: Partial<Console>;

  public pages: IRoute[] = this.$routes.routes;

  constructor(
    private $routes: RoutesProvider
  ) { }

  openPage( page: IRoute ) {
    this.$routes.navigateToPage( page );
  }
}
