import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { IonTabBar, IonTabs, NavController, Platform } from '@ionic/angular';
import { getCurrentUser, getShoppingCart, OrderMode } from '@springtree/eva-sdk-redux';
import { isNil } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, first, map, take, takeUntil } from 'rxjs/operators';
import { CartSecondaryActionsComponent } from 'src/app/components/cart-secondary-actions/cart-secondary-actions.component';
import { SwipeIndicatorComponent } from 'src/app/components/swipe-indicator/swipe-indicator.component';
import { EvaBarcodeScannerProvider } from 'src/app/modules/scanner/eva-barcode-scanner';
import { EvaApplicationConfigProvider } from 'src/app/services/eva-application-config/eva-application-config';
import { OrderLifecycleService } from 'src/app/services/order-lifecycle/order-lifecycle.service';
import { RoutesProvider } from 'src/app/services/routes/routes';
import { SentinelProvider } from 'src/app/services/sentinel/sentinel';
import { UserTaskProvider } from 'src/app/services/user-task/user-task';
import { Logger } from 'src/app/shared/decorators/logger';
import isNotNil from 'src/app/shared/operators/isNotNil';
import someInArray from 'src/app/shared/operators/someInArray';
import { BasketService } from '../basket/basket.service';
import { ILineActionsTypes } from '../basket/components/basket-list/basket-list.component';

@Logger('[tabs-page]')
@Component({
  selector: 'eva-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})
export class TabsPage implements OnInit {

  public logger: Partial<Console>;

  public userTaskCountsBadge$: Observable<number> = this.$userTaskProvider.userTaskCountsBadge$

  isInterbranchOrder$ = getShoppingCart.getState$().pipe(
    map( state => state.checkoutStatus),
    map((checkoutState) => checkoutState.mode === OrderMode.interbranch)
  );

  isRtsOrder$ = getShoppingCart.getState$().pipe(
    map( state => state.checkoutStatus),
    map((checkoutState) => checkoutState.mode === OrderMode.returnToSupplier)
  );

  public interlogisticsOrder$: Observable<boolean> = getShoppingCart.getState$().pipe(
    map( state => state.checkoutStatus),
    map((checkoutState) => {
      const inInterlogisticsOrder = checkoutState.mode === OrderMode.returnToSupplier || checkoutState.mode === OrderMode.interbranch;

      return inInterlogisticsOrder;
    })
  );

  public iconStream$ = this.$orderLifecycleService.pageIconStream$;
  public labelStream$ = this.$orderLifecycleService.pageTitleStream$;

  public cartTotal$: Observable<number> = getShoppingCart.getResponse$().pipe(
    filter(response => !isNil(response)),
    map(response => response.ShoppingCart.TotalItems)
  );

  // This enable bottom swipe checkout button to be active
  public areOrderLinesValid$ = getShoppingCart.getResponse$()
  .pipe(
    map(response => {
      const allLines = response?.ShoppingCart?.Lines;
      const reqLines = response?.AdditionalOrderData?.RequiredData?.OrderLines;
      const allVerifiedLines = [];

      for (let lineId in reqLines) {
        const line = reqLines[lineId];
        const currentOrderLine = allLines?.find(line => line.ID === parseInt(lineId));
        const isDeliveryOrder = currentOrderLine?.LineActionType === ILineActionsTypes.DELIVERY || currentOrderLine?.LineActionType === ILineActionsTypes.ORDER_LINE;

        for (let information in line) {
          const informationLine = line[information];

          // For delivery orders we dont want to show the Serial number requirement
          // @see https://n6k.atlassian.net/browse/OPTR-24053?focusedCommentId=139061
          const skipSerialNumberValidation = informationLine.Name === 'SerialNumber' && isDeliveryOrder;
          if(skipSerialNumberValidation) {
            continue;
          }

          if (!informationLine.IsValid && informationLine.Name !== 'EmployeeVerification') {
            allVerifiedLines.push(false);
          }
        }
      }

      return allVerifiedLines.every(check => Boolean(check));
    })
  )

  // We had some issues (in the past) regarding these checks
  // @see https://n6k.atlassian.net/browse/OPTR-17026
  public areOrderRequirementsValid$: Observable<boolean> = combineLatest([
    this.$basket.validCartRequirements$,
    this.areOrderLinesValid$
  ])
  .pipe(
    map(([validCartRequirements, validOrderLines]) => {
      return validCartRequirements && validOrderLines
    })
  )

  /**
   * Determine how much lines we should show
   * @see https://n6k.atlassian.net/browse/OPTR-20058
   */
  public hasReachedMaximumOrderLines$: Observable<boolean> = combineLatest([
      getShoppingCart.getResponse$().pipe(isNotNil()),
      this.$evaApplicationConfig.orderMaxLines$.pipe(first())
  ])
  .pipe(
      map(([order,totalLinesAllowed]) => {
        const totalLinesOrder = order.ShoppingCart.TotalLineCount;
        const showWarning = totalLinesOrder > totalLinesAllowed;
        const isInterbranch = getShoppingCart.getState()?.checkoutStatus?.mode === OrderMode.interbranch;
        const isRma = getShoppingCart.getState()?.checkoutStatus?.mode === OrderMode.returnToSupplier;

        // We dont limit when order type is RTS or Interstore
        if(isInterbranch || isRma){
          return false;
        }

        return showWarning;
      })
  )

  public routes = this.$routes.routes;

  disableSales$ = getCurrentUser.getResponse$().pipe(
    isNotNil(),
    map( userResponse => userResponse.User.ScopedFunctionalities ),
    map(functionalities => !(this.functionalityName in functionalities)),
  );

  private readonly functionalityName = 'Sales';

  public disabledSalesTabButton$ = combineLatest([
    this.disableSales$,
    this.$userTaskProvider.fullStockCountEnabled$
  ]).pipe(
    someInArray(Boolean)
  );

  public disabledTasksTabButton$ = this.$sentinel.offlineChanges$.pipe(
    map(state => state !== 'online')
  );

  public disabledOrdersTabButton$ = combineLatest([
    this.disableSales$,
    this.$userTaskProvider.fullStockCountEnabled$
  ]).pipe(
    someInArray(Boolean)
  );

  @ViewChild(IonTabBar, {read: ElementRef}) ionTabBar: ElementRef<HTMLElement>;

  @ViewChild(IonTabs) ionTabs: IonTabs;

  @ViewChild(CartSecondaryActionsComponent) cartSecondaryAction: CartSecondaryActionsComponent;

  @ViewChild('swipeIndicator', {read: ElementRef}) swipeIndicator: ElementRef<HTMLElement>;

  private currentActiveTab$ = new BehaviorSubject<string|null>(null);

  private stop$ = new Subject();

  private fiscalConfigurationRequired$ = this.$evaApplicationConfig.fiscalConfigurationRequired$;

  public showSwiper$ = combineLatest([
    this.currentActiveTab$,
    this.$basket.totalItems$,
    this.fiscalConfigurationRequired$,
    getShoppingCart.getResponse$().pipe(isNotNil()),
  ]).pipe(
    map(([currentActiveTab, totalItems, fiscalConfigurationRequired, shoppingCart]) => {
      // We have a rare case where the user cancel all order lines on C&C task
      // @see https://n6k.atlassian.net/browse/OPTR-20567
      const needsRefund = shoppingCart.Amounts?.Open?.PendingInTax < 0;
      const cartHaveItems = totalItems > 0 && !fiscalConfigurationRequired;

      return currentActiveTab === 'basket' && (cartHaveItems || needsRefund);
    })
  );

  public checkoutButtonText$ = this.$basket.checkoutButtonText$;

  public checkoutButtonPricing$ = this.$basket.checkoutButtonPricing$;

  public showTabs: boolean = true;

  constructor(
    private $routes: RoutesProvider,
    private $userTaskProvider: UserTaskProvider,
    public navCtrl: NavController,
    private $sentinel: SentinelProvider,
    private route: ActivatedRoute,
    private $barcodeScanner: EvaBarcodeScannerProvider,
    private $basket: BasketService,
    private $orderLifecycleService: OrderLifecycleService,
    private $evaApplicationConfig: EvaApplicationConfigProvider,
    private ngZone: NgZone,
    private platform: Platform
  ) {
    this.logger.log(this.route);
  }

  ngOnInit() {
    this.setupKeyboardListener();
  }

  ngAfterViewInit() {
    if (this.$sentinel.isOnline) {
      // Because some parts of the app might be disabled based on the presence of FSC tasks, we want to get the tasks as soon as possible
      //
      this.$userTaskProvider.loadTasks();
    } else {
      this.$sentinel.disableRouting();
    }

    this.ionTabs.outlet.activateEvents.subscribe((pageInstance: Object) => {
      this.$barcodeScanner.onPageNavigation(pageInstance);

      this.logger.log(this.ionTabs.outlet.activatedRoute);

      // Due to a bug in ionic, tab children lifecycle hook events are not called, this causes a bug in the scanner
      // where the camera-placeholder is not re-registered properly. We will use the router activation events of the ion-tabs to trigger this manually
      //
      if (this.containsIonViewWillEnter(pageInstance)) {
        pageInstance['ionViewWillEnter'].call(pageInstance);
      }
      if (this.containsIonViewDidEnter(pageInstance)) {
        pageInstance['ionViewDidEnter'].call(pageInstance);
      }
    });

    this.showSwiper$.pipe(takeUntil(this.stop$)).subscribe((showSwiper) => {
      if (!showSwiper && !isNil(this.cartSecondaryAction)) {
        this.cartSecondaryAction.selectedSwipeUpOption = null;
      }
    })
  }

  onTabChange({tab}: {tab: string}) {
    this.currentActiveTab$.next(tab);
  }

  navigate(swipeIndicator: SwipeIndicatorComponent) {
    this.$basket.checkout(swipeIndicator);
  }

  containsIonViewWillEnter(pageInstance: Object): pageInstance is {ionViewWillEnter: Function} {
    return 'ionViewWillEnter' in pageInstance;
  }

  containsIonViewDidEnter(pageInstance: Object): pageInstance is {ionViewDidEnter: Function} {
    return 'ionViewDidEnter' in pageInstance;
  }

  /**
   * Hide and show the bottom page when the keyboard is active or not
   * Exception to this rule: when the swiper is active (must be able to fill the inputs)
   */
  private setupKeyboardListener() {

    if ( !Capacitor.isNativePlatform() ) {
      return;
    }

    Keyboard.addListener('keyboardDidShow', () => {
      this.ngZone.run(async () => {
        // should run when the swiper is shown
        const swiperIsActive = await this.showSwiper$.pipe(take(1)).toPromise();

        if (!swiperIsActive) {
          return;
        }
        // traverses the element and its parents to find the swipe-indicator

        const activeElementInSwiper = document.activeElement.closest(this.swipeIndicator.nativeElement.tagName);

        this.showTabs = !isNil(activeElementInSwiper);
      });
    });

    Keyboard.addListener('keyboardDidHide', () => {
      this.ngZone.run(() => {
        this.showTabs = true;
      });
    });
  }
}
