
/**
 * Represents the model fed to the tasks page to render following topologies:
 *
 * Urgent tasks
 * My Tasks
 * All tasks
 */
export interface ITaskView {
  myTasks: UserTask[];
  urgentTasks: UserTask[];
  allTasks: ITaskGroupView[];
}

/**
 * Supported tasks type
 */
export type TSupportedTasks = keyof typeof SupportedTasks;

/**
 * Task group type
 *
 * @see https://eva2015.atlassian.net/browse/OPTR-8045
 */
export type TSupportedTaskGroupType = keyof typeof SupportedTaskGroups;

/**
 * Create a union type out of the SupportedSubTasks enum
 */
export type TSupportedTaskSubtype = keyof typeof SupportedSubTasks;

/**
 * Create a union type out of the SupportedArtificialSubTasks enum
 */
export type TArtificialSupportedTaskSubGroup = keyof typeof SupportedArtificialSubGroups;

/**
 * All task subgroups
 *
 * They can be either supported subtypes or the tasks themselves (if an artificial supported task group doesn't have any subtypes/artificial subtypes)
 * or artificial supported task subtypes
 */
export type TTaskSubgroupType =
  TSupportedTaskSubtype | TArtificialSupportedTaskSubGroup | TSupportedTasks;

/**
 * Don't allow an empty array type
 */
export type TNonEmptyArray<T> = [T, ...T[]];

/**
 * Task groupped view
 *
 * We group all (artificial + supported sub tasks) sub tasks under the same task group
 */
export interface ITaskGroupView {
  group: TSupportedTaskGroupType;
  taskSubgroups: ITaskSubGroupView[];
  count: number;
}

/**
 * Represents a task subgroup collection of tasks
 */
export interface ITaskSubGroupView {
  subGroup: TTaskSubgroupType;
  tasks: UserTask[];
}

export enum SupportedTaskGroups {
  ReservationTasksGroup = 'ReservationTasksGroup',
  ReceiveGoodsTasksGroup = 'ReceiveGoodsTasksGroup',
  RunnerTasksGroup = 'RunnerTasksGroup',
  StockMovementTasksGroup = 'StockMovementTasksGroup',
  CycleCountTasksGroup = 'CycleCountTasksGroup',
  FullStockCountTasksGroup = 'FullStockCountTasksGroup',
  ShipFromStoreTasksGroup = 'ShipFromStoreTasksGroup',
  CompliancyTasksGroup = 'CompliancyTasksGroup',
  Print = 'Print',
  PrintPriceLabel = 'PrintPriceLabel',
  OperationalGroup = 'OperationalGroup',
}

/**
 * Tasks that are supported in the Companion App
 *
 * See more information regarding below comments on:
 *
 * @see https://eva2015.atlassian.net/browse/OPTR-8045
 */
export enum SupportedTasks {
  /**
   * `Reservation tasks` group
   * UI - wise this is the only task type in the `Reservation tasks` group
   */
  StockReservation = 'StockReservation',

  /**
   * `Receive goods tasks` group
   * UI - wise this is the only task type in the `Receive goods tasks` group
   */
  ReceiveShipment = 'ReceiveShipment',

  /**
   * `Operational tasks` group
   * This group will manage returned damaged products
   */
  SecondChanceProductRegistration = 'SecondChanceProductRegistration',

  /**
   * `Runner tasks` group
   * UI - wise this is the only task type in the `Runner tasks` group
   */
  StockReplenishment = 'StockReplenishment',

  /**
   * `Stock movement tasks` group
   *
   * UI - wise this contains the following Artificial (grouped by app not by backend) task subgroups:
   * StockMovementFollowUp
   * ReservationCleanup
   */
  ReservationCleanup = 'ReservationCleanup',

  /**
   * `Cycle counts tasks` group
   *
   * UI - wise this contains the following Artificial (grouped by app not by backend) task subgroups:
   * ZonedCycleCountPreCount
   * ZonedCycleCount (Confirmation task)
   */
  ZonedCycleCount = 'ZonedCycleCount',

  /**
   * `Cycle counts tasks` group
   *
   * UI - wise this contains the following Artificial (grouped by app not by backend) task subgroups:
   * ZonedCycleCountPreCount
   * ZonedCycleCount (Confirmation task)
   */
  ZonedCycleCountPreCount = 'ZonedCycleCountPreCount',

  /**
   * `Stock movement tasks` group
   *
   * UI - wise this contains the following Artificial (grouped by app not by backend) task subgroups:
   * StockMovementFollowUp
   * ReservationCleanup
   */
  StockMovementFollowUp = 'StockMovementFollowUp',

  /**
   * `Full stock count tasks` group
   *
   * UI - wise this is the only task type in the `Full stock count tasks` group
   * UI Exception - when This task group is not empty, we only show tasks belonging to this group.
   */
  FullStockCountLabel = 'FullStockCountLabel',

  /**
   * `Full stock count tasks` group
   *
   * UI - wise this is the only task type in the `Full stock count tasks` group
   * UI Exception - when This task group is not empty, we only show tasks belonging to this group.
   */
  FullStockCount = 'FullStockCount',

  /**
   * `Ship from store tasks` group
   *
   * UI - wise this contains the following task subgroups (Groupped by the backend):
   * Picking
   * Shipping
   * Delivery
   * Packing
   */
  ShipFromStore = 'ShipFromStore',

  CompositeUserTask = 'CompositeUserTask',

  Print = 'Print',

  /**
   * @see https://n6k.atlassian.net/browse/OPTR-11403
   */
  ReturnToSupplier = 'ReturnToSupplier',

  /**
   * @see https://n6k.atlassian.net/browse/OPTR-12447
   */
  PrintPriceLabel = 'PrintPriceLabel',
}

// TODO: Name to subgroups
export enum SupportedArtificialSubGroups {
  /**
   * Artificial subtask of `Stock movement tasks` group
   */
  StockMovementFollowUp = 'StockMovementFollowUp',

  /**
   * Artificial subtask of `Stock movement tasks` group
   */
  ReservationCleanup = 'ReservationCleanup',

  /**
   * Artificial subtask of `Cycle count tasks` group
   */
  ZonedCycleCountPreCount = 'ZonedCycleCountPreCount',

  /**
   * Artificial subtask of `Cycle count tasks` group
   */
  ZonedCycleCount = 'ZonedCycleCount',

  /**
   * Artificial subtask of `Full stock count tasks` group
   */
  FullStockCountLabel = 'FullStockCountLabel',
  /**
   * Artificial subtask of `Full stock count tasks` group
   */
  FullStockCount = 'FullStockCount',
}

export enum SupportedSubTasks {
  Ship = 'Ship',
  Pick = 'Pick',
  Pack = 'Pack',
  Deliver = 'Deliver',
  YearlyControlDocument = 'YearlyControlDocument',
  MonthlyControlDocument = 'MonthlyControlDocument',
  StationInitializationDocument = 'StationInitializationDocument',
  StationClosingDocument = 'StationClosingDocument'
}


export type UserTask = EVA.Core.AvailableUserTaskDto;

export interface IViewTask extends UserTask {
  /** Some tasks such as runner tasks (StockReplenishment tasks) need to be bundled */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AvailableTasks?: UserTask[];
}

export type ViewTasks = IViewTask[];

export type TTaskGroupsTranslationMapping = {
  [key in keyof typeof SupportedTaskGroups]: string;
};

export type TTaskSubGroupsTranslationMapping = {
  [key in keyof typeof SupportedSubTasks | SupportedArtificialSubGroups | SupportedTasks]: string;
};

