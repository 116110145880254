import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { OverlayBaseController } from '@ionic/angular/util/overlay';
import { TranslateService } from '@ngx-translate/core';
import { getCurrentUser, searchProducts, settings, store } from '@springtree/eva-sdk-redux';
import { isEmpty } from 'lodash';
import { isNil } from 'lodash-es';
import { filter, take } from 'rxjs/operators';
import { EvaBarcodeScannerProvider } from '../../modules/scanner/eva-barcode-scanner';
import { ILoggable, Logger } from '../../shared/decorators/logger';

/**
 * This class is responsible for checking the users session
 */
@Injectable()
@Logger('[eva-session-validator-provider]')
export class EvaSessionValidatorProvider implements ILoggable {
  public logger: Partial<Console>;

  private alert: HTMLIonAlertElement;

  private sessionWatchPaused = false;

  constructor(
    private $translate: TranslateService,
    private alertCtrl: AlertController,
    private $barcodeScanner: EvaBarcodeScannerProvider,
    private modalCtrl: ModalController,
    private navCtrl: NavController
  ) {
  }


  /** Will start watching for session expirations both in the store and once the application is resumed */
  public start() {

    getCurrentUser.expired$.pipe(
      filter(() => this.sessionWatchPaused === false )
    )
    .subscribe(() => {
      this.presentSessionNotice();
    });

    App.addListener('appStateChange', ({isActive}) => {
      if (isActive && !this.sessionWatchPaused) {
        // If the app is opened, we want to check the session
        //
        this.checkSession();
      }
    });
  }

  public pause() {
    this.sessionWatchPaused = true;
  }

  public resume() {
    this.sessionWatchPaused = false;
  }

  /** Checks the session of the current logged in user by fetching the current user */
  private checkSession() {

    // There is no point in checking the session if there is no user token to check in the first place.
    //
    if (isEmpty(settings.userToken)) {
      return;
    }

    const [action, fetchPromise] = getCurrentUser.createFetchAction();
    store.dispatch(action);

    fetchPromise.then(() => {
      const currentUserState = getCurrentUser.getState();
      if (!currentUserState.isLoggedIn) {
        this.presentSessionNotice();
      }
    })
    .catch((error) => {
      this.logger.warn(' Failed to fetch current user', error);
      this.presentSessionNotice();
    });
  }

  /** Tells the user about the fact their session expired */
  private async presentSessionNotice() {

    // If the session expired, we want to stop the interval
    //
    const translatedText = {
      title: await this.$translate.get('session.expired.title').pipe(take(1)).toPromise(),
      description: await this.$translate.get('session.expired.description').pipe(take(1)).toPromise(),
      ok: await this.$translate.get('action.ok').pipe(take(1)).toPromise()
    };

    if ( this.alert ) {
      // If there is already an alert, return early
      //
      return;
    }

    await this.closeOverlays();

    this.alert = await this.alertCtrl.create({
      header: translatedText.title,
      subHeader: translatedText.description,
      buttons: [translatedText.ok],
      backdropDismiss: false,
    });

    this.alert.present();

    this.$barcodeScanner.pauseScan();

    this.alert.onDidDismiss().then(() => {
      this.$barcodeScanner.resumeScan();

      this.navCtrl.navigateRoot('login');

      this.alert = null;
    });
  }

  private async closeOverlays() {
    this.dismissOverlays(this.modalCtrl);
    this.dismissOverlays(this.alertCtrl);
  }

  private async dismissOverlays(overlayType: OverlayBaseController<any, HTMLIonModalElement|HTMLIonAlertElement>) {
    let topOverlay = await overlayType.getTop();

    while (!isNil(topOverlay)) {
      try {
        this.logger.log('dismissing overlay');
        await topOverlay.dismiss();
        this.logger.log('overlay dismissed');

        topOverlay = await overlayType.getTop();
      } catch (error) {
        this.logger.error('error dismissing or getting the top overlay', error);
        // If an error occurs, we want to prevent an infinite loop from happening so we will set topOverlay to null

        topOverlay = null;
      }
    }
  }
}
