import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { filter } from "rxjs/operators";
import { ILoggable, Logger } from "src/app/shared/decorators/logger";
import { UserTaskProvider } from "../user-task/user-task";
import { EvaToastController } from "src/app/modules/eva-toast/eva-toast.controller";
import { TranslateService } from "@ngx-translate/core";

/**
 * This service will show the employee an alert if a full stock count is started while the user is elsewhere in the application.
 */
@Logger('[full-stock-count-alert-service]')
@Injectable({
  providedIn: 'root'
})
export class FullStockCountAlertService implements ILoggable {

  logger: Partial<Console>;

  constructor(private $userTask: UserTaskProvider,
    private route: ActivatedRoute,
    private toast: EvaToastController,
    private translate: TranslateService) {}

  initialise() {
    this.$userTask.fullStockCountEnabled$.pipe(
      filter(Boolean)
    ).subscribe( () => {
      this.toast.showMessage(this.translate.instant('full.stock.count.started'));
      this.logger.log(this.route);
    });
  }
}
