import { Component, HostBinding, Input, OnChanges, Optional } from '@angular/core';
import { take } from 'rxjs/operators';
import { fadeInOut } from '../../shared/animations';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import isNotNil from '../../shared/operators/isNotNil';
import { isNil } from 'lodash';
import { EvaApplicationConfigProvider } from '../../services/eva-application-config/eva-application-config';
import { ProductGalleryNavigatorDirective } from 'src/app/directives/product-gallery-navigator/product-gallery-navigator';

/**
 * This component will be responsible for rendering eva images with their guid
 * @example
 * ```html
 * <eva-img [guid]="product.guid"></eva-img>
 * <eva-img [guid]="product.guid" width=75 height=75></eva-img>
 * <eva-img [guid]="product.guid" width=75 height=75 extension='jpg'></eva-img>
 * <eva-img [productId]="product.id" width=75 height=75 extension='jpg'></eva-img>
 * ```
 */
@Logger('[eva-img-component]')
@Component({
  selector: 'eva-img',
  templateUrl: './img.html',
  styleUrls: ['./img.scss'],
  animations: [ fadeInOut ]
})
export class EvaImgComponent implements OnChanges, ILoggable {

  public logger: Partial<Console>;

  @Input() alt: string;

  @Input() guid: string;

  /**
   * Sometimes products dont have a media guid, a product id will be used as fallback in that case
   */
  @Input() productId: number;

  /**
   * The width of the fetched image
   */
   @HostBinding('style.--width.px')
  @Input() width = 50;

  /**
   * The height of the fetched image
   */
  @HostBinding('style.--height.px')
  @Input() height = 50;

  /**
   * The extension of the fetched image
   */
  @Input() extension = 'png';

  /**
   * Flag that specifies if the image is loaded or not - this affects the css applied in the template
   */
  public loaded = false;

  readonly imageNotFoundPath = './assets/imgs/not-found-300x300.png';

  /** The assetbase URL */
  public assetBaseUrl$ = this.$evaAppConfig.assetBaseUrl$;

  /** The url the native image element will be fetching */
  public url: string;

  public get showEnlargeIcon(): boolean {
    /** We will show the enlarge icon if the gallery opening directive is present and the URL is not the fallback image */
    const showEnlargeIcon = this.loaded && !isNil(this.productGalleryNavigatorDirective) && !(this.url === this.imageNotFoundPath);

    return showEnlargeIcon;
  }

  constructor(
    @Optional() private productGalleryNavigatorDirective: ProductGalleryNavigatorDirective,
    private $evaAppConfig: EvaApplicationConfigProvider,
  ) { }

  async ngOnChanges() {
    const assetBaseUrl = await this.assetBaseUrl$.pipe(
      isNotNil(),
      take(1)
    ).toPromise();

    this.setImageUrl(assetBaseUrl);
  }

  public setImageUrl(assetBaseUrl: string) {
    if ( this.guid ) {
      this.url = this.getProductGuidUrl(assetBaseUrl);
    } else if ( this.productId ) {
      this.url = this.getProductIdUrl(assetBaseUrl);
    } else {
      this.url = this.imageNotFoundPath;
    }
  }

  /**
   * Handler function executed when the `img` element loads the `url` link
   */
  public onImageLoaded() {
    this.loaded = true;
  }

  /**
   * Handler function executed when the `img` element load has failed
   */
  public async onImageError() {

    // Commented this out for now as it fills the console with logs.
    //
    // this.logger.error(`On error: image could not be loaded. Check the URL: ${this.url}`);

    // If the image path is the image not found path, it means
    // there is a network error. So we will just return early we will end up in a loop
    //
    if (this.url === this.imageNotFoundPath) {
      return;
    }
    // If both guid and product id were present, and the fetching has failed
    // we will fallback to the product id as fetching with guids is our primary way of getting product images
    //
    if (this.guid && this.productId) {
      const assetBaseUrl = await this.assetBaseUrl$.pipe(
        isNotNil(),
        take(1)
      ).toPromise();
      const productUrl = this.getProductIdUrl(assetBaseUrl);
      this.url = this.url === productUrl ? this.imageNotFoundPath : productUrl;
    } else {
      this.url = this.imageNotFoundPath;
    }

    // Mark the image as not loaded as we will trigger the loaded
    //

    this.loaded = false;
  }

  private getProductGuidUrl(assetBaseUrl: string): string {
    return `${assetBaseUrl}/image/${this.width}/${this.height}/${this.guid}.${this.extension}`;
  }

  private getProductIdUrl(assetBaseUrl: string): string {
    return `${assetBaseUrl}/productimage/${this.width}/${this.height}/${this.productId}.${this.extension}`;
  }
}
