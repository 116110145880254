import { Injectable } from '@angular/core';
import { IShoppingCartCheckoutStepStatus } from '@springtree/eva-sdk-redux';
import { isNil } from 'lodash';
import { IShowCreateAddressParameters, IShowEditAddressParameters } from './customer-settings-contracts';

/**
 *
 * THis provider contains the logic described in the task below
 * to show the billing/shipping address based on a number of parameters
 *
 * Currently there are different requirements to show addresses
 * based on whether we are creating / editing a customer, whether we are in checkout
 * and whether the order requires the addresses to be filled in (order requirements - checkout steps)
 *
 * When App:Customer:ShowAddresses is true, we display the shipping/billing addresses.
 *
 * When App:Customer:ShowAddresses is set to false:
 * - don't display the field in create customer from CRM
 * - display the field in edit only when it has values
 * - display the field in view only when it has values
 * - display the field in create customer in the checkout when the order requirements ask for it. (for example with a delivery order).
 *
 * @see https://eva2015.atlassian.net/browse/OPTR-2154
 */
@Injectable()
export class EvaCustomerSettingsProvider {
    /**
     * Decides if we show the address information in the create customer screens.
     */
    public showCreateAddress(parameters: IShowCreateAddressParameters) {
        if (parameters.inCheckout) {
            return this.showAddressInCheckout(parameters.appSettingsShowAddress, parameters.requirement);
        } else {
            return parameters.appSettingsShowAddress;
        }
    }

    /**
     * Decides if we show the address information in the edit customer screens.
     */
    public showEditAddress(parameters: IShowEditAddressParameters) {
        if (parameters.inCheckout) {
            return this.showAddressInCheckout(parameters.appSettingsShowAddress, parameters.requirement);
        } else {
            return this.showAddress(parameters.appSettingsShowAddress, parameters.address);
        }
    }

    /**
     *
     * Decides if we show the address in a view/edit screen
     * presented during the checkout flow.
     *
     * In this case, if the address has data, we show it
     * otherwise we respect the appSettings flag.
     *
     */
    private showAddressInCheckout( appSettingsShowAddress: boolean, requirement?: IShoppingCartCheckoutStepStatus) {
        if (!isNil(requirement) && requirement.required) {
            return true;
        }

        return appSettingsShowAddress;
    }

    /**
     * Decides if we show the address in edit/view screens (Not during the checkout flow)
     *
     * If the address has data, we show it even if the appSettings are set to false.
     */
    private showAddress( appSettingsShowAddress: boolean, address: EVA.Core.AddressDto ) {
        if (!isNil(address)) {
            return true;
        }

        return appSettingsShowAddress;
    }
}
