import { Component, Input } from '@angular/core';
import { Logger, ILoggable } from '../../shared/decorators/logger';
import isNotNil from '../../shared/operators/isNotNil';
import { first, map, take } from 'rxjs/operators';
import { getShoppingCart, detachCustomerFromOrder, store, getAvailablePaymentMethods } from '@springtree/eva-sdk-redux';
import { Observable } from 'rxjs';
import { EvaFeedback } from '../../shared/decorators/eva-feedback';
import { NavController } from '@ionic/angular';
import { TCustomerCompany } from '../user-details-card/user-details-card.component';

export type CustomerFieldsTranslationsMap = {
  [key in keyof EVA.Core.UserDto]: string;
};

/**
 * This component will render the customer information panel
 * @see https://projects.invisionapp.com/d/main#/console/13832734/289974458/preview
 */
@Logger('[checkout-customer-details-component]')
@Component({
  selector: 'eva-checkout-customer-details',
  templateUrl: 'checkout-customer-details.component.html',
  styleUrls: ['checkout-customer-details.component.scss'],
})
export class CheckoutCustomerDetailsComponent implements ILoggable {
  logger: Partial<Console>;

  @Input() isRefundOrder: boolean;

  /** The shoppingcart order */
  public cart$ = getShoppingCart.getResponse$().pipe(
    isNotNil(),
    map(response => response.ShoppingCart),
  );

  /** The customer currently attached to the cart */
  public cartCustomer$ = this.cart$.pipe(
    map(cart => cart.Customer)
  );

  public customerCompany$: Observable<TCustomerCompany> = this.cartCustomer$.pipe(
    isNotNil(),
    map( customerCompany => customerCompany.Company )
  );

  public cartCustomerID$ = this.cartCustomer$.pipe(
    isNotNil(),
    map(customer => customer.ID),
  );

  constructor(private navCtrl: NavController) {}

  /**
  * Start editing the currently attached customer
  */
  public async editCustomer() {
    const id = await this.cartCustomer$.pipe(
      isNotNil(),
      first(),
      map(customer => customer.ID)
    ).toPromise();

    this.navCtrl.navigateForward([`users/edit/${id}`], {
      queryParams: {
        inCheckout: true
      }
    })
      .catch(err => {
        this.logger.error('failed to navigate to customer-edit', err);
      });
  }

  /**
 * Send to customer search to attach a customer
 */
  public attachCustomer() {
    this.navCtrl.navigateForward(['/users/search'], { queryParams: { inCheckout: true } })
      .catch(err => {
        this.logger.error('failed to navigate to page-customer-search', err);
      });
  }

  public async removeCustomer() {
    const orderId: number = await getShoppingCart.getResponse$().pipe(
      isNotNil(),
      map( response => response.ShoppingCart.ID ),
      take(1)
    ).toPromise();

    await this.detachCustomer(orderId);

    const [paymentMethodAction] = getAvailablePaymentMethods.createFetchAction({
      OrderID: orderId,
      ReturnUnusablePaymentMethods: true
    });
    store.dispatch(paymentMethodAction);
  }

  @EvaFeedback({
    i18nFailKey: 'detach.customer.fail'
  })
  private detachCustomer(orderId: number) {
    const [action, detachCustomerFromOrderPromise] = detachCustomerFromOrder.createFetchAction({
      OrderID: orderId
    });

    store.dispatch(action);

    return detachCustomerFromOrderPromise;
  }

}
