import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { listAvailableUserTasks } from "@springtree/eva-sdk-redux";
import { isEmpty, isNil } from "lodash-es";
import { from, Observable, of } from "rxjs";
import { filter, first, map, mergeMap } from "rxjs/operators";
import { UserTaskProvider } from "src/app/services/user-task/user-task";
import { ILoggable, Logger } from "src/app/shared/decorators/logger";

export type TaskResolverData = EVA.Core.AvailableUserTaskDto;

@Logger('[task-resolver-service]')
@Injectable({
  providedIn: 'root'
})
export class TaskResolverService implements Resolve<any>, ILoggable {
  logger: Partial<Console>;

  constructor(private $userTaskProvider: UserTaskProvider) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TaskResolverData> {
    const taskId: number = parseInt(route.params['taskId'], 10);

    if ( isNil(taskId) ) {
      this.logger.error('Error resolving task when taskId parameter is undefined route=', route);
    }

    return listAvailableUserTasks.getState$().pipe(
      filter( state => !state.isFetching),
      map( state => state.response),
      mergeMap( response => {
        if ( isNil (response) ) {
          this.logger.log('No available tasks available fetching them');
          return from(this.$userTaskProvider.loadTasks());
        } else {
          this.logger.log('Available tasks available, returning last fetched result');
          return of(response);
        }
      }),
      map((tasksResponse: EVA.Core.ListAvailableUserTasksResponse) => {
        const matchingTask = this.findMatchingTask(taskId, tasksResponse.AvailableTasks);

        this.logger.log('matching task', matchingTask);

        return matchingTask;
      }),
      first()
    )
  }

  /** Will recursively loop through all tasks and their children to find a given taskId */
  private findMatchingTask(taskId: number, tasks: EVA.Core.AvailableUserTaskDto[]): EVA.Core.AvailableUserTaskDto {
    for(let potentiallyMatchingTask of tasks) {
      const matchingTask = potentiallyMatchingTask.ID === taskId;

      if ( matchingTask ) {
        return potentiallyMatchingTask;
      }

      if ( !isEmpty(potentiallyMatchingTask.Children) ) {
        this.logger.log(`non empty children of task with taskId=${potentiallyMatchingTask.ID} searching through children..`);

        const childTaskMatch = this.findMatchingTask(taskId, potentiallyMatchingTask.Children);

        if (childTaskMatch) {
          return childTaskMatch;
        }
      }
    }
  }
}
