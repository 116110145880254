import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { getProductDetail, store } from '@springtree/eva-sdk-redux';
import { Observable } from 'rxjs';
import isNotNil from '../../shared/operators/isNotNil';
import { map } from 'rxjs/operators';
import { get } from 'lodash';
import { IProductComplexCard } from 'src/app/components/product-complex-card/product-complex-card';

export type TLineActionTypes = 'shipline' | 'reserveline' | 'orderline';

export interface ILineActionTypeOrderElement {
  lineActionTypeValue: number;
  lineActionTypeLabel: TLineActionTypes;
}

/**
 * Responsible for showing the insufficient stock modal and allow users to take specific actions
 * @see https://eva2015.atlassian.net/browse/OPTR-5342
 */
@Component({
  selector: 'eva-insufficient-stock-modal',
  templateUrl: './insufficient-stock-modal.html'
})
export class InsufficientStockModalComponent implements OnInit {
  private readonly lineActionTypeElement: ILineActionTypeOrderElement = this.navParams.get('lineActionTypeElement');
  private readonly productId: number = this.navParams.get('productId');

  public errorMessage: string;

  public productIsLoading$ = getProductDetail.isFetching$();

    public productDetails$: Observable<IProductComplexCard> = getProductDetail.getResponse$().pipe(
    isNotNil(),
    map(response => response.Result),
    map(product => {
      const productDetails: IProductComplexCard = {
        title: get(product, 'primitive_name'),
        customID: get(product, 'custom_id'),
        status: get(product, 'product_status'),
        blobGUID: get(product.Properties, 'primary_image.blob'),
        id: get(product, 'product_id')
      };

      return productDetails;
    })
  );

  constructor(
    private viewCtrl: ModalController,
    private $translate: TranslateService,
    private navParams: NavParams
  ) { }

  async ngOnInit() {
    const lineActionTypeTranslation = this.$translate.instant(`order.type.${this.lineActionTypeElement.lineActionTypeLabel}`);

    this.errorMessage = this.$translate.instant('insufficient.stock.message', {
      lineActionTypeLabel: lineActionTypeTranslation
    });

    this.getProductData();
  }

  getProductData() {
    // We will be fetching this product which actually does not exist in the shipment
    //
    const [action] = getProductDetail.createFetchAction({ ID: this.productId });
    store.dispatch(action);
  }

  confirm() {
    this.viewCtrl.dismiss(this.lineActionTypeElement);
  }

  dismiss() {
    this.viewCtrl.dismiss(undefined);
  }
}
