import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ReplaySubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { IScannableConfigData, TScannerType } from '../../shared/decorators/scannable';
import { EvaBarcodeScannerProvider } from '../../modules/scanner/eva-barcode-scanner';
import { isNil } from 'lodash';

interface IScanPageConfigButton {
  label: string;
  action: () => any;
  /** The ionic color attribute value */
  color: string;
}

export interface IScanPageConfig {
  /** Title to show in the page */
  title: string;

  /** Text to show under the subtitle */
  subTitle?: string;

  /** Type of scan we are interested in */
  typeOfScan: TScannerType;

  /** The navigation controller, will be used as context */
  navCtrl: NavController;

  /** Buttons that will be rendered on the bottom of the page */
  footerButtons?: IScanPageConfigButton[];

  /** Whether we should show ths matrix scanner button or not */
  matrixScanningActive?: boolean;

  /** Matrix scanning target barcodes to search for */
  matrixScanningTarget?: any;
}

/**
 * This provider will open a scan page and will return a structure that is similar to the scan configuration
 * that is set by the scan decorator
 */
@Logger('[scan-page-provider]')
@Injectable({providedIn: 'root'})
export class ScanPageProvider implements ILoggable {
  public logger: Partial<Console>;

  /** The scan page will emit on this stream when ngAfterViewInit has been called */
  public onScanPageViewInit$ = new ReplaySubject<IScannableConfigData>(1);

  public onClose$ = new Subject<void>();

  private scannerPageConfig: IScanPageConfig;

  constructor(private $barcodeScanner: EvaBarcodeScannerProvider) { }

  /**
   * This will open a page and return a promise that resolves into an IScannableConfig
   * after the opened page ngAfterViewInit has been called as that is the moment the scan streams are available
   */
  async openPage(scanConfigParams: IScanPageConfig): Promise<IScannableConfigData> {
    this.scannerPageConfig = scanConfigParams;

    try {
      await scanConfigParams.navCtrl.navigateForward(['scan']);

      if ( !this.$barcodeScanner.isActive ) {
        this.$barcodeScanner.expand();
      }
    } catch (error) {
      this.logger.error(`Error navigating to 'scan' because of`, error);
    }

    // Waiting for an emission by the scan page that the view has been initialized
    //
    const scannableConfig = await this.onScanPageViewInit$.pipe(
      first( value => !isNil(value) )
    ).toPromise();

    return scannableConfig;
  }

  public dismissPage(navCtrl: NavController) {
    return navCtrl.pop();
  }

  getCurrentConfig() {
    return this.scannerPageConfig;
  }

  resetCurrentConfig() {
    this.scannerPageConfig = null;
  }

}
