import { Component, Input, OnChanges } from '@angular/core';
import { IEvaProductRequirement } from '@springtree/eva-sdk-redux';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';
import { IProductRequirementObject } from '../order-line-requirement/order-line-requirement.component';
import { IRequirements } from '../product-details-requirements/product-details-requirements.component';

@Logger('[order-line-requirement-view-mode-component]')
@Component({
  selector: 'eva-order-line-requirement-view-mode',
  templateUrl: './order-line-requirement-view-mode.component.html',
  styleUrls: ['./order-line-requirement-view-mode.component.scss']
})
export class OrderLineRequirementViewModeComponent implements ILoggable, OnChanges {
  public logger: Partial<Console>;

  @Input() orderLineId: number;

  @Input() productRequirements: IEvaProductRequirement[];

  @Input() productRequirementsModel: IRequirements;

  /** Will be a combination of the requirements and their values, and will be used to render them */
  productRequirementsObjectValues: {[key: number]: IProductRequirementObject} = {};

  ngOnChanges() {
    // Whenever the requirements or model change, we want to re-create the productRequirementsObjectValues object
    //
    if ( this.productRequirementsModel && this.productRequirements ) {
      const productRequirementsObjectValues = {};

      this.productRequirements.forEach(requirement => {
        // Find the matching requirement value in the model
        const value = this.productRequirementsModel.find(productRequirementModel => productRequirementModel.ID === requirement.id)?.Value;

        // Show product requirment name also
        // @see https://n6k.atlassian.net/browse/OPTR-21240
        const name = requirement.data && requirement.data[value];

        productRequirementsObjectValues[requirement.id] = {
          value, name,
          type: requirement.data_type
        }
      });

      this.productRequirementsObjectValues = productRequirementsObjectValues;
    }

  }
}
