import { Component, Input } from '@angular/core';
import { fadeInOut } from '../../shared/animations';
import { ILocalizedPrice } from '../localized-price/localized-price.component';
import { IProductVariations } from '../product-variations/product-variations';


/** Represents the data that is needed to render common product card */
export interface IViewProduct {
  description: string;
  customId: string;
  productId: number;
  blobGUID?: string;
  productVariations?: IProductVariations;

  primaryLocalizedPricing?: ILocalizedPrice;
  secondaryLocalizedPricing?: ILocalizedPrice;

  currencyId?: string;
  price?: number;
  preferredPriceDisplayMode?: EVA.Core.OrderPreferredPriceDisplayMode;
}

@Component({
  selector: 'eva-product-card-common',
  templateUrl: 'product-card-common.html',
  styleUrls: ['product-card-common.scss'],
  animations: [ fadeInOut ]
})
export class ProductCardCommonComponent {

  @Input() product: IViewProduct;
  @Input() showImage: boolean;
  @Input() showPrice: boolean = true;

  static createViewProductFromProductDto(productResponse: EVA.Core.GetProductDetailResponse) {
    const product = productResponse.Result;

    const productCard: IViewProduct = {
      description: product.display_value,
      customId: product.custom_id,
      blobGUID: product?.primary_image?.blob,
      productId: product.product_id,
      price: product.display_price || 0,
      currencyId: product.currency_id,
    }

    return productCard;
  }


}
