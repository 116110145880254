import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {getCLS, getFID, getLCP, getFCP, ReportHandler, Metric} from 'web-vitals';

export type MetricRating = 'good' | 'needs-improvement' | 'poor';

/**
 * this will be an object for tracking standards set out by the chrome user experience report
 * @see https://developers.google.com/web/tools/chrome-user-experience-report
 */
 interface MetricStandard {
  rating: MetricRating;
  to: number;
  from: number
}

interface IWebVitalsRatings {
  largestContentfulPaint: MetricRating;
  firstInputDelay: MetricRating;
  cumulativeLayoutShift: MetricRating;
  firstContentfulPaint: MetricRating;
}

@Injectable({
  providedIn: 'root'
})
export class WebVitalsService {
  getCLSPromise: Promise<Metric>|undefined;
  getFIDPromise: Promise<Metric>|undefined;
  getLCPPromise: Promise<Metric>|undefined;
  getFCPPromise: Promise<Metric>|undefined;

  private largestContentfulPaintStandard: MetricStandard[] = [{
    from: 0,
    to: 2500,
    rating: 'good'
  }, {
    from: 2500,
    to: 4000,
    rating: 'needs-improvement'
  }, {
    from: 4000,
    rating: 'poor',
    to: Infinity
  }];

  private firstInputDelayStandard: MetricStandard[] = [{
    from: 0,
    to: 100,
    rating: 'good'
  }, {
    from: 100,
    to: 300,
    rating: 'needs-improvement'
  }, {
    from: 300,
    rating: 'poor',
    to: Infinity
  }];

  private cumulativeLayoutShiftStandard: MetricStandard[] = [{
    from: 0,
    to: 0.1,
    rating: 'good'
  }, {
    from: 0.1,
    to: 0.25,
    rating: 'needs-improvement'
  }, {
    from: 0.25,
    rating: 'poor',
    to: Infinity
  }];

  private firstContentfulPaintStandard: MetricStandard[] = [{
    from: 0,
    to: 1000,
    rating: 'good'
  }, {
    from: 1000,
    to: 3000,
    rating: 'needs-improvement'
  }, {
    from: 3000,
    rating: 'poor',
    to: Infinity
  }];

  ratings$: BehaviorSubject<IWebVitalsRatings> = new BehaviorSubject({
    largestContentfulPaint: null,
    firstInputDelay: null,
    cumulativeLayoutShift: null,
    firstContentfulPaint: null
  });

  /**
   * All the web vitals will be collected and stored for later usage
   */
  initialise() {
    this.getLCPPromise = this.getReportPromise(getLCP).then( metric => {
      this.ratings$.next({
        ...this.ratings$.value,
        largestContentfulPaint: this.getRating(this.largestContentfulPaintStandard, metric.value, 'largestContentfulPaint')
      });

      return metric;
    });
    this.getFIDPromise = this.getReportPromise(getFID).then( metric => {
      this.ratings$.next({
        ...this.ratings$.value,
        firstInputDelay: this.getRating(this.firstInputDelayStandard, metric.value, 'firstInputDelay')
      });

      return metric;
    });
    this.getCLSPromise = this.getReportPromise(getCLS).then( metric => {
      this.ratings$.next({
        ...this.ratings$.value,
        cumulativeLayoutShift: this.getRating(this.cumulativeLayoutShiftStandard, metric.value, 'cumulativeLayoutShift')
      });
      return metric;
    });
    this.getFCPPromise = this.getReportPromise(getFCP).then(metric => {
      this.ratings$.next({
        ...this.ratings$.value,
        firstContentfulPaint: this.getRating(this.firstContentfulPaintStandard, metric.value, 'firstContentfulPaint')
      });
      return metric;
    });
  }

  private getReportPromise(metricFunction: (onReport: ReportHandler, reportAllChanges?: boolean | undefined) => void): Promise<Metric> {
    return new Promise(resolve => {
      metricFunction((metric) => {
        resolve(metric);
      });
    })
  }

  private getRating(standard: MetricStandard[], value: number, debugLabel: string): MetricRating {
    const matchingStandard = standard.find( standardEntry => value >= standardEntry.from && standardEntry.to >= value);

    if (!matchingStandard) {
      console.error(`[web-vitals:getRating] couldnt find matching standard for '${debugLabel}'`, standard, 'with value', value);
    }

    return matchingStandard?.rating ?? 'poor';
  }
}
