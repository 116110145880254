<ion-slides (ionSlideDidChange)="onSlideChange()">
  <ion-slide *ngFor="let slide of slides" class="ion-no-padding">
    <ion-card class="ion-no-margin">
      <img class="hero-image" [src]="slide.heroImage">

      <main>
        <h5 class="header"> <b>{{ slide.header }}</b> </h5>

        <div class="description" [innerHTML]="slide.description"></div>
      </main>
    </ion-card>
  </ion-slide>
</ion-slides>

<footer class="whats-new">

  <eva-dots [total]="slides.length" [selectedIndex]="activeSlideIndex"></eva-dots>

  <ion-button class="ion-no-margin" fill="clear" *ngIf="(slides.length - 1) === activeSlideIndex" (click)="close()">
    {{'action.close' | translate }}
  </ion-button>
  <ion-button class="ion-no-margin" fill="clear" *ngIf="!((slides.length - 1) === activeSlideIndex)" (click)="skip()">
    {{'action.skip' | translate }}
  </ion-button>
</footer>
