import { registerPlugin } from '@capacitor/core';

import type { AppleScannerPlugin } from './definitions';

const AppleScanner = registerPlugin<AppleScannerPlugin>('AppleScanner', {
  web: () => import('./web').then(m => new m.AppleScannerWeb()),
});

export * from './definitions';
export { AppleScanner };
