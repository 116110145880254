import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash';
import moment from 'moment';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/nl';
import 'moment/locale/pt';
import 'moment/locale/sv';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { DEFAULT_LOCALE } from '../../shared/modules/injection-tokens';
import { languageFromLocale } from '../../shared/utils/language';

interface ILocaleData {
  locale: moment.Locale;
  dateTimeFormat: string;
}

/**
 * `MomentJS provider` - Encalsulates all `MomentJS` time formatting and internationalisation utils
 *
 * @see https://eva2015.atlassian.net/browse/OPTR-3164
 */
@Injectable()
@Logger('[moment-provider]')
export class MomentProvider implements ILoggable {
  /**
   * The MomentJS date format key we use to get the `Long Date Format` value.
   */
  private momentDateFormatKey: moment.LongDateFormatKey = 'LL';

  /**
   * User language - used for `humanized(..)` functions
   */
  private userLanguage: string;

  /**
   * Default date time format
   */
  private localeData: BehaviorSubject<ILocaleData> = new BehaviorSubject<ILocaleData>(
    this.getLocaleData(this.defaultLocale, this.momentDateFormatKey)
  );

  /**
   * Logger implementation
   */
  public logger: Partial<Console>;

  /**
   * Date time format
   */
  public dateFormat$: Observable<string> = this.localeData.pipe(
    map(localeData => localeData.dateTimeFormat)
  );

  /**
   * Default format method from Moment
   */
  public format(format: string){
    return moment().format(format);
  }

  public ionDateTimeLocalisation: {
    dayNames: string[]
    dayShortNames: string[]
    monthNames: string[]
    monthShortNames: string[]
  };


  public constructor(
    @Inject(DEFAULT_LOCALE) private defaultLocale: string,
  ) {
    const defaultLanguage = languageFromLocale(defaultLocale);
    this.logger.log(`Initialise default language to ${defaultLanguage} 🌏`);
    this.setLocale(defaultLanguage);
  }

  /**
   * Set a locale/language in `MomentJS`
   */
  public setLocale(locale: string): void {
    moment.locale(locale);
    // Trigger calculation of locale observables
    //
    const newLocaleData = this.getLocaleData(locale, this.momentDateFormatKey);

    // Signal new locale
    //
    this.localeData.next(newLocaleData);
  }

  /**
   * Set the datepicker months/days translations and base language for `humanize(..)` functions
   *
   * The language we use to set the
   */
  public setUserLanguage(language: string): void {
    try {
      this.userLanguage = language;

      // attempt to get the moment locale
      const momentLocale = moment.localeData(language);

      if (!isNil(momentLocale)) {
        this.setApplicationWideTimeNames(momentLocale);
      }
    } catch { }
  }


  /**
   * Humanize a `Moment Duration` in a specific language
   */
  public humanize(duration: moment.Duration): string {
    // This is an immutable operation and it does not affect global Moment language
    //
    const humanizedResult = duration.locale(this.userLanguage).humanize();
    return humanizedResult;
  }

  /**
   * Sets the `internationalized Time names` depending on the `Locale`
   *
   * `Month names`
   * `Short month names`
   * `Week days names`
   * `Short week day names`
   *
   * The names are fetched from the `MomentJS locale`
   */
  private setApplicationWideTimeNames(locale: moment.Locale): void {
    const monthNames = locale.months();
    const monthShortNames = locale.monthsShort();
    const dayNames = locale.weekdays();
    const dayShortNames = locale.weekdaysShort();

    this.ionDateTimeLocalisation = {
      dayNames,
      dayShortNames,
      monthNames,
      monthShortNames
    }
  }

  /**
   * Get locale data information from a locale/language name
   */
  private getLocaleData(locale: string, momentDateFormatKey: moment.LongDateFormatKey): ILocaleData {
    const localeData = moment.localeData(locale);

    if (isNil(localeData)) {
      throw new Error(`Locale data not found for language: "${locale}"`);
    }

    const dateTimeLongFormatting = localeData.longDateFormat(momentDateFormatKey);
    const result: ILocaleData = {
      dateTimeFormat: dateTimeLongFormatting,
      locale: localeData
    };

    return result;
  }
}
