import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { SentinelProvider } from 'src/app/services/sentinel/sentinel';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';

/**
 * the user can only visit the discount page if they're not in sentinel mode and the discount service is available
 */
@Logger('[discount-guard-service]')
@Injectable({
  providedIn: 'root'
})
export class DiscountGuardService implements CanActivate, ILoggable {
  logger: Partial<Console>;

  constructor(private navCtrl: NavController, private $sentinel: SentinelProvider, private toastCtrl: EvaToastController, private $translate: TranslateService) {}

  async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    /** We will only open this page if not in offline mode or the `ListManualDiscounts` is available in offline mode */
    const shouldOpen =
      !this.$sentinel.isOffline ||
      this.$sentinel.isServiceAvailableOffline("ListManualDiscounts");

    this.logger.log(
      "canActivate checking if not in offline mode, isNotOffline =",
      shouldOpen
    );

    if (!shouldOpen) {
      this.toastCtrl.showMessage(
        this.$translate.instant("sentinel.offline.page.unavailable")
      );
      try {
        this.logger.log(`sentinel is in offline mode, navigating away`);
        await this.navCtrl.navigateRoot(['tabs/basket']);
      } catch (error) {
        this.logger.error('error navigating to basket page', error);
      }
    }

    return shouldOpen;
  }
}
