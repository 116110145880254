import { Injectable } from '@angular/core';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { EditPinModalComponent, ICreatePinResult } from 'src/app/modals/edit-pin/edit-pin-modal';

type TPinModalMode = 'create' | 'update';

export interface ICanShowUpdatePinInput {
  userScopedFunctionalities: { [ key: string ]: EVA.Core.FunctionalityScope };
  pinEnabled: boolean;
  userHasPin: boolean;
}

@Logger('[pin-provider]')
@Injectable()
export class PinProvider implements ILoggable {

  /**
   * Logger instance
   */
  public logger: Partial<Console>;

  constructor(
    private modalCtrl: ModalController,
    private toastCtrl: EvaToastController,
    private $translate: TranslateService) {
  }

  /**
   * Method that decides whether we can show the update pin modal
   */
  public canShowUpdatePin(input: ICanShowUpdatePinInput): boolean {
    // In order to show the update pin modal
    // The pin setting must be enabled on the OU
    // The user must have already a PIN set-up and they need to have the PickIdentificationPin functionality
    //
    const userHasCreatePinRights = 'PickIdentificationPin' in input.userScopedFunctionalities;
    const canShowCreatePin = input.pinEnabled && input.userHasPin && userHasCreatePinRights;

    return canShowCreatePin;
  }

  /**
   * Show the PIN creation modal
   * @see https://eva2015.atlassian.net/browse/OPTR-6542
   */
  public async showCreatePinModal() {
    this.logger.log('Showing create pin modal');

    const createPinResult = await this.showCreateOrEditPinModal('create');

    if (createPinResult && createPinResult.pinCreated) {
      this.toastCtrl.create({
        message: this.$translate.instant('pin.created')
      }).present();
    }
  }

  /**
   * Show the PIN edit modal
   *
   * @see https://eva2015.atlassian.net/browse/OPTR-7754
   */
  public async showUpdatePinModal() {
    this.logger.log('Showing edit pin modal');

    const updatePinResult = await this.showCreateOrEditPinModal('update');
    if (updatePinResult && updatePinResult.pinCreated) {
      this.toastCtrl.create({
        message: this.$translate.instant('pin.updated')
      }).present();
    }
  }

  /**
   * Shows the edit pin modal
   */
  private async showCreateOrEditPinModal(mode: TPinModalMode) {
    const modalTitle = this.getModalTitle(mode);

    // Launch the create pin popup
    //
    const createOrEditPinModal = await this.modalCtrl.create({
      component: EditPinModalComponent,
      componentProps: {
        modalTitle
      }
    });

    createOrEditPinModal.present();

    // const modalOnDidDismissPromise: Promise<ICreatePinResult> = new Promise((resolve) => {
    //   createOrEditPinModal.onDidDismiss((data: ICreatePinResult) => resolve(data));
    // });
    const modalOnDidDismissPromise = createOrEditPinModal.onDidDismiss<ICreatePinResult>().then( eventDetail => eventDetail.data);

    return modalOnDidDismissPromise;
  }

  /**
   * Get the pin modal title based on whether the pin is in create/update mode
   */
  private getModalTitle(mode: TPinModalMode): string {
    let pinModalTitle;

    switch (mode) {
      case 'create': {
        pinModalTitle = this.$translate.instant('create.pin.title');
        break;
      }

      case 'update': {
        pinModalTitle = this.$translate.instant('update.pin.title');
        break;
      }

      default: {
        this.logger.error(`PIN modal mode ${mode} not supported!`);
      }
    }

    return pinModalTitle;
  }
}
