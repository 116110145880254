<ion-header>
  <ion-toolbar>
    <ion-title> {{ 'receive.goods' | translate }} </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button >
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-card *ngFor="let shipment of shipments" class="selectable ion-padding ion-no-margin" [class.selected]="shipment === chosenShipment" (click)="chooseShipment(shipment)" [button]="true">
    <eva-incoming-shipment-card [shipment]="shipment"></eva-incoming-shipment-card>
  </ion-card>
</ion-content>

<ion-footer class="ion-no-border ion-no-shadow">
  <ion-button expand="full" color="primary" [disabled]="chosenShipment | isNil"
    (click)="confirmChosenShipment()">
    {{ 'action.confirm' | translate }}
  </ion-button>
</ion-footer>
