<p class="ion-no-margin ion-margin-bottom" *ngIf="confirmationNeeded$ | async">
  <ion-text class="confirm-selection-message" color="danger">
    {{ 'confirm.selection.message' | translate }}
  </ion-text>
</p>

<ion-card class="ion-padding selectable" [class.selected]="currentSelectedProductId$ | async | isNil" [button]="true"
  (click)="selectDiscountOption(null)">
  <ion-row>
    <ion-col size="2" class="ion-no-padding ion-padding-start">
    </ion-col>
    <ion-col size="7" class="ion-no-padding">
      {{ 'gift-option.empty' | translate }}
    </ion-col>
    <ion-col size="3" class="ion-no-padding">
    </ion-col>
  </ion-row>
</ion-card>

<ion-card class="ion-padding selectable" *ngFor="let possibleProduct of possibleProductsForDiscount$ | async" [button]="true"
  (click)="selectDiscountOption(possibleProduct)"
  [class.selected]="(currentSelectedProductId$ | async) === possibleProduct.ID">
  <ion-row>
    <ion-col size="2" class="ion-no-padding">
      <eva-img [guid]="possibleProduct.blobGUID"></eva-img>
    </ion-col>
    <ion-col size="7" class="ion-no-padding ion-padding-start">
      <p class="ion-no-margin">
        {{ possibleProduct.Description }}
      </p>
      <small *ngIf="possibleProduct.CustomID" class="product-type">
        {{ possibleProduct.CustomID }}
      </small>
    </ion-col>
    <ion-col size="3" class="ion-text-end ion-no-padding">
      <b>
        <eva-price [currencyString]="possibleProduct.CurrencyID" [price]="possibleProduct.Price">
        </eva-price>
      </b>
    </ion-col>
  </ion-row>
  <ng-container *ngIf="(currentSelectedProductId$ | async) === possibleProduct.ID && (currentSelectedProductRequirements$ | async); let currentSelectedProductRequirements">
    <ion-row class="ion-margin-top" *ngIf="currentSelectedProductRequirements.hasInsufficientStock">
      <ion-text class="ion-no-margin" color="danger"> {{ 'insufficient.stock' | translate }} </ion-text>
    </ion-row>
    <ion-row class="ion-margin-top" *ngIf="currentSelectedProductRequirements.hasShippingRestriction">
      <ion-text class="ion-no-margin" color="danger"> {{ 'product.shipping.restricted' | translate }} </ion-text>
    </ion-row>
  </ng-container>
</ion-card>

<div class="next-tier" *ngIf="!((nextTierProductsForDiscount$ | async) | isEmpty)">
  <div class="tier-indicator">
    <p>
      {{ nextDiscountTierDescription$ | async }}
    </p>
  </div>
  <ion-card class="ion-padding" *ngFor="let possibleProduct of nextTierProductsForDiscount$ | async">
    <ion-row>
      <ion-col size="2" class="ion-no-padding">
        <eva-img [guid]="possibleProduct.blobGUID"></eva-img>
      </ion-col>
      <ion-col size="7" class="ion-no-padding ion-padding-start">
        <p class="ion-no-margin">
          {{ possibleProduct.Description }}
        </p>
        <small *ngIf="possibleProduct.tierDescription" class="product-type">
          {{ possibleProduct.tierDescription }}
        </small>
      </ion-col>
      <ion-col size="3" class="ion-text-end ion-no-padding">
        <b>
          <eva-price [currencyString]="possibleProduct.CurrencyID" [price]="possibleProduct.Price">
          </eva-price>
        </b>
      </ion-col>
    </ion-row>
  </ion-card>
</div>
