import { Component, Input } from '@angular/core';
import { fadeInOut } from '../../shared/animations';
import { EvaApplicationConfigProvider } from '../../services/eva-application-config/eva-application-config';
import { get } from 'lodash';

/** Represents the data that is needed to render a complex card */
export interface IProductComplexCard {
  title: string;
  customID: string;
  blobGUID: string;
  id: number;
  status: number;
  currency?: string;
}


@Component({
  selector: 'eva-product-complex-card',
  templateUrl: './product-complex-card.html',
  styleUrls: ['./product-complex-card.scss'],
  animations: [ fadeInOut ]
})
export class ProductComplexCardComponent {

  @Input() product: IProductComplexCard;

  public showProductStatus$ = this.$evaAppConfig.showProductStatus$;

  constructor(private $evaAppConfig: EvaApplicationConfigProvider) {}

  static createProductComplexCardFromProductDto(productResponse: EVA.Core.GetProductDetailResponse) {
    const product = productResponse.Result;

    const productCard: IProductComplexCard = {
      title: get(product, 'display_value'),
      customID: get(product, 'custom_id'),
      blobGUID: get(product, 'primary_image.blob'),
      id: get(product, 'product_id'),
      status: get(product, 'product_status')
    };
    return productCard;
  }
}
