import { Component, Input } from '@angular/core';
import { getShoppingCart } from '@springtree/eva-sdk-redux';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { slideUpDown } from 'src/app/shared/animations';
import { ICheckoutTotal, IProductLine } from '../checkout-total.component';

/**
 * This component shows each order line of the total summary container
 */
@Component({
  selector: 'eva-checkout-total-line',
  templateUrl: 'checkout-total-line.component.html',
  styleUrls: ['checkout-total-line.component.scss'],
  animations: [slideUpDown]
})
export class CheckoutTotalLineComponent {

  @Input() productLine: IProductLine;

  @Input() orderData: ICheckoutTotal;

  @Input() showLineTypeView: boolean = false;

  @Input() showShippedQuantity: boolean = false;

  public preferredPriceDisplayMode$: Observable<EVA.Core.OrderPreferredPriceDisplayMode> = getShoppingCart.getResponse$().pipe(
    map( response => response?.ShoppingCart.PreferredPriceDisplayMode)
  );

  /**
   * We determine which quantity to show based on component input properties
   * @see https://n6k.atlassian.net/browse/OPTR-18349
   */
  public get totalQuantityToShip(){
    if(this.showShippedQuantity){
      return this.productLine?.quantityShipped;
    }

    // We show the remaining qty if showLineTypeView is active and there are still items to be send
    if(this.showLineTypeView){
      const stillAvailableQty = (this.productLine?.totalQuantityToShip > this.productLine?.quantityShipped);
      if(stillAvailableQty){
        return (this.productLine?.totalQuantityToShip - this.productLine?.quantityShipped);
      }
    }

    return this.productLine?.totalQuantityToShip;
  }

  /**
   * We want to display orderlines when its a return order type too
   * @see https://n6k.atlassian.net/browse/OPTR-23845?focusedCommentId=136723
   */
  public get isReturnOrderType(){
    return (this.productLine.totalQuantityToShip <= -1);
  }

}
