import { NgModule } from '@angular/core';
import { IsEmptyPipe } from './is-empty/is-empty';
import { IsNilPipe } from './is-nil/is-nil';
import { StockLabelPipe } from './stock-label/stock-label';
import { IsArrayPipe } from './is-array/is-array';
import { ToStringPipe } from './to-string/to-string';
import { IntlDatePipe } from './intl-date/intl-date';
import { IntlNumberPipe } from './intl-number/intl-number';
import { GravatarPipe } from './gravatar/gravatar';
import { DistancePipe } from './distance/distance';
import { ShowUnitOfMeasuresPipe } from './show-unit-of-measures/show-unit-of-measures.pipe';
import { ToFormControlPipe } from './to-form-control/to-form-control.pipe';
import { BorrowStatePipe } from './borrow-state/borrow-state';
import { SearchFilterPipe } from './search-filter/search.pipe';

@NgModule({
   declarations: [
      DistancePipe,
      IsEmptyPipe,
      IsNilPipe,
      StockLabelPipe,
      IsArrayPipe,
      ToStringPipe,
      IntlDatePipe,
      IntlNumberPipe,
      GravatarPipe,
      ShowUnitOfMeasuresPipe,
      ToFormControlPipe,
      BorrowStatePipe,
      SearchFilterPipe,
   ],
   imports: [],
   providers: [],
   exports: [
      DistancePipe,
      IsEmptyPipe,
      IsNilPipe,
      StockLabelPipe,
      IsArrayPipe,
      ToStringPipe,
      IntlDatePipe,
      IntlNumberPipe,
      GravatarPipe,
      ShowUnitOfMeasuresPipe,
      ToFormControlPipe,
      BorrowStatePipe,
      SearchFilterPipe,
   ]
})
export class PipesModule {}
