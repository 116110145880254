import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { EvaToastComponent } from './eva-toast.component';
import { EvaToastController } from './eva-toast.controller';
import { OverlayService } from './overlay.service';

@NgModule({
  declarations: [EvaToastComponent],
  imports: [CommonModule, BrowserModule, IonicModule, TranslateModule],
  providers: [OverlayService, EvaToastController],
})
export class EvaToastModule {}
