<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'pause.order' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button navPop>
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form *ngIf="form" [formGroup]="form" autocomplete="off">
    <div class="toggle-container">
      <ion-item class="ion-checkbox-container">
        <ion-label>{{ 'print.receipt' | translate }}</ion-label>
        <ion-checkbox slot="start" formControlName="printReceipt"></ion-checkbox>
      </ion-item>
      <eva-station-selector formControlName="station"></eva-station-selector>
    </div>
  </form>
</ion-content>
<ion-footer class="ion-padding">
  <ion-button expand="full" *ngIf="form?.get('printReceipt').value"
    [disabled]="form?.invalid" color="primary" (click)="pauseOrder()">
    {{'action.confirm' | translate}}
  </ion-button>
  <ion-button expand="full" *ngIf="!form?.get('printReceipt').value"
    [disabled]="form?.invalid" color="primary" (click)="closeModal()">
    {{'action.close' | translate}}
  </ion-button>
</ion-footer>
