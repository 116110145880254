<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'shipping.method.select' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button navPop>
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <p class="ion-no-margin ion-margin-bottom" *ngIf="(shippingMethods$ | async | isEmpty)">
    {{ 'no.shipping.methods' | translate }}
  </p>
  <ng-container *ngIf="!(shippingMethods$ | async | isEmpty)">
    <ion-card
      *ngFor="let shippingMethod of shippingMethods$ | async"
      class="selectable"
      (click)="chooseShippingMethod(shippingMethod)"
      [class.selected]="!(selectedShippingMethod | isNil) && shippingMethod.Code === selectedShippingMethod.Code"
    >
      <ion-card-content>
        <div class="title">{{ shippingMethod.Name }}</div>
      </ion-card-content>
    </ion-card>
  </ng-container>
</ion-content>
<ion-footer>
  <ion-button
    expand="full"
    color="primary"
    *ngIf="!(shippingMethods$ | async | isEmpty)"
    (click)="confirmSelection()"
    type="submit"
  >
    {{ 'action.confirm' | translate }}
  </ion-button>
  <ion-button
    expand="full"
    navPop
    color="primary"
    *ngIf="(shippingMethods$ | async | isEmpty)"
    type="submit"
  >
    {{ 'action.close' | translate }}
  </ion-button>
</ion-footer>
