import { Injectable } from '@angular/core';
import { getShoppingCart } from '@springtree/eva-sdk-redux';
import { isNil } from 'lodash';
import { filter, take } from 'rxjs/operators';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { PendingPaymentsService } from 'src/app/pages/checkout/pending-payments-status/services/pending-payments.service';
import { ICheckoutTotal } from '../../components/checkout-total/checkout-total.component';
import isNotNil from '../../shared/operators/isNotNil';
import { EvaErrorGeneratorProvider } from '../eva-error-generator/eva-error-generator';
import { InterLogisticsSwitchProvider } from '../inter-logistics-switch/inter-logistics-switch';
import { OrderLifecycleService } from '../order-lifecycle/order-lifecycle.service';

/**
 *
 * This service will be able to empty the basket
 */
@Injectable()
export class SummaryCheckoutProvider {
  public logger: Partial<Console>;

  public cartState$ = getShoppingCart.getState$().pipe(
    isNotNil(),
    filter(state => !state.isFetching),
    filter(state => !isNil(state) && !isNil(state.response) && !isNil(state.response.ShoppingCart))
  );

  constructor(
    private $interlogisticsSwitchProvider: InterLogisticsSwitchProvider,
    private $evaErrorGenerator: EvaErrorGeneratorProvider,
    private toastCtrl: EvaToastController,
    private $orderLifecycleService: OrderLifecycleService,
    private pendingPaymentsService: PendingPaymentsService
  ) { }

  public async emptyBasket() {
    let loadingPromise: Promise<any>;

    const isRma = await this.$interlogisticsSwitchProvider.isRma;
    const isInterbranch = await this.$interlogisticsSwitchProvider.isInterbranch;

    if (isInterbranch || isRma) {

      try {
        if (isRma) {
          loadingPromise = this.$interlogisticsSwitchProvider.createRmaOrder({ forceCreate: true });
        } else {
          loadingPromise = this.$interlogisticsSwitchProvider.createInterbranchOrder({ forceCreate: true });
        }

        await loadingPromise;
      } catch (error) {
        const errorFeedback = await this.$evaErrorGenerator.constructFailureFeedback(error);
        this.toastCtrl.create(errorFeedback);
        this.logger.error('error creating a new order for rma or interbranch', error);
      }
    } else {
      const [fetchPromise] = this.$orderLifecycleService.unsetOrderId();

      loadingPromise = fetchPromise;

      // Unsubscribe from pending payments confirmation every time we remove or create new order
      // @see https://n6k.atlassian.net/browse/OPTR-20555
      this.pendingPaymentsService.unsubscribePendingPaymentsActions();
    }
    return loadingPromise;
  }

  public async getOrderData() {
    const cart = await this.cartState$.pipe(take(1)).toPromise();

    const currency = cart.response.ShoppingCart.CurrencyID;

    const amounts = cart.response.Amounts;

    const orderLines = cart.response.ShoppingCart.Lines;

    const totalItems = cart.response.ShoppingCart.TotalItems;

    const orderData: ICheckoutTotal = {
      partialShipment: [],
      currency,
      amounts,
      orderLines,
      totalItems
    };
    return orderData;
  }
}
