import { Injectable } from '@angular/core';
import { find } from 'lodash';
import { Logger } from '../../shared/decorators/logger';
interface IAudioFeedbackFile {
  key: string;
  path: string;
}

@Logger('[audio-feedback-provider]')
@Injectable()
export class AudioFeedbackProvider {
  logger: Partial<Console>;

  private audioFiles: IAudioFeedbackFile[] = [
    {
      key:    'error',
      path:   './assets/audio/feedback_error.mp3'
    },
    {
      key:    'success',
      path:   './assets/audio/feedback_success.mp3'
    }
];

  /**
   * Convenience method for playing the error feedback
   */
  public playError() {
    this.play( 'error' )
    .catch( ( err ) => this.logger.error( err ) );
  }

  public play( key: string ): Promise<any> {
    return new Promise( ( resolve, reject ) => {
      this.logger.debug( `Playing feedback ${key}` );

      const audioFile = find( this.audioFiles, { key } );

      if ( !audioFile ) {
        this.logger.error( `Unknown feedback key requested ${key}` );
        return reject( 'Unknown feedback key' );
      }

      new Audio(audioFile.path).play()
        .then(resolve).catch(error => {
          this.logger.error('error playing audio', error);
          reject(error);
        });
    });
  }
}
