<ng-container *ngIf="userCards$ | async; let userCards">
  <ng-container *ngIf="userCards?.length">
    <h6 class="ion-margin-top">
      <span class="section-header">{{
        "user.cards.title" | translate
      }}</span>
    </h6>
    <ion-card class="customer-details ion-padding ion-no-margin">
      <ng-container *ngFor="let userCard of userCards">
        <ion-row>
          <ion-col size="2">
            <ion-icon name="card"></ion-icon>
          </ion-col>
          <ion-col size="5" class="ion-text-start">
            <b>{{ userCard?.Type?.Name }}</b>
          </ion-col>
          <ion-col size="5" class="ion-text-end">
            <eva-price
              [price]="userCard?.CurrentBalance?.CardBalance"
              [currencyString]="userCard?.CurrencyID"
            >
            </eva-price>
          </ion-col>
        </ion-row>
      </ng-container>
    </ion-card>
  </ng-container>
</ng-container>
