import { NgModule } from '@angular/core';
import { FormTouchDirective } from './form-touch/form-touch';
import { ImageErrorDirective } from './image-error/image-error';
import { IonDateTimeDirective } from './ion-datetime/ion-datetime';
import { IonFooterEmptyDirective } from './ion-footer-empty/ion-footer-empty';
import { LocalizedInputNumberDirective } from './localized-input/localized-input.directive';
import { NavPopDirective } from './nav-pop/nav-pop.directive';
import { PageDtoDirective } from './page-dto/page-dto';
import { ProductGalleryNavigatorDirective } from './product-gallery-navigator/product-gallery-navigator';
import { StopPropagationDirective } from './stop-propagation/stop-propagation';

@NgModule({
   declarations: [
      ImageErrorDirective,
      PageDtoDirective,
      ProductGalleryNavigatorDirective,
      StopPropagationDirective,
      FormTouchDirective,
      IonFooterEmptyDirective,
      NavPopDirective,
      IonDateTimeDirective,
      LocalizedInputNumberDirective
   ],
   imports: [],
   exports: [
      ImageErrorDirective,
      PageDtoDirective,
      ProductGalleryNavigatorDirective,
      StopPropagationDirective,
      FormTouchDirective,
      IonFooterEmptyDirective,
      NavPopDirective,
      IonDateTimeDirective,
      LocalizedInputNumberDirective
   ]
})
export class DirectivesModule { }
