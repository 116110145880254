<form [formGroup]="form">
  <eva-item-search-button
    *ngIf="countriesSearchData$ | async as countriesSearchData"
    formControlName="CountryID"
    [itemSearchData]="countriesSearchData"
  ></eva-item-search-button>

  <div [val-messages]="form.get('CountryID')" class="validation-container">
    <span val-message="required">
      {{ validationMessages.CountryID.required }}
    </span>
  </div>
</form>
