import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash-es';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { ScanPageProvider } from 'src/app/services/scan-page/scan-page';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';

/**
 * This guard will ensure the scan page isnt opened unless the scanner page config is set in the provider. This will mainly be the case
 * on page refresh as consumers of the scan page set the provider
 */
@Logger('[scan-guard-service]')
@Injectable({
  providedIn: 'root'
})
export class ScanGuardService implements CanActivate, ILoggable {
  logger: Partial<Console>;

  constructor(private navCtrl: NavController, private $scanPage: ScanPageProvider, private toastCtrl: EvaToastController, private $translate: TranslateService) {}

  async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    /** We will only open this page if the scanner page config is there */
    const shouldNotOpen = isNil(this.$scanPage.getCurrentConfig());

    this.logger.log(`shouldNotOpen=${shouldNotOpen}`);

    if (shouldNotOpen) {
      try {
        await this.navCtrl.navigateRoot('');
      } catch (error) {
        this.logger.error('error navigating to root page', error);
      }
    }

    const shouldOpen = !shouldNotOpen;

    return shouldOpen;
  }
}
