<div class="ion-padding">
  <h6 class="ion-no-margin" *ngIf="subTotalPricing$ | async as subTotalPrice">
    <ion-row class="ion-justify-content-between" *ngIf="subTotalPrice.price || subTotalPrice.priceInTax">
      <span>{{ 'subtotal' | translate }}</span>
      <eva-localized-price
        [pricing]="subTotalPrice"
        [currencyString]="currencyId$ | async"
        [preferredPriceDisplayMode]="preferredPriceDisplayMode$ | async">
      </eva-localized-price>
    </ion-row>

    <ng-container *ngIf="((giftwrappingCosts$ | async).price > 0 || (giftwrappingCosts$ | async).priceInTax > 0)">
      <ion-row *ngIf="(showGiftwrapping$ | async)" class="ion-justify-content-between">
        <span class="light-text">{{ 'gift.wrapping.title' | translate }}</span>
        <span class="light-text price">
            <eva-localized-price
              [pricing]="giftwrappingCosts$ | async"
              [currencyString]="currencyId$ | async"
              [preferredPriceDisplayMode]="preferredPriceDisplayMode$ | async">
            </eva-localized-price>
        </span>
      </ion-row>
      <div *ngIf="hasGreetingCard$ | async">
        <ion-row class="ion-margin-start ion-justify-content-between">
          <span class="light-text">{{ 'greeting.card.title' | translate }}</span>
        </ion-row>
      </div>
    </ng-container>
  </h6>
  <hr>

  <ng-container *ngIf="(showDiscountTotal$ | async) || (showShippingCosts$ | async) || (showAddedTax$ | async) ">
    <ion-row secondary-text class="subtotal-line ion-justify-content-between" *ngIf="showDiscountTotal$ | async">
      <span>{{ 'discount' | translate }}</span>
      <span>
        <eva-localized-price [pricing]="discountTotal$ | async" [currencyString]="currencyId$ | async" [preferredPriceDisplayMode]="preferredPriceDisplayMode$ | async">
        </eva-localized-price>
      </span>
    </ion-row>
    <ion-row secondary-text class="subtotal-line ion-justify-content-between" *ngIf="showShippingCosts$ | async">
      <span>{{ 'delivery.cost' | translate }}</span>
      <span>
        <eva-localized-price [pricing]="shippingCosts$ | async" [currencyString]="currencyId$ | async" [preferredPriceDisplayMode]="preferredPriceDisplayMode$ | async">
        </eva-localized-price>
      </span>
    </ion-row>
    <ng-container *ngIf="(showAddedTax$ | async)">
      <ion-row secondary-text class="subtotal-line ion-justify-content-between" *ngFor="let tax of (taxes$ | async)">
        <span>{{ 'vat.included' | translate }} - {{ tax.Rate - 1 | intlNumber: intlNumberTaxOpts  }} </span>
        <span>
          <eva-price [currencyString]="currencyId$ | async" [price]="tax.Amount"></eva-price>
        </span>
      </ion-row>
    </ng-container>
    <hr>
  </ng-container>
  <ng-container *ngIf="(ecoTaxes$ | async) as ecoTaxes">
    <eva-eco-tax-label
      [currencyID]="currencyId$ | async"
      [ecoTaxInTax]="ecoTaxes?.EcoTaxInTax" [showTotal]="false" [showItem]="false"
      [preferredPriceDisplayMode]="preferredPriceDisplayMode$ | async">
    </eva-eco-tax-label>
  </ng-container>
  <div class="horizontal-scroll">
    <ion-button fill="clear" class="option" [class.delete-selected]="selectedSwipeUpOption === 'emptyBasket'"
      (click)="select('emptyBasket')">
      <ion-row class="ion-wrap">
        <ion-icon name="trash"></ion-icon>
        <div>{{ 'action.clear' | translate }}</div>
      </ion-row>
    </ion-button>

    <ng-container *ngIf="interlogisticsOrder$ | async">
      <ion-button fill="clear" class="option" (click)="switchToSalesOrder()">
        <ion-row class="ion-wrap">
          <ion-icon name="cart-outline"></ion-icon>
          <div>{{ 'sales' | translate }}</div>
        </ion-row>
      </ion-button>

      <ion-button fill="clear" class="option" (click)="switchToRtsOrder()" *ngIf="!(isRtsOrder$ | async)">
        <ion-row class="ion-wrap">
          <ion-icon src="./svg/order-rma.svg"></ion-icon>
          <div>{{ 'order.type.return.to.supplier' | translate }}</div>
        </ion-row>
      </ion-button>

      <ion-button fill="clear" class="option" (click)="switchToInterbranchOrder()" *ngIf="!(isInterbranchOrder$ | async)">
        <ion-row class="ion-wrap">
          <ion-icon src="./svg/order-interbranch.svg"></ion-icon>
          <div>{{ 'order.type.interbranch' | translate }}</div>
        </ion-row>
      </ion-button>
    </ng-container>

    <ng-container *ngIf="!(interlogisticsOrder$ | async)">
      <ion-button fill="clear" class="option" [class.selected]="selectedSwipeUpOption === 'discount'" (click)="select('discount')">
        <ion-row class="ion-wrap">
          <ion-icon name="pricetag-outline"></ion-icon>
          <div>{{ 'discount' | translate }}</div>
        </ion-row>
      </ion-button>

      <ion-button fill="clear" class="option" [class.selected]="selectedSwipeUpOption === 'coupon'" [class.coupon-selected]="selectedSwipeUpOption === 'coupon'" (click)="select('coupon')">
        <ion-row class="ion-wrap">
          <ion-icon name="list-outline"></ion-icon>
          <div>{{ 'coupons' | translate }}</div>
        </ion-row>
      </ion-button>
    </ng-container>

    <ion-button fill="clear" [hidden]="orderIsRMA$ | async" class="option" [class.selected]="selectedSwipeUpOption === 'orderActionType'"
      (click)="select('orderActionType')">
      <ion-row class="ion-wrap" *ngIf="orderLineAction$ | async as orderLineAction">
        <eva-icon [icon]="orderLineAction.icon"></eva-icon>
        <div>{{ orderLineAction.label }}</div>
      </ion-row>
    </ion-button>

    <ion-button fill="clear" class="option" [class.selected]="selectedSwipeUpOption === 'pause'"
      (click)="select('pause')">
      <ion-row class="ion-wrap">
        <ion-icon name="pause"></ion-icon>
        <div>{{ 'pause' | translate }}</div>
      </ion-row>
    </ion-button>
  </div>
  <!-- Swiper buttons -->
</div>

<!-- New swipe up component for change order type -->
<div class="bottom-order-input ion-margin-top" *ngIf="selectedSwipeUpOption === 'orderActionType'"
  (touchmove)="$event.stopPropagation()" (touchstart)="$event.stopPropagation()">
  <div *ngFor="let orderType of orderTypes$ | async">
    <ion-button fill="clear" class="option-order"
      (click)="onOrderActionTypeChange(orderType)">
      <ion-row class="ion-wrap">
        <eva-icon [icon]="orderType.icon"></eva-icon>
        <div class="order-text">{{ orderType.translation | translate }}</div>
      </ion-row>
    </ion-button>
  </div>
</div>

<div class="bottom-input ion-margin-top"
  *ngIf="selectedSwipeUpOption === 'emptyBasket'"
  [attr.padding-horizontal]="true">
  <ion-button *ngIf="selectedSwipeUpOption === 'emptyBasket'" class="empty-cart" fill="clear" color="danger"
    (click)="emptyBasket()">
    {{ 'empty.basket' | translate }} </ion-button>
</div>


<ion-row class="bottom-coupon-input ion-align-items-center ion-justify-content-between ion-margin-top" *ngIf="selectedSwipeUpOption === 'coupon'">
  <form [formGroup]="couponForm" (ngSubmit)="onCouponFormSubmit(couponForm.get('couponCode'))" class="ion-padding-start">
    <ion-input type="text" placeholder="Coupon code" formControlName="couponCode">
    </ion-input>
  </form>
  <ion-row class="ion-align-items-center">
    <ion-button fill="clear" (click)="scanCouponCode()" class="ion-no-margin">
      <ion-icon name="scan-outline"></ion-icon>
    </ion-button>
    <ion-button fill="clear" (click)="clearCoupon()" class="ion-no-margin">
      <ion-icon name="close"></ion-icon>
    </ion-button>
  </ion-row>
</ion-row>
