import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { getUserTaskNotCompletedReasons } from '@springtree/eva-sdk-redux';
import { map } from 'rxjs/operators';

export interface TaskDeclineModalDismissData {
  reasonId?: number;
  declineConfirmed: boolean;
}

/**
 * Whenever there are not completed task reasons, we would like to show them in this modal.
 */
@Component({
  selector: 'eva-task-decline-modal',
  templateUrl: './task-decline-modal.component.html'
})
export class TaskDeclineModalComponent implements OnInit {

  /**
  * This will contain the deviation reason by the user, in the case of deviations
  * @see https://n6k.atlassian.net/browse/OPTR-14314
  */
  declineReasonCtrl = new FormControl(null);

  declineReasons$ = getUserTaskNotCompletedReasons.getResponse$().pipe(
    map( response => (response?.Reasons ?? [])),
  );

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  declineTask() {
    this.modalCtrl.dismiss({
      reasonId: this.declineReasonCtrl.value as number,
      declineConfirmed: true
    } as TaskDeclineModalDismissData);
  }

  dontDecline() {
    this.modalCtrl.dismiss({
      declineConfirmed: false
    } as TaskDeclineModalDismissData);
  }

}
