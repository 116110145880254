import { Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getAvailableOpenIDConfigurations, store } from '@springtree/eva-sdk-redux';
import { Platform } from '@ionic/angular';
import { isNil } from 'lodash';
import { UserManager, User } from 'oidc-client';
import { BehaviorSubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { appUrlScheme } from '../../shared/constants';
import { Logger } from '../../shared/decorators/logger';
import { EvaApplicationConfigProvider } from '../eva-application-config/eva-application-config';
import { SelectedOrganisationUnitProvider } from '../selected-organisation-unit/selected-organisation-unit';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';

/**
 * We will find providers with this user type in order to prevent login errors.
 * @see https://n6k.atlassian.net/browse/OPTR-11690
 */
 const EMPLOYEE_USER_TYPE = 1;
@Logger('[open-id-auth-provider]')
@Injectable()
export class OpenIdAuthProvider {
  public logger: Partial<Console>;

  public isEnabled$ = this.$evaApplicationConfig.loginWithOpenIdEnabled$;

  public providers$: BehaviorSubject<EVA.Authentication.OpenID.AvailableOpenIDConfiguration[]> = new BehaviorSubject(
    [],
  );

  public openIdLoginSuccess$ = new Subject<Partial<EVA.Core.Login>>();

  private oidcClientUserManager: UserManager;

  private get redirect_uri(): string {
    const redirectUri = this.platform.is('capacitor') ? `${appUrlScheme}://open-id/login` : window.location.href;

    return redirectUri;
  }

  constructor(
    private $evaApplicationConfig: EvaApplicationConfigProvider,
    private $selectedOrganisationUnit: SelectedOrganisationUnitProvider,
    private $toast: EvaToastController,
    private $translate: TranslateService,
    private platform: Platform,
    private zone: NgZone
  ) { }

  public async initialise(url: string) {
    try {
      await this.configureOpenIdSettings();

      this.handlePotentialCallback(url);
    } catch (error) {
      this.logger.error('error calling initialise', error);
    }
  }

  public async handleDeepLinking(url: string) {
    try {
      const user = await this.oidcClientUserManager.signinCallback(url);
      this.openIdResponseCallback(user);
    } catch (error) {
      this.logger.error('Failed callback redirection for OpenID provider', error);
      this.$toast.showMessage(this.$translate.instant('login.fail'));
    }
  }

  public async login(openIdProviderID: number) {
    try {
      this.setOpenIdProviderID(openIdProviderID);
      this.oidcClientUserManager.signinRedirect();
    } catch (error) {
      this.logger.error('Failed OpenID redirection', error);
    }
  }

  private async configureOpenIdSettings() {
    const openIdEnabled = await this.isEnabled$.pipe(first()).toPromise();
    if (!openIdEnabled) {
      return;
    }

    const hasAdditionalProviders = await this.$evaApplicationConfig.openIdAdditionalProviders$
      .pipe(first())
      .toPromise();

    let openIdAdditionalProviders = [];
    if (hasAdditionalProviders) {
      const [action, promise] = getAvailableOpenIDConfigurations.createFetchAction();

      store.dispatch(action);

      const response = await promise;

      openIdAdditionalProviders = response.Providers
        .filter(provider => Boolean(provider.UserType & EMPLOYEE_USER_TYPE))
        .sort((provider1, provider2) => {
          return provider1.Primary === provider2.Primary ? 0 : provider1.Primary ? -1 : 1;
        });
    } else {
      const id = await this.$evaApplicationConfig.openIdProviderID$.pipe(first()).toPromise();
      const name = await this.$evaApplicationConfig.openIdProviderName$.pipe(first()).toPromise();
      const baseUrl = await this.$evaApplicationConfig.openIdBaseUrl$.pipe(first()).toPromise();
      const clientId = await this.$evaApplicationConfig.openIdClientId$.pipe(first()).toPromise();
      const openIdUserType = await this.$evaApplicationConfig.openIdUserType$.pipe(first()).toPromise();

      openIdAdditionalProviders = [{
        ID: id,
        Name: name,
        BaseUrl: baseUrl,
        ClientID: clientId,
        Primary: true,
      }];

      if ( Boolean(openIdUserType & EMPLOYEE_USER_TYPE) ) {
        const id = await this.$evaApplicationConfig.openIdProviderID$.pipe(first()).toPromise();
        const name = await this.$evaApplicationConfig.openIdProviderName$.pipe(first()).toPromise();
        const baseUrl = await this.$evaApplicationConfig.openIdBaseUrl$.pipe(first()).toPromise();
        const clientId = await this.$evaApplicationConfig.openIdClientId$.pipe(first()).toPromise();

        openIdAdditionalProviders = [{
          ID: id,
          Name: name,
          BaseUrl: baseUrl,
          ClientID: clientId,
          Primary: true,
          UserType: openIdUserType
        }];
      }
    }

    this.providers$.next(openIdAdditionalProviders);
  }

  /** when the app is opened, maybe it was opened after the login flow. We will be checking this here */
  private async handlePotentialCallback(url: string) {
    try {
      const openIdProviderID = this.getOpenIdProviderID();

      if (isNil(openIdProviderID)) {
        return;
      }

      // If we don't have the id_token parameter, this is not a redirect
      // see https://n6k.atlassian.net/browse/OPTR-10909
      //
      if (!window.location.href?.includes('id_token')) {
        return;
      }

      const openIdProvider = this.providers$.value.find((provider) => provider.ID === openIdProviderID);
      this.oidcClientUserManager = new UserManager({
        authority: openIdProvider.BaseUrl,
        client_id: openIdProvider.ClientID,
        redirect_uri: this.redirect_uri,
        scope: 'openid profile email',
        prompt: 'login',
      });
      const user = await this.oidcClientUserManager.signinRedirectCallback(url);

      this.openIdResponseCallback(user);
    } catch (error) {
      this.logger.error('Failed callback redirection for OpenID provider', error);

      const errorMessage = await this.$translate.get('login.fail').pipe(first()).toPromise();

      this.$toast.showMessage(errorMessage);
    }
  }

  private async openIdResponseCallback(user: User) {
    const openIdProviderID = this.getOpenIdProviderID();
    this.resetOpenIdProviderID();

    const loginData: Partial<EVA.Core.Login> = {
      AsEmployee: true,
      CustomAuthenticateData: {
        id_token: user.id_token,
        access_token: user.access_token,
        provider: openIdProviderID,
      },
      CustomAuthenticatorType: 'OpenID',
      UserType: EVA.Core.UserTypes.Employee,
    };

    const selectedOrganisation = this.$selectedOrganisationUnit.getOrganisation();

    if (!isNil(selectedOrganisation)) {
      loginData.OrganizationUnitID = selectedOrganisation.ID;
    }

    this.zone.run(() => {
      this.openIdLoginSuccess$.next(loginData);
    });

  }

  private setOpenIdProviderID(id: number) {
    const openIdProvider = this.providers$.value.find((provider) => provider.ID === id);

    this.oidcClientUserManager = new UserManager({
      authority: openIdProvider.BaseUrl,
      client_id: openIdProvider.ClientID,
      redirect_uri: this.redirect_uri,
      scope: 'openid profile email',
      prompt: 'login',
    });
    localStorage.setItem('OpenIdProviderID', id.toString());
  }

  private getOpenIdProviderID() {
    const id = localStorage.getItem('OpenIdProviderID');

    return isNil(id) ? null : Number(id);
  }

  private resetOpenIdProviderID() {
    localStorage.removeItem('OpenIdProviderID');
  }

}
