<ng-container *ngIf="!(productRequirementsObjectValues | isEmpty)">
  <ng-container *ngFor="let requirement of productRequirements">
    <ion-item lines="full" class="ion-no-padding">
      <ion-row class="requirement-item">
        <ion-col size="5" class="ion-no-padding">
          <ion-label>{{ requirement.name }}</ion-label>
        </ion-col>
        <ion-col size="7" class="ion-no-padding" ellipsis>
          <ng-container [ngSwitch]="(productRequirementsObjectValues)[requirement.id].type">
            <ng-container *ngSwitchCase="'Bool'">
              <ng-container *ngIf="(productRequirementsObjectValues)[requirement.id].value; else noCheckmark">
                <ion-icon color="success" name="checkmark"></ion-icon>
              </ng-container>
              <ng-template #noCheckmark>
                <ion-icon name="close"></ion-icon>
              </ng-template>
            </ng-container>
            <ion-note *ngSwitchDefault>
              <span *ngIf="productRequirementsObjectValues[requirement.id] as requirementDetails">
                {{ requirementDetails.value }} <ng-container *ngIf="requirementDetails.name">: {{ requirementDetails.name }}</ng-container>
              </span>
            </ion-note>
          </ng-container>
        </ion-col>
      </ion-row>
    </ion-item>
  </ng-container>
</ng-container>
