import { Injectable } from '@angular/core';
// We use an alias for settings because of a weird bundling issue with Angular
//
import { getCurrentUser, listStationsForOrganizationUnit, settings as sdkSettings, store } from '@springtree/eva-sdk-redux';
import { get, isEmpty, isNaN, isNil, noop } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, first, map, take } from 'rxjs/operators';
import { IAvailableFilteredStations } from 'src/app/components/station-available-selector/station-available-selector.component';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import isNotNil from '../../shared/operators/isNotNil';

export type FilterStationFn  = (station: EVA.Core.StationDto) => boolean;

@Logger('[station-selector-provider]')
@Injectable()
export class StationSelectorProvider implements ILoggable {

  public logger: Partial<Console>;

  /** The current last selected stationId */
  private get currentStationId(): number|null {
    const currentStationId = this._currentStationId || parseInt(localStorage.getItem( 'stationId' ), 10);

    if ( isNaN(currentStationId) ) {
      return null;
    } else {
      return currentStationId;
    }
  }

  private set currentStationId( newStationId: number ) {
    this._currentStationId = newStationId;

    this.currentStationId$.next( newStationId );

    if ( newStationId ) {
      localStorage.setItem( 'stationId', newStationId.toString() );
    } else {
      localStorage.removeItem(  'stationId' );
    }
  }

   _currentStationId: number = null;

  public currentStationId$: BehaviorSubject<number>;

  public resetSelectedStation$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /** A list of the available stations. */
  public availableStations$ = this.listStationsForOrganizationUnitStream$().pipe(
    isNotNil(),
    map( station => station.Result.Page )
  );

  // This observable help us to grey out payment button on checkout page
  // @see https://n6k.atlassian.net/browse/OPTR-20556
  public availableStationsStatus$ = new BehaviorSubject(null);

  constructor() {
    this.currentStationId$ = new BehaviorSubject( this.currentStationId );

    this.setFetchStationsListener();
  }

  public getCurrentStationId(): number {
    return this.currentStationId$.value;
  }

  public setCurrentStationId( id: number ) {
    this.currentStationId = id;
    // This will ensure station id is sent in the headers of requests to the backend
    // @see https://eva2015.atlassian.net/browse/OPTR-6292
    // @see https://eva2015.atlassian.net/browse/OPTR-6284
    //
    sdkSettings.currentStationID = id;
  }

  public async getStationById(stationId: number) {
    const allAvailableStations = await this.listStationsForOrganizationUnitStream$().pipe(
      take(1),
      isNotNil(),
      map(response => response.Result.Page || [])
    ).toPromise();
    const currentStation = allAvailableStations.find(station => station.ID === stationId);
    return currentStation;
  }

  /**
   * we use this stream to make sure we have stations in the response even if the call fails at start up
   * @see https://eva2015.atlassian.net/browse/OPTR-7054
   */
  public listStationsForOrganizationUnitStream$() {
    this.refetchStations();
    return listStationsForOrganizationUnit.getResponse$();
  }

  public async refetchStations() {
    const stationsResponse = await listStationsForOrganizationUnit.getResponse$().pipe(first()).toPromise();
    if (stationsResponse && !stationsResponse.Result) {
      this.logger.log('Refetching stations');
      await this.fetchStations();
    }
  }

  /**
   * Whenever the current OU changes, we want to fetch the stations
   */
  private setFetchStationsListener() {
    getCurrentUser.getResponse$().pipe(
      isNotNil(),
      map( getCurrentUserResponse => getCurrentUserResponse.User.CurrentOrganizationID ),
      distinctUntilChanged()
    ).subscribe( () => {
      this.fetchStations();
    });
  }

  private async fetchStations() {
    const currentOrganizationID: number = await getCurrentUser.getEmployee$()
      .pipe(
        first(employee => !isEmpty(employee)),
        map( employee => employee.User.CurrentOrganizationID )
      ).toPromise();

    const [action, fetchPromise] = listStationsForOrganizationUnit.createFetchAction({
      OrganizationUnitID: currentOrganizationID
    });

    store.dispatch(action);

    fetchPromise.then( response => {
      const stations: EVA.Core.StationDto[] = get( response, 'Result.Page', [] );

      if ( stations.length === 1 ) {
        // If we only have one value station, we will select it
        //
        this.setCurrentStationId( stations[0].ID );
      } else if ( isNil(this.currentStationId) ) {
        // if we have a station that has a thermal printer that station will be selected
        // when we have no station selected.
        const toSelectStation = stations.find(station => {
          return station.HasThermalPrinterDevice;
        });
        toSelectStation ? this.setCurrentStationId(toSelectStation.ID) : noop();
      }

      // We want to ensure the selected station is in the list of station we just fetched
      //
      const selectedStationExists = stations.find( station => station.ID === this.currentStationId );

      if ( isNil(selectedStationExists) ) {
        this.currentStationId = null;
      }
    })
    .catch( error => {
      this.logger.error('Failed to fetch stations for this organisation', error);
    });
  }

}
