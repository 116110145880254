<h2 *ngIf="data.title">{{ data.title }}</h2>

<ion-row class="ion-align-items-center">
  <p [class.no-margin]="shouldHaveMargin">
    {{ data.message }}
  </p>

  <ng-container *ngIf="!data.error">
    <div *ngFor="let button of data.buttons" class="left-auto">
      <ion-button (click)="handleBtnClick(button)" fill="clear" size="small" color="light">{{ button.label }}</ion-button>
    </div>
  </ng-container>

  <div *ngIf="data.error" class="error-code left-auto">
    {{ data.error['Code'] || ('unknown' | translate) }}
  </div>
</ion-row>

<div *ngIf="data.showCloseHandle" class="line"></div>
