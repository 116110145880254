<ion-card class="customer-details ion-padding ion-no-margin">
  <ng-container *ngIf=" user$ | async; let user ">
    <ion-row>
      <ion-col size="2">
        <ion-icon src="./assets/icon/account_box.svg"></ion-icon>
      </ion-col>
      <ion-col size="10">
        <div>
          <b>{{ user.FullName }}</b>
        </div>
        <div ellipsis>{{ user.PhoneNumber }}</div>
        <div ellipsis>{{ user.EmailAddress }}</div>
        <div ellipsis>{{ user.CountryID }}</div>
        <div *ngIf="(showFiscalID$ | async) && user.FiscalID">
          {{user.FiscalID}}
        </div>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="userCompany">
      <ion-col size="2">
        <ion-icon name="business-outline"></ion-icon>
      </ion-col>
      <ion-col size="10">
        <div>
          <b> {{ userCompany.Name }} </b>
        </div>
        <div *ngIf="userCompany.RegistrationNumber">
          {{ 'registration.number' | translate }}: {{
          userCompany.RegistrationNumber }}
        </div>
        <div *ngIf="userCompany.VatNumber">
          {{ 'vat.number' | translate }}: {{ userCompany.VatNumber }}
        </div>
      </ion-col>
    </ion-row>
    <eva-employee-discount
      [userId]="user.ID"
    ></eva-employee-discount>
  </ng-container>
</ion-card>
