import { Component, Inject, NgZone } from '@angular/core';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { SplashScreen } from '@capacitor/splash-screen';
import { LoadingController, MenuController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { getCurrentUser, getShoppingCartInfo, getStockLabels, settings, store } from '@springtree/eva-sdk-redux';
import { CreateClientParams } from 'contentful';
import { isEmpty, isNil } from 'lodash-es';
import { from, timer } from 'rxjs';
import { delayWhen, filter, first, map, retryWhen, tap, withLatestFrom } from 'rxjs/operators';
import { IonInputFocusFixService } from './modules/ion-input-focus-fix/ion-input-focus-fix.service';
import { EvaBarcodeScannerProvider } from './modules/scanner/eva-barcode-scanner';
import { WebVitalsService } from './modules/web-vitals/web-vitals.service';
import { ContentfulProvider } from './modules/whats-new/contentful';
import { TabsPage } from './pages/tabs/tabs.page';
import { AppBlurProvider } from './services/app-blur/app-blur';
import { ClientAppCommunicationProvider } from './services/client-app-communication/client-app-communication';
import { CommunicatorProvider } from './services/communicator/communicator';
import { EnvironmentOverrideProvider } from './services/environment-override/environment-override';
import { EvaApplicationConfigProvider } from './services/eva-application-config/eva-application-config';
import { EvaLogoutProvider } from './services/eva-logout/eva-logout';
import { EvaSessionValidatorProvider } from './services/eva-session-validator/eva-session-validator';
import { EvaStartupProvider, IEnvironment } from './services/eva-start-up/eva-start-up';
import { FullStockCountAlertService } from './services/full-stock-count-alert/full-stock-count-alert.service';
import { InternationalisationProvider } from './services/internationalisation/internationalisation';
import { MomentProvider } from './services/moment/moment';
import { OpenIdAuthProvider } from './services/open-id-auth/open-id-auth';
import { SelectedOrganisationUnitProvider } from './services/selected-organisation-unit/selected-organisation-unit';
import { SentinelProvider } from './services/sentinel/sentinel';
import { ThemeService } from './services/theme/theme.service';
import { UserTaskAssigneeChangeService } from './services/user-task-assignee-change/user-task-assignee-change.service';
import { UserTaskProvider } from './services/user-task/user-task';
import { bootstrapLocalStorageKeys, bootstrapStore } from './shared/bootstrap-store';
import { EvaFeedback } from './shared/decorators/eva-feedback';
import { Logger } from './shared/decorators/logger';
import { DEFAULT_LOCALE } from './shared/modules/injection-tokens';
import isNotNil from './shared/operators/isNotNil';
import { languageFromLocale } from './shared/utils/language';

@Logger('[app-component]')
@Component({
  selector: 'eva-companion-app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  /** Default language derived from locale */
  private defaultLanguage: string;

  logger: Partial<Console>;

  public user$ = getCurrentUser.getState$().pipe(
      filter(state => Boolean(state.response) && Boolean(state.response.User)),
      map(state => state.response.User)
  );

  public blurAppBackground$ = this.$appBlur.getBlurStream();

  public environmentOverrideActive = this.$environmentOverride.overrideActive;

  constructor(
    public platform: Platform,
    private startupProvider: EvaStartupProvider,
    private $translate: TranslateService,
    private evaLogout: EvaLogoutProvider,
    private loadingCtrl: LoadingController,
    private $appBlur: AppBlurProvider,
    private $communicator: CommunicatorProvider,
    private $userTask: UserTaskProvider,
    private $clientAppCommunication: ClientAppCommunicationProvider,
    private $internationalisation: InternationalisationProvider,
    private $sessionValidator: EvaSessionValidatorProvider,
    private $environmentOverride: EnvironmentOverrideProvider,
    private $appConfig: EvaApplicationConfigProvider,
    private $moment: MomentProvider,
    @Inject(DEFAULT_LOCALE) private defaultLocale: string,
    private $sentinel: SentinelProvider,
    private navCtrl: NavController,
    private $barcodeScanner: EvaBarcodeScannerProvider,
    private $contenful: ContentfulProvider,
    private $fullStockCountAlert: FullStockCountAlertService,
    private zone: NgZone,
    private $userTaskAssigneeChange: UserTaskAssigneeChangeService,
    private $openIdAuth: OpenIdAuthProvider,
    private ionInputFocusFixService: IonInputFocusFixService,
    private $themeService: ThemeService,
    private $webVitalsService: WebVitalsService,
    private menu: MenuController,
    private $selectedOrganisationUnit: SelectedOrganisationUnitProvider
  ) {
    this.defaultLanguage = languageFromLocale(this.defaultLocale);

    this.$themeService.initialise();

    // Set the default language fallback
    //
    this.$translate.setDefaultLang(this.defaultLanguage);

    this.$translate.use(this.defaultLanguage);

    this.initialiseApp();

    this.initialiseStore();

    this.$communicator.initialise();

    this.$userTask.initialise();

    this.$clientAppCommunication.initialise();

    this.$userTaskAssigneeChange.initialise();

    this.listenToUserChanges();

    this.listenToOrganizationUnitChanges();

    this.$sentinel.initialise();

    this.initialiseContentFull();

    this.$fullStockCountAlert.initialise();

    this.initialiseIonInputFocusFix();

    this.$webVitalsService.initialise();
  }

  initialiseApp() {
    this.platform.ready().then(() => {
      if ( !this.platform.is('capacitor' ) ) {
        this.logger.warn('[initialiseApp] not running in capacitor, returning early..');
        return;
      }
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.
      //

      SplashScreen.hide();

      Keyboard.setAccessoryBarVisible({
        isVisible: true
      });

      Keyboard.setResizeMode({
        mode: KeyboardResize.Ionic
      });

      this.initialiseDeepLinking();
    });

    this.platform.resume.subscribe(async () => {
      this.logger.log('App resuming');
      try {
        this.$clientAppCommunication.reconnect();
      } catch (error) {
        this.logger.warn('Client app hub reconnect on resume failed', error);
      }
    });
  }

  async initialiseStore() {
    // Enable checkout options for getShoppingCart request
    // @see https://n6k.atlassian.net/browse/OPTR-19490
    settings.shoppingCartOptions = {
      ...settings.shoppingCartOptions,
      IncludeCheckoutOptions: true,
      IncludeAvailableRefundPaymentMethods: true
    }

    const settingsToSave = Object.values(bootstrapLocalStorageKeys);
    // We will subscribe to setting changes in case a user logs in or the session id is set
    //
    settings.changes$
      .pipe(
        filter(setting => settingsToSave.includes(setting.name)),
        tap(setting => this.logger.log(`${setting.name} has changed from ${setting.old} to ${setting.new}`))
      )
      .subscribe(setting => {
        /** The new value we will be storing in local storage cannot be null or undefined. So we will default that to an empty string */
        const newValue = isNil(setting.new) ? '' : setting.new;

        localStorage.setItem(setting.name, newValue);
      });

    try {
      let currentConfigFile: IEnvironment = this.startupProvider.evaConfig;

      if (currentConfigFile.customerName === 'generic') {
        // We want the user to be able to change the endpoint by shaking the device
        let environmentOverride = this.$environmentOverride.getOverride();

        const queryParameterEndpoint = this.getEndpointQueryParameter();

        if (queryParameterEndpoint) {
          environmentOverride = {
            endpoint: queryParameterEndpoint,
            customerName: 'generic',
            name: null,
            source: 'eva-customer-manager'
          };
        } else if (isNil(environmentOverride) || isEmpty(environmentOverride?.endpoint)) {
          environmentOverride = await this.$environmentOverride.getNewOverride();

          this.navCtrl.navigateRoot('login');
        }

        this.$environmentOverride.setOverride(environmentOverride);
        // Overriding the environment with the one the user set
        //
        currentConfigFile = environmentOverride;
      }

      const loader = await this.loadingCtrl.create({
        message: await this.$translate.get('connection.reestablishing').pipe(first()).toPromise()
      });

      // If this is a generic build, and there was no selected endpoint the bootstrapOutput in the start up provider will be undefined.
      // We will make sure to bootstrap the store if it is
      //
      let bootstrapStoreOutput = this.startupProvider.bootstrapOutput;

      if (isNil(bootstrapStoreOutput)) {
        bootstrapStoreOutput = bootstrapStore(currentConfigFile);
      }

      from(bootstrapStoreOutput).pipe(
        tap(data => this.logger.log(data), error => this.logger.error(error)),
        retryWhen(source => {
          let retryCount = 0;

          loader.present();

          return source.pipe(
            filter(data => data instanceof Error || data instanceof Response),
            filter(data => data instanceof Response ? data.status === 401 : true),
            tap(() => this.logger.warn('Error, restarting bootstrap with userToken')),
            tap(error => {
              settings.userToken = null;

              retryCount++;

              // After three attempts we will stop
              //
              if (retryCount > 3) {
                throw new Error(error);
              }
            }),
            delayWhen(() => {
              /** Delay between retries */
              const delay = 1000 + (1000 * (retryCount - 1));

              return timer(delay);
            })
          );

        })
      )
      .subscribe({
        next: () => {
          const user = getCurrentUser.getState();

          // Only if the user is logged in, and nobody is already fetching the shopingCartInfo (done as part of the login chain)
          // do we want to fetch the cart as side effect of the bootstrap
          //
          const getShoppingCartInfoState = getShoppingCartInfo.getState();
          if (user.isLoggedIn && !getShoppingCartInfoState.isFetching) {
            this.fetchShoppingCartInfo();
          }

          this.$sessionValidator.start();

          loader.dismiss();
        },
        error: error => {
          loader.dismiss();

          this.navCtrl.navigateRoot('offline');

          this.logger.error('error bootstraping store', error);
        }
      });
    } catch (error) {
      this.logger.error('failed to fetch environment file', error);
    }
  }

  @EvaFeedback({
    i18nFailKey: 'cart.fetch.fail'
  })
  async fetchShoppingCartInfo() {
    const [action, fetchPromise] = getShoppingCartInfo.createFetchAction();
    store.dispatch(action);

    return fetchPromise;
  }

  async logout() {
    // There is a debugger instruction that breaks the user experience when this error occurs
    // sometimes the animation for the menu closing is slower than the navigation
    //
    try {
      await this.menu.close();
      this.evaLogout.logout();
    } catch(error) {
      this.logger.error('failed to close menu', error);
    }
  }

  private listenToUserChanges(): void {
    getCurrentUser.getState$().pipe(
      filter(state => state.isAnonymous === false),
      map(state => state.response),
      isNotNil(),
      map(response => response.User)
    ).subscribe(user => {
      // Set user language on the moment provider
      //
      this.$moment.setUserLanguage(user.LanguageID);
      // Set current language, if available
      this.$internationalisation.setCurrentLanguage(user);

      // Fetch the stock labels if we are logged in
      //
      const [stockLabelsAction] = getStockLabels.createFetchAction();

      store.dispatch(stockLabelsAction);

      // Everytime we are logged in, we want to re-fetch the user task counts.
      //
      this.$userTask.callGetUserTaskCounts();

      this.$selectedOrganisationUnit.setLastUsedOrganisation(user.CurrentOrganizationID);
    });
  }

  private listenToOrganizationUnitChanges(): void {
    // No take until needed as this subscription will be alive as long as the application is.
    //
    this.$appConfig.organizationUnitCulture$
      .pipe(
        withLatestFrom(getCurrentUser.getState$()),
        filter(([, userState]) => !userState.isAnonymous),
        map(([organizationUnitCulture]) => organizationUnitCulture),
        map(organizationUnitCulture => this.$internationalisation.getLocaleValue(organizationUnitCulture)),
      ).subscribe(locale => {
        this.logger.log(`Locale changed to ${locale}🌏`);
        // Set the locale in the internationalisation provider
        //
        this.$internationalisation.setLocale(locale);
        // Initialize locale-aware formatting for datetime - momentjs
        //
        this.$moment.setLocale(locale);
      });
  }

  /**
   * The application could open with endpoint as query parameter, we will only be supporting this in web as on native we will be making use of deep linking
   */
  getEndpointQueryParameter(): string|null {
    if (this.platform.is('capacitor')) {
      return;
    }

    const endpoint = new URLSearchParams(new URL(window.location.href).search).get('endpoint');

    return endpoint;
  }

  initialiseDeepLinking() {
    App.addListener('appUrlOpen', (data) => {
      this.zone.run(() => {
        // data will look like this on iOS
        // {url: "io.newblack.eva.app://environment-override?endpoint=https%3A%2F%2Fapi.fth.test.eva-online.cloud", iosOpenInPlace: "", iosSourceApplication: ""}
        const url = new URL(data.url);

        if (url.hostname === 'environment-override') {
          const endpoint = new URLSearchParams(url.search).get('endpoint');

          this.navCtrl.navigateRoot(['environment-override'], {
            queryParams: { endpoint: endpoint }
          }).then(() => {
            this.logger.log('navigated..');
          }).catch( error => {
            this.logger.error('error calling navigateForward', error);
          });
        } else if (url.hostname === 'open-id') {
          this.$openIdAuth.handleDeepLinking(data.url);
        }
      });
    });
  }

  onRouterOutletActivate(event: Object) {
    this.logger.log('onRouterOutletActivate', event);
    // TabsPage is always activated when any child in it is navigated to, this introduces problems as it never has a scanner
    // and will trigger a wrong pause of the scanner.
    //
    const isTabsPage = event.constructor === TabsPage;

    if ( !isTabsPage ) {
      this.$barcodeScanner.onPageNavigation(event);
    } else {
      this.logger.log('onRouterOutletActivate ignoring navigation to TabsPage');
    }
    this.$userTaskAssigneeChange.onRouteChange(event);
  }

  private async initialiseContentFull() {
    const contentfulClientParams: CreateClientParams = {
      space: await this.$appConfig.contentfulSpaceId$.pipe(first()).toPromise(),
      accessToken: await this.$appConfig.contentfulAccessToken$.pipe(first()).toPromise()
    };

    if (!contentfulClientParams.space || !contentfulClientParams.accessToken) {
      this.logger.warn('Aborting contenful initialise because either the space or accessToken is missing for this environment');

      return;
    }

    this.$contenful.initialise(contentfulClientParams);
  }

  initialiseIonInputFocusFix() {
    Device.getInfo().then(data => {
      // We only want this for iOS
      //
      if ( data.operatingSystem !== 'ios' ) {
        return;
      }

      // The iOS version is not valid semver, so will simply split the version on the dot and take the first "value"
      // so
      // 12.4.1 => would give 12
      // 14.8 => would give 14
      const [iosMajorVersion] = data.osVersion.split('.');

      const majorVersion: number = parseInt(iosMajorVersion);

      // This fix is only for iOS 12
      //
      if ( majorVersion === 12 ) {
        this.ionInputFocusFixService.activate();
      }
    }).catch( error => {
      this.logger.error('error in initialiseIonInputFocusFix', error);
    })
  }
}
