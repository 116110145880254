import { Injectable } from '@angular/core';
import { Logger, ILoggable } from '../../shared/decorators/logger';
import { InterLogisticsSwitchProvider } from '../inter-logistics-switch/inter-logistics-switch';

export type BasketPageType = 'sales' | 'rma' | 'interbranch';

@Logger('[basket-type-provider]')
@Injectable()
export class BasketTypeProvider implements ILoggable {

  public logger: Partial<Console>;

  public constructor(
    private $interLogistics: InterLogisticsSwitchProvider
  ) {

  }

  public async getBaskedPageBasedOnOrder(): Promise<BasketPageType> {
    const { isRmaOrder, isInterBranchOrder, isSalesOrder } = await this.checkOrderType();

    let currentBasketPageType: BasketPageType;

    if (isRmaOrder) {
      currentBasketPageType =  'rma';
    }

    if (isInterBranchOrder) {
      currentBasketPageType =  'interbranch';
    }

    if (isSalesOrder) {
      currentBasketPageType =  'sales';
    }

    this.logger.log(`Current order basket page is: ${currentBasketPageType}`);

    return currentBasketPageType;
  }

  /**
   * Return an object that describes if the current order is:
   *
   * * RMA
   * * Interbranch
   * * Normal (sales)
   */
  private async checkOrderType() {
    const isRmaOrder = await this.$interLogistics.isRma;
    const isInterBranchOrder = await this.$interLogistics.isInterbranch;
    const isSalesOrder = !isRmaOrder && !isInterBranchOrder;

    return {
      isRmaOrder, isInterBranchOrder, isSalesOrder
    };
  }
}
