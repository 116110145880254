import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { getCurrentUser } from '@springtree/eva-sdk-redux';
import { isNil } from 'lodash';
import { filter, take } from 'rxjs/operators';
import { ILoggable, Logger } from '../../shared/decorators/logger';

@Logger('[auth-guard-service]')
@Injectable()
export class AuthGuardService implements CanActivate, ILoggable {
  logger: Partial<Console>;

  constructor(private navCtrl: NavController) {}

  async canActivate() {
    const userState = await getCurrentUser
      .getState$()
      .pipe(
        filter((state) => !state.isFetching),
        take(1),
      )
      .toPromise();

    const shouldEnter = !isNil(userState.response) && !userState.isAnonymous;

    this.logger.log('shouldEnter = ', shouldEnter);

    if (!shouldEnter) {
      try {
        this.navCtrl.navigateRoot(['login'], { replaceUrl: true });
      } catch ( error ) {
        this.logger.error('error navigating to login page', error);
      }
    }

    return shouldEnter;
  }
}
