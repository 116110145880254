<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'switch.gift' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <eva-product-discount-options-content
    [orderLineId]="orderLineId"
    (onSelectDiscountOption)="selectDiscountOption($event)"
  ></eva-product-discount-options-content>
</ion-content>
<ion-footer class="ion-no-shadow">
  <ion-button expand="block" color="primary" (click)="chooseDiscountOption()">
    <span>{{ 'action.confirm' | translate }}</span>
  </ion-button>
</ion-footer>
