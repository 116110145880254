import { Injectable } from '@angular/core';
import { isNil, cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { ILoggable, Logger } from '../../shared/decorators/logger';

export interface IPartialShipmentSelection {
  orderId: number;
  lines: EVA.Core.ShipOrder.ShipmentOrderLine[];
}

export interface IPartialShipmentSelectionChange {
  [orderLineId: number]: number;
}

/**
 *
 * This class will be responsible for storing the selected lines that the user would like to ship
 *
 * @see https://eva2015.atlassian.net/browse/OPTR-3523
 */
@Logger('[partial-shipments-provider]')
@Injectable()
export class PartialShipmentsProvider implements ILoggable {
  logger: Partial<Console>;

  private defaultSelectedLinesToShip: IPartialShipmentSelection = {
    lines: [],
    orderId: null
  };

  private selectedLinesToShip$: BehaviorSubject<IPartialShipmentSelection> = new BehaviorSubject(this.defaultSelectedLinesToShip);

  setLinesToShip(orderId: number, lines: EVA.Core.ShipOrder.ShipmentOrderLine[]) {
    this.selectedLinesToShip$.next({
      lines,
      orderId
    });
  }

  updateLinesToShip(orderId: number, selectionChange: IPartialShipmentSelectionChange) {
    const currentSelection = this.selectedLinesToShip$.value;

    if (!isNil(orderId) && currentSelection.orderId !== orderId) {
      this.logger.error('Attempt to update shipment lines with wrong order', orderId);
      return;
    }

    const newSelection = cloneDeep(currentSelection);


    for ( const [orderLineId, newQuantityToShip] of Object.entries(selectionChange) ) {
      const matchingSelectionLine = newSelection.lines.find(orderLineSelection => orderLineSelection.OrderLineID === +orderLineId );

      matchingSelectionLine.QuantityShipped = newQuantityToShip;
    }

    this.selectedLinesToShip$.next(newSelection);

  }

  getShipmentSelection(orderId: number): BehaviorSubject<IPartialShipmentSelection> {
    const selectedLinesToShip = this.selectedLinesToShip$.value;
    // Mismatching order ids will cause us to clean the memory. So we have to make sure the consumer is not accidentally doing so by passing in a nil order id
    //
    if ( isNil(orderId) ) {
      return new BehaviorSubject(this.defaultSelectedLinesToShip);
    }

    /**
     * Means consumer is not interested in the selection of the previous order no more so we will purge it. Perhaps we don't want this in the future in case
     * we want to support selections in both ODP and cart at the same time. But to not pollute the memory for now we will keep this implementation as is
     */
    if ( !isNil(selectedLinesToShip.orderId) && selectedLinesToShip.orderId !== orderId ) {
      selectedLinesToShip.lines = [];
      selectedLinesToShip.orderId = orderId;

      this.selectedLinesToShip$.next(selectedLinesToShip);
      this.logger.warn(`Selected lines for partial shipping reset due to mismatch between requested order id and selection's order id in memory`);
    }

    return this.selectedLinesToShip$;
  }
}
