import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

/**
 * Sometimes we want to blur the application, this provider will be responsible for just that
 */
@Injectable()
export class AppBlurProvider {

  private blurStream$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  enable() {
    this.blurStream$.next(true);
  }

  disable() {
    this.blurStream$.next(false);
  }

  getBlurStream(): Observable<boolean> {
    return this.blurStream$.asObservable();
  }

}
