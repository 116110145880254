import { Injectable } from '@angular/core';
import { Logger, ILoggable } from '../../shared/decorators/logger';
import { ApplicationSetting } from '../eva-application-config/eva-application-config';

/**
 *
 * This service will be responsible for saving diagnostic information about the application
 */
@Logger('[diagnostics-provider]')
@Injectable()
export class DiagnosticsProvider implements ILoggable {
  logger: Partial<Console>;

  /** This will contain all the information about a given application setting */
  public applicationSettingRegistery: ApplicationSetting<any>[] = [];

  constructor() { }

  /** We will save which application config key is being used and some meta data about said setting */
  saveApplicationConfigurationKey<T>(applicationSetting: ApplicationSetting<T>) {
    this.applicationSettingRegistery.push(applicationSetting);
  }

}
