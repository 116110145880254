import { Directive, HostListener, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProductGalleryModal } from 'src/app/modals/product-gallery-modal/product-gallery.modal';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import isRequired from '../../shared/decorators/members/is-required';

/**
 * This directive will be responsible to open the product gallery modal, it will open the modal with a product id as input
 * and let the modal take it over from there
 */
@Logger('[product-gallery-navigator-directive]')
@Directive({
  selector: 'eva-img[product-gallery-navigator]'
})
export class ProductGalleryNavigatorDirective implements ILoggable {
  @isRequired
  @Input() productId: number;

  public logger: Partial<Console>;

  constructor(private modalCtrl: ModalController) {
    this.logger.log('constructed');
   }

  @HostListener('click') async onclick() {
    this.logger.log('opening the modal...');

    const productGalleryModal = await this.modalCtrl.create( {
      component: ProductGalleryModal,
      componentProps: {
        productId: this.productId,
      },
      cssClass: 'full-screen'
    });

    productGalleryModal.present();
  }

}
