import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { getCurrentUser } from '@springtree/eva-sdk-redux';
import { get, isEmpty, isNil } from 'lodash';
import { filter, map, take } from 'rxjs/operators';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { ReturnToSupplierTaskProvider } from 'src/app/pages/return-to-supplier-task/return-to-supplier-task.service';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import isNotNil from '../../shared/operators/isNotNil';
import resumeScan from '../../shared/operators/resumeScan';
import { StockReplenishmentType } from '../../shared/typings';
import { IncomingShipmentsProvider } from '../incoming-shipments/incoming-shipments';
import { ScanPageProvider } from '../scan-page/scan-page';
import { ShipFromStoreProvider } from '../ship-from-store/ship-from-store.service';
import { IViewTask, UserTask } from '../user-task/types';

export interface ISelectTaskParameters {
  task: IViewTask;
}

export type TCompositeTaskHandler = (opts: { compositeTask: IViewTask }) => any;

interface ICompositeHandlerMapping {
  handler: TCompositeTaskHandler;
  taskTypeName: string;
  i18nFailKey: string;
}

interface IOpenRunnerTaskListPageOpts {
  navCtrl: NavController;
  userTaskIds: number[];
  zone?: string;
}

/*
 *
*/
@Logger('[task-selection-provider]')
@Injectable()
export class TaskSelectionProvider implements ILoggable {
  public logger: Partial<Console>;

  public constructor(
    private $translate: TranslateService,
    private $scanPage: ScanPageProvider,
    private $shipFromStore: ShipFromStoreProvider,
    private zone: NgZone,
    private $incomingShipments: IncomingShipmentsProvider,
    private toastCtrl: EvaToastController,
    private alertCtrl: AlertController,
    private activatedRoute: ActivatedRoute,
    private navCtrl: NavController,
    private $returnToSupplierTask: ReturnToSupplierTaskProvider
  ) {

  }
  public openRunnerTaskListPage(opts: IOpenRunnerTaskListPageOpts ) {
    const path = opts.zone ? `runner-task-list/${opts.zone}` : 'runner-task-list';

    opts.navCtrl.navigateForward([path], {
      queryParams: {
        userTaskIDs: JSON.stringify(opts.userTaskIds || []),
      }
    });
  }

  public async navigateToTask(pageName: string, taskId: number) {
    try {
      this.logger.log(' ActivatedRoute', this.activatedRoute);

      await this.navCtrl.navigateForward([`${pageName}/${taskId}`]);
      this.logger.log(`navigated to ${pageName}`);
    } catch ( error ) {
      this.logger.error(`error navigating to ${pageName}`, error);
    }
  }

  public async openRunnerTaskDetails(userTaskIDs: number[], type: StockReplenishmentType, navCtrl: NavController ) {
    try {
      await navCtrl.navigateForward(['runner-task-details'], {
        queryParams: {
          userTaskIDs: JSON.stringify(userTaskIDs || []),
          type
        }
      });
    } catch (error) {
      this.logger.error('Error navigating to runner task list', error);
    }
  }

  public async selectTask( parameters: ISelectTaskParameters ) {

    const currentUserId = await this.getCurrentUserId();

    const {task} = parameters;

    // For the task has already been started by someone alert
    // we want to look at the task that would be rendered on the task card, and that is either the task provided to this method in most cases
    // but in the case of CompositeUserTask that is the first child task
    // @see https://n6k.atlassian.net/browse/OPTR-15732
    //
    const childTask = !isEmpty(task.Children) ? task.Children[0] : task;

    const performTaskSelection = () => {
      // We are purposefully not starting the childTask here
      // as the CompositeTask needs to be started & the code will be starting the child task correctly at a later stage
      //
      this.doSelectTask(parameters.task);
    }

    // This means this task has already been started by someone else
    //
    if ( !isNil(childTask.User) && currentUserId !== childTask.User.ID ) {
      const alert = await this.alertCtrl.create({
        header:    this.$translate.instant('task.already.started'),
        message:  this.$translate.instant('task.already.started.description', { username: childTask.User.FullName }),
        buttons: [{
          text:   this.$translate.instant('action.cancel'),
          handler: () => this.logger.log('user pressed on cancel')
        }, {
          text: this.$translate.instant('action.start'),
          handler: performTaskSelection
        }]
      });

      alert.present();

    } else {
      // Task not started by anyone else, we can just go ahead and execute task selection logic
      //
      performTaskSelection();
    }
  }

  private async doSelectTask(task: IViewTask) {
    switch ( get(task, 'Type.Name')) {
      case 'StockReservation':
        this.navigateToTask('stock-reservation', task.ID);
        break;
      case 'ReservationCleanup':
        this.navigateToTask('reservation-cleanup', task.ID);
        break;
      case 'StockReplenishment':
        // There is a bug in VirtualScroll that causes the code to run outside of angular
        //
        this.zone.run(() => {
          this.handleStockReplenishmentTask(task);
        });
        break;

      case 'ReceiveShipment':
        this.handleShipmentTask(task);
        break;

      case 'SecondChanceProductRegistration':
        this.handleSecondChanceProductsTask(task);
        break;

      case 'ZonedCycleCount':
        if ( !isNil(task.Data['ZonedCycleCountID']) ) {

          this.navigateToTask('cycle-count-confirmation', task.ID);
        } else {
          this.logger.error(`attempt to navigate to task id ${task.ID} failed because we couldnt find the correspending zonedCycleCount id locally`);
        }
        break;
      case 'ZonedCycleCountPreCount':
        this.navigateToTask('cycle-count-pre-count', task.ID);
        break;

      case 'StockMovementFollowUp':
        this.navigateToTask('stock-movement-follow-up', task.ID);
        break;

      case 'ShipFromStore':
        this.$shipFromStore.navigate({
          taskId: task.ID,
          subtypeName: task.SubType.Name
        });
        break;
      case 'CompositeUserTask':
        this.handleCompositeUserTask(task);
        break;
      case 'FullStockCount':
        this.navigateToTask('full-stock-count-confirmation', task.ID);
        break;
      case 'FullStockCountLabel':
        const isRecount: boolean = get(task, 'Data.IsRecount');
        if (isRecount) {
          this.navigateToTask('full-stock-count-recount', task.ID);
        } else {
          this.navigateToTask('full-stock-count-label', task.ID);
        }
        break;
      case 'Print':
        this.navigateToTask('print-task', task.ID);
        break;
      case ('ReturnToSupplier'):
        this.$returnToSupplierTask.handleReturnToSupplierTask(task, this.navCtrl);
      break;
      case 'PrintPriceLabel' :
        this.navigateToTask('print-price-label-task', task.ID);
      break;

      default:
        this.logger.log('Unsupported task type: \'', get(task, 'Type.Name'), '\' with ID:', get(task, 'Type.ID'));
        alert('Unsupported task');
        break;
    }
  }

  /**
   * Handle damaged products task
   * @see https://n6k.atlassian.net/browse/OPTR-19434
   */
  private async handleSecondChanceProductsTask(task: Partial<IViewTask>){
    const productID = task.Data?.ProductID;
    const userTaskID = task.ID;

    if(productID && userTaskID){
      this.navCtrl.navigateForward([`second-chance-product/${userTaskID}/${productID}`]);
    } else {
      this.toastCtrl.showMessage('Error: Missing ProductId or TaskId');
    }
  }

  private async handleShipmentTask(task: Partial<IViewTask>) {
    const backendId = task.Description;

    try {
      const scan = await this.$scanPage.openPage({
        navCtrl: this.navCtrl,
        title: this.$translate.instant('incoming.shipments.scan.backend.id', { backendId }),
        matrixScanningActive: true,
        matrixScanningTarget: backendId,
        typeOfScan: 'NONE'
      });

      scan.relatedScan$$.pipe(
        resumeScan(),
      )
      .subscribe( async barcodeResult => {
        const parsedBarcode = barcodeResult.parsedData.Value;
        if ( parsedBarcode === backendId ) {
          this.$scanPage.dismissPage(this.navCtrl);
          const shipment = await this.$incomingShipments.fetchCompleteShipment(barcodeResult);
          const shipmentID = shipment.WorkSet.ShipmentID;

          if (!isNil(shipment)) {
            this.$incomingShipments.navigateToEditPage(
              this.navCtrl,
              shipment,
              shipmentID,
              task.ID
            );
          }
        } else {
          this.toastCtrl.showMessage(
            this.$translate.instant('incoming.shipments.scan.backend.id.fail')
          );
        }
      });

      scan.unrelatedScan$$.subscribe(() => {
        this.toastCtrl.showMessage(
          this.$translate.instant('scan.unrelated.scan')
        );
      });

    } catch (e) {
      this.logger.error('error opening the incoming shipment task');
    }
  }

  private async handleCompositeUserTask(task: IViewTask) {
    if ( isEmpty(task.Children) ) {
      this.logger.warn('a composite task with no children was started');
      return;
    }

    const compositeTasksMapping: Array<ICompositeHandlerMapping> = [{
      taskTypeName: 'ShipFromStore',
      handler: this.$shipFromStore.next,
      i18nFailKey: 'ship.from.store.start.fail'
    }];

    const [firstCompositeChildTask] = task.Children;

    const matchingCompositeTaskMapping = compositeTasksMapping.find(compositeTaskMapping => {
      return compositeTaskMapping.taskTypeName === firstCompositeChildTask.Type.Name;
    });

    if (matchingCompositeTaskMapping) {
      try {
        await matchingCompositeTaskMapping.handler({
          compositeTask: task
        });
      } catch {
        this.toastCtrl.showMessage(
          this.$translate.instant(matchingCompositeTaskMapping.i18nFailKey)
        );
      }
    }
  }

  private async handleStockReplenishmentTask(task: Partial<IViewTask>) {
    const availableTasks = get (task, 'AvailableTasks', []) as UserTask[];
    const bundledRunnerTasks = !isEmpty(availableTasks);
    if (bundledRunnerTasks) {
      if ( availableTasks.length === 1 ) {
        // If there is only one task, it makes no sense to go to the list page. So we will go to the details page
        //
        const userTaskIds: number[] = availableTasks.map(availableTask => availableTask.ID);

        this.openRunnerTaskDetails(userTaskIds, StockReplenishmentType.default, this.navCtrl);
      } else {
        const userTaskIds = availableTasks.map( availableTask => availableTask.ID );
        this.openRunnerTaskListPage({
          navCtrl: this.navCtrl,
          userTaskIds
        });
      }
    } else {
      // Just open the task
      //
      const stockReplenishmentType: StockReplenishmentType =
          task.HasUrgency
            ? StockReplenishmentType.priority
            : StockReplenishmentType.default;

      this.openRunnerTaskDetails([task.ID], stockReplenishmentType, this.navCtrl);
    }
  }

  private async getCurrentUserId() {
    const currentUserPromise = getCurrentUser.getState$().pipe(
      filter( state => state.isAnonymous === false ),
      map( state => state.response ),
      isNotNil(),
      map( response => response.User ),
      take(1)
    ).toPromise();

    const currentUser = await currentUserPromise;

    return currentUser.ID;
  }
}
