import { filter } from 'rxjs/operators';
import { isNil } from 'lodash';
import { Observable } from 'rxjs';

/** This will ensure that a given stream is not nil */
export default function isNotNil<T>() {
  return (source: Observable<T>) => {
    return source.pipe(
      filter(value => !isNil(value))
    );
  };
}
