import { MissingTranslationHandlerParams, MissingTranslationHandler } from '@ngx-translate/core';
import { Inject } from '@angular/core';
import { DEFAULT_LOCALE } from './modules/injection-tokens';
import { isEmpty } from 'lodash';
import { languageFromLocale } from './utils/language';

/**
 *
 * If a translation is missing, and a full locale (language_country) was used we want to fallback to the language
 * otherwise we will fallback to the default language
 */
export class MissingTranslationHandlerImplementation implements MissingTranslationHandler {
  constructor(@Inject(DEFAULT_LOCALE) private defaultLocale: string) {}

  handle(params: MissingTranslationHandlerParams) {

    const translationInBaseLanguage = this.getTranslationFromLocale(params, params.translateService.currentLang || params.translateService.defaultLang );

    const translationInBaseLanguageExists = this.verifyTranslationExists(params.key, translationInBaseLanguage);

    if ( translationInBaseLanguageExists ) {
      return translationInBaseLanguage;
    } else {
      // If the translation doesn't exist in the base language, use the default locale language
      //
      const translationInDefaultLanguage = this.getTranslationFromLocale(params, this.defaultLocale);

      return translationInDefaultLanguage;
    }
  }

  /** Will attempt to get the translation for a locale, with a fallback to base language */
  getTranslationFromLocale(params: MissingTranslationHandlerParams, locale: string) {

    const baseLanguage = languageFromLocale(locale);

    // Getting the translations for the base language
    //
    const translations = params.translateService.translations[baseLanguage];

    if (!translations) {
      return params.key;
    } else if (translations[params.key]) {
      const languageTranslation = params.translateService.getParsedResult(translations, params.key, params.interpolateParams);

      return languageTranslation;
    }
  }

  verifyTranslationExists(key: string, translation: string ) {
    const translationExists = translation && key !== translation;

    return translationExists;
  }
}
