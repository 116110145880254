<ion-row class="product-configuration" *ngIf="product" @fadeInOut>
  <ion-col size="auto" class="ion-align-self-start">
    <ion-row>
      <eva-img
        product-gallery-navigator
        [guid]="product.blobGUID"
        [productId]="product.id"
        [width]="300" [height]="300">
      </eva-img>
    </ion-row>
  </ion-col>
  <ion-col class="ion-padding-start">
    <ion-row>
      <div class="title">
        <span>{{product.title}}</span>
        <ng-container *ngIf="showProductStatus$ | async">
          <br><eva-product-status-chip [status]="product.status"></eva-product-status-chip>
        </ng-container>
      </div>
    </ion-row>

    <div class="bottom-container">
      <ion-row class="ion-justify-content-between ion-align-items-center">
        <small class="custom-id">{{product.customID}}</small>
        <ng-content select=".product-variant, .product-variants, eva-quantity-controls"></ng-content>
      </ion-row>
    </div>
  </ion-col>
</ion-row>

<ion-row class="skeleton" @fadeInOut *ngIf="!product">
  <ion-col size="auto" class="ion-align-self-start">
    <div class="img"></div>
  </ion-col>
  <ion-col>
    <ion-row>
      <div class="title"></div>
      <div class="subtitle"></div>
    </ion-row>

    <div class="bottom-container">
      <small class="custom-id"></small>
    </div>
  </ion-col>
</ion-row>

