<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="!(isRequirementsSerial$ | async)">
      {{ 'custom.requirements' | translate }}
    </ion-title>
    <ion-title *ngIf="isRequirementsSerial$ | async">
      {{ 'serial.number' | translate }}</ion-title
    >
  </ion-toolbar>
</ion-header>
<ion-content>
  <ng-container
    *ngIf=" !(loading$ | async) && (!(productRequirements$ | async) | isEmpty) "
  >
    <eva-product-complex-card
      class="ion-padding-horizontal ion-padding-top"
      [product]=" productDetail$ | async "
    >
    </eva-product-complex-card>

    <hr/>

    <eva-order-line-requirement
      (orderLineRequirementsChange)="orderLineRequirementsChange($event)"
      [orderLineId]="orderLineId"
      [productRequirementsModel]="orderLineProductRequirementsModel$ | async"
    >
    </eva-order-line-requirement>
  </ng-container>
</ion-content>
<ion-footer>
  <ion-button
    expand="full"
    (click)="updateRequirements()"
    [disabled]="invalidRequirements$ | async"
    *ngIf="productDetail$ | async; let details"
  >
    {{ 'action.update.requirements' | translate }}
  </ion-button>
</ion-footer>
