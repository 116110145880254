<ion-header>
  <ion-toolbar>
    <ion-title> {{ productName$ | async }} </ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-slides (ionSlideDidChange)="onSlideChange()">

    <ion-slide *ngFor="let image of media$ | async; let index = index">
      <eva-img [guid]="image.blob"
        [alt]="image.name" [width]="300" [height]="300">
      </eva-img>
    </ion-slide>
  </ion-slides>

  <ng-container *ngIf="media$ | async as media">
    <eva-dots [total]="media.length" [selectedIndex]="activeIndex"></eva-dots>
  </ng-container>

  <div class="carousel">
    <eva-img
      *ngFor="let image of media$ | async; let index = index"
      (click)="setCurrentImage(index)"
      [guid]="image.blob"
      [alt]="image.name">
    </eva-img>
  </div>
</ion-content>
