import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { getOrder, store, verifyCustomer } from '@springtree/eva-sdk-redux';
import { first } from 'rxjs/operators';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { timeToShowSpinnerDefaults } from '../../shared/constants';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { IFeedback } from '../../shared/typings';
import { EvaApplicationConfigProvider } from '../eva-application-config/eva-application-config';
import { EvaErrorGeneratorProvider } from '../eva-error-generator/eva-error-generator';
import { EvaLoadingControllerProvider } from '../eva-loading-controller/eva-loading-controller';
import { OrderLifecycleService } from '../order-lifecycle/order-lifecycle.service';

/**
 * Scanned orders will need to be handled in two different places in the app, the sales page and orders list page
 * we want to centralize this to eliminate duplication of code
 */
@Logger('[eva-handle-order-scan-provider]')
@Injectable()
export class EvaHandleOrderScanProvider implements ILoggable {
  public logger: Partial<Console>;

  fastReservationEnabled$ = this.$evaApplicationConfig.fastReservationEnabled$;

  constructor(
    private toast: EvaToastController,
    private $translate: TranslateService,
    private $evaErrorGenerator: EvaErrorGeneratorProvider,
    private evaLoadingCtrl: EvaLoadingControllerProvider,
    private $evaApplicationConfig: EvaApplicationConfigProvider,
    private navCtrl: NavController,
    private $orderLifecycleService: OrderLifecycleService,
  ) { }

  public async handleOrderScan(orderId: number, navCtrl: NavController) {
    try {
      const orderResponse = await this.getOrder(orderId);

      const isAttachable = this.checkIfAttachable(orderResponse.Result);

      if (isAttachable) {
        await this.setCurrentOrder(orderId);

        const isPickUpOrder = this.isPickUpOrder(orderResponse.Result);

        if (isPickUpOrder) {
          this.handlePickUpOrderNavigation(orderId, navCtrl);
        }
      } else {
        navCtrl.navigateForward([`orders/details/${orderId}`]);
      }

    } catch (e) {
      this.logger.error('error attaching order to session', e);
    }
  }

  public async handleVerifyCustomer(orderId: number, token: string, navCtrl: NavController) {
    const loader = await this.evaLoadingCtrl.create({
      timeToShowSpinner: timeToShowSpinnerDefaults.long,
      debugInformation: {
        className: 'EvaHandleOrderScanProvider',
        methodName: 'handleVerifyCustomer'
      }
    });

    const [action, fetchPromise] = verifyCustomer.createFetchAction({
      OrderID: orderId,
      VerificationCode: token
    });

    store.dispatch(action);
    loader.startLoadingTimer();
    try {
      await fetchPromise;

      await this.setCurrentOrder(orderId);

      await this.handlePickUpOrderNavigation(orderId, navCtrl);
    } catch (e) {
      this.logger.error('Something went wrong verifying customer', e);

      const errorData = await this.$evaErrorGenerator.constructFailureFeedback(e, this.$translate.instant('verifying.customer.fail'));

      this.toast.create(errorData).present();
    } finally {
      loader.stopLoadingTimer();
    }
  }

  getOrder(orderId: number) {
    const [action, fetchPromise] = getOrder.createFetchAction({
      OrderID: orderId
    });

    store.dispatch(action);

    return fetchPromise;
  }

  public async setCurrentOrder( orderId: number ) {
    // Before setting the order id, we checked whether its completed or shipped
    // in that case we didn't want to set it
    //
    const [shoppingCartPromise, shoppingCartChainPromise] = this.$orderLifecycleService.setCurrentOrderById(orderId);

    const feedback: IFeedback = {
      success: this.$translate.instant( 'order.attached.to.session.success' ),
      failure: this.$translate.instant( 'order.attached.to.session.fail' )
    };

    const toast = this.toast.create();

    try {
      // We will wait for the chain promise here,
      //
      await shoppingCartPromise;

      await shoppingCartChainPromise;

      await this.navCtrl.navigateRoot('tabs/basket');

      toast.setMessage(feedback.success);
    } catch (error) {
      const errorFeedback = await this.$evaErrorGenerator.constructFailureFeedback(error, feedback.failure);

      toast.setData(errorFeedback);
    } finally {
      toast.present();
    }

    return shoppingCartPromise;
  }

  /** an order is a pick up order (c&c order) of the PickupOrganizationUnitID is different than the SoldFromOrganizationUnitID */
  public isPickUpOrder(order: EVA.Core.OrderDto) {
    const isPickUpOrder = order.PickupOrganizationUnitID !== order.SoldFromOrganizationUnitID;

    return isPickUpOrder;
  }

  private async handlePickUpOrderNavigation(orderId: number, navCtrl: NavController) {
    // @see https://n6k.atlassian.net/browse/OPTR-9059
    //
    const fastReservationEnabled = await this.fastReservationEnabled$.pipe(first()).toPromise();
    if (fastReservationEnabled) {
      return navCtrl.navigateForward([`checkout/${orderId}`]);
    }
  }

  /** Checks if an order is attachable or not before actually attaching it, otherwise go to the orders details page */
  private checkIfAttachable(order: EVA.Core.OrderDto): boolean {

    const isNotShipped = order.IsShipped === false;

    const isNotComplete = order.IsCompleted === false;

    const isNotRma = Boolean(128 & order.Characteristics) === false;

    const isNotInterbranch = Boolean(4 & order.Characteristics) === false;

    const isAttachable = isNotShipped && isNotComplete && isNotRma && isNotInterbranch;

    return isAttachable;
  }
}
