import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController, NavParams } from '@ionic/angular';
import { getProductDetail } from '@springtree/eva-sdk-redux';
import { get } from 'lodash';
import { from, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';


interface IImage {
  blob: string;
  name: string;
  type: string;
  id?: string;
}

/**
 * This component will display the gallery of the currently selected product
 */
@Component({
  selector: 'product-gallery-modal',
  templateUrl: 'product-gallery.modal.html',
  styleUrls: ['product-gallery.modal.scss'],
})
export class ProductGalleryModal implements OnInit {

  @ViewChild(IonSlides) ionSlides: IonSlides;

  public product$: Observable<any>;

  public productName$: Observable<string>;

  public media$: Observable<IImage[]>;

  public activeIndex = 0;

  public readonly productId: number = this.navParams.get('productId');

  constructor(private modalCtrl: ModalController, private navParams: NavParams) { }

  async ngOnInit() {
    const productId: number = await getProductDetail.getState$().pipe(
      filter( state => state.isFetching === false ),
      map( state => state.response ),
      map( response => get(response, 'Result.product_id') as number ),
      take(1)
    ).toPromise();

    let productResponse: Observable<EVA.Core.GetProductDetailResponse>;

    if ( productId === this.productId ) {
      productResponse = getProductDetail.getResponse$();
    } else {
      // If the current product id in the state is not matching the product id we are interested in,
      // we will fetch the product manually without mutating the store's state
      //
      const [getProductDetailAction] = getProductDetail.createFetchAction({ ID: this.productId });

      // Conversion of the promise to an observable to consume it similarly
      //
      productResponse = from(getProductDetail.fetchData(getProductDetailAction));
    }

    this.product$ = productResponse.pipe(
      filter(state => Boolean(state.Result)),
      map(state => state.Result)
    );

    this.productName$ = this.product$.pipe(
      map(product => product.display_value)
    );

    this.media$ = this.product$.pipe(
      filter(product => Boolean(product.product_media) && Boolean(product.product_media.media)),
      map(product => product.product_media.media as IImage[])
    );
  }

  async onSlideChange() {
    const currentIndex = await this.ionSlides.getActiveIndex();

    this.activeIndex = currentIndex;
  }

  public async setCurrentImage( newIndex: number ) {
    this.activeIndex = newIndex;

    this.ionSlides.slideTo(newIndex);
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }

}
