<ng-container>
  <div 
    class="dot" 
    *ngFor="let item of totalDots; let index = index" 
    #dot
    [class.active]="index === interalSelectedIndex && !isAnimating"
    [ngStyle]="dotStyle"
  >
</div>

  <div #animatable [ngStyle]="dotStyle" [hidden]="!isAnimating" class="dot animating-dot"></div>
</ng-container>
