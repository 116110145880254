import { Directive, HostListener, Optional } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ILoggable, Logger } from '../../shared/decorators/logger';

/**
 * Directive to to call dismiss on modalCtrl instance
 * @example
 * ```html
 * <ion-toolbar>
    <ion-title>Modal Title</ion-title>
    <ion-buttons slot="end">
      <ion-button navPop>
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
 * ```
 */
@Logger('[nav-pop-directive]')
@Directive({
  selector: 'ion-modal ion-toolbar ion-button[navPop], ion-modal ion-footer ion-button[navPop]'
})
export class NavPopDirective implements ILoggable {

  public logger: Partial<Console>;

  constructor(@Optional() private modalCtrl: ModalController ) { }

  @HostListener('click', ['$event']) onClick(_event: Event) {
    if (this.modalCtrl) {
      this.modalCtrl.dismiss();
    } else {
      this.logger.error('injected modalCtrl not found');
    }
  }
}
