import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { getShoppingCart, setDiscountProductOption, setPickProductDiscountOptionsForOrderLine, store } from '@springtree/eva-sdk-redux';
import { get, isNil } from 'lodash';
import { first, map } from 'rxjs/operators';
import { IViewPickableProduct } from 'src/app/components/product-discount-options-content/product-discount-options-content.component';
import { BasketService } from 'src/app/pages/basket/basket.service';
import { EvaFeedback, IExtendedFeedback } from 'src/app/shared/decorators/eva-feedback';
import { Logger } from 'src/app/shared/decorators/logger';
import isNotNil from 'src/app/shared/operators/isNotNil';

@Logger('[product-discount-option-service]')
@Injectable({
  providedIn: 'root',
})
export class ProductDiscountOptionService {
  logger: Partial<Console>;

  constructor(private $translate: TranslateService, public navCtrl: NavController, private $basket: BasketService){ }

  @EvaFeedback()
  chooseDiscountOption(orderLineId: number, possibleProduct: IViewPickableProduct | null) {
    const [action, fetchPromise] = setPickProductDiscountOptionsForOrderLine.createFetchAction({
      OrderLineID: orderLineId,
      Selection: isNil(possibleProduct) ? null : possibleProduct.ID,
    });

    store.dispatch(action);

    let productDescription = this.$translate.instant('gift-option.empty');
    if (!isNil(possibleProduct)) {
      productDescription = possibleProduct.Description;
    }

    const evaFeedback: IExtendedFeedback = {
      i18nSuccessParams: ['discount.option.choice.success', { productName: productDescription }],
      i18nFailParams: ['discount.option.choice.fail', { productName: productDescription }],
      feedbackPromise: fetchPromise
    };

    return evaFeedback;
  }

  @EvaFeedback()
  setDiscountProductOption(orderLineId: number, productId: number|null) {
    const [action, fetchPromise] = setDiscountProductOption.createFetchAction({
      OrderLineID: orderLineId,
      ProductID: productId || null
    });

    store.dispatch(action);

    const evaFeedback: IExtendedFeedback = {
      i18nSuccessParams: 'free.discount.option.set.success',
      i18nFailParams: 'free.discount.option.set.fail',
      feedbackPromise: fetchPromise
    };

    return evaFeedback;
  }

  /**
  * Maps the possible GWP product in a given tier
  */
  mapDiscountTierProducts(discountProducts: any[], tierDescription: string) {
    const discountProductsResult = discountProducts.map(product => {
      const viewProduct: IViewPickableProduct = {
        CurrencyID: product.currency_id,
        Description: product.display_value,
        Price: product.display_price || 0,
        ID: product.product_id,
        CustomID: product.custom_id,
        blobGUID: get(product, 'primary_image.blob') as string,
        tierDescription: tierDescription
      };

      return viewProduct;
    });

    return discountProductsResult;
  }

  async choosePickDiscountOption(orderLineId: number, possibleProduct: IViewPickableProduct) {
    const shoppingCart = await getShoppingCart.getResponse$().pipe(
      first(), isNotNil(),
      map(response => response.ShoppingCart)
    ).toPromise();

    const isOrderPaid: boolean = shoppingCart.IsPaid;
    const hasCommittedLines: boolean = shoppingCart.Lines.some(line => line.QuantityCommitted > 0);
    const hasReservedLines: boolean = shoppingCart.Lines.some(line => line.QuantityReserved > 0);

    if (hasReservedLines && isOrderPaid || hasCommittedLines) {
      const askConfirmation = await this.$basket.askConfirmationCancelLine();
      if(askConfirmation?.role !== 'confirm'){
        this.navCtrl.navigateBack('tabs/basket');
        return;
      }
    }
    try {
      await this.chooseDiscountOption(orderLineId, possibleProduct).feedbackPromise;
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.navCtrl.navigateBack('tabs/basket');
    }
  }
}
