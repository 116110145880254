import { Component } from '@angular/core';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { getShoppingCart } from '@springtree/eva-sdk-redux';
import { first, map, withLatestFrom } from 'rxjs/operators';
import { IViewPickableProduct } from 'src/app/components/product-discount-options-content/product-discount-options-content.component';
import { BasketService } from 'src/app/pages/basket/basket.service';
import { ProductDiscountOptionService } from 'src/app/services/product-discount-option/product-discount-option.service';
import { StockLabelProvider } from 'src/app/services/stock-label/stock-label.provider';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';
import isNotNil from 'src/app/shared/operators/isNotNil';

@Logger('[product-discount-options-modal-component]')
@Component({
  selector: 'eva-product-discount-options-modal',
  templateUrl: 'product-discount-options.modal.html'
})
export class ProductDiscountOptionsModalComponent implements ILoggable {
  public logger: Partial<Console>;

  public orderLineId = this.navParams.get('orderLineId');

  public possibleProduct: IViewPickableProduct | null;

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public navCtrl: NavController,
    private $productDiscountOptionService: ProductDiscountOptionService,
    private $stockLabel: StockLabelProvider,
    private $basket: BasketService,
  ) { }

  public async chooseDiscountOption() {

    const isOrderPaid: boolean = await getShoppingCart.getResponse$().pipe(
      first(), isNotNil(),
      map(response => response.ShoppingCart.IsPaid)
    ).toPromise();

    const hasCommittedLines: boolean = await getShoppingCart.getResponse$().pipe(
      first(),
      map(response => response.ShoppingCart.Lines),
      map((lines) =>  lines.some((line) => line.QuantityCommitted > 0))
    ).toPromise();

    const hasReservedLines: boolean = await getShoppingCart.getResponse$().pipe(
      first(),
      map(response => response.ShoppingCart.Lines),
      map((lines) =>  lines.some((line) => line.QuantityReserved > 0))
    ).toPromise();

    if (hasReservedLines && isOrderPaid || hasCommittedLines) {
      const askConfirmation = await this.$basket.askConfirmationCancelLine();
      if(askConfirmation?.role !== 'confirm'){
        return;
      }
    }
    try {
      await this.$productDiscountOptionService.chooseDiscountOption(this.orderLineId, this.possibleProduct).feedbackPromise;
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.dismiss();
    }
  }

  public dismiss() {
    this.modalCtrl.dismiss();
  }

  public selectDiscountOption(event: IViewPickableProduct) {
    this.possibleProduct = event;
  }
}
