import { Injectable } from '@angular/core';
import { logout, store, settings as sdkSettings } from '@springtree/eva-sdk-redux';
import { NavController } from '@ionic/angular';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { AlertController } from '@ionic/angular';
import { EvaApplicationConfigProvider } from '../eva-application-config/eva-application-config';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { EvaFeedback } from '../../shared/decorators/eva-feedback';
import { ConfirmAlert } from 'src/app/shared/decorators/confirm-alert';
import { SelectedOrganisationUnitProvider } from '../selected-organisation-unit/selected-organisation-unit';

@Logger('[eva-logout-provider]')
@Injectable()
export class EvaLogoutProvider implements ILoggable {
  logger: Partial<Console>;

  constructor(
    private alertCtrl: AlertController,
    private $evaApplicationConfig: EvaApplicationConfigProvider,
    private $translate: TranslateService,
    private navCtrl: NavController,
    private $selectedOrganisationUnit: SelectedOrganisationUnitProvider
  ) {
  }

  @ConfirmAlert({
    i18nTitleKey: 'logout',
    i18nMessageKey: 'logout.confirmation',
    i18nConfirmButtonKey: 'logout',
    i18nCancelButtonKey: 'action.cancel'
  })
  async logout() {
    const clockWorkedHours = await this.$evaApplicationConfig.clockWorkedHours$.pipe(take(1)).toPromise();

    if ( clockWorkedHours ) {
      this.clockOutAlert();
    } else {
      this.doLogout();
    }
  }

  private clockOutAlert() {
    this.alertCtrl.create({
      header: this.$translate.instant('clock.out.question'),
      buttons: [
        {
          text: this.$translate.instant('clock.out'),
          role: 'submit',
          handler: () => {
            this.doLogout({ClockOut: true});
          }
        },
        {
          text: this.$translate.instant('dont.clock.out'),
          role: 'cancel',
          handler: () => {
            this.doLogout({ClockOut: false});
          }
        },
      ],
    }).then(alert => alert.present());
  }

  @EvaFeedback({
    i18nFailKey: 'logout.fail'
  })
  private async doLogout(logoutRequestPayload?: Partial<EVA.Core.Logout>) {
    // We want to set current OU temporary into the headers
    // @see https://n6k.atlassian.net/browse/OPTR-23957
    const currentOU = sdkSettings.requestedOrganizationUnitID;
    const savedOU = this.$selectedOrganisationUnit.getLastUsedOrganisation();
    if(savedOU) sdkSettings.requestedOrganizationUnitID = savedOU;

    const [action, logoutPromise, chainPromise] = logout.createFetchAction(logoutRequestPayload);

    store.dispatch(action);

    try {
      await chainPromise;
    } catch ( error  ) {
      this.logger.error('Error during logout', error);
    } finally {
      if(savedOU) { sdkSettings.requestedOrganizationUnitID = currentOU; }
      this.navCtrl.navigateRoot('login');
    }

    return logoutPromise;
  }
}
