import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { listAvailableUserTasks } from "@springtree/eva-sdk-redux";
import { isNil } from "lodash-es";
import { Observable } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";
import { ILoggable, Logger } from "src/app/shared/decorators/logger";
import isNotNil from "src/app/shared/operators/isNotNil";
import { TaskResolverService } from "./task-resolver.service";

export type CompositeTaskResolverData = EVA.Core.AvailableUserTaskDto;

/**
 * This will resolve the parent composite parent task by looking at the currents task ParentID
 * */
@Logger('[composite-task-resolver-service]')
@Injectable({
  providedIn: 'root'
})
export class CompositeTaskResolverService implements Resolve<any>, ILoggable {
  logger: Partial<Console>;

  constructor(private $taskResolver: TaskResolverService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CompositeTaskResolverData|null> {
    return this.$taskResolver.resolve(route, state).pipe(
      withLatestFrom(
        listAvailableUserTasks.getResponse$().pipe(
          isNotNil(),
          map(listAvailableUserTasksResponse => listAvailableUserTasksResponse.AvailableTasks )
        )
      ),
      map( ([matchingTask, availableTasks]) => {
        // If this task has no ParentID, it means its not part of a composite task so we will return early
        //
        if ( isNil(matchingTask?.ParentID) ) {
          return null;
        }

        // If the matching task has a parentID, it means its part of a compsite task that we will try and find
        //
        const matchingParentTask = availableTasks.find( task => task.ID === matchingTask.ParentID);

        return matchingParentTask;
      })
    );
  }
}
