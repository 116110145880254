import { Injectable } from '@angular/core';
import { getAvailableServices, IReducer } from '@springtree/eva-sdk-redux';
import { first, filter, map } from 'rxjs/operators';
import { isEmpty } from 'lodash';


/** This service is responsible for communicating whether a service is available or not using getAvailableServices */
@Injectable()
export class ServiceAvailabilityProvider {

  async isAvailable(reducer: IReducer): Promise<boolean> {
    const getAvailableServicesResponse = await getAvailableServices.getState$().pipe(
      filter( state => !state.isFetching),
      first(),
      map( state => state.response)
    ).toPromise();

    if ( isEmpty(getAvailableServicesResponse?.Services) ) {
      return false;
    }

    const isAvailable = getAvailableServicesResponse.Services.some(service => service.Name.toLowerCase() === reducer.name.toLowerCase() );

    return isAvailable;
  }

}
