import { Component, Input, ElementRef, OnInit } from '@angular/core';
import { isNil } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DecimalFormatService } from 'src/app/services/decimal-format/decimal-format.service';
import { EvaApplicationConfigProvider } from '../../services/eva-application-config/eva-application-config';
import { Logger, ILoggable } from '../../shared/decorators/logger';

@Logger('[price-component]')
@Component( {
  selector:     'eva-price',
  templateUrl:  './price.component.html',
  styles: [`
    :host {
      display: inline-block;
      text-decoration: inherit;
    }
  `]
} )
export class PriceComponent implements ILoggable, OnInit {

  logger: Partial<Console>;

  @Input() price: number;

  @Input() maximumFractionDigits: number;

  private _currencyString: string;

  /** Pass `currencyID` or `currencyID`  */
  @Input()
  set currencyString(newCurrencyString: string) {
    this._currencyString = newCurrencyString;

    // If the currency input changes, we want to update the view
    //
    this.updateIntlNumberCurrency(newCurrencyString);
  }

  get currencyString() {
    return this._currencyString;
  }

  public intlNumberOpts$ = new BehaviorSubject<Intl.NumberFormatOptions>(null);

  public defaultCurrencyId$ = this.$evaApplicationConfig.currencyId$;

  constructor(private $evaApplicationConfig: EvaApplicationConfigProvider, private el: ElementRef, private $decimalFormat: DecimalFormatService) { }

  ngOnInit() {
    this.updateIntlNumberCurrency(this.currencyString);
  }

  async updateIntlNumberCurrency(currency: string) {
    // We show decimals based on the OU/Product/Giftcard currency type
    // @see https://n6k.atlassian.net/browse/OPTR-19880
    const calculatedMaximumFractionDigits = !isNil(currency) ? this.$decimalFormat.getDecimalPrecisionCurrency(currency) : 2;
    const maxDecimals = this.maximumFractionDigits ?? calculatedMaximumFractionDigits;

    const intlNumberOpts: Intl.NumberFormatOptions = {
      maximumFractionDigits: maxDecimals,
      style: 'currency',
      currencyDisplay: 'symbol'
    };

    // If the currency is null, we will use the default one
    //
    if ( isNil(currency) ) {
      const defaultCurrency: string = await this.defaultCurrencyId$.pipe(take(1)).toPromise();

      intlNumberOpts.currency = defaultCurrency;

      const domPath = this.calculateDOMPath();

      this.logger.warn('Missing currency falling back to currency of OU, path: ', domPath);
    } else {
      intlNumberOpts.currency = currency;
    }

    this.intlNumberOpts$.next(intlNumberOpts);
  }

  private calculateDOMPath() {
    // Getting the context up to the current page is good enough.
    //
    const stopElementNodeNameStart = 'page';

    let currentElement: HTMLElement = this.el.nativeElement;

    // This can be nil
    //
    if ( isNil(currentElement) ) {
      return;
    }

    /**
     * Path we will add DOM node names to, it will eventually look something like this
     * page-x > ion-content > .scroll-content > ion-row > eva-price
     */
    const domPath: string[] = [];

    // Climb up the DOM tree until we found a page.
    //
    while (!isNil(currentElement) && !currentElement.nodeName.toLowerCase().startsWith(stopElementNodeNameStart) ) {
      currentElement = currentElement.parentElement;

      domPath.push(currentElement?.nodeName?.toLowerCase());
    }

    const readableDomPath = domPath.reverse().join(' > ');

    return readableDomPath;
  }
}
