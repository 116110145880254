import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EvaApplicationConfigProvider } from 'src/app/services/eva-application-config/eva-application-config';
import { ILocalizedPrice } from '../localized-price/localized-price.component';

/**
 * This component will be responsible to show eco tax label
 * under usual price information
 * @see https://n6k.atlassian.net/browse/OPTR-23021
 */

@Component({
  selector: 'eva-eco-tax-label',
  templateUrl: 'eco-tax-label.component.html',
  styleUrls: ['./eco-tax-label.component.scss']
})
export class EcoTaxLabelComponent {

  @Input() currencyID: string;

  @Input() ecoTaxInTax: number;

  @Input() totalEcoTaxInTax: number;

  @Input() showTotal: boolean = true;

  @Input() showItem: boolean = true;

  @Input() preferredPriceDisplayMode: EVA.Core.OrderPreferredPriceDisplayMode;

  public showEcoTax$: Observable<boolean> = this.$evaAppConfig.displayEcoTaxInfo$.pipe(
    map(display => Boolean(display))
  );

  public get productEcoTaxPrice(): ILocalizedPrice {
    const productPricing: ILocalizedPrice = {
      ecoTaxInTax: this.ecoTaxInTax
    };
    return productPricing;
  }

  public get productTotalEcoTaxPrice(): ILocalizedPrice {
    const productPricing: ILocalizedPrice = {
      ecoTaxInTax: this.totalEcoTaxInTax
    };
    return productPricing;
  }

  constructor(private $evaAppConfig: EvaApplicationConfigProvider){}

}
