<ion-header>
  <ion-toolbar>
    <ion-title> {{ 'error.viewer' | translate }} </ion-title>
    <ion-buttons slot="end">
      <ion-button navPop>
        <ion-icon name="close" class="close-icon"></ion-icon>
      </ion-button >
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding-horizontal">
  <p>Type<br> <a class="error-color">{{error.Type}}</a></p>
  <p>Code<br> <a class="error-color">{{ error.Code }}</a></p>
  <p>RequestID <a class="error-color">{{ error.RequestID }}</a></p>
  <hr>
  <ion-card class="ion-margin-top ion-no-margin">
    <ion-card-content>
      <h2>Error Message</h2>
      <a class="error-color">{{ error.Message }}</a>
    </ion-card-content>
  </ion-card>
</ion-content>
<ion-footer class="ion-no-shadow" >
  <ion-button color="primary" expand="block" (click)="copyError()">
    <ion-icon name="clipboard"></ion-icon>
    Copy To Clipboard
  </ion-button>
</ion-footer>
