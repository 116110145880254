<ng-container *ngIf="cartCustomer$ | async">
  <h6 id="header">
    <ion-row class="ion-justify-content-between ion-align-items-center">
      <span class="section-header">{{ 'customer.details' | translate }}</span>
      <div *ngIf="cartCustomer$ | async">
        <ion-button fill="clear" secondary-text *ngIf="(cartCustomer$ | async) && !isRefundOrder" (click)="removeCustomer()">
          <ion-icon name="close"></ion-icon>
        </ion-button>
        <ion-button fill="clear" secondary-text *ngIf="!isRefundOrder" (click)="attachCustomer()">
          <ion-icon name="search"></ion-icon>
        </ion-button>
        <ion-button fill="clear" secondary-text (click)="editCustomer()">
          <ion-icon name="create"></ion-icon>
        </ion-button>
      </div>
    </ion-row>
  </h6>
  <eva-user-details-card
    [user$]="cartCustomer$"
    [userCompany]="customerCompany$ | async">
  </eva-user-details-card>

  <eva-customer-preferred-fields [customer]="cartCustomer$ | async"></eva-customer-preferred-fields>

  <eva-user-cards [customerID]="cartCustomerID$ | async"></eva-user-cards>
</ng-container>
