import { Directive, InjectionToken, Inject, Optional, Self, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { isNil, isEmpty } from 'lodash';

/** the interface consumers will have to adhere to */
export interface IFormTouch {
  form: FormGroup;
  /** This is for the `eva-edit-address` as its not always required, in that case the touching of controls is not needed */
  isRequired?: boolean;
}

/** This is the token that consumers will have to provide in order to have their controls touched on the submission of the parent form  */
export const FORM_TOUCH = new InjectionToken<IFormTouch>('form.touch');

/**
 * For customer creation and editing, we use multiple components that implement control value accessor. `eva-edit-customer`, `eva-edit-address` and `eva-edit-company`
 * whenever the page's form is submitted, we want to mark the controls in those components as touched and dirty so they show their validation. Tradionally traditionally we had two seperate implementations in
 * `eva-edit-customer`, `eva-edit-address` but this directive will harness angular's dependency injections power.
 * This will be using the same technique angular uses internally to make `ControlValueAccessor` and `NG_VALUE_ACCESSOR` work with the forms API.
 */
@Directive({
  selector: 'eva-edit-customer, eva-edit-address, eva-edit-company, eva-custom-field-form-element'
})
export class FormTouchDirective implements OnInit, OnDestroy {

  private stop$ = new Subject<void>();

  constructor(
    /** This will give us access to the componnets */
    @Optional() @Self() @Inject(FORM_TOUCH) private formTouchDirective: IFormTouch,
    /** This will give us access to the parent form group directive. We will use this to listen to submission events */
    @Optional() private formGroupDirective: FormGroupDirective
  ) { }

  ngOnInit() {
    if (this.formGroupDirective && this.formTouchDirective) {
      this.formGroupDirective.ngSubmit
        .pipe(
          filter(() => {
            const formInvalid = !this.formTouchDirective.form.valid;

            /**
            * If all the fields are empty, we want to treat this form differently.
            * @see https://eva2015.atlassian.net/browse/OPTR-5569
            */
            const isNotEmptyForm = Object.values(this.formTouchDirective.form.value).every(value => !isEmpty(value));

            /** We want to emit this if the form is required & invalid OR filled in & invalid */
            const shouldEmit = this.formTouchDirective.isRequired && formInvalid || isNotEmptyForm && formInvalid;

            return shouldEmit;
          }),
          takeUntil(this.stop$),
        )
        .subscribe(() => {
          Object.keys(this.formTouchDirective.form.controls).forEach(controlKey => {
            const control = this.formTouchDirective.form.controls[controlKey];

            control.markAsDirty();
            control.markAsTouched({
              onlySelf: true
            });
            // To ensure status changes emits.
            //
            control.updateValueAndValidity();
          });
        });
    }
  }

  ngOnDestroy() {
    this.stop$.next();
  }

}
