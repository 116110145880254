import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Check if one of array element emissions meets a certain condition, not to be confused with the rxjs `some` method which works for multiple emissions of the same type
 *
 * @example
 *
 ```typescript
  const stream$ = combineLatest(numbersOne$, numbersTwo$).pipe(
   someInArray((number) => number > 5)
  );

  stream$.subscribe(value => console.log(value) );

  numbersOne$.next(1);
  numbersTwo$.next(1); // both smaller than 5

  numbersOne$.next(2);
  numbersTwo$.next(2); // both smaller than 5

  numbersOne$.next(7); // bigger than five
  numbersTwo$.next(3);

  numbersOne$.next(1);
  numbersTwo$.next(6); // bigger than 5

  // would output false, false, true, true
 ```
 * */
export default function someInArray<T>(predicate: (element: any) => boolean) {
  return (source: Observable<T[]>) => {
    return source.pipe(
      map((emissions: any[]) => emissions.some(predicate) )
    );
  };
}
