import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injector } from '@angular/core';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EvaToastComponent } from './eva-toast.component';
import { OverlayService } from './overlay.service';
import { EvaToastOptions } from './eva-toast-interface';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';

/**
 * Contains methods which can be used to control the toast
 * Dynamic creation of components and the insert process to the dom is inspired by angular material 2 overlays and portals
 * @see https://github.com/angular/material2/blob/b057391662db002db19b3c9c267e1487b18230cf/src/cdk/portal/dom-portal-outlet.ts#L38
 * @see https://medium.com/@caroso1222/angular-pro-tip-how-to-dynamically-create-components-in-body-ba200cc289e6
 */
@Logger('[eva-toast]')
export class EvaToast implements ILoggable {
  public logger: Partial<Console>;

  /** This will contain a reference to the component host view */
  private componentRef: ComponentRef<EvaToastComponent>;

  /** The data passed to this component */
  private data: EvaToastOptions;

  private dismissed$ = new Subject<void>();

  constructor(
    private $overlay: OverlayService,
    private opts: EvaToastOptions,
    private factoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private turnInQueue: Promise<void>,
  ) {
    this.data = this.opts;
  }

  public async present() {
    // Waiting for our turn in the queue
    //
    await this.turnInQueue;

    this.componentRef = this.factoryResolver.resolveComponentFactory(EvaToastComponent).create(this.injector);

    const componentInstance = this.componentRef.instance as EvaToastComponent;

    // We want to insert all our toasts in the eva-toasts-container
    //
    const overlay = this.$overlay.getOverlay('eva-toasts-container');
    // Inserting the component to our view
    //
    this.$overlay.insertComponent(this.componentRef, overlay);

    // Registering the view in the ApplicationRef so its inside of the component tree
    //
    this.appRef.attachView(this.componentRef.hostView);

    // Listening to dismiss events
    //
    componentInstance.onDismiss.pipe(take(1)).subscribe(() => this.dismiss());

    componentInstance.data = this.data;
  }

  /**
   * Returns a stream which will emit a single value once dismissed
   */
  public onDidDismiss(): Subject<void> {
    return this.dismissed$;
  }

  public dismiss() {
    this.appRef.detachView(this.componentRef.hostView);
    this.componentRef.destroy();

    // this (this.data.id) might need to be provided in the dismiss event to do queing
    //
    this.dismissed$.next();

    // Completing this stream so consumers dont need to do a take(1) or a first()
    //
    this.dismissed$.complete();

    // Detecting the changes
    //
    this.appRef.tick();
  }

  public setDuration(duration: number) {
    this.data.duration = duration;
  }

  public setMessage(message: string) {
    this.data.message = message;
  }

  public setTitle(title: string) {
    this.data.title = title;
  }

  /** Updates the data of the toast by merging the current data with any newly provided data */
  public setData(data: Partial<EvaToastOptions>) {
    this.data = { ...this.data, ...data };
  }
}
