<form [formGroup]="form" autocomplete="off" [hidden]="!addressRequirementsLoaded">
  <ng-container *ngIf="addressLookupControl">
    <ion-item>
      <ion-label position="floating">{{ 'address.lookup' | translate }}</ion-label>
      <ion-input [formControl]="addressLookupControl"></ion-input>
    </ion-item>
    <ion-list class="search-results" *ngIf="addressLookupSearching">
      <ion-item (click)="selectAddress(result)" *ngFor="let result of addressLookup$ | async">
        {{ result.Description }}
      </ion-item>
    </ion-list>
  </ng-container>

  <ng-container *ngIf="form.get('Street')">
    <ion-item [fill]="renderItemStyle" [hidden]="form.get('Street').disabled">
      <ion-label position="floating">{{ 'street' | translate }}</ion-label>
      <ion-input formControlName="Street"></ion-input>
    </ion-item>
    <div [val-messages]="form.get('Street')" [hidden]="form.get('Street').disabled" class="validation-container">
      <span val-message="required"> {{ validationMessages['Street'].required }} </span>
    </div>
  </ng-container>

  <ng-container *ngIf="form.get('Address1')">
    <ion-item [fill]="renderItemStyle" [hidden]="form.get('Address1').disabled">
      <ion-label position="floating">{{ 'address.one' | translate }}</ion-label>
      <ion-input formControlName="Address1"></ion-input>
    </ion-item>
    <div [val-messages]="form.get('Address1')" [hidden]="form.get('Address1').disabled" class="validation-container">
      <span val-message="required"> {{ validationMessages['Address1'].required }} </span>
    </div>
  </ng-container>

  <ng-container *ngIf="form.get('HouseNumber')">
    <ion-item [fill]="renderItemStyle" [hidden]="form.get('HouseNumber').disabled">
      <ion-label position="floating">{{ 'house.number' | translate }}</ion-label>
      <ion-input formControlName="HouseNumber"></ion-input>
    </ion-item>

    <div [val-messages]="form.get('HouseNumber')" [hidden]="form.get('HouseNumber').disabled" class="validation-container">
      <span val-message="required"> {{ validationMessages.HouseNumber.required }} </span>
    </div>
  </ng-container>

  <ng-container *ngIf="form.get('Address2')">
    <ion-item [fill]="renderItemStyle" [hidden]="form.get('Address2').disabled">
      <ion-label position="floating">{{ 'address.two' | translate }}</ion-label>
      <ion-input formControlName="Address2"></ion-input>
    </ion-item>

    <div [val-messages]="form.get('Address2')" [hidden]="form.get('Address2').disabled" class="validation-container">
      <span val-message="required"> {{ validationMessages['Address2'].required }} </span>
    </div>
  </ng-container>

  <ng-container *ngIf="form.get('ZipCode')">
    <ion-item [fill]="renderItemStyle" [hidden]="form.get('ZipCode').disabled">
      <ion-label position="floating">{{ 'zip.code' | translate }}</ion-label>
      <ion-input formControlName="ZipCode"></ion-input>
    </ion-item>
    <div [val-messages]="form.get('ZipCode')" [hidden]="form.get('ZipCode').disabled" class="validation-container">
      <span val-message="required"> {{ validationMessages.ZipCode.required }} </span>
    </div>
  </ng-container>

  <ng-container *ngIf="form.get('City')">
    <ion-item [fill]="renderItemStyle" [hidden]="form.get('City').disabled">
      <ion-label position="floating">{{ 'city' | translate }}</ion-label>
      <ion-input formControlName="City" text-capitalize autocapitalize></ion-input>
    </ion-item>
    <div [val-messages]="form.get('City')" [hidden]="form.get('City').disabled" class="validation-container">
      <span val-message="required"> {{ validationMessages.City.required }} </span>
    </div>
  </ng-container>

  <ng-container *ngIf="form.get('District')">
    <ion-item [fill]="renderItemStyle" [hidden]="form.get('District').disabled">
      <ion-label position="floating">{{ 'district' | translate }}</ion-label>
      <ion-input formControlName="District" text-capitalize autocapitalize></ion-input>
    </ion-item>
    <div [val-messages]="form.get('District')" [hidden]="form.get('District').disabled" class="validation-container">
      <span val-message="required"> {{ validationMessages.District.required }} </span>
    </div>
  </ng-container>

  <ng-container *ngIf="form.get('Subdistrict')">
    <ion-item [fill]="renderItemStyle" [hidden]="form.get('Subdistrict').disabled">
      <ion-label position="floating">{{ 'subdistrict' | translate }}</ion-label>
      <ion-input formControlName="Subdistrict" text-capitalize autocapitalize></ion-input>
    </ion-item>
    <div [val-messages]="form.get('Subdistrict')" [hidden]="form.get('Subdistrict').disabled" class="validation-container">
      <span val-message="required"> {{ validationMessages.Subdistrict.required }} </span>
    </div>
  </ng-container>

  <ng-container *ngIf="form.get('State')">
    <ion-item [fill]="renderItemStyle" [hidden]="form.get('State').disabled">
      <ion-label position="floating">{{ 'state' | translate }}</ion-label>
      <ion-input formControlName="State" text-capitalize autocapitalize></ion-input>
    </ion-item>
    <div [val-messages]="form.get('State')" [hidden]="form.get('State').disabled" class="validation-container">
      <span val-message="requiredLength"> {{ validationMessages.State.requiredLength }} </span>
      <span val-message="maxlength"> {{ validationMessages.State.maxlength }} </span>
      <span val-message="minlength"> {{ validationMessages.State.minlength }} </span>
      <span val-message="required"> {{ validationMessages.State.required }} </span>
    </div>
  </ng-container>

  <ng-container *ngIf="form.get('CountryID')">
    <eva-country-selector formControlName="CountryID"></eva-country-selector>
  </ng-container>
  <div [val-messages]="form.get('CountryID')" class="validation-container">
    <span val-message="required"> {{ validationMessages.CountryID.required }} </span>
  </div>

</form>

<!-- Show a loader while we are charging address requirements and form -->
<ion-row class="ion-justify-content-center ion-align-items-center ion-margin" *ngIf="!addressRequirementsLoaded" @slideUpDown>
  <ion-spinner></ion-spinner>
</ion-row>
