import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import InjectorWrapper from '../injector';
import { EvaBarcodeScannerProvider } from '../../modules/scanner/eva-barcode-scanner';


/**
 * ⚠️ While this can be useful in some scenarios, you sometimes want to manually resume a scan.
 * This will resume the barcode scanning events after a given period of time
 */
export default function resumeScan<T>( duration = 2000 ) {
  return (source: Observable<T>) => {
    return source.pipe(
      tap( () => {
        /** This will represent the barcode scanner provider */
        const $barcodeScanner = InjectorWrapper.getInjector().get(EvaBarcodeScannerProvider);

        setTimeout(() => $barcodeScanner.resumeScan(), duration);
      })
    );
  };
}
