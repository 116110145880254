<form [formGroup]="form">
  <ion-item [lines]="ionItemLines">
    <ng-container *ngIf="alertEmptyStations$ | async">
      <ion-label>{{ 'unsupported.stations.empty' | translate }}</ion-label>
    </ng-container>
    <ng-container *ngIf="!(alertEmptyStations$ | async)">
      <ion-label position="floating">{{ 'selected.station' | translate }}</ion-label>
      <ion-select formControlName="mainControl">
        <ion-select-option *ngFor="let station of availableStations$ | async" [value]="station.ID"> {{ station.Name }} </ion-select-option>
      </ion-select>
    </ng-container>
  </ion-item>
</form>
