import { Component, forwardRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';
import { IonModal, ModalController } from '@ionic/angular';
import { noop } from 'lodash-es';
import { FORM_TOUCH, IFormTouch } from 'src/app/directives/form-touch/form-touch';
import { CustomFieldsArraysModalComponent } from 'src/app/modals/custom-fields-arrays-modal/custom-fields-arrays.modal';
import { CustomFieldFormConfig } from 'src/app/services/user-custom-fields/user-custom-fields.service';
import { ILabel } from 'src/app/shared/typings';
type x = keyof EVA.Core.CustomFieldOptions;


type ValidationMap = {
  [name in keyof EVA.Core.CustomFieldOptions]?: {
    /** For which types of fields to use this validator */
    matchingTypes: EVA.Core.CustomFieldDataTypes[];
    validatorFn: (value: number) => ValidatorFn;
  };
};
@Component({
  selector: 'eva-custom-field-form-element',
  templateUrl: './custom-field-form-element.component.html',
  styleUrls: ['./custom-field-form-element.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomFieldFormElementComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => CustomFieldFormElementComponent),
    multi: true
  }, {
    provide: FORM_TOUCH,
    multi: false,
    useExisting: forwardRef(() => CustomFieldFormElementComponent),
  }],
})
export class CustomFieldFormElementComponent implements OnInit, ControlValueAccessor, IFormTouch {

  @Input() customField: CustomFieldFormConfig;

  @ViewChild('dateModal') dateModal: IonModal;

  @Input() position?: string = "floating";

  // For Array custom fields we want to open a modal to manage the values
  // but not always, as it's the case for order custom fields on checkout
  @Input() openModalForArrays?: boolean = true;

  get isRequired(): boolean {
    return this.customField?.Options.IsRequired;
  }

  get formControlLabel(): string {
    return this.customField?.DisplayName || this.customField?.Name;
  }

  /** Every rendered component needs a unique ID for it template modal */
  fieldUniqueId = Math.random();

  get ionModalTriggerId(): string {
    // We have to suffix this with Math.random because there could be a modal with the same id in the DOM
    // for example if the user creates a customer, then goes to the edit screen
    //
    return `ion-modal-date-open-trigger-${this.customField?.CustomFieldID}-${this.fieldUniqueId}`;
  }

  /** For DataTypeID = 2, we only want to allow numbers and no commas/dots. Otherwise the save service will return us errors */
  get inputMode(): string {
    return this.customField?.DataTypeID == 2 ? 'numeric' : null;
  }

  intlDateTimeOpts: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }

  /**
   * We need to open the modal programatically because it doesn't work if you refresh the page.
   * With this solution it opens the modal even if you refresh the page or navigate to it to edit the date.
   * @see https://n6k.atlassian.net/browse/OPTR-20315
   */
  public showDateModal(){
    this.dateModal.present();
  }

  public get formControl() {
    return this.form.get('mainCtrl') as FormControl;
  }

  form = this.fb.group({
    mainCtrl: []
  });

  /** This will be used for customfields of type enum */
  options: Array<ILabel<number>> = [];

  private onTouchedCallback: Function = noop;

  @HostListener('blur') onblur() {
    this.onTouchedCallback();
  }

  /**
   * Mapping between different possible validators, and which fields they should be used on and what angular validators
   * need to be applied to them
  */
  validationMap: ValidationMap = {
    MaximumValue: {
      matchingTypes: [EVA.Core.CustomFieldDataTypes.Decimal, EVA.Core.CustomFieldDataTypes.Integer],
      validatorFn: (max) => Validators.max(max)
    },
    MinimumValue: {
      matchingTypes: [EVA.Core.CustomFieldDataTypes.Decimal, EVA.Core.CustomFieldDataTypes.Integer],
      validatorFn: (min) => Validators.min(min)
    },
    MinimumLength: {
      matchingTypes: [EVA.Core.CustomFieldDataTypes.String, EVA.Core.CustomFieldDataTypes.Text],
      validatorFn: (min) => Validators.minLength(min)
    },
    MaximumLength: {
      matchingTypes: [EVA.Core.CustomFieldDataTypes.String, EVA.Core.CustomFieldDataTypes.Text],
      validatorFn: (max) => Validators.maxLength(max)
    },
  }

  constructor(private fb: FormBuilder, private modalCtrl: ModalController) { }

  ngOnInit() {
    if (this.isRequired) {
      this.formControl.setValidators(Validators.required);
    }

    for (const [validationKey, validatorValue] of Object.entries(this.validationMap)) {
      if (validatorValue.matchingTypes.includes(this.customField.DataTypeID)) {
        // The min/max value of this validator. We will use it to produce a validator function
        //
        const customFieldValidatorValue = this.customField.Options[validationKey];

        // Production of the validator function that we will set on the form control
        //
        const validatorFn = validatorValue.validatorFn(customFieldValidatorValue);

        this.formControl.addValidators(validatorFn);
      }
    }
  }

  public async editArrayValues(){
    const modal = await this.modalCtrl.create({
      component: CustomFieldsArraysModalComponent,
      componentProps: {
        config: { ...this.customField },
        values: [ ...(this.formControl.value || []) ]
      }
    });

    modal.present();

    modal.onDidDismiss().then(dismissEvent => {
      if(dismissEvent?.data?.ArrayValues){
        this.formControl.setValue(dismissEvent.data.ArrayValues);
      }
    });
  }

  // Convert array objects to string of values
  // @see https://n6k.atlassian.net/browse/OPTR-21573
  public getArrayValuesToDisplay(formControl) {
    const values = formControl?.value;
    if(values?.length && values[0] instanceof Object){
      return values.map(item => Object.values(item)[0]).join(', ');
    }
  }

  /**
   * For some strange reason `eva-custom-field-form-element` it doesn't recognize the touched event
   * when the time the user touch the input, but after blur it recognize the touch event.
   * So we implement this method to force mark the form as TOUCHED from ouside.
   * @see https://n6k.atlassian.net/browse/OPTR-20310
   */
  public forceMarkAsTouched(){
    this.formControl.markAllAsTouched();
  }

  writeValue(obj: any): void {
    this.formControl.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.formControl.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  }

  validate(): null|ValidationErrors {
    if ( this.formControl.valid ) {
      return null;
    } else {
      // If the field its required, we force the form to show the error
      // @see https://n6k.atlassian.net/browse/OPTR-21035
      this.forceMarkAsTouched();

      // If this control is invalid and its required by the customField definition,
      // we will add this error to the control to ensure its filled in
      return { required: true };
    }
  }

}
