import { Inject, Injectable } from '@angular/core';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { DEFAULT_LOCALE } from '../../shared/modules/injection-tokens';
import { IOrganizationUnitCulture } from '../eva-application-config/eva-application-config';

/**
 *
 * This provider will be the backbone of the intl number and date pipe
 *
 */
@Logger('[internationalisation-provider]')
@Injectable()
export class InternationalisationProvider implements ILoggable {

  logger: Partial<Console>;

  private locale$ = new BehaviorSubject<string>(this.defaultLocale);

  constructor(
    private $translate: TranslateService,
    @Inject(DEFAULT_LOCALE) private defaultLocale: string,
    private $translateLoader: TranslateLoader
  ) {
  }

  public getLocale(): BehaviorSubject<string> {
    return this.locale$;
  }

  public setLocale(locale: string): void {
    this.locale$.next(locale);
  }

  public async setCurrentLanguage(user: EVA.Core.LoggedInUserDto) {
    // We want to fetch the base language and entire locale, to ensure our missing translations handler will work synchronously
    // which is important for the usage of 'instant' in the app
    //
    const userLanguage = user.LanguageID;

    if ( user.CurrentCountryID ) {
      /** If have a country id, we have a locale therefor we will use that as our current language */
      const locale = `${userLanguage}_${user.CurrentCountryID}`;
      const isLocaleTranslationAvailable = await this.isTranslationAvailable(locale, 'locale');
      if (isLocaleTranslationAvailable) {
        // If we support the locale, we can use it
        //
        this.changeLanguage(locale);
      } else  {
        const isLanguageTranslationAvailable = await this.isTranslationAvailable(userLanguage, 'language');
        if (isLanguageTranslationAvailable) {
          // Fallback to the language
          // If the language is also not provided, we have a default language fallback set up
          //
          this.changeLanguage(userLanguage);
        }
      }
    } else {
      // If all we have is languageID we will use that as our translation current langauge
      //
      const isLanguageTranslationAvailable = await this.isTranslationAvailable(userLanguage, 'language');
      if (isLanguageTranslationAvailable) {
        this.changeLanguage(userLanguage);
      }
    }
  }

  public getLocaleValue(organizationUnitCulture: IOrganizationUnitCulture): string {
    const languageIsNil = isNil(organizationUnitCulture.LanguageID);

    const countryIsNil = isNil(organizationUnitCulture.CountryID);

    const invalidCulture = languageIsNil && countryIsNil;

    const partiallyValidCulture = languageIsNil || countryIsNil;

    if (invalidCulture) {
      this.logger.warn('Culture is invalid because language and countryID is missing');

      return this.defaultLocale;
    } else if (partiallyValidCulture) {
      this.logger.warn('Culture is invalid because either language or countryID is missing');
      // The intl API can handle language id and countryId's separately. So we will give it eiter
      //
      return organizationUnitCulture.LanguageID || organizationUnitCulture.CountryID;
    } else {
      return organizationUnitCulture.Culture;
    }
  }

  /**
   * Change language, but also set the default fallback language to english for missing translations
   */
  private changeLanguage(locale: string) {
    this.$translate.use(locale);
  }

  /**
   * Check that the translation for locale/language is available
   */
  private async isTranslationAvailable(
    languageOrLocale: string, type: 'language' | 'locale'
  ): Promise<boolean> {
    let translationAvailable = true;

    try {
      await this.$translateLoader.getTranslation(languageOrLocale).toPromise();
    } catch (error) {
      translationAvailable = false;
      this.logger.warn(
        `No translations for ${type} ${languageOrLocale}`,
        error);
    }

    return translationAvailable;
  }
}
