import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { getOrder, listAvailableUserTasks, startCompositeUserTask, startShipFromStoreTask, store, settings } from '@springtree/eva-sdk-redux';
import { cloneDeep, get, isEmpty, isNil } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, first, map, withLatestFrom } from 'rxjs/operators';
import { ILocalizedPrice } from 'src/app/components/localized-price/localized-price.component';
import { IViewProduct } from 'src/app/components/product-card-common/product-card-common';
import { ICompositeTaskProgress } from '../../components/composite-task-progress/composite-task-progress';
import { IOrderSummaryProduct } from '../../components/order-product-summary/order-product-summary';
import { IProductVariations } from '../../components/product-variations/product-variations';
import { shipFromStoreSubTypeTranslation, timeToShowSpinnerDefaults } from '../../shared/constants';
import { EvaFeedback } from '../../shared/decorators/eva-feedback';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import isNotNil from '../../shared/operators/isNotNil';
import { EvaApplicationConfigProvider } from '../eva-application-config/eva-application-config';
import { TCompositeTaskHandler } from '../task-selection/task-selection';
import { IViewTask } from '../user-task/types';

export interface IShipFromStoreNavigation {
  subtypeName: string;
  taskId: number;
  compositeTask?: IViewTask;
}

export interface IShipFromStoreViewProduct extends IViewProduct {
  orderLineID: number;
  totalQuantity?: number;
  hasMoreLines?: boolean;
}

export interface ISelectedPackagesFormValue {
  [packageId: number]: number;
}

/** This is an object we will create to check whether every item is accounted for or not */

type OrderQuantitiesMap = Map<number, number>;

interface IGetProductLinesOpts {
  taskResponse: EVA.Core.StartShipFromStoreTaskResponse;
  shipFromStoreShowNetPrices: boolean;
}

interface CalculatePricingOpts {
  orderLineId: number;
  startShipFromStoreTaskResponse: EVA.Core.StartShipFromStoreTaskResponse;
  /** if the line is already used, it can also be passed */
  startShipFromStoreTaskResponseLine?: EVA.Core.StartShipFromStoreTaskResponse.ShipFromStoreLine;
  shipFromStoreShowNetPrices: boolean;
}

interface CalculatePricingResult {
  primaryLocalizedPricing: ILocalizedPrice;
  secondaryLocalizedPricing: ILocalizedPrice;
}

/**
 *
 * We will use this provider in the ship from store pages/components
 */
@Logger('[ship-from-store-provider]')
@Injectable()
export class ShipFromStoreProvider implements ILoggable {

  logger: Partial<Console>;

  // don't want to display the blob url on preview page url
  // service will provide the url used n preview-document page
  //
  blobUrl: string;

  taskStartResponse$ = startShipFromStoreTask.getResponse$().pipe(
    isNotNil()
  );

  products$: Observable<IShipFromStoreViewProduct[]> = combineLatest([
    getOrder.getResponse$().pipe(isNotNil()),
    this.taskStartResponse$,
    this.$appConfig.shipFromStoreProductProperties$,
    this.$appConfig.shipFromStoreShowNetPrices$
  ]).pipe(
    // We want to make sure the task response order id match the order getOrder is returning us
    //
    map(([getOrderResponse, startShipFromStoreTaskResponse, productProperties, shipFromStoreShowNetPrices]) => {
      // If the fetched order doesnt match the task we are currently viewing, we will somply return an empty array
      //
      if (getOrderResponse.Result.ID != startShipFromStoreTaskResponse.OrderID) {
        return [];
      }

      const products: IShipFromStoreViewProduct[] = startShipFromStoreTaskResponse.Lines.map(line => {
        const matchingStartServiceLine = startShipFromStoreTaskResponse.Lines.find( startResponseLine => startResponseLine.OrderLineID === line.OrderLineID);
        // Depending on the setting we will be showing different prices
        // See https://n6k.atlassian.net/browse/OPTR-11145
        //
        const {primaryLocalizedPricing, secondaryLocalizedPricing} = this.calculatePricing({
          shipFromStoreShowNetPrices,
          orderLineId: line.OrderLineID,
          startShipFromStoreTaskResponse,
          startShipFromStoreTaskResponseLine: matchingStartServiceLine
        });

        const { OrderLineID, Properties, ProductID, ProductVariation } = line;

        const viewProduct: IShipFromStoreViewProduct = {
          orderLineID: OrderLineID,
          customId: get(Properties, 'custom_id'),
          description: get(Properties, 'display_value'),
          productId: ProductID,
          totalQuantity: matchingStartServiceLine.Quantity,
          blobGUID: get(Properties, 'primary_image.blob'),
          productVariations: isEmpty(ProductVariation) ? this.createProductVariations(line, productProperties) : ProductVariation,
          currencyId: get(line.Properties, 'currency_id'),
          preferredPriceDisplayMode: startShipFromStoreTaskResponse.PreferredPriceDisplayMode,
          primaryLocalizedPricing,
          secondaryLocalizedPricing,
        };

        return viewProduct;
      });

      return products;
    }),

  );

  shipmentsSelection$ = new BehaviorSubject<EVA.Core.CompleteShipFromStoreTask.Shipment[]>([]);

  /**
   * we want to make sure the user selects all products in the shipment, this object will be computed based on
   * the current shipmentSelection and all available items on the order
   */
  remainingShipmentItems$: Observable<EVA.Core.CompleteShipFromStoreTask.ShipmentLine[]> = combineLatest([
    this.products$,
    this.shipmentsSelection$
  ]).pipe(
    map(([products, shipmentsSelection]) => {
      const orderQuantitiesMap: OrderQuantitiesMap = this.createQuantitiesMap({
        items: products,
        valueProperty: 'totalQuantity',
        identefierProperty: 'orderLineID'
      });

      const shipmentSelectionItems = shipmentsSelection
        .map(shipmentSelection => shipmentSelection.Lines)
        .reduce((a, b) => a.concat(b), []);

      const shipmentsCombinedQuantitiesMap: OrderQuantitiesMap = this.createQuantitiesMap({
        items: shipmentSelectionItems,
        identefierProperty: 'OrderLineID',
        valueProperty: 'Quantity'
      });

      const remainingShipmentItems: EVA.Core.CompleteShipFromStoreTask.ShipmentLine[] = [];

      Array.from(orderQuantitiesMap.entries()).forEach(([orderLineId, totalProductQuantity]) => {
        const totalProductQuantityInShipments = shipmentsCombinedQuantitiesMap.get(orderLineId) || 0;
        /**
         * here we make the assumption that the total product quantity will be more than the product in all shipments
         * this is something we will enforce in the UI. To ensure people can not overship
         */
        const quantityRemaining = totalProductQuantity - totalProductQuantityInShipments;

        // If there is more than 1 item remaining, we will create a deviation entry
        //
        if (quantityRemaining > 0) {
          remainingShipmentItems.push({
            OrderLineID: + orderLineId,
            Quantity: quantityRemaining
          });
        }

      });

      return remainingShipmentItems;
    })
  );

  constructor(private $translate: TranslateService, private $appConfig: EvaApplicationConfigProvider, private navCtrl: NavController) { }

  getProductLines({taskResponse, shipFromStoreShowNetPrices}: IGetProductLinesOpts): IOrderSummaryProduct[] {

    if (isNil(taskResponse)) {
      return [];
    }

    const productLines = taskResponse.Lines.map(shipmentLine => {
      const { OrderLineID, Properties, Quantity } = shipmentLine;

      const productLine: IOrderSummaryProduct = {
        orderLineID: OrderLineID,
        description: get(Properties, 'display_value'),
        pricing: this.calculatePricing({orderLineId: OrderLineID, shipFromStoreShowNetPrices, startShipFromStoreTaskResponse: taskResponse}).primaryLocalizedPricing,
        totalQuantityToShip: Quantity,
        currencyId: shipmentLine.CurrencyID,
        preferredPriceDisplayMode: taskResponse.PreferredPriceDisplayMode
      };

      return productLine;
    });

    return productLines;
  }

  getOrder(taskId: number) {
    const order$ = getOrder.getResponse$().pipe(
      isNotNil(),
      withLatestFrom(
        this.taskStartResponse$.pipe(
          filter(taskResponse => taskResponse.UserTaskID === taskId)
        )
      ),
      filter(([orderResponse, taskStartResponse]) => orderResponse.Result.ID === taskStartResponse.OrderID),
      map(([orderResponse]) => orderResponse)
    );

    return order$;
  }

  getOrderIdLabel(taskId: number) {
    const orderIdLabel$: Observable<string> = this.taskStartResponse$.pipe(
      filter(response => response.UserTaskID === taskId),
      map(response => `${this.$translate.instant('order')} #${response.OrderID}`)
    );

    return orderIdLabel$;
  }

  createProductVariations(line: EVA.Core.StartShipFromStoreTaskResponse.ShipFromStoreLine, productProperties: string[]): IProductVariations {
    const productVariationObj: IProductVariations = {};
    const lineProductVariations = line.Properties || {};
    productProperties.forEach(productProperty => {
      const variationValue = lineProductVariations[productProperty];
      if (!isEmpty(variationValue)) {
        productVariationObj[productProperty] = variationValue;
        productVariationObj[`${productProperty}.name`] = variationValue;
      }
    });

    return productVariationObj;
  }

  @EvaFeedback({
    i18nFailKey: 'start.task.fail',
    timeToShowSpinner: timeToShowSpinnerDefaults.long
  })
  async starTask(taskId: number) {
    const productProperties = await this.$appConfig.shipFromStoreProductProperties$.pipe(first()).toPromise();

    const [startShipFromStoreTaskAction, startShipFromStoreTaskPromise] = startShipFromStoreTask.createFetchAction({
      UserTaskID: taskId,
      ProductProperties: [...settings.defaultProductProperties, ...productProperties]
    });

    store.dispatch(startShipFromStoreTaskAction);

    const startTaskPromises: Promise<any>[] = [startShipFromStoreTaskPromise];

    try {
      const startShipFromStoreTaskResponse = await startShipFromStoreTaskPromise;
      const productProperties = await this.$appConfig.shipFromStoreProductProperties$.pipe(first()).toPromise();

      const [getOrderAction, getOrderPromise] = getOrder.createFetchAction({
        OrderID: startShipFromStoreTaskResponse.OrderID,
        AdditionalOrderDataOptions: {
          ProductProperties: productProperties,
          IncludeProductRequirements: true,
          IncludeGiftWrapping: false,
          IncludePrefigureDiscounts: false,
          IncludePickProductOptions: false,
          IncludeAvailableShippingMethods: false,
          IncludeAvailablePaymentMethods: false,
          IncludeAvailableRefundPaymentMethods: false,
          IncludeValidateShipment: false,
          IncludePaymentTransactionActions: false,
          IncludeCheckoutOptions: false,
          IncludeOrganizationUnitData: false,
          IncludeCustomerCustomFields: true
        }
      });

      store.dispatch(getOrderAction);

      startTaskPromises.push(getOrderPromise);

      await getOrderPromise;
    } catch (error) {
      this.logger.error('error calling startShipFromStoreTask', error);
    }

    return Promise.all(startTaskPromises);
  }

  next: TCompositeTaskHandler = async ({ compositeTask }) => {
    const [action, startCompositeUserTaskPromise] = startCompositeUserTask.createFetchAction({
      UserTaskID: compositeTask.ID
    });

    store.dispatch(action);

    const startCompositeUserTaskReponse = await startCompositeUserTaskPromise;

    const taskToStart = startCompositeUserTaskReponse.Tasks.find(childTask => !childTask.IsCompleted);

    if (taskToStart) {

      const shipFromStoreToNavigation: IShipFromStoreNavigation = {
        compositeTask: compositeTask,
        subtypeName: get(taskToStart, 'SubType.Name') as string,
        taskId: taskToStart.ID
      };

      await this.navigate(shipFromStoreToNavigation);
    }
  }

  async navigate(shipFromStoreNavigation: IShipFromStoreNavigation) {
    let routeUrl: string;

    switch (shipFromStoreNavigation.subtypeName) {
      case 'Pick':
        routeUrl = 'ship-from-store-pick';
        break;
      case 'Pack':
        routeUrl = 'ship-from-store-pack';
        break;
      case 'Ship':
        routeUrl = 'ship-from-store-ship';
        break;
      case 'Deliver':
        routeUrl = 'ship-from-store-deliver';
        break;
      case 'Print':
        routeUrl = 'ship-from-store-print';
        break;
    }

    try {
      await this.navCtrl.navigateForward([`${routeUrl}/${shipFromStoreNavigation.taskId}`]);
      this.logger.log(`navigated to ${routeUrl}`);
    } catch (error) {
      this.logger.error(`error navigating to ${routeUrl}`, error);
    }
  }

  getCompositeTaskProgress(taskId: number, originalCompositeTask: EVA.Core.AvailableUserTaskDto) {

    if (isNil(originalCompositeTask)) {
      this.logger.warn('originalCompositeTask is null, returning early');

      return;
    }

    if (isEmpty(originalCompositeTask.Children)) {
      this.logger.log('composite task has no chidldren, so its not a composite task');
      return;
    }

    const taskProgress: Observable<ICompositeTaskProgress> = listAvailableUserTasks.getState$().pipe(
      filter(listAvailableUserTasksState => !listAvailableUserTasksState.isFetching),
      map(listAvailableUserTasksState => listAvailableUserTasksState.response),
      isNotNil(),
      filter((listAvailableUserTasksResponse) => listAvailableUserTasksResponse.AvailableTasks.map(task => task.ID).includes(originalCompositeTask.ID)),
      map(listAvailableUserTasksResponse => {
        const matchingCompositeTask: EVA.Core.AvailableUserTaskDto = listAvailableUserTasksResponse.AvailableTasks.find(task => task.ID === originalCompositeTask.ID);

        const currentTask = matchingCompositeTask.Children.find(task => task.ID === taskId);

        if (isNil(currentTask)) {
          this.logger.warn(`No current task found with ${taskId} in ${matchingCompositeTask.Children.map(task => task.ID).join(',')}`);
          return;
        }

        const currentTaskI18Key = shipFromStoreSubTypeTranslation[currentTask.SubType.Name];

        const compositeTaskProgress: ICompositeTaskProgress = {
          completedCount: matchingCompositeTask.Data.CompletedCount + 1,
          currentTask: this.$translate.instant(currentTaskI18Key),
          totalCount: matchingCompositeTask.Data.TotalCount,
        };

        const currentTaskIndex = matchingCompositeTask.Children.findIndex(task => task.ID === taskId);

        const nextTask = matchingCompositeTask.Children[currentTaskIndex + 1];

        if (nextTask) {
          const nextTaskSubTypeValue = nextTask.SubType.Name;

          const nextTaski18nKey = shipFromStoreSubTypeTranslation[nextTaskSubTypeValue];

          compositeTaskProgress.nextTask = this.$translate.instant(nextTaski18nKey);
          compositeTaskProgress.nextTaskSubType = nextTask.SubType.Name;
        }

        return compositeTaskProgress;
      })
    );

    return taskProgress;
  }

  /** this will add a shipment to the selection */
  addShipmentToSelection(newShipment: EVA.Core.CompleteShipFromStoreTask.Shipment) {
    const newShipmentSelection = [...this.shipmentsSelection$.value];

    newShipmentSelection.push(newShipment);

    this.shipmentsSelection$.next(newShipmentSelection);
  }

  updatePackagingInShipmentSelection(shipmentIndex: number, formValue: ISelectedPackagesFormValue) {
    /** We will make a copy of the current selection and change it locally */
    const newShipmentSelection = cloneDeep(this.shipmentsSelection$.value);

    const matchingShipment = newShipmentSelection[shipmentIndex];

    const newPackages: EVA.Core.CompleteShipFromStoreTask.ShipmentPackage[] = [];

    Object.entries(formValue).forEach(([packageId, quantity]) => {
      // create packages depending on the control value (quantity)
      //
      for (let i = 0; i < quantity; i++) {
        newPackages.push({PackageID: + packageId});
      }
    });

    matchingShipment.Packages = newPackages;

    this.shipmentsSelection$.next(newShipmentSelection);
  }

  createQuantitiesMap<T>({
    items,
    identefierProperty,
    valueProperty
  }: { items: T[], identefierProperty: keyof T, valueProperty: keyof T }): Map<any, number> {
    const orderQuantitiesMap: Map<any, number> = new Map();

    items.forEach(item => {
      const itemId = item[identefierProperty];

      const itemValue = item[valueProperty] as unknown as number;

      if (orderQuantitiesMap.has(itemId)) {
        const newQuantity = orderQuantitiesMap.get(itemId) + itemValue;
        orderQuantitiesMap.set(itemId, newQuantity);
      } else {
        orderQuantitiesMap.set(itemId, itemValue);
      }
    });

    return orderQuantitiesMap;
  }

  resetShipmentSelection() {
    this.shipmentsSelection$.next([]);
  }

  calculatePricing({orderLineId, startShipFromStoreTaskResponse, shipFromStoreShowNetPrices, startShipFromStoreTaskResponseLine}: CalculatePricingOpts): CalculatePricingResult {
    // If the matching line is provided to us, we will use that instead of looking for it in the response.
    //
    const matchingStartServiceLine = startShipFromStoreTaskResponseLine || startShipFromStoreTaskResponse.Lines.find( startResponseLine => startResponseLine.OrderLineID === orderLineId);

    let result: CalculatePricingResult

    if (shipFromStoreShowNetPrices) {
      // If we have to show net pricing, we will make sure our primary pricing is the net pricing (netTotalAmount/NetTotalAmountInTax) and our secondary pricing is the total amount
      result = {
        primaryLocalizedPricing: {
          price: matchingStartServiceLine.NetTotalAmount,
          priceInTax: matchingStartServiceLine.NetTotalAmountInTax
        },
        secondaryLocalizedPricing: {
          price: matchingStartServiceLine.TotalAmount,
          priceInTax: matchingStartServiceLine.TotalAmountInTax
        },
      };
    } else {
      // If net prices doesn't matter, we will make sure to only have primary pricing of the "totalAmount" and "totalAmountInTax"
      result = {
        primaryLocalizedPricing: {
          price: matchingStartServiceLine.TotalAmount,
          priceInTax: matchingStartServiceLine.TotalAmountInTax
        },
        secondaryLocalizedPricing: {
          price: matchingStartServiceLine.TotalAmount,
          priceInTax: matchingStartServiceLine.TotalAmountInTax
        },
      }

    }

    return result;
  }
}
