import { Injectable } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Platform } from "@ionic/angular";
import { isNil } from "lodash-es";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  prefersDarkListener: void;

  private _themingEnabled: boolean = JSON.parse(localStorage.getItem('themingEnabled'));


  public get themingEnabled(): boolean {
    return this._themingEnabled;
  }

  public set themingEnabled(value: boolean) {
    if ( value != this._themingEnabled) {
      this._themingEnabled = value;
      localStorage.setItem('themingEnabled', JSON.stringify(value));
    }
  }

  constructor(private meta: Meta, private platform: Platform) {}

  enableTheming(enable: boolean = true) {
    this.themingEnabled = enable;

    if (enable) {
      this.initialise();
      this.meta.updateTag({content: 'light dark'}, 'name="color-scheme"');
    } else {
      this.togglTheme(false);
      this.meta.updateTag({content: 'light'}, 'name="color-scheme"');
    }
  }

  initialise() {
    if (!this.themingEnabled)  {
      return;
    }
    this.meta.updateTag({content: 'light dark'}, 'name="color-scheme"');

    // See https://ionicframework.com/docs/theming/dark-mode#automatically-enable-dark-mode
    // Use matchMedia to check the user preference
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    this.togglTheme(prefersDark.matches);

    // We only want to add a listener if we dont already have one
    //
    if (isNil(this.prefersDarkListener)) {
      this.prefersDarkListener = prefersDark.addEventListener('change', (mediaQuery) => this.togglTheme(mediaQuery.matches));
    }

  }

  togglTheme(shouldAdd: boolean) {
    document.documentElement.classList.toggle('dark', shouldAdd);

    if ( this.platform.is('capacitor') ) {
      StatusBar.setStyle({
        style: shouldAdd ? Style.Dark : Style.Light
      });
    }

  }
}
