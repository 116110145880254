import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { attachCustomerToOrder, getShoppingCart, store, getAvailablePaymentMethods } from '@springtree/eva-sdk-redux';
import { NavController } from '@ionic/angular';
import { get, isNil } from 'lodash';
import { first, map, take } from 'rxjs/operators';
import { Logger, ILoggable } from '../../shared/decorators/logger';
import { EvaApplicationConfigProvider } from '../eva-application-config/eva-application-config';
import { EvaFeedback, IExtendedFeedback } from '../../shared/decorators/eva-feedback';
import { timeToShowSpinnerDefaults } from '../../shared/constants';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';

export interface ICustomerAttachOptions {
  customerId: number;
  fullCustomerName: string;
  navigateBack: boolean;
  attachmentMetadata?: string
}

interface ICheckoutStepsValidity {
  shippingAddressValid: boolean;
  billingAddressValid: boolean;
}

/**
 * There are currently two places where the customer gets attached to the order
 * the customer search result item and the customer search, this provider will ensure DRY is respected
 */
@Logger('[eva-attach-customer-to-order-provider]')
@Injectable()
export class EvaAttachCustomerToOrderProvider implements ILoggable {
  logger: Partial<Console>;

  constructor(
    private $translate: TranslateService,
    private toastCtrl: EvaToastController,
    private $evaApplicationConfig: EvaApplicationConfigProvider
  ) {
  }

  /**
   * Attaches a customer to cart
   *
   * Handles all invalidity scenarios:
   *
   * `Billing address` not valid
   * `Shipping address` not valid
   * `Loyalty state check - only if required by the caller`
   *
   * In the above cases, the user is redirected to the `Customer edit page`
   * Otherwise, the user is redirected back `only if required by the caller`
   */
  @EvaFeedback()
  public async attach(options: Partial<ICustomerAttachOptions>, navigationController: NavController) {
    // Get the customer attach options merged with default values
    //
    const customerAttachOptions = this.getCustomerAttachOptions(options);

    const cartId: number = await getShoppingCart.getResponse$().pipe(
      first((res) => !isNil(res)),
      map(res => res.ShoppingCart.ID)
    ).toPromise();

    let parameters: EVA.Core.AttachCustomerToOrder = {
      UserID: customerAttachOptions.customerId,
      OrderID: cartId
    }

    /**
     * We need extra info to be sent to BE
     * @see https://n6k.atlassian.net/browse/OPTR-18566
     */
    if(options.attachmentMetadata){
      parameters.AttachmentMetadata = options.attachmentMetadata;
    }

    const [action, promise, chainPromise] = attachCustomerToOrder.createFetchAction(parameters);

    store.dispatch(action);

    const evaFeedback: IExtendedFeedback = {
      feedbackPromise: promise,
      i18nSuccessParams: ['attach.customer.success', { customer: customerAttachOptions.fullCustomerName }],
      i18nFailParams: 'attach.customer.fail',
      timeToShowSpinner: timeToShowSpinnerDefaults.long,
    };

    try {
      // Await the promise
      //
      await promise;
      // Await the chain promise as verifyCheckoutSteps relies on the `getShoppingCart` call
      // which is part of the chain promise
      //
      await chainPromise;

      const [paymentMethodAction, paymentMethodPromise] = getAvailablePaymentMethods.createFetchAction({
        OrderID: cartId,
        ReturnUnusablePaymentMethods: true
      });
      store.dispatch(paymentMethodAction);

      await paymentMethodPromise;

      const checkoutValidity = this.verifyCheckoutSteps();
      // Handle the checkout validity
      //
      this.handleInvalidCheckoutSteps(checkoutValidity);

      // Handle whether we navigate back
      //
      this.handleNavigateBack(customerAttachOptions.navigateBack, navigationController);
    } catch (error) {
      this.logger.error('Failed to attach the customer to cart', error);
    }

    return evaFeedback;
  }

  /**
   * Handle if we navigate the user back
   *
   * We do this if we didn't open the detail page, checkout steps are all valid and the caller specifically requested to be navigated back
   */
  private handleNavigateBack(navigateBack: boolean, navigationController: NavController) {
    if (navigateBack) {
      // If we don't have any invalid steps and the navigateBack option is true
      // we can navigate the customer back to the checkout screen
      //
      navigationController.pop();
    }
  }

  /**
   * Get options merged with the default values
   */
  private getCustomerAttachOptions(options: Partial<ICustomerAttachOptions>): Partial<ICustomerAttachOptions> {
    const defaultOptions: Partial<ICustomerAttachOptions> = {
      navigateBack: false
    };

    const customerAttachOptions: Partial<ICustomerAttachOptions> = {
      ...defaultOptions,
      ...options
    };

    return customerAttachOptions;
  }

  /**
   * Checks the validity of
   *
   * `BillingAddress`
   * `ShippingAddress`
   *
   * If they exist as requirements in the context of a checkout
   */
  private verifyCheckoutSteps(): ICheckoutStepsValidity {
    const checkoutSteps = getShoppingCart.getState().checkoutStatus.steps;

    const billingAddressStepValid = get(checkoutSteps.billingAddress, 'valid', true) === true;
    const shippingAddressStepValid = get(checkoutSteps.shippingAddress, 'valid', true) === true;

    return {
      billingAddressValid: billingAddressStepValid,
      shippingAddressValid: shippingAddressStepValid
    };
  }

  /**
   * Handles user actions when either the `Shipping` or `Billing` addresses are invalid in the context of a checkout
   */
  private handleInvalidCheckoutSteps(checkoutStepsValidity: ICheckoutStepsValidity) {
    if (checkoutStepsValidity.billingAddressValid && checkoutStepsValidity.shippingAddressValid) {
      // If both steps are valid, we will return early
      //
      return;
    }

    const toastMessage = this.determineUserMessage(checkoutStepsValidity);

    const toast = this.toastCtrl.create({
      message: toastMessage,
      duration: 5000
    });

    toast.present();
  }

  /**
   * Determines the right user message based on whether the `ShippingAddress` and/or `BillingAddress` are invalid
   */
  private determineUserMessage(checkoutStepsValidity: ICheckoutStepsValidity) {
    let toastMessage: string;

    if (!checkoutStepsValidity.billingAddressValid && !checkoutStepsValidity.shippingAddressValid) {
      toastMessage = this.$translate.instant('order.billing.and.shipping.address.incomplete');
    } else if (!checkoutStepsValidity.billingAddressValid) {
      toastMessage = this.$translate.instant('order.billing.address.incomplete');
    } else if (!checkoutStepsValidity.shippingAddressValid) {
      toastMessage = this.$translate.instant('order.shipping.address.incomplete');
    }

    return toastMessage;
  }
}
