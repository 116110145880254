import { CommonModule } from '@angular/common';
import { FactoryProvider, NgModule, ValueProvider } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'src/app/components/components.module';
import { ContentfulProvider, CONTENTFUL_WHATS_NEW_CONTENT_TYPE } from './contentful';
import { WhatsNewComponentModal } from './whats-new-modal/whats-new-modal';
import { WhatsNewComponent } from './whats-new/whats-new';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    IonicModule,
    TranslateModule
  ],
  declarations: [WhatsNewComponent, WhatsNewComponentModal],
})
/** This module will contain commonly used items, and should be imported by future modules (pages in our case) */
export class WhatsNewModule {
  static forRoot(options: { contentFulWhatsNewContentType: string }) {


    return {
      ngModule: WhatsNewModule,
      providers: [
        {
          provide: ContentfulProvider,
          useFactory: (modalCtrl: ModalController, contentFulWhatsNewContentType: string) => new ContentfulProvider(modalCtrl, contentFulWhatsNewContentType),
          deps: [ModalController, CONTENTFUL_WHATS_NEW_CONTENT_TYPE]
        } as FactoryProvider,
        { provide: CONTENTFUL_WHATS_NEW_CONTENT_TYPE, useValue: options.contentFulWhatsNewContentType } as ValueProvider
      ]
    };
  }
 }
