import { Component, Input, OnInit } from '@angular/core';
import { getUserCardsForUser, store } from '@springtree/eva-sdk-redux';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import isNotNil from '../../shared/operators/isNotNil';

/**
 * This component will be responsible for rendering user cards
 */
@Logger('[user-cards-component]')
@Component({
  selector: 'eva-user-cards',
  templateUrl: 'user-cards.component.html'
})
export class UserCardsComponent implements ILoggable, OnInit {

  public logger: Partial<Console>;

  public userCards$: Observable<EVA.Core.UserCardDto[]> = getUserCardsForUser.getResponse$().pipe(
    isNotNil(),
    map((getUserCardsForUserResponse) => {
      const cards = get(
        getUserCardsForUserResponse,
        'UserCards'
      ) as EVA.Core.UserCardDto[];
      return cards;
    })
  );

  @Input() customerID: number;

  ngOnInit() {
    this.fetchUsercardData(this.customerID);
  }

  private async fetchUsercardData(customerId: number) {
    try {
      const [action, promise] = getUserCardsForUser.createFetchAction({
        UserID: customerId,
      });
      store.dispatch(action);
      await promise;
    } catch (error) {
      this.logger.error('could not fetch user cards for user', error);
    }
  }
}
