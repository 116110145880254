import { Component } from '@angular/core';
import { getShoppingCart } from '@springtree/eva-sdk-redux';
import { Logger, ILoggable } from '../../shared/decorators/logger';
import { fadeInOut } from '../../shared/animations';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
import { OrderLifecycleService } from 'src/app/services/order-lifecycle/order-lifecycle.service';

interface IFeedback {
  icon: string;
  label: string;
  cssClass: 'success' | 'fail';
}

/**
 * This modal will be shown whenever the connection timesout during a payment, we want to block the user
 * from continuing until the internet connection is restored and the cart is re-fetched
 */
@Logger('[payment-timeout-modal-component]')
@Component({
  selector: 'payment-timeout-modal',
  templateUrl: 'payment-timeout.modal.html',
  styleUrls: ['./payment-timeout.modal.scss'],
  animations: [fadeInOut]
})
export class PaymentTimeoutModalComponent implements ILoggable {
  logger: Partial<Console>;

  public feedback$ = new BehaviorSubject<IFeedback|null>(null);

  public loading$ = getShoppingCart.isFetching$();

  constructor(private modalCtrl: ModalController, private $translate: TranslateService, private $orderLifecycleService: OrderLifecycleService) {

  }

  retry() {
    const fetchPromise = this.$orderLifecycleService.updateCurrentOrder();

    fetchPromise
      .then(() => {
        this.feedback$.next({
          icon: 'check',
          label: this.$translate.instant('reestablish.connection.success') as string,
          cssClass: 'success'
        });

        setTimeout(() => {
          this.modalCtrl.dismiss();
        }, 1000);
      })
      .catch( error => {

        this.feedback$.next({
          icon: 'error',
          label: this.$translate.instant('reestablish.connection.fail') as string,
          cssClass: 'fail'
        });

        this.logger.error(error);
      });
  }
}
