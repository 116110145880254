import { Injectable } from '@angular/core';
import { IParsedBarcode } from '../../modules/scanner/eva-barcode-scanner';
import { getCurrentUser, getOrganizationUnitSummary, IClientAppTransferOrderPayload, settings, store } from '@springtree/eva-sdk-redux';
import { IParsedSession } from '../../shared/typings';
import { EvaFeedback } from '../../shared/decorators/eva-feedback';
import { NavController } from '@ionic/angular';
import { IParseBarcodeHandler } from '../eva-parse-barcode-handler/eva-parse-barcode-handler';
import { OrderLifecycleService } from '../order-lifecycle/order-lifecycle.service';
import { ClientAppCommunicationProvider } from '../client-app-communication/client-app-communication';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';
import { SelectedOrganisationUnitProvider } from '../selected-organisation-unit/selected-organisation-unit';


@Logger('[eva-parse-barcode-session-handler-provider]')
@Injectable()
export class EvaParseBarcodeSessionHandlerProvider implements IParseBarcodeHandler, ILoggable {

  public logger: Partial<Console>;

  constructor(
    private $clientAppCommunication: ClientAppCommunicationProvider,
    private $orderLifecycleService: OrderLifecycleService,
    private $selectedOrganisationUnit: SelectedOrganisationUnitProvider,
    private navCtrl: NavController,
  ) { }

  /**
   * This method will handle the order pull process for when we are already logged in
   * It will not change the current user and only select the target order
   */
  @EvaFeedback({
    i18nFailKey: 'transfer.order.fail',
    i18nSuccessKey: 'transfer.order.success'
  })
  private async handleWhenLoggedIn(parsedData: IParsedBarcode) {
    const { OrderID } = parsedData.Data as IParsedSession;
    const [shoppingCartPromise] = this.$orderLifecycleService.setCurrentOrderById(OrderID);
    return shoppingCartPromise as Promise<EVA.Core.GetShoppingCartInfoResponse>;
  }

  /**
   * Handles scans of session transfers. Not be confused with deprecated session ids
   * This barcode will contain a target order and user to login with
   *
   * Accepting an order transfer involves joining the transfer channel on the client app hub
   * to signal the sender when the order has been accepted so they can "release" the order.
   * Since we no longer have attachOrderToSession on the backend this is done using a message
   * exchange between the 2 apps. The session barcode is the channel name
   *
   * @see https://eva2015.atlassian.net/browse/OPTR-2159
   */
  public async handle(parsedData: IParsedBarcode) {
    console.log('Joining scan-mode channel', parsedData);

    const { isEmployee, response } = getCurrentUser.getState();
    const userId = response?.User.ID;
    const { Token, OrderID, UserID } = parsedData.Data as IParsedSession;
    const channel = `SESSION:${Token}`;

    // Join the session transfer channel
    //
    await this.$clientAppCommunication.joinChannel(channel);

    // Prepare the message payload we will send to the other app
    //
    const payload: IClientAppTransferOrderPayload = {
      Action: 'transferOrder',
      Data: {
        OrderID,
        Token,
        UserID: userId,
        Status: 'ACCEPTED',
      }
    };

    try {
      if (!isEmployee) {
        await this.handleWhenNotLoggedIn(parsedData);
        await this.navCtrl.navigateRoot('');

        // When logging the user login wil migrate so return the target
        // user id
        //
        payload.Data.UserID = UserID;

        // Because logging in will reconnect the client app communication
        // but with a user token this time we will need to re-join the channel
        // to send the released message
        //
        await this.$clientAppCommunication.joinChannel(channel);
      } else {
        await this.handleWhenLoggedIn(parsedData);
      }
      await this.$clientAppCommunication.sendMessage({
        ChannelID: channel,
        Message: payload,
      });
    } catch (error) {
      this.logger.error('Failed to accept session data', error);
      payload.Data.Status = 'DECLINED';
      await this.$clientAppCommunication.sendMessage({
        ChannelID: channel,
        Message: payload,
      });
    }

    // Always leave the channel when done
    //
    await this.$clientAppCommunication.leaveChannel(channel);
  }

  /**
   * Handles a session pull barcode scan when not logged in
   * This will involve logging in with the user token and setting
   * the current order
   */
  private async handleWhenNotLoggedIn(parsedData: IParsedBarcode) {
    const { Token, OrderID } = parsedData.Data as IParsedSession;

    // Set the current user token
    //
    settings.userToken = Token;
    const [userAction, , chainPromise] = getCurrentUser.createFetchAction();
    store.dispatch(userAction);
    await chainPromise;

    const currentUserResponse = getCurrentUser.getState().response;
    const [action] = getOrganizationUnitSummary.createFetchAction({
      OrganizationUnitID: currentUserResponse.User.CurrentOrganizationID,
    });
    // We don't want to mutate the state so we will use fetchData here.
    //
    const getOrganizationUnitResponse = await getOrganizationUnitSummary.fetchData(action);
    this.$selectedOrganisationUnit.setOrganisation(getOrganizationUnitResponse);

    // Set the new current order
    //
    const [shoppingCartPromise] = this.$orderLifecycleService.setCurrentOrderById(OrderID);
    return shoppingCartPromise as Promise<EVA.Core.GetShoppingCartInfoResponse>;
  }
}
