/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from "@angular/core";
import { AlertController, ModalController, NavController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import {
  cancelDiscountOrderLine,
  getCurrentUser,
  getShoppingCart,
  getShoppingCartInfo,
  getUser,
  prefigureDiscounts,
  prepareOrderForCheckout,
  setPickupOrganizationUnit,
  store,
  transferOrderToOrganizationUnit
} from "@springtree/eva-sdk-redux";
import { IGetShoppingCartState } from "@springtree/eva-sdk-redux/dist/types/redux/reducers/get-shopping-cart";
import { find, findKey, flatten, get, isEmpty, isEqual, isNil, sortBy } from "lodash-es";
import { combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, filter, first, map, mergeMap, take, withLatestFrom } from "rxjs/operators";
import { ILocalizedPrice } from "src/app/components/localized-price/localized-price.component";
import { SwipeIndicatorComponent } from "src/app/components/swipe-indicator/swipe-indicator.component";
import { GenericElevationCheckPincodeModal } from "src/app/modals/elevation-check-pincode-modal/elevation-check-pincode-modal";
import { GenericElevationCheckQRModal } from "src/app/modals/elevation-check-qr-modal/elevation-check-qr-modal";
import { ProductDiscountOptionsModalComponent } from "src/app/modals/product-discount-options-modal/product-discount-options.modal";
import { ReturnOrdersModalComponent } from "src/app/modals/return-orders-modal/return-orders.modal";
import { ShippingMethodModalComponent } from "src/app/modals/shipping-method-modal/shipping-method.modal";
import { ShippingRestrictionsModalComponent } from "src/app/modals/shipping-restrictions-modal/shipping-restrictions.modal";
import { EvaToastController } from "src/app/modules/eva-toast/eva-toast.controller";
import { DecimalFormatService } from "src/app/services/decimal-format/decimal-format.service";
import { EvaApplicationConfigProvider } from "src/app/services/eva-application-config/eva-application-config";
import { EvaAttachCustomerToOrderProvider, ICustomerAttachOptions } from "src/app/services/eva-attach-customer-to-order/eva-attach-customer-to-order";
import { OrderLifecycleService } from "src/app/services/order-lifecycle/order-lifecycle.service";
import { PickDiscountOptionRequirementsProvider } from "src/app/services/pick-discount-option-requirements/pick-discount-option-requirements";
import { timeToShowSpinnerDefaults } from "src/app/shared/constants";
import { EvaFeedback, IExtendedFeedback } from "src/app/shared/decorators/eva-feedback";
import { ILoggable, Logger } from "src/app/shared/decorators/logger";
import isNotNil from "src/app/shared/operators/isNotNil";
import { IParsedRitualsLoyalty, IParsedUserCustomID } from "src/app/shared/typings";
import { ILineActionsTypes } from "./components/basket-list/basket-list.component";
import { AnyAction } from "redux";

interface ICheckoutButtonPricing {
  currencyId: string;
  pricing: ILocalizedPrice;
  preferredPriceDisplayMode: EVA.Core.OrderPreferredPriceDisplayMode;
}

/***
 * this will contain some helper functions that the 'next' button will be using
 */
@Logger('[basket-service]')
@Injectable({
  providedIn: "root",
})
export class BasketService implements ILoggable {

  public disableCheckoutButton$: Observable<boolean> = getShoppingCart.getResponse$().pipe(
    isNotNil(),
    map(response => {
      const cartLines = response?.ShoppingCart?.Lines;
      const requirements = response?.AdditionalOrderData?.RequiredData;
      if(isNil(requirements)){
        return false;
      }

      const orderLineRequirementsInvalid = Object.keys(requirements?.OrderLines || {}).some(key => {
        const orderLine = requirements?.OrderLines[key];

        // For delivery orders we dont want to show the Serial number requirement
        // @see https://n6k.atlassian.net/browse/OPTR-24053?focusedCommentId=139061
        const currentLine = cartLines.find(line => line.ID === parseInt(key));
        const isDeliveryOrder = currentLine?.LineActionType === ILineActionsTypes.DELIVERY || currentLine?.LineActionType === ILineActionsTypes.ORDER_LINE;

        return this.isOrderLineInvalid(orderLine,isDeliveryOrder)
      });

      const maximumAmountInvalid = Boolean( (requirements.Order ?? []).find( orderRequirement => orderRequirement.Name === 'MaximumAmount' && !orderRequirement.IsValid ) );

      return orderLineRequirementsInvalid || maximumAmountInvalid;
    })
  );

  public shoppingCart$ = getShoppingCart.getState$().pipe(
    map(state => state.response),
    distinctUntilChanged((prev, next) => isEqual(prev, next))
  );

  public shoppingCartLines$ = this.shoppingCart$.pipe(
    map(response => response?.ShoppingCart?.Lines ?? [])
  );

  public cartInfo$ = getShoppingCartInfo.getState$().pipe(
    map(state => state.response),
    isNotNil()
  );

  /** The current currency ID, retrieved from the shoppingcart info. */
  public currencyId$ = this.cartInfo$.pipe(map(cartInfo => cartInfo.CurrencyID));

  cartRequiredData$ = this.shoppingCart$.pipe(
    map( response => response?.AdditionalOrderData?.RequiredData)
  );

  public invalidCheckout$: Observable<boolean> = this.cartRequiredData$.pipe(
    isNotNil(),
    map(orders => {
      const checkOrderLines = Object.values(orders.OrderLines).some(orderLines => {
        return orderLines.some(orderLine => {
          return orderLine.Name === 'ShippingMethodID' && !orderLine.IsValid;
        });
      });
      const checkOrders = Object.values(orders.Order).some(order => {
          return ['TransferOrderToOrganizationUnit', 'ReturnOrders'].includes(order.Name) && !order.IsValid;
      });
      return checkOrderLines || checkOrders;
    })
  );

  totalItems$: Observable<number> = this.shoppingCart$.pipe(
    map(shoppingCartResponse => shoppingCartResponse?.ShoppingCart.TotalItems)
  );

  public orderRequirements$ = getShoppingCart.getResponse$().pipe(
    map(response => response?.AdditionalOrderData?.RequiredData),
    isNotNil()
  );

  public shippingRestricionsApplied$ = this.orderRequirements$.pipe(
    map(orderRequirements => {
      const orderLineRequirementValues = flatten(Object.values(orderRequirements.OrderLines));
      const result = this.hasInvalidOrderLineShippingRestriction(orderLineRequirementValues);
      return result;
    })
  );

  /**
   * Checks if, in the `Order Line Requirements array` there are invalid requirements of type `RestrictedShipping`
   */
  public hasInvalidOrderLineShippingRestriction(orderLineRequirements: EVA.Core.Requirement[]): boolean {
    const hasInvalidOrderLineShippingRestriction = orderLineRequirements.some(
      orderLineRequirement => !orderLineRequirement.IsValid && orderLineRequirement.Name === 'RestrictedShipping'
    );
    return hasInvalidOrderLineShippingRestriction;
  }

  public cartState$ = getShoppingCart.getState$().pipe(
    filter(cartState => {
      const currentCartId = (get(cartState.response, 'ShoppingCart.ID') as number);

      const orderId = this.$orderLifecycleService.getCurrentOrderId();

      /** We only want this stream to emit if the id of the order we originally opened matches the current cart id */
      const cartStateMatchesOrderId = orderId === currentCartId;

      return cartStateMatchesOrderId;
    })
  );

  public cartResponse$ = this.cartState$.pipe(
    map(state => state.response),
    isNotNil()
  );

  /** The shoppingcart order */
  public cart$ = this.cartResponse$.pipe(
    filter(response => !isNil(get(response, 'ShoppingCart'))),
    map(response => response.ShoppingCart)
  );

  /**
   * Gets the restricted shipping order lines
   *
   * We calculate these order lines based on the `OrderLines RequiredData`
   */
  public restrictedShippingOrderLines$: Observable<EVA.Core.OrderLineDto[]> = this.cart$.pipe(
    withLatestFrom(this.orderRequirements$),
    filter(([, orderRequirements]) => !isNil(orderRequirements) && !isEmpty(orderRequirements.OrderLines)),
    map(([cart, orderRequirements]) => {

      const orderLinesRequirements = orderRequirements.OrderLines;

      // If there is any pick discount product order line that is shipping restricted,
      // we don't want to handle it via this flow as it will be handled elsewhere.
      // We will get order lines that have a pick discount product choice to fitler them out of the shipping restricted order lines
      //
      const pickDiscountProductLineIds = this.$pickDiscountOptionRequirements.getPickDiscountProductLines(cart, orderLinesRequirements)
        .map(pickDiscountProductLine => pickDiscountProductLine.ID);

      // We will get all the order line ids that have a restriction, and exclude any order lines that are pick discount product choices
      //
      const orderLinesWithRestrictionIds: number[] = Object.entries(orderLinesRequirements).filter(([_orderLineId, requirements]) => {
        const hasShippingRestriction = this.hasInvalidOrderLineShippingRestriction(requirements);

        return hasShippingRestriction;
      })
        .map(([orderLineId]) => + orderLineId)
        .filter(orderLineId => !pickDiscountProductLineIds.includes(orderLineId));

      const restrictedShippingOrderLinesResult: EVA.Core.OrderLineDto[] = cart.Lines.filter(cartLine => {
        return orderLinesWithRestrictionIds.includes(cartLine.ID);
      });

      return restrictedShippingOrderLinesResult;
    }),
    distinctUntilChanged((prev, next) => {

      if (isEmpty(prev) && isEmpty(next)) {
        return true;
      }

      const prevIds = prev.map(prevItem => prevItem.ID);
      const nextIds = next.map(nextItem => nextItem.ID);
      const areEqual = isEqual(sortBy(prevIds), sortBy(nextIds));

      return areEqual;
    })
  );

  public checkoutButtonText$ = combineLatest([
    this.shoppingCart$.pipe(isNotNil()),
    this.invalidCheckout$,
    this.totalItems$,
    this.shippingRestricionsApplied$
  ]).pipe(
    mergeMap( ([shoppingCartResponse, invalidCheckout, totalItems, shippingRestricionsApplied]) => {
      // If we have steps that need fixing, we will show the next button instead of the number of articles
      //
      if (invalidCheckout) {
        return this.$translate.get('next');
      }

      if (totalItems > 1) {
        return this.$translate.get('articles.sales', {
          quantity: totalItems
        });
      } else {
        return this.$translate.get('article.sales');
      }
    })
  );

  checkoutButtonPricing$: Observable<ICheckoutButtonPricing> = combineLatest([
    this.shoppingCart$.pipe(isNotNil()),
    this.invalidCheckout$
  ]).pipe(
    map( ([shoppingCartResponse, invalidCheckout]) => {
      // We dont want to show pricing if the order is currently invalid
      //
      if ( invalidCheckout ) {
        return null;
      }

      const {Total, Open} = shoppingCartResponse.Amounts;

      const checkoutButtonPricing: ICheckoutButtonPricing = {
        currencyId: shoppingCartResponse.ShoppingCart.CurrencyID,
        pricing: {
          price: Total.Amount || Open.InTax,
          priceInTax: Total.InTax || Open.InTax
        },
        preferredPriceDisplayMode: shoppingCartResponse.ShoppingCart.PreferredPriceDisplayMode
      };

      return checkoutButtonPricing;
    })
  );

  // Check if basket is valid to navigate to checkout page
  // @see https://n6k.atlassian.net/browse/OPTR-17026
  public validCartRequirements$: Observable<boolean> = combineLatest([
    getShoppingCart.getState$(),
    this.disableCheckoutButton$
  ]).pipe(
    map( ([shoppingCartState, disableCheckoutButton]) => {
      // Check if we need to transfer the order to the current Organization Unit
      // We do this by looking at the order requirements at a requirement called - TransferOrderToOrganizationUnit
      const hasTransferToOrganizationUnitRequirement = (shoppingCartState.orderIssues || []).some(
        (orderIssue) => orderIssue.Name === "TransferOrderToOrganizationUnit"
      );

      const hasMoveOrderToCurrentOrganizationUnitRequirement = (shoppingCartState.orderIssues || []).some(
        (orderIssue) => orderIssue.Name === "MoveOrderToCurrentOrganizationUnit" && !orderIssue.IsValid
      );

      // Check if the order has an invalid `ReturnOrders` requirement
      const invalidReturnOrderRequirement = (shoppingCartState.orderIssues || []).some(
        (orderIssue) => orderIssue.Name === "ReturnOrders" && !orderIssue.IsValid
      );

      const invalidShippingMethods = this.checkInvalidShippingMethod(shoppingCartState);

      // Whether this order is ready for checkout or not:
      // This will depend on whether all order line issues are resolved or not and whether some other order requirements are valid or not
      // And on whether we need to transfer the order to the current organization unit first
      const readyForCheckout =
        !(disableCheckoutButton) &&
        !hasTransferToOrganizationUnitRequirement &&
        !invalidReturnOrderRequirement &&
        !invalidShippingMethods &&
        !hasMoveOrderToCurrentOrganizationUnitRequirement;

      return readyForCheckout;
    })
  );

  logger: Partial<Console>;

  constructor(
    private toastCtrl: EvaToastController,
    private navCtrl: NavController,
    private $translate: TranslateService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private $orderLifecycleService: OrderLifecycleService,
    private $evaAttachCustomerToOrder: EvaAttachCustomerToOrderProvider,
    private $pickDiscountOptionRequirements: PickDiscountOptionRequirementsProvider,
    private $appConfig: EvaApplicationConfigProvider,
    private $decimalFormat: DecimalFormatService,
  ) {}

  public isConfirmationAlertOpen: boolean = false;

  public async checkout(swipeIndicator: SwipeIndicatorComponent) {
    const shoppingCartResponse = getShoppingCart.getState().response;
    // Sanity check order has an ID
    //
    const orderId = this.$orderLifecycleService.getCurrentOrderId();

    if (!orderId) {
      this.logger.log("Trying to do checkout with empty cart");
      return;
    }

    // Get the required data for order async because the sync way somehow wouldn't pick up the latest changes in data requirements
    const requiredDataState = await getShoppingCart.getState$().pipe(first()).toPromise();

    // Check if basket is valid to navigate to checkout page
    // @see https://n6k.atlassian.net/browse/OPTR-17026
    const readyForCheckout = await this.validCartRequirements$.pipe(first()).toPromise();

    let promise: Promise<any>;

    if (readyForCheckout) {
      // No issues found
      // Check if the cart still needs to be turned into an order
      //
      const createOrderIssue = find(requiredDataState.orderIssues, {
        Name: "CreateOrder",
        IsValid: false,
      });

      if (createOrderIssue) {
        const createOrderPromise = this.prepareOrderForCheckout(orderId);
        promise = createOrderPromise;
        try {
          await createOrderPromise;
          this.navigateToOrder(orderId, shoppingCartResponse.ShoppingCart.Type);
          swipeIndicator.pullDown();
        } catch (e) {
          // Figure out what type of feedback to show here
          //
          this.logger.error("Error creating the order from shopping cart");
        }
      } else {
        this.navigateToOrder(orderId, shoppingCartResponse.ShoppingCart.Type);
        swipeIndicator.pullDown();
      }
    } else {
      // Handle order line issues
      //
      this.logger.log("Order line issues", requiredDataState.orderLineIssues);
      this.handleRequiredDataFeedback(
        requiredDataState,
        orderId,
        shoppingCartResponse.ShoppingCart.Type
      );
    }
    return promise;
  }

  // This method checks if we can continue to checkout or not
  private isOrderLineInvalid(orderLine, isDeliveryOrder){
    const someInvalidProperty = orderLine.some(requirement => {
      // For delivery orders we dont want to show the Serial number requirement
      // @see https://n6k.atlassian.net/browse/OPTR-24053?focusedCommentId=139061
      const skipSerialNumberValidation = requirement.Name === 'SerialNumber' && isDeliveryOrder;
      if(skipSerialNumberValidation){
        return false;
      }

      return (requirement.IsValid === false &&
        requirement.Name !== 'ShippingMethodID' &&
        // We handle this requirement on the checkout page, so we skip it here
        // @see https://n6k.atlassian.net/browse/OPTR-18026
        requirement.Name !== 'EmployeeVerification' &&
        requirement.Name !== 'NeedsEmployeeVerification');
    });

    return someInvalidProperty;
  }

  // Open the popup showing the order lines that have shipping restrictions
  // @see https://n6k.atlassian.net/browse/OPTR-16596
  private async openShippingRestrictionsModal(): Promise<void> {

    const orderId = this.$orderLifecycleService.getCurrentOrderId();

    const restrictedOrderLines = await this.restrictedShippingOrderLines$.pipe(take(1)).toPromise();

    const shippingRestrictionsModalComponentModal = await this.modalCtrl.create({
      component: ShippingRestrictionsModalComponent,
      componentProps: {
        restrictedOrderLines,
        orderId
      },
    });

    await shippingRestrictionsModalComponentModal.present();
    await shippingRestrictionsModalComponentModal.onDidDismiss();
  }

  /**
   * We have an active order present if the shopping cart id is not 0
   * We will use this in adding customers to the order, because we arent allowed to call attachCustomerToOrder
   * with an order id of 0
   */
  activeOrderPresent(): boolean {
    const orderId = this.$orderLifecycleService.getCurrentOrderId();

    const activeOrderPresent = orderId !== 0;

    return activeOrderPresent;
  }

  private navigateToOrder(orderId: number, orderType: EVA.Core.OrderTypes) {
    // Once we have already checked if createOrder is valid or not, we will navigate to either the checkout page
    //
    const isSalesOrder = orderType === 0;


    if (isSalesOrder) {
      this.navCtrl.navigateForward(`/checkout/${orderId}`)
        .catch(error => this.logger.error('error navigating to the checkout page', error));
    }
  }

  @EvaFeedback({
    i18nFailKey: "create.order.fail",
  })
  private async prepareOrderForCheckout(orderId: number) {

    const [
      fetchAction,
      fetchPromise,
    ] = prepareOrderForCheckout.createFetchAction({
      OrderID: orderId,
    });

    store.dispatch(fetchAction);

    return fetchPromise;
  }

  /** Shows feedback to the user based on the required data for order at the moment */
  private async handleRequiredDataFeedback(
    getShoppingCartState: IGetShoppingCartState,
    orderId: number,
    orderType: EVA.Core.OrderTypes
  ) {
    // Order line issues differ because they are indexed by orderLineId
    //
    const pickProductOrderLineId = findKey(
      getShoppingCartState.orderLineIssues,
      (orderLineIssues) => {
        return !!find(orderLineIssues, { Name: "PickDiscountProduct" });
      }
    );
    const productRequirementOrderLineId = findKey(
      getShoppingCartState.orderLineIssues,
      (orderLineIssues) => {
        return !!find(orderLineIssues, { Name: "ProductRequirement" });
      }
    );
    const productRestrictedShippingAddressOrderLineId = findKey(
      getShoppingCartState.orderLineIssues,
      (orderLineIssues) => {
        return !!find(orderLineIssues, { Name: "RestrictedShippingAddress" });
      }
    );

    const maximumAmountRequirement = !!find(getShoppingCartState.orderIssues, {
      Name: "MaximumAmount",
    });

    const transferToOrganizationUnitRequirement = !!find(
      getShoppingCartState.orderIssues,
      { Name: "TransferOrderToOrganizationUnit" }
    );

    const moveOrderToCurrentOrganizationUnitRequirement = find(
      getShoppingCartState.orderIssues,
      { Name: "MoveOrderToCurrentOrganizationUnit" }
    );

    // Check if the order has an invalid `ReturnOrders` requirement
    //
    const invalidReturnOrderRequirement = (
      getShoppingCartState.orderIssues || []
    ).some(
      (orderIssue) => orderIssue.Name === "ReturnOrders" && !orderIssue.IsValid
    );

    // We check if there are invalid shipping lines
    // @see https://n6k.atlassian.net/browse/OPTR-16596
    const invalidShippingRestrictions = await this.shippingRestricionsApplied$.pipe(take(1)).toPromise();

    const invalidShippingMethods = this.checkInvalidShippingMethod(
      getShoppingCartState
    );

    if (transferToOrganizationUnitRequirement) {
      this.presentTransferToOrganizationUnitAlert(orderId, orderType);
    }

    if (moveOrderToCurrentOrganizationUnitRequirement && !moveOrderToCurrentOrganizationUnitRequirement.IsValid) {
      this.presentTransferToOrganizationUnitAlert(orderId, orderType, true);
    }

    if (invalidReturnOrderRequirement) {
      this.presentReturnOrderRequirementModal();
    }

    if (invalidShippingMethods) {
      this.presentShippingMethodModal(orderId);
    }

    if (pickProductOrderLineId) {
      this.logger.log(
        `Opening modal to pick a discount product for order line ${pickProductOrderLineId}`
      );

      const modal = await this.modalCtrl.create({
        component: ProductDiscountOptionsModalComponent,
        componentProps: {
          orderLineId: pickProductOrderLineId,
        },
      });

      modal.present();
    } else if (productRequirementOrderLineId) {
      this.toastCtrl.showMessage(
        this.$translate.instant("sales.provide.product.requirements")
      );
    } else if (productRestrictedShippingAddressOrderLineId) {
      this.toastCtrl.showMessage(
        this.$translate.instant("sales.shipping.address.not.allowed")
      );
    } else if (maximumAmountRequirement) {
      this.toastCtrl.showMessage(
        this.$translate.instant("order.max.amount.requirement")
      );
    } else if(invalidShippingRestrictions) {
      this.openShippingRestrictionsModal();
    }
  }

  /**
   * Check if all orders have shipping method
   */
  private checkInvalidShippingMethod(
    getShoppingCartState: IGetShoppingCartState
  ): boolean {
    return (
      !getShoppingCartState.orderLineIssues ||
      !!Object.values(getShoppingCartState.orderLineIssues).some((orderLines) => {
        return orderLines.some((orderLine) => {
          return orderLine.Name === "ShippingMethodID" && !orderLine.IsValid;
        });
      })
    );
  }

  private async presentTransferToOrganizationUnitAlert(
    orderId: number,
    orderType: EVA.Core.OrderTypes,
    isMoveOrderRequirement = false,
  ) {
    const alert = await this.alertCtrl.create({
      header: this.$translate.instant(
        "transfer.order.different.device.warning.title"
      ),
      message: this.$translate.instant(
        "transfer.order.current.ou.warning.message"
      ),
      buttons: [
        {
          text: this.$translate.instant("action.cancel"),
          role: "cancel",
        },
        {
          text: this.$translate.instant("action.confirm"),
          handler: () => {
            this.handlePickUpOrganizationConfirmation(orderId, orderType, isMoveOrderRequirement);
          },
        },
      ],
    });

    alert.present();
  }

  /**
   * We don't want the user to enter the checkout with a mixed order ( return lines + normal lines )
   * @see https://eva2015.atlassian.net/browse/OPTR-5585
   */
   private async presentReturnOrderRequirementModal() {

    const cartLines = await this.shoppingCartLines$.pipe(first()).toPromise();

    const nonReturnableLines = cartLines.filter( line => {
      const nonReturnableLine = line.TotalQuantityToShip > 0;

      return nonReturnableLine;
    });

    const orderId = this.$orderLifecycleService.getCurrentOrderId();

    const modal = await this.modalCtrl.create({
      component: ReturnOrdersModalComponent,
      componentProps: {
        restrictedOrderLines: nonReturnableLines,
        orderId
      }
    });

    modal.present();
  }

  private async presentShippingMethodModal(orderId: number) {
    const modal = await this.modalCtrl.create({
      component: ShippingMethodModalComponent,
      componentProps: {
        orderId
      }
    });

    modal.present();
  }

  @EvaFeedback()
  private async handlePickUpOrganizationConfirmation(orderId: number, orderType: EVA.Core.OrderTypes, isMoveOrderRequirement = false) {
    const currentUser = await getCurrentUser.getResponse$().pipe(
      map(response => response.User),
      isNotNil(),
      first()
    ).toPromise();

    const currentOrganizationUnitId = currentUser.CurrentOrganizationID;

    const currentOrganizationUnitName = currentUser.CurrentOrganizationName;

    let extendedFeedback: IExtendedFeedback;
    let serviceAction;
    let action: AnyAction;
    let chainPromise;

    if (isMoveOrderRequirement) {
      // Transfer order to OU
      //
      serviceAction = transferOrderToOrganizationUnit.createFetchAction({
        OrderID: orderId,
      });

    } else {

      // Set the pickup organization unit to the current organization unit of the user
      //
      serviceAction = setPickupOrganizationUnit.createFetchAction({
        OrderID: orderId,
        OrganizationUnitID: currentOrganizationUnitId
      });
    }
    action = serviceAction[0];
    chainPromise = serviceAction[2];

    store.dispatch(action);

    chainPromise.then(() => {
      // Refresh order requirements
      //
      this.$orderLifecycleService.updateCurrentOrder();

      this.navigateToOrder(orderId, orderType);
    }).catch( error => {
      this.logger.error(`error calling ${isMoveOrderRequirement ? 'transferOrderToOrganizationUnit' : 'setPickupOrganizationUnit'}`, error);
    });

    extendedFeedback = {
      feedbackPromise: chainPromise,
      timeToShowSpinner: timeToShowSpinnerDefaults.long,
      i18nFailParams: 'transfer.order.to.current.ou.fail',
      i18nSuccessParams: ['transfer.order.to.current.ou.success', {
        currentStoreName: currentOrganizationUnitName
      }]
    };

    return extendedFeedback;
  }

  async checkAndDisableDiscount(orderLineID: number) {
    const hasPendingPayments = await getShoppingCart.getResponse$().pipe(
      first(),
      map(response => response?.ShoppingCart?.Lines?.length > 0 && response?.Amounts?.Paid?.Pending !== 0 && !response?.ShoppingCart.IsPaid)
    ).toPromise();

    if (!hasPendingPayments) {
      return this.disableDiscount(orderLineID);
    }

    const askConfirmation = await this.askConfirmationOnOrderChange();

    if (askConfirmation?.role === 'confirm') {
      return this.disableDiscount(orderLineID);
    }
  }

  /**
   * @see https://n6k.atlassian.net/browse/OPTR-13518
   */
  @EvaFeedback({
    i18nFailKey: 'disable.discount.fail',
    i18nSuccessKey: 'disable.discount.success'
  })
  async disableDiscount(orderLineId: number) {
    this.logger.log(`disable discount orderLineId=${orderLineId}`);

    const [cancelDiscountOrderLineAction, promise] = cancelDiscountOrderLine.createFetchAction({
      OrderLineID: orderLineId
    });

    store.dispatch(cancelDiscountOrderLineAction);

    try {
      await promise;
      this.$orderLifecycleService.updateCurrentOrder();
    } catch (error) {
      this.logger.error(`error calling cancelDiscountOrderLine for orderLineId=${orderLineId}`);
      this.handleRequestVerificationError(error,orderLineId);
    }

    return promise;
  }

  async handleUserIdScan(userCustomIdScan: IParsedUserCustomID, attachmentMetadata?:any) {
    if (!(this.activeOrderPresent())) {
      this.toastCtrl.create({
        message: this.$translate.instant('basket.customer.scan.no.active.cart'),
        duration: 5000
      }).present();
      return;
    }

    try {
      // We want to first fetch the customer,
      //
      const [getUserAction] = getUser.createFetchAction({
        ID: userCustomIdScan.UserID
      });

      const getUserResponse = await getUser.fetchData(getUserAction);

      const fullCustomerName = getUserResponse.FullName;

      let parameters: Partial<ICustomerAttachOptions> = {
        customerId: userCustomIdScan.UserID,
        fullCustomerName
      }

      /**
       * We need extra info to be sent to BE
       * @see https://n6k.atlassian.net/browse/OPTR-18566
       */
      if(attachmentMetadata){
        parameters.attachmentMetadata = attachmentMetadata;
      }

      this.$evaAttachCustomerToOrder.attach(parameters, this.navCtrl);

    } catch ( error ) {
      this.logger.error(`error handling USER_CUSTOMID scan, userId=${userCustomIdScan.UserID}`, error);

      this.toastCtrl.create({
        message: this.$translate.instant('customer.scan.get.user.error'),
        duration: 5000
      }).present();

    }
  }

  handleRitualsLoyaltyScan(ritualsLoyaltyParsedData: IParsedRitualsLoyalty) {
    if (!(this.activeOrderPresent())) {
      this.toastCtrl.create({
        message: this.$translate.instant('basket.customer.scan.no.active.cart'),
        duration: 5000
      }).present();
      return;
    }

    const fullName = `${ritualsLoyaltyParsedData.FirstName} ${ritualsLoyaltyParsedData.LastName}`;

    this.$evaAttachCustomerToOrder.attach({
      customerId: ritualsLoyaltyParsedData.ID,
      fullCustomerName: fullName
    }, this.navCtrl);
  }

  public async askConfirmationCancelLine() {
    if (!this.isConfirmationAlertOpen) {
      this.isConfirmationAlertOpen = true;
      const alert = await this.alertCtrl.create({
        header: this.$translate.instant('order.paid'),
        message: this.$translate.instant('product.cancel.confirmation.message'),
        buttons: [
          {
            text: this.$translate.instant('action.cancel'),
            role: 'cancel',
          },
          {
            text: this.$translate.instant('action.confirm'),
            cssClass: 'alert-danger',
            role: 'confirm',
          },
        ],
      });
      alert.present();
      const dismissEvent = await alert.onDidDismiss<{role: string}>();
      this.isConfirmationAlertOpen = false;
      return dismissEvent;
    }
  }

  public async askConfirmationOnOrderChange() {
    const alert = await this.alertCtrl.create({
      header: this.$translate.instant('modify.order.pending.payments.order.warning.title'),
      message: this.$translate.instant('modify.order.pending.payments.order.warning.message'),
      buttons: [
        {
          text: this.$translate.instant('action.cancel'),
          role: 'cancel',
        },
        {
          text: this.$translate.instant('action.update'),
          cssClass: 'alert-danger',
          role: 'confirm',
        }
      ]
    });

    alert.present();

    const dismissEvent = await alert.onDidDismiss<{role: string}>();
    return dismissEvent;
  }

  /**
   * In case of error, but the user has elevated rights we show the modal.
   * This method checks if the failed service returned elevation funcionality.
   * If that's the case we open the Pincode or QRcode modal to handle the elevation token process.
   * The modal also will execute the service, this time injecting the elevationToken to succeed.
   */
  private async handleRequestVerificationError(error,orderLineId){
    const hasElevatedRights = error.headers.get('EVA-Elevation-Functionality') === 'CancelManualDiscount' ? true : false;
    if(hasElevatedRights){
      const serviceToExecute = (elevationToken) => {
        const [cancelDiscountOrderLineAction, promise] = cancelDiscountOrderLine.createFetchAction({
          OrderLineID: orderLineId
        }, null, undefined, null, elevationToken);
        store.dispatch(cancelDiscountOrderLineAction);
        return promise;
      }
      this.openElevationCheckModal(serviceToExecute).then(response => {
        if(response?.data){
          this.$orderLifecycleService.updateCurrentOrder();
        } else {
          this.logger.error("Failed to remove discount", error);
          this.toastCtrl.showMessage(this.$translate.instant('verify.operation.failed'));
        }
      });
    }
  }

  /**
   * We open the modal for elevation support
   * @see https://n6k.atlassian.net/browse/OPTR-23336
   */
  async openElevationCheckModal(serviceToExecute?:Function) {
    const orderId = this.$orderLifecycleService.getCurrentOrderId();
    const functionalityEnabled = await this.$appConfig.elevatedFunctionalityProvider$.pipe(first(),isNotNil()).toPromise();

    // Open the QR code modal
    if(functionalityEnabled?.toLowerCase() === 'elevationbarcode'){
      const modal = await this.modalCtrl.create({
        component: GenericElevationCheckQRModal,
        cssClass: 'auto-height',
        componentProps: {
          functionality: 'CancelManualDiscount',
          orderId:orderId,
          serviceToExecute
        },
      });
      modal.present();
      return modal.onDidDismiss();
    }

    // Open the Pincode code modal
    if(functionalityEnabled?.toLowerCase() === 'temporaryelevationcode'){
      const modal = await this.modalCtrl.create({
        component: GenericElevationCheckPincodeModal,
        cssClass: 'auto-height',
        componentProps: {
          orderId:orderId,
          serviceToExecute
        }
      });
      modal.present();
      return modal.onDidDismiss();
    }
  }

  public checkApplyDiscountFailedReason(error){
    if(error?.Type === 'Discounts:CouponAlreadyAdded' || error?.Type === 'Discounts:InvalidCoupon'){
      return error.Message;
    }
    return this.$translate.instant('discount.add.fail');
  }

  /**
   * Check if discount requirements are met to apply the discount
   * @see https://n6k.atlassian.net/browse/OPTR-23018
   */
  public async checkDiscountRequirements(orderID: number, discountID?: number): Promise<void | string>{
    const [actionDiscount,promise] = prefigureDiscounts.createFetchAction({
      OrderID: orderID
    });

    store.dispatch(actionDiscount);

    const discountDetails = await promise;
    const currencyId = await this.currencyId$.pipe(first()).toPromise();
    const decimalPrecision = await this.getCurrencyDecimals(currencyId);
    const details = discountDetails?.Results?.find(disc => disc.DiscountID === discountID);
    let messages = '';

    if(!details.IsAlreadyApplied){
      details?.ConditionsResults?.forEach(discountReq => {
        if(discountReq.TypeName === 'V2:ORDERAMOUNT' && discountReq?.RequiresMinimumAmount && !discountReq?.RequirementsMet){
          const amount = discountReq.OrderAmountMissing.toFixed(decimalPrecision);
          const formatedAmount = `${currencyId} ${amount}`;
          messages += this.$translate.instant('discount.add.minimum.amount',{ amount:formatedAmount })
        } else if(discountReq.TypeName === 'V2:CUSTOMERAGE' && discountReq?.RequiresCustomer && !discountReq?.RequirementsMet){
          messages += this.$translate.instant('discount.requires.minimum.age')
        } else if(!discountReq?.RequirementsMet){
          messages += this.$translate.instant('discount.requires.not.met')
        }
      })
    }

    return messages;
  }

  /**
   * We need to adjust decimals based on the Order currency type
   * @see https://n6k.atlassian.net/browse/OPTR-19880
   */
  private async getCurrencyDecimals(currencyId?: string) {
    const decimalPrecision = !isNil(currencyId) ? this.$decimalFormat.getDecimalPrecisionCurrency(currencyId) : 2;
    return decimalPrecision;
  }


}
