import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  addDiscountCouponToOrder, getGiftWrappingOptionsForOrder, getShoppingCart,
  getShoppingCartInfo,
  OrderMode,
  store
} from '@springtree/eva-sdk-redux';
import { TAddDiscountCouponToOrderChainData } from '@springtree/eva-sdk-redux/dist/types/redux/reducers/add-discount-coupon-to-order';
import { get, includes, isEmpty } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { PauseOrderModalComponent } from 'src/app/modals/pause-order-modal/pause-order.modal';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { BasketService } from 'src/app/pages/basket/basket.service';
import { IDisplayProperties } from 'src/app/pages/basket/components/basket-list-product/basket-list-product.component';
import { OrderLifecycleService } from 'src/app/services/order-lifecycle/order-lifecycle.service';
import { EvaApplicationConfigProvider } from '../../services/eva-application-config/eva-application-config';
import { EvaErrorGeneratorProvider } from '../../services/eva-error-generator/eva-error-generator';
import { SummaryCheckoutProvider } from '../../services/eva-summary-checkout/summary-checkout.provider';
import { InterLogisticsSwitchProvider } from '../../services/inter-logistics-switch/inter-logistics-switch';
import { ScanPageProvider } from '../../services/scan-page/scan-page';
import { SentinelProvider } from '../../services/sentinel/sentinel';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import isRequired from '../../shared/decorators/members/is-required';
import isNotNil from '../../shared/operators/isNotNil';
import { GenericIcon } from '../icon/icon.component';
import { ILocalizedPrice } from '../localized-price/localized-price.component';
import { SwipeIndicatorComponent } from '../swipe-indicator/swipe-indicator.component';

/**
 * @param id - The identifier for the orderType.
 * @param icon - The name for the ionic icon.
 * @param translation - The translation key.
 */
export interface IOrderType {
  id: string;
  icon: GenericIcon;
  translation: string;
  disabled?: boolean;
}

export interface ICurrentOrderType {
  icon: GenericIcon;
  label: string;
}

@Logger('[cart-secondary-actions-component]')
@Component({
  selector: 'eva-cart-secondary-actions',
  templateUrl: './cart-secondary-actions.component.html',
  styleUrls: ['./cart-secondary-actions.component.scss']
})
export class CartSecondaryActionsComponent implements ILoggable, OnInit {

  public lineActionTypesAvailable$ = this.$applicationConfig.lineActionTypesAvailable$;
  /**
   * A list of all orderTypes a user can change the current cart to.
   */
  public orderTypes$: BehaviorSubject<IOrderType[]> = new BehaviorSubject([]);

  public orderId$ = getShoppingCart.getResponse$().pipe(
    isNotNil(),
    map(cart => cart.ShoppingCart.ID)
  );

  public logger: Partial<Console>;

  @isRequired
  @Input()
  swipeIndicator: SwipeIndicatorComponent;

  /** Information about the current cart.
   */
  public cartInfo$ = getShoppingCartInfo.getState$().pipe(
    map(state => state.response),
    isNotNil()
  );

  // @see https://n6k.atlassian.net/browse/OPTR-23021
  public ecoTaxes$: Observable<IDisplayProperties> = getShoppingCart.getResponse$().pipe(
    isNotNil(),
    map( cart => {
      if(cart.ShoppingCart['TotalEcoTaxInTax']){
        return { EcoTaxInTax:cart.ShoppingCart['TotalEcoTaxInTax'] }
      }
    })
  )

  /**
   * Hide lineaction type selector when order is RMA/Interstore
   * @see https://n6k.atlassian.net/browse/OPTR-16377
   */
  public orderIsRMA$ = getShoppingCart.getResponse$().pipe(
    isNotNil(),
    map(state => state.ShoppingCart),
    map(order => {
      const characteristics = order.Characteristics;
      const isRmaOrder = Boolean(characteristics & 128);
      const isInterbranchOrder = Boolean(characteristics & 4);

      if (isRmaOrder || isInterbranchOrder) {
        return true;
      }
    })
  );

  /** The current currency ID, retrieved from the shoppingcart info. */
  public currencyId$ = this.cartInfo$.pipe(map(cartInfo => cartInfo.CurrencyID));

  /** The amounts for the checkout total card.
   */
  public amounts$ = getShoppingCart.getResponse$().pipe(
    isNotNil(),
    map(response => response.Amounts),
    isNotNil()
  );

  public subTotalPricing$: Observable<ILocalizedPrice> = this.amounts$.pipe(
    map( amounts => {
      const subTotalPricing = this.constructLocalizedPricing(amounts.Types['NormalProduct' as any]);

      return subTotalPricing;
    })
  );

  /**
   * Whether the current order has giftwrapping lines
   */
  public showGiftwrapping$: Observable<boolean> = getGiftWrappingOptionsForOrder.getState$().pipe(
    isNotNil(),
    map(giftWrappingResult => giftWrappingResult.response),
    isNotNil(),
    map(giftwrappingResult => {
      const orderHasGiftwrappingSelection = giftwrappingResult.WrapIndividually || giftwrappingResult.WrapOrder;
      return orderHasGiftwrappingSelection;
    })
  );

  /**
   * Whether the current order has greeting card
   */
  public hasGreetingCard$: Observable<boolean> = getGiftWrappingOptionsForOrder.getState$().pipe(
    isNotNil(),
    map(giftWrappingResult => giftWrappingResult.response),
    isNotNil(),
    map(giftwrappingResult => !!giftwrappingResult.GreetingCardProductID),
  );

  /**
   * Get giftwrapping costs to show on basket bottom info
   * @see https://n6k.atlassian.net/browse/OPTR-19388
   */
  public giftwrappingCosts$ = getShoppingCart.getResponse$().pipe(
    isNotNil(),
    map(cart => {
      const costs: ILocalizedPrice = {
        price: get(cart, 'Amounts.Types.GiftWrappingCosts.Amount', 0),
        priceInTax: get(cart, 'Amounts.Types.GiftWrappingCosts.InTax', 0)
      };
      return costs;
    })
  )

  /** The total discount amount on this shoppingcart.
   */
  public discountTotal$: Observable<ILocalizedPrice> = this.amounts$.pipe(
    map( amounts => {
      const discountPricing = this.constructLocalizedPricing(amounts.Types['Discount' as any]);

      return discountPricing;
    })
  );

  /** Whether the discount total should be shown or not.
   */
  public showDiscountTotal$: Observable<boolean> = this.discountTotal$.pipe(
    map(discountTotal => discountTotal.price !== 0 && discountTotal.priceInTax !== 0 )
  );

  /** The total cost of shipping all lines in the current shoppingcart.
   */
  public shippingCosts$: Observable<ILocalizedPrice> = getShoppingCart.getResponse$().pipe(
    map(response => <EVA.Core.OrderLineDto[]>get(response, 'ShoppingCart.Lines', [])),
    isNotNil(),
    map(lines => {
      /** The line representing the shipping cost, see OrderLineTypes enum to understand why 5 */
      const shippingCostLine = lines.find(line => line.Type === 5);

      const totalShippingCost: ILocalizedPrice = {
        price: get(shippingCostLine, 'TotalAmount', 0),
        priceInTax: get(shippingCostLine, 'TotalAmountInTax', 0)
      };

      return totalShippingCost;
    })
  );

  /**
   * Whether to show the added tax or not, we will only do so if PreferredPriceDisplayMode = OrderPreferredPriceDisplayMode.InTax
   * @see https://n6k.atlassian.net/browse/OPTR-15725
   */
  public showAddedTax$: Observable<boolean> = getShoppingCart.getResponse$().pipe(
    map( response => response?.ShoppingCart.PreferredPriceDisplayMode === EVA.Core.OrderPreferredPriceDisplayMode.InTax)
  );

  public preferredPriceDisplayMode$: Observable<EVA.Core.OrderPreferredPriceDisplayMode> = getShoppingCart.getResponse$().pipe(
    map( response => response?.ShoppingCart.PreferredPriceDisplayMode)
  );

  public taxes$ = this.amounts$.pipe(
    map(amounts => amounts.Taxes )
  );


  /** Whether we should show the shipping costs or not.
   */
  public showShippingCosts$: Observable<boolean> = this.shippingCosts$.pipe(
    map(shippingCosts => shippingCosts.price > 0 && shippingCosts.priceInTax > 0 )
  );

  /** Represents the selected swipe up option.
   */
  public selectedSwipeUpOption: string | null;

  public couponForm: FormGroup = this.fb.group({
    couponCode: [null]
  });

  public orderLineAction$: Observable<ICurrentOrderType> = combineLatest([
    getShoppingCart.getState$(),
    this.orderTypes$.pipe(
      filter( orderTypes => !isEmpty(orderTypes) )
    )
  ]).pipe(
    map(([state, orderTypes]) => {
        switch (state.orderLineActionTypes.length) {
          // If there's no orderLineActionTypes, return nothing.
          //
          case 0:
            const currentOrderType: ICurrentOrderType = {
              label: this.$translate.instant('order.type.none') as string,
              icon: {
                value: 'close-outline',
                type: 'ionicon'
              }
            };

            return currentOrderType;

          // If there's one orderLineActionType, return that type.
          // Return it as a string to allow checks in the template.
          //
          case 1:
            const lineActionType = state.orderLineActionTypes[0].toString();
            const matchingOrderType = orderTypes.find(orderType => orderType.id === lineActionType);

            return {
              icon: matchingOrderType.icon,
              label: this.$translate.instant(matchingOrderType.translation) as string
            };

          // If there's more than 1 types, return 'manual', to indicate that it's a mixed order.
          //
          default:
            return {
              label: this.$translate.instant('order.type.mixed') as string,
              icon: {
                type: 'ionicon',
                value: 'shuffle-outline'
              }
            } as ICurrentOrderType;
        }
    })
  );

  public intlNumberTaxOpts: Intl.NumberFormatOptions = {
    style: 'percent',
    maximumFractionDigits: 2
  };

  public get isOffline() {
    return this.$sentinel.isOffline;
  }

  isInterbranchOrder$ = getShoppingCart.getState$().pipe(
    map( state => state.checkoutStatus),
    map((checkoutState) => checkoutState.mode === OrderMode.interbranch)
  );

  isRtsOrder$ = getShoppingCart.getState$().pipe(
    map( state => state.checkoutStatus),
    map((checkoutState) => checkoutState.mode === OrderMode.returnToSupplier)
  );

  public interlogisticsOrder$: Observable<boolean> = getShoppingCart.getState$().pipe(
    map( state => state.checkoutStatus),
    map((checkoutState) => {
      const inInterlogisticsOrder = checkoutState.mode === OrderMode.returnToSupplier || checkoutState.mode === OrderMode.interbranch;

      return inInterlogisticsOrder;
    })
  );

  constructor(
    private fb: FormBuilder,
    private navCtrl: NavController,
    private $translate: TranslateService,
    private toastCtrl: EvaToastController,
    private $evaErrorGenerator: EvaErrorGeneratorProvider,
    private $scanPageProvider: ScanPageProvider,
    private modalCtrl: ModalController,
    private $applicationConfig: EvaApplicationConfigProvider,
    private $interLogisticsSwitch: InterLogisticsSwitchProvider,
    private $sentinel: SentinelProvider,
    private $summaryCheckoutProvider: SummaryCheckoutProvider,
    private $orderLifecycleService: OrderLifecycleService,
    private $basket: BasketService,
  ) { }

  ngOnInit() {
    this.updateOrderTypes();
  }
  /** When the orderActionType changes, this method will switch to the new value. */
  async onOrderActionTypeChange(currentOrderActionType: IOrderType) {
    const { id: type } = currentOrderActionType;

    // If the button is disabled, show a toast explaining why.
    //
    if (currentOrderActionType.disabled === true) {
      // const orderTypeTranslation = this.$translate.instant(orderType.translation);
      this.toastCtrl.showMessage(this.$translate.instant('order.type.disabled'));

      return;
    }

    let orderActionTypePromise: Promise<any>;

    const shoppingCartState = getShoppingCart.getState();

    const currentOrderId: number = get(shoppingCartState, 'response.ShoppingCart.ID');

    this.swipeIndicator.pullDown();

    // Try block. The catch and finally blocks are the same for either paths.
    //
    try {
      let fetchPromise: Promise<EVA.Core.ShoppingCartResponse>;
      // Just modify the orderLineActionType.
      //
      if (+type === 4) {
        const request: EVA.Core.ChangeOrderLinesToDelivery = {
          OrderID: currentOrderId,
        };
        const {cartOperationPromise} = await this.$orderLifecycleService.changeOrderLinesToDelivery({request});
        fetchPromise = cartOperationPromise;
      } else if (+type === 3 || +type === 2) {
        let request: EVA.Core.ChangeOrderLinesToCarryOut;
        if (+type === 3) {
          request = {
            OrderID: currentOrderId,
          };
        } else {
          request = {
            OrderID: currentOrderId,
            MustBeOrdered: true,
          };
        }
        const {cartOperationPromise} = await this.$orderLifecycleService.changeOrderLinesToCarryOut({request});
        fetchPromise = cartOperationPromise;
      } else if (+type === 1) {
        const request: EVA.Core.ChangeOrderLinesToPickup = {
          OrderID: currentOrderId,
        };
        const {cartOperationPromise} = await this.$orderLifecycleService.changeOrderLinesToPickup({request});
        fetchPromise = cartOperationPromise;
      }

      await fetchPromise;

      this.logger.log('Modifying order line action type success');

      const label = this.orderTypes$.value.find(orderType => orderType.id === type);

      const message = this.$translate.instant('order.type.modify.success', {
        value: this.$translate.instant(label.translation)
      });

      this.toastCtrl.showMessage(message);
    } catch (error) {
      this.logger.error('Modifying order line action type failed', error);

      const evaError = await this.$evaErrorGenerator.constructFailureFeedback(
        error,
        this.$translate.instant('order.type.modify.fail')
      );

      this.toastCtrl.create(evaError).present();
    } finally {
      this.swipeIndicator.pullDown( () => {
        this.selectedSwipeUpOption = null;
      });
    }
  return orderActionTypePromise;
  }

  async onCouponFormSubmit(couponControl: AbstractControl) {
    const value = couponControl.value as string;

    const hasPendingPayments = await getShoppingCart.getResponse$().pipe(
      first(),
      map(response => response?.ShoppingCart?.Lines?.length > 0 && response?.Amounts?.Paid?.Pending !== 0 && !response?.ShoppingCart?.IsPaid)
    ).toPromise();

    if (!hasPendingPayments) {
      await this.addCoupon(value);
      couponControl.reset();
      return;
    }

    const confirmation = await this.$basket.askConfirmationOnOrderChange();

    if (confirmation?.role === 'confirm') {
      await this.addCoupon(value);
    } else {
      this.swipeIndicator.pullDown();
    }

    couponControl.reset();

  }

  /** Adds a coupon to the current cart */
  async addCoupon(couponCode: string, returnResponse?:any) {
    const orderID = await this.orderId$.pipe(first()).toPromise();

    const [action, , chainPromise] = addDiscountCouponToOrder.createFetchAction({
      OrderID: orderID,
      CouponCode: couponCode,
    });

    store.dispatch(action);

    const toast = this.toastCtrl.create();

    chainPromise.then(async (data: TAddDiscountCouponToOrderChainData) => {
        const [discount] = data;
        const requirementsNotMet = await this.$basket.checkDiscountRequirements(orderID,discount?.['DiscountID']);
        const applied = this.$translate.instant('discount.add.success');
        this.swipeIndicator.pullDown();
        toast.setData({ message: requirementsNotMet || applied });
      })
      .catch(async error => {
        const message = this.$basket.checkApplyDiscountFailedReason(error?.response?.Error);
        const evaError = await this.$evaErrorGenerator.constructFailureFeedback(error,message);
        toast.setData(evaError);
      })
      .then(() => {
        toast.present();
      });

    if(returnResponse){
      return chainPromise;
    }

    return toast.onDidDismiss();
  }

  /** Detaches the current order from the session.
   */
  public emptyBasket() {
    let loadingPromise: Promise<any>;

    this.swipeIndicator.pullDown(async () => {
      try {
        loadingPromise = await this.$summaryCheckoutProvider.emptyBasket();
      } catch (error) {
        this.logger.error('emptying basket fail', error);
      }
    });
    return loadingPromise;
  }

  /** Manages all button logic for the secondary actions.
   * @param option The option that's been selected.
   */
  public async select(option: string) {
    switch (option) {
      case 'emptyBasket':
        this.selectedSwipeUpOption = this.selectedSwipeUpOption !== 'emptyBasket' ? 'emptyBasket' : null;
        break;
      case 'discount':
        const checkIgnoreDiscountsEnabled = await this.$orderLifecycleService.checkIgnoreDiscountsEnabled();
        if (!checkIgnoreDiscountsEnabled){
          this.navCtrl.navigateForward('discount');
        }
        this.swipeIndicator.pullDown();
        break;
      case 'pause':
        await this.openPauseOrderModal();
        break;
      case 'coupon':
        // if `AddDiscountToOrder` is unavailable in sentinel mode, we want to reutn early.
        //
        if ( this.isOffline && !this.$sentinel.isServiceAvailableOffline('AddDiscountToOrder') ) {
          this.toastCtrl.showMessage( this.$translate.instant('sentinel.offline.page.unavailable') );

          return;
        }
        const isIgnoreDiscountsEnabled = await this.$orderLifecycleService.checkIgnoreDiscountsEnabled();
        if(!isIgnoreDiscountsEnabled){
          this.logger.log('coupon clicked');
          this.selectedSwipeUpOption = this.selectedSwipeUpOption !== 'coupon' ? 'coupon' : null;
          this.logger.log(this.selectedSwipeUpOption);
        } else {
          this.swipeIndicator.pullDown();
        }
        break;
      case 'orderActionType':
        if (this.isOffline && !this.$sentinel.isServiceAvailableOffline('ModifyLineActionType')) {
          this.toastCtrl.showMessage(this.$translate.instant('sentinel.offline.page.unavailable'));

          return;
        }

        // Requirement: If order is an RMA order you cannot change
        // the order line action type. In that case we warn the user
        // that changing is not an option, also the next variable assignment assumes theres a shopping cart at the time
        // the order line is changed which is a safe to do because this component wont be rendered if there is no cart
        //
        const orderCharacteristics = getShoppingCart.getState().response.ShoppingCart.Characteristics;
        if (orderCharacteristics & 128) {
          this.toastCtrl
            .create({
              message: this.$translate.instant('order.type.modify.not_allowed')
            })
            .present();
        } else {
          this.selectedSwipeUpOption = this.selectedSwipeUpOption !== 'orderActionType' ? 'orderActionType' : null;
        }
        break;
      default:
        this.selectedSwipeUpOption = null;
        break;
    }
  }

  public clearCoupon() {
    this.couponForm.reset();

    this.selectedSwipeUpOption = null;
  }

  /** Opens the scan page for coupon scanning.
   */
  public async scanCouponCode() {
    const scanConfig = await this.$scanPageProvider.openPage({
      title: this.$translate.instant('scan.coupon.id'),
      typeOfScan: ['NONE'],
      navCtrl: this.navCtrl
    });

    const hasPendingPayments = await getShoppingCart.getResponse$().pipe(
      first(),
      map(response => response?.ShoppingCart?.Lines?.length > 0 && response?.Amounts?.Paid?.Pending !== 0 && !response?.ShoppingCart?.IsPaid)
    ).toPromise();

    scanConfig.relatedScan$$.subscribe(async barcodeScan => {

      // We want to return to the basket after successfully scanning the code
      // @see https://n6k.atlassian.net/browse/OPTR-19001
      if (!hasPendingPayments) {
        this.addCoupon(barcodeScan.rawData.barcode,true).then(() => {
          this.$scanPageProvider.dismissPage(this.navCtrl);
        })
        return;
      }

      const confirmation = await this.$basket.askConfirmationOnOrderChange();

      if (confirmation?.role === 'confirm') {
        await this.addCoupon(barcodeScan.rawData.barcode);
      }

      // We don't want to dismiss this page until the toast is dismissed. Because otherwise the user might end up
      // scanning the same thing twice. Once on the barcode scan screen and once on the sales screen. Waiting for this
      // will give the user time to look away. Otherwise we get double scans that aren't preventable as any scanner pages that are entered
      // resume scanning implicitly by calling `this.scanditPicker.startScanning();`
      // via ` Scannable.ionViewWillEnter => ScannableDefaultConfig.onActivate => cameraPlaceholder.register => registerPlaceholder => expand => startScan `
      // @see https://eva2015.atlassian.net/browse/OPTR-3550
      //
      this.$scanPageProvider.dismissPage(this.navCtrl);
    });

    scanConfig.unrelatedScan$$.subscribe(() => {
      this.toastCtrl.create({
        message: this.$translate.instant('scan.coupon.id.unrelated.scan') as string
      });

      this.$scanPageProvider.dismissPage(this.navCtrl);
    });
  }

  async switchToSalesOrder() {
    try {
      await this.$interLogisticsSwitch.createSalesOrder();
    } catch {} finally {
      this.swipeIndicator.pullDown();
    }
  }

  public async switchToRtsOrder() {
    try {
      await this.$interLogisticsSwitch.createRmaOrder({ forceCreate: true });
    } catch {} finally {
      this.swipeIndicator.pullDown();
    }

  }
  public async switchToInterbranchOrder() {
    try {
      await this.$interLogisticsSwitch.createInterbranchOrder({ forceCreate: true });
    } catch {} finally {
      this.swipeIndicator.pullDown();
    }
  }

  private async openPauseOrderModal() {
    const pauseOrderModal = await this.modalCtrl.create( {
      component: PauseOrderModalComponent
    });

    await pauseOrderModal.present();

    await pauseOrderModal.onDidDismiss();

    this.swipeIndicator.pullDown();
  }

  private constructLocalizedPricing(amountData: EVA.Core.OrderAmount): ILocalizedPrice {
    const discountPricing: ILocalizedPrice = {
      price: get(amountData, 'Amount', 0),
      priceInTax: get(amountData, 'InTax', 0)
    };

    return discountPricing;
  }

  private async updateOrderTypes() {
    const orderTypes: IOrderType[] = [];

    const lineActionTypesAvailable = await this.lineActionTypesAvailable$.pipe(first()).toPromise();

    // Prepare a list of all available orderLineActionTypes.
    //
    const orderLineActionTypes: IOrderType[] = [{
      id: '1', // ReserveLine
      translation: 'order.type.reserve',
      icon: {
        value: 'flag-outline',
        type: 'ionicon'
      }
    },
    {
      id: '2', // OrderLine
      translation: 'order.type.order',
      icon: {
        type: 'svg',
        value: './svg/receipt.svg'
      },
    },
    {
      id: '3', // ShipLine
      translation: 'order.type.shipline',
      icon: {
        type: 'ionicon',
        value: 'bag-handle-outline'
      }
    },
    {
      id: '4', // Delivery
      translation: 'order.type.deliver',
      icon: {
        type: 'svg',
        value: './svg/local_shipping.svg'
      }
    }];

    // Prepare a list of the orderLineActionTypes with their allowed
    orderLineActionTypes.forEach(orderLineActionType => {

      // If an orderLineActionType is not available, we set it to be disabled.
      //
      orderLineActionType.disabled = includes(lineActionTypesAvailable, + orderLineActionType.id) === false;
      return orderLineActionType;
    });

    const sortedOrderLineActionTypes = orderLineActionTypes.sort((a, b) => {
      if (a.disabled) {
        return 1;
      } else if (b.disabled) {
        return -1;
      } else {
        return lineActionTypesAvailable.indexOf(+ a.id) - lineActionTypesAvailable.indexOf(+ b.id);
      }
    });

    // Add the orderLineActionType to the orderTypes list as an OrderType object.
    //
    orderTypes.push(...sortedOrderLineActionTypes);

    this.orderTypes$.next(orderTypes);
  }

}
