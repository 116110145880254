import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { barcode, elevationHelper, getCurrentUser, getUser, IElevationEvent, store } from '@springtree/eva-sdk-redux';
import { debounceTime, filter,first,map,take, takeUntil } from 'rxjs/operators';
import { EvaApplicationConfigProvider } from 'src/app/services/eva-application-config/eva-application-config';
import { fadeInOut } from '../../shared/animations';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { TranslateService } from '@ngx-translate/core';
import isNotNil from '../../shared/operators/isNotNil';
import { EvaFeedback } from 'src/app/shared/decorators/eva-feedback';

enum VerificationCodeStatus {
  LOADING,
  ERROR,
  LOADED
}

/**
 * GENERIC Elevation QR Code Modal
 *
 * We try to validate any operation which has the elevation functionality:
 * - We show the QR modal to be able to scan it
 * - When is successfully scanned by the manger (with his device),
 * - we call the initial service again also passing the elevation token.
 * - OR we return the elevation token to be used multiple times.
 *
 * @see https://n6k.atlassian.net/browse/OPTR-23336
 */
@Logger('[generic-elevation-check-qr-modal]')
@Component({
  selector: 'eva-elevation-check-qr-modal',
  templateUrl: 'elevation-check-qr-modal.html',
  styleUrls: ['elevation-check-qr-modal.scss'],
  animations: [fadeInOut]
})
export class GenericElevationCheckQRModal implements OnInit, ILoggable, OnDestroy {

  public logger: Partial<Console>;

  public orderId: number = this.navParams.get('orderId');

  public functionalityForCheck: string = this.navParams.get('functionality');

  public evaVerificationStatus = VerificationCodeStatus.LOADING;

  public evaVerificationStatusEnum = VerificationCodeStatus;

  public verificationQRCode: string;

  public imageLoading = true;

  public requestToken: string;

  private stop$ = new Subject<void>();

  // Determine if we must return the elevation token or execute a service
  public shouldReturnToken: boolean = this.navParams.get('mustReturnToken');

  // The service/method you want to invoque once the elevation permission is given
  public serviceToExecute: Function = this.navParams.get('serviceToExecute');

  constructor(private $appConfig: EvaApplicationConfigProvider,
              private modalCtrl: ModalController,
              private navParams: NavParams,
              private $translate: TranslateService,
              private toastCtrl: EvaToastController,
  ) {}

  async ngOnInit() {
    const currentUserElevatedFunctionalities = await getCurrentUser.getResponse$().pipe(
      isNotNil(),
      map(getCurrentUserResponse => getCurrentUserResponse.User.ElevationFunctionalities),
      first()
    ).toPromise();

    const elevatedFunctionality = (this.functionalityForCheck in currentUserElevatedFunctionalities);

    if (elevatedFunctionality) {
      // This is the main mechanism to get notified when a manager scans the barcode (on another device)
      elevationHelper.elevationEvent$.pipe(
        map( data => data as IElevationEvent),
        filter(event => (event.functionality === this.functionalityForCheck)),
        debounceTime(400),
        takeUntil(this.stop$)
      ).subscribe(async (event: IElevationEvent) => {
        if(event.status === 'ACTIVE'){
          // Create the QR code to be readed by the manager
          this.createQRCodeImage(event);
        } else if(event.status === 'CONFIRMED' && event.requestToken === this.requestToken){
          // We call the service again passing the token this time
          this.processOperationWithPermissionToken(event);
        }
      });
    }

    // We need to run the service at least once to fire the elevationHelper
    this.serviceToExecute(null);
  }

  private async createQRCodeImage(event: IElevationEvent) {
    this.requestToken = event.requestToken;
    if (isEmpty(event.barcode)) {
      this.evaVerificationStatus = VerificationCodeStatus.ERROR;
      this.confirmPermissions(false);
      this.logger.log(`error generating QR code when verify operation for orderId ${this.orderId}`);
    } else {
      this.evaVerificationStatus = VerificationCodeStatus.LOADED;
      this.verificationQRCode = await this.generateEvaQrCode(event.barcode);
      this.logger.log(`generated QR successfully with code ${event.barcode}`);
    }
  }

  // This method decides if we should return the elevation token or execute the service
  private async processOperationWithPermissionToken(event: IElevationEvent) {
    this.stop$.next(); // We stop listening to this stream as soon as the verification is done

    if(this.serviceToExecute && !this.shouldReturnToken){
      this.executeService({
        elevationToken: event.requestToken,
        confirmedUserId: event.confirmedUserId
      });
    } else {
      this.confirmPermissions(event.requestToken);
    }
  }

  // Execute the initial service that we injected into the modal
  private async executeService(params){
    try {
      this.evaVerificationStatus = VerificationCodeStatus.LOADING;
      await this.serviceToExecute(params.elevationToken);

      // Display the manager info
      if(params.confirmedUserId) {
        this.showUserToast(params.confirmedUserId);
      }
      this.confirmPermissions(params.elevationToken);

    } catch (error) {
      this.logger.log(`Error verifying customer QR for orderId ${this.orderId}`);
      this.evaVerificationStatus = VerificationCodeStatus.ERROR;
    }
  }

  /**
   * We display the manager fullName who approved the operation
   */
  private async showUserToast(confirmedUserId: number) {
    const [action] = getUser.createFetchAction({
      ID: confirmedUserId
    });

    try {
      const userResponse = await getUser.fetchData(action);
      if(userResponse.ID){
        this.toastCtrl.showMessage( this.$translate.instant('verify.operation.manager.name', {
          fullname: userResponse.FullName.toUpperCase()
        }));
      }
    } catch (error) {
      this.logger.error(`error calling getUser with id=${confirmedUserId}`, error);
      // In case getUser fails, at least we give feedback about the verification operation
      this.toastCtrl.showMessage( this.$translate.instant('verify.operation.finished') );
    }
  }

  /**
   * Create secure QR image using BE service
   * @see https://n6k.atlassian.net/browse/OPTR-23966
   */
  @EvaFeedback({
    i18nFailKey: 'generate.qr.code.fail',
  })
  private createBarcodeQR(qrLink: string){
    const [createBarcodeAction, getBarcodePromise] = barcode.createFetchAction({
      Code: qrLink,
      Width: 200,
      Height: 200
    });

    store.dispatch(createBarcodeAction);
    getBarcodePromise.catch((error) => {
      this.logger.error('error generating QR code for elevation check', error);
    });

    return getBarcodePromise;
  }

  async generateEvaQrCode(code: string): Promise<string> {
    const barcodeResponse = await this.createBarcodeQR(code);
    const imagePath = `data:${barcodeResponse.MimeType};base64,${barcodeResponse.Data}`;
    this.imageLoading = false;
    return imagePath;
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  /**
   * We close the modal and return the result
   * false: if the operation was denied
   * string: elevationToken in case we need it somewhere else
   */
  public async confirmPermissions(approved: boolean | string) {
    this.modalCtrl.dismiss(approved);
  }

}
