<small class="eco-pricing" *ngIf="(showEcoTax$ | async) && (productEcoTaxPrice?.ecoTaxInTax > 0 || productTotalEcoTaxPrice?.ecoTaxInTax > 0)">
  <span>{{ 'item.price.eco.include' | translate }}</span>
  <eva-localized-price
    [currencyString]="currencyID" [pricing]="productEcoTaxPrice"
    [preferredPriceDisplayMode]="preferredPriceDisplayMode">
  </eva-localized-price>
  <span>
    {{ 'item.price.eco.tax' | translate }}<span *ngIf="showItem">/{{ 'item' | translate | titlecase }}</span>
    <span *ngIf="showTotal">, {{ 'total' | translate }}:</span>
  </span>
  <eva-localized-price *ngIf="showTotal"
    [currencyString]="currencyID" [pricing]="productTotalEcoTaxPrice"
    [preferredPriceDisplayMode]="preferredPriceDisplayMode">
  </eva-localized-price>
</small>
