import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { EvaApplicationConfigProvider } from 'src/app/services/eva-application-config/eva-application-config';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';

/**
 * this route will be disabled if the runner tasks are disabled
 * @see https://eva2015.atlassian.net/browse/OPTR-3185
 */
@Logger('[runner-task-create-guard-service]')
@Injectable({
  providedIn: 'root'
})
export class RunnerTaskCreateGuardService implements CanActivate, ILoggable {
  logger: Partial<Console>;

  constructor(private $appConfig: EvaApplicationConfigProvider, private navCtrl: NavController) {}

  async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {

    const shouldEnter = this.$appConfig.runnerTasksEnabled$.pipe(
      first()
    ).toPromise();

    if (!shouldEnter) {
      try {
        await this.navCtrl.navigateRoot(['']);
      } catch (error) {
        this.logger.error('error navigating to tasks page', error);
      }
    }

    return shouldEnter;
  }
}
