<ion-header>
  <ion-toolbar>
    <ion-title> {{ 'insufficient.stock.title' | translate }} </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close" class="close-icon"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <eva-product-complex-card class="ion-padding" [product]="productDetails$ | async">
  </eva-product-complex-card>

  <hr class="ion-no-margin">

  <p class="ion-padding-horizontal"> {{ errorMessage }}</p>
</ion-content>
<ion-footer class="vertical-buttons">
  <ion-button expand="full"  (click)="dismiss()" color="light">
    <span>{{ 'action.cancel' | translate }}</span>
  </ion-button>

  <ion-button expand="full" (click)="confirm()" color="primary">
    <span>{{ 'action.confirm' | translate }}</span>
  </ion-button>
</ion-footer>
