import { Component, Input, ViewChildren, ElementRef, QueryList, ViewChild } from '@angular/core';
import { AnimationBuilder, animate, style, keyframes, AnimationPlayer } from '@angular/animations';

/**
 * This component will render a few dots on the screen that will animate back and forth
 * These dots are best used to show progress
 * @see https://stackblitz.com/edit/angular-dots-animation
 */
@Component({
  selector: 'eva-dots',
  templateUrl: './dots.component.html',
  styleUrls: ['./dots.component.scss']
})
export class DotsComponent {

  public totalDots: boolean[] = [];

  private _total: number;

  public get total(): number {
    return this._total;
  }

  @Input()
  public set total(value: number) {
    if ( this._total !== value ) {
      this._total = value;
      this.totalDots = Array(value).fill(true);
    }
  }

  private _selectedIndex: number;

  public get selectedIndex(): number {
    return this._selectedIndex;
  }

  @Input()
  public set selectedIndex(newIndex: number) {
    if ( newIndex !== this._selectedIndex ) {
      this._selectedIndex = newIndex;
      if ( this.dots && this.totalDots[newIndex] ) {
          this.setSelected(newIndex);
      }
    }
  }

  public interalSelectedIndex = 0;

  public get hostXLocation(): number {
    const el = this.el.nativeElement as HTMLElement;

    return el.getBoundingClientRect().left;
  }

  public isAnimating = false;

  public DOT_WIDTH = 10;

  public dotStyle = {
    width: this.DOT_WIDTH + 'px',
    height: this.DOT_WIDTH + 'px'
  };

  /** Get handle on cmp tags in the template */
  @ViewChildren('dot') dots: QueryList<ElementRef<HTMLElement>>;

  @ViewChild('animatable') animatable: ElementRef<HTMLElement>;

  private currentPlayer: AnimationPlayer;

  constructor(private animationBuilder: AnimationBuilder, private el: ElementRef) { }

  async setSelected(newIndex: number) {

    if ( this.currentPlayer ) {
      // If there is a current palyer, lets wait for it to finish
      const promise = new Promise<void>((resolve) => {
        this.currentPlayer.onDone(resolve);
      });

      await promise;
    }

    const startDot = this.dots.toArray()[this.interalSelectedIndex];

    const xStart = startDot.nativeElement.getBoundingClientRect().left - this.hostXLocation;

    const endDot = this.dots.toArray()[newIndex];

    const xEnd = endDot.nativeElement.getBoundingClientRect().left - this.hostXLocation;

    const newWidth = Math.abs(xEnd - xStart) + this.DOT_WIDTH;

    /** Whether we are going left or not */
    const goingRight = xStart < xEnd;

    const middleAnimationStyle = {
      width: newWidth,
      left: xEnd + 'px',
      offset: 0.5
    };

    const endAnimationStyle = {
      left: xEnd + 'px',
      width: this.DOT_WIDTH,
      offset: 1.0
    };

    if ( goingRight ) {
      delete middleAnimationStyle.left;
    } else {
      delete endAnimationStyle.left;
    }

    const animation = this.animationBuilder.build([
      animate(`300ms ease-out`, keyframes([
        style({ left: xStart + 'px', offset: 0 }),
        style(middleAnimationStyle),
        style(endAnimationStyle)
      ]))
    ]);

    this.currentPlayer = animation.create(this.animatable.nativeElement);

    this.currentPlayer.onStart(() => this.isAnimating = true );

    this.currentPlayer.play();

    this.currentPlayer.onDone(() => {
      this.isAnimating = false;
      this.interalSelectedIndex = newIndex;
      this.currentPlayer = null;
    });
  }

}
