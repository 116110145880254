<ion-header>
  <ion-toolbar>
    <ion-title>{{ itemSearchData.title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="searchInputForm">
    <ion-item>
      <ion-input type="search" formControlName="searchString" [placeholder]="'enter.search.key' | translate" clearInput>
      </ion-input>
    </ion-item>
  </form>

  <ion-virtual-scroll [items]="itemSearchData.items | fusejs:fusejsSearchModel:fusejsOpts" approxItemHeight="320px">
    <ion-item *virtualItem="let item" (click)="dismiss(item)">
      <div *ngIf="item.fuseJsHighlighted; else name" [innerHTML]="item.fuseJsHighlighted[itemSearchData.labelKey]"></div>
      <ng-template #name>
        {{item[itemSearchData.labelKey]}}
      </ng-template>
    </ion-item>
  </ion-virtual-scroll>
</ion-content>

