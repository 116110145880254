<ion-row class="ion-justify-content-between">
  <h6 class="ion-no-margin">{{ shipment.TotalQuantityShipped }} {{ 'products' | translate }}</h6>
  <time [attr.datetime]="shipment.CreationTime" class="fz-12">
    {{ shipment.CreationTime | intlDate }}
  </time>
</ion-row>

<p>{{ shipment.BackendID }}</p>

<ion-row class="ion-justify-content-between">
  <p class="ion-no-margin">{{ 'estimated.packages' | translate }} {{ shipment.TotalPackageCountIndication }}</p>
  <!-- This needs to be changed to deliveryDate when the backend implaments it TO:DO-->
  <span>
    <ion-row class="ion-align-items-center">
      <ng-container *ngIf=" shipment.ShipmentDate | isBeforeToday ">
        <ion-icon name="alert-circle-outline" color="danger"></ion-icon>
        &nbsp;
      </ng-container>

      <time [attr.datetime]="shipment.ShipmentDate" class="fz-12">
        {{ 'expected' | translate }} {{ shipment.ShipmentDate | intlDate }}
      </time>
    </ion-row>
  </span>
</ion-row>
