import { Component, Input } from '@angular/core';
import { EvaProductStatusProvider } from 'src/app/services/eva-product-status/eva-product-status';
/**
 * A component to render the product status chip used inside the product card
 *
 * @see https://eva2015.atlassian.net/browse/OPTR-2932
 */
@Component({
  selector: 'eva-product-status-chip',
  template: `
    <span *ngFor="let i18nLabel of labels">
      {{ i18nLabel | translate }}
    </span>`,
  styles: [`
  :host {
    display: inline;
  }

  span {
    border: 1px solid #979797;
    height: 21px;
    color: #979797;
    font-size: 10px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
    padding: 2px 4px;
    margin-right: 4px;
    text-transform: uppercase;
    border-radius: 2px;
  }

  span:last-child {
    margin-right: 0;
  }
  `],
})
export class ProductStatusChipComponent {

  public labels: string[] = [];
  private _status: number;

  @Input()
  public set status(value: number | null) {
    this._status = value;
    this.labels.length = 0;
    const productStatusesTranslationKeys = this.$productStatus.getProductStatusTranslationKeys(value);
    this.labels.push(...productStatusesTranslationKeys);
  }

  public get status() {
    return this._status;
  }

  public constructor(
    private $productStatus: EvaProductStatusProvider
  ) {

  }
}
