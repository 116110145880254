import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { SentinelProvider } from 'src/app/services/sentinel/sentinel';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';

/**
 * the user can only visit the product details page if they're not in sentinel mode
 */
@Logger('[product-details-guard-service]')
@Injectable({
  providedIn: 'root'
})
export class ProductDetailsGuardService implements CanActivate, ILoggable {
  logger: Partial<Console>;

  constructor(private navCtrl: NavController, private $sentinel: SentinelProvider, private toastCtrl: EvaToastController, private $translate: TranslateService) {}

  async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    const shouldOpen = !this.$sentinel.isOffline

    this.logger.log(
      "canActivate checking if not in offline mode, isNotOffline =", shouldOpen);

    if (!shouldOpen) {
      this.toastCtrl.showMessage(
        this.$translate.instant("sentinel.offline.page.unavailable")
      );
      try {
        this.logger.log(`sentinel is in offline mode, navigating away`);
        await this.navCtrl.navigateRoot(['tabs/basket']);
      } catch (error) {
        this.logger.error('error navigating to basket page', error);
      }
    }

    return shouldOpen;
  }
}
