import { ILoggable, Logger } from '../../shared/decorators/logger';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EvaErrorGeneratorProvider } from '../eva-error-generator/eva-error-generator';
import { EvaApplicationConfigProvider } from '../eva-application-config/eva-application-config';
import { ModalController } from '@ionic/angular';
import { isNil, get } from 'lodash';
import { first } from 'rxjs/operators';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { ILineActionTypeOrderElement, InsufficientStockModalComponent } from 'src/app/modals/insufficient-stock-modal/insufficient-stock-modal';
import { OrderLifecycleService } from '../order-lifecycle/order-lifecycle.service';
import { getShoppingCart, OrderMode } from '@springtree/eva-sdk-redux';

@Logger('[eva-insufficient-stock-provider]')
@Injectable()
export class EvaInsufficientStockProvider implements ILoggable {

  /**
   * Logger implementation
   */
  public logger: Partial<Console>;

  constructor(
    private $translate: TranslateService,
    private toastCtrl: EvaToastController,
    private $evaErrorGenerator: EvaErrorGeneratorProvider,
    private $applicationConfig: EvaApplicationConfigProvider,
    private $orderLifecycleService: OrderLifecycleService,
    private modalCtrl: ModalController
  ) { }

  /**
   * Handle the insufficient stock error by either showing the Insufficient Stock Modal or showing a toast with the generic error message
   */
  public async handleInsufficientStockError(error: any, actionData: Partial<EVA.Core.AddProductToOrder>) : Promise<ILineActionTypeOrderElement> {
    // We prevent showing the modal when order is RMA/Interstore
    // @see https://n6k.atlassian.net/browse/OPTR-16377
    const order = await getShoppingCart.getResponse$().pipe(first()).toPromise();
    const currentPage = await this.$orderLifecycleService.orderCharacteristics$.pipe(first()).toPromise();
    const characteristics = order.ShoppingCart.Characteristics;
    const isRmaOrder = Boolean(characteristics & 128);
    const isInterbranchOrder = Boolean(characteristics & 4);
    const isRMAPage = currentPage === OrderMode.returnToSupplier;
    const isInterstorePage = currentPage === OrderMode.interbranch;
    const lineActionTypeOrderElement = await this.determineLineActionTypeOrderElement();

    if (isRmaOrder || isInterbranchOrder || isRMAPage || isInterstorePage) {
      return lineActionTypeOrderElement;
    }

    if (isNil(lineActionTypeOrderElement)) {
      await this.showToastError(error);
    } else {
      return this.showInsufficientStockModal(lineActionTypeOrderElement, actionData);
    }
  }

  private async showInsufficientStockModal(
    lineActionTypeOrderElement: ILineActionTypeOrderElement, actionData: Partial<EVA.Core.AddProductToOrder>
  ): Promise<ILineActionTypeOrderElement> {
    const modal = await this.modalCtrl.create({
      component: InsufficientStockModalComponent,
      componentProps: {
        lineActionTypeElement: lineActionTypeOrderElement,
        productId: actionData.ProductID,
      }
    });

    modal.present();

    const modalDismissPromise: Promise<ILineActionTypeOrderElement> = modal.onDidDismiss<ILineActionTypeOrderElement>().then( e => e.data);

    return modalDismissPromise;
  }

  private async showToastError(error: any) {
    const toast = this.toastCtrl.create();
    const feedbackFailure = this.$translate.instant('product.add.fail') as string;
    const evaError = await this.$evaErrorGenerator.constructFailureFeedback(error, feedbackFailure);
    toast.setData({
      ...evaError,
      title: feedbackFailure,
      message: get(error, 'response.Error.Message')
    });

    toast.present();
  }

  private async determineLineActionTypeOrderElement(): Promise<ILineActionTypeOrderElement> {
    const lineActionTypesOrder: ILineActionTypeOrderElement[] = [{
      lineActionTypeLabel: 'shipline',
      lineActionTypeValue: 3
    }, {
      lineActionTypeLabel: 'reserveline',
      lineActionTypeValue: 1
    }, {
      lineActionTypeLabel: 'orderline',
      lineActionTypeValue: 2
    }];

    const availableLineActionTypes = await this.$applicationConfig.lineActionTypesAvailable$.pipe(
      first()
    ).toPromise();

    const matchingLineActionType = lineActionTypesOrder.find((lineActionType) => {
      const lineActionTypeValue = lineActionType.lineActionTypeValue;
      const exists = availableLineActionTypes.includes(lineActionTypeValue);

      return exists;
    });

    if (!isNil(matchingLineActionType)) {
      return matchingLineActionType;
    }
  }
}
