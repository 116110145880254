<ion-header>
  <ion-toolbar>
    <ion-title> {{ (configCustomField?.DisplayName || configCustomField?.BackendID) }} </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close" class="close-icon"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding ion-padding-start ion-padding-end"><br>
  <form [formGroup]="form" *ngIf="customFieldConfig" autocomplete="off" #formDirectiveRef="ngForm">
    <eva-custom-field-form-element #customInput
      [formControlName]="'customField'"
      [openModalForArrays]="false"
      [customField]="customFieldConfig">
    </eva-custom-field-form-element>
  </form>

  <ion-button class="add-sealbag-button"
    expand="full" fill="clear"
    [disabled]="!form.get('customField').value || !form.get('customField').valid"
    (click)="addInputItem()">
    {{ 'add.field' | translate }}
  </ion-button>

  <ng-container>
    <ion-card class="sealbag-number-card" *ngFor="let field of fieldValues$ | async; let index = index">
      <ion-row class="ion-justify-content-between ion-align-items-center">
        <div class="sealbag-label">{{field}}</div>
        <ion-button class="ion-no-margin" fill="clear" (click)="removeInputItem(index)">
          <ion-icon class="remove-icon" name="trash"></ion-icon>
        </ion-button>
      </ion-row>
    </ion-card>
  </ng-container>
</ion-content>

<ion-footer class="ion-no-border ion-no-shadow">
  <!-- We only disable UPDATE button if there are some invalid fields. Also we give to the user the posibility to save empty value -->
  <ion-button expand="full" color="primary" [disabled]="!isValid && (fieldValues$ | async)?.length" (click)="saveDataAction()">
    {{ 'action.update' | translate }}
  </ion-button>
</ion-footer>
