import { ComponentFactoryResolver, Injectable, Injector } from "@angular/core";
import { AngularDelegate, ModalController } from "@ionic/angular";
import { ModalOptions, ComponentRef } from "@ionic/core";
import { noop } from "lodash-es";
import { ILoggable, Logger } from "src/app/shared/decorators/logger";
import { EvaBarcodeScannerProvider } from "../../modules/scanner/eva-barcode-scanner";

/**
 * This will act as a wrapper of the modal controller to ensure we can track when modals are opened/dismissed.
 * We will be using this for the scanner
 * */
@Logger('[modal-controller-wrapper]')
@Injectable()
export class ModalControllerWrapper extends ModalController implements ILoggable {
  logger: Partial<Console>;

  constructor(
    private $barcodeScanner: EvaBarcodeScannerProvider,
    angularDelegate: AngularDelegate,
    componentFactoryResolver: ComponentFactoryResolver,
    injector: Injector
  ) {
    super(angularDelegate, componentFactoryResolver, injector);
  }

  create(opts: ModalOptions<ComponentRef>): Promise<HTMLIonModalElement> {

    const createdModalPromise = super.create(opts);

    createdModalPromise.then(createdModal => {
      createdModal.addEventListener('ionModalDidPresent', () => {
        this.$barcodeScanner.onOverlayEvent({open: true});
      }, {once: true});
      createdModal.onDidDismiss().then(() => {
        this.$barcodeScanner.onOverlayEvent({ open: false });
      }).catch(noop);
    }).catch(noop);

    return createdModalPromise;
  }

}
