import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { createPayment, getShoppingCart, ICheckoutOptions, OrderMode, reserveOrder, store, settings } from '@springtree/eva-sdk-redux';
import { first } from 'rxjs/operators';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { StationSelectorProvider } from '../eva-station-selector/station-selector.provider';
import { PartialShipmentsProvider } from '../partial-shipments/partial-shipments';
import { isEmpty } from 'lodash';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { SelectedOrganisationUnitProvider } from '../selected-organisation-unit/selected-organisation-unit';
import isNotNil from 'src/app/shared/operators/isNotNil';
import { ILineActionsTypes } from 'src/app/pages/basket/components/basket-list/basket-list.component';

@Logger('[eva-checkout-provider]')
@Injectable()
export class EvaCheckoutProvider implements ILoggable {
  logger: Partial<Console>;
  /**
   * Expose the order payments stream from the SDK
   * Will emit on both partial and full payment
   * Contains success state and remaining and change amounts
   *
   */
  public orderPayments$ = createPayment.orderPayments$;


  /**
   * This will contain the current application environment
   * We sadly need this for some customer specific logic
   *
   */
 //  private environment: Promise<IEnvironment> = this.$evaStartup.environmentFile;


  constructor(
    private toastCtrl: EvaToastController,
    private $translate: TranslateService,
    private $station: StationSelectorProvider,
    private $partialShipments: PartialShipmentsProvider,
    private $selectedOrganisationUnit: SelectedOrganisationUnitProvider
  ) { }

  /**
   * This method will try to finalise the order by performing wrap-up actions
   * such as placing and shipping the order.
   * Depending on state and requirement issues this might fail.
   * Methods can be called multiple times to keep tryping to finalize the checkout
   *
   * @param [shipReservation=false] Will change a reservation order to ship if valid and placed
   */
  public async performCheckout(placeWithoutPay = false) {

    const cart = await getShoppingCart.getState$().pipe( first() ).toPromise();

    // Get the current OrderID from the orderPayment.
    //
    const OrderID = cart.response.ShoppingCart.ID;


    // Reserve
    // @see https://eva2015.atlassian.net/browse/OPTR-1039
    //
    if ( cart.checkoutStatus.mode === OrderMode.reservation ) {
      // Get the currently selected station's ID from the station provider.
      //
      const StationID = this.$station.getCurrentStationId();
      this.logger.log('STATION ID', StationID);

      try {

        const currentOrgUnitId = this.$selectedOrganisationUnit.getLastUsedOrganisation()
        // We will only call reserveOrder if the organization unit reserving the order is the pick up organization unit.
        //
        if ( currentOrgUnitId === cart.response.ShoppingCart.PickupOrganizationUnitID ) {
          // Reserve the order using the OrderID and the StationID.
          //
          const [ reserveAction, reservePromise ] = reserveOrder.createFetchAction({
            OrderID,
            StationID
          });

          store.dispatch( reserveAction );

          await reservePromise;
        } else {
          this.logger.log(`Skipping reserveOrder call with orderId=${OrderID} because currentOrgUnitId=${currentOrgUnitId} is not equal to PickupOrganizationUnitID=${cart.response.ShoppingCart.PickupOrganizationUnitID}`);
        }
      } catch ( reserveError ) {
        // Catch any errors that may occur during the reservation and logs it.
        //
        this.logger.error( 'Failed to reserve order', reserveError );
        this.toastCtrl.showMessage( this.$translate.instant('reserve.order.fail' ) );
      }
    }

    const options: ICheckoutOptions = {
      autoShipReservation: false,
      allowPlaceWithoutPayment: placeWithoutPay,
      placeOrderPayload: {
        OrderID
      }
    };

    // When dealing with a reservation order and pickup location is the same store
    // @see https://n6k.atlassian.net/browse/OPTR-24211?focusedCommentId=138736
    const selectedPickupOU = cart.response.ShoppingCart.PickupOrganizationUnitID;
    const currentOrgUnitId = this.$selectedOrganisationUnit.getLastUsedOrganisation();
    const canCompleteWithoutShip = cart.checkoutStatus.mode === OrderMode.reservation && currentOrgUnitId === selectedPickupOU;
    if(canCompleteWithoutShip){
      options.allowCompletionWithoutShip = true;
    }

    // When dealing with reservation/order type transaction
    // @see https://n6k.atlassian.net/browse/OPTR-24053?focusedCommentId=139521
    const isDeliveryOrderTypeMode = cart.response.ShoppingCart.Lines?.some(line => (
      line.LineActionType === ILineActionsTypes.DELIVERY || line.LineActionType === ILineActionsTypes.ORDER_LINE
    ));

    /**
     * If the user has selected a set of specific lines to ship, we will use that instead of what the sdk is internally providing
     * @see https://eva2015.atlassian.net/browse/OPTR-3523
     */
    const linesToShip = this.$partialShipments.getShipmentSelection(OrderID).value.lines;

    if ( !isEmpty(linesToShip) ) {
      options.shipOrderPayload = {
        FinalShipment: undefined,
        Force: undefined,
        Lines: linesToShip,
        OrderID,
      };
    }

    const checkoutResultPromise = getShoppingCart.performCheckout(options);

    try {
      const checkoutResult = await checkoutResultPromise;

      switch (checkoutResult.message) {
        case ('Cannot place order'):
          this.showToast('perform.checkout.cannot.place');
          break;
        case ('Cannot ship order'):
          this.showToast('perform.checkout.cannot.ship');
          break;
        case ('Order is invalid'):
          this.showToast('perform.checkout.order.invalid');
          break;
        case ('Order needs to be paid'):
          this.showToast('perform.checkout.order.unpaid');
          break;
        case ('Order is not able to complete'):
          this.showToast('perform.checkout.order.incomplete');
          break;
      }

      return checkoutResult;
    } catch (e) {
      this.logger.error('Checkout failed', e);

      // We need to re-check the checkout state for this particular case
      // if shoppingCart state is completed we return a resolved promis to finish the flow
      // @see https://n6k.atlassian.net/browse/OPTR-24053?focusedCommentId=139521
      if(isDeliveryOrderTypeMode){
        const shoppingCart = await getShoppingCart.getResponse$().pipe(isNotNil(),first()).toPromise();
        if(shoppingCart?.ShoppingCart?.IsPaid){
          return Promise.resolve();
        }
      }
    }

    return checkoutResultPromise;
  }

  private showToast( messageKey: string ) {
    this.toastCtrl.showMessage( this.$translate.instant(messageKey) );
  }

}
