import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import InjectorWrapper from '../../injector';

/**
 * Parameters pertaining to the alert dialogue
 *
 * Title, message, ok and cancel button translations
 */
export interface IConfirmAlertParams {
  i18nTitleKey: string;
  i18nMessageKey: string;
  i18nCancelButtonKey?: string;
  i18nConfirmButtonKey: string;
}

/**
 * Role assigned to the Confirm button
 */
const USER_CONFIRMATION_ROLE = 'confirm';

/**
 * Role assigned to the Cancel button
 */
const USER_CANCELLATION_ROLE = 'cancel';

/**
 * Encapsulate Confirmation Alert logic
 *
 * Returns a boolean value corresponding to whether the user confirmed the alert or not
 */
export async function presentConfirmAlert(input: IConfirmAlertParams): Promise<boolean> {

  // Get the dependencies needed for showing the alert
    //
  const alertCtrl = InjectorWrapper.getInjector().get(AlertController);
  const $translate = InjectorWrapper.getInjector().get(TranslateService);

  const alert = await alertCtrl.create({
    header: $translate.instant(input.i18nTitleKey),
    message: $translate.instant(input.i18nMessageKey),
    buttons: [{
      role: USER_CANCELLATION_ROLE,
      text: $translate.instant(input.i18nCancelButtonKey || 'action.cancel')
    }, {
      role: USER_CONFIRMATION_ROLE,
      cssClass: 'alert-danger',
      text: $translate.instant(input.i18nConfirmButtonKey),
    }]
  });

  alert.present();

  const dismissedPromise = alert.onDidDismiss();

  // Await for the alert promise
  //
  const result = await dismissedPromise;

  // Check if the user preseed the confirm button
  //
  const userConfirmed = result.role === USER_CONFIRMATION_ROLE;

  return userConfirmed;
}
