import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash-es';
import { first } from 'rxjs/operators';
import { EvaApplicationConfigProvider } from 'src/app/services/eva-application-config/eva-application-config';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';
import isNotNil from 'src/app/shared/operators/isNotNil';
import { CustomerFieldsTranslationsMap } from '../checkout-customer-details/checkout-customer-details.component';

export type TCustomerPreferredFieldsInput = EVA.Core.UserDto | EVA.Core.GetUserResponse;

/**
 * This component will render the preferred fields
 *
 * @see https://n6k.atlassian.net/browse/OPTR-11884
 */
 @Logger('[customer-preferred-fields-component]')
@Component({
   selector: 'eva-customer-preferred-fields',
   templateUrl: 'customer-preferred-fields.html',
   styleUrls: ['customer-preferred-fields.scss'],
 })
 export class CustomerPreferredFieldsComponent implements ILoggable {
  logger: Partial<Console>;


   private _customer: TCustomerPreferredFieldsInput;

   public get customer(): TCustomerPreferredFieldsInput {
     return this._customer;
   }

   @Input()
   public set customer(newCustomerValue: TCustomerPreferredFieldsInput) {
     if ( newCustomerValue != this._customer ) {
       this._customer = newCustomerValue;
       this.setMissingCustomerPreferredFieldsLabels(newCustomerValue);
     }
   }


  missingCustomerPreferredFieldsLabels: string[] = [];

  constructor(
    private $applicationConfig: EvaApplicationConfigProvider,
    private $translate: TranslateService,
  ) {}

  async setMissingCustomerPreferredFieldsLabels(customer: TCustomerPreferredFieldsInput) {
    if (isNil(customer)) {
      return;
    }

    const userRequirements = await this.$applicationConfig.userRequirements$.pipe(
      isNotNil(),
      first()
    ).toPromise();

    const missingPreferredFields = Object.entries(userRequirements)
      .filter(([_field, requirement]) => requirement.Display && requirement.Preferred)
      .map(([field]) => field as keyof TCustomerPreferredFieldsInput)
      .filter(field => isNil(this.customer[field]));

    const customerFieldMapping: Partial<CustomerFieldsTranslationsMap> = {
      EmailAddress: this.$translate.instant('email.address'),
      SocialSecurityNumber: this.$translate.instant('social.security.number'),
      FirstName: this.$translate.instant('first.name'),
      LastName: this.$translate.instant('last.name'),
      PhoneNumber: this.$translate.instant('phone.number'),
      Gender: this.$translate.instant('gender'),
      Title: this.$translate.instant('title'),
      Salutation: this.$translate.instant('salutation'),
      DateOfBirth: this.$translate.instant('date.of.birth'),
      LanguageID: this.$translate.instant('language'),
      FiscalID: this.$translate.instant('customer.fiscal.id'),
    };

    const missingCustomerPreferredFieldsLabels = [];

    for ( const missingField of missingPreferredFields ) {
      const matchingLabel = customerFieldMapping[missingField];
      if (!isNil(matchingLabel)) {
        missingCustomerPreferredFieldsLabels.push(matchingLabel);
      } else {
        this.logger.error(`there is a preffered field that we dont support name=${missingField}`);
      }
    }

    this.missingCustomerPreferredFieldsLabels = missingCustomerPreferredFieldsLabels;
  }
 }
