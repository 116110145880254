import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../pipes/pipes.module';
import { CheckoutTotalComponent } from './checkout-total/checkout-total.component';
import { CompositeTaskProgressComponent } from './composite-task-progress/composite-task-progress';
import { ProductCardCommonComponent } from './product-card-common/product-card-common';
import { LocalizedPriceComponent } from './localized-price/localized-price.component';
import { PinInputComponent } from './pin-input/pin-input.component';
import { PriceComponent } from './price/price.component';
import { ProductComplexCardComponent } from './product-complex-card/product-complex-card';
import { ProgressCircularComponent } from './progress-circular/progress-circular';
import { ProductVariationsComponent } from './product-variations/product-variations';
import { EvaImgComponent } from './img/img';
import { OrderProductSummaryComponent } from './order-product-summary/order-product-summary';
import { SentinelButtonIndicatorComponent } from './sentinel-button-indicator/sentinel-button-indicator';
import { EmptyStateComponent } from './empty-state/empty-state';
import { MenuComponent } from './menu/menu';
import { TaskListComponent } from './task-list/task-list';
import { TaskCardComponent } from './task-card/task-card';
import { TaskDeadlineCountdownComponent } from './task-deadline-countdown/task-deadline-countdown';
import { DirectivesModule } from '../directives/directives.module';
import { SwipeIndicatorComponent } from './swipe-indicator/swipe-indicator.component';
import { OverlayComponent } from './overlay/overlay.component';
import { QuantityControlsComponent } from './quantity-controls/quantity-controls.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomerSearchResultComponent } from './customer-search-result/customer-search-result.component';
import { LineActionTypeIconComponent } from './line-action-type-icon/line-action-type-icon.component';
import { OrderLineDeliverableIconComponent } from './order-line-deliverable-icon/order-line-deliverable-icon';
import { OrderListItemComponent } from './order-list-item/order-list-item.component';
import { OrderShipmentCardComponent } from './order-shipment-card/order-shipment-card.component';
import { OrderShipmentsComponent } from './order-shipments/order-shipments.component';
import { OrderStatusComponent } from './order-status/order-status.component';
import { PaymentTransactionContainerComponent } from './payment-transaction-container/payment-transaction-container.component';
import { PaymentTransactionPopoverComponent } from './payment-transaction-popover/payment-transaction-popover.component';
import { PaymentTransactionStatusComponent } from './payment-transaction-status/payment-transaction-status.component';
import { PaymentTransactionComponent } from './payment-transaction/payment-transaction.component';
import { SearchResultsContainerComponent } from './search-results-container/search-results-container.component';
import { SearchInputContainerComponent } from './search-input-container/search-input-container.component';
import { StationSelectorComponent } from './station-selector/station-selector.component';
import { CustomerTileComponent } from './customer-tile/customer-tile.component';
import { RequestedDateComponent } from './requested-date/requested-date.component';
import { CheckoutTileComponent } from './checkout-tile/checkout-tile.component';
import { UserDetailsCardComponent } from './user-details-card/user-details-card.component';
import { EmployeeDiscountComponent } from './employee-discount/employee-discount.component';
import { OrderAddressesComponent } from './order-addresses/order-addresses.component';
import { CheckoutCustomerDetailsComponent } from './checkout-customer-details/checkout-customer-details.component';
import { UserCardsComponent } from './user-cards/user-cards.component';
import { AddressBookCardComponent } from './address-book-card/address-book-card.component';
import { EditAddressComponent } from './edit-address/edit-address.component';
import { ItemSearchButtonComponent } from './item-search-button/item-search-button.component';
import { ProductDiscountOptionsContentComponent } from './product-discount-options-content/product-discount-options-content.component';
import { ValMessagesModule } from '../shared/ngx-messages/val-messages.module';
import { CardDetailsComponent } from './card-details/card-details.component';
import { ProductSearchResultComponent } from './product-search-result/product-search-result.component';
import { ProductSearchFilterComponent } from './product-search-filter/product-search-filter.component';
import { DotsComponent } from './dots/dots.component';
import { ProductDetailsRequirementsComponent } from './product-details-requirements/product-details-requirements.component';
import { ParentProductComplexCardComponent } from './parent-product-complex-card/parent-product-complex-card.component';
import { ProductStockInTransitComponent } from './product-stock-in-transit/product-stock-in-transit.component';
import { StockMutationsComponent } from './stock-mutations/stock-mutations.component';
import { AddressBookSummaryComponent } from './address-book-summary/address-book-summary.component';
import { OrdersForCustomerContainerComponent } from './orders-for-customer-container/orders-for-customer-container.component';
import { OrderLineRequirementComponent } from './order-line-requirement/order-line-requirement.component';
import { EvaGiftLinesComponent } from './gift-lines/gift-lines.component';
import { CartSecondaryActionsComponent } from './cart-secondary-actions/cart-secondary-actions.component';
import { IconComponent } from './icon/icon.component';
import { EditCompanyComponent } from './edit-company/edit-company.component';
import { EditCustomerComponent } from './edit-customer/edit-customer.component';
import { CountrySelectorComponent } from './country-selector/country-selector';
import { CustomerPreferredFieldsComponent } from './customer-preferred-fields/customer-preferred-fields';
import { ProductStatusChipComponent } from './product-status-chip/product-status-chip.component';
import { UnitOfMeasuresFeedbackComponent } from './unit-of-measures-feedback/unit-of-measures-feedback.component';
import { PriceLabelProductCardComponent } from './price-label-product-card/price-label-product-card.component';
import { IonInputFocusFixModule } from '../modules/ion-input-focus-fix/ion-input-focus-fix.module';
import { CompanyLogoComponent } from './company-logo/company-logo.component';
import { BorrowedItemCardComponent } from './borrowed-item-card/borrowed-item-card';
import { BorrowedItemStatusComponent } from './borrowed-item-status/borrowed-item-status';
import { BorrowedItemsSummaryComponent } from './borrowed-items-summary/borrowed-items-summary';
import { CustomFieldFormElementComponent } from './custom-field-form-element/custom-field-form-element.component';
import { CustomFieldRendererComponent } from './custom-field-renderer/custom-field-renderer.component';
import { OrderLineRequirementViewModeComponent } from './order-line-requirement-view-mode/order-line-requirement-view-mode.component';
import { CheckoutTotalLineComponent } from './checkout-total/checkout-total-line/checkout-total-line.component';
import { EvaProductDiscountOptionLinesComponent } from './product-discount-option-lines/product-discount-option-lines.component';
import { StationAvailableSelectorComponent } from './station-available-selector/station-available-selector.component';
import { StationsModalComponent } from './station-available-selector/stations-modal/stations-modal';
import { PhoneNumberComponent } from './phone-number/phone-number';
import { CustomerReferenceTileComponent } from '../pages/checkout/customer-reference-tile/customer-reference-tile.component';
import { FiscalIdTileComponent } from '../pages/checkout/fiscal-id-tile/fiscal-id-tile.component';
import { GiftwrappingTileComponent } from '../pages/checkout/giftwrapping-tile/giftwrapping-tile.component';
import { LotteryTileComponent } from '../pages/checkout/lottery-number-tile/lottery-number-tile.component';
import { OrderCustomFieldsTileComponent } from '../pages/checkout/order-custom-fields-tile/order-custom-fields-tile.component';
import { OrderRemarkTileComponent } from '../pages/checkout/order-remark-tile/order-remark-tile.component';
import { PartialShipmentTileComponent } from '../pages/checkout/partial-shipment-tile/partial-shipment-tile.component';
import { QuickBuyComponent } from '../pages/checkout/quick-buy-tile/quick-buy-tile.component';
import { RecipientFieldsTileComponent } from '../pages/checkout/recipient-fields-tile/recipient-fields-tile.component';
import { ShippingTileComponent } from '../pages/checkout/shipping-tile/shipping-tile.component';
import { SignatureTileComponent } from '../pages/checkout/signature-tile/signature-tile.component';
import { SoldByComponent } from '../pages/checkout/sold-by-tile/sold-by-tile.component';
import { StoreSelectionTileComponent } from '../pages/checkout/store-selection-tile/store-selection-tile.component';
import { TaxExemptionTileComponent } from '../pages/checkout/tax-exemption-tile/tax-exemption-tile.component';
import { VatTileComponent } from '../pages/checkout/vat-number-tile/vat-number-tile.component';
import { OrderOptionItemComponent } from './order-option-item/order-option-item';
import { ShippingMethodsTileComponent } from '../pages/checkout/shipping-methods-tile/shipping-methods-tile.component';
import { EcoTaxLabelComponent } from './eco-tax-label/eco-tax-label.component';
import { FiscalRemarkTileComponent } from '../pages/checkout/fiscal-remark-tile/fiscal-remark-tile.component';

@NgModule({
  declarations: [
    AddressBookCardComponent,
    AddressBookSummaryComponent,
    UserDetailsCardComponent,
    CustomerSearchResultComponent,
    CustomerTileComponent,
    PinInputComponent,
    PriceComponent,
    LineActionTypeIconComponent,
    LocalizedPriceComponent,
    CheckoutCustomerDetailsComponent,
    CheckoutTileComponent,
    CheckoutTotalComponent,
    ProductComplexCardComponent,
    CompositeTaskProgressComponent,
    ParentProductComplexCardComponent,
    PaymentTransactionComponent,
    PaymentTransactionContainerComponent,
    PaymentTransactionPopoverComponent,
    PaymentTransactionStatusComponent,
    ProgressCircularComponent,
    ProductCardCommonComponent,
    ProductDetailsRequirementsComponent,
    ProductDiscountOptionsContentComponent,
    ProductSearchFilterComponent,
    ProductSearchResultComponent,
    ProductStockInTransitComponent,
    ProductVariationsComponent,
    QuantityControlsComponent,
    EditAddressComponent,
    EditCompanyComponent,
    EditCustomerComponent,
    EmployeeDiscountComponent,
    EvaImgComponent,
    ItemSearchButtonComponent,
    OrderAddressesComponent,
    OrdersForCustomerContainerComponent,
    OrderLineDeliverableIconComponent,
    OrderListItemComponent,
    OrderProductSummaryComponent,
    OrderShipmentCardComponent,
    OrderShipmentsComponent,
    OrderStatusComponent,
    RequestedDateComponent,
    SearchInputContainerComponent,
    SearchResultsContainerComponent,
    SentinelButtonIndicatorComponent,
    StationSelectorComponent,
    StationAvailableSelectorComponent,
    StockMutationsComponent,
    EmptyStateComponent,
    MenuComponent,
    TaskListComponent,
    TaskCardComponent,
    TaskDeadlineCountdownComponent,
    UserCardsComponent,
    SwipeIndicatorComponent,
    OverlayComponent,
    QuantityControlsComponent,
    CardDetailsComponent,
    DotsComponent,
    OrderLineRequirementComponent,
    EvaGiftLinesComponent,
    CartSecondaryActionsComponent,
    IconComponent,
    CountrySelectorComponent,
    PhoneNumberComponent,
    CustomerPreferredFieldsComponent,
    ProductStatusChipComponent,
    UnitOfMeasuresFeedbackComponent,
    PriceLabelProductCardComponent,
    CompanyLogoComponent,
    BorrowedItemCardComponent,
    BorrowedItemStatusComponent,
    BorrowedItemsSummaryComponent,
    CustomFieldFormElementComponent,
    CustomFieldRendererComponent,
    OrderLineRequirementViewModeComponent,
    CheckoutTotalLineComponent,
    EvaProductDiscountOptionLinesComponent,
    StationsModalComponent,
    SoldByComponent,
    QuickBuyComponent,
    LotteryTileComponent,
    ShippingTileComponent,
    PartialShipmentTileComponent,
    StoreSelectionTileComponent,
    TaxExemptionTileComponent,
    VatTileComponent,
    FiscalIdTileComponent,
    OrderRemarkTileComponent,
    GiftwrappingTileComponent,
    CustomerReferenceTileComponent,
    SignatureTileComponent,
    OrderCustomFieldsTileComponent,
    RecipientFieldsTileComponent,
    OrderOptionItemComponent,
    ShippingMethodsTileComponent,
    EcoTaxLabelComponent,
    FiscalRemarkTileComponent
  ],
  imports: [
    IonicModule,
    TranslateModule.forChild(),
    CommonModule,
    PipesModule,
    DirectivesModule,
    ReactiveFormsModule,
    ValMessagesModule,
    IonInputFocusFixModule
  ],
  entryComponents: [],
  exports: [
    AddressBookCardComponent,
    AddressBookSummaryComponent,
    UserDetailsCardComponent,
    CustomerSearchResultComponent,
    CustomerTileComponent,
    PinInputComponent,
    PriceComponent,
    LineActionTypeIconComponent,
    LocalizedPriceComponent,
    CheckoutCustomerDetailsComponent,
    CheckoutTileComponent,
    CheckoutTotalComponent,
    CheckoutTotalLineComponent,
    ProductComplexCardComponent,
    CompositeTaskProgressComponent,
    ParentProductComplexCardComponent,
    PaymentTransactionComponent,
    PaymentTransactionContainerComponent,
    PaymentTransactionPopoverComponent,
    PaymentTransactionStatusComponent,
    ProgressCircularComponent,
    ProductCardCommonComponent,
    ProductDetailsRequirementsComponent,
    ProductDiscountOptionsContentComponent,
    ProductSearchFilterComponent,
    ProductSearchResultComponent,
    ProductStockInTransitComponent,
    ProductVariationsComponent,
    QuantityControlsComponent,
    EditAddressComponent,
    EditCompanyComponent,
    EditCustomerComponent,
    EmployeeDiscountComponent,
    EvaImgComponent,
    ItemSearchButtonComponent,
    OrderAddressesComponent,
    OrdersForCustomerContainerComponent,
    OrderLineDeliverableIconComponent,
    OrderListItemComponent,
    OrderProductSummaryComponent,
    OrderShipmentCardComponent,
    OrderShipmentsComponent,
    OrderStatusComponent,
    RequestedDateComponent,
    SearchInputContainerComponent,
    SearchResultsContainerComponent,
    SentinelButtonIndicatorComponent,
    StationSelectorComponent,
    StationAvailableSelectorComponent,
    StockMutationsComponent,
    EmptyStateComponent,
    MenuComponent,
    TaskListComponent,
    TaskCardComponent,
    TaskDeadlineCountdownComponent,
    UserCardsComponent,
    SwipeIndicatorComponent,
    OverlayComponent,
    QuantityControlsComponent,
    CardDetailsComponent,
    DotsComponent,
    OrderLineRequirementComponent,
    EvaGiftLinesComponent,
    CartSecondaryActionsComponent,
    IconComponent,
    CountrySelectorComponent,
    PhoneNumberComponent,
    CustomerPreferredFieldsComponent,
    ProductStatusChipComponent,
    UnitOfMeasuresFeedbackComponent,
    PriceLabelProductCardComponent,
    CompanyLogoComponent,
    BorrowedItemCardComponent,
    BorrowedItemStatusComponent,
    BorrowedItemsSummaryComponent,
    CustomFieldFormElementComponent,
    CustomFieldRendererComponent,
    OrderLineRequirementViewModeComponent,
    EvaProductDiscountOptionLinesComponent,
    StationsModalComponent,
    OrderOptionItemComponent,
    SignatureTileComponent,
    EcoTaxLabelComponent,
  ]
})
export class ComponentsModule {}
