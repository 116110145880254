import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { NavController } from '@ionic/angular';
import { isEmpty } from 'lodash';
import { isNil } from 'lodash-es';
import { filter, first, map, pairwise, withLatestFrom } from 'rxjs/operators';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import isNotNil from '../../shared/operators/isNotNil';
import { AvailableSubscriptionsProvider } from '../available-subscriptions/available-subscriptions.provider';
import { EvaApplicationConfigProvider } from '../eva-application-config/eva-application-config';

/** Pages that could be visitted during the edit/create flow */
export enum CustomerNavigationPages {
  CUSTOMER_OPT_IN = 'opt-in',
  CUSTOMER_DETAILS = 'details'
}

/** Function to call once the creation flow of a customer is over */
export type TPostUpsertFlowFn = (navCtrl: NavController) => any;

export type TCustomerFn = () => Promise<number>;

/** This payload will be available to all pages in the customer upsert flow */
interface IUpsertStartProcessPayload {
  /** Only interesting if we are editing a user */
  userId?: number;
  /** Will help us determine whether optin page should be opened or not */
  isBusinessCustomer?: boolean;

  /** Function to call when the employee reaches the step where the customer needs
   * to be created or edited, this is currently the customer opt-in page
   */
  customerFn?: TCustomerFn;

  /** Which order the user is curerntly busy with, will be used for update orders page */
  orderId?: number;
  hasPhoneNumber?: boolean;

  // Determine if we are creating a new user or not
  creatingCustomer?: boolean;

  inCheckout: boolean;
}

export interface CurrentNavigationState extends IUpsertStartProcessPayload {
  ritualsUpdateLoyaltyCalled?: boolean;
}

interface CustomerNavigationPage {
  /** Whether we can open this paeg or not */
  canOpen: (navCtrl: NavController, startProcessPayload: IUpsertStartProcessPayload) => Promise<boolean> | boolean;
  /** This will help us determine which page this is */
  page: CustomerNavigationPages;
  /** some pages like customer optin will be calling the customer create / edit function,
   * if they dont get opened we want to making those calls
   */
  shouldCallCustomerFn?: boolean;
}

/**
 * This provider will contain navigation helpers for customer editing / creating.
 *
 * @see https://eva2015.atlassian.net/browse/OPTR-6974
 * @see https://eva2015.atlassian.net/browse/OPTR-3446
 * @see https://eva2015.atlassian.net/browse/OPTR-1610
 */
@Injectable()
@Logger('[customer-upsert-navigation-provider]')
export class CustomerUpsertNavigationProvider implements ILoggable {

  logger: Partial<Console>;

  /** Whether the optin page chould be opened or not */
  public chouldOpenOptInPage$ = this.$evaApplicationConfig.ritualsLoyaltyEnabled$.pipe(
    withLatestFrom(
      this.$evaApplicationConfig.newSubscriptionsEnabled
    ),
    map( ([ritualsLoyaltyEnabled, subscriptionsEnabled]) => {
      /** Only if rituals loyalty is enabled or the subscription type is enabled */
      const couldOpenOptInPage = ritualsLoyaltyEnabled || subscriptionsEnabled;

      return couldOpenOptInPage;
    })
  );

  /** When the whole upsert flow is complete, we want to call this function */
  private onUpsertCompleteHandler: TPostUpsertFlowFn;

  /** The order of these pages will be respected whenever .next is called */
  private upsertProcessPages: CustomerNavigationPage[] = [
    {
      page: CustomerNavigationPages.CUSTOMER_OPT_IN,
      canOpen: async (_, startProcessPayload) => {
        const availableSubscriptions = await this.$availableSubscriptions.getAvailableSubscriptionsStream$().pipe(
          isNotNil(),
          first(),
          map(availableSubscriptionsResponse => availableSubscriptionsResponse.Subscriptions)
        ).toPromise();

        const subscriptionsEnabled = await this.$evaApplicationConfig.newSubscriptionsEnabled.pipe(first()).toPromise();

        const canOpen = subscriptionsEnabled && !isEmpty(availableSubscriptions) && !startProcessPayload.isBusinessCustomer;

        return canOpen;
      },
      shouldCallCustomerFn: false
    },
    {
      page: CustomerNavigationPages.CUSTOMER_DETAILS,
      canOpen: async () => {
        const inCheckout = this.currentNavigationState.inCheckout;

        const customerShowPreview: boolean = await this.$evaApplicationConfig.customerShowPreview$.pipe(first()).toPromise();
        // If the setting is on, and we are not in the checkout. Navigate to the customer details page
        //
        const canOpen = customerShowPreview && !inCheckout;

        return canOpen;
      }
    },
  ];

  /** Any state a customer upsert page will set, will be saved here (we will merge objects and save them here) */
  public currentNavigationState: CurrentNavigationState;

  public previousPageUrl: string = '';

  constructor(
    private $evaApplicationConfig: EvaApplicationConfigProvider,
    private $availableSubscriptions: AvailableSubscriptionsProvider,
    private router: Router
  ) {
    this.router.events
    .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
    .subscribe(([previousEvent]: [RoutesRecognized, RoutesRecognized]) => {
      this.previousPageUrl = previousEvent.urlAfterRedirects;
    });
  }

  registerOnUpsertCompleteHandler(handler: TPostUpsertFlowFn) {
    this.onUpsertCompleteHandler = handler;
  }

  async startUpsertProcess(navCtrl: NavController, startPayload: IUpsertStartProcessPayload) {
    this.currentNavigationState = startPayload;

    // Calling currentPage as null, as the customer create/edit page are not in the map
    // because they can always be opened and they will start this flow.
    //
    this.next(navCtrl, null);
  }

  /**
   * Moves to the next step
   * @param navCtrl nav controller instance to do the navigation
   * @param currentPage the current page, will help us determine the next one
   * @param navParams extra navigation params
   */
  async next(navCtrl: NavController, currentPage: CustomerNavigationPages, navParams: any = {}) {
    const currentPageIndex = this.upsertProcessPages.findIndex( customerNavigationPage => customerNavigationPage.page === currentPage );

    // If the current page index reaches the last element in the pages array, we are done
    //
    if ( currentPageIndex === (this.upsertProcessPages.length - 1) ) {
      this.logger.log('Customer upsert process complete, calling complete handler');

      // This is the method we need to call when the whole upsert process is complete
      //
      this.onUpsertCompleteHandler(navCtrl);

      return;
    }

    this.currentNavigationState = {...navParams, ...this.currentNavigationState};

    /** currentPageIndex will be -1 for the first page and thats okay, as the first page index is 0 */
    const newPageIndex = currentPageIndex + 1;

    const nextPage = this.upsertProcessPages[newPageIndex];

    /** If a page cannot be opened, we will just move to the next one */
    const canOpen = await nextPage.canOpen(navCtrl, this.currentNavigationState);

    if (canOpen) {
      const { userId=null } = this.currentNavigationState;
      const mainPath = `users/${nextPage.page}`;
      const path  = userId ? `${mainPath}/${userId}` : mainPath;
      // Pushing to the next page, and passing both the start payload and any additional nav params provided by the current page
      navCtrl.navigateForward(path)
        .catch(error => this.logger.error(`navigating to ${nextPage.page}`, error ));

    } else {
      // If the page we cannot open, is responsible for calling the create / edit user, but we cannot open it
      // we will make sure we do the create / edit user calls
      //
      const nextPageNavParams = navParams || {};

      // Sometimes on first load of the app, the navController doesn't works as expected
      // so we need to save manually the previous url route, to use it later one
      // @see https://n6k.atlassian.net/browse/OPTR-22809
      if(currentPageIndex === -1){
        this.previousPageUrl = this.router?.url;
      }

      if (nextPage.shouldCallCustomerFn) {
        try {
          const userId = await this.currentNavigationState.customerFn();

          nextPageNavParams.userId = userId;
        } catch (error) {
          this.logger.error('error creating customer', error);
        }
      }

      // If we had to call the customer function, but no customer id was returned. We dont want to move to the next step just yet.
      if (nextPage.shouldCallCustomerFn && isNil(nextPageNavParams.userId)) {
        return;
      }

      // We couldnt open this page (nextPage), so we will move on to the next one
      //
      this.next(navCtrl, nextPage.page, nextPageNavParams);
    }
  }
}
