<ion-card *ngIf="!(missingCustomerPreferredFieldsLabels | isEmpty)" class="ion-padding ion-no-margin ion-margin-top">
  <ng-container *ngIf="(missingCustomerPreferredFieldsLabels)?.length === 1; else multipleFields">
    <ion-text color="warning" *ngIf="(missingCustomerPreferredFieldsLabels) as missingFields">
      <span>
        {{'customer.details.missing.single.preferred.field.message' | translate: {
            field: missingFields[0]
          }
        }}
      </span>
    </ion-text>
  </ng-container>

  <ng-template #multipleFields>
    <ion-text color="warning">
      {{'customer.details.missing.preferred.fields.message' | translate}}
    </ion-text>
    <ul>
      <li *ngFor="let fieldName of missingCustomerPreferredFieldsLabels">
        {{fieldName}}
      </li>
    </ul>
  </ng-template>
</ion-card>
