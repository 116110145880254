import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNil, isEmpty } from 'lodash';
import { EvaApplicationConfigProvider } from '../eva-application-config/eva-application-config';
import { IProductSearchFilterDisplayPriceFormValue, IProductSearchFilterFormValue } from 'src/app/components/product-search-filter/product-search-filter.component';

export interface Filters {
  [key: string]: EVA.Core.FilterModel;
}

/**
 * We will store the current filters here
 *
 * @see https://eva2015.atlassian.net/browse/OPTR-2965
 */
@Injectable()
export class ProductSearchFiltersProvider {

  public productSearchFilters$ = new BehaviorSubject<Filters>(null);

  filterNumbers$ = this.productSearchFilters$.pipe(
    map( productSearchFilters => {
      const productFilters = Object.entries(productSearchFilters || {}).map(([filterKey, filterValue]) => {
        if(isNil(filterValue) || filterKey === "is_sellable"){
          return 0;
        }
        // We want to make sure display_price filter is fully filled in for us to count it as 'one' filter
        if ( filterKey === 'display_price' || filterKey === 'filter_stock') {
          return 1;
        } else {
          return filterValue && filterValue?.Values?.length;
        }
      }).reduce((a, b) => a + b, 0);

      return productFilters;
    })
  );

  public showFilterButton$ = this.$evaApplicationConfig.searchProductProperties$.pipe(
    map(searchProductProperties => !isEmpty(searchProductProperties))
  );

  private filters: IProductSearchFilterFormValue;

  constructor(private $evaApplicationConfig: EvaApplicationConfigProvider) {}

  setFilters(filters: IProductSearchFilterFormValue) {
    this.filters = filters;

    this.setSearchProductFilters(filters);
  }

  getFilters() {
    return this.filters;
  }

  // We want to reset the filter when leaving the search view
  // @seee https://n6k.atlassian.net/browse/OPTR-24459?focusedCommentId=146592
  public resetFilters(){
    this.filters = undefined;
    this.productSearchFilters$.next(null);
  }

  /**
   * We will create a filter model here, which will be consumable by product search
   */
  setSearchProductFilters(formValueFilters: IProductSearchFilterFormValue) {

    const displayPrice = formValueFilters['display_price'] as IProductSearchFilterDisplayPriceFormValue;

    const filters: Filters = {};

    if ( displayPrice && !isNil(displayPrice.from) && !isNil(displayPrice.to) ) {
      filters['display_price'] = {
        ExactMatch: true,
        From: `${displayPrice.from}`,
        IncludeMissing: undefined,
        Negation: undefined,
        To: `${displayPrice.to}`,
        Values: undefined
      };
    }

    // We already handled display_price filter, so we will ignore it here
    const formCheckboxFilters = Object.entries(formValueFilters).filter(([productProp]) => productProp !== 'display_price');

    for ( const [ productProperty, productPropertyValues ] of  formCheckboxFilters) {
      // We only want to the values array to be populated with aggregations that were checked ( set to true )
      const productPropertyFilterValue = Object.entries(productPropertyValues)
        .filter(([_aggregationValueName, aggregationValueChecked]) => aggregationValueChecked)
        .map(([aggregationValueName]) => aggregationValueName);

      // If the product property filters are empty, we won't add them to the filters object
      if ( !isEmpty(productPropertyFilterValue) ) {
        filters[productProperty] = {
          ExactMatch: true,
          From: undefined,
          IncludeMissing: undefined,
          Negation: undefined,
          To: undefined,
          Values: productPropertyFilterValue
        };
      }

    }

    if(formValueFilters?.filter_stock){
      filters['filter_stock'] = {
        ExactMatch: true,
        From: undefined,
        IncludeMissing: undefined,
        Negation: undefined,
        To: undefined,
        Values: [true]
      };
    }

    this.productSearchFilters$.next(filters);
  }

}
