import { Component, forwardRef, HostListener, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash';
import { first, map } from 'rxjs/operators';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { BehaviorSubject ,  combineLatest } from 'rxjs';
import { StationSelectorProvider } from '../../services/eva-station-selector/station-selector.provider';

/** Which stations to filter out */
export type FilterStationFn  = (station: EVA.Core.StationDto) => boolean;

type TChangeFn = (stationId: number) => void;

@Logger('[station-selector-component]')
@Component( {
  selector:    'eva-station-selector',
  templateUrl: 'station-selector.component.html',
  styleUrls: ['station-selector.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => StationSelectorComponent),
    multi: true
  }]
})
export class StationSelectorComponent implements OnInit, ControlValueAccessor, ILoggable {
  logger: Partial<Console>;

  @Input() label: string = this.$translate.instant('select.a.station');

  @Input() ionItemLines: "full" | "inset" | "none" | undefined;

  @Input() showAlertEmpty?: boolean = false;

  /** Whether to remember the station id value for the next time */
  @Input() memorizeValue: boolean = true;

  public form = this.fb.group({
    mainControl: []
  });

  private _filterStationFn$ = new BehaviorSubject<FilterStationFn>((_station) => true);

  public availableStations$ = combineLatest([
    this.$stationSelector.availableStations$,
    this._filterStationFn$
  ]).pipe(
    map(([stations = [], filterStation]) => {
      return this.filterStation ? stations.filter(filterStation) : stations;
    })
  );

  /**
   * When there is no available devices, maybe we want to show
   * to the user a warning text instead of an empty selector
   * @see https://n6k.atlassian.net/browse/OPTR-18581
   */
  public alertEmptyStations$ = this.availableStations$.pipe(map((stations = []) =>{
    if(!stations?.length && this.showAlertEmpty){
      return true;
    }
    return false;
  }))

  public get filterStation(): FilterStationFn {
    return this._filterStationFn$.value;
  }

  /** If the consumer doesn't provide this, the default behavior will be to show all stations */
  @Input()
  public set filterStation(value: FilterStationFn) {
    this._filterStationFn$.next(value);
  }

  constructor(
    private fb: FormBuilder,
    private $translate: TranslateService,
    public $stationSelector: StationSelectorProvider
  ) {
  }

  ngOnInit() {
    this.form.get('mainControl').valueChanges.subscribe( ( stationId: number ) => {
      this.onChangeCallback(stationId);

      if (this.memorizeValue) {
        this.$stationSelector.setCurrentStationId(stationId);
      }
    });
  }

  @HostListener('blur') onblur() {
    this.onTouchedCallback();
  }

  async writeValue(stationId: number) {
    this.form.get('mainControl').setValue(stationId);

    if (this.memorizeValue) {
      this.$stationSelector.setCurrentStationId(stationId);
    }

    const stations = await this.availableStations$.pipe(first()).toPromise();


    const matchingStation = stations.find( potentialMatchingStation => potentialMatchingStation.ID === stationId );

    // If the consumer provided a station id that was not null|undefined and we cannot find it in the local list of stations
    // we will show them a warning
    //
    if ( isNil(matchingStation) && !isNil(stationId) ) {
      this.logger.warn(`You provided ${stationId} as selected station id but the station was not found in the local list of stations. Resetting the current value.`);

      this.form.get('mainControl').reset();
    }
  }

  registerOnChange(fn: TChangeFn): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  private onChangeCallback: TChangeFn = () => { };

  private onTouchedCallback = () => { };
}
