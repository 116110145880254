import { Component, ViewChild } from '@angular/core';
import { getBundleProductsForProduct } from '@springtree/eva-sdk-redux';
import { IonSlides, ModalController } from '@ionic/angular';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { TranslationKeys, ProductBundlesProvider, ITranslations } from '../../services/product-bundle/product-bundles.provider';

export interface BundleProductsModalDismissData {
  bundleId: number;
}

/**
 * This component will be responsible for showing the bundles a given product is a part off
 * @see https://eva2015.atlassian.net/browse/OPTR-1138
 * @see https://projects.invisionapp.com/d/main#/console/15973886/331336785/preview
 */
@Logger('[bundle-products-modal-component]')
@Component({
  selector: 'eva-bundle-products-modal',
  templateUrl: 'bundle-products.modal.html',
  styleUrls: ['bundle-products.modal.scss']
})
export class BundleProductsModalComponent implements ILoggable {
  logger: Partial<Console>;

  public activeSlideIndex$ = new BehaviorSubject<number>(0);

  public bundles$ = getBundleProductsForProduct.getResponse$().pipe(
    map(response => response.Result)
  );

  public translations$: Observable<ITranslations> = this.$bundleProductsProvider.getBundleTranslations$();

  public titleText$ = combineLatest([
    this.translations$.pipe(pluck(TranslationKeys.TITLE)),
    this.activeSlideIndex$,
    this.bundles$
  ]).pipe(
    map( ([titleText, activeIndex, bundles]) => `${titleText} (${activeIndex + 1}/${bundles.length})` )
  );

  public buttonText$ = this.translations$.pipe(
    pluck(TranslationKeys.BUTTON)
  );

  @ViewChild(IonSlides) ionSlides: IonSlides;

  constructor(
    private $bundleProductsProvider: ProductBundlesProvider,
    private viewCtrl: ModalController,
  ) { }


  async onSlideChange() {
    const currentIndex = await this.ionSlides.getActiveIndex();

    this.activeSlideIndex$.next(currentIndex);
  }

  openBundle(bundleId: number) {
    this.viewCtrl.dismiss({ bundleId } as BundleProductsModalDismissData);
  }
}
