import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { listAvailableShippingMethods, setShippingMethod, store } from '@springtree/eva-sdk-redux';
import { head, isEmpty } from 'lodash';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { OrderLifecycleService } from 'src/app/services/order-lifecycle/order-lifecycle.service';
import { EvaFeedback } from '../../shared/decorators/eva-feedback';
import { ILoggable } from '../../shared/decorators/logger';
import isNotNil from '../../shared/operators/isNotNil';

/**
 * This component will be responsible to choose a shipping method
 * @see https://n6k.atlassian.net/browse/OPTR-9148
 */
@Component({
  selector: 'shipping-method-modal',
  templateUrl: 'shipping-method.modal.html'
})
export class ShippingMethodModalComponent implements ILoggable, OnInit, OnDestroy {
  public logger: Partial<Console>;

  /**
   * Available shipping methods
   */
  shippingMethods$ = listAvailableShippingMethods.getResponse$().pipe(
    isNotNil(),
    map(response => response.AvailableShippingMethods)
  );

  selectedShippingMethod: EVA.Core.ShippingMethodByOrderLineDto;

  public readonly orderId: number = this.navParams.get('orderId');

  private stop$ = new Subject<void>();

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    private $orderLifecycleService: OrderLifecycleService,
  ) { }

  public ngOnInit(): void {
    const [action] = listAvailableShippingMethods.createFetchAction({
      OrderID: this.orderId
     });
    store.dispatch(action);

    this.shippingMethods$.pipe(takeUntil(this.stop$)).subscribe((shippingMethods) => {
      if (!isEmpty(shippingMethods)) {
        this.selectedShippingMethod = head(shippingMethods);
      }
    });

  }

  public chooseShippingMethod(shippingMethod: EVA.Core.ShippingMethodByOrderLineDto): void {
    this.selectedShippingMethod = shippingMethod;
  }

  @EvaFeedback({
    i18nFailKey: 'shipping.method.set.error',
    i18nSuccessKey: 'shipping.method.set.success'
  })
  public async confirmSelection() {
    /**
   * Set the shipping method for an order Id.
   */
    const [action, setShippingMethodPromise] = setShippingMethod.createFetchAction({
      OrderID: this.orderId,
      ShippingMethodID: this.selectedShippingMethod.ID,
    });
    store.dispatch(action);

    try {
      await setShippingMethodPromise;
      // Fetching the cart as we need a new one to see the value on the Order
      this.$orderLifecycleService.updateCurrentOrder();
    } catch (error) {
      this.logger.error('error calling setShippingMethodPromise', error);
    } finally {
      this.modalCtrl.dismiss();
    }

    return setShippingMethodPromise;
  }

  ngOnDestroy() {
    this.stop$.next();
  }
}
