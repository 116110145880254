import { Component, Input } from '@angular/core';

export interface IProductVariations {
  [key: string]: string;
}

interface IViewProductionVariation {
  label: string;
  value: any;
}


/**
 * This component will be responsible for rendering product variations
 *
 * @see https://eva2015.atlassian.net/browse/OPTR-5581
 */
@Component({
  selector: 'eva-product-variations',
  templateUrl: 'product-variations.html',
  styles: [
    `
    :host {
      display: block;
    }
    `
  ]
})
export class ProductVariationsComponent {

  _productVariations: IProductVariations;

  @Input()
  set productVariations(newProductVariations: IProductVariations) {
    if ( newProductVariations !== this._productVariations ) {
      this._productVariations = newProductVariations;

      this.setViewProductVariations(newProductVariations);
    }
  }

  get productVariations(): IProductVariations {
    return this._productVariations;
  }

  viewProductVariations: IViewProductionVariation[];

  /**
   * We want to turn orderLine.ProductVariation value of
   * {size: "62", 'size.name': "size", color: 'red', 'color.name': 'kleur'}
   *
   * into
   *
   * [{label: 'size', value: '62'}, {label: 'kleur', value: 'red'}]
   *
   * value here was taken from 'size' and 'color' we will call the keys
   * that were used to retrieve the values 'keyOfValue' in this case its 'color' and 'size'
   */
  setViewProductVariations(newProductVariations: IProductVariations) {
    const productVariations: IViewProductionVariation[] = Object.keys(newProductVariations || {}).filter(productVariationKey => {
      return productVariationKey.endsWith('.name');
    }).map(labelKey => {
      /** The value stored in this variable will help us determine which key we need to find a labels value */
      const [keyOfValue] = labelKey.split('.');

      const productVariation: IViewProductionVariation = {
        label: newProductVariations[labelKey],
        value: newProductVariations[keyOfValue]
      };

      return productVariation;
    });

    this.viewProductVariations = productVariations;
  }

}
