<ion-header class="ion-no-border ion-no-padding">
  <ion-toolbar>
    <ion-title> {{ 'verify.operation' | translate }} </ion-title>
    <ion-buttons slot="end">
      <ion-button navPop>
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="inner-content">

  <p class="ion-no-margin ion-text-center">
    {{ 'Ask a manager for permission to verify the operation' | translate }}
  </p>

  <ng-container *ngIf=" 'verify.order.number' | translate: { orderNumber: orderId } as label">
    <eva-pin-input [label]="label" [dotsFilledInCallback]="pinFilled"></eva-pin-input>
  </ng-container>

</div>
