import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getCurrentUser, getShipmentDetails, store, listPurchaseOrderShipments } from '@springtree/eva-sdk-redux';
import { AlertController, NavController, ModalController } from '@ionic/angular';
import { filter, map, take } from 'rxjs/operators';
import { EvaFeedback } from '../../shared/decorators/eva-feedback';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { IBarcodeScannerResult } from '../../modules/scanner/eva-barcode-scanner';
import { get, isNil, isEmpty } from 'lodash';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { UnknownFunction } from 'src/app/shared/typings';
import { IIncomingShipmentModalResult, IncomingShipmentModalComponent } from 'src/app/pages/incoming-shipment-list/eva-incoming-shipments-modal/incoming-shipment-modal';
import { Subject } from 'rxjs';

/**
 *
 * There will be two places in the app where we open the incoming shipment edit screen
 */
@Logger('[incoming-shipments-provider]')
@Injectable()
export class IncomingShipmentsProvider implements ILoggable {
  logger: Partial<Console>;

  /**
   * whenever a shipment is complete we want to be showing toasts for shipments passed their deadline
   * @see https://eva2015.atlassian.net/browse/OPTR-4228
   */
  shipmentComplete$ = new Subject<number>();

  constructor(
    private alertCtrl: AlertController,
    private toastCtrl: EvaToastController,
    private $translate: TranslateService,
    private modalCtrl: ModalController
  ) { }

  /**
   * Fetches a completed shipment
   */
  public async fetchCompleteShipment(incomingShipmentScan: IBarcodeScannerResult) {
    const backendId = incomingShipmentScan.parsedData.Value;

    const organizationUnitID = await this.getCurrentOrganizationUnitId();
    const shipmentDetailRequest: Partial<EVA.Core.GetShipmentDetails> = {
      OrganizationUnitID: organizationUnitID,
      BackendID: backendId
    };

    return this.fetchShipmentDetails(shipmentDetailRequest);
  }

  /**
   * Fetches shipment details
   */
  public async fetchShipmentDetailsById(shipmentID: number) {

    const organizationUnitID = await this.getCurrentOrganizationUnitId();
    const shipmentDetailRequest: Partial<EVA.Core.GetShipmentDetails> = {
      OrganizationUnitID: organizationUnitID,
      ID: shipmentID
    };

    return this.fetchShipmentDetails(shipmentDetailRequest);
  }

  /**
   * Fetches an incomplete shipment
   *
   * @see https://eva2015.atlassian.net/browse/OPTR-6784
   */
  public async fetchIncomingShipment(incomingShipmentScan: IBarcodeScannerResult): Promise<EVA.Core.GetShipmentDetailsResponse> {
    const backendId = incomingShipmentScan.parsedData.Value;
    let shipmentId: string = null;

    // We will search for non-completed shipments by backendId
    // and let the user choose if there are multiple shipments
    //

    try {
      const listPurchaseOrderShipmentsResponse: EVA.Core.ListPurchaseOrderShipmentsResponse =
        await this.fetchShipmentsByBackendId(backendId);

      const shipmentResults = get(listPurchaseOrderShipmentsResponse, 'Result.Page', []) as EVA.Core.PurchaseOrderShipmentDto[];

      if (isEmpty(shipmentResults)) {
        const message = this.$translate.instant('no.shipment.found.for.backend.id', {
          backendId
        });

        // We do not have any shipments
        //
        this.toastCtrl.create( { message } ).present();
      } else if (shipmentResults.length > 1) {
        // Multiple shipments result
        //
        const chosenShipment = await this.chooseShipment(shipmentResults);
        // Chosen shipment can be null if the user chose to dismiss the popup
        //
        if (!isNil(chosenShipment)) {
          shipmentId = chosenShipment.ID.toString();
        }
      } else if (shipmentResults.length === 1) {
        shipmentId = shipmentResults[0].ID.toString();
      }
    } catch (error) {
      this.logger.error('Could not fetch shipments by backend id', backendId);
    }

    if (isNil(shipmentId)) {
      return null;
    }

    const shipmentIdNumber = Number(shipmentId);

    const organizationUnitID = await this.getCurrentOrganizationUnitId();
    const shipmentDetailRequest: Partial<EVA.Core.GetShipmentDetails> = {
      OrganizationUnitID: organizationUnitID,
      ID: shipmentIdNumber
    };

    // Proceed with fetching the shipment details
    //
    const shipmentDetailsResult =  this.fetchShipmentDetails(shipmentDetailRequest);

    return shipmentDetailsResult;
  }

  /**
   * Get the current user OU id
   */
  public async getCurrentOrganizationUnitId(): Promise<number> {
    const organizationUnitID = await getCurrentUser.getState$().pipe(
      take(1),
      filter(state => Boolean(state.response) && Boolean(state.response.User)),
      map(state => state.response.User.CurrentOrganizationID)
    ).toPromise();

    return organizationUnitID;
  }

  /**
   * Navigates to the incoming shipment screen
   * @param NavCtrl will be used to perform the navigation
   * @param shipment will be used to show the user an alert incase its a manual shipment
   * @param backendId will be used to receive the shipment
   * @param taskId will be used to start the receive shipment task incase opened from the tasks screen
   *
   * @see https://eva2015.atlassian.net/browse/OPTR-3817
   */
  public async navigateToEditPage(
    navCtrl: NavController,
    shipment: EVA.Core.GetShipmentDetailsResponse,
    shipmentID: number,
    taskId?: number
  ) {
    const navigate = () => {
      const queryParams = {};

      if (!isNil(taskId)) {
        queryParams['taskId'] = taskId;
      }

      navCtrl.navigateForward([`incoming-shipment-edit/${shipmentID}`, {
        taskId,
      }], {
        queryParams,
      });
    };
    //  If the shipment is manual, we want to ask the user if they would like to proceed. Otherwise we will just go to the edit page
    //
    if (shipment.ReceiveMethod === 0) {
      const accepted = await this.showManualShipmentPrompt();

      if (accepted) {
        navigate();
      }
    } else {
      navigate();
    }
  }

  /**
   * Allows the user to choose a shipment from the incoming shipments modal
   */
  private async chooseShipment(shipments: EVA.Core.PurchaseOrderShipmentDto[]): Promise<EVA.Core.PurchaseOrderShipmentDto> {
    const incomingShipmentsModal = await this.modalCtrl.create({
      component: IncomingShipmentModalComponent,
      componentProps: {
        purchaseOrderShipments: shipments
      }
    });

    incomingShipmentsModal.present();

    const modalDismissPromise = await incomingShipmentsModal.onDidDismiss<IIncomingShipmentModalResult>();

    const selectedShipment: EVA.Core.PurchaseOrderShipmentDto = modalDismissPromise.data.shipment;

    return selectedShipment;
  }

  @EvaFeedback({
    i18nFailKey: 'shipment.fetch.fail'
  })
  private async fetchShipmentDetails(request: Partial<EVA.Core.GetShipmentDetails>): Promise<EVA.Core.GetShipmentDetailsResponse> {
    const [action, fetchPromise] = getShipmentDetails.createFetchAction(request);

    store.dispatch(action);

    try {
      await fetchPromise;
    } catch (error) {
      this.logger.error(`error fetching shipment with request ${request}`, error);
    }

    return fetchPromise;
  }

  /**
   * Fetch a list of shipments by passing in the backend ID
   */
  @EvaFeedback({
    i18nFailKey: 'shipments.fetch.fail'
  })
  private async fetchShipmentsByBackendId(backendId: string) {
    const organizationUnitID = await this.getCurrentOrganizationUnitId();
    const request: Partial<EVA.Core.ListPurchaseOrderShipments> = {
      PageConfig: {
        Start: 0,
        Limit: 10,
        Filter: {
          StatusIDs: [0],
          BackendID: backendId,
          IncludeChildOrganizationUnits: undefined,
          IsCompleted: false,
          OrderBackendID: undefined,
          OrderID: undefined,
          OrganizationUnitID: organizationUnitID,
          ShipmentID: undefined,
          StatusID: undefined,
          TrackingCode: undefined
        },
        SortDirection: undefined,
        SortProperty: undefined,
      }
    };

    const [action] = listPurchaseOrderShipments.createFetchAction(request);

    const listPurchaseOrderShipmentsPromise: EVA.Core.ListPurchaseOrderShipmentsResponse =
      await listPurchaseOrderShipments.fetchData(action);

    return listPurchaseOrderShipmentsPromise;
  }

  /**
   * Asks the user if they want to perform the manual shipment, resolves with whether the user accepted or not
   */
  private async showManualShipmentPrompt(): Promise<boolean> {
    const alert = await this.alertCtrl.create({
      header: this.$translate.instant('shipment.inspection.needed.title'),
      message: this.$translate.instant('shipment.inspection.needed.message'),
      buttons: [
        {
          text: this.$translate.instant('action.cancel'),
          role: 'cancel'
        },
        {
          text: this.$translate.instant('action.start.scanning'),
          role: 'submit'
        }
      ]
    });

    alert.present();

    const alertDismissPromise: Promise<boolean> = alert.onDidDismiss().then(({ role: dissmissRole }) => {
      const accepted = dissmissRole === 'submit' ? true : false;

      return accepted;
    });

    return alertDismissPromise;
  }
}
