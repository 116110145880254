import { Injectable } from '@angular/core';
import { Logger, ILoggable } from '../../shared/decorators/logger';
import { getCurrentUser } from '@springtree/eva-sdk-redux';
import isNotNil from '../../shared/operators/isNotNil';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

interface ICountryType { CurrentCountryID: string; }
interface ICurrencyInformation { CurrentCurrencyID: string; }

interface ICountryStateLengthMapping {
  acceptedLength: number;
  valid: boolean;
}

@Logger('[country-settings-provider]')
@Injectable()
export class CountrySettingsProvider implements ILoggable {

  /**
   * Current user - we use this to check the `Current Currency and Country`
   */
  private currentUser$ = getCurrentUser.getResponse$().pipe(
    isNotNil(),
    map(currentUser => currentUser.User)
  );

  /**
   * Logger implementation
   */
  public logger: Partial<Console>;

  /**
   * We only want to show the sms subtext for US
   * @see https://eva2015.atlassian.net/browse/OPTR-4355
   */
  public showSmsSubText$ = this.currentUser$.pipe(
    map(user => this.isCountryUnitedStates(user))
  );

  /**
   * Whether to hide the year on `US customer birthday registration flow`
   *
   * @see https://eva2015.atlassian.net/browse/OPTR-2390
   */
  public hideYear$: Observable<boolean> = this.currentUser$.pipe(
    map(user => this.isCountryUnitedStates(user))
  );

  /**
   * We only show the state information if the country is either `United States Of America` or `Australia`
   *
   * @see https://eva2015.atlassian.net/browse/OPTR-6407
   */
  public async canShowState(): Promise<boolean> {

    const isCountryUnitedStates = await this.isCountryUs();
    const isCountryAustralia = await this.isCountryAu();

    const shouldShowState  = isCountryAustralia || isCountryUnitedStates;

    return shouldShowState;
  }

  /**
   * Get the state field required length
   *
   * @see https://eva2015.atlassian.net/browse/OPTR-6391
   */
  public async getStateFieldAcceptedLength(): Promise<number> {

    const stateLengthMappings: ICountryStateLengthMapping[] = [
      { acceptedLength: 2, valid: await this.isCountryUs() },
      { acceptedLength: 3, valid: await this.isCountryAu() }
    ];

    const matchingMapping = stateLengthMappings.find((stateLengthMapping) => stateLengthMapping.valid);

    if ( matchingMapping ) {
      return matchingMapping.acceptedLength;
    }

    throw new Error('No state length mapping found');
  }

  /**
  * Calculate the initial value to be presented in the Date of Birth picker.
  *
  * @see https://eva2015.atlassian.net/browse/OPTR-5818
  * */
  public async getDateOfBirthDefaultValue(): Promise<string> {
    /**
     * We now calculate the date time that should be presented/preset in the date time picker.
     * Value must be a date string following the
     * [ISO 8601 datetime format standard](https://www.w3.org/TR/NOTE-datetime),
     * `1996-12-19`.
     */
    let dateStringRepresentation = null;

    // We return a value only if the OU is not of type US
    //
    const isCountryUnitedStates: boolean = await this.isCountryUs();

    if (!isCountryUnitedStates) {

      const today = new Date();
      const currentYear = today.getFullYear();
      const yearsBack = 30;

      dateStringRepresentation = `${currentYear - yearsBack}-01-01`;
    }

    return dateStringRepresentation;
  }

  /**
   * Depending on the country, we will show the user the date differently. We are doing this because in the U.S
   * we are not allowed to save the birthyear.
   *
   * @see https://eva2015.atlassian.net/browse/OPTR-2390
   */
  async getIntlDatePipeOpts(): Promise<Intl.DateTimeFormatOptions> {
    const isCountryUnitedStates: boolean = await this.isCountryUs();

    const opts: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };

    if (isCountryUnitedStates) {
      delete opts.year;
      opts.month = 'long';
    }

    return opts;
  }

  /**
   * Depending on the country we display a different placeholder for the dateOfBirth picker
   * @see https://eva2015.atlassian.net/browse/OPTR-7670
   * @see https://eva2015.atlassian.net/browse/OPTR-7970
   */
  public async getDateOfBirthPlaceholder(): Promise<string> {
    const isCountryUnitedStates: boolean = await this.isCountryUs();

    const dateOfBirthPlaceholder = isCountryUnitedStates ? 'MM DD' : 'DD MM YYYY';

    return dateOfBirthPlaceholder;
  }

  private async isCountryDe(): Promise<boolean> {
    const currentUser = await this.currentUser$.pipe(take(1)).toPromise();
    const isCountryGermany: boolean = this.isCountryGermany(currentUser);

    return isCountryGermany;
  }

  private async isCountryUs(): Promise<boolean> {
    const currentUser = await this.currentUser$.pipe(take(1)).toPromise();
    const isCountryUnitedStates: boolean = this.isCountryUnitedStates(currentUser);

    return isCountryUnitedStates;
  }

  private async isCountryAu(): Promise<boolean> {
    const currentUser = await this.currentUser$.pipe(take(1)).toPromise();
    const isCountryAustralia: boolean = this.isCountryAustralia(currentUser);

    return isCountryAustralia;
  }

  /**
   * Check that the current country is `United States of America`
   */
  private isCountryUnitedStates(countryInformation: ICountryType): boolean {
    return countryInformation.CurrentCountryID === 'US';
  }

  /**
   * Check that the current country is `Australia`
   */
  private isCountryAustralia(countryInformation: ICountryType): boolean {
    return countryInformation.CurrentCountryID === 'AU';
  }

   /**
   * Check that the current country is `Germany`
   */
  private isCountryGermany(countryInformation: ICountryType): boolean {
    return countryInformation.CurrentCountryID === 'DE';
  }

  /**
   * Check that the current currency is `US Dollar`
   */
  private isCurrencyDollar(currencyInformation: ICurrencyInformation): boolean {
    return currencyInformation.CurrentCurrencyID === 'USD';
  }
}
