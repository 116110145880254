import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { getShoppingCart, store, suspendOrder } from '@springtree/eva-sdk-redux';
import { map, startWith, take } from 'rxjs/operators';
import { EvaApplicationConfigProvider } from 'src/app/services/eva-application-config/eva-application-config';
import { StationSelectorProvider } from 'src/app/services/eva-station-selector/station-selector.provider';
import { EvaFeedback, IExtendedFeedback } from '../../shared/decorators/eva-feedback';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import isNotNil from '../../shared/operators/isNotNil';

interface FormValue {
  printReceipt: boolean;
  station: number;
}

/**
 * @see https://eva2015.atlassian.net/browse/OPTR-1787
 */
@Logger('[pause-order-modal-component]')
@Component({
  selector: 'pause-order-modal',
  templateUrl: './pause-order.modal.html',
  styleUrls: ['./pause-order.modal.scss']
})
export class PauseOrderModalComponent implements ILoggable, OnInit {
  /**
   * Logger instance
   */
  public logger: Partial<Console>;

  /**
   * Translation labels used by the form controls and validation
   */
  public translationLabels = {
    error: {
      printReceipt: 'print.receipt.error'
    },
    success: {
      printReceipt: 'success.printing.receipt'
    }
  }

  /**
   * Pause order form group
   */
  public form: FormGroup;

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private $stationSelector: StationSelectorProvider,
    private $evaApplicationConfig: EvaApplicationConfigProvider,
  ) {
  }

  async ngOnInit() {
    this.form = await this.setupPauseDocumentsForm();
    // Listen to form preferences and update the button label
    //
    this.listenToPreferences();
  }

  public async setupPauseDocumentsForm(): Promise<FormGroup> {
    const printReceiptDefaultToggle = await this.$evaApplicationConfig.printReceiptDefaultToggle$.pipe(take(1)).toPromise();

    // Create form group
    //
    const pauseOrderDocumentsForm = this.formBuilder.group({
      printReceipt: [printReceiptDefaultToggle, Validators.required],
      station: [this.$stationSelector.getCurrentStationId()]
    });

    return pauseOrderDocumentsForm;
  }


  @EvaFeedback({
    i18nFailKey: 'suspended-orders.suspend.fail',
    i18nSuccessKey: 'suspended-orders.suspend.success'
  })
  async pauseOrder(): Promise<EVA.Core.EmptyResponseMessage> {
    try {
      const printReceipt = (this.form.value as FormValue).printReceipt;

      if (printReceipt) {
        return this.suspendOrder();
      }
    } catch (error) {
      this.logger.log('error calling suspendOrder', error);
    } finally {
      this.dismiss();
    }
  }

  @EvaFeedback()
  async closeModal() {
    const evaFeedback: IExtendedFeedback = {
      i18nFailParams: 'suspended-orders.suspend.fail',
      i18nSuccessParams: 'suspended-orders.suspend.success',
      feedbackPromise: this.suspendOrder(),
      finallyCallback: () => this.dismiss()
    };

    return evaFeedback;
  }

  /**
   * Suspend ortder logic
   */
  private async suspendOrder(): Promise<EVA.Core.EmptyResponseMessage> {
    const { printReceipt, stationId } = this.form.value;

    const orderId: number = await getShoppingCart.getResponse$().pipe(
      isNotNil(),
      map( cart => cart.ShoppingCart.ID ),
      take(1)
    ).toPromise();

    /**
     * Call the suspendOrder action with what we received.
     *
     * If the user chose no (don't print the order) - we will automatically pass that false value
     * to the server.
     */
    const [suspendOrderAction, suspendOrderResult] = suspendOrder.createFetchAction({
      OrderID: orderId,
      PrintReceipt: printReceipt,
      StationID: stationId,
    });

    store.dispatch(suspendOrderAction);

    // if this fails, the order is still suspended.
    return suspendOrderResult;
  }

  /**
   * Fetches the translation labels or their overrides (if the `getTranslationLabels` method is overriden in an inheriting class)
   *
   * Listens to the choices to populate the modal confirm button text
   */
  private listenToPreferences(): void {
    this.form.valueChanges.pipe(
      startWith(this.form.value),
    ).subscribe( (newFormValue: FormValue) => {
      // Toggle the station validation based on whether the user chose to print the receipt
      //
      if (newFormValue.printReceipt) {
        this.form.get('station').setValidators(Validators.required);
      } else {
        this.form.get('station').removeValidators(Validators.required);
      }
    });
  }

  /**
   * Close the modal
   */
  private dismiss(data?: any): void {
    this.modalCtrl.dismiss(data);
  }
}
