import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, NavController } from '@ionic/angular';
import { isNil } from 'lodash';
import { bootstrapLocalStorageKeys } from '../../shared/bootstrap-store';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { IEnvironment } from '../eva-start-up/eva-start-up';
import { isEmpty } from 'lodash-es';

interface ISetOverrideOpts {
  restartApp: boolean;
}

@Logger('[environment-override-provider]')
@Injectable()
export class EnvironmentOverrideProvider implements ILoggable {
  logger: Partial<Console>;

  /** we will be calling this in the page */
  public onGetNewOverrideCallback: (environmentOverride: IEnvironment) => any;

  private readonly LOCAL_STORAGE_KEY = 'environmentOverride';

  private alertPresent = false;

  constructor(
    private alertCtrl: AlertController,
    private $translate: TranslateService,
    private navCtrl: NavController
  ) {
    // We will expose the function performing this action on window incase we need to change the environment in the browser
    //
    globalThis['overrideEnvironment'] = () => this.overrideEnvironment();
  }

  public get overrideActive(): boolean {
    const override = this.getOverride();
    return !isNil(override) && !isEmpty(override.endpoint);
  }

  getOverride(): IEnvironment {
    try {
      const env = JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_KEY)) as IEnvironment;

      return env;
    } catch (e) {
      this.logger.error('Error parsing environment override');
    }
  }

  public async getNewOverride() {
    return new Promise<IEnvironment>((resolve) => {

      this.onGetNewOverrideCallback = (environmentOverride: IEnvironment) => {
        resolve(environmentOverride);

        // We only want to use this callback once.
        //
        this.onGetNewOverrideCallback = null;
      };

      this.navCtrl.navigateForward(['environment-override'])
        .then(() => {
          this.logger.log('navigated..');
        })
        .catch( error => {
          this.logger.error('error calling navigateForward', error);
        });

    });
  }

  /** This will save the override in localStorage */
  public async setOverride(env: Partial<IEnvironment>, opts?: ISetOverrideOpts) {

    // if there was already an environment present, we want to do a fresh restart
    //
    if (opts && opts.restartApp) {
      // Resetting the override and all bootstrap information
      //
      this.resetOverride();

      const alert = await this.alertCtrl.create({
        message: this.$translate.instant('environment.override.loading')
      });

      alert.present();

      // Reloading the application so the sdk bootstraps pointing to the correct thing
      //
      window.location.replace('login');
    }

    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(env));
  }

  /**
   * This will ask the user if they are sure about overriding the current environment
   * and it will also get a new override and set it
   */
  async overrideEnvironment() {
    // If there is already an alert present return early
    //
    if (this.alertPresent) {
      return;
    }

    const alert = await this.alertCtrl.create({
      header: this.$translate.instant('environment.override.confirmation.title'),
      message: this.$translate.instant('environment.override.confirmation.description'),
      buttons: [{
        text: this.$translate.instant('action.cancel'),
        role: 'cancel'
      }, {
        text: this.$translate.instant('action.ok'),
        role: 'submit'
      }]
    });

    alert.present();

    this.alertPresent = true;

    const dismissedPromise = alert.onDidDismiss().then(eventData => eventData.role as 'cancel' | 'submit' );

    const role = await dismissedPromise;

    this.alertPresent = false;

    // If the user pressed cancel, we will return early.
    //
    if (role === 'cancel') {
      return;
    }

    // After we got a new override, we will save it and just refresh
    //
    const environmentOverride = await this.getNewOverride();

    // Whenever setting new overrides, we want to restart the app.
    //
    this.setOverride(environmentOverride, {
      restartApp: true
    });
  }

  /** This will remove the override from localStorage */
  public resetOverride() {
    // We want to clean local storage of SDK dependencies so we have a clean start up
    //
    [
      ...Object.values(bootstrapLocalStorageKeys),
      // We also want to get delete the currently selected station id
      //
      'stationId'
    ]
    .forEach(key => localStorage.removeItem(key));

    localStorage.removeItem(this.LOCAL_STORAGE_KEY);
  }
}
