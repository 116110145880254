import { Component, Input, OnInit } from '@angular/core';
import { isNil } from 'lodash-es';
import { map } from 'rxjs/operators';
import { DecimalFormatService } from 'src/app/services/decimal-format/decimal-format.service';
import { EvaApplicationConfigProvider } from 'src/app/services/eva-application-config/eva-application-config';

export interface ILocalizedPrice {
  price?: number;
  priceInTax?: number;
  unitPrice?: number;
  unitPriceInTax?: number;
  originalPrice?: number;
  originalPriceInTax?: number;
  originalUnitPrice?: number;
  originalUnitPriceInTax?: number;
  ecoTaxInTax?: number;
}

@Component({
  selector:     'eva-localized-price',
  templateUrl:  './localized-price.component.html',
  styleUrls: ['localized-price.component.scss']
})
export class LocalizedPriceComponent implements OnInit{

  public maximumFractionDigits: number;

  /** Show price without tax if country is US and currency is USD */
  public showPriceWithoutTax$ = this.$appConfig.preferredPriceDisplayMode$.pipe(
    map((preferredPriceDisplayMode) => {

      // If an input was provided we will use that, otherwise we will use preferredPriceDisplayMode from GetApplicationConfiguration
      const preferredPriceDisplay = (preferredPriceDisplayMode === EVA.Core.OrderPreferredPriceDisplayMode.ExTax);
      const preferredOrderDisplay = (this.preferredPriceDisplayMode === EVA.Core.OrderPreferredPriceDisplayMode.ExTax);
      const showPriceWithoutTax = isNil(this.preferredPriceDisplayMode) ? preferredPriceDisplay : preferredOrderDisplay;

      return showPriceWithoutTax;
    })
  );

  @Input() pricing: ILocalizedPrice;

  @Input() currencyString: string;

  @Input() preferredPriceDisplayMode: EVA.Core.OrderPreferredPriceDisplayMode = EVA.Core.OrderPreferredPriceDisplayMode.InTax;

  public get isEcoTax(): boolean {
    return !isNil(this.pricing?.ecoTaxInTax)
  }

  constructor(
    private $appConfig: EvaApplicationConfigProvider,
    private $decimalFormat: DecimalFormatService,
  ) { }

  public ngOnInit() {
    this.updatePriceMaxDecimalsAllowed(this.pricing,this.currencyString);
  }

  // We show price decimals based on the OU/Product/Giftcard currency type
  // @see https://n6k.atlassian.net/browse/OPTR-19880
  public updatePriceMaxDecimalsAllowed(pricing:ILocalizedPrice, currencyID: string) {
    this.maximumFractionDigits = undefined;
    if(pricing && currencyID){
      this.maximumFractionDigits = !isNil(currencyID) ? this.$decimalFormat.getDecimalPrecisionCurrency(currencyID) : 2;
    }
  }

}
