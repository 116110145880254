import { Component, Input, OnInit } from '@angular/core';
import { getGiftWrappingOptionsForOrder, getOrder, getShoppingCart } from '@springtree/eva-sdk-redux';
import { get, isNil } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDisplayProperties } from 'src/app/pages/basket/components/basket-list-product/basket-list-product.component';
import { ILineActionsTypes } from 'src/app/pages/basket/components/basket-list/basket-list.component';
import { slideUpDown } from '../../shared/animations';
import isNotNil from '../../shared/operators/isNotNil';
import { ILocalizedPrice } from '../localized-price/localized-price.component';

export interface IProductLine {
  pricing: ILocalizedPrice;
  description: string;
  totalQuantityToShip: number;
  currentQuantityToShip?: number;
  orderLineType?: number;
  quantityOrdered?: number;
  quantityShipped?: number;
  isServiceProduct?: boolean;
  displayProperties?: EVA.Core.OrderLineDisplayProperties
}

export interface ICheckoutTotal {
  amounts: EVA.Core.OpenOrderAmounts;
  currency: string;
  orderLines: EVA.Core.OrderLineDto[];
  totalItems: number;
  partialShipment: EVA.Core.ShipOrder.ShipmentOrderLine[];
}

@Component({
  selector: 'eva-checkout-total',
  templateUrl: 'checkout-total.component.html',
  styleUrls: ['checkout-total.component.scss'],
  animations: [slideUpDown]
})
export class CheckoutTotalComponent implements OnInit{

  private _orderData: ICheckoutTotal;

  public get orderData(): ICheckoutTotal {
    return this._orderData;
  }

  @Input() showEcoTax: boolean = false;

  @Input() checkoutPageActive: boolean = false;

  @Input()
  public set orderData(newOrderData: ICheckoutTotal) {
    if ( newOrderData !== this._orderData ) {
      this._orderData = newOrderData;

      this.productLines = this.getProductLines(newOrderData);

      this.setProductOrderLinesTypes();

      this.giftwrapLine = this.getGiftWrappingLine(newOrderData);

      this.containsAmountsTypes = this.getContainsAmountsTypes(newOrderData);
    }
  }

  @Input() isRefundAdyenDropin: boolean = false;

  // We check if we should display each order line by type
  @Input() shouldShowOrderLinesTypes: boolean = false;

  // This is used to display the info when we are on the checkout page
  // @see https://n6k.atlassian.net/browse/OPTR-23021
  public ecoTaxesOnCart$: Observable<IDisplayProperties> = getShoppingCart.getResponse$().pipe(
    isNotNil(),
    map( cart => {
      const ecoTax = cart.ShoppingCart['TotalEcoTaxInTax'];
      if(this.showEcoTax && !isNil(ecoTax) && ecoTax > 0){
        return { EcoTaxInTax:cart.ShoppingCart['TotalEcoTaxInTax'] }
      }
    })
  )

  // This is used to display the info when we are on the documents page
  // @see https://n6k.atlassian.net/browse/OPTR-23021
  public ecoTaxesOnOrder$: Observable<IDisplayProperties> = getOrder.getResponse$().pipe(
    isNotNil(),
    map( order => {
      const ecoTax = order.Result['TotalEcoTaxInTax'];
      if(this.showEcoTax && !isNil(ecoTax) && ecoTax > 0 && !this.checkoutPageActive){
        return { EcoTaxInTax:order.Result['TotalEcoTaxInTax'] }
      }
    })
  )

  // We actually display Order Lines by Action type and expand them by default
  public showOrderLinesTypes: boolean = false;

  // We need this flag for some component instances that has delayed data
  private alreadyCheckedTypeLines: boolean = false;

  /**
   * Whether the current order has giftwrapping lines
   */
  public showGiftwrapping$: Observable<boolean> = getGiftWrappingOptionsForOrder.getState$().pipe(
    isNotNil(),
    map(giftWrappingResult => giftWrappingResult.response),
    isNotNil(),

    map(giftwrappingResult => {
      const orderHasGiftwrappingSelection = giftwrappingResult.WrapIndividually || giftwrappingResult.WrapOrder;

      return orderHasGiftwrappingSelection;
    })
  );

  /**
   * Whether the current order has greeting card
   */
  public hasGreetingCard$: Observable<boolean> = getGiftWrappingOptionsForOrder.getState$().pipe(
    isNotNil(),
    map(giftWrappingResult => giftWrappingResult.response),
    isNotNil(),
    map(giftwrappingResult => !!giftwrappingResult.GreetingCardProductID),
  );

  /**
   * Whether the order lines details are shown.
   */
  public orderLinesCollapsed = true;

  public intlNumberTaxOpts: Intl.NumberFormatOptions = {
    style: 'percent',
    maximumFractionDigits: 2,
  };

  public preferredPriceDisplayMode$: Observable<EVA.Core.OrderPreferredPriceDisplayMode> = getShoppingCart.getResponse$().pipe(
    map( response => response?.ShoppingCart.PreferredPriceDisplayMode)
  );

  public get subTotalPricing(): ILocalizedPrice {
    const subTotalPricing: ILocalizedPrice = {
      price: this.orderData?.amounts?.Types?.NormalProduct?.Amount,
      priceInTax: this.orderData?.amounts?.Types?.NormalProduct?.InTax,
    };

    return subTotalPricing;
  }

  public get giftwrappingCosts(): ILocalizedPrice {
    const costs: ILocalizedPrice = {
      price: get(this.orderData, 'amounts.Types.GiftWrappingCosts.Amount', 0),
      priceInTax: get(this.orderData, 'amounts.Types.GiftWrappingCosts.InTax', 0)
    };

    return costs;
  }

  public productLines: IProductLine[] = [];

  public lineActionTypes = ILineActionsTypes;

  // Order Line Types
  public productLinesReserveType: IProductLine[] = [];
  public productLinesOrderLineType: IProductLine[] = [];
  public productLinesCarryOutType: IProductLine[] = [];
  public productLinesDeliveryType: IProductLine[] = [];
  public productLinesServiceType: IProductLine[] = [];

  // Computed shipped lines
  public productLinesShippedType: IProductLine[] = [];

  giftwrapLine: EVA.Core.OrderLineDto;

  containsAmountsTypes: boolean;

  ngOnInit(): void {
    if(!this.alreadyCheckedTypeLines){
      this.setProductOrderLinesTypes();
    }
  }

  /**
   * We want to show the order lines separated by line types
   * @see https://n6k.atlassian.net/browse/OPTR-18349
   */
  public setProductOrderLinesTypes(){
    if(this.productLines?.length){
      // Check if all the lines are the same action type
      const haveSingleLineType = this.productLines.every(line =>
        line.orderLineType === this.productLines[0].orderLineType
      );

      if(this.shouldShowOrderLinesTypes && !haveSingleLineType){
        this.orderLinesCollapsed = false;
        this.showOrderLinesTypes = true;
        this.alreadyCheckedTypeLines = true;
      } else {
        this.showOrderLinesTypes = false;
      }

      // We reset values in case its executed multiple times
      this.productLinesReserveType = [];
      this.productLinesOrderLineType = [];
      this.productLinesCarryOutType = [];
      this.productLinesDeliveryType = [];
      this.productLinesShippedType = [];
      this.productLinesServiceType = [];

      // Set each array values based on order line types
      this.productLines.forEach(line => {
        // We save the lines that has been already shipped
        if(line.quantityShipped){
          this.productLinesShippedType.push(line);
        }

        // When dealing with return order lines we want to display all lines
        // @see https://n6k.atlassian.net/browse/OPTR-23845
        const isReturnOrder = (line.totalQuantityToShip === -1);
        if(line.isServiceProduct){
          this.productLinesServiceType.push(line);
        }

        // We don't show the lines that are fully shipped unless we are dealing with a return order type
        if(line.quantityOrdered > line.quantityShipped || isReturnOrder){
          switch(line.orderLineType) {
            case this.lineActionTypes.RESERVE_LINE: {
              this.productLinesReserveType.push(line);
              break;
            }
            case this.lineActionTypes.ORDER_LINE: {
              this.productLinesOrderLineType.push(line);
              break;
            }
            case this.lineActionTypes.CARRY_OUT: {
              this.productLinesCarryOutType.push(line);
              break;
            }
            case this.lineActionTypes.DELIVERY: {
              this.productLinesDeliveryType.push(line);
              break;
            }
          }
        }
      })
    }
  }

  getGiftWrappingLine(orderData: ICheckoutTotal): EVA.Core.OrderLineDto {
    const giftWrappingLine = (orderData?.orderLines ?? []).find(orderLine => orderLine.CustomOrderLineType === 'GiftWrapping');

    return giftWrappingLine;
  }

  getProductLines(orderData: ICheckoutTotal) {
    const relevantProductLines = (orderData?.orderLines ?? [])
      // EVA.Core.OrderLineTypes.NormalProduct
      .filter(line => line.Type === 0 && !line.IsCancelled && line.TotalQuantityToShip !== 0);

    // Map the product lines out of the order lines.
    // The product line contains information about the price (With/Without tax)
    // to be properly localized
    //
    const productLines = relevantProductLines
      .map(orderLine => {
        const potentiallyPartialShippedLine = orderData.partialShipment
          .find(lineToShip => lineToShip.OrderLineID === orderLine.ID );

        /** Means this line will be partially shipped */
        const currentQuantityToShip = potentiallyPartialShippedLine ? potentiallyPartialShippedLine.QuantityShipped : null;

        const productLine: IProductLine = {
          orderLineType: orderLine.LineActionType,
          description: orderLine.Description,
          totalQuantityToShip: orderLine.TotalQuantityToShip,
          quantityOrdered: orderLine.QuantityOrdered,
          quantityShipped: orderLine.QuantityShipped,
          isServiceProduct: Boolean(orderLine.Product?.Type & 16),
          currentQuantityToShip,
          pricing: {
            price: orderLine.DisplayProperties.TotalAmount,
            priceInTax: orderLine.DisplayProperties.TotalAmountInTax
          },
          displayProperties: orderLine.DisplayProperties
        };

        return productLine;
      });

    return productLines;
  }

  getContainsAmountsTypes(orderData: ICheckoutTotal): boolean {
    if (orderData && !isNil(orderData.amounts)) {

      enum PossibleTypes {
        Discount = 'Discount',
        ShippingCosts = 'ShippingCosts',
        ReturnCosts = 'ReturnCosts',
        ExtraCosts = 'ExtraCosts',
      };

      const containsAmountsTypes = Object.values(PossibleTypes).some(possibleType => !isNil(orderData.amounts.Types[possibleType]));

      return containsAmountsTypes;
    }
  }

}
