import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class IonInputFocusFixService {
  private isActive = false;

  /**
   * This should be set to active on iOS 12, the implementation details are up to the consumer of this functionality
   * @param active
   */
  activate(active: boolean = true) {
    this.isActive = active;
  }

  getActiveState() {
    return this.isActive;
  }
}
