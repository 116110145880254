import { Injectable } from '@angular/core';
import { store, getStockLabels, listStockMutationReasons } from '@springtree/eva-sdk-redux';
import { first, take } from 'rxjs/operators';
import { EvaFeedback } from 'src/app/shared/decorators/eva-feedback';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { EvaApplicationConfigProvider } from '../eva-application-config/eva-application-config';

@Logger('[stock-label-provider]')
@Injectable()

export class StockLabelProvider implements ILoggable {

  public logger: Partial<Console>;

  private availableStockMutationReasons$ = this.$evaApplicationConfig.stockMutationReasonsAvailable$;

  constructor(private $evaApplicationConfig: EvaApplicationConfigProvider) {}

  /**
   * we use this stream to make sure we have stockLabels in the response even if the call fails at start up
   * @see https://eva2015.atlassian.net/browse/OPTR-7054
   */
  public getStockLabelsStream$() {
    this.refetchStockLabels();
    return getStockLabels.getResponse$();
  }

  public async refetchStockLabels() {
    const stockLabels = await getStockLabels.getResponse$().pipe(first()).toPromise();

    if (!stockLabels?.StockLabels) {
      this.logger.log('Refetching stock labels');
      await this.fetchStockLabels();
    }
  }

  private async fetchStockLabels() {

    const [action, fetchPromise] = getStockLabels.createFetchAction();

    store.dispatch(action);

    try {
      await fetchPromise;
      this.logger.log('Stock labels fetched successfully');
    } catch (error) {
      this.logger.error('Fetching stock labels failed');
    }
  }

  @EvaFeedback({
    i18nFailKey: 'stock.mutation.reasons.fail'
  })
  public async fetchStockMutationReasons() {
    const availableReasons = await this.availableStockMutationReasons$.pipe(take(1)).toPromise();

    const [action, fetchPromise] = listStockMutationReasons.createFetchAction({
      PageConfig: {
        Filter: {
          IDs: availableReasons
        }
      }
    });
    store.dispatch(action);

    return fetchPromise;
  }
}
