import { Injectable } from '@angular/core';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';
import { IParsedBarcode } from '../../modules/scanner/eva-barcode-scanner';
import { EvaParseBarcodeDeviceHubHandlerProvider } from '../eva-parse-barcode-device-hub-handler/eva-parse-barcode-device-hub-handler';
import { EvaParseBarcodeElevationTokenHandlerProvider } from '../eva-parse-barcode-elevation-barcode/eva-parse-barcode-elevation-barcode';
import { EvaParseBarcodeSessionHandlerProvider } from '../eva-parse-barcode-session-handler/eva-parse-barcode-session-handler';


export interface IParseBarcodeHandler {
  handle: (parsedData: IParsedBarcode) => any;
}

export interface IParseBarcodeMainHandler {
  handle: (parsedData: IParsedBarcode) => { isHandled: boolean };
}

/**
 * This method will handle parse barcode response when a page doesn't handle it. So its essentially a fallback for unrelatedScans
 */
@Logger('[eva-parse-barcode-handler-provider]')
@Injectable()
export class EvaParseBarcodeHandlerProvider implements IParseBarcodeHandler, ILoggable {

  public logger: Partial<Console>;

  constructor(
    private $evaParseBarcodeSessionHandler: EvaParseBarcodeSessionHandlerProvider,
    private $evaParseBarcodeDeviceHubHandler: EvaParseBarcodeDeviceHubHandlerProvider,
    private $evaParseBarcodeElevationTokenHandler: EvaParseBarcodeElevationTokenHandlerProvider
  ) { }

  /**
   * Handle a parse barcode type
   */
  handle(parsedData: IParsedBarcode) {
    const returnPayload = { isHandled: false };

    if(parsedData?.Type?.toLowerCase() === 'elevationbarcode'){
      returnPayload.isHandled = this.$evaParseBarcodeElevationTokenHandler.handle(parsedData);
    } else {
      switch (parsedData.Type) {
        case 'SESSION':
          // The SESSION barcode is used to pull an order to this app
          this.$evaParseBarcodeSessionHandler.handle(parsedData).catch(error => {
            this.logger.error('Error handling session pull barcode', error);
          });

          returnPayload.isHandled = true;
          break;

        case 'DeviceHub':
          // The DeviceHub barcode is used to push the current user and order to another app
          // This does not actually use the device hub from SignalR anymore but instead
          // uses a sequence of messages on the client app hub to transfer the user and order
          this.$evaParseBarcodeDeviceHubHandler.handle(parsedData);
          returnPayload.isHandled = true;
          break;
      }
    }

    return returnPayload;
  }
}
