import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EvaToastOptions, IEvaToastButton } from './eva-toast-interface';
import { easing } from 'src/app/shared/animations';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';
import { ModalController } from '@ionic/angular';
import { DebugAllowedProvider } from 'src/app/services/debug-allowed/debug-allowed';
import { ErrorViewerModalComponent } from 'src/app/modals/error-viewer-modal/error-viewer.modal';

/**
 * This component will be responsible for showing notifications
 */
@Logger('[eva-toast-component]')
@Component({
  selector: 'eva-toast',
  templateUrl: 'eva-toast.component.html',
  animations: [
    trigger('toastAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-15%)' }),
        animate(
          `350ms ${easing.enter}`,
          style({
            opacity: 1,
            transform: 'translateY(0%)',
          }),
        ),
      ]),
      transition(':leave', [
        animate(
          `450ms ${easing.leave}`,
          keyframes([
            style({ opacity: 1, offset: 0 }),
            style({ opacity: 0, transform: 'translateY(-15%)', offset: 0.5 }),
            style({ opacity: 0, transform: 'translateX(-200%)', overflow: 'hidden', offset: 0.75 }),
            style({ opacity: 0, height: 0, offset: 1 }),
          ]),
        ),
      ]),
    ]),
  ],
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '[@toastAnimation]': '',
  },
  styleUrls: ['eva-toast.component.scss'],
})
export class EvaToastComponent implements ILoggable, OnInit, OnDestroy {
  logger: Partial<Console>;

  @Input() data: EvaToastOptions;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onDismiss = new EventEmitter<void>();

  private touchDown = false;

  private touchEvents$ = new Subject<TouchEvent>();

  private touchStartEvent: TouchEvent;

  private stop$ = new Subject<void>();

  /** Will return either true or null depending on the presence of the title */
  public get shouldHaveMargin(): boolean {
    const shouldHaveMargin = !Boolean(this.data.title);

    return shouldHaveMargin;
  }

  constructor(private modalCtrl: ModalController, private debugAllowedProvider: DebugAllowedProvider) {
    this.touchEvents$
      .pipe(
        filter(() => this.data.showCloseHandle),
        map(touchEvent => touchEvent.touches[0].screenY),
        filter((newScreenY) => newScreenY < this.touchStartEvent.touches[0].screenY),
        takeUntil(this.stop$)
      )
      .subscribe(() => this.onDismiss.emit());
  }

  @HostListener('touchstart', ['$event']) ontouchStart(e: TouchEvent) {
    this.touchDown = true;
    this.touchStartEvent = e;
  }

  @HostListener('touchend', ['$event']) ontouchEnd(e: TouchEvent) {
    this.touchDown = false;
    this.touchStartEvent = null;
  }

  @HostListener('window:touchmove', ['$event']) ontouchMove(e: TouchEvent) {
    if (this.touchDown) {
      this.touchEvents$.next(e);
    }
  }

  @HostListener('window:keyup.Escape') onEscapePress() {
    this.onDismiss.emit();
  }

  @HostListener('click', ['$event']) async onclick() {
    const debugAllowed = await this.debugAllowedProvider.isAllowed();

    if (this.data.error && debugAllowed) {
      const error = this.data.error;

      const modal = await this.modalCtrl.create({
        component: ErrorViewerModalComponent,
        componentProps: { error }
      });

      modal.present();
    }
  }

  ngOnInit() {
    setTimeout(() => this.onDismiss.emit(), this.data.duration);
  }

  handleBtnClick(button: IEvaToastButton) {
    button.callback();

    this.onDismiss.next();
  }

  ngOnDestroy() {
    this.stop$.next();
  }
}
