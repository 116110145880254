import { Component, OnInit } from '@angular/core';
import { NavController, NavParams, ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import * as Fuse from 'fuse.js';

export interface IItemsSearchData<T> {
  items: T[];
  title: string;
  // keyof will ensure the value of `valueKey` or `labelKey` is a key that exists in T
  // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-1.html
  //
  valueKey: keyof T & string;
  labelKey: keyof T & string;
}

/**
 * This component will act as a modal that shows a list of items in a virtual scroll and which will search
 * items on a certain property
 */
@Component({
  selector: 'eva-item-search',
  templateUrl: 'item-search.html',
  styleUrls: ['./item-search.scss']
})
export class ItemSearchComponent implements OnInit {

  public searchInputForm: FormGroup = this.fb.group({
    searchString: ['']
  });

  public fusejsSearchModel: string;

  public readonly itemSearchData: IItemsSearchData<any> = this.params.get('itemSearchData');

  private _fusejsOpts: Fuse.FuseOptions = {
    keys: [this.itemSearchData.labelKey],
    shouldSort: true
  };

  public get fusejsOpts(): Fuse.FuseOptions {
    return this._fusejsOpts;
  }

  constructor(
    public params: NavParams,
    public navCtrl: NavController,
    public fb: FormBuilder,
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.searchInputForm
      .get('searchString')
      .valueChanges.pipe(
        debounceTime(200)
      )
      .subscribe(value => this.fusejsSearchModel = value);
  }

  dismiss(selectedItem?: any) {
    this.modalCtrl.dismiss(selectedItem);
  }
}
