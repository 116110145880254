import { Injectable } from '@angular/core';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import {
  store,
  setShippingMethod,
  postNLGetAvailablePickUpLocationsForOrder,
  postNLGetShippingOptionsForOrder,
  listAvailableShippingMethods,
  getShoppingCart,
  getStockAvailabilityEstimateForOrder,
  postNLGetAvailableTimeframesForOrder
} from '@springtree/eva-sdk-redux';
import { isNil, min, isEmpty, get, max } from 'lodash';
import { filter, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import isNotNil from '../../shared/operators/isNotNil';
import { from, Observable } from 'rxjs';
import { IDeliveryOption, IRequestedDeliveryDate, IDeliveryTimeFrameOptions, IDeliveryDay, IShippingDeliveryInformation, IPickupLocation } from './models';
import moment from 'moment';


/**
 * Contains helper functions for fetching shipping/pickup options & saving the user choice.
 *
 * @see https://eva2015.atlassian.net/browse/OPTR-2694
 *
 **/
@Injectable()
@Logger('[shipping-provider]')
export class ShippingProvider implements ILoggable {

  /**
   * Is hardcoded for now, will later be taken from an app setting.
   */
  private static readonly NUMBER_OF_DAY_SLOTS = 7;

  /**
   * The formatting for the short dates
   */
  private static readonly SHORT_DATE_FORMATTING = 'DD MMMM';

  /**
   * The formatting for the day of week
   */
  private static readonly DAY_OF_WEEK_FORMATTING = 'dddd';

  /**
   * @see https://eva2015.atlassian.net/browse/OPTR-4093
   */
  private readonly maximumRequestedDeliveryDate = moment().add(2, 'years').toISOString();

  /**
   * Available shipping methods
   */
  public shippingMethods$: Observable<EVA.Core.ShippingMethodByOrderLineDto[]> = listAvailableShippingMethods.getState$().pipe(
    filter(state => !state.isFetching),
    map(state => state.response),
    map(response => get(response, 'AvailableShippingMethods', []) )
  );

  /**
   * Delivery options
   */
  public deliveryOptions$: Observable<IDeliveryOption[]> = this.shippingMethods$.pipe(
    map(shippingMethods => {
      return shippingMethods.map(shippingMethod => this.createDeliveryOption(shippingMethod));
    })
  );

  /** We will use to determine whether to open the shipping modal or not. Both EV and POSTNL need to be present for this to be true now. */
  public shippingMethodAvailable$: Observable<boolean> = this.shippingMethods$.pipe(
    isNotNil(),
    map(shippingMethods => {
      const postNLCarrier = shippingMethods.find(shippingMethod => get(shippingMethod, 'Carrier.Code') === this.PostNLCode);
      const evCarrier = shippingMethods.find(shippingMethod => get(shippingMethod, 'Carrier.Code') === this.EVCode);

      return !isNil(postNLCarrier) || !isNil(evCarrier);
    })
  );

  /**
   * We want to show the pick up location if the selected shipping method is `POSTNL` and the `Date` and `PickUpLocationID` fields are set
   *
   * We will do a check when the cart changes, as its a good indicator that the shipping method changed.
   */
   public showPickUpLocation$: Observable<boolean> = getShoppingCart.getResponse$().pipe(
    isNotNil(),
    map( getShoppingCartResponse => getShoppingCartResponse.ShoppingCart ),
    withLatestFrom(postNLGetShippingOptionsForOrder.getResponse$()
      .pipe(isNotNil())
    ),
    switchMap(([_cart, postNLGetShippingOptionsForOrderResponse]: [EVA.Core.OrderDto, EVA.Carrier.PostNL.PostNLGetShippingOptionsForOrderResponse]) => {
      const requiredPickupFieldsPresent = !isNil(postNLGetShippingOptionsForOrderResponse.PickUpLocationID) && !isNil(postNLGetShippingOptionsForOrderResponse.Date);
      return from(this.currentShippingMethodIsPostNL()).pipe(
        map(currentShippingMethodIsPostNL => currentShippingMethodIsPostNL && requiredPickupFieldsPresent)
      );
    })
  );

  /** Pick up locations which we will use to determine what the  `PickUpLocationID` in `postNLGetShippingOptionsForOrder`represents */
  private postaNLAvailablePickupLocations$ = postNLGetAvailablePickUpLocationsForOrder.getResponse$();

  /** Contains information about the pickup location present on the order (if any). */
  public pickupLocation$: Observable<IPickupLocation> = this.showPickUpLocation$.pipe(
    filter( Boolean ),
    withLatestFrom(postNLGetShippingOptionsForOrder.getResponse$()),
    map( ([ _, postaNLAvailablePickupLocations]) => postaNLAvailablePickupLocations ),
    mergeMap( (postaNLAvailablePickupLocations) => {
      return this.postaNLAvailablePickupLocations$.pipe(
        isNotNil(),
        map((availablePickupLocations) => {
          const pickupLocation = availablePickupLocations.Locations.find( location => {
            const locationCode = location.LocationCode;
            if (isNil(locationCode)) {
              return false;
            }

            return locationCode.toString() === postaNLAvailablePickupLocations.PickUpLocationID;
          });


          const pickUpLocation: IPickupLocation = {
            ...pickupLocation,
            date: postaNLAvailablePickupLocations.Date,
          };

          return pickUpLocation;
      }));
    })
  );


  public postNlShippingDeliveryInformation$: Observable<IShippingDeliveryInformation> = postNLGetShippingOptionsForOrder.getResponse$().pipe(
    isNotNil(),
    switchMap (async (postNlShippingOptions: EVA.Carrier.PostNL.PostNLGetShippingOptionsForOrderResponse) => {
      const date = postNlShippingOptions?.Date as string;
      const timeFrameOption = postNlShippingOptions?.TimeframeOptions;

      if (isNil(timeFrameOption)) {
        return null;
      }

      const deliveryTimeframe = await this.getPostNlShippingDeliveryInformation(date, timeFrameOption);

      return deliveryTimeframe;
    })
  );

  public evShippingDeliveryInformation$: Observable<IShippingDeliveryInformation> = getShoppingCart.getResponse$().pipe(
    map((shoppingCartResponse) => {
      const date = this.getRequestedDate(shoppingCartResponse.ShoppingCart);
      // Gather the ev delivery info - for now only the date
      //
      const evDeliveryInfo: IShippingDeliveryInformation = {
        date,
        carrier: 'ev'
      };

      return evDeliveryInfo;
    })
  );

  /**
   * Logger implementation
   */
  public logger: Partial<Console>;

  /**
   * Action type value for delivery order lines.
   */
  private readonly DeliveryActionTypeValue = 4;

  /**
   * Post NL Delivery carrier code.
   */
  public readonly PostNLCode = 'POSTNL';

  /**
   * EV Delivery carrier code.
   */
  public readonly EVCode = 'EV';

  /**
   * Checks if the order has some items with unknown delivery time
   *
   * @see https://eva2015.atlassian.net/browse/OPTR-6512
   */
  public orderContainsUnknownDeliveryTimeItems (orderLines: EVA.Core.GetStockAvailabilityEstimateForOrderResponse.OrderLine[]) {
    if (isEmpty(orderLines)) {
      return true;
    }

    const someItemsHaveUnknownDeliveryTime = orderLines.some(
      orderLine => isNil(orderLine.RequestedDate) || !orderLine.CanBeFulfilled
    );

    return someItemsHaveUnknownDeliveryTime;
  }

  /**
   * Fetching the available shipping timeslots
   */
  public async fetchDeliveryTimeframes(orderId: number, options: IDeliveryTimeFrameOptions): Promise<EVA.Carrier.PostNL.PostNLGetAvailableTimeframesForOrderResponse> {
    const [request, postNLGetAvailableTimeframesForOrderPromise] = postNLGetAvailableTimeframesForOrder.createFetchAction({
      StartDate: options.date,
      EndDate: options.date,
      OrderID: orderId,
      SkipAvailabilityChecks: options.skipAvailabilityChecks
    });

    store.dispatch(request);

    try {
      const result = await postNLGetAvailableTimeframesForOrderPromise;

      return result;
    } catch (error) {
      this.logger.error('Error calling postNLGetAvailableTimeframesForOrder', error);
     }
  }

  /**
   * Gets an array of delivery days based on the initial date
   *
   * For now the number of day slots is hardcoded but it will, in the future, be replaced with a setting
   */
  public getDeliveryDays(initialDate: string): IDeliveryDay[] {
    const momentDate = moment(new Date(initialDate));

    const deliveryDaysResult: IDeliveryDay[] = [];

    for (let i = 0; i < ShippingProvider.NUMBER_OF_DAY_SLOTS; i++) {

      const shortDate = momentDate.format(ShippingProvider.SHORT_DATE_FORMATTING);
      const dayOfWeek = momentDate.format(ShippingProvider.DAY_OF_WEEK_FORMATTING);

      const deliveryDayEntry: IDeliveryDay = {
        dayOfWeek,
        date: momentDate.toISOString(),
        shortDate
      };

      deliveryDaysResult.push(deliveryDayEntry);

      // Go to the next date
      //
      momentDate.add(1, 'days');
    }

    return deliveryDaysResult;
  }

  /**
   * Gets the delivery option by code
   */
  public async getShippingMethodByCode(code: string): Promise<IDeliveryOption> {
    const shippingMethods = await this.shippingMethods$.pipe(take(1)).toPromise();

    const matchingShippingMethod = shippingMethods.find( shippingMethod => shippingMethod.Carrier.Code === code );

    if (isNil(matchingShippingMethod)) {
      return null;
    }

    const mappedMatchingShippingMethod = this.createDeliveryOption(matchingShippingMethod);

    return mappedMatchingShippingMethod;
  }

  /**
   * Get the requested delivery date object based on the stock availability estimate for order
   */
  public async getRequestedDeliveryDate(
    orderId: number,
    deliveryOrderLineIds: number[]
  ) {
    const deliveryOrderLinesPayload = deliveryOrderLineIds.map(orderLineId => ({ ID: orderLineId }));
    let requestedDeliveryDateSettings: IRequestedDeliveryDate;

    const [request, response] = getStockAvailabilityEstimateForOrder.createFetchAction({
      OrderID: orderId,
      OrderLines: deliveryOrderLinesPayload
    });

    store.dispatch(request);

    const estimateResponse = await response;
    if (isNil(estimateResponse) || isEmpty(estimateResponse.OrderLines)) {
      this.logger.log('The order contains items with an unknown delivery time');
      requestedDeliveryDateSettings = {
        minimumDate: undefined,
        selectedDate: undefined,
        maximumDate: this.maximumRequestedDeliveryDate
      };

      return;
    }

    const minDate = await this.getStockMinimumDate(estimateResponse);
    if (isNil(minDate)) {
      this.logger.log('The order contains items with an unknown delivery time');
      requestedDeliveryDateSettings = {
        minimumDate: undefined,
        selectedDate: undefined,
        maximumDate: this.maximumRequestedDeliveryDate
      };
    } else {

      // The selected date is now computed separately because the minimum date could be earlier than the selected date.
      // @see task https://eva2015.atlassian.net/browse/OPTR-3342
      let selectedDate = await this.getStockSelectedDate(estimateResponse);

      if (isNil(selectedDate)) {
        selectedDate = minDate;
      }

      requestedDeliveryDateSettings = this.calculateRequestedDeliveryDateSettings(
        minDate, selectedDate
      );
    }

    return requestedDeliveryDateSettings;
  }

  /**
   * Gets the minimum date
   */
  public async getStockMinimumDate(estimateResponse: EVA.Core.GetStockAvailabilityEstimateForOrderResponse): Promise<Date> {
    try {
      //
      // Get the minimum stock availability date for each orderline
      const minimumStockAvailabilityDates = estimateResponse.OrderLines.map(
        orderLine => this.getMinimumStockAvailabilityDate(orderLine)
      );

      if (isEmpty(minimumStockAvailabilityDates)) {
        return undefined;
      }

      //
      // Return the earliest date of minimumStockAvailabilityDates
      // that satisfies all stock items. We do a max(date array)
      // to make sure all products can be delivered at the same date.
      const minimumAlternativeDateValue: Date = max(minimumStockAvailabilityDates);

      return minimumAlternativeDateValue;
    } catch (error) {
      this.logger.error('Error calling `getStockAvailabilityEstimateForOrder` service ', error);
    }
  }

  /**
   * Gets the stock availability date selection for an order and delivery lines.
   */
  public async getStockSelectedDate(estimateResponse: EVA.Core.GetStockAvailabilityEstimateForOrderResponse): Promise<Date> {
    try {
      //
      // Get the stock availability selected date for orderlines
      const minimumStockAvailabilityDates = estimateResponse.OrderLines.map(
        orderLine => this.getSelectedStockAvailabilityDate(orderLine)
      );

      if (isEmpty(minimumStockAvailabilityDates)) {
        return undefined;
      }

      //
      // Return the earliest date
      const minimumAlternativeDateValue: Date = max(minimumStockAvailabilityDates);

      return minimumAlternativeDateValue;
    } catch (error) {
      this.logger.error('Error calling `getStockAvailabilityEstimateForOrder` service ', error);
    }
  }

  /**
   * Fetching the shipping options for an order but also the available pickup locations for that order
   */
  public fetchPostNlShippingOptions(orderId: number) {

    this.getShippingOptionsForOrder(orderId);

    this.getAvailablePickUpLocationsForOrder(orderId);
  }

  /**
   * Sets the shipping method for an order Id & a requested date.
   */
  public setShippingMethod(shippingMethodId: number, orderId: number, requestedDate: string): Promise<EVA.Core.ShoppingCartResponse> {
    const [action, response] = setShippingMethod.createFetchAction({
      OrderID: orderId,
      RequestedDeliveryDate: requestedDate,
      ShippingMethodID: shippingMethodId,
    });

    store.dispatch(action);
    return response;
  }

  public fetchAvailableShippingMethodsForOrder(order: EVA.Core.OrderDto) {
    const deliveryOrderLineIds = this.getDeliveryOrderLineIds(order);

    const [action] = listAvailableShippingMethods.createFetchAction({
      OrderID: order.ID,
      OrderLineIDs: deliveryOrderLineIds,
    });
    store.dispatch(action);
  }

  /**
   * A shipping method is selected if the `RequestedDate` and `ShippingMethodID`
   */
  public isShippingMethodSelected(cartResponse: EVA.Core.ShoppingCartResponse) {
    const deliveryLines = cartResponse.ShoppingCart.Lines.filter(line =>
      line.Type === 0 && // line needs to be a product - we filter stuff like ShippingCosts or Discount here
      line.LineActionType === this.DeliveryActionTypeValue);

      if ( isEmpty(deliveryLines) ) {
        return false;
      }

      /** A delivery line has a 'Shipping method' seleceted incase there is both a `requestedDate` and `ShippingMethodID` set */
      const deliveryLinesHaveShippingMethod = deliveryLines.every(deliveryLine => !isNil(deliveryLine.ShippingMethodID));

      return deliveryLinesHaveShippingMethod;
  }

  /**
   * Gets the delivery order lines Ids out of an order.
   */
  public getDeliveryOrderLineIds(order: EVA.Core.OrderDto): number[] {
    // get the order lines with type delivery
    const orderLines = order.Lines;

    const orderLinesId = orderLines
      .filter(orderLine => orderLine.LineActionType === this.DeliveryActionTypeValue)
      .map(orderLine => orderLine.ID);

    return orderLinesId;
  }

  /**
   * Checks that the current selected shipping method is Post NL
   */
  public async currentShippingMethodIsPostNL(): Promise<boolean> {
    const currentShippingMethod = await this.getCurrentShippingMethod();

    const currentShippingMethodIsPostNL = !isNil(currentShippingMethod) && currentShippingMethod.Carrier.Code === this.PostNLCode;

    return currentShippingMethodIsPostNL;
  }

  /**
   * Get the current order
   */
  public async getCurrentOrder() {
    const currentOrder = await getShoppingCart.getResponse$().pipe(
      isNotNil(),
      map(cartResponse => cartResponse.ShoppingCart),
      take(1)
    ).toPromise();
    return currentOrder;
  }

  /**
   * Gets the requested date from the order lines
   */
  public getRequestedDate(order: EVA.Core.OrderDto) {

    const [lineWithEVDelivery] = (order?.Lines ?? []).filter( orderLine => orderLine.ShippingMethod?.Carrier?.Code === this.EVCode);

    const date = lineWithEVDelivery?.RequestedDate;

    return date;
  }

  /**
   * Get the delivery timeframe information
   */
  public async getPostNlShippingDeliveryInformation (
    date: string,
    timeFrameOption: EVA.Carrier.PostNL.TimeframeOptions): Promise<IShippingDeliveryInformation> {
    const order = await this.getCurrentOrder();
    const orderId = order.ID;

    let skipAvailabilityChecks = false;
    let timeframe = await this.getDeliveryTimeframe(orderId, date, timeFrameOption, skipAvailabilityChecks);

    if (isNil(timeframe)) {
      skipAvailabilityChecks = true;
      // Retry with skipAvailabilityChecks toggled
      //
      timeframe = await this.getDeliveryTimeframe(orderId, date, timeFrameOption, skipAvailabilityChecks);
    }

    if (isNil(timeframe)) {
      this.logger.error(`Could not find the user chosen timeframe for date ${date} and option ${timeFrameOption}`);
    }

    const shippingDeliveryInformation: IShippingDeliveryInformation = {
      date,
      skipAvailabilityChecks,
      timeframe: timeframe,
      carrier: 'postnl'
    };

    return shippingDeliveryInformation;
  }

  /**
   * Checks that the current selected shipping method is EV
   */
  public async currentShippingMethodIsEV(): Promise<boolean> {
    const currentShippingMethod = await this.getCurrentShippingMethod();

    const currentShippingMethodIsEV = !isNil(currentShippingMethod) && currentShippingMethod.Carrier.Code === this.EVCode;

    return currentShippingMethodIsEV;
  }

  /**
   * Get the delivery option model corresponding to the PostNL delivery
   */
  public async getPostNLDeliveryOption(): Promise<IDeliveryOption> {
    const deliveryOptions = await this.deliveryOptions$.pipe(take(1)).toPromise();

    const postNLDeliveryOption = deliveryOptions.find(deliveryOption => deliveryOption.code === this.PostNLCode);

    return postNLDeliveryOption;
  }

  /**
   * Get the delivery option model corresponding to Own Shipping delivery
   */
  public async getEvDeliveryOption(): Promise<IDeliveryOption> {
    const deliveryOptions = await this.deliveryOptions$.pipe(take(1)).toPromise();

    const evDeliveryOption = deliveryOptions.find(deliveryOption => deliveryOption.code === this.EVCode);

    return evDeliveryOption;
  }

  /**
   * Gets the current shipping method id based on the property in the cart
   */
  public async getCurrentShippingMethod(): Promise<EVA.Core.ShippingMethodDto> {
    const currentShippingMethod: EVA.Core.ShippingMethodDto = await getShoppingCart.getResponse$().pipe(isNotNil(), map(response => {
      // Filtering out on line action type 4 ( Deliver )
      //
      const deliveryLines = response.ShoppingCart.Lines.filter(line => line.LineActionType === 4);

      if (isEmpty(deliveryLines)) {
        return null;
      } else {
        // TO:DO
        // Here we are making the assumptions that all the delivery lines have the same `ShippingMethodID` which is not ALWAYS the case. The UI needs to handle mixed `ShippingMethodID` lines better
        // which we are not doing it now. As the designs assume you either have `EIGEN_VERVOER` or `POSTNL` as current shipping method. If the backend imports babydump orders that are mixed on `ShippingMethodID`
        // our UI would show wrong things.
        //
        const [firstDeliveryLine] = deliveryLines;

        return firstDeliveryLine.ShippingMethod;
      }
    }), take(1)).toPromise();

    return currentShippingMethod;
  }

  /**
   * Calculates the requested delivery date settings model
   */
  public calculateRequestedDeliveryDateSettings(minDate: Date, selectedDate: Date): IRequestedDeliveryDate {
    const result: IRequestedDeliveryDate = {
      minimumDate: minDate.toISOString(),
      selectedDate: selectedDate.toISOString(),
      maximumDate: this.maximumRequestedDeliveryDate
    };

    const today = new Date();
    // If the min date is today, we want to add an extra day and use that as selected value.
    // @see https://eva2015.atlassian.net/browse/OPTR-3112
    //
    if (moment(minDate).isSame(today, 'day')) {
      this.logger.log(`
      This stock can be delivered today, and because same day delivery is rare we are setting the selected date in the UI
      to tomorrow
    `);
      result.minimumDate = moment(minDate).add(1, 'day').toISOString();
    }

    return result;
  }

  /**
   * Create a delivery option model from the shipping method DTO
   */
  public createDeliveryOption(shippingMethod: EVA.Core.ShippingMethodDto): IDeliveryOption {
    const deliveryOption: IDeliveryOption = {
      code: shippingMethod.Carrier.Code,
      id: shippingMethod.Carrier.ID,
      name: shippingMethod.Carrier.Name
    };

    return deliveryOption;
  }

  public async currentOrderHasShippingAddress() {
    const currentOrder = await this.getCurrentOrder();

    const currentOrderHasShippingAddress = !isNil(currentOrder.ShippingAddress);

    return currentOrderHasShippingAddress;
  }

  /**
   * Get the delivery timeframe for the order id on a certain date
   * matching it with a timeframe option
   */
  private async getDeliveryTimeframe(
    orderId: number,
    date: string,
    timeFrameOption: EVA.Carrier.PostNL.TimeframeOptions,
    skipAvailabilityChecks: boolean) {
    const result = await this.fetchDeliveryTimeframes(orderId, {
      date, skipAvailabilityChecks
    });

    const dayFrame = (result?.Frames ?? []).find(frame => {
      return new Date(frame.Date).toDateString() === new Date(date).toDateString();
    });

    const timeframes = get(dayFrame, 'Timeframes', [] ) as EVA.Carrier.PostNL.AvailableTimeframe[];
    const chosenTimeframe = timeframes.find(timeframe => timeframe.Option === timeFrameOption);
    return chosenTimeframe;
  }

  /**
   * @see https://eva2015.atlassian.net/browse/OPTR-3112
   *
   * When returning an array of minimum stock availability dates:
   * - We first look to see if the `RequestedDate` is already set and `CanBeFulfilled` is set to `true`.
   *   In this case we return the `RequestedDate`.
   *
   * - Otherwise, we return the `minimum` stock availability date object from the `AlternativeDates` array.
   */
  private getSelectedStockAvailabilityDate(
    orderLine: EVA.Core.GetStockAvailabilityEstimateForOrderResponse.OrderLine): Date {
    let result: Date;
    if (!isNil(orderLine.RequestedDate) && orderLine.CanBeFulfilled) {
      result = new Date(orderLine.RequestedDate);
    } else {
      const alternativeDates = orderLine.AlternativeDates.map(dateObject => new Date(dateObject.Date));

      //
      // Pick the minimum date out of the alternative dates.
      result = min(alternativeDates);
    }

    return result;
  }

  /**
   * @see https://eva2015.atlassian.net/browse/OPTR-3342
   *
   * We need to find the minimum dates out of `RequestedDate` and `AlternativeDates` array
   */
  private getMinimumStockAvailabilityDate(
    orderLine: EVA.Core.GetStockAvailabilityEstimateForOrderResponse.OrderLine): Date {
    const array = orderLine.AlternativeDates.map(dateObject => new Date(dateObject.Date));

    if (!isNil(orderLine.RequestedDate) && orderLine.CanBeFulfilled) {
      array.push(new Date(orderLine.RequestedDate));
    }

    const result = min(array);

    return result;
  }

  /**
   * Fetching the PostNL shipping options for a given order id
   */
  private async getShippingOptionsForOrder(orderId: number) {
    try {
      const [getShippingOptionsForOrderAction, getShippingOptionsForOrderResult] = postNLGetShippingOptionsForOrder.createFetchAction({
        OrderID: orderId
      });

      store.dispatch(getShippingOptionsForOrderAction);

      // Awaiting this so we can catch any errors
      //
      await getShippingOptionsForOrderResult;
    } catch (error) {
      this.logger.error('Could not get shipping options for order', error);
    }
  }

  /**
   * Fetching the available Pick Up Locations for a given order id
   */
  private async getAvailablePickUpLocationsForOrder(orderId: number) {
    const currentOrderHasShippingAddress = await this.currentOrderHasShippingAddress();
    if (!currentOrderHasShippingAddress) {
      return;
    }

    try {
      const [getPickupLocationsForOrderAction, getPickupLocationsForOrderResponse] = postNLGetAvailablePickUpLocationsForOrder.createFetchAction({
        OrderID: orderId
      });
      store.dispatch(getPickupLocationsForOrderAction);

      // Awaiting this so we can catch any errors
      //
      await getPickupLocationsForOrderResponse;
    } catch (error) {
      this.logger.error('Could not get pickup locations for order', error);
    }
  }
}
