import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'isBeforeToday'
})
export class IsBeforeTodayPipe implements PipeTransform {

  transform(value: string, args?: any): boolean {
    const isAfter = moment(value).isBefore(moment());

    return isAfter;
  }

}
