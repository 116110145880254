import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { parseBarcode, sentinel, settings } from '@springtree/eva-sdk-redux';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, pairwise, startWith } from 'rxjs/operators';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { ConfirmAlert } from '../../shared/decorators/confirm-alert';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { runInZone } from '../../shared/operators/runInZone';
import { RoutesProvider } from '../routes/routes';

export type TOnlineStatus = 'unknown' | 'online' | 'offline' | 'pending';
/**
 * The sentinel is an add on to EVA which can be installed on-premise
 * See: https://springtreesolutions.github.io/eva-sdk-redux/#/concepts/sentinel
 *
 * The sentinel code in the SDK provides 3 main features:
 *
 * - feature status
 * - barcode scanning
 * - offline aware redux store
 *
 * @see https://eva2015.atlassian.net/browse/OPTR-8190
 *
 */
@Logger('[sentinel-provider]')
@Injectable()
export class SentinelProvider implements ILoggable {

  /**
   * Our shared logging instance
   */
  public logger: Partial<Console>;

  /**
   * Expose the sentinel status module
   */
  public status = sentinel.status;

  /**
   * Observable for the online status
   *
   * As discussed with Mark we should always start with the assumption that we're 'online' as opposed to the SDK
   *
   * This is directly used in the checkout page to control the display of 'Attach customer' button so this modification
   * allows us to show the attach customer button.
   */
  public offlineChanges$: BehaviorSubject<TOnlineStatus> = new BehaviorSubject('online' as TOnlineStatus);

  public get isOnline() {
    return sentinel.status.sentinel !== 'connected' || sentinel.offline.status === 'online';
  }

  public get isOffline(): boolean {
    return !this.isOnline;
  }

  constructor(
    private toastCtrl: EvaToastController,
    private $translate: TranslateService,
    private $routes: RoutesProvider,
    private zone: NgZone,
    private alertCtrl: AlertController,
    private navCtrl: NavController,
    private router: Router
  ) { }

  initialise() {

    // Starting with the assumption that we're online
    //
    sentinel.offline.changes$.pipe(
      startWith('online' as TOnlineStatus),
      distinctUntilChanged(),
      pairwise(),
      runInZone(this.zone)
    )
    .subscribe(([previousStatus, offlineStatusChange]) => {
      this.offlineChanges$.next(offlineStatusChange);

      this.logger.log(`New sentinel offline status: ${offlineStatusChange}. Previous status: ${previousStatus}`);

      if (offlineStatusChange === 'offline' || offlineStatusChange === 'online' || offlineStatusChange === 'unknown') {
        // Notify the user with a toast
        //
        if (offlineStatusChange === 'online' && previousStatus !== 'unknown') {
          // Notify about not being offline anymore
          //
          this.toastCtrl.showMessage( this.$translate.instant('sentinel.online') );

          this.disableRouting(false);
          this.enableRouteForOnline();
        } else if (offlineStatusChange === 'offline' && previousStatus !== 'pending') {
          this.goOffline();
          this.disableRouteForOffline();
        }
      }
    });
  }

  /** When we are offline, we want to disable some tabs */
  disableRouting(disable: boolean = true) {
    if (disable) {
      this.$routes.disableRoutes();
    } else {
      // This will enable the routes in the side menu
      //
      this.$routes.enableRoutes();
    }
  }

  /**
   * Directly expose the parseBarcode from the sentinel performance section
   * The SDK will automatically use sentinel for parseBarcode when using
   * the reducer unless you set `settings.disableSentinelParseCode` to true.
   *
   * This method will also fallback to the regular parseBarcode service if not connected to the sentinel
   */
  parseBarcode(request?: Partial<EVA.Core.ParseBarcode>, timeout?: number, authorisationToken?: string)  {
    if (sentinel.status.sentinel !== 'connected') {
      const [fetchAction] = parseBarcode.createFetchAction(request, authorisationToken, timeout);

      return parseBarcode.fetchData(fetchAction);
    } else {
      return sentinel.performance.parseBarcode(request, timeout, authorisationToken);
    }
  }

  @ConfirmAlert({
    i18nConfirmButtonKey: 'sentinel.online.confirm.button',
    i18nCancelButtonKey: 'action.cancel',
    i18nTitleKey: 'sentinel.online.title',
    i18nMessageKey: 'sentinel.online.message'
  })
  async goOnline() {
    try {
      sentinel.offline.goOnline();
    } catch (error) {
      this.logger.error('error going online', error);
    } finally {
      this.navigateToLoginPage();
    }
  }

  isServiceAvailableOffline(serviceName: string) {
    return sentinel.offline.isServiceAvailableOffline(`/message/${serviceName}`);
  }

  disableRouteForOffline() {
    this.$routes.isOfflineMode();
  }

  enableRouteForOnline() {
    this.$routes.isOnlineMode();
  }

  private async goOffline() {

    const goOffline = async () => {
      // Notify about being offline
      //
      this.toastCtrl.showMessage(this.$translate.instant('sentinel.offline'));

      await this.navigateToLoginPage();

      this.disableRouting();
    };

    // We only want to show the user an alert if they are in any page that is a subview of the page-tabs
    //
    if (this.router.url.includes('tabs')) {
      const alert = await this.alertCtrl.create({
        buttons: [this.$translate.instant('sentinel.offline.confirm.button')],
        backdropDismiss: false,
        message: this.$translate.instant('sentinel.offline.message'),
        header: this.$translate.instant('sentinel.offline.title'),
      });

      alert.present();

      alert.onDidDismiss().then(goOffline);
    } else {
      goOffline();
    }
  }

  private navigateToLoginPage() {
    return this.navCtrl.navigateRoot('login');
  }
}
