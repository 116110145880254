import { Component, forwardRef, HostListener, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { getOrganizationUnitSummary, listCountries, store } from '@springtree/eva-sdk-redux';
import * as i18nCountries from 'i18n-iso-countries';
import { isNil } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, take, withLatestFrom } from 'rxjs/operators';
import { IItemsSearchData } from 'src/app/modals/item-search/item-search';
import { InternationalisationProvider } from 'src/app/services/internationalisation/internationalisation';
import { fadeInOut, slideUpDown } from '../../shared/animations';
import isNotNil from '../../shared/operators/isNotNil';
import { languageFromLocale } from '../../shared/utils/language';
import { TAddressForm, TValidationMessages } from '../edit-address/edit-address.component';

@Component({
  selector: 'eva-country-selector',
  templateUrl: 'country-selector.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CountrySelectorComponent),
    multi: true
  }],
  animations: [slideUpDown, fadeInOut],
})
export class CountrySelectorComponent implements OnInit, ControlValueAccessor {

  /** Array of all possible countries
   * this wont require dispatching because its already being done implicitly in the store
   * */
   public countries$: Observable<EVA.Core.CountryDto[]> = listCountries.getResponse$().pipe(
    isNotNil(),
    map( res => res.Result.map( element => {
      const defaultLang = this.$translate.defaultLang;

      const locale = this.$internationalisation.getLocale().value;

      const language = languageFromLocale(locale);

      const translatedCountryName = i18nCountries.getName(element.ID, language || defaultLang);

      return { Name: translatedCountryName ? translatedCountryName : element.Name, ID: element.ID };
      })
    )
  );

  public countriesSearchData$: Observable<IItemsSearchData<EVA.Core.CountryDto>> = this.countries$.pipe(
    isNotNil(),
    map(countries => {
      const countriesSearchObj: IItemsSearchData<EVA.Core.CountryDto> = {
        items: countries,
        labelKey: 'Name',
        title: this.$translate.instant('select.country'),
        valueKey: 'ID'
      };

      return countriesSearchObj;
    })
  );

  /** Validation messages we will use in our template */
  public validationMessages: TValidationMessages<TAddressForm> = {
    CountryID: {
      required: null
    },
  };

  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private $translate: TranslateService,
    private $internationalisation: InternationalisationProvider,
  ) {
    this.form = this.formBuilder.group({
      CountryID: [ null ],
    });
  }

  onTouchedCallback = () => { };

  @HostListener('blur')
  onblur() {
    this.onTouchedCallback();
  }

  writeValue(obj: any): void {
    if (obj) {
      this.form.get('CountryID').setValue(obj);
    }
  }

  registerOnChange(fn: any): void {
    this.form.get('CountryID').valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  ngOnInit() {
    this.fetchCountries();
  }

  fetchCountries() {
    listCountries.getResponse$().pipe(first(response => isNil(response)))
      .subscribe(() => {
        // Only fetch the country list once
        //
        const [action] = listCountries.createFetchAction({
          FetchAll: true
        });
        store.dispatch(action);
      });
  }
}
