import { isNil, isEmpty } from 'lodash';
/**
 * Language locale delimiter
 *
 * e.g. en-US
 */
const languageLocaleDelimiter = '-';

export const languageFromLocale = (locale: string) => {
  if (isNil(locale) || isEmpty(locale)) {
    throw new Error('Locale identifier is empty');
  }

  // Underscore is a valid locale delimeter as per ISO 15897. But bcp47 uses dash as delimeter.
  // so both are valid depending on what spec we respect. so we will support both
  //
  const localeWithCorrectDelimiter = locale.replace('_', languageLocaleDelimiter);

  // See if the locale contains the delimiter
  //

  const containsDelimiter = localeWithCorrectDelimiter.indexOf(languageLocaleDelimiter) >= 0;

  if (!containsDelimiter) {

    // Probably our locale is just the language
    // so we can safely return it
    //
    return localeWithCorrectDelimiter;
  }

  const localeSplit = localeWithCorrectDelimiter.split(languageLocaleDelimiter);
  const language = localeSplit[0];

  return language;
};
