import { Injectable, NgZone } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { communicator, getCurrentUser, getUser, IHubEvent, IHubEventUserTask, store } from '@springtree/eva-sdk-redux';
import { isNil } from 'lodash';
import { isFunction } from 'lodash-es';
import { debounceTime, filter, map, take, tap } from 'rxjs/operators';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import isNotNil from '../../shared/operators/isNotNil';


/**
 * Any page that implements this interface will beneift from the task assignee change functionality
 * @se https://n6k.atlassian.net/browse/OPTR-7967
 */
 export interface ITaskPage {
  navCtrl: NavController;
  getHandledTasks(): number[] | Promise<number[]>;
  /** method that will determine how to navigate away from this page, with a fallback to this.navCtrl.pop() */
  navigateAway?(): any;
}

@Logger('[user-task-assignee-change-service]')
@Injectable({
  providedIn: 'root'
})
export class UserTaskAssigneeChangeService implements ILoggable {

  logger: Partial<Console>;

  private currentlyActiveTaskPage: ITaskPage;

  /** Whether we have an alert present or not, we will use this to ensure we only show one alert at a time */
  private taskAssigneeChangeAlertPresent: boolean;

  constructor(
    private ngZone: NgZone,
    private $translate: TranslateService,
    private alertCtrl: AlertController,
    private navCtrl: NavController,
    private modalCtrl: ModalController
  ) {}

  onRouteChange(page: Object) {
    if ( this.implementsTaskPageInterface(page) ) {
      this.currentlyActiveTaskPage = page;
    } else {
      this.currentlyActiveTaskPage = null;
    }
  }

  /**
   * Will add a listener to UserTaskUpdated events and react to events that affect the currently open task page.
   */
  initialise() {
    communicator.event$.pipe(
      filter(event => event.method === 'UserTaskUpdated'),
      debounceTime(500),
      tap((e) => this.logger.log('UserTaskUpdated', e)),
    ).subscribe((event: IHubEvent) => {
      this.ngZone.run(() => {
          this.handleTaskAssigneeUpdate(event.data as IHubEventUserTask);
      });
    });
  }

  private async handleTaskAssigneeUpdate(taskHubEvent: IHubEventUserTask) {
    if ( !isNil(this.currentlyActiveTaskPage)) {
      const handledTaskIds = await this.currentlyActiveTaskPage.getHandledTasks();

      const eventIsConcerningThisComponent = !isNil(taskHubEvent) && handledTaskIds.includes(taskHubEvent.ID);

      if (eventIsConcerningThisComponent) {
        this.handleTaskUpdatedEvent(taskHubEvent);
      }
    }
  }

  private implementsTaskPageInterface(taskPage: Object): taskPage is ITaskPage {
    return 'getHandledTasks' in taskPage;
  }

  private async handleTaskUpdatedEvent(taskHubEvent: IHubEventUserTask) {
    const currentEmployeeId = await getCurrentUser.getResponse$().pipe(
      map(response => response.User),
      isNotNil(),
      map(user => user.ID),
      take(1)
    ).toPromise();

    // If a task's assignee has changed and the assignee is not the current employee, show the message
    //
    if (!isNil(taskHubEvent.UserID) && taskHubEvent.UserID !== currentEmployeeId) {
      // The task was assigned to somebody else, so we need to show the confirmation toast
      //
      this.logger.log(`Task assignee changed for task ${taskHubEvent.ID}. Showing the message toast`);

      // Show the alert to the user
      //
      this.showConfirmationDialog(taskHubEvent.UserID);
    }
  }

  /**
   * Shows a confirmation dialog to the user that the task has been reassigned to someone else.
   *
   * Upon confirmation, the user can navigate back
   */
  private async showConfirmationDialog(newTaskAssigneeId: number) {
    // if the alert is present, return early to ensure we dont show double alerts.
    //
    if ( this.taskAssigneeChangeAlertPresent ) {
      return;
    }

    const employeeFirstName = await this.getEmployeeFirstName(newTaskAssigneeId);

    const alertMessage = !isNil(employeeFirstName)
      ? this.$translate.instant('task.assignee.changed.message', {
        EmployeeFirstName: employeeFirstName
      })
      : this.$translate.instant('task.assignee.changed.message.generic');

    const alertTitle = this.$translate.instant('task.assignee.changed.title');

    const alertInstance = await this.alertCtrl.create({
      header: alertTitle,
      message: alertMessage,
      buttons: [
        {
          text: this.$translate.instant('action.confirm'),
          role: 'submit',
          handler: async () => {
            // if a modal is open on a task page it will be closed when the task is reassigned
            //
            if (await this.modalCtrl.getTop()) {
              this.modalCtrl.dismiss();
            }

            if (isFunction(this.currentlyActiveTaskPage.navigateAway)) {
              this.currentlyActiveTaskPage.navigateAway();
            } else {
              this.navCtrl.navigateBack('tabs/tasks');
            }
          }
        },
      ],
      backdropDismiss: false,
    });

    await alertInstance.present();

    this.taskAssigneeChangeAlertPresent = true;

    alertInstance.onDidDismiss().then(() => {
      // This being set to false doesnt matter too much, because the user will be navigated away anyway. But this piece of code
      // is here incase that changes in the future and we want to support multiple alerts over a components lifecycle.
      //
      this.taskAssigneeChangeAlertPresent = false;
    });
  }

  /**
   * Gets an employee first name by id or null if encountered networking issues.
   */
  private async getEmployeeFirstName(employeeId: number): Promise<string> {
    const [getUserAction, getUserPromise] = getUser.createFetchAction({
      ID: employeeId
    });

    store.dispatch(getUserAction);

    try {
      const userResponse = await getUserPromise;
      const employeeName = userResponse?.FullName;

      return employeeName;
    } catch {
      this.logger.error(`Could not fetch user data for id ${employeeId}`);

      return null;
    }
  }

}
