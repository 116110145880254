import { Injectable } from '@angular/core';
import { getCurrentUser } from '@springtree/eva-sdk-redux';
import { take } from 'rxjs/operators';

/**
 * This service will allow special features in the app based on the user that is logged in, this implementation can be changed in the future to look at something else
 * like adhoc builds or the current environment.
 * */
@Injectable()
export class DebugAllowedProvider {

  constructor() { }

  async isAllowed(): Promise<boolean> {
    const getCurrentUserResponse = await getCurrentUser.getLoggedInUser$().pipe(take(1)).toPromise();

    if ( !getCurrentUserResponse ) {
      return false;
    } else {
      const emailAddress = getCurrentUserResponse.User.EmailAddress;

      const domain = (emailAddress || '').split('@')[1];

      const allowedDomains = ['springtree.nl', 'newblack.io', 'jandewitt.nl', 'jandewitt.io', 'cgi.com', 'corebuild.eu'];

      const isAllowed = allowedDomains.includes(domain);

      return isAllowed;
    }
  }

}
