import { Injectable } from '@angular/core';
import { communicator, getShoppingCartInfo, store, settings, getCurrentUser, IHubEventSessionChanged, getUser } from '@springtree/eva-sdk-redux';
import { filter, map, distinctUntilChanged, pairwise, tap, take, distinctUntilKeyChanged, first } from 'rxjs/operators';
import { Logger, ILoggable } from '../../shared/decorators/logger';
import { get, isNumber } from 'lodash';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { OrderLifecycleService } from '../order-lifecycle/order-lifecycle.service';
import { Router } from '@angular/router';
import { EvaApplicationConfigProvider } from '../eva-application-config/eva-application-config';

@Logger('[communicator-provider]')
@Injectable()
export class CommunicatorProvider implements ILoggable {

  logger: Partial<Console>;

  private currentOrderHubId: number = null;

  private userID$ = getCurrentUser.getState$().pipe(
    filter(state => Boolean(state.response) && Boolean(state.response.User)),
    map(state => state.response.User.ID)
  );

  constructor(
    private alertCtrl: AlertController,
    private $translate: TranslateService,
    private $orderLifecycleService: OrderLifecycleService,
    private router: Router,
    private $applicationConfig: EvaApplicationConfigProvider,
  ) { }

  initialise() {
    this.logger.log('Initializing');
    this.listenForSessionChanges();
    this.listenForCartChanges();
    this.listenForRogueOrderTakeovers();
  }

  async joinOrderHub(newOrderId: number) {
    this.logger.log(`Joining order hub for order ${newOrderId}`);

    if ( !this.isValidOrderHub(newOrderId) ) {
      this.logger.log(`Order id ${newOrderId} was passed to joinOrderHub, returning early as this order is not joinable`);

      return;
    }

    const isEmployee = await getCurrentUser.getState$().pipe(
      filter(state => !state.isFetching),
      map(state => state.isEmployee),
      take(1)
    ).toPromise();


    // If we are not an employee we will return early
    //
    if ( !isEmployee ) {
      return;
    }

    try {
      await communicator.joinOrderHub({
        orderId: newOrderId,
        token: settings.userToken,
        options: {}
      });

      this.currentOrderHubId = newOrderId;
    } catch (error) {
      this.logger.error('Error performing joinOrderHub', error);
    }
  }

  private listenForSessionChanges() {
    communicator.event$.pipe(
      filter( event => event.method === 'SessionChanged' ),
      map(event => event.data as IHubEventSessionChanged),
      distinctUntilChanged((prev, next) => prev.OrderID === next.OrderID)
    ).subscribe((sessionChangedData) => {
      this.logger.log('sessionChangedData', sessionChangedData);
      const [action] = getShoppingCartInfo.createFetchAction();

      store.dispatch(action);
    });
  }

  /**
   * Listen for incoming events to trigger an alert if someone else changed the order
   * @see https://n6k.atlassian.net/browse/OPTR-22729
   */
  private listenForRogueOrderTakeovers() {
    communicator.event$.pipe(
      filter(event => event.method === 'OrderUpdated'),
      map(event => event.data as IHubEventSessionChanged),
      filter(event => event.OrderID === this.$orderLifecycleService.getCurrentOrderId()),
      distinctUntilChanged((prev, next) => {
        // this prevents opening the modal multiple times if the order or user didn't change
        return (prev.OrderID === next.OrderID && prev.UserID === next.UserID)
      })
    ).subscribe(async (incomingData) => {
      const currenUserId = await this.userID$.pipe(take(1)).toPromise();
      const showAlertEnabled = await this.$applicationConfig.showTakeorverOrderAlert$.pipe(first()).toPromise();
      const ignoreTakeoverUsers = await this.$applicationConfig.ignoreTakeoverUsers$.pipe(first()).toPromise();
      const skipIncomingUser = ignoreTakeoverUsers?.includes(String(incomingData.UserID));
      const shouldCheckUser = incomingData.UserID != currenUserId && !skipIncomingUser;

      if (shouldCheckUser && showAlertEnabled) {
        const [action] = getUser.createFetchAction({
          ID: incomingData.UserID
        });
        try {
          const remoteUserResponse = await getUser.fetchData(action);
          const { FirstName, Type } = remoteUserResponse;
          const isEmployee = Boolean(Type & 1);
          if (isEmployee) {
            this.showRogueOrderTakeoversModal(FirstName);
          }
        } catch (error) {
          this.logger.error(`error calling getUser with id=${incomingData.UserID}`, error);
        }
      }
    });
  }

  private async showRogueOrderTakeoversModal(firstName: string) {
    if (firstName) {
      const alert = await this.alertCtrl.create({
        header: this.$translate.instant('rogue.order.takeovers.title'),
        message: this.$translate.instant('rogue.order.takeovers.message', {
          firstName
        }),
        buttons: [
          {
            text: this.$translate.instant('action.continue')
          },
          {
            text: this.$translate.instant('action.deattach'),
            cssClass: 'alert-danger',
            handler: () => {
              this.deattachOrder();
            }
          }
        ]
      });

      alert.present();
    }
  }

  private async deattachOrder() {
    const [unsetPromise] = this.$orderLifecycleService.unsetOrderId();

    try {
      await unsetPromise;

      this.router.navigate(['tabs/basket']);
    } catch (error) {
      this.logger.error('Failed to deattach the order', error);
    }
  }

  /**
   * We need to ensure we are always connected to the correct order hub, where correct is the order hub with the matching order od
   */
  private listenForCartChanges() {
    getShoppingCartInfo.getResponse$().pipe(
      map( getShoppingCartInfoResponse => get(getShoppingCartInfoResponse, 'OrderID') ),
      distinctUntilChanged(),
      pairwise(),
    ).subscribe( async ([oldOrderId, newOrderId]) => {

      if (this.isValidOrderHub(oldOrderId)) {
        this.logger.log(`Leaving order hub for order ${oldOrderId}`);
        try {
          await communicator.leaveOrderHub({
            orderId: oldOrderId,
            token: settings.userToken,
            options: {}
          });
        } catch ( error ) {
          this.logger.error('Error performing leaveOrderHub', error);
        }
      }

      this.joinOrderHub(newOrderId);

    });
  }

  private isValidOrderHub(orderId: number): boolean {
    const isJoinableOrder = isNumber(orderId) && orderId !== 0;

    return isJoinableOrder;
  }
}
