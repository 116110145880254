<div class="pricing" *ngIf="!isEcoTax">
  <ng-container *ngIf="showPriceWithoutTax$ | async; else showOriginalPriceInTax">
    <!-- current price without tax -->
    <eva-price *ngIf="pricing?.price !== undefined" [currencyString]="currencyString" [maximumFractionDigits]="maximumFractionDigits" [price]="pricing?.price" data-in-tax="false"></eva-price>
    <eva-price *ngIf="pricing?.unitPrice !== undefined" [currencyString]="currencyString" [maximumFractionDigits]="maximumFractionDigits" [price]="pricing?.unitPrice" data-in-tax="false"></eva-price>
    <!-- original price without tax-->
    <del class="original-price" *ngIf="pricing?.originalPrice !== undefined">
      <eva-price [currencyString]="currencyString" [maximumFractionDigits]="maximumFractionDigits" [price]="pricing?.originalPrice" data-in-tax="false"></eva-price>
    </del>
    <del class="original-price" *ngIf="pricing?.originalUnitPrice !== undefined">
      <eva-price [currencyString]="currencyString" [maximumFractionDigits]="maximumFractionDigits" [price]="pricing?.originalUnitPrice" data-in-tax="false"></eva-price>
    </del>
  </ng-container>

  <ng-template #showOriginalPriceInTax>
    <!-- current price with tax-->
    <eva-price *ngIf="pricing?.priceInTax !== undefined" [currencyString]="currencyString" [maximumFractionDigits]="maximumFractionDigits" [price]="pricing?.priceInTax" data-in-tax="true"></eva-price>
    <eva-price *ngIf="pricing?.unitPriceInTax !== undefined" [currencyString]="currencyString" [maximumFractionDigits]="maximumFractionDigits" [price]="pricing?.unitPriceInTax" data-in-tax="true"></eva-price>
    <!-- original price with tax-->
    <del class="original-price" *ngIf="pricing?.originalPriceInTax !== undefined">
      <eva-price [currencyString]="currencyString" [maximumFractionDigits]="maximumFractionDigits" [price]="pricing?.originalPriceInTax" data-in-tax="false"></eva-price>
    </del>
    <del class="original-price" *ngIf="pricing?.originalUnitPriceInTax !== undefined">
      <eva-price [currencyString]="currencyString" [maximumFractionDigits]="maximumFractionDigits" [price]="pricing?.originalUnitPriceInTax" data-in-tax="false"></eva-price>
    </del>
  </ng-template>
</div>

<!--
  We use this same component to show Eco Taxes as well
  @see https://n6k.atlassian.net/browse/OPTR-23021
-->
<div class="pricing" *ngIf="isEcoTax">
  <eva-price [currencyString]="currencyString"
    [maximumFractionDigits]="maximumFractionDigits"
    [price]="pricing?.ecoTaxInTax" data-in-tax="false">
  </eva-price>
</div>
