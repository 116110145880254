import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash-es';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { CustomerUpsertNavigationProvider } from 'src/app/services/customer-upsert-navigation/customer-upsert-navigation';
import { ScanPageProvider } from 'src/app/services/scan-page/scan-page';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';

/**
 * This guard will ensure the customer/opt-in page isnt opened unless the upsertStartPayloadObj is set in the provider.
 */
@Logger('[opt-in-guard-service]')
@Injectable({
  providedIn: 'root'
})
export class OptInGuardService implements CanActivate, ILoggable {
  logger: Partial<Console>;

  constructor(private navCtrl: NavController, private $customerUpsertNavigationProvider: CustomerUpsertNavigationProvider) {}

  async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    /** We will only open this page if the upsertStartPayload is there */
    const shouldNotOpen = isNil(this.$customerUpsertNavigationProvider.currentNavigationState);

    this.logger.log(`shouldNotOpen=${shouldNotOpen}`);

    if (shouldNotOpen) {
      try {
        await this.navCtrl.navigateRoot('');
      } catch (error) {
        this.logger.error('error navigating to root page', error);
      }
    }

    const shouldOpen = !shouldNotOpen;

    return shouldOpen;
  }
}
