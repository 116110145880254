import { Component, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { fadeInOut } from '../../shared/animations';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { IDotsFilledCallbackResult, PinInputComponent, TDotsFilledInCallback, TPinAnimationState } from 'src/app/components/pin-input/pin-input.component';

/**
 * GENERIC Elevation Pincode Modal
 *
 * Show pincode modal to the user to be able to verify an operation
 * This works like this:
 * - An employee ask another employee/manager to generate an elevation pincode from their device
 * - The same employee use the pincode which was given by the manager to verify the operation
 *
 * @see https://n6k.atlassian.net/browse/OPTR-23336
 */
@Logger('[generic-elevation-check-pincode-modal]')
@Component({
  selector: 'eva-elevation-check-pincode-modal',
  templateUrl: 'elevation-check-pincode-modal.html',
  styleUrls: ['elevation-check-pincode-modal.scss'],
  animations: [fadeInOut]
})
export class GenericElevationCheckPincodeModal implements ILoggable {

  @ViewChild(PinInputComponent) pinInputComponent: PinInputComponent;

  public logger: Partial<Console>;

  public orderId: number = this.navParams.get('orderId');

  // The service/method you want to invoque once the elevation permission is given
  public serviceToExecute: Function = this.navParams.get('serviceToExecute');

  constructor(private modalCtrl: ModalController, private navParams: NavParams) {}

  pinFilled: TDotsFilledInCallback = async (pin) => {
    let newAnimationState: TPinAnimationState = 'success';

    try {
      const pincode = pin.join('');
      if(this.serviceToExecute){
        await this.serviceToExecute(pincode);
        this.pinInputComponent.onAnimationDone.pipe(first()).subscribe(() => {
          this.modalCtrl.dismiss({approvedPermission:true});
        });
      } else {
        this.modalCtrl.dismiss(pincode);
      }
    } catch {
      newAnimationState = 'failure';
    }

    const callbackResult: IDotsFilledCallbackResult = {
      animationState: newAnimationState
    };

    return callbackResult;
  }

}
