import { ComponentRef, EmbeddedViewRef, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Logger, ILoggable } from 'src/app/shared/decorators/logger';

@Logger('[overlay-service]')
@Injectable({
  providedIn: 'root',
})
export class OverlayService implements ILoggable {
  logger: Partial<Console>;

  private containerElement: HTMLElement;

  private createdOverlays = new Map<string, HTMLElement>();

  private id = Math.random();

  constructor(@Inject(DOCUMENT) private document: Document) {}

  removeOverlays() {
    if (this.containerElement && this.containerElement.parentNode) {
      this.containerElement.parentNode.removeChild(this.containerElement);
    }
  }

  /** Inserts a component into an overlay prefferably created by the createOverlay method */
  public insertComponent(componentRef: ComponentRef<any>, overlay: HTMLElement) {
    const component = this.getComponentRootNode(componentRef);

    overlay.appendChild(component);
  }

  /** returns an overlay, will create it if doesnt exist */
  public getOverlay(className: string): HTMLElement {
    const overlayInMap = this.createdOverlays.get(className);
    if (overlayInMap) {
      return overlayInMap;
    }
    return this.createOverlay(className);
  }

  private createOverlay(className: string): HTMLElement {
    const pane = this.document.createElement('div');
    pane.classList.add(className);
    this.getContainerElement().appendChild(pane);
    this.createdOverlays.set(className, pane);
    return pane;
  }

  /**
   * This method returns the overlay container element. It will lazily
   * create the element the first time  it is called to facilitate using
   * the container in non-browser environments.
   * @returns the container element
   */
  private getContainerElement(): HTMLElement {
    this.logger.log(this.id);
    if (!this.containerElement) {
      this.createContainer();
    }
    return this.containerElement;
  }

  /**
   * Create the overlay container element, which is simply a div
   * with the 'eva-overlay-container' class on the document body.
   * This container will contain all the overlay elements
   */
  private createContainer(): void {
    const container = this.document.createElement('div');

    container.classList.add('eva-overlay-containers');
    this.document.body.querySelector('ion-app').appendChild(container);
    this.containerElement = container;
  }

  /** Gets the root HTMLElement for an instantiated component. */
  private getComponentRootNode(componentRef: ComponentRef<any>): HTMLElement {
    return (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
  }
}
