import { TranslateService } from '@ngx-translate/core';
import { isEmpty, isNil, isFunction, isString } from 'lodash';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { EvaErrorGeneratorProvider } from '../../services/eva-error-generator/eva-error-generator';
import { EvaLoadingControllerProvider, ILoadingOptionsDebugInfo } from '../../services/eva-loading-controller/eva-loading-controller';
import InjectorWrapper from '../../shared/injector';
import { UnknownFunction } from '../typings';
import { createLogger, kebabClassName } from './logger';

interface IEvaFeedback {
  i18nSuccessKey?: string;
  i18nFailKey: string;
  /** Field that will be passed to loading provider number of milliseconds for showing spinner */
  timeToShowSpinner?: number;
}

type TEvaPromiseReturn =
  EVA.Core.EmptyResponseMessage
  | EVA.Core.EmptyResponseMessage[]
  | EVA.Core.ResourceResponseMessage
  | EVA.Core.ResourceResponseMessage[];

export interface IExtendedFeedback<T = TEvaPromiseReturn> {
  /** Promise to wait for */
  feedbackPromise: Promise<T>;

  /** Function to call when the promise is resolved. */
  finallyCallback?: UnknownFunction;

  /** Params that will be passed to translate method */
  i18nSuccessParams?: string | [string, any];

  /** params that will be passed to translate method */
  i18nFailParams: string | [string, any];

  /** Field that will be passed to loading provider number of milliseconds for showing spinner */
  timeToShowSpinner?: number;
}

type TEvaFeedbackReturnType = Promise<TEvaPromiseReturn|IExtendedFeedback> | IExtendedFeedback;


class EvaFeedbackHelper {

  private evaLoadingCtrl: EvaLoadingControllerProvider;

  private evaErrorGenerator: EvaErrorGeneratorProvider;

  private evaToastCtrl: EvaToastController;

  private translate: TranslateService;

  private logger: Partial<Console>;

  private className: string;

  constructor(target: any, private prop: string) {
    this.className = target.constructor.name;

    this.logger = createLogger(`[${kebabClassName(target)}]`);

    if (!InjectorWrapper.getInjector()) {
      return;
    }

    this.evaLoadingCtrl = InjectorWrapper.getInjector().get(EvaLoadingControllerProvider);
    this.evaErrorGenerator = InjectorWrapper.getInjector().get(EvaErrorGeneratorProvider);
    this.evaToastCtrl = InjectorWrapper.getInjector().get(EvaToastController);
    this.translate = InjectorWrapper.getInjector().get(TranslateService);
  }

  /**
   * This is the simplest form of using the decorator
   */
  async handleEvaFeedbackParams(evaFeedback: IEvaFeedback, returnValue: TEvaPromiseReturn) {
    const loadingCtrl = await this.evaLoadingCtrl.create({
      timeToShowSpinner: evaFeedback.timeToShowSpinner,
      debugInformation: this.getDebugInformation()
    });

    loadingCtrl.startLoadingTimer();

    try {
      const resolvedValue: unknown = await returnValue;

      if (this.isExtendedEvaFeedback(resolvedValue)) {
        this.logger.error('You cannot return an extended eva feedback object in combitation with eva feedback as params. Please choose one of the two');
      }

      if (!isEmpty(evaFeedback.i18nSuccessKey)) {
        this.evaToastCtrl.create({
          message: this.translate.instant(evaFeedback.i18nSuccessKey)
        }).present();
      }

    } catch (error) {
      // logger.error(`error making service call in ${prop}`, error);

      this.evaErrorGenerator.showTranslatedError(error, evaFeedback.i18nFailKey);
    } finally {
      loadingCtrl.stopLoadingTimer();
    }
  }

  public async handleExtendedFeedback(returnValue: unknown) {
    if ( this.isPromise(returnValue) ) {
      try {
        const extendedFeedback: unknown = await returnValue;

        if (this.isExtendedEvaFeedback(extendedFeedback) ) {
          this.showExtendedFeedback(extendedFeedback);
        } else {
          this.logger.error('Neither an evaFeedback or an extendedFeedback were passed');
        }

      } catch (error) {
        this.logger.error('Function returning the extended feedback threw, unable to show feedback');
      }
    } else if ( this.isExtendedEvaFeedback(returnValue) ) {
      this.showExtendedFeedback(returnValue);
    }
  }

  private async showExtendedFeedback(extendedFeedback: IExtendedFeedback) {
    const loadingCtrl = await this.evaLoadingCtrl.create({
      timeToShowSpinner: extendedFeedback.timeToShowSpinner,
      debugInformation: this.getDebugInformation()
    });

    loadingCtrl.startLoadingTimer();

    try {
      const resolvedValue: unknown = await extendedFeedback.feedbackPromise;

      if (!isEmpty(extendedFeedback.i18nSuccessParams)) {
        const [key, params] = isString(extendedFeedback.i18nSuccessParams) ?
          [extendedFeedback.i18nSuccessParams, null] :
          extendedFeedback.i18nSuccessParams;

        this.evaToastCtrl.create({
          message: this.translate.instant(key, params)
        }).present();
      }

    } catch (error) {
      // logger.error(`error making service call in ${prop}`, error);

      this.evaErrorGenerator.showTranslatedError(error, extendedFeedback.i18nFailParams);
    } finally {
      loadingCtrl.stopLoadingTimer();

      if ( isFunction(extendedFeedback.finallyCallback) ) {
        extendedFeedback.finallyCallback();
      }

    }
  }

  private isExtendedEvaFeedback(value: any): value is IExtendedFeedback {
    return !isNil((value).feedbackPromise);
  }

  private isPromise(value: any): value is Promise<any> {
    return typeof value.then === 'function';
  }

  /** This information will be used by the loading controller for tracking usage */
  private getDebugInformation(): ILoadingOptionsDebugInfo {
    const debugInfo: ILoadingOptionsDebugInfo = {
      className: this.className,
      methodName: this.prop
    };

    return debugInfo;
  }
}


/**
 *
 * Decorator that will show the user both spinner feedback and show errors based on an eva promise
 *
 * an eva promise is mostly a eva-sdk-redux fetchPromise that contains a response that extends `EVA.Core.EmptyResponseMessage`
 *
 * @example
 * ```typescript
 * EvaFeedback({
 *   i18nMessage: 'error.printing.receipt'
 * })
 * produceDocuments(formValue: any) {
 *   const [action, fetchPromise] = produceDocuments.createFetchAction({
 *     OrderID: this.orderId,
 *     PrintReceipt: formValue.printReceipt,
 *     StationID: formValue.station,
 *   });
 *
 *   store.dispatch(action);
 *
 *   return fetchPromise;
 * }
 * ```
 */
export function EvaFeedback(evaFeedbackParams?: IEvaFeedback) {
  return function(target: any, prop: string, descriptor: TypedPropertyDescriptor<(...args: any[]) => TEvaFeedbackReturnType>) {
    const method = descriptor.value;

    descriptor.value = function() {
      const returnValue = method.apply(this, arguments);

      // We want to support three scenarios
      // 1- An evaFeedbackParams object was passed in
      // 2- No evaFeedbackParams object was passed in, but a IExtendedFeedback wrapped in a promise
      // 3- No evaFeedbackParams object was passed in, but a IExtendedFeedback
      //
      const evaFeedbackHelper = new EvaFeedbackHelper(target, prop);

      if ( !isNil(evaFeedbackParams) ) {
        evaFeedbackHelper.handleEvaFeedbackParams(evaFeedbackParams, returnValue);
      } else {
        evaFeedbackHelper.handleExtendedFeedback(returnValue);
      }
      return returnValue;
    };
  };
}
