import { Injectable } from "@angular/core";
import { EvaToastController } from "../eva-toast/eva-toast.controller";
import { AppleScanner } from 'custom-plugins/capacitor-apple-scanner-plugin/src';
import { BehaviorSubject } from "rxjs";
import { Device } from "@capacitor/device";
import { TranslateService } from "@ngx-translate/core";

export interface IBarcodeData{
  symbology: string;
  barcode: string;
}

/**
 * Apple Native scanner provider (capacitor)
 *
 * We only display this scanner under certain conditions
 * @see https://n6k.atlassian.net/browse/OPTR-21869
 */
@Injectable({
  providedIn: 'root'
})
export class AppleScannerProvider {

    // Scanner barcodes stream of data
    public onBarcodeScannEvents$: BehaviorSubject<IBarcodeData> = new BehaviorSubject(null);

    // Check if Apple Scanner is supported by device
    public appleScannerIsSupported$: BehaviorSubject<boolean> = new BehaviorSubject(null);

    // Whether we listen for incoming barcodes or not
    public listeningBarcodes$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    constructor(private toastCtrl: EvaToastController, private translate: TranslateService){
      this.registerPluginEvents();
      this.checkIfAppleScannerIsSupported();
    }

    public async initializeScanner(){
      // Check camera permission
      await AppleScanner.checkPermission({ force: true, newScann: true });

      // Make background of WebView transparent
      AppleScanner.hideBackground();

      // Show initialize possible errors
      AppleScanner.initialize().then((resp:any) => {
        if(resp.error){
          if(resp.error == 'unsupported'){
            const message = this.translate.instant('camera.unsupported');
            this.toastCtrl.showMessage(message);
          } else if(resp.error == 'cameraRestricted'){
            const message = this.translate.instant('camera.restricted');
            this.toastCtrl.showMessage(message);
          } else {
            this.toastCtrl.showMessage(resp.error);
          }
        }
      });
    }

    public async startScanning(): Promise<void> {
      AppleScanner.startScanning().then(() => {});
    }

    private async stopScanning(): Promise<void> {
      AppleScanner.stopScanning().then(() => {});
    }

    public resumeScanning(): void {
      this.startScanning();
    }

    public pauseScanning(): void {
      this.stopScanning();
    }

    public setCameraViewport(height: number): void {
      AppleScanner.setViewportHeight({ height }); // percentage format: 0 - 100 %
    }

    public setCameraPosition(state: string | 'worldFacing' | 'userFacing'): void {}

    private registerPluginEvents(): void{
      // Handle scanned barcode action events
      (AppleScanner as any).addListener('onPluginScannedBarcode', (eventData: any) => {
        const payload = {
          symbology: eventData.symbology,
          barcode: eventData.barcode
        }
        this.onBarcodeScannEvents$.next(payload);
      });

      // Handle any type of error
      (AppleScanner as any).addListener('onPluginGenericError', (eventData: any) => {
        console.log('>>> onPluginGenericError: ',eventData);
        this.toastCtrl.showMessage(`ERROR Scanner: ${JSON.stringify(eventData)}`);
      });
    }

    // We check for permissions here to improve performance
    private async checkIfAppleScannerIsSupported(): Promise<void> {
      const deviceInfo = await Device.getInfo();

      // The iOS version is not valid semver, so will simply split the version on the dot and take the first "value"
      // 12.4.1 => would give 12  OR  14.8 => would give 14
      const [iosMajorVersion] = deviceInfo?.osVersion?.split('.');
      const currentVersion: number = parseInt(iosMajorVersion);

      // We only want this for iOS
      if (!deviceInfo || deviceInfo?.operatingSystem !== 'ios') {
        this.appleScannerIsSupported$.next(false);
        return;
      }

      // For iPhones running ios < 16, the Apple scanner won’t work at all, so Scandit will be default there
      if(currentVersion < 16){
        this.appleScannerIsSupported$.next(false);
      } else {
        // We check on swift if DataScannerViewController.isAvailable && .isSupported;
        const scanner = await AppleScanner.isAppleScannerSupported();
        this.appleScannerIsSupported$.next(scanner?.isSupported);
      }
    }

}
