import { Component } from '@angular/core';
import { ILoggable, Logger } from '../../shared/decorators/logger';
import { cancelOrder, store } from '@springtree/eva-sdk-redux';
import { EvaFeedback } from '../../shared/decorators/eva-feedback';
import { timeToShowSpinnerDefaults } from '../../shared/constants';
import { ModalController, NavParams } from '@ionic/angular';
import { OrderLifecycleService } from 'src/app/services/order-lifecycle/order-lifecycle.service';

@Logger('[shipping-restrictions-modal-component]')
@Component({
  selector: 'eva-shipping-restrictions-modal',
  templateUrl: 'shipping-restrictions.modal.html',
  styleUrls: ['shipping-restrictions.modal.scss'],
})

export class ShippingRestrictionsModalComponent implements ILoggable {
  public logger: Partial<Console>;

  public readonly restrictedOrderLines: EVA.Core.OrderLineDto[] = this.navParams.get('restrictedOrderLines');

  public readonly orderId: number = this.navParams.get('orderId');

  public cancelOrderLineIsFetching$ = cancelOrder.isFetching$();

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    private $orderLifecycleService: OrderLifecycleService,
  ) {
  }

  @EvaFeedback({
    i18nFailKey: 'cart.lines.delete.fail',
    i18nSuccessKey: 'cart.lines.delete.success',
    timeToShowSpinner: timeToShowSpinnerDefaults.long,
  })
  public async deleteRestrictedProducts() {
    const promises: Promise<any>[] = this.restrictedOrderLines.map(async restrictedOrderLine => {
      const request: EVA.Core.CancelOrder = { OrderLines: [{OrderLineID: restrictedOrderLine.ID}] };

      const {cartUpdateChainPromise} = await this.$orderLifecycleService.cancelOrder({request});

      return cartUpdateChainPromise;
    });

    const allPromises = Promise.all(promises);

    try {
      await allPromises;
      // If everything ended with success, close the modal
      //
      this.closeModal();
    } finally {

    }

    return allPromises;
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }
}
