import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { getCurrentUser } from '@springtree/eva-sdk-redux';
import { isNil } from 'lodash';
import { filter, take } from 'rxjs/operators';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';

@Logger('[login-guard-service]')
@Injectable()
export class LoginGuardService implements CanActivate, ILoggable {
  logger: Partial<Console>;

  constructor(private navCtrl: NavController) {}

  async canActivate() {

    const userState = await getCurrentUser
      .getState$()
      .pipe(
        filter((state) => !state.isFetching),
        take(1),
      )
      .toPromise();

    const shouldEnter = isNil(userState.response) || userState.isAnonymous;

    this.logger.log('shouldEnter = ', shouldEnter);

    if (!shouldEnter) {
      try {
        await this.navCtrl.navigateRoot(['']);
      } catch ( error ) {
        this.logger.error('error navigating to root page', error);
      }
    }

    return shouldEnter;
  }
}
