import { core, ISettingsChange, settings } from '@springtree/eva-sdk-redux';
import { IEnvironment } from '../services/eva-start-up/eva-start-up';
import { GetKeyFromInterface } from './typings';
import nodePackage from 'package.json';

const nodePackageVersion = nodePackage.version;
const nodePackageName = nodePackage.name;

/** We will this map to reset all the local storage keys used during the bootstrap in the case it fails */
export const bootstrapLocalStorageKeys: { [key: string]: GetKeyFromInterface<ISettingsChange, 'name'>} = {
  USERTOKEN: 'userToken',
  SENTINEL_END_POINT_URL: 'sentinelEndPointUrl',
  CURRENT_ORDER_ID: 'currentOrderId',
  APPLICATION_TOKEN: 'applicationToken'
};


/**
 * This will bootstrap the store by initalizing it with the loaded environment file
 */
export const bootstrapStore = ( env: IEnvironment ) => {

  console.count('[bootstrapStore] bootstrapping');

  // Configure default fields return for searchProducts
  //
  settings.defaultProductProperties = [
    'display_price',
    'display_value',
    'product_id',
    'currency_id',
    'custom_id',
    'primary_image.blob',
    'product_requirements',
    'product_types',
    'x_dimension_value',
    'y_dimension_value',
    'usp_blobs',
    'usp_texts',
    'quality_fit'
  ];

  settings.enableLogging = false;

  // TO:DO re-enable when sentinel parse barcode works again
  //
  settings.disableSentinelParseCode = true;

  /** User token of the user */
  const userToken = localStorage.getItem( bootstrapLocalStorageKeys.USERTOKEN );

  const sentinelEndPointUrl = localStorage.getItem( bootstrapLocalStorageKeys.SENTINEL_END_POINT_URL );

  settings.sentinelEndPointUrl = sentinelEndPointUrl;

  const localCurrentOrderId = localStorage.getItem(bootstrapLocalStorageKeys.CURRENT_ORDER_ID);

  if (localCurrentOrderId) {
    settings.currentOrderId = parseInt(localCurrentOrderId, 10);
  }

  const applicationTokenValue = localStorage.getItem(bootstrapLocalStorageKeys.APPLICATION_TOKEN);

  const bootstrapPromise = core.bootstrap({
    applicationToken: applicationTokenValue,
    appName: nodePackageName,
    appVersion: nodePackageVersion,
    defaultCallUserType: 'employee',
    defaultToken: env.defaultToken,
    disableCartBootstrap: true,
    enableSentinel: false,
    endPointUrl: env.endpoint,
    sentinelEndPointUrl,
    startCommunicator: true,
    userToken,
  });

  return bootstrapPromise;
};
